import { ChangeEvent, forwardRef } from "react";

import { AddIcon, MinusIcon } from "@chakra-ui/icons";
import { Center, Flex, FlexProps, Input, InputProps, chakra } from "@chakra-ui/react";

import { BodyM } from "ui/Typography/Typography";
import { VerticalLayout } from "ui/VerticalLayout/VerticalLayout";

export type IncreaseDecreaseProps = {
  label: string | React.ReactNode;
  count?: number;
  onChangeHandler: (e: ChangeEvent<HTMLInputElement>) => void;
  changeHandler: (count: number) => void;
  withInput?: boolean;
  isIncreaseBtnDisabled?: boolean;
  isDecreaseBtnDisabled?: boolean;
  allowNegativeInput?: boolean;
  dataTestId?: string;
  inputProps?: InputProps;
  onInputSubmit?: React.FormEventHandler;
} & FlexProps;

export const IncreaseDecrease = forwardRef<HTMLInputElement, IncreaseDecreaseProps>(
  (
    {
      label,
      count = 0,
      onChangeHandler,
      changeHandler,
      withInput = false,
      isIncreaseBtnDisabled = false,
      isDecreaseBtnDisabled = false,
      allowNegativeInput = false,
      dataTestId,
      inputProps,
      onInputSubmit,
      ...rest
    },
    ref,
  ) => {
    const isNegativeInputAllowed = allowNegativeInput || count;
    return (
      <VerticalLayout gap="s100" {...rest}>
        <BodyM py="s100">{label}</BodyM>
        <Flex justifyContent="space-between" alignItems="center">
          <Center
            backgroundColor="grey.100"
            borderRadius="sm"
            height="54px"
            width="104px"
            cursor={isNegativeInputAllowed ? "pointer" : "auto"}
            data-testid={`${dataTestId}-decrement`}
            onClick={
              isNegativeInputAllowed && !isDecreaseBtnDisabled
                ? () => changeHandler(count - 1)
                : undefined
            }
          >
            <MinusIcon
              color={isNegativeInputAllowed && !isDecreaseBtnDisabled ? "pink.400" : "grey.400"}
            />
          </Center>
          <Center p={4}>
            {withInput ? (
              <chakra.form onSubmit={onInputSubmit}>
                <Input
                  type="text"
                  inputMode="numeric"
                  pattern="[0-9]*"
                  value={count}
                  onChange={onChangeHandler}
                  fontWeight="bold"
                  textAlign="center"
                  variant="unstyled"
                  borderRadius="sm"
                  borderColor="grey.400"
                  borderWidth="1px"
                  width="42px"
                  height="42px"
                  data-testid={`${dataTestId}-input`}
                  ref={ref}
                  {...inputProps}
                />
              </chakra.form>
            ) : (
              <strong data-testid={`${dataTestId}-count`}>{count}</strong>
            )}
          </Center>
          <Center
            backgroundColor="grey.100"
            borderRadius="sm"
            cursor="pointer"
            height="54px"
            width="104px"
            data-testid={`${dataTestId}-increment`}
            onClick={() => (isIncreaseBtnDisabled ? undefined : changeHandler(count + 1))}
          >
            <AddIcon color={isIncreaseBtnDisabled ? "grey.400" : "pink.400"} />
          </Center>
        </Flex>
      </VerticalLayout>
    );
  },
);
