import { GetPublicRestockingListQuery } from "shared/queries/publicRestockingList/publicRestockingList.generated";
import { getShelfDetail } from "utils/item";

import { GetPrivateRestockingListQuery } from "../../queries/privateRestockingList/privateRestockingList.generated";
import { PublicRestockingItem, RestockingItem } from "./types";

type PrivateRestockingItemFromQuery =
  GetPrivateRestockingListQuery["getPrivateRestockingList"]["restockingItems"][0];

type PublicRestockingItemFromQuery =
  GetPublicRestockingListQuery["getPublicRestockingList"]["publicRestockingList"]["restockingItems"][0];

export const serializePublicRestockingItem = ({
  sku,
  product,
}: PublicRestockingItemFromQuery): PublicRestockingItem => {
  const shelfDetail = product?.inventoryEntry.shelfNumber
    ? getShelfDetail(product.inventoryEntry.shelfNumber)
    : null;
  return {
    sku,
    product: product
      ? {
          sku: product.sku,
          name: product.name,
          imageUrl: product.imageUrl ?? null,
          isBio: product.bio ?? null,
          countryOfOriginCode: product.countryOfOrigin.code?.toUpperCase() ?? null,
          shelfLetter: shelfDetail?.shelfLetter ?? null,
          shelfNumber: shelfDetail?.shelfNumber ?? null,
          shelf: product.inventoryEntry.shelfNumber ?? null,
          stockOnShelf: product.inventoryEntry.stock.shelf,
        }
      : null,
  };
};

export const serializePrivateRestockingItem = (
  restockingItem: PrivateRestockingItemFromQuery,
): RestockingItem => {
  const { sku, product } = serializePublicRestockingItem(restockingItem);
  return {
    sku,
    product: product
      ? {
          ...product,
          numberOfShelfFacings: restockingItem.product?.numberOfShelfFacings ?? null,
          isShelvedInHandlingUnits: !!restockingItem.product?.isShelvedInHandlingUnits ?? null,
        }
      : null,
    status: restockingItem.status,
  };
};
