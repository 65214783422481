import { useCallback } from "react";

import { useAuth0 } from "@auth0/auth0-react";
import { Flex } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import shallow from "zustand/shallow";

import { useAppPWAStateStore } from "core/stores/useAppPWAStateStore";
import { useEmployeeStore } from "core/stores/useEmployeeStore";
import { useLayoutStore } from "core/stores/useLayoutStore";
import { useHubSlug } from "shared/hooks/useHubSlug";
import { TopBar } from "ui/TopBar/TopBar";
import { formatEmployeeName } from "utils/formats";

export const HEIGHT = "56px";

export function Header() {
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth0();
  const hubSlug = useHubSlug();

  const appUpdateAvailable = useAppPWAStateStore((state) => state.appUpdateAvailable);

  const { canGoBack, toggleMenu } = useLayoutStore(
    (state) => ({
      canGoBack: state.canGoBack,
      toggleMenu: state.toggleMenu,
    }),
    shallow,
  );

  const { isIdentified, firstName, lastName } = useEmployeeStore(
    (state) => ({
      isIdentified: state.isIdentified,
      firstName: state.firstName,
      lastName: state.lastName,
    }),
    shallow,
  );

  const onClickGoBack = useCallback(() => {
    navigate(-1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canGoBack]);

  const isHubAuthenticated = isAuthenticated && !isIdentified;
  const isEmployeeIdentified = isAuthenticated && isIdentified;

  return (
    <Flex
      width="100%"
      height={HEIGHT}
      minHeight={HEIGHT}
      justifyContent="space-between"
      alignItems="center"
      borderBottomWidth="1px"
      borderBottomStyle="solid"
      borderBottomColor="grey.200"
      px="s200"
      data-testid="app-header"
    >
      <TopBar
        hubName={`${hubSlug}`}
        userName={isEmployeeIdentified ? formatEmployeeName(firstName, lastName) : undefined}
        goBack={canGoBack ? onClickGoBack : undefined}
        onClickHubName={isHubAuthenticated ? toggleMenu : undefined}
        onClickUserName={isEmployeeIdentified ? toggleMenu : undefined}
        showUserNameNotification={appUpdateAvailable}
      />
    </Flex>
  );
}
