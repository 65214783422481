import { ReactElement, useCallback } from "react";

import { useNavigate } from "react-router";
import shallow from "zustand/shallow";

import { routes } from "config/routes";
import { useAppBackgroundEffectsStore } from "core/stores/useAppBackgroundEffectsStore";
import { TabBarItemID, useLayoutStore } from "core/stores/useLayoutStore";
import { BottomTabBarContainer } from "ui/BottomTabBarContainer";
import { BottomTabBarItem } from "ui/BottomTabBarItem/BottomTabBarItem";
import { ActivitiesIcon, InboundIcon, InventoryIcon, PickingIcon } from "ui/Icons/Icons";

type TabBarLinkProps = {
  id: TabBarItemID;
  icon: ReactElement;
  title: string;
  showNotificationTag?: boolean;
  to: string;
  testId?: string;
};

const bottomTabBarItems: TabBarLinkProps[] = [
  {
    id: "activities",
    title: "Activities",
    testId: "tab-item-activities",
    icon: <ActivitiesIcon />,
    to: routes.activities.root,
  },
  {
    id: "inbound",
    title: "Inbound",
    testId: "tab-item-inbound",
    icon: <InboundIcon />,
    to: routes.inbound.root,
  },
  {
    id: "inventory",
    title: "Inventory",
    testId: "tab-item-inventory",
    icon: <InventoryIcon />,
    to: routes.inventory.root,
  },
  {
    id: "picking",
    title: "Picking",
    testId: "tab-item-picking",
    icon: <PickingIcon />,
    to: routes.picking.root,
  },
];

function TabBarLink({ to, isActive, ...rest }: TabBarLinkProps & { isActive: boolean }) {
  const navigate = useNavigate();

  return (
    <BottomTabBarItem
      {...rest}
      isActive={isActive}
      onClick={() => navigate(to, { replace: true })}
    />
  );
}

export function TabBar() {
  const { withTabBar, highlightedTab } = useLayoutStore(
    (state) => ({
      withTabBar: state.withTabBar,
      highlightedTab: state.highlightedTab,
    }),
    shallow,
  );

  const [hasOpenUrgentChecks, publicRestockingList, hasNextOrderForPicking] =
    useAppBackgroundEffectsStore(
      (state) => [
        state.hasOpenUrgentChecks,
        state.publicRestockingList,
        !!state.summaryOfNextOrderForPicking,
      ],
      shallow,
    );

  const shouldShowNotificationTagForItem = useCallback(
    (item: TabBarLinkProps) => {
      switch (item.id) {
        case "picking": {
          return hasNextOrderForPicking;
        }
        case "inventory": {
          return (
            hasOpenUrgentChecks ||
            (publicRestockingList?.getPublicRestockingList.publicRestockingList.restockingItems
              ?.length ?? 0) > 0
          );
        }
        default:
          return undefined;
      }
    },
    [hasNextOrderForPicking, hasOpenUrgentChecks, publicRestockingList],
  );

  if (!withTabBar) {
    return null;
  }

  return (
    <BottomTabBarContainer>
      {bottomTabBarItems.map((item) => (
        <TabBarLink
          key={item.id}
          {...item}
          showNotificationTag={shouldShowNotificationTagForItem(item)}
          isActive={highlightedTab === item.id}
          data-testid="bottom-bar-notification"
        />
      ))}
    </BottomTabBarContainer>
  );
}
