import { useCallback, useMemo } from "react";

import { deserializeListVerificationCheck } from "../models/listVerificationCheck/deserializer";
import {
  ListVerificationCheck,
  SerializedListVerificationCheck,
} from "../models/listVerificationCheck/types";
import { useInboundStore } from "../stores/inboundStore/useInboundStore";

export function useSortedActiveVerificationChecks(): ListVerificationCheck[] {
  const { inboundUnitsSortedByDate, verificationChecks } = useInboundStore((state) => ({
    inboundUnitsSortedByDate: state.inboundUnitsSortedByDate,
    verificationChecks: state.verificationChecks,
  }));

  const sortVerificationChecks = useCallback(
    (check1: SerializedListVerificationCheck, check2: SerializedListVerificationCheck) => {
      if (check1.isCompleted) {
        return check2.isCompleted ? 0 : -1;
      }
      if (check2.isCompleted) {
        return 1;
      }
      return (
        inboundUnitsSortedByDate.indexOf(check1.sku) - inboundUnitsSortedByDate.indexOf(check2.sku)
      );
    },
    [inboundUnitsSortedByDate],
  );

  const activeListVerificationChecks = useMemo(() => {
    return verificationChecks
      .filter((check) => check.isActive)
      .sort(sortVerificationChecks)
      .map(deserializeListVerificationCheck);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verificationChecks]);

  return activeListVerificationChecks;
}
