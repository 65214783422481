import { useCallback } from "react";

import { EndDroppingProcessButton } from "flows/Inbound/components/EndDroppingProcessButton";
import { useSelectDroppingListActiveInboundUnit } from "flows/Inbound/hooks/useInboundMachineSelectors";
import { useInboundService } from "flows/Inbound/hooks/useInboundService";
import { useCustomToast } from "shared/hooks/useCustomToast";
import { BOTTOM_TAB_BAR_HEIGHT_NUMBER } from "ui/BottomTabBarContainer";
import { isNullOrUndefined } from "utils/tsHelpers";

export function FinishDroppingButton() {
  const { showToastUI } = useCustomToast();

  const inboundService = useInboundService();
  const activeInboundUnit = useSelectDroppingListActiveInboundUnit();

  const onClickFinishDropping = useCallback(() => {
    inboundService.send({ type: "FINISH_DROPPING" });
    showToastUI({
      title: "pages.inbound.inbound-dropping-list.finish-dropping-success-toast-title",
      status: "success",
      variant: "success",
      duration: 2000,
      containerStyle: {
        width: "100%",
        bottom: BOTTOM_TAB_BAR_HEIGHT_NUMBER,
        position: "absolute",
        paddingLeft: "s200",
        paddingRight: "s200",
      },
    });
  }, [inboundService, showToastUI]);

  const isDroppingComplete = isNullOrUndefined(activeInboundUnit);

  return (
    <EndDroppingProcessButton
      shouldShowButton={isDroppingComplete}
      labelMessageId="pages.inbound.inbound-dropping-list.finish-dropping-button-label"
      onClickButton={onClickFinishDropping}
      buttonTestId="finish-dropping-button"
      bottom={0}
    />
  );
}
