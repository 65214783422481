import { useCallback, useMemo, memo } from "react";

import { EppoFeatureFlags } from "core/types/flags";
import {
  useSelectInboundUnitStockData,
  useSelectInboundUnitListItemDataProps,
} from "flows/Inbound/hooks/useInboundMachineSelectors";
import { useInboundService } from "flows/Inbound/hooks/useInboundService";
import { useEppoFeatureFlagProvider } from "shared/hooks/useEppoFeatureFlag";
import {
  InboundDroppingListItemCard,
  InboundDroppingListItemCardTranslatedLabelsProps,
} from "ui/InboundDroppingListItemCard/InboundDroppingListItemCard";

type DroppingInboundUnitListElemProps = {
  sku: string;
  onClickCard?: () => void;
  handleTapOnCardBanner: () => void;
  translatedLabels: InboundDroppingListItemCardTranslatedLabelsProps;
  elementRef: React.RefObject<HTMLDivElement>;
};

function DroppingListInboundUnitListItemComponent({
  sku,
  translatedLabels,
  elementRef,
  handleTapOnCardBanner,
}: DroppingInboundUnitListElemProps) {
  const inboundService = useInboundService();
  const inboundItemDataProps = useSelectInboundUnitListItemDataProps(sku);
  const {
    displayQuantity,
    inboundQuantity,
    stockUpdatePlanTotal,
    stockUpdated,
    unitSizeForDisplay,
    stockOnShelf,
  } = useSelectInboundUnitStockData(sku);

  const updateProductStock = useCallback(() => {
    inboundService.send({
      type: "UPDATE_INBOUND_UNIT_STOCK",
      sku,
    });
  }, [inboundService, sku]);

  const onClickCard = useCallback(() => {
    const isUpdateInboundUnitStockLoading = inboundService
      .getSnapshot()
      .matches("updateInboundUnitStock.loading");
    if (isUpdateInboundUnitStockLoading || stockUpdated) {
      return;
    }
    handleTapOnCardBanner();
    updateProductStock();
  }, [inboundService, stockUpdated, handleTapOnCardBanner, updateProductStock]);

  const isOutbounded = stockUpdatePlanTotal === 0;

  const isPartiallyOutbounded = useMemo(
    () => stockUpdatePlanTotal < (inboundQuantity ?? 0) && !isOutbounded,
    [stockUpdatePlanTotal, inboundQuantity, isOutbounded],
  );
  const { isFeatureEnabled: isProductFacingEnabled } = useEppoFeatureFlagProvider(
    EppoFeatureFlags.PRODUCT_FACING,
  );

  return (
    <InboundDroppingListItemCard
      stockUpdatePlanTotal={stockUpdatePlanTotal}
      onClickCard={onClickCard}
      ref={elementRef}
      displayQuantity={displayQuantity}
      isStockUpdateComplete={stockUpdated}
      displayAsHandlingUnit={unitSizeForDisplay > 1}
      isOutbounded={isOutbounded}
      isPartiallyOutbounded={isPartiallyOutbounded}
      stockOnShelf={stockOnShelf}
      shouldShowProductFacing={!!isProductFacingEnabled}
      {...translatedLabels}
      {...inboundItemDataProps}
    />
  );
}

export const DroppingListInboundUnitListItem = memo(DroppingListInboundUnitListItemComponent);

DroppingListInboundUnitListItem.displayName = "DroppingListInboundUnitListItem";
