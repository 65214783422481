import React from "react";

import ReactDOM from "react-dom/client";
import { ErrorBoundary } from "react-error-boundary";

import App from "core/components/App";

import reportWebVitals from "./reportWebVitals";

function ErrorFallback() {
  return (
    <div
      role="alert"
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: "24px 8px",
        textAlign: "center",
        height: "100%",
      }}
    >
      <div>
        <h3> If you are seeing this screen, probably something went wrong.</h3>
        <h4>Please follow these steps:</h4>
      </div>
      <div style={{ textAlign: "left", maxWidth: "600px" }}>
        <ol>
          <li>
            <strong>Force quit the HubOne app:</strong> Long press the HubOne icon → App settings →
            Quit. Then, reopen the app.
          </li>
          <li>
            <strong>Clear HubOne cache:</strong> Long press the HubOne icon → App settings → Storage
            & Cache → Clear cache. *If the issue persists, proceed to the next step.
          </li>
          <li>
            <strong>Remove the battery:</strong> Take out the battery from your device.
          </li>
          <li>
            <strong>Update Chrome:</strong> Reinsert the battery, open Chrome, and check for
            updates.
          </li>
          <li>
            <strong>Quit Chrome:</strong> Long press the Chrome thumbnail → App settings → Quit.
          </li>
          <li>
            <strong>Restart HubOne:</strong> Close and reopen the HubOne app.
          </li>
        </ol>
        <ul>
          <li>
            <strong>Alternative cache clearing:</strong> If the problem persists, go back to step 2
            but choose “Delete Storage” instead of “Clear Cache.” Then, force quit and reopen the
            app.
          </li>
          <li>
            <strong>Clear Chrome data:</strong> If the issue is still not resolved, clear both the
            cache and storage of Google Chrome. <u>Note: This will erase saved passwords.</u>
          </li>
        </ul>
      </div>
    </div>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <React.StrictMode>
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <App />
    </ErrorBoundary>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
