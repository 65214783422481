import { MouseEvent } from "react";

export function preventPropagation(callback: (() => void) | undefined) {
  return (event: MouseEvent) => {
    event.stopPropagation();
    if (callback) {
      callback();
    }
  };
}

export function preventScroll(event: WheelEvent | TouchEvent) {
  if (event.cancelable) {
    event.preventDefault();
    event.stopPropagation();
  }

  return false;
}

export function preventVerticalScroll(event: WheelEvent) {
  const { deltaY } = event;

  // Prevent vertical scrolling (up and down)
  if (Math.abs(deltaY) > 0 && event.cancelable) {
    event.preventDefault();
  }
}

export const createPreventVerticalTouchMove = () => {
  let touchStartY = 0;
  let touchStartX = 0;

  const preventVerticalTouchMove = (event: TouchEvent) => {
    const touchY = event.touches[0].clientY;
    const touchX = event.touches[0].clientX;

    // Check if the touch movement is mostly vertical
    if (event.cancelable && Math.abs(touchY - touchStartY) > Math.abs(touchX - touchStartX)) {
      event.preventDefault();
    }
  };

  const touchStartHandler = (event: TouchEvent) => {
    touchStartY = event.touches[0].clientY;
    touchStartX = event.touches[0].clientX;
  };

  return { touchStartHandler, preventVerticalTouchMove };
};
