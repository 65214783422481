import { forwardRef } from "react";

import { Button as ChakraButton, ButtonProps as ChakraButtonProps } from "@chakra-ui/react";

const defaultStyle: ChakraButtonProps = {
  backgroundColor: "white",
  color: "grey.800",
};

const activeStyle: ChakraButtonProps = {
  backgroundColor: "pinkFlink.100",
  color: "pinkFlink.500",
};

const hoverStyle: ChakraButtonProps = {
  backgroundColor: "pinkFlink.200",
  color: "pinkFlink.500",
};

export type ChipState = "default" | "active" | "hover";

const buttonStyleMap: Record<ChipState, ChakraButtonProps> = {
  default: defaultStyle,
  active: activeStyle,
  hover: hoverStyle,
};

export type ChipProps = {
  state: ChipState;
} & ChakraButtonProps;

export const Chip = forwardRef<HTMLButtonElement, ChipProps>(
  ({ children, state, ...rest }: ChipProps, ref) => {
    const buttonStyle = buttonStyleMap[state];
    return (
      <ChakraButton
        ref={ref}
        {...buttonStyle}
        colorScheme="pinkFlink"
        whiteSpace="normal"
        {...rest}
      >
        {children}
      </ChakraButton>
    );
  },
);
