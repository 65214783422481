import create from "zustand";

export type AppDisplayMode = "browser" | "standalone" | "fullscreen";

export type RestockingProductSearchStore = {
  initialSearchQuery: string | null;
};

type RestockingProductSearchStoreWithMethods = RestockingProductSearchStore & {
  setInitialSearchQuery(query: string | null): void;
};

const initialState: RestockingProductSearchStore = {
  initialSearchQuery: null,
};

export const useRestockingProductSearchStore = create<RestockingProductSearchStoreWithMethods>()(
  (set) => ({
    ...initialState,
    setInitialSearchQuery(initialSearchQuery) {
      set({ initialSearchQuery });
    },
  }),
);
