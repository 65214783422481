import { shallowEqual, useSelector } from "@xstate/react";

import {
  selectDroppingListActiveInboundUnitFromContext,
  selectDroppingListInboundUnitsSkusSortedByShelfFromContext,
  selectInboundUnitDataBySku,
  selectInboundUnitStockDataBySku,
  selectInboundUnitStockUpdateStateBySku,
  selectActiveListVerificationChecksFromContext,
} from "flows/Inbound/machines/inboundMachine/selector";
import { useCountryNames } from "shared/hooks/useCountryNames";

import { TranslatedProductSearchUnit } from "../../../shared/models/productSearchUnit/types";
import { useInboundService } from "./useInboundService";

export function useSelectPreDroppingListInboundUnitsSkusSortedByAddedDate() {
  const inboundService = useInboundService();

  return useSelector(
    inboundService,
    (state) => state.context.preDroppingListInboundUnitsSkusSortedByAddedDate,
    (a, b) => JSON.stringify(a) === JSON.stringify(b),
  );
}

export function useSelectDroppingListInboundUnitsSkusSortedByShelf() {
  const inboundService = useInboundService();

  return useSelector(
    inboundService,
    (state) => selectDroppingListInboundUnitsSkusSortedByShelfFromContext(state.context),
    (a, b) => JSON.stringify(a) === JSON.stringify(b),
  );
}

export function useSelectInboundUnitListItemDataProps(sku: string): TranslatedProductSearchUnit {
  const inboundService = useInboundService();

  const inboundUnit = useSelector(
    inboundService,
    selectInboundUnitDataBySku(sku),
    (a, b) => JSON.stringify(a) === JSON.stringify(b),
  );
  const countryOfOriginName = useCountryNames(inboundUnit?.countryOfOriginCode ?? null);

  return {
    ...inboundUnit,
    countryOfOriginName,
  };
}

export function useSelectInboundUnitStockData(sku: string) {
  const inboundService = useInboundService();
  return useSelector(inboundService, selectInboundUnitStockDataBySku(sku), shallowEqual);
}

export function useSelectInboundUnitListItemStockUpdateState(sku: string) {
  const inboundService = useInboundService();
  return useSelector(inboundService, selectInboundUnitStockUpdateStateBySku(sku));
}

export function useSelectDroppingListActiveInboundUnit() {
  const inboundService = useInboundService();

  return useSelector(
    inboundService,
    (state) => selectDroppingListActiveInboundUnitFromContext(state.context),
    (a, b) => JSON.stringify(a) === JSON.stringify(b),
  );
}

export function useSelectActiveListVerificationChecks() {
  const inboundService = useInboundService();
  return useSelector(inboundService, (state) =>
    selectActiveListVerificationChecksFromContext(state.context),
  );
}

export function useSelectDespatchAdviceItems() {
  const inboundService = useInboundService();
  return useSelector(inboundService, (state) => state.context.despatchAdviceItems);
}
