import * as Types from "../../../../../../__graphql__/types.js";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type ValidateFreshnessCheckMutationVariables = Types.Exact<{
  input: Types.ValidateFreshnessCheckInput;
}>;

export type ValidateFreshnessCheckMutation = {
  __typename?: "Mutation";
  validateFreshnessCheck: { __typename?: "CheckMutationResponse"; success: boolean };
};

export const ValidateFreshnessCheckDocument = gql`
  mutation ValidateFreshnessCheck($input: ValidateFreshnessCheckInput!) {
    validateFreshnessCheck(input: $input) {
      success
    }
  }
`;
export type ValidateFreshnessCheckMutationFn = Apollo.MutationFunction<
  ValidateFreshnessCheckMutation,
  ValidateFreshnessCheckMutationVariables
>;

/**
 * __useValidateFreshnessCheckMutation__
 *
 * To run a mutation, you first call `useValidateFreshnessCheckMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidateFreshnessCheckMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validateFreshnessCheckMutation, { data, loading, error }] = useValidateFreshnessCheckMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useValidateFreshnessCheckMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ValidateFreshnessCheckMutation,
    ValidateFreshnessCheckMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ValidateFreshnessCheckMutation,
    ValidateFreshnessCheckMutationVariables
  >(ValidateFreshnessCheckDocument, options);
}
export type ValidateFreshnessCheckMutationHookResult = ReturnType<
  typeof useValidateFreshnessCheckMutation
>;
export type ValidateFreshnessCheckMutationResult =
  Apollo.MutationResult<ValidateFreshnessCheckMutation>;
export type ValidateFreshnessCheckMutationOptions = Apollo.BaseMutationOptions<
  ValidateFreshnessCheckMutation,
  ValidateFreshnessCheckMutationVariables
>;
