import { generatePath } from "react-router";

import { TaskType } from "__graphql__/types";
import { routes } from "config/routes";

export function getInitialCheckUrl({ type, checkId }: { type: TaskType; checkId: string }) {
  let url = "";
  switch (type) {
    case TaskType.stock_check: {
      url = generatePath(routes.inventory.stockChecks.quantity, { checkId });
      break;
    }
    case TaskType.freshness_check: {
      url = generatePath(routes.inventory.freshChecks.quantity, { checkId });
      break;
    }
    case TaskType.bbd_check: {
      url = generatePath(routes.inventory.bbdChecks.declareExpired, { checkId });
      break;
    }
    case TaskType.eoy_inventory_check: {
      url = generatePath(routes.inventory.eoyChecks.detail, { checkId });
      break;
    }
    case TaskType.eoy_inventory_recount_check: {
      url = generatePath(routes.inventory.eoyRecountChecks.detail, { checkId });
      break;
    }
    default: {
      break;
    }
  }
  return url;
}
