import { forwardRef, useMemo } from "react";

import { Box, BoxProps, Flex, Grid } from "@chakra-ui/react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router";

import { routes } from "config/routes";
import { TranslatedRestockingItem } from "flows/Inventory/flows/RestockingList/models/restockingItem/types";
import { useRestockingServiceStore } from "flows/Inventory/flows/RestockingList/stores/restockingServiceStore";
import { Button } from "ui/Button/Button";
import { TapIcon } from "ui/Icons/Icons";
import { InboundFruitOrVeggyChips } from "ui/InboundFruitOrVeggyChips/InboundFruitOrVeggyChips";
import { ProductFacing } from "ui/ProductFacing/ProductFacing";
import { ProductInformation } from "ui/ProductInformation/ProductInformation";
import { Shelf } from "ui/Shelf/Shelf";
import { Spinner } from "ui/Spinner/Spinner";
import { Status } from "ui/Status/Status";
import { StockPill } from "ui/StockPill/StockPill";
import { HeaderS, TitleS } from "ui/Typography/Typography";
import { isNotNullNorUndefined } from "utils/tsHelpers";

export type PrivateRestockingListItemCardTranslatedLabelsProps = {
  bioLabel: string;
  nonBioLabel: string;
  inStockLabel: string;
  backOnShelfLabel: string;
  shouldShowProductFacing?: boolean;
};

export type PrivateRestockingListItemCardProps = {
  restockingItem: TranslatedRestockingItem;
  isRestockingComplete: boolean;
  isBlocked: boolean;
  isClickable: boolean;
  isLoading?: boolean;
  onClickCard: () => void;
} & PrivateRestockingListItemCardTranslatedLabelsProps &
  BoxProps;

export const PrivateRestockingListItemCard = forwardRef<
  HTMLDivElement,
  PrivateRestockingListItemCardProps
>(
  (
    {
      restockingItem: { product, sku },
      isRestockingComplete,
      isBlocked,
      isClickable,
      isLoading = false,
      onClickCard,
      bioLabel,
      nonBioLabel,
      inStockLabel,
      backOnShelfLabel,
      shouldShowProductFacing = false,
      ...rest
    },
    ref,
  ) => {
    const {
      sku: productSku,
      name,
      imageUrl,
      shelfLetter,
      shelfNumber,
      countryOfOriginName,
      isBio,
      stockOnShelf,
      numberOfShelfFacings,
      isShelvedInHandlingUnits,
    } = product ?? {};
    const isFruitOrVeggy = isNotNullNorUndefined(countryOfOriginName);
    const navigate = useNavigate();

    const { setUseRestockingServiceStore } = useRestockingServiceStore((state) => ({
      setUseRestockingServiceStore: state.setUseRestockingServiceStore,
    }));

    const displayedShelf =
      isNotNullNorUndefined(shelfLetter) && isNotNullNorUndefined(shelfNumber) ? (
        <Shelf gridColumnStart="3" letter={shelfLetter} number={shelfNumber} />
      ) : (
        <Status bg="grey.200" textColor="black" gridColumnStart="3" label="N/A" />
      );

    const displayedStatus = useMemo(() => {
      if (isRestockingComplete) {
        return <Status bg="veggie.100" textColor="black" label={backOnShelfLabel} />;
      }
      if (isFruitOrVeggy) {
        return (
          <InboundFruitOrVeggyChips
            bioLabel={bioLabel}
            nonBioLabel={nonBioLabel}
            countryOfOriginName={countryOfOriginName}
            isBio={!!isBio}
          />
        );
      }
      return null;
    }, [
      isRestockingComplete,
      backOnShelfLabel,
      isFruitOrVeggy,
      bioLabel,
      nonBioLabel,
      isBio,
      countryOfOriginName,
    ]);

    const goToStockCorrection = () => {
      setUseRestockingServiceStore({
        isNavigatingToStockCorrection: true,
        productName: product?.name ?? "",
      });
      navigate(routes.inventory.stockCorrections.declare);
    };

    const bottomLeftElement = useMemo(() => {
      if (shouldShowProductFacing) {
        return isClickable ? (
          <ProductFacing
            isShelvedInHandlingUnits={!!isShelvedInHandlingUnits}
            numberOfFacings={numberOfShelfFacings ?? 0}
          />
        ) : (
          <Flex mt="s25" py="6px" justifyContent="end">
            <StockPill stock={stockOnShelf} customBgColor="grey.100" />
          </Flex>
        );
      }
      if (isLoading) return <Spinner size="md" ml="s100" mt="s100" />;
      if (isRestockingComplete || !isClickable) {
        return null;
      }
      return <TapIcon data-testid="product-tap-icon" h="32px" w="32px" ml="s100" />;
    }, [
      shouldShowProductFacing,
      isLoading,
      isRestockingComplete,
      isClickable,
      isShelvedInHandlingUnits,
      numberOfShelfFacings,
      stockOnShelf,
    ]);

    return (
      <Box
        onClick={isClickable && !isLoading && !isRestockingComplete ? onClickCard : () => {}}
        p="s200"
        bg="white"
        w="100%"
        filter={isBlocked ? "grayscale(100%)" : undefined}
        ref={ref}
        data-testid="restocking-list-item-card"
        {...rest}
      >
        <Grid mb="s200" templateColumns="min-content 1fr min-content">
          {displayedStatus}
          {displayedShelf}
        </Grid>
        <ProductInformation
          bg="grey.100"
          name={name ?? ""}
          sku={sku || productSku}
          imageUrl={imageUrl ?? ""}
          showLeftSideBar={isRestockingComplete}
          leftSideBarProps={{ bgColor: "veggie.500" }}
          showSKU={!isClickable}
          stock={isClickable ? stockOnShelf : undefined}
          variantSize="sm"
        />

        {shouldShowProductFacing ? (
          <> {bottomLeftElement}</>
        ) : (
          <Flex justifyContent="space-between" mt="s200" h="64px">
            <Flex direction="column">
              <Box h="24px" />
              <Flex justifyContent="flex-start" alignItems="center">
                {bottomLeftElement}
              </Flex>
            </Flex>
            <Flex gap="s200">
              <Flex direction="column" alignItems="center">
                <TitleS mb="s100" color="grey.800">
                  {inStockLabel}
                </TitleS>
                <HeaderS data-testid="product-stock-on-shelf">{stockOnShelf ?? "N/A"}</HeaderS>
              </Flex>
            </Flex>
          </Flex>
        )}

        <Button
          onClick={goToStockCorrection}
          flinkVariant="secondary"
          marginY="s100"
          height="36px"
          width="100%"
        >
          <FormattedMessage id="pages.restockingList.privateRestockingListItemCard.cta-button" />
        </Button>
      </Box>
    );
  },
);
