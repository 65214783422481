import { Box } from "@chakra-ui/react";

import { useInboundUIStore } from "flows/Inbound/stores/useInboundUIStore";

export function PreDroppingInboundUnitsListBottomSpace() {
  const isEditingQuantity = useInboundUIStore((state) => state.isEditingQuantity);

  // This box helps us having the EndDroppingProcessButton not overflowing bottom content
  // The larger box that is displayed when editing a quantity field helps up display scroll to
  // the right position and prevent the visual viewport from being automatically pushed out the
  // top of the screen when the on-screen keyboard would otherwise obscure the input.
  return <Box h={isEditingQuantity ? "1000px" : "56px"} />;
}
