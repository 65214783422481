import { useCallback } from "react";

import { useSelector } from "@xstate/react";

import { PageName } from "analytics/events";
import { useInboundService } from "flows/Inbound/hooks/useInboundService";
import { useInboundUIStore } from "flows/Inbound/stores/useInboundUIStore";
import { useAnalytics } from "shared/hooks/useAnalytics";
import { useCustomToast } from "shared/hooks/useCustomToast";
import { ProductSearchUnit } from "shared/models/productSearchUnit/types";

import {
  ActionChooseUnit,
  ProductSearchMachineContext,
  ProductSearchOrigin,
  ServiceDoneInvokeEvents,
} from "./types";

const SEARCH_UNIT_BY_EAN_REQUEST_ERROR_TOAST_ID = "search_unit_by_ean_request_error";

export function useProductSearchActionsImplems() {
  const { sendSegmentTrackEvent } = useAnalytics();
  const setInboundUIState = useInboundUIStore((state) => state.setInboundUIState);
  const inboundService = useInboundService();
  const { showToastUI } = useCustomToast();

  const hasUnconfirmedQuantities = useSelector(
    inboundService,
    (state) => !!state.context.skusWithUnconfirmedQuantity.length,
  );

  const sendInboundUnitsToInboundMachine = (
    _: ProductSearchMachineContext,
    event: ActionChooseUnit | ServiceDoneInvokeEvents["fetchUnitsByEAN"],
  ) => {
    const { inboundUnitsStockUpdateState } = inboundService.getSnapshot().context;
    let origin: ProductSearchOrigin | undefined;
    let newInboundUnits: ProductSearchUnit[] = [];
    if ("data" in event && event.data?.results.length === 1) {
      // event.data is an array of InboundUnits of length 1
      newInboundUnits = event.data.results;
      origin = event.data.origin;
    } else if ("inboundUnits" in event) {
      newInboundUnits = (event as ActionChooseUnit).inboundUnits;
      origin = event.origin;
    }
    if (!newInboundUnits.length || !origin) {
      return;
    }
    newInboundUnits.forEach((newInboundUnit) => {
      const sku = newInboundUnit.productSku;
      if (inboundUnitsStockUpdateState[sku]) {
        // If the new inbound unit is already in the pre-dopping list, we should only
        // update its quantity
        inboundService.send({
          type: "INCREASE_INBOUND_UNIT_INBOUND_QUANTITY",
          sku,
          origin: origin as ProductSearchOrigin,
          quantity: newInboundUnit.unitType === "handling" ? newInboundUnit.quantity : 1,
        });
        return;
      }
      inboundService.send({
        type: "ADD_INBOUND_UNIT_TO_DROPPING_LIST",
        inboundUnit: newInboundUnit,
        origin: origin as ProductSearchOrigin,
      });
    });
  };

  const onSearchUnitsByEanError = () => {
    sendSegmentTrackEvent("errorShown", {
      screen_name: PageName.INBOUND_PAGE,
      component_value: "scan_request_failed",
    });
    showToastUI({
      id: SEARCH_UNIT_BY_EAN_REQUEST_ERROR_TOAST_ID,
      title: "pages.inbound.inbound-index.request-failed-title",
      description: "pages.inbound.inbound-index.request-failed-description",
    });
  };

  const onEanSearchNoResult = (
    _: ProductSearchMachineContext,
    event: ServiceDoneInvokeEvents["fetchUnitsByEAN"],
  ) => {
    setInboundUIState({ showScanningFailedModal: true });
    sendSegmentTrackEvent("errorShown", {
      screen_name: PageName.INBOUND_PAGE,
      component_value: "scanning_failed",
      component_content: event.data.searchedValue,
    });
  };

  const putInboundMachineIntoManualSearchMode = useCallback(() => {
    inboundService.send({ type: "START_MANUAL_SEARCH" });
  }, [inboundService]);

  const ejectInboundMachineFromManualSearch = useCallback(() => {
    inboundService.send({ type: "CANCEL_MANUAL_SEARCH" });
  }, [inboundService]);

  const showUnverifiedQuantityModal = () => {
    if (hasUnconfirmedQuantities) {
      setInboundUIState({ isUnverifiedQuantityModalOpen: true });
    }
  };

  return {
    sendInboundUnitsToInboundMachine,
    onSearchUnitsByEanError,
    onEanSearchNoResult,
    putInboundMachineIntoManualSearchMode,
    ejectInboundMachineFromManualSearch,
    showUnverifiedQuantityModal,
  };
}
