import { useSelector } from "@xstate/react";

import {
  selectCurrentItem,
  selectItemsSortedByShelf,
  selectOrderDetail,
} from "../machines/pickingMachine/selector";
import { usePickingService } from "./usePickingService";

export function useSelectItemsSortedByShelf() {
  const pickingService = usePickingService();

  return useSelector(
    pickingService,
    selectItemsSortedByShelf,
    (a, b) => JSON.stringify(a) === JSON.stringify(b),
  );
}

export function useSelectCurrentItem() {
  const pickingService = usePickingService();
  return useSelector(
    pickingService,
    selectCurrentItem,
    (a, b) => JSON.stringify(a) === JSON.stringify(b),
  );
}

export function useSelectPickedOrder() {
  const pickingService = usePickingService();
  return useSelector(pickingService, (state) => state.context.order);
}

export function useGiftQuantites() {
  const pickingService = usePickingService();

  return useSelector(pickingService, (state) => ({
    picked: state.context.pickedGiftsQuantity,
    total: state.context.totalGiftsQuantity,
  }));
}

export function useCampaignQuantites() {
  const pickingService = usePickingService();

  return useSelector(pickingService, (state) => ({
    picked: state.context.pickedCampaignsQuantity,
    total: state.context.totalCampaignsQuantity,
  }));
}

export function useRemoveFlyersQuantites() {
  const pickingService = usePickingService();

  return useSelector(pickingService, (state) => ({
    picked: state.context.pickedRemoveFlyersQuantity,
    total: state.context.totalRemoveFlyersQuantity,
  }));
}

export function useSelectOrderDetails() {
  const pickingService = usePickingService();

  return useSelector(pickingService, selectOrderDetail);
}
