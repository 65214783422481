import { createContext, useContext, useMemo } from "react";

import { useInterpret } from "@xstate/react";
import { Outlet } from "react-router-dom";
import { InterpreterFrom } from "xstate";

import { useInterpreterRouteSynchronizer } from "shared/hooks/useInterpreterRouteSynchronizer";
import { keepTrackOfStateForDebugging } from "utils/xstate";

import { usePickingActionsImplems } from "../machines/pickingMachine/actions";
import { PICKING_MACHINE_NAME, pickingMachine } from "../machines/pickingMachine/machine";
import { usePickingServiceImplems } from "../machines/pickingMachine/services";
import { PickingEvents, PickingMachineContext } from "../machines/pickingMachine/types";

export const XstatePickingContext = createContext({
  pickingService: {} as InterpreterFrom<typeof pickingMachine>,
});

export function XStatePickingProvider() {
  const pickingActionsImplems = usePickingActionsImplems();
  const pickingServicesImplems = usePickingServiceImplems();

  const pickingService = useInterpret(
    pickingMachine,
    {
      actions: pickingActionsImplems,
      services: pickingServicesImplems,
      guards: {},
    },
    ({ value }) => keepTrackOfStateForDebugging("picking", value),
  );

  useInterpreterRouteSynchronizer<PickingMachineContext, PickingEvents>(
    PICKING_MACHINE_NAME,
    pickingService,
  );

  const values = useMemo(
    () => ({
      pickingService,
    }),
    [pickingService],
  );

  return (
    <XstatePickingContext.Provider value={values}>
      <Outlet />
    </XstatePickingContext.Provider>
  );
}

export function usePickingServiceContext() {
  return useContext(XstatePickingContext);
}
