import { useCallback } from "react";

import dayjs from "dayjs";
import { DoneInvokeEvent } from "xstate";

import { BbdDateCorrectionOrigin } from "analytics/events";
import { useAnalytics } from "shared/hooks/useAnalytics";
import { useCustomToast } from "shared/hooks/useCustomToast";
import { useErrorToastWithAudio } from "shared/hooks/useErrorToastWithAudio";

import { BbdCorrectionMachineContext, ServiceReturnTypes } from "./types";

const PRODUCT_BBD_UPDATE_SUCCESS_TOAST_ID = "product_bbd_update_success_toast_id";

export function useBbdCorrectionActionsImplems() {
  const { showToastUI } = useCustomToast();

  const { sendSegmentTrackEvent } = useAnalytics();
  const { showToastAndPlayError } = useErrorToastWithAudio({
    errorToastId: "bbd_correction_error",
  });

  const errorToast = useCallback(() => {
    showToastAndPlayError({
      title: "flows.inventory.bbd-corrections-machines.actions.error-toast.title",
    });
  }, [showToastAndPlayError]);

  const updateProductBbdSuccessToast = useCallback(() => {
    showToastUI({
      id: PRODUCT_BBD_UPDATE_SUCCESS_TOAST_ID,
      status: "success",
      title:
        "flows.inventory.bbd-corrections-machines.actions.product-bbd-update-success-toast-id.title",
    });
  }, [showToastUI]);

  const sendBbdDateCorrectionStartedEvent = useCallback(
    (ctx: BbdCorrectionMachineContext) => {
      const origin: BbdDateCorrectionOrigin =
        ctx.scanType === "search" ? "search_bar" : "scanned_product";
      sendSegmentTrackEvent("bbdDateCorrectionStarted", {
        origin,
        product_sku: ctx.selectedInventoryProductSku as string,
        shelf_number: ctx.selectedInventoryProductShelf ?? "none",
      });
    },
    [sendSegmentTrackEvent],
  );

  const sendBbdDateCorrectionFinishedEvent = useCallback(
    (
      ctx: BbdCorrectionMachineContext,
      event: DoneInvokeEvent<ServiceReturnTypes["updateProductBbd"]>,
    ) => {
      const nextExpirationDateAfter = dayjs(event.data.closestBbd).format("YYYY-MM-DD");
      const nextExpirationDateBefore = event.data.initialClosestBbd
        ? dayjs(event.data.initialClosestBbd).format("YYYY-MM-DD")
        : "none";
      sendSegmentTrackEvent("bbdDateCorrectionFinished", {
        product_sku: ctx.selectedInventoryProductSku as string,
        next_expiration_date_after: nextExpirationDateAfter,
        next_expiration_date_before: nextExpirationDateBefore,
      });
    },
    [sendSegmentTrackEvent],
  );

  return {
    errorToast,
    updateProductBbdSuccessToast,
    sendBbdDateCorrectionStartedEvent,
    sendBbdDateCorrectionFinishedEvent,
  };
}
