import { createContext, useEffect, useMemo } from "react";

import { useInterpret } from "@xstate/react";
import { Outlet } from "react-router";
import { InterpreterFrom } from "xstate";

import { useInboundActionImplems } from "flows/Inbound/machines/inboundMachine/actions";
import { useInboundGuardsImplems } from "flows/Inbound/machines/inboundMachine/guards";
import {
  INBOUND_MACHINE_CTX_LOCAL_STORAGE_KEY,
  INBOUND_MACHINE_NAME,
  getInitialInboundMachineContext,
  inboundMachine,
} from "flows/Inbound/machines/inboundMachine/machine";
import { useInboundServiceImplems } from "flows/Inbound/machines/inboundMachine/services";
import {
  InboundMachineContext,
  InboundingEvents,
} from "flows/Inbound/machines/inboundMachine/types";
import { useInterpreterRouteSynchronizer } from "shared/hooks/useInterpreterRouteSynchronizer";
import { keepTrackOfStateForDebugging } from "utils/xstate";

export const XStateInboundContext = createContext({
  inboundService: {} as InterpreterFrom<typeof inboundMachine>,
});

export function XStateInboundProvider() {
  const inboundServicesImplems = useInboundServiceImplems();
  const inboundActionsImplems = useInboundActionImplems();
  const inboundGuardsImplems = useInboundGuardsImplems();
  const inboundService = useInterpret(
    inboundMachine,
    {
      actions: inboundActionsImplems,
      services: inboundServicesImplems,
      guards: inboundGuardsImplems,
      context: getInitialInboundMachineContext(),
    },
    ({ value }) => keepTrackOfStateForDebugging("inbounding", value),
  );

  useEffect(() => {
    // Subscription to inboundService context changes in order to persist the context
    const sub = inboundService.subscribe((state) => {
      localStorage.setItem(INBOUND_MACHINE_CTX_LOCAL_STORAGE_KEY, JSON.stringify(state.context));
    });
    return () => {
      sub.unsubscribe();
    };
  }, [inboundService]);

  useInterpreterRouteSynchronizer<InboundMachineContext, InboundingEvents>(
    INBOUND_MACHINE_NAME,
    inboundService,
  );

  const values = useMemo(() => ({ inboundService }), [inboundService]);

  return (
    <XStateInboundContext.Provider value={values}>
      <Outlet />
    </XStateInboundContext.Provider>
  );
}
