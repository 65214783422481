import { useAuth0 } from "@auth0/auth0-react";

/**
 * Returns the hub slug if the user is authenticated, otherwise returns null.
 */
export function useHubSlug(): string | null {
  const { user } = useAuth0();

  return user?.hub_slug ?? null;
}
