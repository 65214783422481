import React, { useState } from "react";

import { FlexProps, Flex, Image } from "@chakra-ui/react";

import { TranslatedSubcategory } from "flows/Inbound/models/subcategory/types";
import { TitleXS } from "ui/Typography/Typography";

export type SubcategoryListProps = {
  subcategories: TranslatedSubcategory[];
  onClickSubcategory: (id: string, translatedName?: string) => void;
  lang: string;
} & FlexProps;

export function SubcategoryList({
  subcategories,
  onClickSubcategory,
  lang,
  ...rest
}: SubcategoryListProps) {
  const [selection, setSelection] = useState<string | null>(null);

  return (
    <Flex flexWrap="wrap" columnGap="1.75rem" px="calc((100% - 20rem)/2)" lang={lang} {...rest}>
      {subcategories.map(({ id, imageUrl, translatedName }) => (
        <Flex
          key={id}
          direction="column"
          align="center"
          w="5.5rem"
          h="8rem"
          pt="s50"
          px="s50"
          borderRadius="sm"
          backgroundColor={selection === id ? "grey.200" : undefined}
          onClick={() => {
            setSelection(id);
            onClickSubcategory(id, translatedName);
          }}
        >
          <Image
            src={imageUrl || undefined}
            alt={translatedName}
            objectFit="cover"
            w="100%"
            h="5rem"
            objectPosition="0 100%"
            borderRadius="sm"
          />
          <TitleXS
            maxW="100%"
            textOverflow="ellipsis"
            overflow="hidden"
            display="-webkit-box"
            noOfLines={2}
            style={{ hyphens: "auto" }}
            textAlign="center"
          >
            {translatedName}
          </TitleXS>
        </Flex>
      ))}
    </Flex>
  );
}
