import { Flex } from "@chakra-ui/react";
import { FormattedMessage } from "react-intl";

import { SmileyHappyIcon } from "ui/Icons/Icons";
import { BodyM, HeaderS } from "ui/Typography/Typography";

export function InboundListVerificationNoChecks() {
  return (
    <Flex direction="column" align="center" flex={1} pt="4rem">
      <HeaderS mb="s100" mt="s50">
        <FormattedMessage id="pages.inbound.list-verification.no-checks.title" />
      </HeaderS>
      <BodyM textAlign="center">
        <FormattedMessage id="pages.inbound.list-verification.no-checks.description" />
      </BodyM>
      <Flex
        w="200px"
        h="200px"
        align="center"
        justify="center"
        borderRadius="100%"
        bgColor="white"
        mt="s300"
      >
        <SmileyHappyIcon boxSize="120px" color="pinkFlink.500" />
      </Flex>
    </Flex>
  );
}
