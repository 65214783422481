import { Circle, Flex } from "@chakra-ui/react";
import { useIntl } from "react-intl";

import { TitleXS } from "ui/Typography/Typography";

export type StockPillProps = {
  stock?: number | string;
  customBgColor?: string;
};

export function StockPill({ stock, customBgColor = "white" }: StockPillProps) {
  const intl = useIntl();
  const { bg, label } = stock
    ? {
        label: intl.formatMessage({ id: "components.stock-pill.lable.stock" }, { stock }),
        bg: "veggie.500",
      }
    : {
        label: intl.formatMessage({ id: "components.stock-pill.lable.out-of-stock" }),
        bg: "red",
      };

  return (
    <Flex
      bg={customBgColor}
      borderRadius="full"
      alignItems="center"
      gap="s100"
      w="fit-content"
      px="s100"
      py="s25"
      mt="s50"
    >
      <Circle size="6px" bg={bg} />
      <TitleXS>{label}</TitleXS>
    </Flex>
  );
}
