export const fontSizes = {
  "4xl": "2rem", // 32px
  "3xl": "1.875rem", // 30px
  "2xl": "1.5rem", // 24px
  "0.5xl": "1.375rem", // 22px
  xl: "1.25rem", // 20px
  l: "1.125rem", // 18px
  m: "1rem", // 16px
  s: "0.875rem", // 14px
  xs: "0.75rem", // 12px
};
