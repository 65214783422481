import { Flex, Image, Box, useDisclosure } from "@chakra-ui/react";
import { shallowEqual, useSelector } from "@xstate/react";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router";

import { PageName } from "analytics/events";
import { BbdClosestExpirationDate } from "flows/Inventory/shared/components/BbdClosestExpirationDate";
import { DatePickerModal } from "flows/Inventory/shared/components/DatePickerModal";
import { TaskCheckHeader } from "flows/Inventory/shared/components/TaskCheckHeader";
import { useGetProductStockQuery } from "flows/Inventory/shared/queries/inventoryEntry/inventoryEntry.generated";
import { getBbdEnterableMinDate } from "flows/Inventory/shared/utils/bbd";
import { Page } from "shared/components/Page";
import { useAnalytics } from "shared/hooks/useAnalytics";
import { Button } from "ui/Button/Button";
import { BodyM, BodyS } from "ui/Typography/Typography";
import { formatImage } from "utils/image";
import { isNotNullNorUndefined, isNullOrUndefined } from "utils/tsHelpers";
import { transformChunks } from "utils/ui";

import { CheckBackstockModal } from "../../components/CheckBackstockModal";
import { SkipStepButton } from "../../components/SkipStepButton";
import { useBbdCheckService } from "../../hooks/useBbdCheckService";
import { useBbdCheckTaskData } from "../../hooks/useBbdCheckTaskData";

const IMAGE_SIZE = 187;

export function BbdCheckDeclareClosestBdd() {
  const navigate = useNavigate();
  const intl = useIntl();
  const bbdCheckService = useBbdCheckService();
  const { sendSegmentTrackEvent } = useAnalytics();

  const { origin, shelfLetter, shelfNumber, closestBbd, expiredQuantity, tooGoodToGoQuantity } =
    useSelector(bbdCheckService, ({ context }) => context, shallowEqual);

  const isUpdating = useSelector(bbdCheckService, (state) => state.matches("performCheckUpdate"));

  const {
    productImageUrl,
    productName,
    productCountryOfOriginName,
    sku,
    minDaysToBestBeforeDate,
    checkId,
  } = useBbdCheckTaskData();

  const { data, loading: expectedQuantityLoading } = useGetProductStockQuery({
    fetchPolicy: "network-only",
    variables: { sku: sku!, isAlwaysInbound: true },
    skip: !sku,
    onError: () => bbdCheckService.send({ type: "UNEXPECTED_ERROR" }),
  });

  const productStock = data?.getProduct.inventoryEntry.stock.shelf ?? 0;

  const {
    onOpen: onOpenAdjustBddModal,
    isOpen: isAdjustBddModalOpen,
    onClose: onCloseAdjustBddModal,
  } = useDisclosure();

  const {
    onOpen: onOpenCheckBackstockModal,
    isOpen: isCheckBackstockModalOpen,
    onClose: onCloseCheckBackstockModal,
  } = useDisclosure();

  const onConfirmNewExpirationDate = (newDate: Date) => {
    bbdCheckService.send({ type: "SET_CLOSEST_BBD", closestBbd: newDate });
    onCloseAdjustBddModal();
  };

  const handleUpdate = () => {
    bbdCheckService.send({ type: "UPDATE_CHECK" });
  };

  const handleSkipButtonClick = () => {
    sendSegmentTrackEvent("inAppMessageShown", {
      screen_name: PageName.INVENTORY_BBD_DECLARE_CLOSEST_BBD,
      component_name: "please_check_the_backstock",
      component_content: checkId!,
    });
    onOpenCheckBackstockModal();
  };

  const onClickOutOfStock = () => {
    sendSegmentTrackEvent("click", {
      screen_name: PageName.INVENTORY_BBD_DECLARE_CLOSEST_BBD,
      component_name: "please_check_the_backstock",
      component_value: "out_of_stock",
      component_content: checkId!,
    });
    onCloseCheckBackstockModal();
    bbdCheckService.send({ type: "SET_CREATE_CHECK_TYPE", checkType: "NO_QUANTITY" });
    handleUpdate();
  };

  const imageUrlFormatted = isNotNullNorUndefined(productImageUrl)
    ? formatImage(productImageUrl, IMAGE_SIZE, IMAGE_SIZE)
    : null;

  const minDate = getBbdEnterableMinDate(minDaysToBestBeforeDate ?? 0);

  const afterOutboundProductStock = productStock - expiredQuantity - tooGoodToGoQuantity;

  const isConfirmUpdateButtonDisabled = isUpdating || isNullOrUndefined(closestBbd);

  return (
    <Page isLoading={expectedQuantityLoading} isCentered isBgGrey isFull>
      <TaskCheckHeader
        headerMessageId="flows.inventory.checks.bbd-checks.pages.bbd-check-declare-expired.bbd-check"
        origin={origin}
        shelfLetter={shelfLetter}
        shelfNumber={shelfNumber}
        onBack={() => navigate(-1)}
      />
      <Flex direction="column" flex={1} w="100%" alignItems="center" overflowY="scroll">
        {isNotNullNorUndefined(imageUrlFormatted) && (
          <Image
            src={imageUrlFormatted}
            objectFit="contain"
            maxW={`${IMAGE_SIZE}px`}
            maxH={`${IMAGE_SIZE}px`}
          />
        )}
        <Box py="s150" px="s200" mx="s200" bg="white" borderRadius="sm" alignSelf="stretch">
          <BodyS
            w="100%"
            textAlign="center"
            color="grey.800"
            data-testid="stock-check-product-name"
          >
            {productName} {productCountryOfOriginName ? ` (${productCountryOfOriginName})` : null}
          </BodyS>
        </Box>
        <BbdClosestExpirationDate
          date={closestBbd}
          onClickAdjustBbdButton={onOpenAdjustBddModal}
          titleElement={
            <BodyM>
              <FormattedMessage
                id="flows.inventory.bbd-checks.pages.bbd-check-declare-closest-bdd.when-is-closest"
                values={transformChunks({ b: { as: "strong" } })}
              />
            </BodyM>
          }
          mt="s200"
        />
      </Flex>
      <SkipStepButton
        onClick={handleSkipButtonClick}
        label={intl.formatMessage({
          id: "flows.inventory.bbd-checks.pages.declare-closest-bbd.skip-step-button-label",
        })}
      />
      <Box
        bg="white"
        borderTopWidth="1px"
        borderTopStyle="solid"
        borderTopColor="grey.200"
        p="s200"
        width="100%"
      >
        <Button
          disabled={isConfirmUpdateButtonDisabled}
          isLoading={isUpdating}
          onClick={handleUpdate}
          width="full"
          flinkVariant="primary"
          size="lg"
          data-testid="confirm-update-button"
        >
          <FormattedMessage id="flows.inventory.bbd-checks.pages.bbd-check-declare-closest-bdd.update" />
        </Button>
      </Box>
      {isAdjustBddModalOpen && ( // usually we keep the modal in the dom but here it seems that the modal content doesn't get unmounted when closing it
        <DatePickerModal
          isOpen={isAdjustBddModalOpen}
          onClickCancel={onCloseAdjustBddModal}
          onClickConfirm={onConfirmNewExpirationDate}
          initialValue={closestBbd}
          minDate={minDate}
        />
      )}
      <CheckBackstockModal
        isOpen={isCheckBackstockModalOpen}
        onClickConfirm={onCloseCheckBackstockModal}
        onClickOutOfStock={onClickOutOfStock}
        totalUnits={afterOutboundProductStock}
      />
    </Page>
  );
}
