import { Flex, FlexProps } from "@chakra-ui/react";

import { BodyM } from "ui/Typography/Typography";

export type AvatarProps = {
  userName: string;
} & FlexProps;

function getInitial(name: string) {
  return name
    .split(" ")
    .map((n) => n[0])
    .slice(0, 2)
    .join("")
    .toUpperCase();
}
export function Avatar({ userName, ...rest }: AvatarProps) {
  const initials = getInitial(userName);
  return (
    <Flex
      borderRadius="8px"
      w="48px"
      h="48px"
      bg="sunny.400"
      alignItems="center"
      justifyContent="center"
      {...rest}
    >
      <BodyM color="white">{initials}</BodyM>
    </Flex>
  );
}
