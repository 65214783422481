import { useCallback, useEffect, useMemo, useState } from "react";

import { Box, Flex } from "@chakra-ui/react";
import { datadogRum } from "@datadog/browser-rum";
import { FormattedMessage, useIntl } from "react-intl";
import shallow from "zustand/shallow";

import { useAppLanguageStore } from "core/stores/useAppLanguageStore";
import { EndDroppingProcessButton } from "flows/Inbound/components/EndDroppingProcessButton";
import { countryNameFromCode } from "libs/countryNames";
import { SpinnerModal } from "shared/components/SpinnerModal";
import { serializeInboundUnitFromSearchResult } from "shared/models/productSearchUnit/serializer";
import { useSearchUnitsByTextLazyQuery } from "shared/queries/productSearchUnit/productSearchUnit.generated";
import { useAddProductsToSelectedProducts } from "shared/stores/useProductSearchService";
import { useProductSearchStore } from "shared/stores/useProductSearchStore";
import { ProductSearchResultCard } from "ui/ProductSearchResultCard/ProductSearchResultCard";
import { BodyS, TitleM } from "ui/Typography/Typography";
import { isNullOrUndefined } from "utils/tsHelpers";

import { InboundUnitAddOrigin } from "../stores/inboundStore/types";

function NoResultsFound() {
  return (
    <Flex direction="column" flex={1} justifyContent="center" textAlign="center">
      <TitleM>
        <FormattedMessage id="components.inbound.inbound-units-search-results-list.no-results-found.title" />
      </TitleM>
      <BodyS>
        <FormattedMessage id="components.inbound.inbound-units-search-results-list.no-results-found.description" />
      </BodyS>
    </Flex>
  );
}

export function ProductsSearchInboundUnitsResultsList() {
  const intl = useIntl();

  const appLanguage = useAppLanguageStore((state) => state.appLanguage);

  const { searchUnitType, textSearchQuery } = useProductSearchStore(
    (state) => ({
      searchUnitType: state.searchUnitType,
      textSearchQuery: state.textSearch,
    }),
    shallow,
  );

  const [searchUnitsByText, { data, loading }] = useSearchUnitsByTextLazyQuery({
    fetchPolicy: "no-cache",
    onError: (error: unknown) => {
      const errorMessage = error instanceof Error ? error.message : "Unknown error";
      datadogRum.addError("Error While Searching units by text", { errorMessage });
    },
  });

  useEffect(() => {
    if (textSearchQuery?.length >= 3) {
      searchUnitsByText({ variables: { searchUnitsByTextInput: { query: textSearchQuery } } });
    }
  }, [textSearchQuery, searchUnitsByText]);

  const searchResults = useMemo(() => {
    return data ? data.searchUnitsByText.units.map(serializeInboundUnitFromSearchResult) : null;
  }, [data]);

  const transformedResults = useMemo(() => {
    if (!searchResults) return null;

    const translatedResults = searchResults.map((unit) => ({
      ...unit,
      countryOfOriginName: countryNameFromCode(appLanguage, unit.countryOfOriginCode),
    }));

    if (data?.searchUnitsByText.matchType === "ean") return translatedResults;

    return translatedResults.filter(
      (result, index, self) =>
        index ===
          self.findIndex(
            (unit) => unit.productSku === result.productSku && unit.quantity === result.quantity,
          ) && result.unitType === searchUnitType,
    );
  }, [appLanguage, searchResults, searchUnitType, data?.searchUnitsByText.matchType]);

  const noResults = useMemo(
    () => textSearchQuery.length > 2 && (!transformedResults || transformedResults.length === 0),
    [textSearchQuery.length, transformedResults],
  );

  const [selectedStates, setSelectedStates] = useState<Record<string, boolean>>({});

  const selectedResults = useMemo(
    () => transformedResults?.filter(({ id }) => selectedStates[id]) || [],
    [selectedStates, transformedResults],
  );

  const toggleSelection = useCallback((id: string) => {
    setSelectedStates((prevState) => ({ ...prevState, [id]: !prevState[id] }));
  }, []);

  const addProducts = useAddProductsToSelectedProducts();
  const handleAddToList = useCallback(() => {
    addProducts(selectedResults, InboundUnitAddOrigin.TextSearch);
  }, [addProducts, selectedResults]);

  const productCardLabels = useMemo(
    () => ({
      singleUnitLabel: intl.formatMessage({
        id: "components.inbound.inbound-units-search-results-list.single-unit-label",
      }),
      handlingUnitLabel: intl.formatMessage({
        id: "components.inbound.inbound-units-search-results-list.handling-unit-label",
      }),
    }),
    [intl],
  );

  if (loading) return <SpinnerModal isOpen />;

  if (isNullOrUndefined(transformedResults)) return null;

  return (
    <Flex
      direction="column"
      w="100%"
      gap="s50"
      h={noResults ? "100%" : undefined}
      data-testid="inbound-unit-search-results-list"
    >
      {noResults ? (
        <NoResultsFound />
      ) : (
        <>
          {transformedResults.map((result) => (
            <ProductSearchResultCard
              key={result.id}
              {...result}
              {...productCardLabels}
              onClickProductCard={() => toggleSelection(result.id)}
              isHighlighted={!!selectedStates[result.id]}
            />
          ))}
          <Box mb="60px" />
          <EndDroppingProcessButton
            shouldShowButton={selectedResults.length > 0}
            onClickButton={handleAddToList}
            labelMessageId="components.inbound.inbound-units-search-results-list.add-to-list-button-label"
            buttonTestId="add-to-list-button"
            bottom="0"
            p="s200"
          />
        </>
      )}
    </Flex>
  );
}
