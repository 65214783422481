import { useEffect, useRef } from "react";

import { useLayoutStore } from "core/stores/useLayoutStore";
import { EppoFeatureFlags } from "core/types/flags";
import {
  useSelectCurrentItem as useSelectCurrentItemToPick,
  useSelectItemsSortedByShelf,
} from "flows/Picking/hooks/usePickingMachineSelectors";
import { useEppoFeatureFlagProvider } from "shared/hooks/useEppoFeatureFlag";
import { ORDER_NUMBER_HEADER_HEIGHT_NUMBER } from "ui/OrderNumberHeader/OrderNumberHeader";
import { createPreventVerticalTouchMove, preventVerticalScroll } from "utils/domEvents";
import { isNotNullNorUndefined } from "utils/tsHelpers";

export function usePickingListScrollEffects() {
  const scrollContainerRef = useLayoutStore((state) => state.scrollContainerRef);
  const {
    itemsSortedByShelf,
    skippedItemsSortedByShelf,
    isPickingDone,
    isGiftPicked,
    isCampaignPicked,
    hasFlyersRemoved,
  } = useSelectItemsSortedByShelf();
  const currentItemToPick = useSelectCurrentItemToPick();
  const { touchStartHandler, preventVerticalTouchMove } = createPreventVerticalTouchMove();

  const itemsRef = useRef<HTMLDivElement[] | null[]>([]);
  const skippedItemsRef = useRef<HTMLDivElement[] | null[]>([]);
  const skippedItemsHeaderRef = useRef<HTMLDivElement | null>(null);
  const giftsHeaderRef = useRef<HTMLDivElement | null>(null);
  const removeFlyersHeaderRef = useRef<HTMLDivElement | null>(null);
  const endPickingButtonRef = useRef<HTMLDivElement | null>(null);

  const isScrollDisabledRef = useRef(false);
  const currentItemInitPickedQtyRef = useRef<number>(0);

  const { isFeatureEnabled: isCampaignProductFlagEnabled } = useEppoFeatureFlagProvider(
    EppoFeatureFlags.CAMPAIGN_PRODUCT,
  );

  const isPickingSkippedItems = !!currentItemToPick?.isSkipped;

  // AUTO SCROLL TO ITEMS EFFECTS

  useEffect(() => {
    skippedItemsRef.current = skippedItemsRef.current.slice(0, skippedItemsSortedByShelf.length);
  }, [skippedItemsSortedByShelf.length]);

  useEffect(() => {
    const currentlyPickingItems = isPickingSkippedItems
      ? skippedItemsSortedByShelf
      : itemsSortedByShelf;
    const currentItemIndex = currentlyPickingItems.findIndex(
      (item) => item.item.id === currentItemToPick?.item.id,
    );
    let elementRef: HTMLDivElement | null;
    if (isPickingDone) {
      const isCampaignItemPicked = isCampaignProductFlagEnabled ? isCampaignPicked : true;
      if (isGiftPicked && isCampaignItemPicked) {
        if (hasFlyersRemoved) {
          elementRef = endPickingButtonRef.current;
        } else {
          elementRef = removeFlyersHeaderRef.current;
        }
      } else {
        elementRef = giftsHeaderRef.current;
      }
    } else if (isPickingSkippedItems) {
      elementRef =
        currentItemIndex === 0
          ? skippedItemsHeaderRef.current
          : skippedItemsRef.current[currentItemIndex];
    } else {
      elementRef = itemsRef.current[currentItemIndex];
    }
    // auto scroll to next item in the list
    if (isNotNullNorUndefined(elementRef) && isNotNullNorUndefined(scrollContainerRef)) {
      scrollContainerRef.current?.scrollTo({
        top: elementRef.offsetTop - ORDER_NUMBER_HEADER_HEIGHT_NUMBER,
        behavior: "smooth",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentItemToPick?.item.id,
    currentItemToPick?.pickedQuantity,
    isPickingSkippedItems,
    isPickingDone,
    isGiftPicked,
    hasFlyersRemoved,
    itemsSortedByShelf,
    skippedItemsSortedByShelf,
  ]);

  // PREVENT PAGE SCROLL EFFECTS

  useEffect(() => {
    if (typeof currentItemToPick?.pickedQuantity === "number") {
      currentItemInitPickedQtyRef.current = currentItemToPick?.pickedQuantity;
    }
    // Restore scroll when employee has finished picking an item
    if (isScrollDisabledRef.current) {
      isScrollDisabledRef.current = false;
      scrollContainerRef.current?.removeEventListener("wheel", preventVerticalScroll);
      scrollContainerRef.current?.removeEventListener("touchstart", touchStartHandler);
      scrollContainerRef.current?.removeEventListener("touchmove", preventVerticalTouchMove);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentItemToPick?.item.id, isPickingDone]);

  useEffect(() => {
    // Disable scroll when employee had started picking an item
    // We use prevCurrentItemIdScrollDisablerRef because a skipped item could
    // have an inital pickedItem value > 0. So we should track both :
    //  - if the current item id effectively changed
    //  - if the pickedQuantity of an item changed
    if (!currentItemToPick?.item) {
      return () => {};
    }
    if (
      !isScrollDisabledRef.current &&
      currentItemToPick?.pickedQuantity > currentItemInitPickedQtyRef.current
    ) {
      isScrollDisabledRef.current = true;
      scrollContainerRef.current?.addEventListener("wheel", preventVerticalScroll, {
        passive: false,
      });
      scrollContainerRef.current?.addEventListener("touchstart", touchStartHandler);
      scrollContainerRef.current?.addEventListener("touchmove", preventVerticalTouchMove, {
        passive: false,
      });
      const scrollContainerRefAtTime = scrollContainerRef.current;
      return () => {
        scrollContainerRefAtTime?.removeEventListener("wheel", preventVerticalScroll);
        scrollContainerRefAtTime?.removeEventListener("touchstart", touchStartHandler);
        scrollContainerRefAtTime?.removeEventListener("touchmove", preventVerticalTouchMove);
      };
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentItemToPick?.pickedQuantity]);

  return {
    itemsRef,
    skippedItemsRef,
    skippedItemsHeaderRef,
    giftsHeaderRef,
    removeFlyersHeaderRef,
    endPickingButtonRef,
  };
}
