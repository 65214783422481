import create from "zustand";
import { persist } from "zustand/middleware";

export enum AppLanguage {
  En = "en",
  De = "de",
  Nl = "nl",
}

type AppLanguageStore = {
  appLanguage: AppLanguage;
};

type AppLanguageStoreWithMethods = AppLanguageStore & {
  setAppLanguage(appLanguage: AppLanguage): void;
};

export const LEGACY_APP_LANGUAGE_STORAGE_KEY = "preferred-language";
export const APP_LANGUAGE_STORAGE_KEY = "app-language";

const legacyAppLanguage = localStorage.getItem(LEGACY_APP_LANGUAGE_STORAGE_KEY) as
  | AppLanguage
  | undefined;

const initialState: AppLanguageStore = {
  appLanguage: legacyAppLanguage || AppLanguage.En,
};

export const useAppLanguageStore = create<AppLanguageStoreWithMethods>()(
  persist(
    (set) => {
      return {
        ...initialState,
        setAppLanguage(appLanguage: AppLanguage) {
          set({ appLanguage });
        },
      };
    },
    {
      name: APP_LANGUAGE_STORAGE_KEY,
    },
  ),
);
