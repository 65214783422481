import { cloneElement } from "react";

import { Flex } from "@chakra-ui/react";
import { FormattedMessage } from "react-intl";

import { useIsCardClicked } from "shared/hooks/useIsCardClicked";
import { ArrowRightIcon } from "ui/Icons/Icons";
import { BodyS } from "ui/Typography/Typography";

import { TimedActivity } from "../models/timedActivity/types";

type TimedActivitiesListItemProps = {
  timedActivity: TimedActivity;
  onClick: () => void;
};

export function TimedActivitiesListItem({
  timedActivity: { nameKey, icon },
  onClick,
}: TimedActivitiesListItemProps) {
  const { isClicked, onClickCard } = useIsCardClicked({ onClickCallback: onClick });

  return (
    <Flex
      onClick={onClickCard}
      bg={isClicked ? "grey.200" : "white"}
      p="s200"
      w="100%"
      justifyContent="space-between"
      data-testid="timed-activity-panel"
    >
      <Flex align="center">
        {icon && cloneElement(icon, { boxSize: "6", mr: "s150" })}
        <BodyS>
          <FormattedMessage id={nameKey} />
        </BodyS>
      </Flex>
      <ArrowRightIcon boxSize="24px" />
    </Flex>
  );
}
