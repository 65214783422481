import { useCallback, useEffect, useMemo, useState } from "react";

import { Box, Flex, Image, useDisclosure } from "@chakra-ui/react";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router";

import { DeliveryRolliV2 } from "__graphql__/types";
import { EppoFeatureFlags } from "core/types/flags";
import { ConnectionErrorPlaceholder } from "flows/Activities/components/ConnectionErrorPlaceholder";
import { ExitFlowInRolliValidationModal } from "flows/Activities/components/ExitFlowInRolliValidationModal";
import {
  RolliIdBarcodeError,
  ManualRolliIdInput,
} from "flows/Activities/components/ManualRolliIdInput/ManualRolliIdInput";
import { RolliListContainer } from "flows/Activities/components/RolliListContainer";
import { TimePickerModal } from "flows/Activities/components/TimePickerModal";
import {
  DeliveryDataUnavailableModal,
  PotentialIncorrectRolliModal,
  RolliContainsCratesModal,
  WrongRolliModal,
} from "flows/Activities/components/WarningModals";
import {
  useCreateDeliveryCheckinForHubMutation,
  useCreateDeliveryCheckinForHubV2Mutation,
} from "flows/Activities/queries/deliveryCheckin/deliveryCheckin.generated";
import { useGetSuppliersForHubQuery } from "flows/Activities/queries/supplier/supplier.generated";
import { ModalNames, useCheckinUIStore } from "flows/Activities/stores/useCheckInUIStore";
import DeliveryTruck from "images/random/delivery-truck.svg";
import ReweLogo from "images/random/rewe-logo.png";
import { Page } from "shared/components/Page";
import { useCustomToast } from "shared/hooks/useCustomToast";
import { useEppoFeatureFlagProvider } from "shared/hooks/useEppoFeatureFlag";
import { BOTTOM_TAB_BAR_HEIGHT_NUMBER, BottomTabBarContainer } from "ui/BottomTabBarContainer";
import { Button } from "ui/Button/Button";
import { DropDown } from "ui/DropDown/DropDown";
import { DropDownOption } from "ui/DropDownList/DropDownList";
import { DropDownListItem } from "ui/DropDownListItem/DropDownListItem";
import { PenIcon } from "ui/Icons/Icons";
import { NavigationHeader } from "ui/NavigationHeader/NavigationHeader";
import { TitleM, BodyM, HeaderL } from "ui/Typography/Typography";
import { isNotNullNorUndefined, isNullOrUndefined } from "utils/tsHelpers";
import { transformChunks } from "utils/ui";

import { serializeSupplier } from "../../models/supplier/serializer";

const deliveryCategories = ["dry", "drinks", "fresh", "frozen"] as const;
export type DeliveryCategory = typeof deliveryCategories[any];
type CategoryDeliveryState = { [Property in DeliveryCategory]: boolean };

const CREATE_DELIVERY_CHECK_IN_ERROR_TOAST_ID = "create_delivery_check_in_error_toast";
const OTHER_SUPPLIER_NAME = "Other supplier";
const REWE_STAGING_ID = "712ac378-865a-4a71-a2d2-f37f01fc5f53";
const BOON_STAGING_ID = "78509c6e-95b7-424f-b6dc-b08c7d95165b";
const REWE_PROD_ID = "330c377c-4224-43a5-9e14-198f1fc3d1c2";
const BOON_PROD_ID = "1393d6fd-263b-41cf-a120-20d97435a644";
const REWE_BOON_IDS = [REWE_STAGING_ID, BOON_STAGING_ID, REWE_PROD_ID, BOON_PROD_ID];
function CheckIn({
  supplierOptions,
  selectedTime,
  onOpenAdjustTimeModal,
  selectedTimeAsISOString,
}: {
  supplierOptions: DropDownOption[];
  selectedTime: string;
  onOpenAdjustTimeModal: () => void;
  selectedTimeAsISOString: string;
}) {
  const navigate = useNavigate();
  const { showToastUI } = useCustomToast();
  const [selectedSupplierId, setSelectedSupplierId] = useState<string | null>(null);
  const { supplierBgColor, supplierTextColor } = useMemo(() => {
    return {
      supplierBgColor: selectedSupplierId ? "pinkFlink.100" : "grey.100",
      supplierTextColor: selectedSupplierId ? "pinkFlink.500" : "",
    };
  }, [selectedSupplierId]);
  const {
    scannedRollies,
    shouldShowManualRolliIdInput,
    hasRolliIdBarcodeError,
    modals,
    setShowModal,
    reset: resetCheckinUIStore,
  } = useCheckinUIStore((state) => ({
    scannedRollies: state.scannedRollies,
    shouldShowManualRolliIdInput: state.shouldShowManualRolliIdInput,
    hasRolliIdBarcodeError: state.hasRolliIdBarcodeError,
    modals: state.modals,
    reset: state.reset,
    setShowModal: state.setShowModal,

    setCheckinUIState: state.setCheckinUIState,
  }));
  const hasValidSupplier = isNotNullNorUndefined(selectedSupplierId);
  const hasRollies = selectedSupplierId && REWE_BOON_IDS.includes(selectedSupplierId);
  const hasScannedRollies = !!(scannedRollies && scannedRollies.length > 0);
  const shouldShowActionComponent = hasRollies
    ? !shouldShowManualRolliIdInput && (hasScannedRollies || hasRolliIdBarcodeError)
    : hasValidSupplier;
  const shouldShowComfirmCTA = hasRollies ? hasScannedRollies : true;
  const [createDeliveryCheckinForHub, { loading: isUpdating }] =
    useCreateDeliveryCheckinForHubV2Mutation({
      onCompleted: () => {
        navigate("/");
        resetCheckinUIStore();
      },
      onError: () => {
        showToastUI({
          id: CREATE_DELIVERY_CHECK_IN_ERROR_TOAST_ID,
          title: "pages.delivery-check-in.error.title",
          description: "pages.delivery-check-in.error.description",
        });
      },
    });

  const canConfirmDeliveryCheckInForHub = useMemo(() => {
    if (hasRollies) {
      if (!scannedRollies || scannedRollies.length === 0) {
        return false;
      }
      return scannedRollies.every((scannedRolli) => scannedRolli.status !== null);
    }
    return hasValidSupplier;
  }, [hasRollies, hasValidSupplier, scannedRollies]);

  const confirmDeliveryCheckinForHub = useCallback(async () => {
    if (isNullOrUndefined(selectedSupplierId) || !canConfirmDeliveryCheckInForHub) {
      return;
    }
    createDeliveryCheckinForHub({
      variables: {
        input: {
          supplierId: selectedSupplierId,
          deliveredAt: selectedTimeAsISOString,
          deliveryRollies: scannedRollies?.map(
            (scannedRolli) =>
              ({
                desadvId: scannedRolli.desadv_id,
                expectedHubSlug: scannedRolli.expected_hub,
                sscc: scannedRolli.sscc,
                status: scannedRolli.status,
              } as DeliveryRolliV2),
          ),
        },
      },
    });
  }, [
    canConfirmDeliveryCheckInForHub,
    createDeliveryCheckinForHub,
    scannedRollies,
    selectedSupplierId,
    selectedTimeAsISOString,
  ]);

  const isREWE = selectedSupplierId && [REWE_STAGING_ID, REWE_PROD_ID].includes(selectedSupplierId);
  const logoImg = isREWE ? ReweLogo : DeliveryTruck;

  return (
    <>
      <Flex
        direction="column"
        backgroundColor="grey.100"
        w="100%"
        h="100%"
        pt="s150"
        px="s150"
        overflow="hidden"
      >
        <Flex align="center" h="127px" p="s200" bg="white" borderRadius="8px 8px 0 0">
          <Image src={logoImg} borderRadius="sm" mr="s300" w="88px" />
          <Flex direction="column" gap={2}>
            <DropDown
              options={supplierOptions}
              value={selectedSupplierId}
              onInput={setSelectedSupplierId}
              filterMode={false}
              backgroundColor={supplierBgColor}
              titleColor={supplierTextColor}
              color={supplierTextColor}
              isDisabled={hasScannedRollies}
              _hover={hasScannedRollies ? { bg: "pinkFlink.100" } : {}}
              w="170px"
              h="40px"
            >
              <FormattedMessage id="pages.delivery-check-in.supplier-dropdown" />
            </DropDown>
            <Flex direction="row" mt="s100" h="40px">
              <Box bg="grey.100" borderRadius="8px">
                <TitleM alignSelf="center" color="grey.800" px="s200" py="s100">
                  {selectedTime}
                </TitleM>
              </Box>
              <Box
                bg="grey.100"
                ml="s100"
                onClick={onOpenAdjustTimeModal}
                cursor="pointer"
                borderRadius="8px"
                p="5px"
              >
                <PenIcon boxSize="24px" />
              </Box>
            </Flex>
          </Flex>
        </Flex>
        {hasRollies && (
          <>
            <RolliListContainer />
            <WrongRolliModal
              onClose={() => setShowModal(ModalNames.WRONG_ROLLI, false)}
              isOpen={modals.wrongRolliModal}
            />
            <RolliContainsCratesModal
              onClose={() => setShowModal(ModalNames.ROLLI_CONTAINS_CRATES, false)}
              isOpen={modals.rolliContainsCratesModal}
            />
            <PotentialIncorrectRolliModal
              onClose={() => setShowModal(ModalNames.POTENTIAL_INCORRECT_ROLLI, false)}
              isOpen={modals.potentialIncorrectRolliModal}
            />
            <DeliveryDataUnavailableModal
              onClose={() => setShowModal(ModalNames.DELIVERY_DATA_UNAVAILABLE, false)}
              isOpen={modals.deliveryDataUnavailableModal}
            />
            <ExitFlowInRolliValidationModal
              onClickConfirm={() => {
                setShowModal(ModalNames.EXIT_FLOW_IN_ROLLI_VALIDATION, false);
                resetCheckinUIStore();
                navigate(-1);
              }}
              onClickDecline={() => setShowModal(ModalNames.EXIT_FLOW_IN_ROLLI_VALIDATION, false)}
              isOpen={modals.exitFlowInRolliValidationModal}
            />
          </>
        )}
      </Flex>
      <ManualRolliIdInput />
      {shouldShowActionComponent && (
        <Flex
          flexDirection="column"
          justifyContent="center"
          w="100%"
          p="s200"
          bgColor="white"
          borderTopWidth="0.5px"
          borderTopColor="grey.150"
          align="center"
          position="absolute"
          bottom="0"
          minH={`${BOTTOM_TAB_BAR_HEIGHT_NUMBER}px`}
          gap="s100"
        >
          {hasRollies && <RolliIdBarcodeError />}
          {shouldShowComfirmCTA && (
            <Button
              w="100%"
              onClick={confirmDeliveryCheckinForHub}
              disabled={!canConfirmDeliveryCheckInForHub}
              isLoading={isUpdating}
              data-testid="confirm_delivery_checkin"
            >
              <FormattedMessage id="pages.delivery-check-in.cta-button" />
            </Button>
          )}
        </Flex>
      )}
    </>
  );
}

function OldCheckIn({
  supplierOptions,
  selectedTime,
  onOpenAdjustTimeModal,
  selectedTimeAsISOString,
}: {
  supplierOptions: DropDownOption[];
  selectedTime: string;
  onOpenAdjustTimeModal: () => void;
  selectedTimeAsISOString: string;
}) {
  const intl = useIntl();
  const navigate = useNavigate();
  const { showToastUI } = useCustomToast();
  const [selectedSupplierId, setSelectedSupplierId] = useState<string | null>(null);
  const [selectedCategories, setSelectedCategories] = useState<CategoryDeliveryState>({
    dry: false,
    drinks: false,
    fresh: false,
    frozen: false,
  });

  const selectedCategoriesAsList = useMemo(() => {
    return deliveryCategories.filter((key) => selectedCategories[key]);
  }, [selectedCategories]);

  const [createDeliveryCheckIn, { loading: isUpdating }] = useCreateDeliveryCheckinForHubMutation({
    onCompleted: () => {
      navigate("/");
    },
    onError: () => {
      showToastUI({
        id: CREATE_DELIVERY_CHECK_IN_ERROR_TOAST_ID,
        title: "pages.delivery-check-in.error.title",
        description: "pages.delivery-check-in.error.description",
      });
    },
  });

  const toggleDeliveryCategory = useCallback(
    (category: DeliveryCategory) => () => {
      setSelectedCategories({
        ...selectedCategories,
        [category]: !selectedCategories[category],
      });
    },
    [selectedCategories],
  );

  const canConfirmDeliveryCheckIn = useMemo(() => {
    const hasValidSupplier = isNotNullNorUndefined(selectedSupplierId);
    const hasSelectedCategory = Object.values(selectedCategories).some((x) => x);
    return hasValidSupplier && hasSelectedCategory;
  }, [selectedCategories, selectedSupplierId]);

  const confirmDeliveryCheckIn = useCallback(async () => {
    if (isNullOrUndefined(selectedSupplierId) || selectedCategoriesAsList.length === 0) {
      return;
    }
    createDeliveryCheckIn({
      variables: {
        input: {
          supplier_id: selectedSupplierId,
          delivered_at: selectedTimeAsISOString,
          categories: selectedCategoriesAsList,
        },
      },
    });
  }, [
    createDeliveryCheckIn,
    selectedCategoriesAsList,
    selectedSupplierId,
    selectedTimeAsISOString,
  ]);

  return (
    <>
      <Flex
        direction="column"
        backgroundColor="grey.100"
        px="s150"
        pb="s200"
        overflow="scroll"
        width="100%"
      >
        <Flex align="center" w="100%" my="s150" bg="white" py="s150" borderRadius="8px">
          <Image src={DeliveryTruck} borderRadius="sm" ml="s150" mr="s300" />
          <Flex direction="column">
            <DropDown
              options={supplierOptions}
              value={selectedSupplierId}
              onInput={setSelectedSupplierId}
              filterMode={false}
              backgroundColor="grey.100"
              w="170px"
              h="37px"
            >
              <FormattedMessage id="pages.delivery-check-in.supplier-dropdown" />
            </DropDown>
          </Flex>
        </Flex>
        <Flex
          direction="column"
          bgColor="white"
          w="100%"
          h="100%"
          mt="20px"
          borderRadius="sm"
          p="s200"
        >
          <BodyM mb="s50">
            <FormattedMessage
              id="pages.delivery-check-in.time-selector.label"
              values={transformChunks({ b: { as: "strong" } })}
            />
          </BodyM>

          <HeaderL alignSelf="center" mt="s300">
            {selectedTime}
          </HeaderL>

          <Button flinkVariant="secondary" m="1.25rem" h="2.25rem" onClick={onOpenAdjustTimeModal}>
            <FormattedMessage
              id="pages.delivery-check-in.adjust-time"
              defaultMessage="Adjust time"
            />
          </Button>

          <BodyM>
            <FormattedMessage
              id="pages.delivery-check-in.category-selector.label"
              values={transformChunks({ b: { as: "strong" } })}
            />
          </BodyM>

          <Flex direction="column" align="center">
            {deliveryCategories.map((category) => (
              <DropDownListItem
                key={category}
                label={intl.formatMessage({
                  id: `pages.delivery-check-in.category-labels.${category}`,
                })}
                isSelected={selectedCategories[category]}
                onClickItem={toggleDeliveryCategory(category)}
              />
            ))}
          </Flex>
        </Flex>
      </Flex>
      <BottomTabBarContainer
        w="100%"
        px="s200"
        bgColor="white"
        borderTopWidth="0.5px"
        borderTopColor="grey.200"
        align="center"
        position="inherit"
      >
        <Button
          w="100%"
          onClick={confirmDeliveryCheckIn}
          disabled={!canConfirmDeliveryCheckIn}
          isLoading={isUpdating}
        >
          <FormattedMessage id="pages.delivery-check-in.cta-button" />
        </Button>
      </BottomTabBarContainer>
    </>
  );
}

export function DeliveryCheckIn() {
  const navigate = useNavigate();
  const intl = useIntl();
  const { isFeatureEnabled: isRolliValidationEnabled } = useEppoFeatureFlagProvider(
    EppoFeatureFlags.ROLLI_VALIDATION,
  );
  const {
    setShowModal,
    scannedRollies,
    reset: resetCheckinUIStore,
  } = useCheckinUIStore((state) => ({
    setShowModal: state.setShowModal,
    scannedRollies: state.scannedRollies,
    reset: state.reset,
  }));

  useEffect(() => {
    resetCheckinUIStore();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // suppliers
  const {
    data: suppliersData,
    loading: isLoading,
    refetch,
    error,
  } = useGetSuppliersForHubQuery({
    fetchPolicy: "network-only",
  });

  const sortedSuppliers = useMemo(() => {
    if (isNullOrUndefined(suppliersData)) {
      return [];
    }
    const suppliers = suppliersData.getSuppliersForHub.suppliers.map(serializeSupplier);
    const translatedSuppliers = suppliers.map((supplier) => ({
      ...supplier,
      name:
        supplier.name === OTHER_SUPPLIER_NAME
          ? intl.formatMessage({
              id: "pages.activities.delivery-check-in-history.other-supplier",
              defaultMessage: OTHER_SUPPLIER_NAME,
            })
          : supplier.name,
    }));
    return translatedSuppliers.sort(
      (a, b) => (b.sortSequence || 0) - (a.sortSequence || 0) || a.name.localeCompare(b.name),
    );
  }, [suppliersData, intl]);

  const supplierOptions = useMemo(() => {
    return (
      sortedSuppliers.map(({ id, name }) => ({
        key: id,
        label: name,
      })) ?? []
    );
  }, [sortedSuppliers]);

  // selectedTime
  const [selectedTime, setSelectedTime] = useState(
    new Date().toLocaleTimeString("de-DE").slice(0, 5),
  );

  const {
    onOpen: onOpenAdjustTimeModal,
    isOpen: isAdjustTimeModalOpen,
    onClose: onCloseAdjustTimeModal,
  } = useDisclosure();

  const onAdjustTimeModalConfirm = useCallback(
    (value: string) => {
      setSelectedTime(value);
      onCloseAdjustTimeModal();
    },
    [setSelectedTime, onCloseAdjustTimeModal],
  );

  const selectedTimeAsISOString = useMemo(() => {
    const d = new Date();
    const [hours, minutes] = selectedTime.split(":").map((n) => parseInt(n, 10));
    d.setHours(hours, minutes, 0, 0);
    return d.toISOString();
  }, [selectedTime]);

  if (isNotNullNorUndefined(error)) {
    return (
      <Page isCentered>
        <ConnectionErrorPlaceholder onClickCta={refetch} />
      </Page>
    );
  }

  return (
    <Page
      isCentered
      isFull
      justifyContent={isLoading ? "center" : "flex-start"}
      bgColor="grey.100"
      isLoading={isLoading}
    >
      <NavigationHeader
        title={intl.formatMessage({ id: "pages.delivery-check-in.title" })}
        onClickGoBack={() => {
          if (scannedRollies?.length) {
            setShowModal(ModalNames.EXIT_FLOW_IN_ROLLI_VALIDATION, true);
          } else {
            navigate(-1);
          }
        }}
      />
      {isRolliValidationEnabled ? (
        <CheckIn
          supplierOptions={supplierOptions}
          selectedTime={selectedTime}
          onOpenAdjustTimeModal={onOpenAdjustTimeModal}
          selectedTimeAsISOString={selectedTimeAsISOString}
        />
      ) : (
        <OldCheckIn
          supplierOptions={supplierOptions}
          selectedTime={selectedTime}
          onOpenAdjustTimeModal={onOpenAdjustTimeModal}
          selectedTimeAsISOString={selectedTimeAsISOString}
        />
      )}

      <TimePickerModal
        confirmLabel={intl.formatMessage({
          id: "pages.delivery-check-in.time-picker-modal.confirm",
          defaultMessage: "Set a time",
        })}
        cancelLabel={intl.formatMessage({
          id: "pages.delivery-check-in.time-picker-modal.cancel",
          defaultMessage: "Cancel",
        })}
        isOpen={isAdjustTimeModalOpen}
        onClickCancel={onCloseAdjustTimeModal}
        onClickConfirm={onAdjustTimeModalConfirm}
        initialValue={selectedTime}
      />
    </Page>
  );
}
