import * as Types from "../../../__graphql__/types.js";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type GetSummaryOfNextOrderToPickQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GetSummaryOfNextOrderToPickQuery = {
  __typename?: "Query";
  getSummaryOfNextOrderToPick: {
    __typename?: "SummaryOfNextOrderToPickResponse";
    orderSummary?: { __typename?: "OrderSummary"; id: string; number?: string | null } | null;
  };
};

export const GetSummaryOfNextOrderToPickDocument = gql`
  query getSummaryOfNextOrderToPick {
    getSummaryOfNextOrderToPick {
      orderSummary {
        id
        number
      }
    }
  }
`;

/**
 * __useGetSummaryOfNextOrderToPickQuery__
 *
 * To run a query within a React component, call `useGetSummaryOfNextOrderToPickQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSummaryOfNextOrderToPickQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSummaryOfNextOrderToPickQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSummaryOfNextOrderToPickQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetSummaryOfNextOrderToPickQuery,
    GetSummaryOfNextOrderToPickQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSummaryOfNextOrderToPickQuery,
    GetSummaryOfNextOrderToPickQueryVariables
  >(GetSummaryOfNextOrderToPickDocument, options);
}
export function useGetSummaryOfNextOrderToPickLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSummaryOfNextOrderToPickQuery,
    GetSummaryOfNextOrderToPickQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSummaryOfNextOrderToPickQuery,
    GetSummaryOfNextOrderToPickQueryVariables
  >(GetSummaryOfNextOrderToPickDocument, options);
}
export type GetSummaryOfNextOrderToPickQueryHookResult = ReturnType<
  typeof useGetSummaryOfNextOrderToPickQuery
>;
export type GetSummaryOfNextOrderToPickLazyQueryHookResult = ReturnType<
  typeof useGetSummaryOfNextOrderToPickLazyQuery
>;
export type GetSummaryOfNextOrderToPickQueryResult = Apollo.QueryResult<
  GetSummaryOfNextOrderToPickQuery,
  GetSummaryOfNextOrderToPickQueryVariables
>;
