import { Flex } from "@chakra-ui/react";

import { Status } from "ui/Status/Status";

type PickingProductCardStatusTagsProps = {
  state: "IDLE" | "PICKING" | "DONE";
  isSkippedItem: boolean;
  hasMissingItems: boolean;
  skippedItemPendingStatusLabel?: string;
  skippedItemRefundStatusLabel?: string;
  skippedItemBackStockLabel: string | undefined;
};

export function PickingProductCardStatusTags({
  state,
  isSkippedItem,
  hasMissingItems,
  skippedItemPendingStatusLabel,
  skippedItemRefundStatusLabel,
  skippedItemBackStockLabel,
}: PickingProductCardStatusTagsProps) {
  const isPending = state !== "DONE";
  const bgColorForBadge = hasMissingItems ? "red.100" : "veggie.500";
  const colorForBadge = hasMissingItems ? "red.500" : "white";
  return (
    <Flex>
      {state === "DONE" && (
        <Flex width="fit-content" h="40px">
          <Status
            label={hasMissingItems ? "Issue reported" : "Picked"}
            textColor={colorForBadge}
            mr="s50"
            size="small"
            fontSize="0.75rem"
            w="max-content"
            bgColor={bgColorForBadge}
          />
        </Flex>
      )}

      {isSkippedItem && (
        <>
          <Flex width="fit-content" h="40px">
            {(isPending || hasMissingItems) && (
              <Status
                label={
                  isPending
                    ? skippedItemPendingStatusLabel ?? ""
                    : skippedItemRefundStatusLabel ?? ""
                }
                textColor={isPending ? "orange.500" : "white"}
                mr="s50"
                size="small"
                fontSize="0.75rem"
                h="40px"
                bg={isPending ? "orange.100" : "orange.500"}
              />
            )}
          </Flex>
          <Flex
            width="fit-content"
            py="s50"
            h="40px"
            mx="s50"
            bgColor="grey.100"
            alignItems="center"
            justifyContent="center"
            borderRadius="0.5rem"
          >
            <Status
              label={skippedItemBackStockLabel ?? ""}
              textColor="black"
              mr="s50"
              size="small"
              fontSize="0.75rem"
              bg="grey.100"
            />
          </Flex>
        </>
      )}
    </Flex>
  );
}
