import { createContext, useContext, useMemo } from "react";

import { useInterpret } from "@xstate/react";
import { Outlet } from "react-router-dom";
import { InterpreterFrom } from "xstate";

import { useInterpreterRouteSynchronizer } from "shared/hooks/useInterpreterRouteSynchronizer";

import { useStockCorrectionActionsImplems } from "../machines/stockCorrectionMachine/actions";
import {
  STOCK_CORRECTION_MACHINE_NAME,
  stockCorrectionMachine,
} from "../machines/stockCorrectionMachine/machine";
import { useStockCorrectionServiceImplems } from "../machines/stockCorrectionMachine/services";
import {
  StockCorrectionEvents,
  StockCorrectionMachineContext,
} from "../machines/stockCorrectionMachine/types";

export const XStateStockCorrectionContext = createContext({
  stockCorrectionService: {} as InterpreterFrom<typeof stockCorrectionMachine>,
});

export function XStateStockCorrectionProvider() {
  const stockCorrectionActionsImplems = useStockCorrectionActionsImplems();
  const stockCorrectionServicesImplems = useStockCorrectionServiceImplems();

  const stockCorrectionService = useInterpret(stockCorrectionMachine, {
    actions: stockCorrectionActionsImplems,
    services: stockCorrectionServicesImplems,
  });

  useInterpreterRouteSynchronizer<StockCorrectionMachineContext, StockCorrectionEvents>(
    STOCK_CORRECTION_MACHINE_NAME,
    stockCorrectionService,
  );

  const values = useMemo(
    () => ({
      stockCorrectionService,
    }),
    [stockCorrectionService],
  );

  return (
    <XStateStockCorrectionContext.Provider value={values}>
      <Outlet />
    </XStateStockCorrectionContext.Provider>
  );
}

export function useStockCorrectionServiceContext() {
  return useContext(XStateStockCorrectionContext);
}
