/*
[QUICK GUIDANCE]
Use 100s as background fills
Use 200s for interactive elements, chips, tags, etc.
Use 400s - reserved for future use.
Use 500s as text color on white (semantic usage).
Use 500s as icon color on color-100/200 fills.
Avoid using 500s (excl. primary-500s) as element fills. 
 */

export const colors = {
  pinkFlink: {
    100: "#FEECF4",
    200: "#FDD8E9",
    400: "#F97FB5",
    500: "#E81F76",
  },
  nightBlueFlink: {
    500: "#002855",
  },
  purple: {
    100: "#F1ECF9",
    200: "#E5DBF3",
    400: "#AE95DA",
    500: "#7C55C3",
  },
  marine: {
    100: "#E9F3FB",
    200: "#D3E6F7",
    400: "#69AAE4",
    500: "#257FCF",
  },
  veggie: {
    100: "#E8F7F5",
    200: "#D0EFEA",
    400: "#66C8BA",
    500: "#009B7D",
  },
  grey: {
    100: "#F8F8F8",
    150: "#F2F2F2",
    200: "#DEDEDE",
    300: "#C4C4C4",
    350: "#ECECEC",
    400: "#ABABAB",
    500: "#919191",
    600: "#787878",
    650: "#E6E6E6",
    700: "#5E5E5E",
    800: "#454545",
  },
  orange: {
    100: "#FFF0E0",
    200: "#FFE8D0",
    400: "#FFB063",
    500: "#EC7400",
  },
  red: {
    100: "#FFE0E0",
    500: "#CC0000",
  },
  black: "#000000",
  white: "#FFFFFF",
  sunny: {
    200: "#FCF6D4",
    400: "#F0D22B",
    500: "#FADD00",
  },
  error: "#D8232A",
  critical: "#F55B00",
  lightBlue: "#5AA8EA",
};
