import { Modal, ModalContent, ModalOverlay } from "@chakra-ui/react";

import { Spinner } from "ui/Spinner/Spinner";

export type SpinnerModalProps = {
  isOpen: boolean;
};

export function SpinnerModal({ isOpen = false }: SpinnerModalProps) {
  return (
    <Modal
      onClose={() => {}}
      trapFocus={false}
      closeOnOverlayClick={false}
      isCentered
      isOpen={isOpen}
    >
      <ModalOverlay bg="rgba(0,0,0,0.32)" />
      <ModalContent width="auto" p="s200">
        <Spinner />
      </ModalContent>
    </Modal>
  );
}
