import create from "zustand";
import { persist } from "zustand/middleware";

export enum OAOccupation {
  Inbounding = "Inbounding",
  Other = "Other",
}

type OAOccupationStore = {
  oaOccupation: OAOccupation;
  oaOccupationInBackend: OAOccupation;
};

type OAOccupationStoreWithMethods = OAOccupationStore & {
  setOAOccupation(oaOccupation: OAOccupation): void;
  registerAckedByBackend(oaOccupation: OAOccupation): void;
};

const initialState: OAOccupationStore = {
  oaOccupation: OAOccupation.Other,
  oaOccupationInBackend: OAOccupation.Other,
};

const OA_OCCUPATION_STORAGE_KEY = "oa-occupation";

export const useOAOccupationStore = create<OAOccupationStoreWithMethods>()(
  persist(
    (set) => ({
      ...initialState,
      setOAOccupation(oaOccupation: OAOccupation) {
        set({ oaOccupation });
      },
      registerAckedByBackend(oaOccupation: OAOccupation) {
        set({ oaOccupationInBackend: oaOccupation });
      },
    }),
    {
      name: OA_OCCUPATION_STORAGE_KEY,
    },
  ),
);
