import { useCallback } from "react";

import { useDisclosure } from "@chakra-ui/react";
import { useNavigate } from "react-router";

import { TaskType } from "__graphql__/types";
import { PageName } from "analytics/events";

import { getPriorityGroup } from "../models/check/serializer";
import { useGetPendingChecksQuery } from "../queries/check/check.generated";
import { getInitialCheckUrl } from "../utils/checkUrl";
import { useStartNextCheck } from "./useStartNextCheck";

type UseChecksListProps = {
  type: TaskType[];
  origin: PageName;
};

export function useChecksList({ type, origin }: UseChecksListProps) {
  const navigate = useNavigate();

  const { startNextCheck, isLoading: isLoadingFetchNextCheckAndStartCheck } = useStartNextCheck();

  const {
    data,
    loading: isLoadingChecks,
    refetch: refetchChecks,
  } = useGetPendingChecksQuery({
    fetchPolicy: "network-only",
    variables: { input: { type } },
  });

  const {
    isOpen: isTaskIsTakenModalOpen,
    onOpen: onOpenTaskIsTakenModal,
    onClose: onCloseTaskIsTakenModal,
  } = useDisclosure();

  const {
    isOpen: isErrorModalOpen,
    onOpen: onOpenErrorModal,
    onClose: onCloseErrorModal,
  } = useDisclosure();

  const startCheck = useCallback(
    async (shelfNumber: string, priority: number) => {
      try {
        const response = await startNextCheck({
          shelfNumber,
          groupedPriority: getPriorityGroup(priority),
          type,
        });
        if (response?.status === "checkAlreadyTaken") {
          onOpenTaskIsTakenModal();
          return;
        }
        if (response?.status === "noMoreCheck") {
          refetchChecks();
          return;
        }
        if (response?.check?.checkId && response?.check?.type) {
          const { type: checkType, checkId } = response.check;
          navigate(getInitialCheckUrl({ type: checkType, checkId }), {
            state: { origin },
          });
        }
      } catch (error) {
        onOpenErrorModal();
      }
    },
    [
      startNextCheck,
      onOpenErrorModal,
      onOpenTaskIsTakenModal,
      refetchChecks,
      navigate,
      origin,
      type,
    ],
  );

  return {
    isLoadingChecks,
    refetchChecks,
    rawChecks: data?.getPendingChecks?.checks ?? [],
    isLoadingFetchNextCheckAndStartCheck,
    isTaskIsTakenModalOpen,
    onCloseTaskIsTakenModal,
    startCheck,
    isErrorModalOpen,
    onCloseErrorModal,
  };
}
