import { useCallback } from "react";

import { Box, Flex, Image, Spacer, useDisclosure } from "@chakra-ui/react";
import { useIntl } from "react-intl";

import { TaskType } from "__graphql__/types";
import { useEmployeeStore } from "core/stores/useEmployeeStore";
import { openOngoingActivityModal } from "core/stores/useLayoutStore";
import { TaskLoadingFailedModal } from "flows/Inventory/shared/components/TaskLoadingFailedModal";
import Scan from "images/random/scan.png";
import { useScan } from "shared/hooks/useScan/useScan";
import { useTimedActivityStore } from "shared/stores/useTimedActivityStore";
import { BodyS } from "ui/Typography/Typography";
import { isNotNullNorUndefined } from "utils/tsHelpers";

import { useGetPendingChecksQuery } from "../queries/check/check.generated";

type CheckScannerProps = {
  checksList: {
    priority: number;
    shelfNumber: string;
  }[];
  startCheck: (shelfId: string, priority: number) => void;
  type: TaskType[];
};

export function CheckScannerV2({ checksList, startCheck, type }: CheckScannerProps) {
  const intl = useIntl();
  const isAutoScanActive = useEmployeeStore((state) => state.autoScan);

  const timedActivityInstance = useTimedActivityStore((state) => state.timedActivityInstance);

  const { refetch } = useGetPendingChecksQuery({
    variables: { input: { type } },
  });

  const {
    isOpen: isTaskLoadingFailedModal,
    onOpen: onOpenTaskLoadingFailedModal,
    onClose: onCloseTaskLoadingFailedModal,
  } = useDisclosure();

  const onRefreshTaskLoadingFailed = useCallback(() => {
    refetch();
    onCloseTaskLoadingFailedModal();
  }, [refetch, onCloseTaskLoadingFailedModal]);

  const onScan = useCallback(
    async (scannedValue: string) => {
      if (isNotNullNorUndefined(timedActivityInstance)) {
        openOngoingActivityModal();
        return;
      }
      const foundCheck = checksList.find((check) => check.shelfNumber === scannedValue);
      if (!foundCheck) {
        onOpenTaskLoadingFailedModal();
        return;
      }
      startCheck(foundCheck.shelfNumber, foundCheck.priority);
    },
    [checksList, onOpenTaskLoadingFailedModal, startCheck, timedActivityInstance],
  );

  useScan({ onScan });

  return (
    <>
      {isAutoScanActive && (
        <Box w="100%" p="s200">
          <Box bg="white" borderRadius="8px" w="100%" p="s200" textAlign="center">
            <Flex justifyContent="between">
              <Image src={Scan} boxSize="48px" borderRadius="full" />
              <Spacer />
              <BodyS w="224px" textAlign="left" my="s50" color="black">
                {intl.formatMessage({ id: "component.check-scanner.title" })}
              </BodyS>
            </Flex>
          </Box>
        </Box>
      )}
      <TaskLoadingFailedModal
        isOpen={isTaskLoadingFailedModal}
        onClose={onCloseTaskLoadingFailedModal}
        onRefresh={onRefreshTaskLoadingFailed}
      />
    </>
  );
}
