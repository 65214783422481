import { Flex, Image } from "@chakra-ui/react";
import { FormattedMessage } from "react-intl";

import ScanImage from "images/inbound/inbound-index-page-scan.png";
import { BodyM, HeaderS } from "ui/Typography/Typography";

export function InboundIndexInstructions() {
  return (
    <Flex flex={1} direction="column" h="100%" w="100%" alignItems="center" justifyContent="center">
      <HeaderS textAlign="center">
        <FormattedMessage id="pages.inbound.inbound-index.instructions.title" />
      </HeaderS>
      <BodyM mt="s100" textAlign="center">
        <FormattedMessage id="pages.inbound.inbound-index.instructions.subtitle" />
      </BodyM>
      <Image mt="s300" src={ScanImage} h="124px" w="124px" />
    </Flex>
  );
}
