import { useCallback, useState } from "react";

import { Flex, useDisclosure } from "@chakra-ui/react";

import { useInboundStore } from "../stores/inboundStore/useInboundStore";
import { EditTotalQuantityModal } from "./EditTotalQuantityModal";
import { PreDroppingInboundUnitsListBottomSpace } from "./PreDroppingInboundUnitsListBottomSpace";
import { PreDroppingListPreparationItem } from "./PreDroppingListPreparationItem";

export function PreDroppingListPreparation() {
  const inboundUnitsSortedByDate = useInboundStore((state) => state.inboundUnitsSortedByDate);

  const {
    isOpen: isEditTotalQuantityModalOpen,
    onOpen: openEditTotalQuantityModal,
    onClose: closeEditTotalQuantityModal,
  } = useDisclosure();

  const [editTotalQuantityModalCallback, setEditTotalQuantityModalCallback] = useState<() => void>(
    () => () => {},
  );

  const openEditTotalQuantityModalWithCallback = useCallback(
    (callback: () => void) => {
      setEditTotalQuantityModalCallback(() => callback);
      openEditTotalQuantityModal();
    },
    [setEditTotalQuantityModalCallback, openEditTotalQuantityModal],
  );

  const onConfirmEditTotalQuantityModal = useCallback(() => {
    editTotalQuantityModalCallback();
    closeEditTotalQuantityModal();
  }, [editTotalQuantityModalCallback, closeEditTotalQuantityModal]);

  return (
    <Flex direction="column" gap="s200">
      <EditTotalQuantityModal
        isOpen={isEditTotalQuantityModalOpen}
        onConfirm={onConfirmEditTotalQuantityModal}
        onCancel={closeEditTotalQuantityModal}
      />
      {inboundUnitsSortedByDate.map((sku) => (
        <PreDroppingListPreparationItem
          key={sku}
          sku={sku}
          showSwipeButtons
          allowBlur
          defaultTabBarState
          allowEditHandlingUnitSize
          openEditTotalQuantityModal={openEditTotalQuantityModalWithCallback}
        />
      ))}
      <PreDroppingInboundUnitsListBottomSpace />
    </Flex>
  );
}
