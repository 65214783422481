import create from "zustand";
import { persist } from "zustand/middleware";

type LocalFeatureFlags = Record<string, string | undefined>;

type FeatureFlagOverridesStore = {
  overridesMap: Record<string, boolean | null>;
  localFeatureFlags: LocalFeatureFlags;
};

type FeatureFlagOverridesStoreWithMethods = FeatureFlagOverridesStore & {
  setFeatureFlagOverrides(key: string, value: boolean | null): void;
  setLocalFeatureFlags(flags: LocalFeatureFlags): void;
  resetOverrides(): void;
  resetLocalFeatureFlags(): void;
};

const LOCAL_OVERRIDES_STORAGE_KEY = "local_feature_flag_overrides";

const initialState: FeatureFlagOverridesStore = {
  overridesMap: {},
  localFeatureFlags: {},
};

export const useFeatureFlagOverridesStore = create<FeatureFlagOverridesStoreWithMethods>()(
  persist(
    (set, get) => {
      return {
        ...initialState,
        setFeatureFlagOverrides(key: string, value: boolean | null) {
          set({
            overridesMap: {
              ...get().overridesMap,
              [key]: value,
            },
          });
        },
        setLocalFeatureFlags(flags: LocalFeatureFlags) {
          set({
            localFeatureFlags: flags,
          });
        },
        resetOverrides() {
          set({ overridesMap: initialState.overridesMap });
        },
        resetLocalFeatureFlags() {
          set({ localFeatureFlags: initialState.localFeatureFlags });
        },
      };
    },
    {
      name: LOCAL_OVERRIDES_STORAGE_KEY,
    },
  ),
);
