import { useCallback, useMemo } from "react";

import { Flex } from "@chakra-ui/react";
import { groupBy, orderBy } from "lodash";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router";

import { TaskType } from "__graphql__/types";
import { PageName } from "analytics/events";
import { routes } from "config/routes";
import { openOngoingActivityModal } from "core/stores/useLayoutStore";
import { CheckScannerV2 } from "flows/Inventory/shared/components/CheckScannerV2";
import { CheckListItem, ChecksList } from "flows/Inventory/shared/components/ChecksList";
import { ConnectionErrorModal } from "flows/Inventory/shared/components/ConnectionErrorModal";
import { TaskIsTakenModal } from "flows/Inventory/shared/components/TaskIsTakenModal";
import { useChecksList } from "flows/Inventory/shared/hooks/useChecksList";
import { SpinnerModal } from "shared/components/SpinnerModal";
import { SwipeablePageWrapper } from "shared/components/SwipeablePageWrapper";
import { useTimedActivityStore } from "shared/stores/useTimedActivityStore";
import { NavigationHeader } from "ui/NavigationHeader/NavigationHeader";
import { isNotNullNorUndefined } from "utils/tsHelpers";

export function EoyChecksList() {
  const intl = useIntl();
  const navigate = useNavigate();

  const timedActivityInstance = useTimedActivityStore((state) => state.timedActivityInstance);

  const {
    rawChecks,
    refetchChecks,
    isLoadingChecks,
    isLoadingFetchNextCheckAndStartCheck,
    startCheck,
    isErrorModalOpen,
    isTaskIsTakenModalOpen,
    onCloseErrorModal,
    onCloseTaskIsTakenModal,
  } = useChecksList({
    type: [TaskType.eoy_inventory_check],
    origin: PageName.INVENTORY_EOY_CHECK_LIST_PAGE,
  });

  const onSelectCheck = useCallback(
    (shelfId: string, priority: number) => {
      if (isNotNullNorUndefined(timedActivityInstance)) {
        openOngoingActivityModal();
        return;
      }
      startCheck(shelfId, priority);
    },
    [startCheck, timedActivityInstance],
  );

  const sortedAndGroupedChecks = useMemo<CheckListItem[]>(() => {
    const grouped = Object.entries(groupBy(rawChecks, "shelfNumber")).map(
      ([shelfNumber, checks]) => ({
        shelfNumber,
        type: TaskType.eoy_inventory_check,
        count: checks.length,
        priority: checks[0].priority,
        id: checks[0].id,
      }),
    );
    return orderBy(grouped, ["shelfNumber"], ["asc"]);
  }, [rawChecks]);

  return (
    <>
      <SwipeablePageWrapper
        refetch={refetchChecks}
        isFull
        justifyContent="flex-start"
        flexDirection="column"
        h="100%"
        isLoading={isLoadingChecks}
      >
        <NavigationHeader
          title={intl.formatMessage({
            id: "flows.inventory.eoy-checks.pages.eoy-checks-list.title",
          })}
          onClickGoBack={() => navigate(routes.inventory.root)}
        />
        <Flex h="100%" direction="column" w="100%" overflowY="scroll">
          <CheckScannerV2
            checksList={rawChecks}
            startCheck={startCheck}
            type={[TaskType.eoy_inventory_check]}
          />
          <ChecksList items={sortedAndGroupedChecks} onSelectCheck={onSelectCheck} />
        </Flex>
      </SwipeablePageWrapper>
      <TaskIsTakenModal
        isOpen={isTaskIsTakenModalOpen}
        onClose={onCloseTaskIsTakenModal}
        onRefresh={refetchChecks}
      />
      <ConnectionErrorModal
        isOpen={isErrorModalOpen}
        onClose={onCloseErrorModal}
        onRefresh={refetchChecks}
      />
      <SpinnerModal isOpen={isLoadingFetchNextCheckAndStartCheck} />
    </>
  );
}
