import { useRef } from "react";

import { Modal, ModalBody, ModalContent, ModalFooter, ModalOverlay } from "@chakra-ui/react";

import { Button } from "ui/Button/Button";
import { TIME_PICKER_WIDTH_NUMBER, TimePicker } from "ui/TimePicker/TimePicker";

export type TimePickerModalProps = {
  onClickConfirm: (newDate: string) => void;
  onClickCancel: () => void;
  confirmLabel: string;
  cancelLabel: string;
  isOpen: boolean;
  /**
   * initialValue: expected format : "hh:mm"
   */
  initialValue?: string;
};

export function TimePickerModal({
  onClickConfirm,
  onClickCancel,
  isOpen,
  confirmLabel,
  cancelLabel,
  initialValue = new Date().toLocaleTimeString("de-DE").slice(0, 5),
}: TimePickerModalProps) {
  const currentTimeRef = useRef(initialValue);

  return (
    <Modal
      trapFocus={false}
      closeOnOverlayClick={false}
      isCentered
      isOpen={isOpen}
      onClose={() => {}}
    >
      <ModalOverlay bg="rgba(0,0,0,0.2)" />
      <ModalContent maxW={TIME_PICKER_WIDTH_NUMBER + 32}>
        <ModalBody p="s200">
          <TimePicker
            onChange={(newDate) => {
              currentTimeRef.current = newDate;
            }}
            initialValue={currentTimeRef.current}
          />
        </ModalBody>
        <ModalFooter paddingX="s200" paddingTop="0" flexDir="column">
          <Button
            onClick={() => onClickConfirm(currentTimeRef.current)}
            flinkVariant="primary"
            size="lg"
            width="full"
          >
            {confirmLabel}
          </Button>
          <Button mt="s100" onClick={onClickCancel} flinkVariant="secondary" size="lg" width="full">
            {cancelLabel}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
