import { useState, useEffect, useRef } from "react";

const useScrollDetection = (ref: React.RefObject<HTMLDivElement | null>, timeoutDuration = 100) => {
  const [isScrolling, setIsScrolling] = useState(false);
  const scrollingTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolling(true);
      if (scrollingTimeoutRef.current) clearTimeout(scrollingTimeoutRef.current);
      scrollingTimeoutRef.current = setTimeout(() => {
        setIsScrolling(false);
      }, timeoutDuration);
    };

    const scrollContainer = ref.current;
    if (scrollContainer) {
      scrollContainer.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener("scroll", handleScroll);
      }
      if (scrollingTimeoutRef.current) clearTimeout(scrollingTimeoutRef.current);
    };
  }, [ref, timeoutDuration]);

  return isScrolling;
};

export default useScrollDetection;
