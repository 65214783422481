import { DespatchAdviceItem, InventoryChangeReason, Unit } from "__graphql__/types";
import { isEmptyObject, isNullOrUndefined } from "utils/tsHelpers";

import { DespatchSummaryItem, InboundUnitStockUpdateState } from "./types";

interface StockUpdatePlan {
  reason: InventoryChangeReason;
}

export function sumStockUpdatePlanTotal(
  stockUpdatePlan: InboundUnitStockUpdateState["stockUpdatePlan"],
) {
  return stockUpdatePlan.reduce((sum, plan) => plan.quantityDelta + sum, 0);
}

export function extractInboundPlan<T extends StockUpdatePlan>(stockUpdatePlan: T[]): T | undefined {
  return stockUpdatePlan.find(
    ({ reason }) => reason === InventoryChangeReason.inbound_goods_received,
  );
}

const hasSameHuSize = (items: DespatchAdviceItem[]): boolean => {
  const uniqueSizes = new Set(items.map((item) => item.handlingUnitSize));
  return uniqueSizes.size === 1;
};

const getHuUnitSize = (items: DespatchAdviceItem[]) => {
  return items.length === 1 || hasSameHuSize(items) ? items[0].handlingUnitSize : 1;
};

const prefill = (items: DespatchAdviceItem[]): DespatchSummaryItem | null => {
  const totalQuantity = items.reduce((sum, item) => sum + item.totalQuantity, 0);

  if (totalQuantity <= 0) return null;

  const handlingUnitSize = getHuUnitSize(items);

  const prefilledItem: DespatchSummaryItem = {
    unitType: handlingUnitSize === 1 ? "single" : "handling",
    handlingUnitSize,
    expectedNumberOfUnits: totalQuantity / handlingUnitSize,
    totalQuantity,
  };

  return prefilledItem;
};

export const filteredDespatchAdviceBySku = (
  sku: string,
  despatchAdviceItems: Record<string, DespatchAdviceItem[]>,
) => {
  if (isEmptyObject(despatchAdviceItems)) return null;

  const matchingItems = Object.values(despatchAdviceItems)
    .flat()
    .filter((item: DespatchAdviceItem) => Array.isArray(item?.skus) && item.skus.includes(sku));

  if (!matchingItems?.length) return null;

  const isTotalQuantityEstimated = matchingItems.some(
    (item: DespatchAdviceItem) => item.isTotalQuantityEstimate,
  );

  if (isTotalQuantityEstimated) return null;

  return matchingItems;
};

export const selectDespatchAdviceItemBySku = (
  sku: string,
  despatchAdviceItems: Record<string, DespatchAdviceItem[]>,
) => {
  if (isEmptyObject(despatchAdviceItems)) return null;

  const matchingItems = filteredDespatchAdviceBySku(sku, despatchAdviceItems);

  if (isNullOrUndefined(matchingItems)) return null;

  return prefill(matchingItems);
};

export const getMatchedtDesadvId = (
  sku: string,
  despatchAdviceItems: Record<string, DespatchAdviceItem[]>,
) => {
  if (isEmptyObject(despatchAdviceItems)) return null;

  const matchingKey = Object.entries(despatchAdviceItems).find(
    ([, items]) =>
      Array.isArray(items) &&
      items.some((item) => Array.isArray(item?.skus) && item.skus.includes(sku)),
  );
  return matchingKey ? matchingKey[0] : null;
};

export const getUniqueHandlingUnitSizes = (
  units?: {
    quantity: Unit["quantity"];
  }[],
): number[] => {
  const handlingUnitSizes = units
    ? units.filter((unit: any) => unit.quantity > 1).map((unit: any) => unit.quantity)
    : [];

  return Array.from(new Set(handlingUnitSizes));
};
