import { v4 as uuidv4 } from "uuid";

export const LS_DEVICE_ID_KEY = "generated-device-id";

export function getDeviceId() {
  let deviceId = localStorage.getItem(LS_DEVICE_ID_KEY);
  if (!deviceId) {
    deviceId = uuidv4();
    localStorage.setItem(LS_DEVICE_ID_KEY, deviceId);
  }
  return deviceId;
}
