import React from "react";

import { Flex, FlexProps } from "@chakra-ui/react";

import { Chip, ChipProps } from "ui/Chip/Chip";

type ChipSelectorProps<T extends string | number> = {
  options: ChipSelectorOption<T>[];
  value: T | null;
  onInput: (value: T) => void;
} & FlexProps;

type ChipSelectorOption<T extends string | number> = {
  value: T;
} & Omit<ChipProps, "state">;

export function ChipSelector<T extends string | number>({
  options,
  value,
  onInput,
  ...rest
}: ChipSelectorProps<T>) {
  return (
    <Flex {...rest}>
      {options.map(({ value: optionValue, children, ...rest2 }) => {
        const state = value === optionValue ? "active" : "default";

        const onClick = () => {
          if (value === optionValue) {
            return;
          }
          onInput(optionValue);
        };

        return (
          <Chip key={optionValue} state={state} onClick={onClick} {...rest2} minW="fit-content">
            {children}
          </Chip>
        );
      })}
    </Flex>
  );
}
