import * as Types from "../../../__graphql__/types.js";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type GetPendingCheckCountQueryVariables = Types.Exact<{
  input?: Types.InputMaybe<Types.CheckFilters>;
}>;

export type GetPendingCheckCountQuery = {
  __typename?: "Query";
  getOpenedChecksCountForHub: {
    __typename?: "GetOpenedChecksCountForHubResponse";
    checkCount?: number | null;
  };
};

export const GetPendingCheckCountDocument = gql`
  query GetPendingCheckCount($input: CheckFilters) {
    getOpenedChecksCountForHub(input: $input) {
      checkCount: pendingChecksCount
    }
  }
`;

/**
 * __useGetPendingCheckCountQuery__
 *
 * To run a query within a React component, call `useGetPendingCheckCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPendingCheckCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPendingCheckCountQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetPendingCheckCountQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetPendingCheckCountQuery,
    GetPendingCheckCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPendingCheckCountQuery, GetPendingCheckCountQueryVariables>(
    GetPendingCheckCountDocument,
    options,
  );
}
export function useGetPendingCheckCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPendingCheckCountQuery,
    GetPendingCheckCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPendingCheckCountQuery, GetPendingCheckCountQueryVariables>(
    GetPendingCheckCountDocument,
    options,
  );
}
export type GetPendingCheckCountQueryHookResult = ReturnType<typeof useGetPendingCheckCountQuery>;
export type GetPendingCheckCountLazyQueryHookResult = ReturnType<
  typeof useGetPendingCheckCountLazyQuery
>;
export type GetPendingCheckCountQueryResult = Apollo.QueryResult<
  GetPendingCheckCountQuery,
  GetPendingCheckCountQueryVariables
>;
