import { useCallback, useEffect, useMemo, useState } from "react";

import { isApolloError } from "@apollo/client";
import { useParams } from "react-router";

import { TaskType } from "__graphql__/types";
import { PageName } from "analytics/events";
import { useAppLanguageStore } from "core/stores/useAppLanguageStore";
import { useCheckAlreadyCompletedToast } from "flows/Inventory/shared/hooks/useCheckAlreadyCompletedToast";
import { useChecksRedirect } from "flows/Inventory/shared/hooks/useChecksRedirect";
import { useStartNextCheck } from "flows/Inventory/shared/hooks/useStartNextCheck";
import { useTaskCompletedToast } from "flows/Inventory/shared/hooks/useTaskCompletedToast";
import {
  getPriorityGroup,
  serializeAndTranslateCheck,
} from "flows/Inventory/shared/models/check/serializer";
import { Check } from "flows/Inventory/shared/models/check/types";
import { GetCheckByIdQuery } from "flows/Inventory/shared/queries/check/check.generated";
import { useErrorToastWithAudio } from "shared/hooks/useErrorToastWithAudio";
import { isNotNullNorUndefined } from "utils/tsHelpers";

import { useEoyChecksQueries } from "./useEoyChecksQueries";

type UseEoyChecksServiceProps = {
  type: TaskType;
  origin: PageName;
};

export const useEoyChecksService = ({ type, origin }: UseEoyChecksServiceProps) => {
  const appLanguage = useAppLanguageStore((state) => state.appLanguage);
  const { checkId } = useParams<{ checkId: string | undefined }>();
  const [checkData, setCheckData] = useState<GetCheckByIdQuery | null>(null);
  const [countedItems, setCountedItems] = useState<number>(0);

  const { validateEoyCheckMutation, fetchCheckByIdQuery } = useEoyChecksQueries();
  const { startNextCheck } = useStartNextCheck();
  const showTaskCompletedToast = useTaskCompletedToast();
  const { showCheckAlreadyCompletedToast } = useCheckAlreadyCompletedToast();
  const { showToastAndPlayError } = useErrorToastWithAudio({
    errorToastId: "validate_eoy_check_error",
  });

  const { navigateToCheck, navigateToOrigin } = useChecksRedirect();

  const fetchEoyTask = useCallback(async () => {
    if (!checkId) return;
    setCheckData(null);
    try {
      const response = await fetchCheckByIdQuery({ checkId });
      setCheckData(response.data);
    } catch (e) {
      setCheckData(null);
      if (!isApolloError(e as Error)) throw e;
    }
  }, [checkId, setCheckData, fetchCheckByIdQuery]);

  useEffect(() => {
    fetchEoyTask();
  }, [fetchEoyTask]);

  const redirectToOrigin = useCallback(() => navigateToOrigin(origin), [navigateToOrigin, origin]);

  const redirectToCheck = useCallback(
    (check: Check) => {
      navigateToCheck(check, origin);
    },
    [navigateToCheck, origin],
  );

  const taskCompletedToast = useCallback(() => {
    showTaskCompletedToast();
  }, [showTaskCompletedToast]);
  const taskAlreadyCompletedToast = useCallback(() => {
    showCheckAlreadyCompletedToast();
  }, [showCheckAlreadyCompletedToast]);
  const errorToast = useCallback(() => {
    showToastAndPlayError({ title: "hooks.use-custom-toast.error-title" });
  }, [showToastAndPlayError]);

  const eoyCheck = useMemo(
    () =>
      checkData === null || !checkData.getTaskById.task
        ? null
        : serializeAndTranslateCheck(checkData.getTaskById.task, appLanguage),
    [appLanguage, checkData],
  );

  const updateItemQuantity = useCallback((quantity: number) => setCountedItems(quantity), []);
  const { shelfNumber, shelfLetter, productName, priority } = eoyCheck || {};
  const shelfNumberAndLetter = shelfNumber && shelfLetter ? `${shelfNumber}${shelfLetter}` : "";
  const name = productName || "";

  const handleEoyCheck = useCallback(async (): Promise<boolean | undefined> => {
    if (!checkId) return false;
    try {
      const response = await validateEoyCheckMutation({
        checkId,
        quantity: countedItems,
        type,
        name,
      });
      return response?.data?.validateEoyCheck?.success;
    } catch (e: any) {
      if (!isApolloError(e as Error)) throw e;
      const errorCode = e?.graphQLErrors?.[0]?.extensions?.code;
      if (errorCode === "SKIPPED_TASK_CREATION") {
        return true;
      }
      if (errorCode === "TASK_UPDATE_CONFLICT") {
        taskAlreadyCompletedToast();
      } else {
        errorToast();
      }
      return false;
    }
  }, [
    checkId,
    countedItems,
    errorToast,
    name,
    taskAlreadyCompletedToast,
    type,
    validateEoyCheckMutation,
  ]);

  const handleStartNextCheck = useCallback(async () => {
    const data = await startNextCheck({
      shelfNumber: shelfNumberAndLetter,
      groupedPriority: priority ? getPriorityGroup(priority) : [],
      type: [type],
    });

    if ("check" in data && isNotNullNorUndefined(data.check)) {
      redirectToCheck(data.check);
    } else {
      if ("status" in data && data.status === "noMoreCheck") {
        taskCompletedToast();
      }
      redirectToOrigin();
    }
  }, [
    priority,
    redirectToCheck,
    redirectToOrigin,
    shelfNumberAndLetter,
    startNextCheck,
    taskCompletedToast,
    type,
  ]);

  const validateEoyCheck = useCallback(async () => {
    try {
      const isCompleted = await handleEoyCheck();
      if (isCompleted) {
        await handleStartNextCheck();
      } else {
        redirectToOrigin();
      }
    } catch (e: any) {
      if (!isApolloError(e as Error)) throw e;
      errorToast();
      redirectToOrigin();
    } finally {
      updateItemQuantity(0);
    }
  }, [handleEoyCheck, handleStartNextCheck, redirectToOrigin, errorToast, updateItemQuantity]);

  return { eoyCheck, validateEoyCheck, countedItems, updateItemQuantity };
};
