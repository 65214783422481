import { useMemo } from "react";

import { useAppLanguageStore } from "core/stores/useAppLanguageStore";
import { countryNameFromCode } from "libs/countryNames";

export function useCountryNames(countryCode: string | null) {
  const appLanguage = useAppLanguageStore((state) => state.appLanguage);
  return useMemo(
    () => countryNameFromCode(appLanguage, countryCode) ?? null,
    [appLanguage, countryCode],
  );
}
