import {
  BagIcon,
  BikeIcon,
  BucketBroomIcon,
  HandTruckIcon,
  ThermometerIcon,
  WarningRetryAlertIcon,
} from "ui/Icons/Icons";

import { TimedActivity } from "./types";

export const timedActivities: TimedActivity[] = [
  {
    id: "receiving-process",
    nameKey: "flows.activities.timed-activities.receiving-process.title",
    descriptionKey: "flows.activities.timed-activities.receiving-process.description",
    icon: <HandTruckIcon />,
  },
  {
    id: "TGTG-bags",
    nameKey: "flows.activities.timed-activities.TGTG-bags.title",
    descriptionKey: "flows.activities.timed-activities.TGTG-bags.description",
    icon: <BagIcon />,
  },
  {
    id: "hub-alert",
    nameKey: "flows.activities.timed-activities.hub-alert.title",
    descriptionKey: "flows.activities.timed-activities.hub-alert.description",
    icon: <WarningRetryAlertIcon />,
  },
  {
    id: "fleet-management",
    nameKey: "flows.activities.timed-activities.fleet-management.title",
    descriptionKey: "flows.activities.timed-activities.fleet-management.description",
    icon: <BikeIcon />,
  },
  {
    id: "temperature-checks",
    nameKey: "flows.activities.timed-activities.temperature-checks.title",
    descriptionKey: "flows.activities.timed-activities.temperature-checks.description",
    icon: <ThermometerIcon />,
  },
  {
    id: "cleaning",
    nameKey: "flows.activities.timed-activities.cleaning.title",
    descriptionKey: "flows.activities.timed-activities.cleaning.description",
    icon: <BucketBroomIcon />,
  },
];
