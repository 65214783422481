import { useIntl } from "react-intl";

import { YesNoModal } from "shared/components/YesNoModal";
import { PenIcon } from "ui/Icons/Icons";
import { transformChunks } from "utils/ui";

type EditTotalQuantityModalProps = {
  isOpen: boolean;
  onConfirm: () => void;
  onCancel: () => void;
};

export function EditTotalQuantityModal({
  isOpen,
  onConfirm,
  onCancel,
}: EditTotalQuantityModalProps) {
  const intl = useIntl();
  const title = intl.formatMessage({ id: "flows.inbound.pre-dropping.edit-total-quantity.title" });
  const body = intl.formatMessage(
    { id: "flows.inbound.pre-dropping.edit-total-quantity.description" },
    transformChunks({ pink: { color: "pinkFlink.500" } }),
  );
  const yesLabel = intl.formatMessage({
    id: "flows.inbound.pre-dropping.edit-total-quantity.button-label.confirm",
  });
  const noLabel = intl.formatMessage({
    id: "flows.inbound.pre-dropping.edit-total-quantity.button-label.cancel",
  });

  return (
    <YesNoModal
      isOpen={isOpen}
      onClickYes={onConfirm}
      onClickNo={onCancel}
      title={title}
      body={body}
      yesLabel={yesLabel}
      noLabel={noLabel}
      icon={<PenIcon />}
    />
  );
}
