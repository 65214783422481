import * as Types from "../../../__graphql__/types.js";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type GetProductsBySubcategoryV2QueryVariables = Types.Exact<{
  input: Types.GetProductsBySubcategoryInput;
}>;

export type GetProductsBySubcategoryV2Query = {
  __typename?: "Query";
  getProductsBySubcategoryV2: {
    __typename?: "GetProductsBySubcategoryResponse";
    products: Array<{
      __typename?: "IProduct";
      bio?: boolean | null;
      imageUrl?: string | null;
      isShelvedInHandlingUnits?: boolean | null;
      name: string;
      numberOfShelfFacings?: number | null;
      sku: string;
      countryOfOrigin: { __typename?: "CountryOfOrigin"; code?: string | null };
      inventoryEntry: { __typename?: "InventoryEntry"; shelfNumber?: string | null };
      units: Array<{
        __typename?: "Unit";
        ean?: string | null;
        id: string;
        quantity: number;
        type: string;
      }>;
    }>;
  };
};

export const GetProductsBySubcategoryV2Document = gql`
  query getProductsBySubcategoryV2($input: GetProductsBySubcategoryInput!) {
    getProductsBySubcategoryV2(input: $input) {
      products {
        bio
        imageUrl
        isShelvedInHandlingUnits
        name
        numberOfShelfFacings
        sku
        countryOfOrigin {
          code
        }
        inventoryEntry {
          shelfNumber
        }
        units {
          ean
          id
          quantity
          type
        }
      }
    }
  }
`;

/**
 * __useGetProductsBySubcategoryV2Query__
 *
 * To run a query within a React component, call `useGetProductsBySubcategoryV2Query` and pass it any options that fit your needs.
 * When your component renders, `useGetProductsBySubcategoryV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductsBySubcategoryV2Query({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetProductsBySubcategoryV2Query(
  baseOptions: Apollo.QueryHookOptions<
    GetProductsBySubcategoryV2Query,
    GetProductsBySubcategoryV2QueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProductsBySubcategoryV2Query, GetProductsBySubcategoryV2QueryVariables>(
    GetProductsBySubcategoryV2Document,
    options,
  );
}
export function useGetProductsBySubcategoryV2LazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProductsBySubcategoryV2Query,
    GetProductsBySubcategoryV2QueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetProductsBySubcategoryV2Query,
    GetProductsBySubcategoryV2QueryVariables
  >(GetProductsBySubcategoryV2Document, options);
}
export type GetProductsBySubcategoryV2QueryHookResult = ReturnType<
  typeof useGetProductsBySubcategoryV2Query
>;
export type GetProductsBySubcategoryV2LazyQueryHookResult = ReturnType<
  typeof useGetProductsBySubcategoryV2LazyQuery
>;
export type GetProductsBySubcategoryV2QueryResult = Apollo.QueryResult<
  GetProductsBySubcategoryV2Query,
  GetProductsBySubcategoryV2QueryVariables
>;
