import React from "react";

import { useAppAnalyticsEffects } from "core/hooks/useAppAnalyticsEffects";
import { useAppBackgroundEffects } from "core/hooks/useAppBackgroundEffects";
import { useAppOAOccupationEffects } from "core/hooks/useAppOAOccupationEffects";
import { useAppPWAStateEffects } from "core/hooks/useAppPWAStateEffects";
import { useAuthStateEffects } from "core/hooks/useAuthStateEffects";
import { useLogoutWhenUnauthorized } from "core/hooks/useLogoutWhenUnauthorized";

type AppEffectsProviderProps = {
  children: React.ReactNode;
};

export function AppEffectsProvider({ children }: AppEffectsProviderProps) {
  useAuthStateEffects();
  useAppAnalyticsEffects();
  useAppPWAStateEffects();
  useAppBackgroundEffects();
  useAppOAOccupationEffects();
  useLogoutWhenUnauthorized();

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
}
