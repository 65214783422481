import { createContext, useEffect, useMemo, useState } from "react";

import { useInterpret } from "@xstate/react";
import { Outlet } from "react-router-dom";
import { InterpreterFrom } from "xstate";

import { PageName } from "analytics/events";
import { useCheckRoutesEffects } from "flows/Inventory/shared/hooks/useCheckRoutesEffects";
import { serializeCheck } from "flows/Inventory/shared/models/check/serializer";
import { Check } from "flows/Inventory/shared/models/check/types";
import { useInterpreterRouteSynchronizer } from "shared/hooks/useInterpreterRouteSynchronizer";
import { keepTrackOfStateForDebugging } from "utils/xstate";

import { useStockCheckActionsImplems } from "../machines/stockCheckMachine/actions";
import {
  stockCheckMachine,
  contextInitialState,
  STOCK_CHECK_MACHINE_NAME,
} from "../machines/stockCheckMachine/machine";
import { useStockCheckServiceImplems } from "../machines/stockCheckMachine/services";
import {
  StockCheckMachineContext,
  StockCheckMachineEvents,
} from "../machines/stockCheckMachine/types";

export const XStateStockCheckContext = createContext({
  stockCheckService: {} as InterpreterFrom<typeof stockCheckMachine>,
});

type StockCheckRoutesXStateProviderProps = {
  checkId: string;
  origin: PageName;
  check: Check;
};

function StockCheckRoutesXStateProvider({
  checkId,
  origin,
  check,
}: StockCheckRoutesXStateProviderProps) {
  const actions = useStockCheckActionsImplems();
  const services = useStockCheckServiceImplems();

  // We use useState to keep the same machine reference for the whole lifetime of this component
  const [machine] = useState(() => {
    return stockCheckMachine.withContext({
      ...contextInitialState,
      checkId,
      origin,
      sku: check.sku!,
      shelfNumber: check.shelfNumber ?? null,
      shelfLetter: check.shelfLetter ?? null,
      priority: check.priority!,
    });
  });

  const stockCheckService = useInterpret(
    machine,
    {
      actions,
      services,
    },
    ({ value }) => keepTrackOfStateForDebugging("stockCheckMachine", value),
  );

  useInterpreterRouteSynchronizer<StockCheckMachineContext, StockCheckMachineEvents>(
    STOCK_CHECK_MACHINE_NAME,
    stockCheckService,
  );

  useEffect(() => {
    // Making sure we can call start so it is only triggered once
    if (stockCheckService.getSnapshot().nextEvents.includes("START")) {
      stockCheckService.send({ type: "START" });
    }
  }, [stockCheckService]);

  const values = useMemo(() => ({ stockCheckService }), [stockCheckService]);

  return (
    <XStateStockCheckContext.Provider value={values}>
      <Outlet />
    </XStateStockCheckContext.Provider>
  );
}

export function XStateStockCheckProvider() {
  const { isInvalidParams, loading, checkId, origin, data } = useCheckRoutesEffects();

  if (isInvalidParams || loading) {
    return null;
  }

  return (
    <StockCheckRoutesXStateProvider
      checkId={checkId!}
      origin={origin!}
      check={serializeCheck(data!.getTaskById.task!)}
    />
  );
}
