import { Box, BoxProps } from "@chakra-ui/react";

export type TextWrapperProps = BoxProps & { "data-testid"?: string };
export function TextWrapper({ children, ...props }: TextWrapperProps) {
  return (
    <Box as="span" {...props}>
      {children}
    </Box>
  );
}
