import { useCallback, useEffect, useRef, useState } from "react";

import { ApolloClient, ApolloError, useApolloClient } from "@apollo/client";
import { chakra, useDisclosure } from "@chakra-ui/react";
import { datadogRum } from "@datadog/browser-rum";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router";

import { IdentifierMethodType, PageName } from "analytics/events";
import { getDeviceId } from "analytics/utils";
import { config } from "config";
import { useAppLanguageStore } from "core/stores/useAppLanguageStore";
import { useAppPWAStateStore } from "core/stores/useAppPWAStateStore";
import { useEmployeeStore } from "core/stores/useEmployeeStore";
import { EppoFeatureFlags } from "core/types/flags";
import {
  ClaimDeviceDocument,
  ClaimDeviceMutation,
  ClaimDeviceMutationVariables,
  ForceClaimDeviceDocument,
  ForceClaimDeviceMutation,
  ForceClaimDeviceMutationVariables,
} from "flows/Auth/queries/deviceClaim/deviceClaim.generated";
import {
  IdentifyEmployeeDocument,
  IdentifyEmployeeMutation,
  IdentifyEmployeeMutationVariables,
} from "flows/Auth/queries/employee/employee.generated";
import { ScannableInput } from "shared/components/ScannableInput";
import { YesNoModal } from "shared/components/YesNoModal";
import { useAnalytics } from "shared/hooks/useAnalytics";
import { useEppoFeatureFlagProvider } from "shared/hooks/useEppoFeatureFlag";
import { useHubSlug } from "shared/hooks/useHubSlug";
import { Button } from "ui/Button/Button";
import { HeaderS } from "ui/Typography/Typography";
import { asyncTryCatchWithErrorChecking } from "utils/error";

interface EmployeeData {
  id: string;
  firstName: string;
  lastName: string;
  roles: string[];
}

function getErrorTextId(error: ApolloError | undefined) {
  if (!error) {
    return undefined;
  }
  switch (error.graphQLErrors[0].extensions.code) {
    case "EMPLOYEE_NOT_FOUND": {
      return "pages.auth.operator-auth.input-error-employee-not-found";
    }
    case "PUNCHED_IN_EMPLOYEE_NOT_FOUND": {
      return "pages.auth.operator-auth.input-error-not-punched-in";
    }
    case "WRONG_HUB_FOR_DEVICE_CLAIM": {
      return "pages.auth.operator-auth.input-error-wrong-hub";
    }
    default:
  }
  return "pages.auth.operator-auth.input-error-login-failed";
}

const hasValidEmployeeIdFormat = (employeeId: string) => {
  return /^\d*$/.test(employeeId) && employeeId.length <= 10;
};

const identifyEmployee = (client: ApolloClient<object>, badgeNo: string) => {
  return client.mutate<IdentifyEmployeeMutation, IdentifyEmployeeMutationVariables>({
    mutation: IdentifyEmployeeDocument,
    variables: { badgeNo },
  });
};

const claimDevice = (client: ApolloClient<object>, employeeId: string) => {
  return client.mutate<ClaimDeviceMutation, ClaimDeviceMutationVariables>({
    mutation: ClaimDeviceDocument,
    variables: { input: { employeeId } },
  });
};

const forceClaimDevice = (client: ApolloClient<object>, employeeId: string) => {
  return client.mutate<ForceClaimDeviceMutation, ForceClaimDeviceMutationVariables>({
    mutation: ForceClaimDeviceDocument,
    variables: { input: { employeeId } },
  });
};

export function EmployeeIdentification() {
  const intl = useIntl();
  const navigate = useNavigate();
  const hubSlug = useHubSlug();
  const client = useApolloClient();

  const setEmployee = useEmployeeStore((state) => state.setEmployee);
  const { analytics, sendSegmentTrackEvent } = useAnalytics();
  const appLanguage = useAppLanguageStore((state) => state.appLanguage);

  const { isFeatureEnabled: isDeviceClaimingEnabled } = useEppoFeatureFlagProvider(
    EppoFeatureFlags.DEVICE_CLAIMING_PROCESS,
  );

  const { isFeatureEnabled: isIntercomEnabled } = useEppoFeatureFlagProvider(
    EppoFeatureFlags.INTERCOM,
  );

  const { appUpdateAvailable, updateServiceWorker } = useAppPWAStateStore((state) => ({
    appUpdateAvailable: state.appUpdateAvailable,
    updateServiceWorker: state.updateServiceWorker,
  }));

  const employeeIdInput = useRef("");
  const [validationError, setValidationError] = useState<string | undefined>();
  const [isLoading, setIsLoading] = useState(false);
  const inputMethod = useRef<IdentifierMethodType>("manual");

  const {
    isOpen: isForceDeviceClaimModalOpen,
    onOpen: openForceDeviceClaimModal,
    onClose: closeForceDeviceClaimModal,
  } = useDisclosure();

  const handleChangeEmployeeIdValue = useCallback(
    (value: string) => {
      employeeIdInput.current = value;
      setValidationError(undefined);
    },
    [setValidationError],
  );

  const handleError = useCallback(
    (error: ApolloError) => {
      if (error.graphQLErrors[0].extensions.code === "EMPLOYEE_ALREADY_HAS_CLAIM") {
        openForceDeviceClaimModal();
      } else {
        setValidationError(intl.formatMessage({ id: getErrorTextId(error) }));
      }

      sendSegmentTrackEvent("errorShown", {
        screen_name: PageName.USER_IDENTIFICATION_PAGE,
        component_value: error.message,
      });
      setIsLoading(false);
    },
    [openForceDeviceClaimModal, setValidationError, sendSegmentTrackEvent, intl],
  );

  const handleLoginSuccess = useCallback(
    ({ id, roles, firstName, lastName }: EmployeeData) => {
      setEmployee({
        badgeNo: id,
        roles,
        firstName,
        lastName,
      });
      // id set to null until we are able to give an Auth0ID or similar
      analytics.identify(null, {
        quinyx_badge_number: id,
        hub_slug: hubSlug,
        device_id: getDeviceId(),
      });
      if (isIntercomEnabled) {
        Intercom("boot", {
          app_id: config.environment.INTERCOM_APP_ID,
          user_id: id,
          hub_slug: hubSlug,
          language_override: appLanguage,
          country_iso: hubSlug?.split("_")[0],
          env: process.env.NODE_ENV,
        });
      }
      datadogRum.setUser({ id, name: `${firstName} ${lastName}`, hub_slug: hubSlug });
      sendSegmentTrackEvent("loginCompleted", {
        type: "staff",
        hub_slug: hubSlug!,
        quinyx_badge_number: id,
        identifier_method: inputMethod.current,
      });
      navigate("/", { replace: true });
    },
    [
      analytics,
      appLanguage,
      hubSlug,
      isIntercomEnabled,
      navigate,
      sendSegmentTrackEvent,
      setEmployee,
    ],
  );

  const identify = useCallback(async () => {
    setIsLoading(true);
    const employeeId = employeeIdInput.current;
    if (!hasValidEmployeeIdFormat(employeeId)) {
      return;
    }

    const identifyEmployeeResult = await asyncTryCatchWithErrorChecking(
      () => identifyEmployee(client, employeeId),
      handleError,
    );
    if (!identifyEmployeeResult?.data?.identifyEmployee) {
      return;
    }

    if (isDeviceClaimingEnabled) {
      const claimDeviceResult = await asyncTryCatchWithErrorChecking(
        () => claimDevice(client, employeeId),
        handleError,
      );
      if (!claimDeviceResult) {
        return;
      }
    }

    handleLoginSuccess({
      id: employeeId,
      ...identifyEmployeeResult.data.identifyEmployee,
    });
  }, [client, handleError, isDeviceClaimingEnabled, handleLoginSuccess]);

  const onFormSubmit = useCallback(
    (e?: React.FormEvent<HTMLFormElement>) => {
      e?.preventDefault();
      inputMethod.current = "manual";
      identify();
    },
    [identify],
  );

  useEffect(() => {
    if (appUpdateAvailable && updateServiceWorker) {
      updateServiceWorker();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appUpdateAvailable]);

  const onClickConfirmForceDeviceClaim = useCallback(async () => {
    setIsLoading(true);
    if (!hasValidEmployeeIdFormat(employeeIdInput.current)) {
      return;
    }
    const identifyEmployeeResult = await asyncTryCatchWithErrorChecking(
      () => identifyEmployee(client, employeeIdInput.current),
      handleError,
    );
    if (!identifyEmployeeResult?.data?.identifyEmployee) {
      return;
    }

    const forceClaimDeviceResult = await asyncTryCatchWithErrorChecking(
      () => forceClaimDevice(client, employeeIdInput.current),
      handleError,
    );
    if (!forceClaimDeviceResult) {
      closeForceDeviceClaimModal();
      return;
    }

    handleLoginSuccess({
      id: employeeIdInput.current,
      ...identifyEmployeeResult.data.identifyEmployee,
    });
  }, [client, closeForceDeviceClaimModal, handleError, handleLoginSuccess]);

  const isSubmitButtonDisabled = isLoading || !hasValidEmployeeIdFormat(employeeIdInput.current);

  return (
    <>
      <HeaderS>
        <FormattedMessage id="pages.auth.operator-auth.title" />
      </HeaderS>
      <chakra.form w="100%" onSubmit={onFormSubmit}>
        <ScannableInput
          inputMode="numeric"
          error={validationError}
          onScan={(scannedValue) => {
            handleChangeEmployeeIdValue(scannedValue);
            inputMethod.current = "qr_scan";
            identify();
          }}
          isAutoFocus
          onValueChange={handleChangeEmployeeIdValue}
          data-testid="input-scannable-input"
          placeholder={intl.formatMessage({
            id: "pages.auth.operator-auth.input-placeholder",
          })}
        />
        <Button
          isLoading={isLoading}
          isDisabled={isSubmitButtonDisabled}
          onClick={() => {
            inputMethod.current = "manual";
            identify();
          }}
          size="lg"
          flinkVariant="primary"
          data-testid="button-submit-button"
          width="full"
          mt="s300"
        >
          <FormattedMessage id="pages.auth.operator-auth.submit-button-label" />
        </Button>
      </chakra.form>
      <YesNoModal
        isOpen={isForceDeviceClaimModalOpen}
        onClickYes={onClickConfirmForceDeviceClaim}
        onClickNo={closeForceDeviceClaimModal}
        title={intl.formatMessage({
          id: "pages.auth.operator-auth.force-device-claim-modal.title",
        })}
        body={intl.formatMessage({
          id: "pages.auth.operator-auth.force-device-claim-modal.body",
        })}
        yesLabel={intl.formatMessage({
          id: "pages.auth.operator-auth.force-device-claim-modal.confirm-button-label",
        })}
        noLabel={intl.formatMessage({
          id: "pages.auth.operator-auth.force-device-claim-modal.decline-button-label",
        })}
      />
    </>
  );
}
