import { ReactElement, cloneElement } from "react";

import { Flex } from "@chakra-ui/react";

import { TitleXS } from "ui/Typography/Typography";

type IconTextPillProps = {
  icon: ReactElement;
  text: string;
  testId?: string;
};

export function IconTextPill({ icon, text, testId }: IconTextPillProps) {
  return (
    <Flex alignItems="center" gap="s50" data-testid={testId}>
      {cloneElement(icon)}
      <TitleXS>{text}</TitleXS>
    </Flex>
  );
}
