import { Config } from "./types";

export const productionConfig: Config = {
  environment: {
    ENVIRONMENT: "production",
    HUB_ONE_CORE_API_URL: "https://api.goflink.com/hub-one-core/hub-one-core",
    FORCE_PWA: true,
    AUTH0_AUDIENCE: "https://api.goflink.com",
    AUTH0_DOMAIN: "flink-prod.eu.auth0.com",
    AUTH0_CLIENT_ID: "eGMI3IZDQlSBrwesWQxGEuAaSp9bTS7p",
    IDLE_LOGOUT_MINUTES: 40,
    CLOUDINARY_BASE_URL: "https://res.cloudinary.com/goflink/image/upload",
    SEGMENT_WRITE_KEY: "LQhdoe2I94ZYlhHxSvYCdZk7foqgu1wj",
    OPTIMIZELY_SDK_KEY: "V6JSYtS11qZ3Phxda2b8E",
    DATADOG_APPLICATION_ID: "86aceac2-449a-4e20-9d05-22666af1f498",
    DATADOG_CLIENT_TOKEN: "pubbf0c4d05c2d3bd6caedbe851f6a3bf54",
    EPPO_URL: "https://api.goflink.com/user-tracking-gateway/user-tracking-gateway",
    INTERCOM_APP_ID: "csvc20v5",
  },
};
