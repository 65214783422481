import { useEffect } from "react";

import { useEmployeeStore } from "core/stores/useEmployeeStore";
import { useEmployeeLogout } from "flows/Auth/hooks/useEmployeeLogout";

export const useLogoutWhenUnauthorized = () => {
  const isSetForLogout = useEmployeeStore((state) => state.isSetForLogout);
  const logout = useEmployeeLogout();

  return useEffect(() => {
    if (isSetForLogout) {
      logout("automatic");
    }
  }, [isSetForLogout, logout]);
};
