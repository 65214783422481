import { useCallback, useState } from "react";

import { ObservableQuery } from "@apollo/client/core";
import { datadogRum } from "@datadog/browser-rum";
import shallow from "zustand/shallow";

import { InboundingList } from "__graphql__/types";
import {
  CollaborativeInboundProgressedAction,
  CollaborativeInboundProgressedMethod,
} from "analytics/events";
import { EppoFeatureFlags } from "core/types/flags";
import { useCustomToast, VARIANT_INFO_SUCCESS } from "shared/hooks/useCustomToast";
import { useEppoFeatureFlagProvider } from "shared/hooks/useEppoFeatureFlag";
import { useFetchInboundPreDroppingProducts } from "shared/stores/useProductSearchService";

import { useInboundEventTracker } from "../hooks/useInboundEventTracker/useInboundEventTracker";
import {
  useDeleteInboundingListMutation,
  useDeleteInboundingListProductsMutation,
  useEditInboundingListMutation,
  useEditProductsInInboundingListMutation,
} from "../queries/collaborativeInbound/collaborativeInbound.generated";
import { useGetMultipleDesadvsRolliIdLazyQuery } from "../queries/despatchAdvice/despatchAdvice.generated";
import { DRAFT_LIST_PERSIST_ID, useInboundStore } from "./inboundStore/useInboundStore";
import { extractInboundPlan } from "./inboundStore/utils";

const EDIT_SHARED_LIST_ERROR_TOAST_ID = "edit-shared-list-error-toast";
const EDIT_PREDROPPING_QUANTITY_ERROR_TOAST_ID = "edit-predropping-quanity-error-toast";
const DELETE_DRAFT_SHARED_LIST_SUCCESS_TOAST_ID = "delete-draft-shared-list-success-toast";
const DELETE_DRAFT_SHARED_LIST_ERROR_TOAST_ID = "delete-draft-shared-list-error-toast";
const DELETE_PRODUCT_ERROR_TOAST_ID = "delete-product-error-toast";

export const useEditSharedListName = () => {
  const [isUpdatingName, setIsUpdatingName] = useState(false);
  const { showToastUI } = useCustomToast();
  const setSharedListName = useInboundStore((state) => state.setSharedListName);

  const handleError = useCallback(
    (error: unknown, listId: string, name: string) => {
      setIsUpdatingName(false);
      const errorMessage = error instanceof Error ? error.message : "Unknown error occurred";
      datadogRum.addError("Failed to update shared list name", {
        listId,
        name,
        errorMessage,
      });
      showToastUI({
        id: EDIT_SHARED_LIST_ERROR_TOAST_ID,
        title: "edit-shared-list-error-toast",
      });
    },
    [showToastUI],
  );

  const [updateSharedListNameMutation] = useEditInboundingListMutation();

  const editSharedListName = useCallback(
    async (listId: string, name: string) => {
      try {
        setIsUpdatingName(true);
        await updateSharedListNameMutation({
          variables: { input: { listId, name } },
        });
        setSharedListName(name);
      } catch (error) {
        handleError(error, listId, name);
      } finally {
        setIsUpdatingName(false);
      }
    },
    [handleError, setSharedListName, updateSharedListNameMutation],
  );

  return { editSharedListName, isUpdatingName };
};

export const usePreDropQuantityUpdate = () => {
  const { sendInboundProgressed } = useInboundEventTracker();
  const [isUpdatingPreDropQuantity, setIsUpdatingPreDropQuantity] = useState(false);
  const [isHUUpdate, setIsUUpdate] = useState(false);
  const { showToastUI } = useCustomToast();

  const {
    inboundUnitsStockUpdates,
    inboundUnitsDisplayStates,
    updateInboundQuantityBySku,
    updateHandlingUnitSize,
  } = useInboundStore((state) => ({
    inboundUnitsDisplayStates: state.inboundUnitsDisplayStates,
    inboundUnitsStockUpdates: state.inboundUnitsStockUpdates,
    updateInboundQuantityBySku: state.updateInboundQuantityBySku,
    updateHandlingUnitSize: state.updateHandlingUnitSize,
  }));

  const [editPreDropQuantity, { error: editPreDropQuantityError }] =
    useEditProductsInInboundingListMutation();

  const handleError = useCallback(
    (error: unknown) => {
      const errorMessage = error instanceof Error ? error.message : "An unknown error occurred.";
      datadogRum.addError("Edit Pre-dropping Quantity Error", { errorMessage });
      showToastUI({
        id: EDIT_PREDROPPING_QUANTITY_ERROR_TOAST_ID,
        title: "edit-predropping-quanity-error-toast",
      });
      setIsUpdatingPreDropQuantity(false);
    },
    [showToastUI],
  );

  const calculateNewQuantity = useCallback(
    (sku: string, oldValue: number, newValue: number): number => {
      const stockPlan = inboundUnitsStockUpdates[sku]?.stockUpdatePlan;
      const currentDelta = extractInboundPlan(stockPlan)?.quantityDelta ?? 0;
      return (currentDelta / oldValue) * newValue;
    },
    [inboundUnitsStockUpdates],
  );

  const updatePreDropQuantity = useCallback(
    async ({
      listId,
      sku,
      oldValue,
      newValue,
      method,
    }: {
      listId: string;
      sku: string;
      oldValue: number;
      newValue: number;
      method: CollaborativeInboundProgressedMethod;
    }) => {
      const isHandlingUnitUpdate = method === CollaborativeInboundProgressedMethod.ManualHU;
      setIsUUpdate(isHandlingUnitUpdate);
      try {
        setIsUpdatingPreDropQuantity(true);
        const { unitSizeForDisplay } = inboundUnitsDisplayStates[sku];
        const quantity = isHandlingUnitUpdate
          ? calculateNewQuantity(sku, oldValue, newValue)
          : newValue * unitSizeForDisplay;
        const handlingUnitSize = isHandlingUnitUpdate ? newValue : unitSizeForDisplay;
        const { data } = await editPreDropQuantity({
          variables: {
            input: {
              listId,
              products: [{ sku, quantity, handlingUnitSize }],
            },
          },
        });

        if (data?.editProductsInInboundingList.success) {
          if (isHandlingUnitUpdate) {
            updateHandlingUnitSize(sku, newValue);
            showToastUI({
              id: EDIT_PREDROPPING_QUANTITY_ERROR_TOAST_ID,
              variant: VARIANT_INFO_SUCCESS,
              title: "edit-predropping-quanity-error-toast",
            });
          } else {
            updateInboundQuantityBySku(sku, newValue);
          }
          sendInboundProgressed({
            sku,
            action: CollaborativeInboundProgressedAction.ProductUpdatedQuantity,
            method,
            quantityInfo: { newValue, oldValue },
          });
        }
      } catch (error) {
        handleError(error);
      } finally {
        setIsUpdatingPreDropQuantity(false);
      }
    },
    [
      calculateNewQuantity,
      editPreDropQuantity,
      handleError,
      inboundUnitsDisplayStates,
      sendInboundProgressed,
      showToastUI,
      updateHandlingUnitSize,
      updateInboundQuantityBySku,
    ],
  );

  return {
    updatePreDropQuantity,
    editPreDropQuantityError,
    isUpdatingPreDropQuantity,
    isHUUpdate,
  };
};

export const useDeleteDraftInboundList = (refetchInboundingLists: ObservableQuery["refetch"]) => {
  const { showToastUI } = useCustomToast();
  const [isDeletingDraftInboundList, setIsDeletingDraftInboundList] = useState(false);
  const [deleteDraftInboundListMutation] = useDeleteInboundingListMutation({
    onCompleted: () => {
      showToastUI({
        status: "success",
        variant: "success",
        id: DELETE_DRAFT_SHARED_LIST_SUCCESS_TOAST_ID,
        title: "delete-draft-shared-list-success-toast",
      });
    },
  });
  const resetPreDroppingState = useInboundStore((state) => state.resetPreDroppingState);
  const deleteDraftInboundList = useCallback(
    async (listId: string) => {
      setIsDeletingDraftInboundList(true);
      try {
        await deleteDraftInboundListMutation({
          variables: { input: { listId } },
        });
        await refetchInboundingLists();
        resetPreDroppingState();
        localStorage.removeItem(DRAFT_LIST_PERSIST_ID);
      } catch (error) {
        const errorMessage =
          error instanceof Error
            ? error.message
            : "An unknown error occurred while deleting the draft inbound list.";
        datadogRum.addError("Failed to delete draft inbound list", { errorMessage });
        showToastUI({
          id: DELETE_DRAFT_SHARED_LIST_ERROR_TOAST_ID,
          title: "delete-draft-shared-list-error-toast",
        });
      } finally {
        setIsDeletingDraftInboundList(false);
      }
    },
    [deleteDraftInboundListMutation, refetchInboundingLists, resetPreDroppingState, showToastUI],
  );

  return { deleteDraftInboundList, isDeletingDraftInboundList };
};

export const useDeleteUnclaimedProduct = () => {
  const [isDeletingUnclaimedProduct, setIsDeletingUnclaimedProduct] = useState(false);
  const { showToastUI } = useCustomToast();
  const { sendInboundProgressed } = useInboundEventTracker();

  const { isFeatureEnabled: isCollaborativeInboundV2Enabled } = useEppoFeatureFlagProvider(
    EppoFeatureFlags.COLLABORATIVE_INBOUND_V2,
  );

  const removeInboundUnitFromPreDroppingList = useInboundStore(
    (state) => state.removeInboundUnitFromPreDroppingList,
  );

  const handleError = useCallback(
    (error: unknown) => {
      setIsDeletingUnclaimedProduct(false);
      const errorMessage = error instanceof Error ? error.message : "Unknown error occurred";
      datadogRum.addError("Failed to delete Unclaimed Product Error", { errorMessage });
      showToastUI({
        id: DELETE_PRODUCT_ERROR_TOAST_ID,
        title: "delete-product-error-toast",
      });
    },
    [showToastUI],
  );

  const [deleteInboundingListProducts] = useDeleteInboundingListProductsMutation();

  const deleteUnClaimedProduct = useCallback(
    async (listId: string, sku: string) => {
      try {
        setIsDeletingUnclaimedProduct(true);
        if (isCollaborativeInboundV2Enabled) {
          await deleteInboundingListProducts({
            variables: { input: { listId, skus: [sku] } },
          });
        }

        sendInboundProgressed({
          sku,
          action: CollaborativeInboundProgressedAction.ProductRemovedFromList,
        });
        removeInboundUnitFromPreDroppingList(sku);
      } catch (error) {
        handleError(error);
      } finally {
        setIsDeletingUnclaimedProduct(false);
      }
    },
    [
      deleteInboundingListProducts,
      handleError,
      isCollaborativeInboundV2Enabled,
      removeInboundUnitFromPreDroppingList,
      sendInboundProgressed,
    ],
  );

  return { deleteUnClaimedProduct, isDeletingUnclaimedProduct };
};

export function useHandleDraftListContinuation() {
  const { setDeliverySSCC, setSharedListId, setSharedListName, updateDespatchAdviceItems } =
    useInboundStore(
      (state) => ({
        setDeliverySSCC: state.setDeliverySSCC,
        setSharedListId: state.setSharedListId,
        setSharedListName: state.setSharedListName,
        updateDespatchAdviceItems: state.updateDespatchAdviceItems,
      }),
      shallow,
    );

  const [fetchDesadvs] = useGetMultipleDesadvsRolliIdLazyQuery({
    onCompleted: (data) =>
      updateDespatchAdviceItems(data?.getMultipleDesadvsRolliID?.despatchAdvices ?? null),
    onError: (error) => {
      const errorMessage = error instanceof Error ? error.message : "An unknown error occurred.";
      datadogRum.addError("Error fetching despatch advices for delivery SSCC:", { errorMessage });
    },
  });

  const { fetchInboundPreDroppingProducts } = useFetchInboundPreDroppingProducts();

  const handleDraftListContinuation = useCallback(
    async (latestDraftList: InboundingList) => {
      setSharedListId(latestDraftList.id);
      setSharedListName(latestDraftList.name);
      if (latestDraftList.sscc) {
        setDeliverySSCC(latestDraftList.sscc);
        await fetchDesadvs({ variables: { input: { rolliID: latestDraftList.sscc } } });
      }
      await fetchInboundPreDroppingProducts(latestDraftList.products);
    },
    [
      fetchDesadvs,
      fetchInboundPreDroppingProducts,
      setDeliverySSCC,
      setSharedListId,
      setSharedListName,
    ],
  );

  return { handleDraftListContinuation };
}
