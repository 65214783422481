export interface Item {
  id: string;
  name: string | null;
  imageUrl: string | null;
  shelf: string | null;
  sku: string | null;
  quantity: number;
  availableQuantity: number;
  eans: Array<string | null>;
  shelfLetter: string | null;
  shelfNumber: string | null;
  countryOfOriginCode: string | null;
  hasSingleUnit: boolean;
  handlingUnitSize: number | null;
}

export enum UnitTypes {
  "SINGLE" = "single",
  "HANDLING" = "handling",
}
