export enum RestockingItemStatus {
  done = "DONE",
  new = "NEW",
}

type RestockingProduct = {
  sku: string;
  name: string;
  imageUrl: string | null;
  isBio: boolean | null;
  countryOfOriginCode: string | null;
  shelfLetter: string | null;
  shelfNumber: string | null;
  shelf: string | null;
  stockOnShelf: number;
  numberOfShelfFacings: number | null;
  isShelvedInHandlingUnits: boolean | null;
};

type TranslatedRestockingProduct = RestockingProduct & {
  countryOfOriginName: string | null;
};

type PublicRestockingProduct = Omit<
  RestockingProduct,
  "numberOfShelfFacings" | "isShelvedInHandlingUnits"
>;

export type PublicRestockingItem = {
  sku: string;
  product: PublicRestockingProduct | null;
};

export type RestockingItem = {
  sku: string;
  product: RestockingProduct | null;
  status: RestockingItemStatus;
};

export type TranslatedRestockingItem = Omit<RestockingItem, "product"> & {
  product: TranslatedRestockingProduct | null;
};
