import { useRef, useCallback, useEffect, useState } from "react";

function useSwipeScroll(
  scrollContainerRef: React.RefObject<HTMLDivElement | null>,
  wrapperRef: React.RefObject<HTMLDivElement | null>,
) {
  const isHorizontalScrollListenerSetRef = useRef(false);
  const isSnappingBackRef = useRef(false);
  const [isScrolledLeft, setIsScrollLeft] = useState<boolean>(false);

  const snapWrapperBack = useCallback(() => {
    // This function helps us automatically snap back the swiped card
    // if the user decides to scroll the whole page down
    if (!isSnappingBackRef.current) {
      isSnappingBackRef.current = true;
      wrapperRef.current?.scrollTo({ left: 0, behavior: "smooth" });
      const checkIfScrollToIsFinished = setInterval(() => {
        if (wrapperRef.current?.scrollLeft === 0) {
          isSnappingBackRef.current = false;
          clearInterval(checkIfScrollToIsFinished);
        }
      }, 25);
    }
  }, [wrapperRef]);

  const handleSwipeScroll = useCallback(
    (event: React.UIEvent<HTMLDivElement>) => {
      if (event.currentTarget.scrollLeft === 0 && isHorizontalScrollListenerSetRef.current) {
        scrollContainerRef.current?.removeEventListener("scroll", snapWrapperBack);
        isHorizontalScrollListenerSetRef.current = false;
        setIsScrollLeft(false);
      } else if (event.currentTarget.scrollLeft > 0 && !isHorizontalScrollListenerSetRef.current) {
        setIsScrollLeft(true);
        scrollContainerRef.current?.addEventListener("scroll", snapWrapperBack);
        isHorizontalScrollListenerSetRef.current = true;
      }
    },
    [scrollContainerRef, snapWrapperBack],
  );

  useEffect(() => {
    const scrollContainerRefAtTheTime = scrollContainerRef.current;
    return () => {
      // Clean up to make sure the listener is removed when component unmounts
      scrollContainerRefAtTheTime?.removeEventListener("scroll", snapWrapperBack);
    };
  }, [snapWrapperBack, scrollContainerRef]);

  return { scrollContainerRef, handleSwipeScroll, snapWrapperBack, isScrolledLeft };
}

export default useSwipeScroll;
