import { useIntl } from "react-intl";

import { YesNoModal } from "shared/components/YesNoModal";

export type ConfirmLargeQuantityChangeModalProps = {
  onClickConfirm: () => void;
  onClickDecline: () => void;
  isOpen: boolean;
  quantity?: number;
  customeTitle?: string;
};

export const shouldShowLargeQuantityChangeModal = (expected: number, actual: number) => {
  if (expected < 12) {
    return Math.abs(expected - actual) >= 3;
  }

  return Math.abs(expected - actual) >= 6;
};

export function ConfirmLargeQuantityChangeModal({
  onClickConfirm,
  onClickDecline,
  isOpen,
  quantity,
  customeTitle,
}: ConfirmLargeQuantityChangeModalProps) {
  const intl = useIntl();
  const title =
    customeTitle ||
    intl.formatMessage(
      { id: "components.inbound.confirm-large-quantity-change-modal.title" },
      { quantity },
    );
  return (
    <YesNoModal
      isOpen={isOpen}
      onClickYes={onClickConfirm}
      onClickNo={onClickDecline}
      title={title}
      yesLabel={intl.formatMessage({
        id: "components.inbound.confirm-large-quantity-change-modal.confirm-button-label",
      })}
      noLabel={intl.formatMessage({
        id: "components.inbound.confirm-large-quantity-change-modal.decline-button-label",
      })}
    />
  );
}
