import { Circle, ModalProps } from "@chakra-ui/react";
import { useIntl } from "react-intl";

import { CtaModal } from "ui/CtaModal";
import { NoConnectionIcon } from "ui/Icons/Icons";
import { BodyM, HeaderS } from "ui/Typography/Typography";

export type ConnectionErrorModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onRefresh: () => void;
} & Pick<ModalProps, "onClose" | "isOpen">;

export function ConnectionErrorModal({
  isOpen = false,
  onClose,
  onRefresh,
}: ConnectionErrorModalProps) {
  const intl = useIntl();
  return (
    <CtaModal
      onClose={onClose}
      isOpen={isOpen}
      body={
        <>
          <Circle size="120px" bg="grey.100" m="16px auto">
            <NoConnectionIcon boxSize="66.67px" />
          </Circle>
          <HeaderS textAlign="center">
            {intl.formatMessage({ id: "components.connection-error-modal.header" })}
          </HeaderS>
          <BodyM textAlign="center" my="s200">
            {intl.formatMessage({ id: "components.connection-error-modal.body" })}
          </BodyM>
        </>
      }
      ctaLabel={intl.formatMessage({ id: "components.connection-error-modal.cta" })}
      onClickCta={onRefresh}
      hideCloseButton
    />
  );
}
