import { theme } from "@chakra-ui/react";

export const sizes = {
  ...theme.space,
  modal: {
    sm: "617px",
    md: "956px",
  },
  container: {
    sm: "640px",
    md: "768px",
    lg: "1080px",
    xl: "1440px",
  },
};
