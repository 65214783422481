import { developmentConfig } from "./development.config";
import { productionConfig } from "./production.config";
import { stagingConfig } from "./staging.config";
import { Config } from "./types";

const getConfig = (): Config => {
  switch (import.meta.env.EXP_ENVIRONMENT) {
    case "production":
      return productionConfig;
    case "staging":
      return stagingConfig;
    case "development":
    default:
      return developmentConfig;
  }
};
export const config = getConfig();
