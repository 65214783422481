import { useCallback, useEffect, useRef, useState } from "react";

import errorAudio from "audio/error.mp3";
import newOrderAudio from "audio/new-order-notification.mp3";

const AUDIO_PLAY_RETRY_INTERVAL_MS = 1000;

export enum AudioSound {
  newOrder,
  error,
}

function getAudioSrcFromEnum(audioSrc: AudioSound) {
  switch (audioSrc) {
    case AudioSound.newOrder: {
      return newOrderAudio;
    }
    case AudioSound.error: {
      return errorAudio;
    }
    default: {
      return "";
    }
  }
}

async function tryToPlay(audio: HTMLAudioElement) {
  let tryTimeout: NodeJS.Timeout | undefined;
  try {
    await audio.play();
    clearTimeout(tryTimeout);
  } catch {
    tryTimeout = setTimeout(() => tryToPlay(audio), AUDIO_PLAY_RETRY_INTERVAL_MS);
  }
}

export function useAudio(audioSrc: AudioSound, loop = false) {
  const audioRef = useRef(new Audio(getAudioSrcFromEnum(audioSrc)));
  const [playing, setPlaying] = useState(false);

  const play = useCallback(async () => {
    if (audioRef.current.paused) {
      await tryToPlay(audioRef.current);
      setPlaying(true);
    }
  }, []);

  const pause = useCallback(async () => {
    if (!audioRef.current.paused) {
      audioRef.current.pause();
      setPlaying(false);
    }
  }, []);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const audio = audioRef.current;
    if (loop) {
      audio.loop = true;
      return () => {
        audio.loop = false;
        audio.pause();
      };
    }
    const stopPlaying = () => setPlaying(false);
    audio.addEventListener("ended", stopPlaying);
    return () => {
      audio.removeEventListener("ended", stopPlaying);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { playing, play, pause };
}
