import { useCallback, useEffect } from "react";

import { Box, Center, Flex, Image } from "@chakra-ui/react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router";

import { InboundingList, InboundingListStatus } from "__graphql__/types";
import { routes } from "config/routes";
import { HEIGHT } from "core/components/Header";
import { EndDroppingProcessButton } from "flows/Inbound/components/EndDroppingProcessButton";
import { SharedListComponent } from "flows/Inbound/components/SharedListComponent";
import { useGetInboundingListsQuery } from "flows/Inbound/queries/collaborativeInbound/collaborativeInbound.generated";
import { useInboundStore } from "flows/Inbound/stores/inboundStore/useInboundStore";
import { useClaimInboundList } from "flows/Inbound/stores/useInboundDropService";
import {
  useDeleteDraftInboundList,
  useHandleDraftListContinuation,
} from "flows/Inbound/stores/useInboundPreDropService";
import DeliveryTruckNew from "images/random/delivery-truck-new.svg";
import { IntlMessageId } from "shared/types/lang";
import { BOTTOM_TAB_BAR_HEIGHT_NUMBER } from "ui/BottomTabBarContainer";
import { Spinner } from "ui/Spinner/Spinner";
import { BodyM, HeaderS, TitleM } from "ui/Typography/Typography";

type CategorizedLists = {
  draft: InboundingList[];
  unclaimed: InboundingList[];
};

function InboundSharedListPlaceholder() {
  return (
    <Flex
      h={`calc(100% - ${HEIGHT})`}
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      px="s100"
      gap="s100"
    >
      <HeaderS textAlign="center">
        <FormattedMessage id="flows.inbound.page.inbound-shared-list.header" />
      </HeaderS>
      <BodyM textAlign="center">
        <FormattedMessage id="flows.inbound.page.inbound-shared-list.paragraph" />
      </BodyM>
      <Image src={DeliveryTruckNew} mt="s200" />
    </Flex>
  );
}

export function InboundSharedList() {
  const navigate = useNavigate();
  const { isClaimingList } = useClaimInboundList();
  const resetToInitialState = useInboundStore((state) => state.resetToInitialState);
  useEffect(() => {
    resetToInitialState();
  }, [resetToInitialState]);

  const {
    data,
    loading: isFetchingShareList,
    refetch: refetchInboundingLists,
  } = useGetInboundingListsQuery({
    fetchPolicy: "network-only",
  });

  const { deleteDraftInboundList, isDeletingDraftInboundList } =
    useDeleteDraftInboundList(refetchInboundingLists);

  const sharedLists = data?.getInboundingLists?.lists;
  const sortedLists = [...(sharedLists || [])].sort(
    (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
  );
  const categorizedLists = sortedLists.reduce<CategorizedLists>(
    (acc, list) => {
      if (list.status === InboundingListStatus.inbounding_list_status_draft) {
        acc.draft.push(list);
      } else if (list.status === InboundingListStatus.inbounding_list_status_unclaimed) {
        acc.unclaimed.push(list);
      }
      return acc;
    },
    { draft: [], unclaimed: [] },
  );
  const hasDraftList = categorizedLists.draft.length > 0;
  const latestDraftList = categorizedLists.draft[0];
  const hasSharedLists = Object.values(categorizedLists).some((lists) => lists.length > 0);

  const { handleDraftListContinuation } = useHandleDraftListContinuation();

  const onClickContinue = useCallback(async () => {
    if (!latestDraftList.id) return;
    await handleDraftListContinuation(latestDraftList);
  }, [handleDraftListContinuation, latestDraftList]);

  const onClickCreateNewList = useCallback(() => {
    navigate(routes.inbound.selectDelivery);
  }, [navigate]);

  if (isFetchingShareList || isClaimingList || isDeletingDraftInboundList) {
    return (
      <Center h="100%" w="100%">
        <Spinner />
      </Center>
    );
  }

  return (
    <Flex flex={1} flexDirection="column" alignItems="center" justifyContent="flex-start" px="s100">
      {hasSharedLists ? (
        <Flex w="100%" flexDirection="column" justifyContent="flex-start" gap="s100" pb="72px">
          {Object.entries(categorizedLists).map(
            ([category, lists]) =>
              lists.length > 0 && (
                <Flex key={category} flexDirection="column" justifyContent="flex-start" gap="s100">
                  <Box px="s200" pt="s200">
                    <TitleM textAlign="left">
                      <FormattedMessage
                        id={
                          `flows.inbound.page.inbound-shared-list.inbound-list.${category}` as IntlMessageId
                        }
                      />
                    </TitleM>
                  </Box>
                  {lists.map((sharedList) => (
                    <SharedListComponent
                      key={sharedList.id}
                      listId={sharedList.id}
                      listName={sharedList.name}
                      deliverySSCC={sharedList.sscc}
                      createdAt={sharedList.createdAt}
                      status={sharedList.status}
                      products={sharedList.products}
                      deleteDraftInboundList={deleteDraftInboundList}
                    />
                  ))}
                </Flex>
              ),
          )}
        </Flex>
      ) : (
        <InboundSharedListPlaceholder />
      )}
      <EndDroppingProcessButton
        shouldShowButton
        onClickButton={hasDraftList ? onClickContinue : onClickCreateNewList}
        labelMessageId={
          hasDraftList
            ? "flows.inbound.page.inbound-shared-list.button.continue"
            : "flows.inbound.page.inbound-shared-list.button"
        }
        isLoading={false}
        bottom={BOTTOM_TAB_BAR_HEIGHT_NUMBER}
        px="s200"
      />
    </Flex>
  );
}
