import { useCallback } from "react";

import { Center, Flex, FlexProps } from "@chakra-ui/react";

import { TitleM } from "ui/Typography/Typography";

export interface LetterRange {
  id: string;
  from: string;
  to: string;
  disabled: boolean;
}

export type LetterRangeSelectorProps = {
  value: string | null;
  letterRanges: LetterRange[];
  onInput: (id: string) => void;
};

const getColor = (letterRange: LetterRange, isSelected: (letterRange: LetterRange) => boolean) => {
  if (letterRange.disabled) {
    return "grey.200";
  }
  return isSelected(letterRange) ? "pinkFlink.500" : "grey.800";
};

const getBgColor = (
  letterRange: LetterRange,
  isSelected: (letterRange: LetterRange) => boolean,
) => {
  return letterRange.disabled || !isSelected(letterRange) ? "transparent" : "pinkFlink.100";
};

export function LetterRangeSelector({
  value,
  letterRanges,
  onInput,
  ...rest
}: LetterRangeSelectorProps & FlexProps) {
  const isSelected = useCallback((letterRange: LetterRange) => value === letterRange.id, [value]);

  const onClick = useCallback(
    (letterRange: LetterRange) => {
      if (letterRange.disabled || isSelected(letterRange)) {
        return;
      }
      onInput(letterRange.id);
    },
    [isSelected, onInput],
  );

  return (
    <Flex justify="space-between" {...rest}>
      {letterRanges.map((letterRange) => {
        const color = getColor(letterRange, isSelected);
        const bgColor = getBgColor(letterRange, isSelected);
        return (
          <Center
            key={letterRange.id}
            h="30px"
            w="39px"
            borderRadius="sm"
            bgColor={bgColor}
            onClick={() => onClick(letterRange)}
          >
            <TitleM color={color}>{`${letterRange.from}-${letterRange.to}`}</TitleM>
          </Center>
        );
      })}
    </Flex>
  );
}
