import { useCallback, useRef, useState } from "react";

import { chakra, FormControl, FormErrorMessage, Input, InputGroup } from "@chakra-ui/react";
import { FormattedMessage, useIntl } from "react-intl";
import shallow from "zustand/shallow";

import { EppoFeatureFlags } from "core/types/flags";
import { DespatchAdviceNotFoundModal } from "flows/Inbound/components/DespatchAdviceNotFoundModal";
import { useInboundService } from "flows/Inbound/hooks/useInboundService";
import { useInboundUIStore } from "flows/Inbound/stores/useInboundUIStore";
import { Page } from "shared/components/Page";
import { useCustomToast } from "shared/hooks/useCustomToast";
import { useEppoFeatureFlagProvider } from "shared/hooks/useEppoFeatureFlag";
import { useScan } from "shared/hooks/useScan/useScan";
import { ScanningFailedIcon } from "ui/Icons/Icons";
import { PagePlaceholder } from "ui/PagePlaceholder/PagePlaceholder";
import { Spinner } from "ui/Spinner/Spinner";
import { BodyS, DetailL } from "ui/Typography/Typography";
import { isValidSSCCFormat } from "utils/scan";
import { transformChunks } from "utils/ui";

const INVALID_ROLLI_FORMAT_TOAST_ID = "invalid_rolli_format_toast";

export function InboundScanRolliPage() {
  const intl = useIntl();
  const [hasError, setHasError] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const inboundService = useInboundService();
  const inputRef = useRef<HTMLInputElement>(null);
  const { showToastUI } = useCustomToast();
  const { isFeatureEnabled: isCollaborativeInboundEnabled } = useEppoFeatureFlagProvider(
    EppoFeatureFlags.COLLABORATIVE_INBOUND,
  );

  const { shouldShowDespatchAdviceModal, setInboundUIState } = useInboundUIStore(
    (state) => ({
      shouldShowDespatchAdviceModal: state.shouldShowDespatchAdviceModal,
      setInboundUIState: state.setInboundUIState,
    }),
    shallow,
  );

  // SCAN
  useScan({
    onScan: (scannedValue) => {
      if (!isValidSSCCFormat(scannedValue)) {
        showToastUI({
          id: INVALID_ROLLI_FORMAT_TOAST_ID,
          title: "flows.inbound.scan-rolli.invalid-rolli-format.title",
        });
        return;
      }
      inboundService.send({ type: "SCAN_ROLLI", deliverySSCC: scannedValue });
    },
  });

  const onClickContinue = () => {
    setInboundUIState({ shouldShowDespatchAdviceModal: false });
    inboundService.send({ type: "SKIP_ROLLI_VALIDATION" });
  };

  const onClickTryNew = () => {
    if (inputRef.current) {
      inputRef.current.value = "";
    }
    setInboundUIState({ shouldShowDespatchAdviceModal: false });
  };

  // Manual Input
  const onFormSubmit = useCallback(
    (e?: React.FormEvent<HTMLFormElement>) => {
      e?.preventDefault();
      if (inputRef.current) {
        inputRef.current.blur();
        setIsDirty(true);
        if (!isValidSSCCFormat(inputRef.current.value)) {
          setHasError(true);
          return;
        }
        setInboundUIState({ shouldShowDespatchAdviceModal: false });
        inboundService.send({ type: "SCAN_ROLLI", deliverySSCC: inputRef.current.value });
      }
    },
    [inboundService, setInboundUIState],
  );

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (isDirty) {
        setHasError(!isValidSSCCFormat(e.target.value));
      }
    },
    [isDirty],
  );

  const isLoadingDespatchAdvice = !isCollaborativeInboundEnabled
    ? inboundService.getSnapshot().matches("inbounding.loadingDespatchAdvice")
    : false;

  return (
    <Page isCentered isBgGrey>
      {isLoadingDespatchAdvice ? (
        <Spinner />
      ) : (
        <>
          <PagePlaceholder
            icon={<ScanningFailedIcon />}
            title={intl.formatMessage({ id: "flows.inbound.scan-rolli.placeholder-title" })}
            subtitle={
              <FormattedMessage
                id="flows.inbound.scan-rolli.placeholder-subtitle"
                values={transformChunks({ pink: { as: "strong", color: "pinkFlink.500" } })}
              />
            }
          />
          <chakra.form w="100%" onSubmit={onFormSubmit}>
            <FormControl isInvalid={hasError}>
              <InputGroup size="lg" marginBottom="s50" marginTop="s100">
                <Input
                  inputMode="numeric"
                  name="rolliId"
                  ref={inputRef}
                  _focusVisible={{ outline: "none" }}
                  onChange={onChange}
                  bg="white"
                  placeholder={intl.formatMessage({
                    id: "flows.inbound.scan-rolli.input-placeholder",
                  })}
                />
              </InputGroup>
              <FormErrorMessage bottom="-20px" left="4px">
                <BodyS fontWeight="500" color="error">
                  <FormattedMessage id="flows.inbound.scan-rolli.input-invalid" />
                </BodyS>
              </FormErrorMessage>
              <DetailL fontWeight="400" paddingX="s200">
                <FormattedMessage id="flows.inbound.scan-rolli.sub-paragraph" />
              </DetailL>
            </FormControl>
          </chakra.form>
          <DespatchAdviceNotFoundModal
            isOpen={shouldShowDespatchAdviceModal}
            onClickContinue={onClickContinue}
            onClickTryNew={onClickTryNew}
          />
        </>
      )}
    </Page>
  );
}
