import { ButtonProps } from "@chakra-ui/react";
import { useSelector } from "@xstate/react";
import { FormattedMessage } from "react-intl";

import { usePickingService } from "flows/Picking/hooks/usePickingService";
import { Button } from "ui/Button/Button";

type EndPickingButtonProps = {
  isButtonDisabled?: boolean;
  onValidateContinue?: () => boolean;
  beforeSubmit?: () => void;
} & ButtonProps;

export function EndPickingButton({
  isButtonDisabled = false,
  beforeSubmit = () => {},
  onValidateContinue = () => true,
  ...rest
}: EndPickingButtonProps) {
  const pickingService = usePickingService();
  const { send } = pickingService;

  const endPickingLoading = useSelector(pickingService, (state) =>
    state.matches("endPicking.loading"),
  );
  const endPickingError = useSelector(pickingService, (state) => state.matches("endPicking.error"));

  const onClickButton = () => {
    if (onValidateContinue()) {
      beforeSubmit();
      send({ type: endPickingError ? "RETRY_END_PICKING" : "END_PICKING" });
    }
  };

  const buttonLabelId = endPickingError
    ? "components.picking.end-picking-button.button-label-retry-end-picking"
    : "components.picking.end-picking-button.button-label-finish-picking";

  return (
    <Button
      isLoading={endPickingLoading}
      isDisabled={isButtonDisabled}
      onClick={onClickButton}
      size="lg"
      width="full"
      data-testid="finish-picking-button"
      {...rest}
    >
      <FormattedMessage id={buttonLabelId} />
    </Button>
  );
}
