import * as Types from "../../../../__graphql__/types.js";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type IdentifyEmployeeMutationVariables = Types.Exact<{
  badgeNo: Types.Scalars["String"];
}>;

export type IdentifyEmployeeMutation = {
  __typename?: "Mutation";
  identifyEmployee: {
    __typename?: "HubOneEmployee";
    badgeNo: string;
    firstName: string;
    isActive?: boolean | null;
    lastName: string;
    roles: Array<string>;
  };
};

export const IdentifyEmployeeDocument = gql`
  mutation identifyEmployee($badgeNo: String!) {
    identifyEmployee(badgeNo: $badgeNo) {
      badgeNo
      firstName
      isActive
      lastName
      roles
    }
  }
`;
export type IdentifyEmployeeMutationFn = Apollo.MutationFunction<
  IdentifyEmployeeMutation,
  IdentifyEmployeeMutationVariables
>;

/**
 * __useIdentifyEmployeeMutation__
 *
 * To run a mutation, you first call `useIdentifyEmployeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIdentifyEmployeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [identifyEmployeeMutation, { data, loading, error }] = useIdentifyEmployeeMutation({
 *   variables: {
 *      badgeNo: // value for 'badgeNo'
 *   },
 * });
 */
export function useIdentifyEmployeeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IdentifyEmployeeMutation,
    IdentifyEmployeeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IdentifyEmployeeMutation, IdentifyEmployeeMutationVariables>(
    IdentifyEmployeeDocument,
    options,
  );
}
export type IdentifyEmployeeMutationHookResult = ReturnType<typeof useIdentifyEmployeeMutation>;
export type IdentifyEmployeeMutationResult = Apollo.MutationResult<IdentifyEmployeeMutation>;
export type IdentifyEmployeeMutationOptions = Apollo.BaseMutationOptions<
  IdentifyEmployeeMutation,
  IdentifyEmployeeMutationVariables
>;
