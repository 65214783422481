import { useCallback } from "react";

import { useApolloClient } from "@apollo/client";

import {
  GetPublicRestockingListDocument,
  GetPublicRestockingListQuery,
  GetPublicRestockingListQueryVariables,
} from "shared/queries/publicRestockingList/publicRestockingList.generated";

import {
  SaveSkuToPublicRestockingListDocument,
  SaveSkuToPublicRestockingListMutation,
  SaveSkuToPublicRestockingListMutationVariables,
} from "../queries/publicRestockingList/publicRestockingList.generated";

export const usePublicRestockingListQueries = () => {
  const apollo = useApolloClient();

  const getPublicRestockingListQuery = useCallback(() => {
    return apollo.query<GetPublicRestockingListQuery, GetPublicRestockingListQueryVariables>({
      query: GetPublicRestockingListDocument,
      fetchPolicy: "network-only",
    });
  }, [apollo]);

  const addItemToPublicRestockingListMutation = useCallback(
    (sku: string) => {
      return apollo.mutate<
        SaveSkuToPublicRestockingListMutation,
        SaveSkuToPublicRestockingListMutationVariables
      >({
        mutation: SaveSkuToPublicRestockingListDocument,
        variables: { input: { sku } },
      });
    },
    [apollo],
  );

  return { getPublicRestockingListQuery, addItemToPublicRestockingListMutation };
};
