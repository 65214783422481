import { Flex, Text, IconButton } from "@chakra-ui/react";

import { CloseIcon } from "ui/Icons/Icons";

type RemovableListItemProps = {
  title: string;
  onClickRemove: () => void;
};

export function RemovableListItem({ onClickRemove, title }: RemovableListItemProps) {
  return (
    <Flex
      borderRadius="12px"
      w="100%"
      mt="s100"
      backgroundColor="grey.100"
      data-testid="removable-item"
      alignItems="center"
      pl="s200"
      pr="s50"
      justifyContent="space-between"
    >
      <Text>{title}</Text>
      <IconButton
        variant="ghost"
        onClick={onClickRemove}
        icon={<CloseIcon />}
        aria-label="on-remove"
      />
    </Flex>
  );
}
