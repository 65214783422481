import { useCallback, useEffect } from "react";

import {
  Circle,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
} from "@chakra-ui/react";
import { FormattedMessage } from "react-intl";
import { generatePath, useNavigate } from "react-router-dom";
import shallow from "zustand/shallow";

import { PageName } from "analytics/events";
import { routes } from "config/routes";
import { useLayoutStore } from "core/stores/useLayoutStore";
import { useAnalytics } from "shared/hooks/useAnalytics";
import { useTimedActivityStore } from "shared/stores/useTimedActivityStore";
import { Button } from "ui/Button/Button";
import { OngoingWarningIcon } from "ui/Icons/Icons";
import { BodyM, HeaderS } from "ui/Typography/Typography";
import { isNotNullNorUndefined, isNullOrUndefined } from "utils/tsHelpers";

const closeOngoingActivityBanner = () =>
  useLayoutStore.setState({ showOngoingTimedActivityModalWithContent: null });

export function OngoingTimedActivityModal() {
  const navigate = useNavigate();
  const { sendSegmentTrackEvent } = useAnalytics();

  const showOngoingTimedActivityModalWithContent = useLayoutStore(
    (state) => state.showOngoingTimedActivityModalWithContent,
  );

  const showOngoingTimedActivityModal = isNotNullNorUndefined(
    showOngoingTimedActivityModalWithContent,
  );
  const { stopTimedActivity, timedActivityInstance } = useTimedActivityStore(
    (state) => ({
      stopTimedActivity: state.stopTimedActivity,
      timedActivityInstance: state.timedActivityInstance,
    }),
    shallow,
  );

  const onClickContinueActivity = useCallback(() => {
    closeOngoingActivityBanner();
    if (isNotNullNorUndefined(timedActivityInstance)) {
      navigate(
        generatePath(routes.activities.timedActivities.details, {
          activityId: timedActivityInstance.activityID,
        }),
      );
    }
    sendSegmentTrackEvent("click", {
      component_name: "ongoing_activity",
      component_variation: "continue_activity",
      screen_name: useLayoutStore.getState().pageName ?? PageName.LAYOUT,
    });
  }, [navigate, timedActivityInstance, sendSegmentTrackEvent]);

  const onClickFinishActivity = useCallback(() => {
    closeOngoingActivityBanner();
    if (isNullOrUndefined(timedActivityInstance)) {
      return;
    }
    stopTimedActivity();
    sendSegmentTrackEvent("click", {
      component_name: "ongoing_activity",
      component_variation: "finish_activity",
      screen_name: useLayoutStore.getState().pageName ?? PageName.LAYOUT,
    });
    sendSegmentTrackEvent("hubActivityFinished", {
      screen_name: useLayoutStore.getState().pageName ?? PageName.LAYOUT,
      category_type: timedActivityInstance.activityID,
      hub_activity_id: timedActivityInstance.activityInstanceID,
      origin: "origin_activity_popup",
      task_completion_time_seconds: Math.floor(
        (Date.now() - timedActivityInstance.startTime.getTime()) / 1000,
      ),
    });
  }, [stopTimedActivity, timedActivityInstance, sendSegmentTrackEvent]);

  useEffect(() => {
    if (showOngoingTimedActivityModal) {
      sendSegmentTrackEvent("inAppMessageShown", {
        component_name: "ongoing_activity",
        screen_name: useLayoutStore.getState().pageName ?? PageName.LAYOUT,
      });
    }
  }, [showOngoingTimedActivityModal, sendSegmentTrackEvent]);

  return (
    <Modal
      trapFocus={false}
      closeOnOverlayClick={false}
      isCentered
      isOpen={showOngoingTimedActivityModal}
      onClose={() => {}}
    >
      <ModalOverlay bg="rgba(0,0,0,0.2)" />
      <ModalContent marginX="s300">
        <ModalBody display="flex" flexDir="column" alignItems="center" textAlign="center" p="s200">
          <Circle bg="grey.100" size="120px">
            <OngoingWarningIcon boxSize="66.67px" />
          </Circle>
          <HeaderS mt="s200">
            <FormattedMessage id="core.components.ongoing-timed-activity-modal.title" />
          </HeaderS>
          <BodyM mt="s100" color="grey.700">
            {showOngoingTimedActivityModal && (
              <FormattedMessage id={showOngoingTimedActivityModalWithContent} />
            )}
          </BodyM>
        </ModalBody>
        <ModalFooter paddingX="s200" pt="s100" flexDir="column">
          <Button
            onClick={onClickContinueActivity}
            flinkVariant="primary"
            size="lg"
            width="full"
            data-testid="ongoing-timed-activity-modal-continue-button"
          >
            <FormattedMessage id="core.components.ongoing-timed-activity-modal.continue-button-label" />
          </Button>
          <Button
            mt="s100"
            onClick={onClickFinishActivity}
            flinkVariant="secondary"
            size="lg"
            width="full"
            data-testid="ongoing-timed-activity-modal-finish-button"
          >
            <FormattedMessage id="core.components.ongoing-timed-activity-modal.finish-button-label" />
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
