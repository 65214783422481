import { useCallback } from "react";

import { Center, Flex, Image } from "@chakra-ui/react";
import { FormattedMessage } from "react-intl";

import { usePickingService } from "flows/Picking/hooks/usePickingService";
import IncompleteOrderIcon from "images/random/incomplete-order.svg";
import { Page } from "shared/components/Page";
import { Button } from "ui/Button/Button";
import { BodyM, HeaderS } from "ui/Typography/Typography";

export function PickingIncomplete() {
  const pickingService = usePickingService();

  const handleClickBanner = useCallback(() => {
    pickingService.send({ type: "BACK_TO_PICKING" });
  }, [pickingService]);

  return (
    <Page isCentered scrollBehavior="auto">
      <Center h="100%" w="100%">
        <Flex direction="column" w="100%" align="center">
          <Flex direction="column" w="70%" align="center">
            <HeaderS textAlign="center" marginY="s300">
              <FormattedMessage id="pages.picking.picking-stuck.placeholder-title" />
            </HeaderS>

            <Image src={IncompleteOrderIcon} borderRadius="sm" ml="s150" mr="s300" />
            <BodyM marginY="s300" textAlign="center">
              <FormattedMessage id="pages.picking.picking-stuck.placeholder-subtitle" />
            </BodyM>
          </Flex>
          <Flex direction="column" w="100%" align="center">
            <Button onClick={handleClickBanner} flinkVariant="primary" size="lg" width="full">
              <FormattedMessage id="pages.picking.picking-stuck.return-order" />
            </Button>
          </Flex>
        </Flex>
      </Center>
    </Page>
  );
}
