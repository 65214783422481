import { useMemo } from "react";

import { useAuth0 } from "@auth0/auth0-react";
import { Box, Switch } from "@chakra-ui/react";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router";
import shallow from "zustand/shallow";

import { PageName } from "analytics/events";
import { useAppUpdate } from "core/hooks/useAppUpdate";
import { AppLanguage, useAppLanguageStore } from "core/stores/useAppLanguageStore";
import { useAppPWAStateStore } from "core/stores/useAppPWAStateStore";
import { useEmployeeStore } from "core/stores/useEmployeeStore";
import { useFeatureFlagOverridesStore } from "core/stores/useFeatureFlagOverridesStore";
import { openOngoingActivityModal, useLayoutStore } from "core/stores/useLayoutStore";
import { EppoFeatureFlags } from "core/types/flags";
import { useEmployeeLogout } from "flows/Auth/hooks/useEmployeeLogout";
import DeFlag from "images/flags/de.png";
import NlFlag from "images/flags/nl.png";
import EnFlag from "images/flags/uk.png";
import { useAnalytics } from "shared/hooks/useAnalytics";
import { useEppoFeatureFlagProvider } from "shared/hooks/useEppoFeatureFlag";
import { useHubSlug } from "shared/hooks/useHubSlug";
import { useTimedActivityStore } from "shared/stores/useTimedActivityStore";
import {
  ArrowLeftIcon,
  Chat2Icon,
  ChatIcon,
  CheckIcon,
  LogoutIcon,
  ScanIcon,
  VersionIcon,
} from "ui/Icons/Icons";
import { Menu as MenuUI, MenuItem } from "ui/Menu/Menu";
import { DetailL, TitleM } from "ui/Typography/Typography";
import { isNotNullNorUndefined } from "utils/tsHelpers";

function appLanguageToImage(language: AppLanguage) {
  return {
    [AppLanguage.De]: DeFlag,
    [AppLanguage.Nl]: NlFlag,
    [AppLanguage.En]: EnFlag,
  }[language];
}

export function Menu() {
  const { logout: hubLogout, isAuthenticated } = useAuth0();
  const navigate = useNavigate();
  const intl = useIntl();
  const { appLanguage, setAppLanguage } = useAppLanguageStore((state) => ({
    appLanguage: state.appLanguage,
    setAppLanguage: state.setAppLanguage,
  }));
  const { resetLocalFeatureFlags } = useFeatureFlagOverridesStore(
    (state) => ({
      resetLocalFeatureFlags: state.resetLocalFeatureFlags,
    }),
    shallow,
  );

  const hubSlug = useHubSlug();
  const employeeLogout = useEmployeeLogout();
  const { sendSegmentTrackEvent } = useAnalytics();
  const { isFeatureEnabled: isIntercomEnabled } = useEppoFeatureFlagProvider(
    EppoFeatureFlags.INTERCOM,
  );
  const { appUpdateAvailable, updateServiceWorker } = useAppPWAStateStore((state) => ({
    appUpdateAvailable: state.appUpdateAvailable,
    updateServiceWorker: state.updateServiceWorker,
  }));

  const { setAppLayout, isMenuOpen, isLanguageMenuOpen, toggleMenu, withMenu } = useLayoutStore(
    (state) => ({
      setAppLayout: state.setAppLayout,
      isMenuOpen: state.isMenuOpen,
      isLanguageMenuOpen: state.isLanguageMenuOpen,
      toggleMenu: state.toggleMenu,
      withMenu: state.withMenu,
    }),
    shallow,
  );

  const { isIdentified, firstName, lastName, badgeNo, autoScan, setEmployee } = useEmployeeStore(
    (state) => ({
      isIdentified: state.isIdentified,
      firstName: state.firstName,
      lastName: state.lastName,
      badgeNo: state.badgeNo,
      autoScan: state.autoScan,
      setEmployee: state.setEmployee,
    }),
    shallow,
  );

  const timedActivityInstance = useTimedActivityStore((state) => state.timedActivityInstance);

  const updateApp = useAppUpdate();

  const handleEmployeeLogout = () => employeeLogout("manual");

  const handleHubLogout = () => {
    sendSegmentTrackEvent("logoutCompleted", {
      type: "hub",
      hub_slug: hubSlug!,
      method: "manual",
    });
    resetLocalFeatureFlags();
    hubLogout({ returnTo: window.location.origin });
  };

  const handleLogout = (logoutCallBack: () => void) => () => {
    if (isNotNullNorUndefined(timedActivityInstance)) {
      openOngoingActivityModal("core.components.ongoing-timed-activity-modal.logout-description");
      toggleMenu();
      return;
    }
    logoutCallBack();
    toggleMenu();
    navigate("/", { replace: true });
  };

  const toggleAutoScan = () => {
    setEmployee({ autoScan: !autoScan });
  };

  const handleLanguageSwitch = (lang: AppLanguage) => {
    sendSegmentTrackEvent("click", {
      screen_name: PageName.LAYOUT,
      component_name: "language_selector",
      component_value: lang,
    });
    setAppLanguage(lang);
    setAppLayout({ isLanguageMenuOpen: false, isMenuOpen: false });
  };

  const menuItems: MenuItem[] = useMemo(() => {
    if (isLanguageMenuOpen) {
      return [
        {
          title: (
            <TitleM>
              <FormattedMessage id="components.layout.menu.language-selection-title" />
            </TitleM>
          ),
          icon: <ArrowLeftIcon boxSize="1.5rem" />,
          onClick: () => setAppLayout({ isLanguageMenuOpen: false }),
        },
        ...Object.values(AppLanguage).map((lang) => ({
          title: intl.formatMessage({
            id: `components.layout.menu.language-selection-${lang}`,
          }),
          icon: (
            <img
              src={`${appLanguageToImage(lang)}`}
              alt={`${lang} flag`}
              height="24px"
              width="24px"
            />
          ),
          onClick: appLanguage !== lang ? () => handleLanguageSwitch(lang) : undefined,
          rightIcon:
            appLanguage === lang ? <CheckIcon color="pinkFlink.500" boxSize="1.2rem" /> : undefined,
        })),
      ];
    }
    if (isIdentified) {
      return [
        {
          title: intl.formatMessage({ id: "components.layout.menu.language" }),
          icon: <ChatIcon />,
          onClick: () => setAppLayout({ isLanguageMenuOpen: true }),
        },
        {
          title: intl.formatMessage({ id: "components.layout.menu.auto-scan" }),
          icon: <ScanIcon />,
          rightIcon: (
            <Switch
              size="md"
              colorScheme="teal"
              defaultChecked={autoScan}
              onChange={toggleAutoScan}
            />
          ),
        },
        ...(isIntercomEnabled
          ? [
              {
                title: intl.formatMessage({ id: "components.layout.menu.chat" }),
                icon: <Chat2Icon />,
                onClick: () => {
                  Intercom("show");
                },
              },
            ]
          : []),
        {
          title: intl.formatMessage(
            { id: "components.layout.menu.app-version" },
            { appVersion: import.meta.env.EXP_CI_GIT_TAG || "" },
          ),
          subtitle: appUpdateAvailable ? (
            <DetailL color="pinkFlink.500">
              <FormattedMessage id="components.layout.menu.app-version-update-subtitle" />
            </DetailL>
          ) : undefined,
          icon: <VersionIcon />,
          showIconNotification: appUpdateAvailable,
          onClick:
            appUpdateAvailable && isNotNullNorUndefined(updateServiceWorker)
              ? updateApp
              : undefined,
        },
        {
          title: intl.formatMessage({ id: "components.layout.menu.user-logout" }),
          icon: <LogoutIcon />,
          onClick: handleLogout(handleEmployeeLogout),
        },
      ];
    }
    return [
      {
        title: intl.formatMessage({ id: "components.layout.menu.warehouse-logout" }),
        icon: <LogoutIcon />,
        onClick: handleLogout(handleHubLogout),
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isIdentified,
    isLanguageMenuOpen,
    autoScan,
    toggleAutoScan,
    appUpdateAvailable,
    updateServiceWorker,
    updateApp,
  ]);

  if (!withMenu || !isMenuOpen || !isAuthenticated) {
    return null;
  }

  return (
    <>
      <Box
        position="absolute"
        top="0"
        left="0"
        right="0"
        bottom="0"
        cursor="pointer"
        bg="rgba(0, 0, 0, 0.1)"
        marginTop="0"
        onClick={toggleMenu}
      />
      <MenuUI
        userName={!isLanguageMenuOpen && isIdentified ? `${firstName} ${lastName}` : undefined}
        badgeNo={!isLanguageMenuOpen && isIdentified ? badgeNo : undefined}
        position="absolute"
        top="0"
        left="0"
        width="100%"
        items={menuItems}
      />
    </>
  );
}
