import { Box, BoxProps } from "@chakra-ui/react";
import { FormattedMessage } from "react-intl";

import { IntlMessageId } from "shared/types/lang";
import { Button } from "ui/Button/Button";

type EndDroppingProcessButtonProps = {
  shouldShowButton?: boolean;
  values?: Record<string, any>;
  onClickButton: () => void;
  labelMessageId: IntlMessageId;
  buttonTestId?: string;
  isLoading?: boolean;
} & BoxProps;

export function EndDroppingProcessButton({
  labelMessageId,
  values,
  onClickButton,
  shouldShowButton = true,
  buttonTestId,
  isLoading = false,
  ...rest
}: EndDroppingProcessButtonProps) {
  return (
    <Box
      py="s150"
      px="s100"
      width="100%"
      pos="fixed"
      height="72px" // Need a fixed height to avoid flickering view when scrolling to bottom
      {...rest}
    >
      {shouldShowButton && (
        <Button
          width="full"
          flinkVariant="primary"
          size="lg"
          data-testid={buttonTestId}
          onClick={onClickButton}
          isLoading={isLoading}
        >
          <FormattedMessage id={labelMessageId} values={values} />
        </Button>
      )}
    </Box>
  );
}
