import { Circle, ModalProps } from "@chakra-ui/react";
import { useIntl } from "react-intl";

import { CtaModal } from "ui/CtaModal";
import { ClosedIcon } from "ui/Icons/Icons";
import { BodyM, HeaderS } from "ui/Typography/Typography";

export type TaskIsTakenModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onRefresh: () => void;
} & Pick<ModalProps, "onClose" | "isOpen">;

export function TaskIsTakenModal({ isOpen = false, onClose, onRefresh }: TaskIsTakenModalProps) {
  const intl = useIntl();
  return (
    <CtaModal
      onClose={onClose}
      isOpen={isOpen}
      body={
        <>
          <Circle size="120px" bg="grey.50" m="16px auto">
            <ClosedIcon boxSize="66.67px" />
          </Circle>
          <HeaderS textAlign="center">
            {intl.formatMessage({ id: "components.task-is-taken-modal.header" })}
          </HeaderS>
          <BodyM textAlign="center" my="s200">
            {intl.formatMessage({ id: "components.task-is-taken-modal.body" })}
          </BodyM>
        </>
      }
      ctaLabel={intl.formatMessage({ id: "components.task-is-taken-modal.cta" })}
      onClickCta={onRefresh}
      hideCloseButton
    />
  );
}
