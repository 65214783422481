import { SwipeablePageWrapper } from "shared/components/SwipeablePageWrapper";
import { useFFConfigPageSecretScan } from "shared/hooks/useFFConfigPageSecretScan";

import { DeliveryCheckInHistory } from "../components/DeliveryCheckInHistory/DeliveryCheckInHistory";
import { TimedActivities } from "../components/TimedActivitiesList/TimedActivities";

export function ActivitiesIndexPage() {
  useFFConfigPageSecretScan();

  return (
    <SwipeablePageWrapper
      isFull
      minHeight="100%"
      justifyContent="flex-start"
      refetch={() => {
        // TODO: reimplement this
        // window.location.reload();
        // The window.location.reload() can't be used here because the user employee credentials are not persisted in the local storage in production.
        // When the page reload the user is redirected to the employee identification page
        // https://github.com/goflink/hub-staff-app/blob/c8d28b92c9972ae490a9aca83f0f6f124a6db023/src/stores/useEmployeeStore.ts#L40
        // Issue: https://goflink.slack.com/archives/C03UH8FK3QV/p1676392759027119
      }}
      data-testid="activities-index-page"
    >
      <TimedActivities />
      <DeliveryCheckInHistory />
    </SwipeablePageWrapper>
  );
}
