import { ModalProps } from "@chakra-ui/react";
import { FormattedMessage, useIntl } from "react-intl";

import { CtaModal } from "ui/CtaModal";
import { ScanningFailedIcon } from "ui/Icons/Icons";
import { BodyM } from "ui/Typography/Typography";

export type ScanningFailedModalProps = {
  onClickConfirm: () => void;
} & Pick<ModalProps, "isOpen">;

export function ScanningFailedModal({ isOpen = false, onClickConfirm }: ScanningFailedModalProps) {
  const intl = useIntl();

  return (
    <CtaModal
      isOpen={isOpen}
      onClose={() => {}}
      hideCloseButton
      onClickCta={onClickConfirm}
      headerIcon={<ScanningFailedIcon height="60px" width="60px" />}
      title={intl.formatMessage({ id: "components.inbound.scanning-failed-modal.title" })}
      ctaLabel={intl.formatMessage({ id: "components.inbound.scanning-failed-modal.cta-label" })}
      body={
        <BodyM textAlign="center">
          <FormattedMessage id="components.inbound.scanning-failed-modal.description" />
        </BodyM>
      }
    />
  );
}
