import { TextWrapper, TextWrapperProps } from "utils/TextWrapper";

type ChunkTransformer = (chunks: React.ReactNode[]) => JSX.Element;

export function transformChunks(
  config: Record<string, TextWrapperProps>,
): Record<string, ChunkTransformer> {
  const transformerMap: Record<string, ChunkTransformer> = {};
  Object.keys(config).forEach((chunk) => {
    transformerMap[chunk] = (chunks: React.ReactNode[]) =>
      TextWrapper({
        children: chunks,
        ...config[chunk],
      });
  });
  return transformerMap;
}
