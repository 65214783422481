import { useCallback } from "react";

import { datadogLogs } from "@datadog/browser-logs";
import { datadogRum } from "@datadog/browser-rum";

import { parseGS1128Barcode } from "utils/scan";
import { isNullOrUndefined } from "utils/tsHelpers";

import { useCustomToast } from "./useCustomToast";

const SEARCH_UNIT_BY_EAN_VALIDATION_ERROR_TOAST_ID = "search_unit_by_ean_validation_error";

export function useBarcodeScanner() {
  const { showToastUI } = useCustomToast();

  const showErrorToastAndLog = useCallback(
    (barcode: string) => {
      datadogLogs.logger.info("Scanned barcode is invalid EAN", {
        barcode,
      });
      showToastUI({
        id: SEARCH_UNIT_BY_EAN_VALIDATION_ERROR_TOAST_ID,
        title: "hooks.use-barcode.request-failed-title",
        description: "hooks.use-barcode.request-failed-description",
      });
    },
    [showToastUI],
  );

  const processBarcode = useCallback(
    (value: string): string | null => {
      let searchedEan = value;
      if (value.length > 14) {
        // if the barcode is longer than 14, we'll try to interpret it as a GS1-128 barcode
        try {
          const parsedBarcode = parseGS1128Barcode(value);
          const ean = parsedBarcode.parsedCodeItems.find((item) =>
            ["GTIN", "CONTENT"].includes(item.dataTitle),
          )?.data;
          if (isNullOrUndefined(ean)) {
            showErrorToastAndLog(value);
            return null;
          }
          searchedEan = ean.replace(/^0+/, "");
        } catch (error) {
          showErrorToastAndLog(searchedEan);
          datadogRum.addError(`Failed to parse barcode`, { error });
        }
      }
      if (/[^0-9]/.test(searchedEan)) {
        showErrorToastAndLog(searchedEan);
        return null;
      }
      return searchedEan;
    },
    [showErrorToastAndLog],
  );

  return { processBarcode };
}
