import { useCallback, useEffect } from "react";

import { useAuth0 } from "@auth0/auth0-react";
import { Box, SlideFade } from "@chakra-ui/react";
import { useIntl } from "react-intl";
import { useMatch, useNavigate } from "react-router";
import shallow from "zustand/shallow";

import { routes } from "config/routes";
import { HEIGHT as HEADER_HEIGHT } from "core/components/Header";
import { useAppBackgroundEffectsStore } from "core/stores/useAppBackgroundEffectsStore";
import { useLayoutStore } from "core/stores/useLayoutStore";
import { useNewOrderBannerStore } from "core/stores/useNewOrderBannerStore";
import { useWaitingOrdersSound } from "shared/hooks/useWaitingOrdersSound";
import { Banner as NewOrderBannerUi, BANNER_HEIGHT } from "ui/Banner/Banner";
import { preventPropagation } from "utils/domEvents";
import { isNotNullNorUndefined } from "utils/tsHelpers";

export function NewOrderBanner() {
  const { isAuthenticated } = useAuth0();
  const intl = useIntl();
  const navigate = useNavigate();

  const waitingOrdersBannerType = useLayoutStore((state) => state.withWaitingOrdersBannerOfType);
  const { summaryOfNextOrderForPicking } = useAppBackgroundEffectsStore((state) => ({
    summaryOfNextOrderForPicking: state.summaryOfNextOrderForPicking,
  }));

  const { canShowBanner, setNewOrderPending, setUserClosedBanner, reset } = useNewOrderBannerStore(
    (state) => ({
      canShowBanner: state.canShowBanner,
      setNewOrderPending: state.setNewOrderPending,
      setUserClosedBanner: state.setUserClosedBanner,
      reset: state.reset,
    }),
    shallow,
  );

  const isOnPickingIndexPage = !!useMatch({
    path: routes.picking.root,
    end: true,
  });

  const isOnDeliveryCheckinIndexPage = !!useMatch({
    path: routes.activities.deliveryCheckIn,
    end: true,
  });

  const setUserClosed = useCallback(() => {
    setUserClosedBanner();
  }, [setUserClosedBanner]);

  const handleClickBanner = useCallback(() => {
    navigate(routes.picking.root);
  }, [navigate]);

  useEffect(() => {
    if (isOnPickingIndexPage && isNotNullNorUndefined(summaryOfNextOrderForPicking)) {
      // Being on the picking index page and seeing that there is a new order available for picking
      // is considered as a new order acknowledgment, so, equivalent to manually closing the banner
      setUserClosed();
    }
  }, [isOnPickingIndexPage, setUserClosed, summaryOfNextOrderForPicking]);

  useEffect(() => {
    if (isNotNullNorUndefined(summaryOfNextOrderForPicking)) {
      if (waitingOrdersBannerType !== "none") {
        setNewOrderPending(summaryOfNextOrderForPicking.id);
      }
    } else {
      reset();
    }
  }, [reset, setNewOrderPending, summaryOfNextOrderForPicking, waitingOrdersBannerType]);

  const shouldShowWaitingOrdersBanner =
    isAuthenticated && canShowBanner && !isOnDeliveryCheckinIndexPage;

  const shouldStopWaitingOrdersSound =
    !shouldShowWaitingOrdersBanner || waitingOrdersBannerType === "disabled";

  useWaitingOrdersSound(shouldStopWaitingOrdersSound);

  const bannerUI = (() => {
    if (!shouldShowWaitingOrdersBanner) {
      return null;
    }
    if (waitingOrdersBannerType === "disabled-unidentified") {
      return (
        <NewOrderBannerUi
          title={intl.formatMessage({ id: "components.toast.new-order-toast-title" })}
          onClose={preventPropagation(setUserClosed)}
          description={intl.formatMessage({
            id: "components.toast.new-order-toast-disabled-text",
          })}
        />
      );
    }
    if (waitingOrdersBannerType === "disabled") {
      return (
        <NewOrderBannerUi
          title={intl.formatMessage({ id: "components.toast.new-order-toast-title" })}
          onClose={preventPropagation(setUserClosed)}
          description={intl.formatMessage({
            id: "components.toast.new-order-toast-disabled-text.complete-task",
          })}
        />
      );
    }
    return (
      <NewOrderBannerUi
        onClickBanner={handleClickBanner}
        title={intl.formatMessage({ id: "components.toast.new-order-toast-title" })}
        onClose={preventPropagation(setUserClosed)}
        buttonText={intl.formatMessage({
          id: "components.toast.new-order-toast-start-picking",
        })}
        dataTestId="new-order-banner"
      />
    );
  })();

  return (
    <Box position="absolute" top={HEADER_HEIGHT} left="0" right="0" zIndex="banner">
      <SlideFade unmountOnExit in={shouldShowWaitingOrdersBanner} offsetY={`-${BANNER_HEIGHT}`}>
        {bannerUI}
      </SlideFade>
    </Box>
  );
}
