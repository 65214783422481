import { useCallback } from "react";

import { useSelector } from "@xstate/react";
import shallow from "zustand/shallow";

import { useInboundUIStore } from "flows/Inbound/stores/useInboundUIStore";
import { SpinnerModal } from "shared/components/SpinnerModal";
import { useProductSearchService } from "shared/hooks/useProductSearchService";

import { ScanningFailedModal } from "./ScanningFailedModal";

export function PreDroppingListModals() {
  const productSearchService = useProductSearchService();

  const { setInboundUIState, showScanningFailedModal } = useInboundUIStore(
    (state) => ({
      setInboundUIState: state.setInboundUIState,
      showScanningFailedModal: state.showScanningFailedModal,
    }),
    shallow,
  );

  const isLoading = useSelector(productSearchService, (state) => state.matches("resolvingEAN"));

  const onCloseScanningFailedModal = useCallback(() => {
    setInboundUIState({ showScanningFailedModal: false });
  }, [setInboundUIState]);

  return (
    <>
      <SpinnerModal isOpen={isLoading} />
      <ScanningFailedModal
        isOpen={showScanningFailedModal}
        onClickConfirm={onCloseScanningFailedModal}
      />
    </>
  );
}
