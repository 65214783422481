import { Flex, FlexProps, forwardRef } from "@chakra-ui/react";

import { DropDownListItem } from "ui/DropDownListItem/DropDownListItem";

export type DropDownOption = {
  key: string;
  label: string;
};

type DropDownListProps = {
  options: DropDownOption[];
  value: string | null;
  onInput: (value: string | null) => void;
  testId?: string;
} & Omit<FlexProps, "onInput">;

export const DropDownList = forwardRef<DropDownListProps, "ul">(
  ({ options, value, onInput, testId, ...rest }, ref) => {
    return (
      <Flex
        ref={ref}
        direction="column"
        borderRadius="sm"
        py="s100"
        bgColor="white"
        width="fit-content"
        as="ul"
        maxHeight="60vh"
        overflowY="scroll"
        overscrollBehavior="none"
        style={{ scrollbarWidth: "none" }}
        {...rest}
      >
        {options.map((option) => (
          <DropDownListItem
            key={option.key}
            label={option.label}
            isSelected={value === option.key}
            onClickItem={() => onInput(option.key)}
            dataTestId={`${testId}-option-${option.key}`}
          />
        ))}
      </Flex>
    );
  },
);
