import { useSelector } from "@xstate/react";

import { useSelectOrderDetails } from "flows/Picking/hooks/usePickingMachineSelectors";
import { usePickingService } from "flows/Picking/hooks/usePickingService";
import { OrderNumberHeader } from "ui/OrderNumberHeader/OrderNumberHeader";

export function PickingListHeader() {
  const {
    orderNumber,
    orderWeight,
    numberOfItems,
    itemsPickedCount,
    externalDeliveryProvider,
    reseller,
  } = useSelectOrderDetails();
  const pickingService = usePickingService();
  const elapsedTime = useSelector(pickingService, (state) => {
    return state.context.elapsedTime;
  });

  return (
    <OrderNumberHeader
      pos="sticky"
      zIndex={1}
      top={0}
      orderNumber={orderNumber}
      pickedItems={itemsPickedCount}
      totalNumberItems={numberOfItems}
      elapsedTime={elapsedTime}
      orderWeight={orderWeight}
      externalDeliveryProvider={externalDeliveryProvider}
      reseller={reseller}
    />
  );
}
