import React, { ReactElement, forwardRef } from "react";

import { Flex, FlexProps } from "@chakra-ui/react";

type SwipeScrollWrapperProps = {
  mainComponent: ReactElement<Pick<FlexProps, "flex" | "scrollSnapAlign">>;
  scrolledComponent?: ReactElement<Pick<FlexProps, "scrollSnapAlign">>;
  handleSwipeScroll: (event: React.UIEvent<HTMLDivElement, UIEvent>) => void;
  showSwipeButtons?: boolean;
  flex?: string;
};

export const SwipeScrollWrapper = forwardRef<HTMLDivElement, SwipeScrollWrapperProps>(
  ({ mainComponent, scrolledComponent, handleSwipeScroll, showSwipeButtons, ...rest }, ref) => {
    return (
      <Flex
        ref={ref}
        w="100%"
        flexWrap="nowrap"
        overflowX={showSwipeButtons ? "scroll" : undefined}
        scrollSnapType="x mandatory"
        css={{
          "&::-webkit-scrollbar": {
            display: "none",
          },
        }}
        onScroll={handleSwipeScroll}
        {...rest}
      >
        {React.cloneElement(mainComponent, { flex: "0 0 100%", scrollSnapAlign: "start" })}
        {showSwipeButtons &&
          scrolledComponent &&
          React.cloneElement(scrolledComponent, { scrollSnapAlign: "start" })}
      </Flex>
    );
  },
);
