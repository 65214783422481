import { useEffect, useState } from "react";

import { Grid } from "@chakra-ui/react";
import { useIntl } from "react-intl";

import { Spinner } from "ui/Spinner/Spinner";
import { BodyS, HeaderM, TitleS } from "ui/Typography/Typography";
import { VerticalLayout } from "ui/VerticalLayout/VerticalLayout";

type StockChangeOverviewProps = {
  counted: number;
  expectedStock: number | null;
  expiredCount: number;
  damagedCount: number;
  tooGoodToGoCount: number;
  newStockCallback: (newStockCount: number) => void;
};

export function StockChangeOverview({
  counted,
  expectedStock,
  expiredCount,
  damagedCount,
  tooGoodToGoCount,
  newStockCallback,
}: StockChangeOverviewProps) {
  const intl = useIntl();
  const [newStockCount, setNewStockCount] = useState<number>(0);

  useEffect(() => {
    setNewStockCount(counted - (expiredCount + damagedCount + tooGoodToGoCount));
  }, [counted, expiredCount, damagedCount, tooGoodToGoCount]);

  useEffect(() => {
    newStockCallback(newStockCount);
  }, [newStockCount, newStockCallback]);

  const showRedExpectedText: boolean = counted !== expectedStock;
  const showPinkNewStock: boolean = newStockCount !== expectedStock;
  const showErrorMessage: boolean = !!expectedStock && counted < expectedStock;

  return (
    <>
      <Grid templateColumns="repeat(3, 1fr)" bg="white" py="s150" borderRadius="sm">
        <VerticalLayout alignItems="center">
          <TitleS color="grey.600" mb="s50">
            {intl.formatMessage({ id: "components.stock_change_overview.counted" })}
          </TitleS>
          <HeaderM>{counted}</HeaderM>
        </VerticalLayout>
        <VerticalLayout alignItems="center">
          <TitleS color="grey.600" mb="s50">
            {intl.formatMessage({ id: "components.stock_change_overview.expected" })}
          </TitleS>
          {expectedStock !== null ? (
            <HeaderM color={showRedExpectedText ? "red.500" : "green.500"}>{expectedStock}</HeaderM>
          ) : (
            <Spinner />
          )}
        </VerticalLayout>
        <VerticalLayout alignItems="center">
          <TitleS color="grey.600" mb="s50">
            {intl.formatMessage({ id: "components.stock_change_overview.new_stock" })}
          </TitleS>
          <HeaderM
            px="s100"
            bg={showPinkNewStock ? "pink.100" : ""}
            color={showPinkNewStock ? "pink.500" : ""}
            borderRadius="sm"
            h="32px"
            minW="41px"
            textAlign="center"
          >
            {newStockCount}
          </HeaderM>
        </VerticalLayout>
      </Grid>
      {showErrorMessage && (
        <BodyS textAlign="center" color="red.500">
          {intl.formatMessage({ id: "components.stock_change_overview.error" })}
        </BodyS>
      )}
    </>
  );
}
