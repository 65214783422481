import { StockCorrectionsOrigin } from "analytics/events";
import { useRestockingServiceStore } from "flows/Inventory/flows/RestockingList/stores/restockingServiceStore";
import { useAnalytics } from "shared/hooks/useAnalytics";
import { useCustomToast } from "shared/hooks/useCustomToast";

import { StockCorrectionMachineContext, ValidateProductStockEvent } from "./types";

const EAN_NOT_FOUND_TOAST_ID = "ean_not_found_toast_id";
const NO_PRODUCT_ASSIGNED_TOAST_ID = "no_product_assigned_toast_id";
const STOCK_UPDATE_SUCCESS_TOAST_ID = "stock_update_success_toast_id";
const STOCK_UPDATE_ERROR_TOAST_ID = "stock_update_error_toast_id";

export function useStockCorrectionActionsImplems() {
  const { showToastUI } = useCustomToast();

  const { setUseRestockingServiceStore } = useRestockingServiceStore((state) => ({
    setUseRestockingServiceStore: state.setUseRestockingServiceStore,
  }));

  const { sendSegmentTrackEvent } = useAnalytics();

  const showScanProductError = () => {
    showToastUI({
      id: EAN_NOT_FOUND_TOAST_ID,
      title: "hooks.use-stock-correction.ean-not-found",
    });
  };

  const showNoProductAssignedError = () => {
    showToastUI({
      id: NO_PRODUCT_ASSIGNED_TOAST_ID,
      title: "hooks.use-stock-correction.no-product-assigned",
    });
  };

  const showUpdateProductStockError = () => {
    showToastUI({
      id: STOCK_UPDATE_ERROR_TOAST_ID,
      title: "hooks.use-stock-correction.stock-update-error",
    });
  };

  const showUpdateProductStockSuccess = () => {
    showToastUI({
      id: STOCK_UPDATE_SUCCESS_TOAST_ID,
      status: "success",
      title: "hooks.use-stock-correction.stock-updated",
    });
  };

  const resetRestockingServiceStore = () => {
    setUseRestockingServiceStore({
      isNavigatingToStockCorrection: false,
      productName: null,
    });
  };

  const sendSegmentStartCorrectionsEvent = (ctx: StockCorrectionMachineContext) => {
    let origin: StockCorrectionsOrigin = "scanned_product";
    if (ctx.scanType === "shelf") {
      origin = "scanned_shelf";
    }
    if (ctx.scanType === "search") {
      origin = "search_bar";
    }
    sendSegmentTrackEvent("stockCorrectionStarted", {
      product_sku: ctx.selectedInventoryProductSku as string,
      shelf_number: ctx.selectedInventoryProductShelf ?? "none",
      origin,
      screen_name: "stock_correction_screen",
    });
  };
  // TODO: type event
  const sendSegmentFinishCorrectionsEvent = (ctx: StockCorrectionMachineContext, event: any) => {
    const { before, after, damaged, expired, correction, tgtg } = event.data;

    sendSegmentTrackEvent("stockCorrectionFinished", {
      product_sku: ctx.selectedInventoryProductSku as string,
      shelf_number: ctx.selectedInventoryProductShelf ?? "none",
      quantity_before_correction: before,
      quantity_corrected: correction,
      quantity_damaged: damaged,
      quantity_expired: expired,
      quantity_tgtg: tgtg,
      quantity_after_correction: after,
      screen_name: "stock_correction_screen",
    });
  };

  const sendSegmentStartCorrectionsByShortcutEvent = (ctx: StockCorrectionMachineContext) => {
    sendSegmentTrackEvent("stockCorrectionStarted", {
      product_sku: ctx.selectedInventoryProductSku as string,
      shelf_number: ctx.selectedInventoryProductShelf ?? "none",
      origin: ctx.scanType === "product" ? "scanned_product" : "scanned_shelf",
      screen_name: "stock_correction_multiple_results",
    });
  };

  const sendSegmentFinishCorrectionsByShortcutEvent = (
    ctx: StockCorrectionMachineContext,
    event: ValidateProductStockEvent,
  ) => {
    sendSegmentTrackEvent("stockCorrectionFinished", {
      product_sku: ctx.selectedInventoryProductSku as string,
      shelf_number: ctx.selectedInventoryProductShelf ?? "none",
      quantity_before_correction: event.stock,
      quantity_after_correction: event.stock,
      quantity_corrected: 0,
      quantity_damaged: 0,
      quantity_expired: 0,
      quantity_tgtg: 0,
      screen_name: "stock_correction_multiple_results",
    });
  };

  return {
    showScanProductError,
    showNoProductAssignedError,
    showUpdateProductStockError,
    showUpdateProductStockSuccess,
    resetRestockingServiceStore,
    sendSegmentStartCorrectionsEvent,
    sendSegmentFinishCorrectionsEvent,
    sendSegmentStartCorrectionsByShortcutEvent,
    sendSegmentFinishCorrectionsByShortcutEvent,
  };
}
