import * as Types from "../../../../../../__graphql__/types.js";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type GetProductWithBbdQueryVariables = Types.Exact<{
  sku: Types.Scalars["ID"];
}>;

export type GetProductWithBbdQuery = {
  __typename?: "Query";
  getProduct: {
    __typename?: "IProduct";
    bbd?: string | null;
    bio?: boolean | null;
    imageUrl?: string | null;
    minDaysToBestBeforeDate?: number | null;
    name: string;
    sku: string;
    countryOfOrigin: { __typename?: "CountryOfOrigin"; code?: string | null };
    inventoryEntry: {
      __typename?: "InventoryEntry";
      shelfNumber?: string | null;
      stock: { __typename?: "Stock"; shelf: number };
    };
  };
};

export const GetProductWithBbdDocument = gql`
  query getProductWithBBD($sku: ID!) {
    getProduct(input: { sku: $sku }) {
      bbd
      bio
      imageUrl
      minDaysToBestBeforeDate
      name
      sku
      countryOfOrigin {
        code
      }
      inventoryEntry {
        shelfNumber
        stock {
          shelf
        }
      }
    }
  }
`;

/**
 * __useGetProductWithBbdQuery__
 *
 * To run a query within a React component, call `useGetProductWithBbdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductWithBbdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductWithBbdQuery({
 *   variables: {
 *      sku: // value for 'sku'
 *   },
 * });
 */
export function useGetProductWithBbdQuery(
  baseOptions: Apollo.QueryHookOptions<GetProductWithBbdQuery, GetProductWithBbdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProductWithBbdQuery, GetProductWithBbdQueryVariables>(
    GetProductWithBbdDocument,
    options,
  );
}
export function useGetProductWithBbdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProductWithBbdQuery,
    GetProductWithBbdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetProductWithBbdQuery, GetProductWithBbdQueryVariables>(
    GetProductWithBbdDocument,
    options,
  );
}
export type GetProductWithBbdQueryHookResult = ReturnType<typeof useGetProductWithBbdQuery>;
export type GetProductWithBbdLazyQueryHookResult = ReturnType<typeof useGetProductWithBbdLazyQuery>;
export type GetProductWithBbdQueryResult = Apollo.QueryResult<
  GetProductWithBbdQuery,
  GetProductWithBbdQueryVariables
>;
