import { StartPickingResponse } from "__graphql__/types";
import { OrderFragment, StartPickingMutation } from "flows/Picking/queries/order/order.generated";
import { isNullOrUndefined } from "utils/tsHelpers";

import { serializeItem } from "../item/serializer";
import { Order, OrderWithItems } from "./types";

export const serializeOrder = (
  order: OrderFragment,
  externalDeliveryProvider: StartPickingResponse["externalDeliveryProvider"],
  handoverIdentifier: StartPickingResponse["handoverIdentifier"],
  deliveryTag: StartPickingResponse["deliveryTag"],
): Order => {
  return {
    id: order.id,
    createdAt: order.createdAt ?? null,
    isClickAndCollect: order.isClickAndCollect,
    customerFirstName: order.customerFirstName ?? null,
    customerLastName: order.customerLastName ?? null,
    externalProviderId: order.externalProviderId ?? null,
    number: order.number ?? null,
    state: order.state ?? null,
    isNewCustomer: order.isNewCustomer,
    reseller: order.reseller as Order["reseller"],
    externalDeliveryProvider:
      (externalDeliveryProvider as Order["externalDeliveryProvider"]) ?? null,
    handoverIdentifier: handoverIdentifier ?? null,
    isInStore: order.isInStore,
    ageVerification: (order.ageVerification as Order["ageVerification"]) ?? "NONE",
    weight: order.weight,
    numberOfItems: order.pickingItemCount,
    deliveryTag: deliveryTag as Order["deliveryTag"],
  };
};

export function serializeOrderToStartPicking(
  startPickingResponse: StartPickingMutation["startPickingV2"],
): OrderWithItems {
  const {
    order,
    externalDeliveryProvider = null,
    handoverIdentifier = null,
    deliveryTag = null,
  } = startPickingResponse;
  if (isNullOrUndefined(order)) {
    throw new Error("Order doesn't exist");
  }
  return {
    ...serializeOrder(order!, externalDeliveryProvider, handoverIdentifier, deliveryTag),
    items: order.items.flatMap((item) => (item ? [serializeItem(item)] : [])),
  };
}
