import React from "react";

import { ColorProps, Tag, TagProps } from "@chakra-ui/react";

import { TitleM, TitleS } from "ui/Typography/Typography";

type StatusProps = {
  size?: "regular" | "small";
  label: string;
  textColor?: ColorProps["color"];
} & TagProps;

export function Status({ size = "regular", label, textColor, ...rest }: StatusProps) {
  return (
    <Tag {...rest} borderRadius="8px" paddingY="s100" whiteSpace="nowrap" height="36px">
      {React.createElement(size === "regular" ? TitleM : TitleS, { color: textColor }, label)}
    </Tag>
  );
}
