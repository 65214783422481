// font-size: 8px;
// 100 = 1rem = 8px

export const space = {
  s25: "0.125rem", // 2px
  s50: "0.25rem", // 4px
  s75: "0.375rem", // 6px
  s100: "0.5rem", // 8px
  s150: "0.75rem", // 12px
  s200: "1rem", // 16px
  s300: "1.5rem", // 24px
  s400: "2rem", // 32px
  s500: "3rem", // 48px
};
