import create from "zustand";

export enum RolliStatus {
  ACCEPTED = "accepted",
  REJECTED = "rejected",
}

export enum ModalNames {
  WRONG_ROLLI = "wrongRolliModal",
  ROLLI_CONTAINS_CRATES = "rolliContainsCratesModal",
  POTENTIAL_INCORRECT_ROLLI = "potentialIncorrectRolliModal",
  DELIVERY_DATA_UNAVAILABLE = "deliveryDataUnavailableModal",
  EXIT_FLOW_IN_ROLLI_VALIDATION = "exitFlowInRolliValidationModal",
}

export type Rolli = {
  desadv_id: string | null;
  sscc: string;
  isRolliNotMatch: boolean;
  status: RolliStatus | null;
  expected_hub: string | null;
};

type CheckinUIStore = {
  scannedRollies: Rolli[] | null;
  shouldShowManualRolliIdInput: boolean;
  hasRolliIdBarcodeError: boolean;
  modals: Record<ModalNames, boolean>;
  isScanningCrates: boolean;
  highlightedRolliId: string | null;
};

type CheckinUIStoreWithMethods = CheckinUIStore & {
  setCheckinUIState(checkinUIState: Partial<CheckinUIStore>): void;
  toggleManualRolliIdInput(): void;
  toggleRolliIdBarcodeError(): void;
  setShowModal: (modalName: ModalNames, show: boolean) => void;
  reset(): void;
};

const initialState: CheckinUIStore = {
  scannedRollies: [],
  shouldShowManualRolliIdInput: false,
  hasRolliIdBarcodeError: false,
  modals: {
    [ModalNames.WRONG_ROLLI]: false,
    [ModalNames.ROLLI_CONTAINS_CRATES]: false,
    [ModalNames.POTENTIAL_INCORRECT_ROLLI]: false,
    [ModalNames.DELIVERY_DATA_UNAVAILABLE]: false,
    [ModalNames.EXIT_FLOW_IN_ROLLI_VALIDATION]: false,
  },
  isScanningCrates: false,
  highlightedRolliId: null,
};

export const useCheckinUIStore = create<CheckinUIStoreWithMethods>()((set, get) => ({
  ...initialState,
  setCheckinUIState(checkinUIState) {
    set({ ...checkinUIState, shouldShowManualRolliIdInput: false, hasRolliIdBarcodeError: false });
  },
  toggleManualRolliIdInput() {
    const currentState = get();
    set({
      ...currentState,
      shouldShowManualRolliIdInput: !currentState.shouldShowManualRolliIdInput,
    });
  },
  toggleRolliIdBarcodeError() {
    const currentState = get();
    set({
      ...currentState,
      shouldShowManualRolliIdInput: false,
      hasRolliIdBarcodeError: !currentState.hasRolliIdBarcodeError,
    });
  },
  setShowModal(modalName, show) {
    set((state) => ({
      shouldShowManualRolliIdInput: false,
      hasRolliIdBarcodeError: false,
      modals: {
        ...state.modals,
        [modalName]: show,
      },
    }));
  },
  reset() {
    set(initialState);
  },
}));
