import { transformChunks } from "utils/ui";

import {
  LargeInboundQuantity,
  ListVerificationCheck,
  ListVerificationDeserializerOptions,
  SerializedListVerificationCheck,
  InboundQuantityOfOne,
  HandlingUnitQuantityMismatch,
} from "./types";

const PINKIFIER = transformChunks({ pink: { color: "pinkFlink.500" } });

const InboundQuantityOfOneDeserializerOptions: ListVerificationDeserializerOptions<InboundQuantityOfOne> =
  {
    title: "pages.inbound.list-verification.checks.inbound-quantity-of-one.title",
    description: () => [
      { id: "pages.inbound.list-verification.checks.inbound-quantity-of-one.description" },
      PINKIFIER,
    ],
  };

const LargeInboundQuantityDeserializerOptions: ListVerificationDeserializerOptions<LargeInboundQuantity> =
  {
    title: "pages.inbound.list-verification.checks.large-inbound-quantity.title",
    description: ({ quantity }) => [
      { id: "pages.inbound.list-verification.checks.large-inbound-quantity.description" },
      {
        quantity,
        ...PINKIFIER,
      },
    ],
  };

const HandlingUnitQuantityMismatchDeserializerOptions: ListVerificationDeserializerOptions<HandlingUnitQuantityMismatch> =
  {
    title: "pages.inbound.list-verification.handling-unit-quantity-mismatch.title",
    description: ({ quantity, expected }) => {
      if (expected)
        return [
          {
            id: "pages.inbound.list-verification.checks.handling-unit-quantity-mismatch.single.description",
          },
          {
            expected,
            quantity,
            ...PINKIFIER,
          },
        ];
      return [
        {
          id: "pages.inbound.list-verification.checks.handling-unit-quantity-mismatch.multi.description",
        },
      ];
    },
  };

function getDeserializerOptions<Check extends SerializedListVerificationCheck>(
  listVerificationCheckTypeKey: Check["type"],
): ListVerificationDeserializerOptions<Check> {
  return {
    InboundQuantityOfOne: InboundQuantityOfOneDeserializerOptions,
    LargeInboundQuantity: LargeInboundQuantityDeserializerOptions,
    HandlingUnitQuantityMismatch: HandlingUnitQuantityMismatchDeserializerOptions,
  }[listVerificationCheckTypeKey] as ListVerificationDeserializerOptions<Check>;
}

export function deserializeListVerificationCheck({
  key,
  sku,
  type,
  isCompleted,
  data,
}: SerializedListVerificationCheck): ListVerificationCheck {
  const { title, description } = getDeserializerOptions(type);
  return {
    key,
    sku,
    isCompleted,
    title,
    description: description(data),
  };
}
