import { useCallback, useEffect, useRef, useState } from "react";

import { isNotNullNorUndefined, isNullOrUndefined } from "utils/tsHelpers";

type UseCountUpTimerProps = { updateIntervalInMs?: number; startImmediatelyFrom?: Date | null };

const clearTimerIfItExists = (potentialTimer: NodeJS.Timer | null) => {
  if (isNotNullNorUndefined(potentialTimer)) {
    clearInterval(potentialTimer);
  }
};

export function useCountUpTimer({
  updateIntervalInMs = 1000,
  startImmediatelyFrom = null,
}: UseCountUpTimerProps = {}) {
  const [elapsedTimeInMs, setElapsedTimeInMs] = useState(0);
  const [isRunning, setIsRunning] = useState(isNotNullNorUndefined(startImmediatelyFrom));
  const startTime = useRef<Date | null>(startImmediatelyFrom);
  const timer = useRef<NodeJS.Timer | null>(null);

  const updateElapsedTime = useCallback(() => {
    if (isNullOrUndefined(startTime.current)) {
      return;
    }
    const now = Date.now();
    setElapsedTimeInMs(now - startTime.current.getTime());
  }, [setElapsedTimeInMs]);

  const startTimer = useCallback(
    (customStartTime: Date) => {
      if (isRunning) {
        return;
      }
      startTime.current = customStartTime;
      setIsRunning(true);
    },
    [isRunning],
  );

  const stopTimer = useCallback(() => {
    setIsRunning(false);
  }, []);

  useEffect(() => {
    let timerStaticRef: NodeJS.Timer | null = null;
    if (isRunning) {
      updateElapsedTime();
      timer.current = setInterval(() => {
        updateElapsedTime();
      }, updateIntervalInMs);
      timerStaticRef = timer.current;
    } else {
      clearTimerIfItExists(timer.current);
    }
    return () => {
      clearTimerIfItExists(timerStaticRef);
    };
  }, [isRunning, stopTimer, updateElapsedTime, updateIntervalInMs]);

  return { startTimer, stopTimer, elapsedTimeInMs };
}
