import { useCallback } from "react";

import { Flex, Image } from "@chakra-ui/react";
import { FormattedMessage } from "react-intl";
import { generatePath, useNavigate } from "react-router-dom";

import { routes } from "config/routes";
import { openOngoingActivityModal } from "core/stores/useLayoutStore";
import Stopwatch from "images/random/stopwatch.svg";
import { useTimedActivityStore } from "shared/stores/useTimedActivityStore";
import { BodyS, TitleM } from "ui/Typography/Typography";
import { isNotNullNorUndefined } from "utils/tsHelpers";
import { transformChunks } from "utils/ui";

import { timedActivities } from "../models/timedActivity/config";
import { TimedActivitiesListItem } from "./TimedActivitiesListItem";

export function TimedActivitiesList() {
  const navigate = useNavigate();

  const timedActivityInstance = useTimedActivityStore((state) => state.timedActivityInstance);

  const goToActivityDetails = useCallback(
    (activityId: string) => {
      if (isNotNullNorUndefined(timedActivityInstance)) {
        openOngoingActivityModal();
        return;
      }
      navigate(generatePath(routes.activities.timedActivities.details, { activityId }));
    },
    [navigate, timedActivityInstance],
  );

  return (
    <Flex direction="column" gap="s200" my="s300">
      <TitleM ml="s200">
        <FormattedMessage id="flows.activities.timed-activities.list-page.title" />
      </TitleM>

      <Flex bgColor="white" mx="s200" p="s200" align="center" borderRadius="sm">
        <Image src={Stopwatch} boxSize="48px" borderRadius="full" />

        <BodyS ml="s300" my="s100">
          <FormattedMessage
            id="pages.activities.timed-activities.instructions"
            values={transformChunks({ b: { as: "strong" } })}
          />
        </BodyS>
      </Flex>

      <Flex gap="8px" direction="column" w="100%">
        {timedActivities.map((timedActivity) => (
          <TimedActivitiesListItem
            key={timedActivity.id}
            timedActivity={timedActivity}
            onClick={() => goToActivityDetails(timedActivity.id)}
          />
        ))}
      </Flex>
    </Flex>
  );
}
