import { useNavigate } from "react-router";

import { routes } from "config/routes";

import { useScan } from "./useScan/useScan";

const SECRET_FF_CONFIG_CODE = "ffs_stands_for_feature_flags";

export function useFFConfigPageSecretScan() {
  const navigate = useNavigate();

  useScan({
    onScan: (scannedValue) => {
      if (scannedValue === SECRET_FF_CONFIG_CODE) {
        navigate(routes.activities.featureFlagConfig);
      }
    },
  });
}
