import { useCallback, useEffect, useMemo, useState } from "react";

import { Button, Flex } from "@chakra-ui/react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router";

import { RESTOCKING_PROGRESSED_METHOD } from "analytics/events";
import { routes } from "config/routes";
import { useAppLanguageStore } from "core/stores/useAppLanguageStore";
import { EndDroppingProcessButton } from "flows/Inbound/components/EndDroppingProcessButton";
import { countryNameFromCode } from "libs/countryNames";
import { Page } from "shared/components/Page";
import { useCustomToast } from "shared/hooks/useCustomToast";
import { useScan } from "shared/hooks/useScan/useScan";
import { ProductInformation } from "ui/ProductInformation/ProductInformation";
import { Shelf } from "ui/Shelf/Shelf";
import { Spinner } from "ui/Spinner/Spinner";
import { BodyS, TitleM } from "ui/Typography/Typography";
import { getShelfDetail } from "utils/item";
import { isShelfNumberFormat } from "utils/scan";

import { RestockingTextSearch } from "../../components/RestockingTextSearch";
import {
  RestockingProductSearchMode,
  useRestockingProductSearch,
} from "../../services/restockingProductSearchService/service";
import { usePrivateRestockingList } from "../../services/restockingService/service";
import { useRestockingProductSearchStore } from "../../stores/restockingProductSearchStore";

export function RestockingProductSearch({ mode }: { mode: RestockingProductSearchMode }) {
  const navigate = useNavigate();
  const { showToastUI } = useCustomToast();

  const appLanguage = useAppLanguageStore((state) => state.appLanguage);

  const { searchProducts, searchResults, currentQuery, isLoading } = useRestockingProductSearch({
    mode,
  });
  const { addItemsToPrivateRestockingList } = usePrivateRestockingList({ loadOnInit: false });

  useScan({
    onScan: (value: string) => {
      if (isShelfNumberFormat(value)) searchProducts(value);
    },
  });

  const [selectedResultSKUs, setSelectedResultSKUs] = useState<string[]>([]);
  const [isLoadingResultsToList, setIsLoadingResultsToList] = useState(false);

  const initialSearchQuery = useRestockingProductSearchStore((state) => state.initialSearchQuery);

  const toggleSelectResult = useCallback(
    (sku: string) => {
      if (selectedResultSKUs.includes(sku)) {
        setSelectedResultSKUs(selectedResultSKUs.filter((resultSku) => resultSku !== sku));
      } else {
        setSelectedResultSKUs([...selectedResultSKUs, sku]);
      }
    },
    [selectedResultSKUs, setSelectedResultSKUs],
  );

  const addSelectedSearchResultsToPrivateList = async () => {
    try {
      setIsLoadingResultsToList(true);
      const method =
        mode === "shelf"
          ? RESTOCKING_PROGRESSED_METHOD.SCANNED_SHELF
          : RESTOCKING_PROGRESSED_METHOD.TEXT_SEARCHED;
      await addItemsToPrivateRestockingList(selectedResultSKUs, method);
      navigate(routes.inventory.restockingList.root);
    } catch (error) {
      showToastUI({
        id: "add-search-results-to-list-error",
        title: "flows.inventory.restocking-list.add-items-to-private-list-error",
      });
    } finally {
      setIsLoadingResultsToList(false);
    }
  };

  const onClickCancel = () => navigate(routes.inventory.restockingList.root);

  // Reset result selections when results change
  useEffect(() => {
    setSelectedResultSKUs([]);
  }, [searchResults]);

  const searchResultsList = useMemo(() => {
    if (searchResults === null) return undefined;

    return searchResults.length > 0 ? (
      <Flex direction="column" gap="s200" w="100%">
        {searchResults.map((result) => (
          <ProductInformation
            key={result.sku}
            name={result.name}
            imageUrl={result.imageUrl}
            stock={result.stockOnShelf}
            sku={result.sku}
            onClick={() => toggleSelectResult(result.sku)}
            bgColor={selectedResultSKUs.includes(result.sku) ? "grey.200" : "white"}
            countryOfOrigin={countryNameFromCode(appLanguage, result.countryOfOriginCode)}
            variantSize="s"
            p="s200"
            borderRadius="0"
          />
        ))}
      </Flex>
    ) : (
      <Flex h="100%" w="100%" direction="column" justify="center" align="center">
        <TitleM>
          <FormattedMessage id="flows.inventory.restocking-list.product-search.no-results.title" />
        </TitleM>
        <BodyS>
          <FormattedMessage id="flows.inventory.restocking-list.product-search.no-results.empty-shelf" />
        </BodyS>
      </Flex>
    );
  }, [appLanguage, searchResults, selectedResultSKUs, toggleSelectResult]);

  return (
    <Page isFull isBgGrey height="100%" justifyContent="flex-start" alignItems="flex-start">
      {mode === "shelf" ? (
        <Flex p="s200" w="100%" align="center" justify="space-between">
          {isLoading ? (
            <Spinner size="md" />
          ) : (
            <Shelf
              letter={getShelfDetail(currentQuery ?? "").shelfLetter}
              number={getShelfDetail(currentQuery ?? "").shelfNumber}
              variant="small"
            />
          )}
          <Button
            onClick={onClickCancel}
            variant="link"
            color="grey.600"
            ml="s200"
            _hover={{
              textDecoration: "none",
            }}
            _active={{
              textDecoration: "none",
            }}
          >
            <FormattedMessage id="flows.inventory.restocking-list.product-search.cancel" />
          </Button>
        </Flex>
      ) : (
        <RestockingTextSearch
          initialInputValue={initialSearchQuery ?? ""}
          onClickCancel={onClickCancel}
          onQuery={searchProducts}
        />
      )}

      {searchResultsList}

      {selectedResultSKUs.length > 0 && (
        <EndDroppingProcessButton
          onClickButton={addSelectedSearchResultsToPrivateList}
          labelMessageId="flows.inventory.restocking-list.pages.product-search.cta-button-label"
          isLoading={isLoadingResultsToList}
          bottom={0}
          data-testid="product-search-cta-button"
        />
      )}
    </Page>
  );
}
