import { useIntl } from "react-intl";

import { Banner } from "ui/Banner/Banner";
import { CheckCircleIcon } from "ui/Icons/Icons";

export type DespatchAdviceInfoBannerProps = {
  onClose: () => void;
};

export function DespatchAdviceInfoBanner() {
  const intl = useIntl();
  const title = intl.formatMessage({
    id: "flows.inbound.pre-dropping.despatch-advice-info-banner.title",
  });
  const subtitle = intl.formatMessage({
    id: "flows.inbound.pre-dropping.despatch-advice-info-banner.description",
  });

  return (
    <Banner
      title={title}
      subTitle={subtitle}
      colorVariant="purple"
      icon={<CheckCircleIcon />}
      pos="fixed"
      top="55px"
      zIndex={100}
      dataTestId="despatch-advice-info-banner"
    />
  );
}
