import { useCallback } from "react";

import { useNavigate } from "react-router-dom";

import { PageName } from "analytics/events";
import { routes } from "config/routes";

import { Check } from "../models/check/types";
import { getInitialCheckUrl } from "../utils/checkUrl";

export function useChecksRedirect() {
  const navigate = useNavigate();

  const navigateToCheck = useCallback(
    (check: Check, origin: PageName) => {
      const { checkId, type } = check;
      if (!checkId || !type) {
        return;
      }
      const url = getInitialCheckUrl({ checkId, type });
      navigate(url, { state: { origin }, replace: true });
    },
    [navigate],
  );

  const navigateToOrigin = useCallback(
    (origin: PageName) => {
      let targetUrl: string;
      switch (origin) {
        case PageName.INVENTORY_FRESH_CHECK_LIST_PAGE: {
          targetUrl = routes.inventory.freshChecks.root;
          break;
        }
        case PageName.INVENTORY_BBD_CHECK_LIST_PAGE: {
          targetUrl = routes.inventory.bbdChecks.root;
          break;
        }
        case PageName.INVENTORY_STOCK_CHECK_LIST_PAGE: {
          targetUrl = routes.inventory.stockChecks.root;
          break;
        }
        case PageName.INVENTORY_EOY_CHECK_LIST_PAGE: {
          targetUrl = routes.inventory.eoyChecks.root;
          break;
        }
        case PageName.INVENTORY_RECOUNT_CHECK_LIST_PAGE: {
          targetUrl = routes.inventory.eoyRecountChecks.root;
          break;
        }
        default: {
          targetUrl = routes.activities.root;
          break;
        }
      }
      navigate(targetUrl, { replace: true });
    },
    [navigate],
  );

  return {
    navigateToCheck,
    navigateToOrigin,
  };
}
