import { useApolloClient } from "@apollo/client";
import { uniqBy } from "lodash";

import { serializeInventoryProductFromSearchResult } from "flows/Inventory/shared/models/inventoryProduct/serializer";
import {
  SearchProductsByEanDocument,
  SearchProductsByEanQuery,
  SearchProductsByEanQueryVariables,
  SearchProductsByTextDocument,
  SearchProductsByTextQuery,
  SearchProductsByTextQueryVariables,
} from "flows/Inventory/shared/queries/product/product.generated";
import { reportErrorsToDDAndThrow } from "utils/datadog";
import { isNullOrUndefined } from "utils/tsHelpers";

import {
  UpdateProductBbdDocument,
  UpdateProductBbdMutation,
  UpdateProductBbdMutationVariables,
} from "../../queries/bbd/bbd.generated";
import {
  GetProductWithBbdDocument,
  GetProductWithBbdQuery,
  GetProductWithBbdQueryVariables,
} from "../../queries/product/product.generated";
import {
  BbdCorrectionMachineContext,
  FetchProducts,
  FetchProductsByText,
  UpdateProductBbd,
} from "./types";

export function useBbdCorrectionServiceImplems() {
  const client = useApolloClient();

  const fetchProductsByEan = async (ean: string) => {
    const { data: dataProductsByEan } = await client.query<
      SearchProductsByEanQuery,
      SearchProductsByEanQueryVariables
    >({
      query: SearchProductsByEanDocument,
      variables: { searchProductsByEanInput: { ean }, isAlwaysInbound: true },
      fetchPolicy: "no-cache",
    });
    return dataProductsByEan.searchUnitsByEan.units.map((unit) =>
      serializeInventoryProductFromSearchResult(unit.product),
    );
  };

  const fetchProducts: FetchProducts = async (_, { barcode }) => {
    return reportErrorsToDDAndThrow("fetch products by ean", async () => {
      return fetchProductsByEan(barcode);
    });
  };

  const fetchProductsByText: FetchProductsByText = async (_, event) => {
    const { data } = await client.query<
      SearchProductsByTextQuery,
      SearchProductsByTextQueryVariables
    >({
      query: SearchProductsByTextDocument,
      variables: { searchProductsByTextInput: { query: event.query } },
      fetchPolicy: "no-cache",
    });

    const filteredUnits = data.searchUnitsByText.units.filter((unit) => !!unit.product);
    const uniqueProducts = uniqBy(filteredUnits, (unit) => unit.product.sku);

    return uniqueProducts.map((unit) => serializeInventoryProductFromSearchResult(unit.product));
  };

  const updateProductBbd: UpdateProductBbd = async (context, event) => {
    return reportErrorsToDDAndThrow("updateProductBbd", async () => {
      if (isNullOrUndefined(context.selectedInventoryProductSku)) {
        throw new Error("No selectedInventoryProductSku present in context");
      }
      const sku = context.selectedInventoryProductSku;
      const { closestBbd } = event;
      const closestBBDAfterOutbound = closestBbd?.toISOString();
      await client.mutate<UpdateProductBbdMutation, UpdateProductBbdMutationVariables>({
        mutation: UpdateProductBbdDocument,
        variables: {
          input: { closestBBDAfterOutbound, sku },
        },
      });
      return event;
    });
  };

  const fetchProductWithBBDTask = async (context: BbdCorrectionMachineContext) => {
    return reportErrorsToDDAndThrow("fetchProductWithBBDTask", async () => {
      const { selectedInventoryProductSku } = context;
      if (isNullOrUndefined(selectedInventoryProductSku)) {
        throw new Error("No selectedInventoryProductSku present in context");
      }
      try {
        await client.query<GetProductWithBbdQuery, GetProductWithBbdQueryVariables>({
          query: GetProductWithBbdDocument,
          variables: { sku: selectedInventoryProductSku },
          fetchPolicy: "network-only",
        });
      } catch (e: any) {
        switch (e?.graphQLErrors?.[0]?.extensions?.code) {
          case "NOT_BBD_ELIGIBLE": {
            throw new Error("productIsNotBbd");
          }
          case "BBD_INPUT_PENDING": {
            throw new Error("hasOpenTask");
          }
          default:
            throw e;
        }
      }
    });
  };

  return {
    updateProductBbd,
    fetchProducts,
    fetchProductsByText,
    fetchProductWithBBDTask,
  };
}
