import { useCallback } from "react";

import { UseToastOptions } from "@chakra-ui/react";

import { AudioSound, useAudio } from "shared/hooks/useAudio";
import { IntlMessageId } from "shared/types/lang";

import { useCustomToast } from "./useCustomToast";

type UseErrorToastWithAudioArgs = {
  errorToastId: string;
  title?: IntlMessageId;
} & UseToastOptions;

export function useErrorToastWithAudio({ errorToastId, ...rest }: UseErrorToastWithAudioArgs) {
  const { play: playErrorAudio } = useAudio(AudioSound.error);
  const { showToastUI, closeToast } = useCustomToast();

  const showToastAndPlayError = useCallback(
    (overRideArgs?: Omit<UseErrorToastWithAudioArgs, "errorToastId">) => {
      playErrorAudio();

      // @ts-ignore
      showToastUI({
        id: errorToastId,
        ...rest,
        ...overRideArgs,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [errorToastId, rest],
  );

  const closeIfToastActive = useCallback(
    () => {
      closeToast(errorToastId);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [errorToastId],
  );

  return {
    showToastAndPlayError,
    closeIfToastActive,
  };
}
