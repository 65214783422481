import { cloneElement } from "react";

import { CloseButton, Flex, FlexProps } from "@chakra-ui/react";

import { BodyM } from "ui/Typography/Typography";

type ToastProps = {
  icon: React.ReactElement;
  title: string;
  onClose: () => void;
} & FlexProps;

export function Toast({ icon, title, onClose, ...rest }: ToastProps) {
  return (
    <Flex w="100%" px="s200">
      <Flex
        w="100%"
        borderRadius="md"
        minH="3rem"
        color="white"
        pr="s400"
        pl="s200"
        py="s150"
        position="relative"
        {...rest}
      >
        <Flex flex="1" gap="s100" align="center">
          {cloneElement(icon, { boxSize: "1.5rem" })}
          <BodyM color="white" mr="s100" fontWeight="bold">
            {title}
          </BodyM>
        </Flex>
        <CloseButton
          display="flex"
          position="absolute"
          size="sm"
          top="4px"
          right="4px"
          onClick={() => onClose()}
        />
      </Flex>
    </Flex>
  );
}
