import { createContext, useEffect, useMemo, useState } from "react";

import { useInterpret } from "@xstate/react";
import { Outlet } from "react-router-dom";
import { InterpreterFrom } from "xstate";

import { PageName } from "analytics/events";
import { useCheckRoutesEffects } from "flows/Inventory/shared/hooks/useCheckRoutesEffects";
import { serializeCheck } from "flows/Inventory/shared/models/check/serializer";
import { Check } from "flows/Inventory/shared/models/check/types";
import { useInterpreterRouteSynchronizer } from "shared/hooks/useInterpreterRouteSynchronizer";
import { keepTrackOfStateForDebugging } from "utils/xstate";

import { useFreshCheckActionsImplems } from "../machines/freshCheckMachine/actions";
import {
  FRESH_CHECK_MACHINE_NAME,
  freshCheckMachine,
  contextInitialState,
} from "../machines/freshCheckMachine/machine";
import { useFreshCheckServiceImplems } from "../machines/freshCheckMachine/services";
import {
  FreshCheckMachineContext,
  FreshCheckMachineEvents,
} from "../machines/freshCheckMachine/types";

export const XStateFreshCheckContext = createContext({
  freshCheckService: {} as InterpreterFrom<typeof freshCheckMachine>,
});

type FreshCheckRoutesXStateProviderProps = {
  checkId: string;
  origin: PageName;
  check: Check;
};

function FreshCheckRoutesXStateProvider({
  checkId,
  origin,
  check,
}: FreshCheckRoutesXStateProviderProps) {
  const actions = useFreshCheckActionsImplems();
  const services = useFreshCheckServiceImplems();

  // We use useState to keep the same machine reference for the whole lifetime of this component
  const [machine] = useState(() => {
    return freshCheckMachine.withContext({
      ...contextInitialState,
      checkId,
      origin,
      sku: check.sku!,
      shelfNumber: check.shelfNumber ?? null,
      shelfLetter: check.shelfLetter ?? null,
      priority: check.priority!,
    });
  });

  const freshCheckService = useInterpret(
    machine,
    {
      actions,
      services,
    },
    ({ value }) => keepTrackOfStateForDebugging("freshCheckMachine", value),
  );

  useInterpreterRouteSynchronizer<FreshCheckMachineContext, FreshCheckMachineEvents>(
    FRESH_CHECK_MACHINE_NAME,
    freshCheckService,
  );

  useEffect(() => {
    // Making sure we can call start so it is only triggered once
    if (freshCheckService.getSnapshot().nextEvents.includes("START")) {
      freshCheckService.send({ type: "START" });
    }
  }, [freshCheckService]);

  const values = useMemo(() => ({ freshCheckService }), [freshCheckService]);

  return (
    <XStateFreshCheckContext.Provider value={values}>
      <Outlet />
    </XStateFreshCheckContext.Provider>
  );
}

export function XStateFreshCheckProvider() {
  const { isInvalidParams, loading, checkId, origin, data } = useCheckRoutesEffects();

  if (isInvalidParams || loading) {
    return null;
  }

  return (
    <FreshCheckRoutesXStateProvider
      checkId={checkId!}
      origin={origin!}
      check={serializeCheck(data!.getTaskById.task!)}
    />
  );
}
