import { useCallback, useEffect, useMemo } from "react";

import shallow from "zustand/shallow";

import { useAppLanguageStore } from "core/stores/useAppLanguageStore";
import { useFilteredSortedGroupedAndTranslatedSubcategories } from "flows/Inbound/hooks/useFilteredSortedGroupedAndTranslatedSubcategories";
import { TranslatedSubcategory } from "flows/Inbound/models/subcategory/types";
import { useInboundUIStore } from "flows/Inbound/stores/useInboundUIStore";
import { LetterRange, LetterRangeSelector } from "ui/LetterRangeSelector/LetterRangeSelector";
import { SubcategoryList } from "ui/SubcategoryList/SubcategoryList";

export type Category = "fruits" | "vegetables";

const letterRangeTemplates: Omit<LetterRange, "disabled">[] = [
  { id: "AD", from: "A", to: "D" },
  { id: "EH", from: "E", to: "H" },
  { id: "IL", from: "I", to: "L" },
  { id: "MP", from: "M", to: "P" },
  { id: "QT", from: "Q", to: "T" },
  { id: "UX", from: "U", to: "X" },
  { id: "YZ", from: "Y", to: "Z" },
];

type NoEANSubcategoriesProps = {
  chooseSubcategory: (subcategoryID: string) => void;
};

export function NoEANSubcategoriesList({ chooseSubcategory }: NoEANSubcategoriesProps) {
  const { setInboundUIState, selectedLetterRangeId } = useInboundUIStore((state) => state, shallow);
  const appLanguage = useAppLanguageStore((state) => state.appLanguage);

  const filteredSortedGroupedAndTranslatedSubcategories =
    useFilteredSortedGroupedAndTranslatedSubcategories(letterRangeTemplates) as Record<
      string,
      TranslatedSubcategory[]
    >;
  // asserting type here because there is a guard clause in
  // the parent component that doesn't display this component
  // whenever the categories are null.

  const letterRanges: LetterRange[] = useMemo(() => {
    return letterRangeTemplates.map((template) => ({
      ...template,
      disabled: filteredSortedGroupedAndTranslatedSubcategories[template.id].length === 0,
    }));
  }, [filteredSortedGroupedAndTranslatedSubcategories]);

  // The following function will detect if the currently selected letter range is disabled.
  // If so, it will look for the (alphabetically) first non-disabled letter range instead.
  const safeSelectedLetterRangeId = useMemo(() => {
    if (!letterRanges.find((lr) => lr.id === selectedLetterRangeId)?.disabled) {
      return selectedLetterRangeId;
    }
    return letterRanges.find((lr) => !lr.disabled)?.id || selectedLetterRangeId;
  }, [letterRanges, selectedLetterRangeId]);

  const selectLetterRangeId = useCallback(
    (letterRangeId: string) => setInboundUIState({ selectedLetterRangeId: letterRangeId }),
    [setInboundUIState],
  );

  useEffect(() => {
    selectLetterRangeId(safeSelectedLetterRangeId);
  }, [safeSelectedLetterRangeId, selectLetterRangeId]);

  const displayedSubcategories = useMemo(
    () => filteredSortedGroupedAndTranslatedSubcategories[safeSelectedLetterRangeId],
    [filteredSortedGroupedAndTranslatedSubcategories, safeSelectedLetterRangeId],
  );

  const handleSubcategoryClick = useCallback(
    (subcategoryID: string) => {
      setTimeout(() => chooseSubcategory(subcategoryID), 90);
    },
    [chooseSubcategory],
  );

  return (
    <>
      <LetterRangeSelector
        p="s200"
        pt="0"
        value={safeSelectedLetterRangeId}
        letterRanges={letterRanges}
        onInput={(v) => selectLetterRangeId(v as string)}
      />
      <SubcategoryList
        subcategories={displayedSubcategories}
        onClickSubcategory={handleSubcategoryClick}
        lang={appLanguage}
        bgColor="white"
        pt="s300"
        overflowY="scroll"
      />
    </>
  );
}
