import { useMemo } from "react";

import { Box, BoxProps } from "@chakra-ui/react";
import { useIntl } from "react-intl";

import { useAppLanguageStore } from "core/stores/useAppLanguageStore";
import { countryNameFromCode } from "libs/countryNames";
import {
  ProductInformation,
  ProductInformationProps,
} from "ui/ProductInformation/ProductInformation";

import { InventoryProduct } from "../../../shared/models/inventoryProduct/types";
import { StockCorrectorShortcut } from "./StockCorrectorShortcut";

export type ProductsListProps = {
  products: InventoryProduct[];
  onClickProduct: (product: InventoryProduct) => void;
  onConfirmStock?: (product: InventoryProduct) => void;
  showUpdateStockShortCut?: boolean;
  showStockStatus?: boolean;
  showStock?: boolean;
  showStockLabel?: boolean;
  showProductFacing?: boolean;
  variantSize: ProductInformationProps["variantSize"];
} & BoxProps;

export function ProductsList({
  products,
  onClickProduct,
  onConfirmStock = () => {},
  variantSize,
  showUpdateStockShortCut = false,
  showStockStatus = false,
  showStock = false,
  showStockLabel = false,
  showProductFacing,
  ...rest
}: ProductsListProps) {
  const intl = useIntl();
  const appLanguage = useAppLanguageStore((state) => state.appLanguage);

  const { inStockLabel, noStockLabel } = useMemo(() => {
    return {
      inStockLabel: intl.formatMessage({
        id: "pages.inventory.stock-correction.productsList.stockLabel",
        defaultMessage: "Stock",
      }),
      noStockLabel: intl.formatMessage({
        id: "pages.inventory.stock-correction.productsList.noStockLabel",
        defaultMessage: "No stock",
      }),
    };
  }, [intl]);

  return (
    <Box display="flex" w="100%" flexDirection="column" gap="s200" {...rest}>
      {products.map((product: InventoryProduct) => {
        if (product.productName === null) return null;
        const stockLabel = product.stock > 0 ? inStockLabel : noStockLabel;
        return (
          <Box padding="s200" bg="white" key={product.productSku}>
            <ProductInformation
              imageUrl={product.productImageUrl}
              name={product.productName}
              sku={product.productSku}
              countryOfOrigin={countryNameFromCode(appLanguage, product.countryOfOriginCode)}
              data-testid={`select-inventory-unit-${product.productSku}`}
              variantSize={variantSize}
              stockStatus={showStockStatus ? product.stockStatus : undefined}
              stock={showStock ? product.stock : undefined}
              stockLabel={showStockLabel ? stockLabel : undefined}
              showProductFacing={showProductFacing}
              isShelvedInHandlingUnits={product.isShelvedInHandlingUnits}
              numberOfShelfFacings={product.numberOfShelfFacings}
              onClick={() => onClickProduct(product)}
            />
            {showUpdateStockShortCut && (
              <StockCorrectorShortcut
                canConfirm={product.stockStatus === "unchanged"}
                onConfirm={() => onConfirmStock(product)}
                onEdit={() => onClickProduct(product)}
                mt="s200"
              />
            )}
          </Box>
        );
      })}
    </Box>
  );
}
