/* eslint-disable @typescript-eslint/no-unused-vars */
import { assign, createMachine, DoneInvokeEvent } from "xstate";

import { routes } from "config/routes";
import { InventoryProduct } from "flows/Inventory/shared/models/inventoryProduct/types";
import { XstateRouteSyncMeta } from "shared/types/xstate";
import { wrongEventErrorHandlerFactory } from "utils/xstate";

import { BbdCorrectionEvents, BbdCorrectionMachineContext, BbdCorrectionServices } from "./types";

export const contextInitialState: BbdCorrectionMachineContext = {
  selectedInventoryProductSku: null,
  selectedInventoryProductShelf: null,
  inventoryProductsList: [],
  scanType: null,
  scannedValue: null,
  isFromSelectProduct: false,
};

export const BBD_CORRECTION_MACHINE_NAME = "bbdCorrectionMachine";

export const bbdCorrectionMachine = createMachine(
  {
    preserveActionOrder: true,
    predictableActionArguments: true,
    id: BBD_CORRECTION_MACHINE_NAME,
    tsTypes: {} as import("./machine.typegen").Typegen0,
    schema: {
      context: {} as BbdCorrectionMachineContext,
      events: {} as BbdCorrectionEvents,
      services: {} as BbdCorrectionServices,
    },
    context: contextInitialState,
    on: {
      "*": { actions: "wrongEventErrorHandler" },
    },
    initial: "idle",
    states: {
      idle: {
        entry: [
          "resetScanType",
          "resetScannedValue",
          assign(() => ({ isFromSelectProduct: false })),
        ],
        meta: {
          routeSync: {
            path: routes.inventory.bbdCorrections.root,
            replace: true,
          } as XstateRouteSyncMeta,
        },
        on: {
          SET_SCAN_TYPE: {
            actions: "setScanType",
          },
          SCAN: {
            actions: "setScannedValue",
            target: "resolvingProducts",
          },
          ACTIVATE_SEARCH: "search",
        },
      },
      resolvingProducts: {
        invoke: {
          id: "fetchProducts",
          src: "fetchProducts",
          onDone: [
            {
              cond: (_, event: DoneInvokeEvent<InventoryProduct[]>) => {
                return event.data.length === 0;
              },
              target: "#bbdCorrectionMachine.idle",
            },
            {
              cond: (_, event: DoneInvokeEvent<InventoryProduct[]>) => {
                return event.data.length > 1;
              },
              actions: "setInventoryProductsList",
              target: "#bbdCorrectionMachine.selectProduct",
            },
            {
              target: "#bbdCorrectionMachine.declareBbdCorrections",
              actions: ["setInventoryProductsList", "setFirstInventoryProductAsSelected"],
            },
          ],
          onError: {
            target: "#bbdCorrectionMachine.idle",
          },
        },
      },
      search: {
        initial: "idle",
        entry: ["resetInventoryProductsList", assign(() => ({ scanType: "search" }))],
        on: {
          CANCEL_SEARCH: {
            target: "#bbdCorrectionMachine.idle",
            actions: "resetInventoryProductsList",
          },
          RESET_SEARCH_RESULTS: {
            actions: "resetInventoryProductsList",
          },
          SELECT_INVENTORY_PRODUCT: {
            actions: "setInventoryProduct",
            target: "#bbdCorrectionMachine.declareBbdCorrections",
          },
        },
        states: {
          idle: {
            on: {
              SEARCH_PRODUCTS: {
                actions: "resetInventoryProductsList",
                target: "loading",
              },
            },
          },
          loading: {
            invoke: {
              id: "fetchProductsByText",
              src: "fetchProductsByText",
              onDone: {
                target: "idle",
                actions: "setInventoryProductsList",
              },
            },
          },
        },
      },
      selectProduct: {
        meta: {
          routeSync: {
            path: routes.inventory.bbdCorrections.selectProduct,
          } as XstateRouteSyncMeta,
        },
        entry: [assign(() => ({ isFromSelectProduct: true }))],
        on: {
          SELECT_INVENTORY_PRODUCT: {
            actions: "setInventoryProduct",
            target: "#bbdCorrectionMachine.declareBbdCorrections",
          },
          CLEAR_LIST: {
            actions: ["resetMachineState"],
            target: "#bbdCorrectionMachine.idle",
          },
          GO_BACK: {
            target: "#bbdCorrectionMachine.idle",
          },
        },
      },
      declareBbdCorrections: {
        initial: "fetchingProductWithBBDTask",
        meta: {
          routeSync: {
            path: routes.inventory.bbdCorrections.declare,
            replace: true,
          } as XstateRouteSyncMeta,
        },
        on: {
          GO_BACK: [
            {
              cond: (context) => context.isFromSelectProduct,
              target: "#bbdCorrectionMachine.selectProduct",
            },
            {
              target: "#bbdCorrectionMachine.idle",
            },
          ],
        },
        states: {
          fetchingProductWithBBDTask: {
            invoke: {
              id: "fetchProductWithBBDTask",
              src: "fetchProductWithBBDTask",
              onDone: "idle",
              onError: [
                {
                  cond: (_, event) =>
                    (event.data?.toString() ?? "").includes("Error: productIsNotBbd"),
                  target: "notBbdProduct",
                },
                {
                  cond: (_, event) => (event.data?.toString() ?? "").includes("Error: hasOpenTask"),
                  target: "hasOpenTask",
                },
                {
                  actions: "errorToast",
                  target: "#bbdCorrectionMachine.idle",
                },
              ],
            },
          },
          idle: {
            entry: "sendBbdDateCorrectionStartedEvent",
            on: {
              UPDATE_PRODUCT_BBD: {
                target: "loading",
              },
            },
          },
          notBbdProduct: {},
          hasOpenTask: {},
          loading: {
            invoke: {
              id: "updateProductBbd",
              src: "updateProductBbd",
              onDone: {
                target: "done",
                actions: "sendBbdDateCorrectionFinishedEvent",
              },
              onError: { actions: "errorToast", target: "idle" },
            },
          },
          done: {
            on: {
              TERMINATE: {
                target: "#bbdCorrectionMachine.idle",
                actions: "updateProductBbdSuccessToast",
              },
            },
          },
        },
      },
    },
  },
  {
    actions: {
      wrongEventErrorHandler: wrongEventErrorHandlerFactory(BBD_CORRECTION_MACHINE_NAME),
      setInventoryProduct: assign((_, event) => ({
        selectedInventoryProductSku: event.inventoryProductSku,
        selectedInventoryProductShelf: event.inventoryProductShelf,
      })),
      setFirstInventoryProductAsSelected: assign((ctx) => ({
        selectedInventoryProductSku: ctx.inventoryProductsList[0].productSku,
        selectedInventoryProductShelf: ctx.inventoryProductsList[0].shelf,
      })),
      setInventoryProductsList: assign((_, event) => ({
        inventoryProductsList: event.data as InventoryProduct[],
      })),

      resetInventoryProductsList: assign((_, event) => ({
        inventoryProductsList: [],
      })),
      resetMachineState: assign(() => contextInitialState),
      setScanType: assign((_, event) => ({ scanType: event.scanType })),
      resetScanType: assign((_) => ({ scanType: null })),
      setScannedValue: assign((_, event) => ({ scannedValue: event.barcode })),

      resetScannedValue: assign((_) => ({ scannedValue: null })),
    },
  },
);
