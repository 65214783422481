import { Flex, FlexProps } from "@chakra-ui/react";

import { DeliveryLogo } from "ui/DeliveryLogo/DeliveryLogo";
import { TitleM } from "ui/Typography/Typography";
import { formatWeight } from "utils/formats";
import { isNotNullNorUndefined } from "utils/tsHelpers";

export const ORDER_NUMBER_HEADER_HEIGHT_NUMBER = 72;

type OrderNumberHeaderProps = {
  orderNumber?: string | null;
  pickedItems: number;
  totalNumberItems: number;
  elapsedTime?: number;
  orderWeight?: number;
  externalDeliveryProvider?: string | null;
  reseller?: string | null;
} & FlexProps;

export function OrderNumberHeader({
  orderNumber,
  pickedItems,
  totalNumberItems,
  elapsedTime,
  orderWeight,
  externalDeliveryProvider,
  reseller,
  ...rest
}: OrderNumberHeaderProps) {
  const formattedOrderNumber = `#${orderNumber?.slice(orderNumber.length - 4)}`.toUpperCase();

  return (
    <Flex
      width="100%"
      height={`${ORDER_NUMBER_HEADER_HEIGHT_NUMBER}px`}
      paddingX="s200"
      bg="grey.100"
      alignItems="center"
      justifyContent="space-between"
      borderBottomWidth="1px"
      borderBottomStyle="solid"
      borderBottomColor="grey.200"
      {...rest}
    >
      <DeliveryLogo externalDeliveryProvider={externalDeliveryProvider} reseller={reseller} />

      <Flex direction="column" minW="60px" alignItems="center">
        <TitleM data-testid="title-order-number">{formattedOrderNumber}</TitleM>
        {isNotNullNorUndefined(elapsedTime) && (
          <TitleM>{new Date(elapsedTime * 1000).toISOString().substring(14, 19)}</TitleM>
        )}
      </Flex>
      <Flex direction="column">
        <TitleM data-testid="title-items-counter">{`${pickedItems} / ${totalNumberItems} items`}</TitleM>
        {isNotNullNorUndefined(orderWeight) && (
          <TitleM textAlign="right">{formatWeight(orderWeight)}</TitleM>
        )}
      </Flex>
    </Flex>
  );
}
