import { useCallback } from "react";

import { useNavigate } from "react-router";
import shallow from "zustand/shallow";

import { PageName } from "analytics/events";
import { routes } from "config/routes";
import { useLayoutStore } from "core/stores/useLayoutStore";
import { BOTTOM_TAB_BAR_HEIGHT_NUMBER } from "ui/BottomTabBarContainer";

import { useInboundStore } from "../stores/inboundStore/useInboundStore";
import { EndDroppingProcessButton } from "./EndDroppingProcessButton";

export function AddItemsToSharedListButton({
  hasAllZeroQuantities = false,
}: {
  hasAllZeroQuantities?: boolean;
}) {
  const navigate = useNavigate();
  const { pageName } = useLayoutStore((state) => ({
    pageName: state.pageName,
  }));

  const { inboundUIState, setInboundUIState } = useInboundStore(
    (state) => ({
      inboundUIState: state.inboundUIState,
      setInboundUIState: state.setInboundUIState,
    }),
    shallow,
  );

  const onClickAddItemsToSharedList = useCallback(() => {
    if (hasAllZeroQuantities) {
      navigate(routes.inbound.root);
      return;
    }
    setInboundUIState({ isAddToSharedListModalVisible: true });
  }, [hasAllZeroQuantities, navigate, setInboundUIState]);

  const bottomHeight =
    pageName === PageName.INBOUND_PAGE_LIST_VERIFICATION ? 0 : BOTTOM_TAB_BAR_HEIGHT_NUMBER;

  return (
    <EndDroppingProcessButton
      shouldShowButton={!inboundUIState.isAddToSharedListModalVisible}
      onClickButton={onClickAddItemsToSharedList}
      labelMessageId="pages.inbound.add-items-to-shared-list.button"
      bottom={bottomHeight}
    />
  );
}
