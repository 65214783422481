import { Button, ButtonProps } from "@chakra-ui/react";

import { CheckIcon } from "ui/Icons/Icons";

type CheckButtonProps = {
  checked: boolean;
} & ButtonProps;

export function CheckButton({ checked, ...rest }: CheckButtonProps) {
  const bgColor = checked ? "green.500" : "grey.200";
  return (
    <Button
      minWidth="unset"
      maxWidth="28px"
      paddingX="14px"
      bgColor={bgColor}
      {...rest}
      data-testid={`check-button-${checked ? "checked" : "unchecked"}`}
      _hover={{ bgColor }}
    >
      <CheckIcon color="grey.100" width="18px" height="18px" />
    </Button>
  );
}
