import { GetPrivateRestockingListQuery } from "../../queries/privateRestockingList/privateRestockingList.generated";
import { serializePrivateRestockingItem } from "../restockingItem/serializer";
import { PrivateRestockingList } from "./types";

export const serializePrivateRestockingList = ({
  getPrivateRestockingList: { id, restockingItems },
}: GetPrivateRestockingListQuery): PrivateRestockingList => ({
  id,
  restockingItems: restockingItems.map(serializePrivateRestockingItem),
});
