import * as Types from "../../../../../../__graphql__/types.js";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type ValidateStockCheckMutationVariables = Types.Exact<{
  input: Types.ValidateStockCheckInput;
}>;

export type ValidateStockCheckMutation = {
  __typename?: "Mutation";
  validateStockCheck: { __typename?: "CheckMutationResponse"; success: boolean };
};

export const ValidateStockCheckDocument = gql`
  mutation ValidateStockCheck($input: ValidateStockCheckInput!) {
    validateStockCheck(input: $input) {
      success
    }
  }
`;
export type ValidateStockCheckMutationFn = Apollo.MutationFunction<
  ValidateStockCheckMutation,
  ValidateStockCheckMutationVariables
>;

/**
 * __useValidateStockCheckMutation__
 *
 * To run a mutation, you first call `useValidateStockCheckMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidateStockCheckMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validateStockCheckMutation, { data, loading, error }] = useValidateStockCheckMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useValidateStockCheckMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ValidateStockCheckMutation,
    ValidateStockCheckMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ValidateStockCheckMutation, ValidateStockCheckMutationVariables>(
    ValidateStockCheckDocument,
    options,
  );
}
export type ValidateStockCheckMutationHookResult = ReturnType<typeof useValidateStockCheckMutation>;
export type ValidateStockCheckMutationResult = Apollo.MutationResult<ValidateStockCheckMutation>;
export type ValidateStockCheckMutationOptions = Apollo.BaseMutationOptions<
  ValidateStockCheckMutation,
  ValidateStockCheckMutationVariables
>;
