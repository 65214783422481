import { useIntl } from "react-intl";

import { Banner } from "ui/Banner/Banner";
import { InfoIcon } from "ui/Icons/Icons";

export type ProductAlreadyAddedBannerProps = {
  onClose: () => void;
};

export function ProductAlreadyAddedBanner({ onClose }: ProductAlreadyAddedBannerProps) {
  const intl = useIntl();
  const title = intl.formatMessage({
    id: "flows.inbound.pre-dropping.product-already-added-banner.title",
  });
  const subtitle = intl.formatMessage({
    id: "flows.inbound.pre-dropping.product-already-added-banner.description",
  });

  return (
    <Banner
      title={title}
      subTitle={subtitle}
      colorVariant="pink"
      icon={<InfoIcon />}
      iconProps={{ boxSize: "24px" }}
      onClose={onClose}
      onClickBanner={onClose}
      position="sticky"
      top="0"
      zIndex="1000"
      dataTestId="product-already-added-banner"
    />
  );
}
