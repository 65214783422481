import { useCallback, useEffect, useRef } from "react";

import { useApolloClient } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";

import {
  NextOrderForPickingIdDocument,
  NextOrderForPickingIdQuery,
  NextOrderForPickingIdQueryVariables,
} from "core/queries/order/order.generated";
import {
  GetSummaryOfNextOrderToPickDocument,
  GetSummaryOfNextOrderToPickQuery,
  GetSummaryOfNextOrderToPickQueryVariables,
} from "core/queries/orderSummary/orderSummary.generated";
import {
  SummaryOfNextOrderForPicking,
  useAppBackgroundEffectsStore,
} from "core/stores/useAppBackgroundEffectsStore";
import { useEmployeeStore } from "core/stores/useEmployeeStore";
import { EppoFeatureFlags } from "core/types/flags";
import { useAnalytics } from "shared/hooks/useAnalytics";
import { useEppoFeatureFlagProvider } from "shared/hooks/useEppoFeatureFlag";
import {
  GetPendingCheckCountDocument,
  GetPendingCheckCountQuery,
  GetPendingCheckCountQueryVariables,
} from "shared/queries/checkCount/checkCount.generated";
import {
  GetPublicRestockingListDocument,
  GetPublicRestockingListQuery,
  GetPublicRestockingListQueryVariables,
} from "shared/queries/publicRestockingList/publicRestockingList.generated";
import { isNotNullNorUndefined } from "utils/tsHelpers";

const NEXT_ORDER_POLLING_INTERVAL_MS = 3000;
const POLLING_INTERVAL_MS_FOR_URGENT_TASK_COUNT_AND_PUBLIC_LIST = 60000;

const POLLING_BREAK_START_HOUR = 2;
const POLLING_BREAK_STOP_HOUR = 6;

function isInPollingHours() {
  const currentTime = new Date();
  const hour = currentTime.getHours();
  return !(hour < POLLING_BREAK_STOP_HOUR && hour >= POLLING_BREAK_START_HOUR);
}

export function useAppBackgroundEffects() {
  const { sendSegmentTrackEvent } = useAnalytics();
  const { isAuthenticated } = useAuth0();
  const client = useApolloClient();
  const isIdentified = useEmployeeStore((state) => state.isIdentified);
  const { isFeatureEnabled: isOpsAssignmentV0Enabled } = useEppoFeatureFlagProvider(
    EppoFeatureFlags.OPS_ASSIGNMENT_V0,
  );

  const { setAppBackgroundEffectsStore } = useAppBackgroundEffectsStore((state) => ({
    setAppBackgroundEffectsStore: state.setAppBackgroundEffectsStore,
  }));

  const nextOrderPollingRef = useRef<NodeJS.Timer>();
  const urgentChecksPollingRef = useRef<NodeJS.Timer>();
  const publicRestockingListPollingRef = useRef<NodeJS.Timer>();

  const shouldFetch = useCallback(() => {
    return isAuthenticated && isIdentified && isInPollingHours();
  }, [isAuthenticated, isIdentified]);

  const nextOrderPollingHandler = useCallback(async () => {
    if (shouldFetch()) {
      const { summaryOfNextOrderForPicking } = useAppBackgroundEffectsStore.getState();
      const handleOrderUpdate = (newSummary: SummaryOfNextOrderForPicking | null) => {
        if (
          isNotNullNorUndefined(newSummary) &&
          newSummary.id !== summaryOfNextOrderForPicking?.id
        ) {
          sendSegmentTrackEvent("orderStateUpdated", {
            state: "offered",
            origin: "PICKING_SCREEN",
            order_number: newSummary.number,
            order_id: newSummary.id,
          });
        }
        setAppBackgroundEffectsStore({
          summaryOfNextOrderForPicking: newSummary,
        });
      };
      if (isOpsAssignmentV0Enabled) {
        const {
          data: { getSummaryOfNextOrderToPick },
        } = await client.query<
          GetSummaryOfNextOrderToPickQuery,
          GetSummaryOfNextOrderToPickQueryVariables
        >({
          query: GetSummaryOfNextOrderToPickDocument,
          fetchPolicy: "network-only",
        });
        const newSummary = getSummaryOfNextOrderToPick.orderSummary
          ? {
              id: getSummaryOfNextOrderToPick.orderSummary.id,
              number: getSummaryOfNextOrderToPick.orderSummary.number ?? null,
            }
          : null;
        handleOrderUpdate(newSummary);
      } else {
        const {
          data: { nextOrderForPicking },
        } = await client.query<NextOrderForPickingIdQuery, NextOrderForPickingIdQueryVariables>({
          query: NextOrderForPickingIdDocument,
          fetchPolicy: "network-only",
        });
        const newSummary = nextOrderForPicking.order
          ? {
              id: nextOrderForPicking.order.id,
              number: nextOrderForPicking.order.number ?? null,
            }
          : null;
        handleOrderUpdate(newSummary);
      }
    }
  }, [
    client,
    isOpsAssignmentV0Enabled,
    sendSegmentTrackEvent,
    setAppBackgroundEffectsStore,
    shouldFetch,
  ]);

  const urgentChecksPollingHandler = useCallback(async () => {
    if (shouldFetch()) {
      const {
        data: { getOpenedChecksCountForHub },
      } = await client.query<GetPendingCheckCountQuery, GetPendingCheckCountQueryVariables>({
        query: GetPendingCheckCountDocument,
        variables: {
          input: { priority: [4, 5, 6] },
        },
        fetchPolicy: "network-only",
      });

      setAppBackgroundEffectsStore({
        hasOpenUrgentChecks: (getOpenedChecksCountForHub.checkCount ?? 0) > 0,
      });
    }
  }, [client, shouldFetch, setAppBackgroundEffectsStore]);

  const publicRestockingListPollingHandler = useCallback(async () => {
    if (shouldFetch()) {
      const {
        data: { getPublicRestockingList },
      } = await client.query<GetPublicRestockingListQuery, GetPublicRestockingListQueryVariables>({
        query: GetPublicRestockingListDocument,
        fetchPolicy: "network-only",
      });

      setAppBackgroundEffectsStore({
        publicRestockingList: { getPublicRestockingList } ?? null,
      });
    }
  }, [client, shouldFetch, setAppBackgroundEffectsStore]);

  useEffect(() => {
    nextOrderPollingHandler();
    nextOrderPollingRef.current = setInterval(
      nextOrderPollingHandler,
      NEXT_ORDER_POLLING_INTERVAL_MS,
    );

    urgentChecksPollingHandler();
    urgentChecksPollingRef.current = setInterval(
      urgentChecksPollingHandler,
      POLLING_INTERVAL_MS_FOR_URGENT_TASK_COUNT_AND_PUBLIC_LIST,
    );

    publicRestockingListPollingHandler();
    publicRestockingListPollingRef.current = setInterval(
      publicRestockingListPollingHandler,
      POLLING_INTERVAL_MS_FOR_URGENT_TASK_COUNT_AND_PUBLIC_LIST,
    );

    return () => {
      clearInterval(nextOrderPollingRef.current);
      clearInterval(urgentChecksPollingRef.current);
      clearInterval(publicRestockingListPollingRef.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldFetch]);
}
