import * as Types from "../../../../__graphql__/types.js";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type ClaimDeviceMutationVariables = Types.Exact<{
  input: Types.DeviceClaimInput;
}>;

export type ClaimDeviceMutation = {
  __typename?: "Mutation";
  claimDevice: { __typename?: "DeviceClaim"; employeeId: string };
};

export type ForceClaimDeviceMutationVariables = Types.Exact<{
  input: Types.DeviceClaimInput;
}>;

export type ForceClaimDeviceMutation = {
  __typename?: "Mutation";
  forceClaimDevice: { __typename?: "DeviceClaim"; employeeId: string };
};

export type UnclaimDeviceMutationVariables = Types.Exact<{
  input: Types.DeviceClaimInput;
}>;

export type UnclaimDeviceMutation = {
  __typename?: "Mutation";
  unclaimDevice: { __typename?: "MutationResponse"; message: string };
};

export const ClaimDeviceDocument = gql`
  mutation claimDevice($input: DeviceClaimInput!) {
    claimDevice(input: $input) {
      employeeId
    }
  }
`;
export type ClaimDeviceMutationFn = Apollo.MutationFunction<
  ClaimDeviceMutation,
  ClaimDeviceMutationVariables
>;

/**
 * __useClaimDeviceMutation__
 *
 * To run a mutation, you first call `useClaimDeviceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClaimDeviceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [claimDeviceMutation, { data, loading, error }] = useClaimDeviceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useClaimDeviceMutation(
  baseOptions?: Apollo.MutationHookOptions<ClaimDeviceMutation, ClaimDeviceMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ClaimDeviceMutation, ClaimDeviceMutationVariables>(
    ClaimDeviceDocument,
    options,
  );
}
export type ClaimDeviceMutationHookResult = ReturnType<typeof useClaimDeviceMutation>;
export type ClaimDeviceMutationResult = Apollo.MutationResult<ClaimDeviceMutation>;
export type ClaimDeviceMutationOptions = Apollo.BaseMutationOptions<
  ClaimDeviceMutation,
  ClaimDeviceMutationVariables
>;
export const ForceClaimDeviceDocument = gql`
  mutation forceClaimDevice($input: DeviceClaimInput!) {
    forceClaimDevice(input: $input) {
      employeeId
    }
  }
`;
export type ForceClaimDeviceMutationFn = Apollo.MutationFunction<
  ForceClaimDeviceMutation,
  ForceClaimDeviceMutationVariables
>;

/**
 * __useForceClaimDeviceMutation__
 *
 * To run a mutation, you first call `useForceClaimDeviceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForceClaimDeviceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forceClaimDeviceMutation, { data, loading, error }] = useForceClaimDeviceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useForceClaimDeviceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ForceClaimDeviceMutation,
    ForceClaimDeviceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ForceClaimDeviceMutation, ForceClaimDeviceMutationVariables>(
    ForceClaimDeviceDocument,
    options,
  );
}
export type ForceClaimDeviceMutationHookResult = ReturnType<typeof useForceClaimDeviceMutation>;
export type ForceClaimDeviceMutationResult = Apollo.MutationResult<ForceClaimDeviceMutation>;
export type ForceClaimDeviceMutationOptions = Apollo.BaseMutationOptions<
  ForceClaimDeviceMutation,
  ForceClaimDeviceMutationVariables
>;
export const UnclaimDeviceDocument = gql`
  mutation unclaimDevice($input: DeviceClaimInput!) {
    unclaimDevice(input: $input) {
      message
    }
  }
`;
export type UnclaimDeviceMutationFn = Apollo.MutationFunction<
  UnclaimDeviceMutation,
  UnclaimDeviceMutationVariables
>;

/**
 * __useUnclaimDeviceMutation__
 *
 * To run a mutation, you first call `useUnclaimDeviceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnclaimDeviceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unclaimDeviceMutation, { data, loading, error }] = useUnclaimDeviceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnclaimDeviceMutation(
  baseOptions?: Apollo.MutationHookOptions<UnclaimDeviceMutation, UnclaimDeviceMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UnclaimDeviceMutation, UnclaimDeviceMutationVariables>(
    UnclaimDeviceDocument,
    options,
  );
}
export type UnclaimDeviceMutationHookResult = ReturnType<typeof useUnclaimDeviceMutation>;
export type UnclaimDeviceMutationResult = Apollo.MutationResult<UnclaimDeviceMutation>;
export type UnclaimDeviceMutationOptions = Apollo.BaseMutationOptions<
  UnclaimDeviceMutation,
  UnclaimDeviceMutationVariables
>;
