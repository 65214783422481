import { useCallback, useEffect, useMemo, useRef } from "react";

import {
  Box,
  Fade,
  FlexProps,
  Popover,
  PopoverContent,
  PopoverTrigger,
  useDisclosure,
} from "@chakra-ui/react";

import { Chip, ChipProps, ChipState } from "ui/Chip/Chip";
import { DropDownList, DropDownOption } from "ui/DropDownList/DropDownList";
import { CaretDownIcon } from "ui/Icons/Icons";
import { TitleS } from "ui/Typography/Typography";
import { isNotNullNorUndefined } from "utils/tsHelpers";

export type DropDownProps = {
  options: DropDownOption[];
  value: string | null;
  onInput: (v: string | null) => void;
  children?: React.ReactNode;
  filterMode?: boolean;
  listStyles?: Omit<FlexProps, "onInput">;
  scrollContainerRef?: HTMLElement | null;
  testId?: string;
  titleColor?: string;
} & Omit<ChipProps, "state" | "value" | "onInput">;

export function DropDown({
  options,
  value,
  onInput,
  children,
  filterMode = true,
  listStyles,
  scrollContainerRef,
  testId,
  titleColor,
  ...rest
}: DropDownProps) {
  const { isOpen, onToggle, onClose } = useDisclosure();

  const chipState: ChipState = useMemo(() => {
    if (isOpen) {
      return "hover";
    }
    if (filterMode && value !== null) {
      return "active";
    }
    return "default";
  }, [isOpen, value, filterMode]);

  const handleInput = useCallback(
    (v: string | null) => {
      onInput(v);
      setTimeout(() => {
        onClose();
      }, 80);
    },
    [onInput, onClose],
  );

  const popoverRef = useRef<HTMLUListElement | null>(null);

  const triggerRef = useRef<HTMLButtonElement | null>(null);
  const scrollToTrigger = useCallback(() => {
    scrollContainerRef?.scrollTo({ top: triggerRef.current?.offsetTop, behavior: "smooth" });
  }, [scrollContainerRef]);
  const toggleDropDown = useCallback(() => {
    if (isNotNullNorUndefined(scrollContainerRef)) {
      scrollToTrigger();
      // Not sure why the next line works,
      // but it makes the dropdown open only after the scroll is finished
      setTimeout(onToggle, 0);
    } else {
      onToggle();
    }
  }, [onToggle, scrollContainerRef, scrollToTrigger]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const ref = popoverRef.current;
    if (!ref) {
      return;
    }
    const handleScroll = (e: WheelEvent | TouchEvent) => {
      // If element is not scrollable
      if (ref.scrollHeight <= ref.clientHeight) {
        // We cancel the scroll event so that it doesn't scroll the document's body
        e.preventDefault();
      }
    };
    if (isOpen) {
      ref.addEventListener("wheel", handleScroll);
      ref.addEventListener("touchmove", handleScroll);
      // eslint-disable-next-line consistent-return
      return () => {
        ref.removeEventListener("wheel", handleScroll);
        ref.removeEventListener("touchmove", handleScroll);
      };
    }
  }, [isOpen]);

  return (
    <Popover placement="auto" gutter={10} onClose={onClose} isOpen={isOpen}>
      <Fade in={isOpen} style={{ zIndex: "9", position: "fixed" }}>
        <Box
          position="fixed"
          top="0"
          left="0"
          width="100%"
          height={isOpen ? "100%" : "0"}
          bgColor="rgba(0,0,0,0.2)"
          zIndex="9"
          data-testid="popover-overlay"
        />
      </Fade>
      <PopoverTrigger>
        <Chip
          state={chipState}
          pr="s100"
          gap="s50"
          onClick={options.length ? toggleDropDown : () => {}}
          justifyContent="space-between"
          ref={triggerRef}
          data-testid={testId}
          {...rest}
        >
          {!filterMode && value ? (
            <TitleS
              whiteSpace="nowrap"
              textOverflow="ellipsis"
              maxWidth="calc(100% - 24px)"
              overflow="hidden"
              color={titleColor}
            >
              {options.find(({ key }) => key === value)?.label}
            </TitleS>
          ) : (
            children
          )}
          <CaretDownIcon boxSize="24px" />
        </Chip>
      </PopoverTrigger>
      <PopoverContent width="fit-content" mr="s100" border="none">
        <DropDownList
          options={options}
          onInput={handleInput}
          value={value}
          ref={popoverRef}
          testId={testId}
          {...listStyles}
        />
      </PopoverContent>
    </Popover>
  );
}
