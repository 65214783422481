import { useCallback } from "react";

import { Flex, IconButton } from "@chakra-ui/react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router";

import { PageName } from "analytics/events";
import { routes } from "config/routes";
import { IntlMessageId } from "shared/types/lang";
import { ArrowLeftIcon } from "ui/Icons/Icons";
import { Shelf } from "ui/Shelf/Shelf";
import { TitleM } from "ui/Typography/Typography";
import { isNotNullNorUndefined } from "utils/tsHelpers";

export type TaskCheckHeaderProps = {
  headerMessageId?: IntlMessageId;
  onBack?: () => void;
  origin: PageName;
  shelfLetter: string | null;
  shelfNumber: string | null;
};

export function TaskCheckHeader({
  headerMessageId,
  onBack,
  origin,
  shelfLetter,
  shelfNumber,
}: TaskCheckHeaderProps) {
  const intl = useIntl();
  const navigate = useNavigate();

  const handleNavigation = useCallback(() => {
    if (onBack) {
      return onBack();
    }

    let url: string;

    switch (origin) {
      case PageName.ACTIVITIES_PAGE: {
        url = routes.activities.root;
        break;
      }
      case PageName.INVENTORY_FRESH_CHECK_LIST_PAGE: {
        url = routes.inventory.freshChecks.root;
        break;
      }
      case PageName.INVENTORY_BBD_CHECK_LIST_PAGE: {
        url = routes.inventory.bbdChecks.root;
        break;
      }
      case PageName.INVENTORY_STOCK_CHECK_LIST_PAGE: {
        url = routes.inventory.stockChecks.root;
        break;
      }
      case PageName.INVENTORY_EOY_CHECK_LIST_PAGE: {
        url = routes.inventory.eoyChecks.root;
        break;
      }
      case PageName.INVENTORY_RECOUNT_CHECK_LIST_PAGE: {
        url = routes.inventory.eoyRecountChecks.root;
        break;
      }
      default: {
        // This case should never happen but we need to handle it
        url = routes.inventory.root;
        break;
      }
    }
    return navigate(url, { replace: true });
  }, [navigate, onBack, origin]);

  const showShelf = isNotNullNorUndefined(shelfLetter) && isNotNullNorUndefined(shelfNumber);

  return (
    <Flex w="100%" h="56px" justifyContent="space-between" bg="white">
      <Flex alignItems="center">
        <IconButton
          variant="ghost"
          aria-label="Go back"
          icon={<ArrowLeftIcon boxSize="1.5rem" />}
          onClick={handleNavigation}
        />
        <TitleM>{intl.formatMessage({ id: headerMessageId })}</TitleM>
      </Flex>
      <Flex px="s200">
        {showShelf && <Shelf letter={shelfLetter} number={shelfNumber} variant="small" />}
      </Flex>
    </Flex>
  );
}
