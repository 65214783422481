import * as Types from "../../../../../../__graphql__/types.js";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type UpdateProductBbdMutationVariables = Types.Exact<{
  input: Types.UpdateProductBbdInput;
}>;

export type UpdateProductBbdMutation = {
  __typename?: "Mutation";
  updateProductBBD: { __typename?: "CheckMutationResponse"; success: boolean };
};

export const UpdateProductBbdDocument = gql`
  mutation updateProductBBD($input: UpdateProductBBDInput!) {
    updateProductBBD(input: $input) {
      success
    }
  }
`;
export type UpdateProductBbdMutationFn = Apollo.MutationFunction<
  UpdateProductBbdMutation,
  UpdateProductBbdMutationVariables
>;

/**
 * __useUpdateProductBbdMutation__
 *
 * To run a mutation, you first call `useUpdateProductBbdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductBbdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductBbdMutation, { data, loading, error }] = useUpdateProductBbdMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProductBbdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateProductBbdMutation,
    UpdateProductBbdMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateProductBbdMutation, UpdateProductBbdMutationVariables>(
    UpdateProductBbdDocument,
    options,
  );
}
export type UpdateProductBbdMutationHookResult = ReturnType<typeof useUpdateProductBbdMutation>;
export type UpdateProductBbdMutationResult = Apollo.MutationResult<UpdateProductBbdMutation>;
export type UpdateProductBbdMutationOptions = Apollo.BaseMutationOptions<
  UpdateProductBbdMutation,
  UpdateProductBbdMutationVariables
>;
