import { Circle } from "@chakra-ui/react";
import { useIntl } from "react-intl";

import { YesNoModal } from "shared/components/YesNoModal";
import { WarningThreeIcon } from "ui/Icons/Icons";

export type ExitFlowInRolliValidationModalProps = {
  onClickConfirm: () => void;
  onClickDecline: () => void;
  isOpen: boolean;
};

export function ExitFlowInRolliValidationModal({
  onClickConfirm,
  onClickDecline,
  isOpen,
}: ExitFlowInRolliValidationModalProps) {
  const intl = useIntl();

  return (
    <YesNoModal
      isOpen={isOpen}
      onClickYes={onClickConfirm}
      onClickNo={onClickDecline}
      title={intl.formatMessage({
        id: "components.activities.exit-flow-in-rolli-validation-modal.title",
      })}
      icon={
        <Circle size="120px" marginX="auto" bg="grey.100">
          <WarningThreeIcon boxSize="80px" />
        </Circle>
      }
      body={intl.formatMessage({
        id: "components.activities.exit-flow-in-rolli-validation-modal.body",
      })}
      yesLabel={intl.formatMessage({
        id: "components.activities.exit-flow-in-rolli-validation-modal.yes-label",
      })}
      noLabel={intl.formatMessage({
        id: "components.activities.exit-flow-in-rolli-validation-modal.no-label",
      })}
    />
  );
}
