import { NavigationHeader, NavigationHeaderProps } from "ui/NavigationHeader/NavigationHeader";
import { Shelf } from "ui/Shelf/Shelf";
import { isNotNullNorUndefined } from "utils/tsHelpers";

export type NavigationHeaderShelfNumberProps = {
  shelfLetter: string | null;
  shelfNumber: string | null;
} & NavigationHeaderProps;

export function NavigationHeaderShelfNumber({
  shelfLetter,
  shelfNumber,
  ...rest
}: NavigationHeaderShelfNumberProps) {
  const hasShelf = isNotNullNorUndefined(shelfLetter) && isNotNullNorUndefined(shelfNumber);
  return (
    <NavigationHeader
      rightElement={
        hasShelf && (
          <Shelf
            data-testid="navigation-header-shelf"
            letter={shelfLetter}
            number={shelfNumber}
            variant="small"
          />
        )
      }
      {...rest}
    />
  );
}
