import { Button as ChakraButton, ButtonProps } from "@chakra-ui/react";
import { FormattedMessage } from "react-intl";

type SkipScanningButtonProps = {
  onClickSkipButton: () => void;
} & ButtonProps;

export function SkipScanningButton({ onClickSkipButton, ...rest }: SkipScanningButtonProps) {
  return (
    <ChakraButton
      color="grey.800"
      mt="s200"
      textDecoration="underline"
      variant="link"
      onClick={onClickSkipButton}
      {...rest}
    >
      <FormattedMessage id="components.picking.skip-scanning-button.label" />
    </ChakraButton>
  );
}
