import { FormattedMessage, useIntl } from "react-intl";

import { IntlMessageId } from "shared/types/lang";
import { CtaModal } from "ui/CtaModal";
import { WarningThreeIcon } from "ui/Icons/Icons";
import { BodyM } from "ui/Typography/Typography";
import { transformChunks } from "utils/ui";

export type WarningModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

type WarningModalTemplateProps = {
  title: IntlMessageId;
  body: IntlMessageId;
} & WarningModalProps;

export function WarningModalTemplate({
  title,
  body,
  isOpen = false,
  onClose,
}: WarningModalTemplateProps) {
  const intl = useIntl();

  return (
    <CtaModal
      isOpen={isOpen}
      onClose={onClose}
      title={intl.formatMessage({ id: title })}
      body={
        <BodyM textAlign="center">
          <FormattedMessage
            id={body}
            values={transformChunks({ pink: { as: "strong", color: "pinkFlink.500" } })}
          />
        </BodyM>
      }
      ctaLabel={intl.formatMessage({
        id: "pages.activities.warning-modal-template.ctaLabel",
      })}
      onClickCta={onClose}
      hideCloseButton
      headerIcon={<WarningThreeIcon boxSize="80px" />}
    />
  );
}

export function WrongRolliModal({ isOpen, onClose }: WarningModalProps) {
  return (
    <WarningModalTemplate
      title="components.ui.wrong-rolli-modal.header"
      body="components.ui.wrong-rolli-modal.description"
      onClose={onClose}
      isOpen={isOpen}
    />
  );
}

export function RolliContainsCratesModal({ isOpen, onClose }: WarningModalProps) {
  return (
    <WarningModalTemplate
      title="pages.activities.rolli-contains-crates-modal.title"
      body="pages.activities.rolli-contains-crates-modal.body"
      onClose={onClose}
      isOpen={isOpen}
    />
  );
}

export function PotentialIncorrectRolliModal({ isOpen, onClose }: WarningModalProps) {
  return (
    <WarningModalTemplate
      title="pages.activities.potential-incorrect-rolli-modal.title"
      body="pages.activities.potential-incorrect-rolli-modal.body"
      onClose={onClose}
      isOpen={isOpen}
    />
  );
}

export function DeliveryDataUnavailableModal({ isOpen, onClose }: WarningModalProps) {
  return (
    <WarningModalTemplate
      title="pages.activities.delivery-data-unavailable-modal.title"
      body="pages.activities.delivery-data-unavailable-modal.body"
      onClose={onClose}
      isOpen={isOpen}
    />
  );
}
