/* eslint-disable react/jsx-no-constructed-context-values */
import React, { createContext, useContext } from "react";

import { AllFeatureFlag, useEppoAllFeatureFlags } from "shared/hooks/useEppoAllFeatureFlags";

const EppoFeatureFlagsContext = createContext<{
  decisions: AllFeatureFlag;
  isLoading: boolean;
}>({
  decisions: null,
  isLoading: false,
});

type EppoFeatureFlagsProviderProps = {
  children: React.ReactNode;
};

export function EppoFeatureFlagsProvider({ children }: EppoFeatureFlagsProviderProps) {
  const { decisions, isLoading } = useEppoAllFeatureFlags();
  return (
    <EppoFeatureFlagsContext.Provider value={{ decisions, isLoading }}>
      {children}
    </EppoFeatureFlagsContext.Provider>
  );
}

export const useEppoFeatureFlagsProvider = () => useContext(EppoFeatureFlagsContext);
