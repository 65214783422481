import { Flex, Image } from "@chakra-ui/react";
import { FormattedMessage } from "react-intl";

import { useAppPWAStateStore } from "core/stores/useAppPWAStateStore";
import AppLogo from "images/not-using-pwa-page/app-logo.webp";
import InstallApp from "images/not-using-pwa-page/install-app.webp";
import Dots from "images/not-using-pwa-page/nav-bar-dots.webp";
import Popup from "images/not-using-pwa-page/popup.webp";
import { Page } from "shared/components/Page";
import { IntlMessageId } from "shared/types/lang";
import { BodyS, TitleM } from "ui/Typography/Typography";
import { transformChunks } from "utils/ui";

type InstallAppTutorialLineProps = {
  stepNumber: number;
  intlTextId: IntlMessageId;
  image: React.ReactElement;
};

function InstallAppTutorialLine({ stepNumber, intlTextId, image }: InstallAppTutorialLineProps) {
  return (
    <Flex
      borderBottom="1px solid"
      borderBottomColor="grey.100"
      w="100%"
      justifyContent="space-between"
      alignItems="center"
      p="s200"
    >
      <Flex direction="column">
        <Flex
          alignItems="center"
          justifyContent="center"
          h="32px"
          w="32px"
          bg="pinkFlink.100"
          borderRadius="8px"
        >
          <TitleM color="pinkFlink.500">{`${stepNumber}.`}</TitleM>
        </Flex>
        <BodyS mt="s100">
          <FormattedMessage id={intlTextId} values={transformChunks({ b: { as: "strong" } })} />
        </BodyS>
      </Flex>
      {image}
    </Flex>
  );
}

export function NotUsingPWAPage() {
  const { isLoading } = useAppPWAStateStore();

  return (
    <Page isLoading={isLoading} isFull>
      <Flex py="s300" px="s200" w="100%" bg="pinkFlink.100" alignItems="center">
        <Image
          src={AppLogo}
          h="56px"
          w="56px"
          filter="drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.16))"
          borderRadius="16px"
        />
        <Flex direction="column" ml="s300">
          <TitleM>
            <FormattedMessage id="pages.not-using-pwa.need-install" />
          </TitleM>
          <TitleM color="pinkFlink.500">
            <FormattedMessage id="pages.not-using-pwa.flink-hub-staff-app" />
          </TitleM>
          <TitleM>
            <FormattedMessage id="pages.not-using-pwa.to-receive" />
          </TitleM>
        </Flex>
      </Flex>
      <InstallAppTutorialLine
        stepNumber={1}
        intlTextId="pages.not-using-pwa.click-dots"
        image={<Image width="85px" src={Dots} />}
      />
      <InstallAppTutorialLine
        stepNumber={2}
        intlTextId="pages.not-using-pwa.click-install-app"
        image={
          <Image
            ml="s200"
            width="109px"
            src={InstallApp}
            filter="drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.08))"
          />
        }
      />
      <InstallAppTutorialLine
        stepNumber={3}
        intlTextId="pages.not-using-pwa.popup"
        image={<Image ml="s200" width="150px" src={Popup} />}
      />
    </Page>
  );
}
