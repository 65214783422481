import { useCallback } from "react";

import { openOngoingActivityModal } from "core/stores/useLayoutStore";
import { useBarcodeScanner } from "shared/hooks/useBarcodeScanner";
import { useTimedActivityStore } from "shared/stores/useTimedActivityStore";
import { isNotNullNorUndefined } from "utils/tsHelpers";

import { useProductSearchService } from "../../../shared/hooks/useProductSearchService";
import { useScan } from "../../../shared/hooks/useScan/useScan";

export function useInboundIndexEanScan() {
  const productSearchService = useProductSearchService();
  const { processBarcode } = useBarcodeScanner();

  const timedActivityInstance = useTimedActivityStore((state) => state.timedActivityInstance);

  const onScan = useCallback(
    async (value: string) => {
      if (isNotNullNorUndefined(timedActivityInstance)) {
        openOngoingActivityModal();
        return;
      }
      const { send } = productSearchService;
      const canScan = productSearchService.getSnapshot().matches("idle");
      if (!canScan) {
        return;
      }
      const searchedEan = processBarcode(value);
      if (searchedEan) {
        send({ type: "RESOLVE_EAN", ean: searchedEan });
      }
    },
    [processBarcode, productSearchService, timedActivityInstance],
  );

  useScan({ onScan });
}
