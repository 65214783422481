import React from "react";

import { Flex, FlexProps } from "@chakra-ui/react";
import { useIntl } from "react-intl";

import { Button } from "ui/Button/Button";

export type StockCorrectorShortcutProps = {
  onConfirm: () => void;
  onEdit: () => void;
  canConfirm: boolean;
} & FlexProps;

export function StockCorrectorShortcut({
  onConfirm,
  onEdit,
  canConfirm,
  ...rest
}: StockCorrectorShortcutProps) {
  const intl = useIntl();
  return (
    <Flex gap="s100" {...rest}>
      <Button disabled={!canConfirm} flinkVariant="secondary" onClick={onConfirm} width="full">
        {intl.formatMessage({
          id: "pages.stock-corrections.shortcut.confirm",
          defaultMessage: "Confirm",
        })}
      </Button>
      <Button flinkVariant="secondary" onClick={onEdit} width="full">
        {intl.formatMessage({
          id: "pages.stock-corrections.shortcut.edit",
          defaultMessage: "Edit",
        })}
      </Button>
    </Flex>
  );
}
