import React from "react";

import { Flex, FlexProps } from "@chakra-ui/react";

export const BOTTOM_TAB_BAR_HEIGHT_NUMBER = 82;

export type BottomTabBarContainerProps = {
  children: React.ReactNode;
} & FlexProps;

export function BottomTabBarContainer({ children, ...rest }: BottomTabBarContainerProps) {
  return (
    <Flex
      justifyContent="space-around"
      h={`${BOTTOM_TAB_BAR_HEIGHT_NUMBER}px`}
      data-testid="bottom-bar-menu"
      {...rest}
    >
      {children}
    </Flex>
  );
}
