import * as Types from "../../../../../../__graphql__/types.js";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type ValidateEoyCheckMutationVariables = Types.Exact<{
  input: Types.ValidateEoyCheckInput;
}>;

export type ValidateEoyCheckMutation = {
  __typename?: "Mutation";
  validateEoyCheck: { __typename?: "CheckMutationResponse"; success: boolean };
};

export const ValidateEoyCheckDocument = gql`
  mutation validateEoyCheck($input: ValidateEoyCheckInput!) {
    validateEoyCheck(input: $input) {
      success
    }
  }
`;
export type ValidateEoyCheckMutationFn = Apollo.MutationFunction<
  ValidateEoyCheckMutation,
  ValidateEoyCheckMutationVariables
>;

/**
 * __useValidateEoyCheckMutation__
 *
 * To run a mutation, you first call `useValidateEoyCheckMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidateEoyCheckMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validateEoyCheckMutation, { data, loading, error }] = useValidateEoyCheckMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useValidateEoyCheckMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ValidateEoyCheckMutation,
    ValidateEoyCheckMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ValidateEoyCheckMutation, ValidateEoyCheckMutationVariables>(
    ValidateEoyCheckDocument,
    options,
  );
}
export type ValidateEoyCheckMutationHookResult = ReturnType<typeof useValidateEoyCheckMutation>;
export type ValidateEoyCheckMutationResult = Apollo.MutationResult<ValidateEoyCheckMutation>;
export type ValidateEoyCheckMutationOptions = Apollo.BaseMutationOptions<
  ValidateEoyCheckMutation,
  ValidateEoyCheckMutationVariables
>;
