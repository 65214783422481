import { useCallback } from "react";

import { PageName } from "analytics/events";
import { useCheckAlreadyCompletedToast } from "flows/Inventory/shared/hooks/useCheckAlreadyCompletedToast";
import { useCheckSessionExpiredToast } from "flows/Inventory/shared/hooks/useCheckSessionExpiredToast";
import { useChecksRedirect } from "flows/Inventory/shared/hooks/useChecksRedirect";
import { useTaskCompletedToast } from "flows/Inventory/shared/hooks/useTaskCompletedToast";
import { ErrorTypeForAnalytics, useAnalytics } from "shared/hooks/useAnalytics";
import { useErrorToastWithAudio } from "shared/hooks/useErrorToastWithAudio";
import { isNotNullNorUndefined, isNullOrUndefined } from "utils/tsHelpers";

import { StockCheckMachineContext, StockCheckMachineServices } from "./types";

export function useStockCheckActionsImplems() {
  const { sendSegmentTrackEvent } = useAnalytics();
  const showTaskCompletedToast = useTaskCompletedToast();
  const { showToastAndPlayError } = useErrorToastWithAudio({ errorToastId: "stock_machine_error" });
  const { showCheckAlreadyCompletedToast } = useCheckAlreadyCompletedToast();
  const { showCheckSessionExpiredToast } = useCheckSessionExpiredToast();
  const { navigateToCheck, navigateToOrigin } = useChecksRedirect();

  const sendTrackStockCheckStarted = useCallback(
    (ctx: StockCheckMachineContext) => {
      if (isNullOrUndefined(ctx.sku)) {
        return;
      }
      sendSegmentTrackEvent("stockCheckStarted", {
        type: "automated",
        check_id: ctx.checkId,
        product_sku: ctx.sku,
        origin: ctx.origin,
      });
    },
    [sendSegmentTrackEvent],
  );

  const sendTrackStockCheckFinished = useCallback(
    (ctx: StockCheckMachineContext) => {
      sendSegmentTrackEvent("stockCheckFinished", {
        type: "automated",
        check_id: ctx.checkId,
        product_sku: ctx.sku,
        quantity_expected: ctx.expectedQuantity,
        quantity_counted: ctx.declaredQuantity,
        quantity_damaged: ctx.damagedQuantity,
        quantity_expired: ctx.expiredQuantity,
        quantity_tgtg: ctx.tooGoodToGoQuantity,
      });
    },
    [sendSegmentTrackEvent],
  );

  const sendTrackOnError = useCallback(() => {
    sendSegmentTrackEvent("errorShown", {
      screen_name: PageName.INVENTORY_STOCK_CHECK_LIST_PAGE,
      component_value: ErrorTypeForAnalytics.UNKNOWN,
    });
  }, [sendSegmentTrackEvent]);

  const checkSessionExpiredToast = useCallback(() => {
    showCheckSessionExpiredToast();
  }, [showCheckSessionExpiredToast]);

  const taskCompletedToast = useCallback(() => {
    showTaskCompletedToast();
  }, [showTaskCompletedToast]);

  const taskAlreadyCompletedToast = useCallback(() => {
    showCheckAlreadyCompletedToast();
  }, [showCheckAlreadyCompletedToast]);

  const errorToast = useCallback(() => {
    showToastAndPlayError({ title: "hooks.use-custom-toast.error-title" });
  }, [showToastAndPlayError]);

  const redirectToOrigin = useCallback(
    (context: StockCheckMachineContext) => navigateToOrigin(context.origin),
    [navigateToOrigin],
  );

  const redirectToCheck = useCallback(
    (
      context: StockCheckMachineContext,
      event: StockCheckMachineServices["fetchNextCheckAndStartCheck"],
    ) => {
      if ("check" in event.data && isNotNullNorUndefined(event.data.check)) {
        navigateToCheck(event.data.check, context.origin);
      }
    },
    [navigateToCheck],
  );

  return {
    sendTrackStockCheckFinished,
    taskCompletedToast,
    taskAlreadyCompletedToast,
    checkSessionExpiredToast,
    errorToast,
    sendTrackOnError,
    sendTrackStockCheckStarted,
    redirectToOrigin,
    redirectToCheck,
  };
}
