import { useCallback } from "react";

import { ApolloClient, useApolloClient } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import { datadogRum } from "@datadog/browser-rum";
import shallow from "zustand/shallow";

import { AuthLogoutActionMethod } from "analytics/events";
import { useAppPWAStateStore } from "core/stores/useAppPWAStateStore";
import { useEmployeeStore } from "core/stores/useEmployeeStore";
import { EppoFeatureFlags } from "core/types/flags";
import { useRestockingServiceStore } from "flows/Inventory/flows/RestockingList/stores/restockingServiceStore";
import { useAnalytics } from "shared/hooks/useAnalytics";
import { useEppoFeatureFlagProvider } from "shared/hooks/useEppoFeatureFlag";
import { useHubSlug } from "shared/hooks/useHubSlug";
import { asyncTryCatchWithErrorChecking } from "utils/error";

import {
  UnclaimDeviceDocument,
  UnclaimDeviceMutation,
  UnclaimDeviceMutationVariables,
} from "../queries/deviceClaim/deviceClaim.generated";

const unclaimDevice = (client: ApolloClient<object>, employeeId: string) => {
  return client.mutate<UnclaimDeviceMutation, UnclaimDeviceMutationVariables>({
    mutation: UnclaimDeviceDocument,
    variables: { input: { employeeId } },
  });
};

export function useEmployeeLogout() {
  const { isAuthenticated } = useAuth0();

  const hubSlug = useHubSlug();
  const { sendSegmentTrackEvent } = useAnalytics();
  const client = useApolloClient();
  const { isFeatureEnabled: isIntercomEnabled } = useEppoFeatureFlagProvider(
    EppoFeatureFlags.INTERCOM,
  );
  const { appUpdateAvailable, updateServiceWorker } = useAppPWAStateStore((state) => ({
    appUpdateAvailable: state.appUpdateAvailable,
    updateServiceWorker: state.updateServiceWorker,
  }));
  const { badgeNo, reset: resetEmployeeStore } = useEmployeeStore(
    (state) => ({
      badgeNo: state.badgeNo,
      reset: state.reset,
    }),
    shallow,
  );

  const setPrivateRestockingListData = useRestockingServiceStore(
    (state) => state.setPrivateRestockingListData,
  );

  return useCallback(
    async (segmentTrackEventLogoutActionType: AuthLogoutActionMethod) => {
      if (isAuthenticated && badgeNo) {
        await asyncTryCatchWithErrorChecking(
          () => unclaimDevice(client, badgeNo),
          () => {}, // swallow errors
        );

        sendSegmentTrackEvent("logoutCompleted", {
          type: "staff",
          quinyx_badge_number: badgeNo,
          method: segmentTrackEventLogoutActionType,
          hub_slug: hubSlug!,
        });
        datadogRum.setUser({ hub_slug: hubSlug });
        if (isIntercomEnabled) {
          Intercom("shutdown");
        }
      }
      if (appUpdateAvailable && updateServiceWorker) {
        updateServiceWorker();
      }
      resetEmployeeStore();
      setPrivateRestockingListData(undefined);
    },
    [
      appUpdateAvailable,
      updateServiceWorker,
      isAuthenticated,
      badgeNo,
      resetEmployeeStore,
      setPrivateRestockingListData,
      sendSegmentTrackEvent,
      hubSlug,
      isIntercomEnabled,
      client,
    ],
  );
}
