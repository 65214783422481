import { Input, InputProps } from "@chakra-ui/react";

export type ProductQuantityInputProps = {
  displayAsHandlingUnit: boolean;
  hasWarning?: boolean;
  elementRef?: (element: HTMLInputElement) => void;
} & InputProps;

const getColor = (displayAsHandlingUnit: boolean, hasWarning: boolean) => {
  let colorProps;
  let focusVisibleProps;

  if (hasWarning) {
    colorProps = {
      bg: "red.100",
    };
    focusVisibleProps = {
      borderColor: "red.500",
      bg: "red.100",
      caretColor: "red.500",
    };
  } else if (displayAsHandlingUnit) {
    colorProps = {
      bg: "orange.100",
    };
    focusVisibleProps = {
      borderColor: "orange.400",
      bg: "orange.200",
      caretColor: "orange.500",
    };
  } else {
    colorProps = {
      bg: "marine.100",
    };
    focusVisibleProps = {
      borderColor: "marine.400",
      bg: "marine.200",
      caretColor: "marine.500",
    };
  }

  return { colorProps, focusVisibleProps };
};

export function ProductQuantityInput({
  displayAsHandlingUnit,
  hasWarning = false,
  elementRef,
  ...rest
}: ProductQuantityInputProps) {
  const { colorProps, focusVisibleProps } = getColor(displayAsHandlingUnit, hasWarning);
  return (
    <Input
      ref={elementRef}
      maxWidth="56px"
      w="56px"
      variant="outlined"
      type="text"
      textAlign="center"
      _focusVisible={{
        zIndex: 1,
        border: "2px solid",
        ...focusVisibleProps,
      }}
      inputMode="numeric"
      fontFamily="Flink-Bold"
      fontStyle="normal"
      fontWeight={700}
      fontSize="1.25rem"
      lineHeight="1.75rem"
      paddingX="0px"
      userSelect="text"
      {...colorProps}
      {...rest}
    />
  );
}
