import { forwardRef } from "react";

import { Box, BoxProps, Flex } from "@chakra-ui/react";

import { BodyS, HeaderS } from "ui/Typography/Typography";
import { isNotNullNorUndefined } from "utils/tsHelpers";

type SeparatorHeaderProps = {
  title: string;
  hintText?: string;
} & BoxProps;

export const SeparatorHeader = forwardRef<HTMLDivElement, SeparatorHeaderProps>(
  ({ title, hintText, ...rest }, ref) => (
    <Box ref={ref} mt="s200" w="100%" p="s200" textAlign="center" {...rest}>
      <Flex alignItems="center">
        <Flex height="0px" flex={1} borderTop="0.5px solid black" />
        <HeaderS marginX="s100">{title}</HeaderS>
        <Flex height="0px" flex={1} borderTop="0.5px solid black" />
      </Flex>
      {isNotNullNorUndefined(hintText) && <BodyS mt="s200">{hintText}</BodyS>}
    </Box>
  ),
);
