import { generatePath } from "react-router-dom";
import { createMachine, assign, send } from "xstate";

import { PageName } from "analytics/events";
import { routes } from "config/routes";
import { CHECK_SESSION_TIMEOUT_MS } from "flows/Inventory/shared/utils/checkTimeout";
import { XstateRouteSyncMeta } from "shared/types/xstate";
import { isNotNullNorUndefined } from "utils/tsHelpers";
import { wrongEventErrorHandlerFactory } from "utils/xstate";

import {
  FreshCheckMachineContext,
  FreshCheckMachineEvents,
  FreshCheckMachineServices,
} from "./types";

export const contextInitialState: FreshCheckMachineContext = {
  sku: null,
  checkId: null,
  priority: 0,
  shelfNumber: null,
  shelfLetter: null,
  expectedQuantity: 0,
  expiredQuantity: 0,
  damagedQuantity: 0,
  remainingStock: 0,
  tooGoodToGoQuantity: 0,
  origin: PageName.ACTIVITIES_PAGE,
};

export const FRESH_CHECK_MACHINE_NAME = "freshCheck";

export const freshCheckMachine = createMachine(
  {
    id: FRESH_CHECK_MACHINE_NAME,
    initial: "idle",
    context: contextInitialState,
    predictableActionArguments: true,
    tsTypes: {} as import("./machine.typegen").Typegen0,
    schema: {
      context: {} as FreshCheckMachineContext,
      events: {} as FreshCheckMachineEvents,
      services: {} as FreshCheckMachineServices,
    },
    on: {
      "*": { actions: "wrongEventErrorHandler" },
      SESSION_EXPIRED: {
        target: "sessionExpired",
      },
    },
    entry: send({ type: "SESSION_EXPIRED" }, { delay: CHECK_SESSION_TIMEOUT_MS }),
    states: {
      idle: {
        on: {
          START: {
            actions: "sendTrackFreshCheckStarted",
            target: "declareStockDetails",
          },
        },
      },
      declareStockDetails: {
        on: {
          DECLARE_STOCK_DETAILS: {
            actions: "setStockDetails",
            target: "performCheckUpdate",
          },
        },
        meta: {
          routeSync: {
            generatePath: (ctx: FreshCheckMachineContext) => {
              return generatePath(routes.inventory.freshChecks.quantity, {
                checkId: ctx.checkId!,
              });
            },
          } as XstateRouteSyncMeta,
        },
      },
      performCheckUpdate: {
        initial: "loading",
        states: {
          loading: {
            invoke: {
              id: "performCheckUpdate",
              src: "performCheckUpdate",
              onDone: [
                {
                  cond: (ctx) => ctx.origin === PageName.ACTIVITIES_PAGE,
                  actions: [
                    "sendTrackFreshCheckFinished",
                    "taskCompletedToast",
                    "redirectToOrigin",
                  ],
                },
                {
                  actions: "sendTrackFreshCheckFinished",
                  target: "finished",
                },
              ],
              onError: [
                {
                  cond: (_, event) =>
                    (event.data?.toString() ?? "").includes("Error: taskAlreadyCompleted"),
                  actions: ["taskAlreadyCompletedToast", "sendTrackOnError", "redirectToOrigin"],
                },
                {
                  actions: ["errorToast", "sendTrackOnError"],
                  target: "#freshCheck.declareStockDetails",
                },
              ],
            },
          },
          finished: {
            invoke: {
              id: "fetchNextCheckAndStartCheck",
              src: "fetchNextCheckAndStartCheck",
              onDone: [
                {
                  cond: (_, { data }) => data?.status === "noMoreCheck",
                  actions: ["taskCompletedToast", "redirectToOrigin"],
                },
                {
                  cond: (_, { data }) => data?.status === "unknownError",
                  actions: "redirectToOrigin",
                },
                {
                  cond: (_, { data }) => data?.status === "checkAlreadyTaken",
                  target: "finished",
                },
                {
                  cond: (_, { data }) => isNotNullNorUndefined(data?.check),
                  actions: "redirectToCheck",
                },
              ],
              onError: {
                actions: "redirectToOrigin",
              },
            },
          },
        },
      },
      sessionExpired: {
        entry: ["redirectToOrigin", "checkSessionExpiredToast"],
      },
    },
  },
  {
    actions: {
      setStockDetails: assign(
        (
          _,
          {
            damagedQuantity,
            expiredQuantity,
            remainingStock,
            expectedQuantity,
            tooGoodToGoQuantity,
          },
        ) => ({
          damagedQuantity,
          expiredQuantity,
          remainingStock,
          expectedQuantity,
          tooGoodToGoQuantity,
        }),
      ),
      wrongEventErrorHandler: wrongEventErrorHandlerFactory(FRESH_CHECK_MACHINE_NAME),
    },
  },
);
