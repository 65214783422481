import { useErrorToastWithAudio } from "shared/hooks/useErrorToastWithAudio";

export function useCheckAlreadyCompletedToast() {
  const { showToastAndPlayError } = useErrorToastWithAudio({
    errorToastId: "task_already_completed_toast",
    title: "flows.inventory.hooks.use-check-already-completed-toast.title",
  });

  return { showCheckAlreadyCompletedToast: showToastAndPlayError };
}
