import create from "zustand";
import { persist } from "zustand/middleware";

export type EmployeeStore = {
  badgeNo?: string;
  roles?: string[];
  firstName: string;
  lastName: string;
  isIdentified: boolean;
  autoScan: boolean;
  persistDuringUpdatePageRefresh: boolean;
  isSetForLogout: boolean;
};

type EmployeeStoreWithMethods = EmployeeStore & {
  setEmployee(employeeState: Partial<EmployeeStore>): void;
  reset(): void;
};

const initialState: EmployeeStore = {
  isIdentified: false,
  badgeNo: undefined,
  roles: undefined,
  firstName: "",
  lastName: "",
  autoScan: true,
  persistDuringUpdatePageRefresh: false,
  isSetForLogout: false,
};

export const useEmployeeStore = create<EmployeeStoreWithMethods>()(
  persist(
    (set) => ({
      ...initialState,
      setEmployee(employeeState) {
        set({ ...employeeState, isIdentified: true });
      },
      reset() {
        set(initialState);
      },
    }),
    {
      name: "employee-storage",
      partialize: (state) =>
        state.persistDuringUpdatePageRefresh || process.env.NODE_ENV === "development" ? state : {},
      merge: (persistedState, currentState) => ({
        ...Object.assign(currentState, persistedState),
        isAutoUpdating: false,
      }),
    },
  ),
);
