import { useMemo } from "react";

import { useSelector } from "@xstate/react";
import { useIntl } from "react-intl";

import { useAppLanguageStore } from "core/stores/useAppLanguageStore";
import { useInboundUIStore } from "flows/Inbound/stores/useInboundUIStore";
import { useProductSearchService } from "shared/hooks/useProductSearchService";
import { IntlMessageId } from "shared/types/lang";
import { LetterRange } from "ui/LetterRangeSelector/LetterRangeSelector";
import { isNullOrUndefined } from "utils/tsHelpers";

import { TranslatedCategory } from "../../../shared/models/category/types";
import { TranslatedSubcategory } from "../models/subcategory/types";

const ALPHABET = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

function nextLetter(letter: string) {
  return ALPHABET[ALPHABET.indexOf(letter) + 1];
}

function normalizeCategoryNameForPhraseKey(name: string) {
  return name
    .replaceAll(/[^a-zA-Z\s]/g, "")
    .toLowerCase()
    .replaceAll(/\s/g, "-");
}

export function useTranslatedNonScannableCategories(): TranslatedCategory[] | null {
  const productSearchService = useProductSearchService();
  const categories = useSelector(
    productSearchService,
    (state) => state.context.nonScannableCategories,
  );
  const intl = useIntl();

  return useMemo(() => {
    if (isNullOrUndefined(categories)) {
      return null;
    }

    return categories?.map((category) => ({
      ...category,
      subcategories: category.subcategories.map((subcategory) => {
        return {
          ...subcategory,
          translatedName: intl.formatMessage({
            id: `flows.inbound.no-ean-search.subcategories.${normalizeCategoryNameForPhraseKey(
              category.name,
            )}.${normalizeCategoryNameForPhraseKey(subcategory.name)}` as IntlMessageId,
            defaultMessage: subcategory.name,
          }),
        };
      }),
    }));
  }, [categories, intl]);
}

export function useFilteredSortedGroupedAndTranslatedSubcategories(
  letterRangeTemplates: Omit<LetterRange, "disabled">[],
) {
  const appLanguage = useAppLanguageStore((state) => state.appLanguage);
  const selectedCategoryName = useInboundUIStore((state) => state.selectedCategoryName);
  const translatedCategories = useTranslatedNonScannableCategories();

  return useMemo(() => {
    if (isNullOrUndefined(translatedCategories) || isNullOrUndefined(selectedCategoryName)) {
      return null;
    }
    const selectedCategory = translatedCategories.find((category) => {
      return category.name === selectedCategoryName;
    });

    const filteredSubcategories = (selectedCategory?.subcategories || []).filter(
      (subcat) => subcat.productsCount > 0,
    );

    const { compare } = new Intl.Collator(appLanguage);

    const filteredAndSortedSubcategories = filteredSubcategories.sort((s1, s2) =>
      compare(s1.translatedName, s2.translatedName),
    );

    return letterRangeTemplates.reduce<Record<string, TranslatedSubcategory[]>>(
      (subCatGroups, letterRange) => {
        subCatGroups[letterRange.id] = filteredAndSortedSubcategories.filter(
          (s) =>
            compare(letterRange.from, s.translatedName) === -1 &&
            (isNullOrUndefined(nextLetter(letterRange.to)) ||
              compare(nextLetter(letterRange.to), s.translatedName) === 1),
        );
        return subCatGroups;
      },
      {},
    );
  }, [letterRangeTemplates, selectedCategoryName, translatedCategories, appLanguage]);
}
