import { useCallback } from "react";

import { useCustomToast } from "shared/hooks/useCustomToast";

const DURATION_MS = 2000;

export function useTaskCompletedToast() {
  const { showToastUI } = useCustomToast();

  const showTaskCompletedToast = useCallback(() => {
    showToastUI({
      variant: "completed",
      title: "hooks.use-task-completed-toast.title",
      duration: DURATION_MS,
    });
  }, [showToastUI]);

  return showTaskCompletedToast;
}
