import { Auth0Provider } from "@auth0/auth0-react";
import { ChakraProvider } from "@chakra-ui/react";
import { AnalyticsBrowser } from "@segment/analytics-next";
import { IntlProvider } from "react-intl";

import { config } from "config";
import { useLocalizedMessages } from "core/hooks/useLocalizedMessages";
import { AppEffectsProvider } from "core/providers/AppEffectsProvider";
import { CustomApolloProvider } from "core/providers/CustomApolloProvider";
import { EppoFeatureFlagsProvider } from "core/providers/CustomEppoProvider";
import { useAppLanguageStore } from "core/stores/useAppLanguageStore";
import { theme } from "theme";

import { AppRouter } from "./AppRouter";

import "fonts/fonts.css";
import "global.css";

import "../../utils/datadog";

export const analytics = AnalyticsBrowser.load({
  writeKey: config.environment.SEGMENT_WRITE_KEY,
});

function App() {
  const messages = useLocalizedMessages();
  const appLanguage = useAppLanguageStore((state) => state.appLanguage);

  return (
    <Auth0Provider
      domain={config.environment.AUTH0_DOMAIN}
      clientId={config.environment.AUTH0_CLIENT_ID}
      audience={config.environment.AUTH0_AUDIENCE}
      redirectUri={window.location.origin}
      useRefreshTokens
      cacheLocation="localstorage"
    >
      <ChakraProvider theme={theme}>
        <EppoFeatureFlagsProvider>
          <CustomApolloProvider>
            {/* @ts-ignore */}
            <IntlProvider locale={appLanguage} messages={messages}>
              <AppEffectsProvider>
                <AppRouter />
              </AppEffectsProvider>
            </IntlProvider>
          </CustomApolloProvider>
        </EppoFeatureFlagsProvider>
      </ChakraProvider>
    </Auth0Provider>
  );
}

export default App;
