import create from "zustand";

import { SortOrder } from "utils/item";

import { GetPrivateRestockingListQuery } from "../queries/privateRestockingList/privateRestockingList.generated";

export type AppDisplayMode = "browser" | "standalone" | "fullscreen";

export type RestockingServiceStore = {
  privateRestockingListData: GetPrivateRestockingListQuery | null | undefined;
  restockingItemsSortOrder: SortOrder;
  publicListSortState: SortOrder;
  isLoadingPrivateRestockingList: boolean;
  lastRefreshedPrivateListAt: Date | null;
  itemInRestockingProcess: string | null;
  totalRestockingItemCount: number;
  isNavigatingToStockCorrection: boolean;
  productName: string | null;
  selectedItemsBeingRemoved: string[];
};

type RestockingServiceStoreWithMethods = RestockingServiceStore & {
  setPrivateRestockingListData(
    privateRestockingList: GetPrivateRestockingListQuery | null | undefined,
  ): void;
  toggleRestockingItemsSortOrder(): void;
  togglePublicListSortState(): void;
  setIsLoadingPrivateRestockingList(v: boolean): void;
  setItemInRestockingProcess(sku: string | null): void;
  getCurrentPrivateRestockingList():
    | GetPrivateRestockingListQuery["getPrivateRestockingList"]
    | null;
  getShelfNumber(sku: string): string;
  setUseRestockingServiceStore(restockingServiceStore: Partial<RestockingServiceStore>): void;
  updateSelectedItemsForRemoval(skus: string[]): void;
};

const initialState: RestockingServiceStore = {
  privateRestockingListData: undefined,
  restockingItemsSortOrder: "asc",
  publicListSortState: "asc",
  isLoadingPrivateRestockingList: false,
  lastRefreshedPrivateListAt: null,
  itemInRestockingProcess: null,
  totalRestockingItemCount: 0,
  isNavigatingToStockCorrection: false,
  productName: null,
  selectedItemsBeingRemoved: [],
};

export const useRestockingServiceStore = create<RestockingServiceStoreWithMethods>()(
  (set, get) => ({
    ...initialState,
    setPrivateRestockingListData(privateRestockingListData) {
      set({
        privateRestockingListData,
        lastRefreshedPrivateListAt: new Date(),
        totalRestockingItemCount:
          privateRestockingListData?.getPrivateRestockingList?.restockingItems.length ?? 0,
      });
    },
    setUseRestockingServiceStore(restockingServiceStore) {
      set(restockingServiceStore);
    },
    toggleRestockingItemsSortOrder() {
      set({ restockingItemsSortOrder: get().restockingItemsSortOrder === "asc" ? "desc" : "asc" });
    },
    togglePublicListSortState() {
      set({ publicListSortState: get().publicListSortState === "asc" ? "desc" : "asc" });
    },
    setIsLoadingPrivateRestockingList(v: boolean) {
      set({ isLoadingPrivateRestockingList: v });
    },
    setItemInRestockingProcess(sku: string | null) {
      set({ itemInRestockingProcess: sku });
    },
    getCurrentPrivateRestockingList() {
      return get().privateRestockingListData?.getPrivateRestockingList || null;
    },
    getShelfNumber(sku: string) {
      return (
        get().privateRestockingListData?.getPrivateRestockingList?.restockingItems.find(
          (item) => sku === item.sku,
        )?.product?.inventoryEntry?.shelfNumber ?? ""
      );
    },
    updateSelectedItemsForRemoval(skus: string[]) {
      set({ selectedItemsBeingRemoved: skus });
    },
  }),
);
