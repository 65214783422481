import { datadogLogs } from "@datadog/browser-logs";
import { datadogRum } from "@datadog/browser-rum";

import { config } from "config";

const env =
  config.environment.ENVIRONMENT === "production" ? "prod" : config.environment.ENVIRONMENT;

datadogRum.init({
  applicationId: config.environment.DATADOG_APPLICATION_ID,
  clientToken: config.environment.DATADOG_CLIENT_TOKEN,
  site: "datadoghq.eu",
  service: "hub-staff-app",
  env,
  version: import.meta.env.EXP_CI_GIT_TAG || "", // Set in github action
  sampleRate: 100,
  replaySampleRate: 20,
  trackInteractions: true,
  defaultPrivacyLevel: "mask-user-input",
  allowedTracingOrigins: ["https://api.staging.goflink.com", "https://api.goflink.com"],
});

datadogLogs.init({
  clientToken: config.environment.DATADOG_CLIENT_TOKEN,
  site: "datadoghq.eu",
  service: "hub-staff-app",
  env,
  version: import.meta.env.EXP_CI_GIT_TAG || "", // Set in github action
  sampleRate: 100,
  forwardErrorsToLogs: false,
});

if (!["localhost", "127.0.0.1"].includes(window.location.hostname)) {
  datadogRum.startSessionReplayRecording();
}

export const reportErrorsToDDAndThrow = async <T>(
  message: string,
  func: () => Promise<T>,
  options?: object,
): Promise<T> => {
  try {
    return await func();
  } catch (e) {
    const newError = new Error(`Error while ${message}: ${e}`);
    datadogRum.addError(newError, options);
    throw newError;
  }
};
