import { useApolloClient } from "@apollo/client";
import { datadogLogs } from "@datadog/browser-logs";

import {
  EndPickingDocument,
  EndPickingMutation,
  EndPickingMutationVariables,
} from "flows/Picking/queries/order/order.generated";
import { useErrorToastWithAudio } from "shared/hooks/useErrorToastWithAudio";

export type StartPickingResult = string | null;
export type EndPickingResult = string | null;

export function usePickingServiceImplems<Context, Events>() {
  const client = useApolloClient();
  const { showToastAndPlayError } = useErrorToastWithAudio({
    errorToastId: "end_picking_error_toast",
  });

  const endPicking = async (
    _: Context,
    __: Events,
    args: {
      data: {
        orderNumber: string;
        missingItems: Array<{ comment?: string; sku: string; quantity: number }>;
        containersIds: string[];
        shelvesIds: string[];
      };
    },
  ): Promise<EndPickingResult> => {
    if (!args.data.orderNumber) throw new Error("Error: endPicking : Missing orderNumber");
    try {
      datadogLogs.logger.info("endPicking called with following args", args.data);
      const { data } = await client.mutate<EndPickingMutation, EndPickingMutationVariables>({
        mutation: EndPickingDocument,
        variables: args.data,
      });
      if (data?.endPicking.message) {
        return data.endPicking.message;
      }
      return null;
    } catch (error) {
      showToastAndPlayError({
        title: "components.picking.end-picking-button.error-toast-retry-end-picking",
      });
      throw new Error("Error: endPicking");
    }
  };

  return {
    endPicking,
  };
}
