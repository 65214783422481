import { useEffect, useRef } from "react";

import { datadogLogs } from "@datadog/browser-logs";
import { useRegisterSW } from "virtual:pwa-register/react";

import { AppDisplayMode, useAppPWAStateStore } from "core/stores/useAppPWAStateStore";
import { isNotNullNorUndefined } from "utils/tsHelpers";

function getCurrentDisplayMode() {
  let displayMode: AppDisplayMode = "browser";
  if (window.matchMedia("(display-mode: standalone)").matches) {
    displayMode = "standalone";
  }
  if (window.matchMedia("(display-mode: fullscreen)").matches) {
    displayMode = "fullscreen";
  }
  return displayMode;
}

// check for updates every minute.
const intervalMS = 60 * 1000;

// Register a periodic sync check every minute
const registerPeriodicSync = (
  swScriptUrl: string,
  registration: ServiceWorkerRegistration,
): NodeJS.Timeout => {
  return setInterval(async () => {
    if (navigator.onLine) {
      const response = await fetch(swScriptUrl, {
        cache: "no-store",
        headers: { "cache-control": "no-cache" },
      });
      if (response?.status === 200) await registration.update();
    }
  }, intervalMS);
};

// Handle Service Worker state changes and registration
const handleServiceWorkerRegistration = (
  swScriptUrl: string,
  registration: ServiceWorkerRegistration | undefined,
  setNeedRefresh: (need: boolean) => void,
  checkAppUpdateIntervalRef: React.MutableRefObject<NodeJS.Timeout | null>,
) => {
  if (!registration) return;

  const activateSync = () => {
    checkAppUpdateIntervalRef.current = registerPeriodicSync(swScriptUrl, registration);
  };

  if (registration.active?.state === "activated") {
    activateSync();
  } else if (registration.installing) {
    registration.installing.addEventListener("statechange", (e) => {
      const sw = e.target as ServiceWorker;
      if (sw.state === "activated") {
        activateSync();
      }
    });
  } else if (registration.waiting) {
    // Skip the waiting phase and activate the new service worker
    registration.waiting.postMessage({ action: "skipWaiting" });
    // Indicate that an update is available and a refresh is needed
    setNeedRefresh(true);
  }
  // Listen for controller change to reload the page
  navigator.serviceWorker.addEventListener("controllerchange", () => {
    window.location.reload();
  });
};

export function useAppPWAStateEffects() {
  const checkAppUpdateIntervalRef = useRef<NodeJS.Timeout | null>(null);
  const setAppPWAState = useAppPWAStateStore((state) => state.setAppPWAState);
  const {
    needRefresh: [needRefresh, setNeedRefresh],
    updateServiceWorker,
  } = useRegisterSW({
    onRegisteredSW: (swScriptUrl, registration) => {
      handleServiceWorkerRegistration(
        swScriptUrl,
        registration,
        setNeedRefresh,
        checkAppUpdateIntervalRef,
      );
    },
    onRegisterError(error) {
      datadogLogs.logger.error("Error while registering service worker", { error });
    },
    onNeedRefresh() {
      setNeedRefresh(true);
    },
  });

  useEffect(() => {
    const currentDisplayMode = getCurrentDisplayMode();

    setAppPWAState({
      isLoading: false,
      displayMode: currentDisplayMode,
    });

    function handleDisplayModeChange(event: MediaQueryListEvent) {
      if (!event.matches) {
        const newDisplayMode = getCurrentDisplayMode();
        setAppPWAState({ displayMode: newDisplayMode });
      }
    }

    function handleBeforeInstallPromt(event: Event) {
      event.preventDefault();
    }
    const browserMatchMedia = window.matchMedia("(display-mode: browser)");

    // Listening to this event makes sure that the app ui updates when
    // the user switched from browser to pwa
    browserMatchMedia.addEventListener("change", handleDisplayModeChange);
    // Prevent the app to display the native android chrome bottom sheet
    // that prompts the user to install the app
    window.addEventListener("beforeinstallprompt", handleBeforeInstallPromt);
    const intervalId = checkAppUpdateIntervalRef.current;
    return () => {
      // Make sure the interval is cleared when the view is unmounted
      if (isNotNullNorUndefined(intervalId)) {
        clearInterval(intervalId);
      }
      browserMatchMedia.removeEventListener("change", handleDisplayModeChange);
      window.removeEventListener("beforeinstallprompt", handleBeforeInstallPromt);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setAppPWAState({
      appUpdateAvailable: needRefresh,
      updateServiceWorker: () => {
        // Call the updateServiceWorker and force reload when it resolves
        if (updateServiceWorker) {
          updateServiceWorker(true);
        }
      },
    });
  }, [needRefresh, updateServiceWorker, setAppPWAState]);
}
