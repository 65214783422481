import { useCallback, useState } from "react";

import shallow from "zustand/shallow";

import { useInboundStore } from "flows/Inbound/stores/inboundStore/useInboundStore";
import { useEditSharedListName } from "flows/Inbound/stores/useInboundPreDropService";

export const useHandleSharedListNameUpdate = () => {
  const { editSharedListName, isUpdatingName } = useEditSharedListName();
  const { sharedListId, sharedListName, setInboundUIState } = useInboundStore(
    (state) => ({
      sharedListName: state.sharedListName,
      sharedListId: state.sharedListId,
      setInboundUIState: state.setInboundUIState,
    }),
    shallow,
  );
  const [newListName, setNewListName] = useState(sharedListName || "");
  const handleUpdateSharedListName = useCallback(() => {
    if (isUpdatingName) return;

    const trimmedNewListName = newListName.trim();
    if (
      !trimmedNewListName ||
      sharedListName === trimmedNewListName ||
      trimmedNewListName.length > 100
    )
      return;

    editSharedListName(sharedListId!, trimmedNewListName);
    setInboundUIState({ isSharedListNameEditActive: false });
  }, [
    editSharedListName,
    isUpdatingName,
    newListName,
    setInboundUIState,
    sharedListId,
    sharedListName,
  ]);

  return {
    newListName,
    setNewListName,
    handleUpdateSharedListName,
  };
};
