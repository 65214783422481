import { State } from "xstate";

import { sortByShelf } from "utils/item";

import { deserializeListVerificationCheck } from "../../models/listVerificationCheck/deserializer";
import { ListVerificationCheck } from "../../models/listVerificationCheck/types";
import { InboundMachineContext } from "./types";
import { sumStockUpdatePlanTotal, extractInboundPlan } from "./utils";

export const selectInboundUnitDataBySku =
  (sku: string) => (state: State<InboundMachineContext>) => {
    return state.context.inboundUnitsMap[sku];
  };

export const selectInboundUnitStockUpdateStateBySku =
  (sku: string) => (state: State<InboundMachineContext>) => {
    return state.context.inboundUnitsStockUpdateState[sku];
  };

export const selectInboundUnitStockDataBySku =
  (sku: string) => (state: State<InboundMachineContext>) => {
    const { stockUpdatePlan, stockUpdated } = state.context.inboundUnitsStockUpdateState[sku];
    const displayState = state.context.inboundUnitsDisplayStates[sku];
    const stockOnShelf = state.context.onShelfStockLevels[sku] ?? 0;
    const stockUpdatePlanTotal = sumStockUpdatePlanTotal(stockUpdatePlan);
    const remainder = stockUpdatePlanTotal % displayState.unitSizeForDisplay;
    const newStockUpdatePlanTotal = stockUpdatePlanTotal - remainder;
    const newDisplayQuantity = newStockUpdatePlanTotal / displayState.unitSizeForDisplay;

    return {
      stockUpdated,
      stockUpdatePlanTotal: newStockUpdatePlanTotal,
      displayQuantity: newDisplayQuantity,
      unitSizeForDisplay: displayState.unitSizeForDisplay,
      inboundQuantity: extractInboundPlan(stockUpdatePlan)?.quantityDelta ?? 0,
      stockOnShelf,
    };
  };

export const selectDroppingListInboundUnitsSkusSortedByShelfFromContext = (
  context: InboundMachineContext,
): string[] => {
  const { droppingListShelfSortOrder, inboundUnitsMap } = context;
  return Object.values(inboundUnitsMap)
    .map((inboundUnit) => ({ ...inboundUnit, name: inboundUnit.productName }))
    .sort((a, b) => sortByShelf(a, b, droppingListShelfSortOrder))
    .map((sortedInboundUnit) => sortedInboundUnit.productSku);
};

export const selectDroppingListActiveInboundUnitFromContext = (context: InboundMachineContext) => {
  const itemsSortedByShelf = selectDroppingListInboundUnitsSkusSortedByShelfFromContext(context);
  const { inboundUnitsStockUpdateState, inboundUnitsMap } = context;
  const activeInboundUnitSku = itemsSortedByShelf.find((sku) => {
    const stockUpdateState = inboundUnitsStockUpdateState[sku];
    return !!stockUpdateState && !stockUpdateState.stockUpdated;
  });
  return activeInboundUnitSku ? inboundUnitsMap[activeInboundUnitSku] : undefined;
};

export const selectActiveListVerificationChecksFromContext = (
  context: InboundMachineContext,
): ListVerificationCheck[] => {
  const sortedSKUS = context.preDroppingListInboundUnitsSkusSortedByAddedDate;
  return context.listVerificationChecks
    .filter((check) => check.isActive)
    .sort((check1, check2) => {
      if (check1.isCompleted) {
        return check2.isCompleted ? 0 : -1;
      }
      if (check2.isCompleted) {
        return 1;
      }
      return sortedSKUS.indexOf(check1.sku) - sortedSKUS.indexOf(check2.sku);
    })
    .map(deserializeListVerificationCheck);
};
