/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
import { useState, useEffect, useCallback } from "react";

import {
  UserTrackingServiceApi,
  V1ExperimentLookupRequest,
  V1ExperimentLookupResponse,
  V1Platform,
} from "@flink/experiment";
import shallow from "zustand/shallow";

import { config } from "config";
import { useFeatureFlagOverridesStore } from "core/stores/useFeatureFlagOverridesStore";
import { EppoFeatureFlags } from "core/types/flags";
import { isNullOrUndefined } from "utils/tsHelpers";

import { useHubSlug } from "./useHubSlug";

export type AllFeatureFlag = Record<string, string | undefined> | null;

const transformData = (flags: V1ExperimentLookupResponse["decisions"]): AllFeatureFlag =>
  flags
    ? Object.fromEntries(Object.entries(flags).map(([key, { variation }]) => [key, variation]))
    : null;

export const fetchAllFeatureFlagDecisions = async (hubSlug: string) => {
  const userTrackingServiceApi = new UserTrackingServiceApi({}, config.environment.EPPO_URL);
  const featureFlags = Object.values(EppoFeatureFlags);
  const experimentRequest: V1ExperimentLookupRequest = {
    userId: "not-used",
    meta: {
      attributes: {
        hub_slug: hubSlug,
      },
    },
    flags: featureFlags,
    platform: V1Platform.EppoOps,
  };
  const experimentResponse = await userTrackingServiceApi.userTrackingServiceExperimentLookup(
    experimentRequest,
  );
  return transformData(experimentResponse.data.decisions);
};

export const useEppoAllFeatureFlags = () => {
  const hubSlug = useHubSlug();
  const [decisions, setDecisions] = useState<AllFeatureFlag>(null);
  const { localFeatureFlags, setLocalFeatureFlags } = useFeatureFlagOverridesStore(
    (state) => ({
      localFeatureFlags: state.localFeatureFlags,
      setLocalFeatureFlags: state.setLocalFeatureFlags,
    }),
    shallow,
  );
  const [isLoading, setIsLoading] = useState(false);

  const fetchAllFeatureFlags = useCallback(async () => {
    if (hubSlug) {
      setIsLoading(true);
      try {
        const responseDecisions = await fetchAllFeatureFlagDecisions(hubSlug);
        setDecisions(responseDecisions);
        // Storing fetched feature flags as local overrides if available
        if (!isNullOrUndefined(responseDecisions)) setLocalFeatureFlags(responseDecisions);
      } catch (error: any) {
        console.error("Error checking feature flags:", error.response.data);
        // If fetching fails, check if local flags are available
        if (localFeatureFlags && Object.keys(localFeatureFlags).length > 0) {
          setDecisions(localFeatureFlags);
        } else {
          setDecisions(null);
        }
      } finally {
        setIsLoading(false);
      }
    } else {
      setDecisions(null);
    }
  }, [hubSlug]);

  useEffect(() => {
    fetchAllFeatureFlags();
  }, [fetchAllFeatureFlags]);

  return { decisions, isLoading };
};
