import { GetProductsBySubcategoryV2Query } from "shared/queries/product/product.generated";
import { getShelfDetail } from "utils/item";

import { ProductBySubcategory, UnitType } from "./types";

function serializeProductInSubcategory(
  product: GetProductsBySubcategoryV2Query["getProductsBySubcategoryV2"]["products"][0],
): ProductBySubcategory {
  const { shelfNumber } = product.inventoryEntry;
  const shelfDetail = shelfNumber ? getShelfDetail(shelfNumber) : null;

  return {
    sku: product.sku,
    name: product.name,
    imageUrl: product.imageUrl ?? null,
    shelf: product.inventoryEntry.shelfNumber ?? null,
    shelfLetter: shelfDetail?.shelfLetter ?? null,
    shelfNumber: shelfDetail?.shelfNumber ?? null,
    countryOfOriginCode: product.countryOfOrigin.code?.toUpperCase() ?? null,
    isBio: product.bio ?? false,
    numberOfShelfFacings: product.numberOfShelfFacings ?? 0,
    isShelvedInHandlingUnits: !!product.isShelvedInHandlingUnits,
    units: product.units.map((unit) => ({
      id: unit.id,
      ean: unit.ean ?? "",
      quantity: unit.quantity,
      unitType: unit.type as UnitType,
    })),
  };
}

export function serializeProductsInSubcategory(
  data: GetProductsBySubcategoryV2Query,
): ProductBySubcategory[] {
  return data.getProductsBySubcategoryV2.products.map(serializeProductInSubcategory);
}
