import { ReactElement } from "react";

import { Center, Flex, FlexProps } from "@chakra-ui/react";

import { Button } from "ui/Button/Button";
import { CircleIcon } from "ui/CircleIcon/CircleIcon";
import { BodyM, HeaderS } from "ui/Typography/Typography";
import { isNotNullNorUndefined } from "utils/tsHelpers";

export type PagePlaceholderProps = {
  icon: ReactElement;
  title: string;
  subtitle?: React.ReactElement | string;
  ctaLabel?: string;
  onClickCta?: () => void;
} & FlexProps;

export function PagePlaceholder({
  icon,
  title,
  subtitle,
  ctaLabel,
  onClickCta,
  ...rest
}: PagePlaceholderProps) {
  return (
    <Center h="100%" w="100%" {...rest}>
      <Flex direction="column" w="100%" align="center">
        <HeaderS textAlign="center">{title}</HeaderS>
        {isNotNullNorUndefined(subtitle) && (
          <BodyM mt="s100" textAlign="center">
            {subtitle}
          </BodyM>
        )}
        <CircleIcon mt="s300" icon={icon} />
        {ctaLabel && (
          <Button mt="s300" onClick={onClickCta} flinkVariant="primary" size="lg">
            {ctaLabel}
          </Button>
        )}
      </Flex>
    </Center>
  );
}
