import { useCallback, useEffect, useMemo, useState } from "react";

import { Box, Button, Flex, useDisclosure } from "@chakra-ui/react";
import { useSelector } from "@xstate/react";
import { useIntl } from "react-intl";

import { PageName } from "analytics/events";
import { config } from "config";
import { EppoFeatureFlags } from "core/types/flags";
import { usePublicRestockingList } from "flows/Inventory/flows/RestockingList/services/restockingService/service";
import { Campaign } from "flows/Picking/components/Campaign";
import { RemoveFlyers } from "flows/Picking/components/RemoveFlyers";
import ScanJETReweBagModal from "flows/Picking/components/ScanJETReweBagModal";
import { Page } from "shared/components/Page";
import { ErrorTypeForAnalytics, useAnalytics } from "shared/hooks/useAnalytics";
import { useCountryCode } from "shared/hooks/useCountryCode";
import { useCustomToast } from "shared/hooks/useCustomToast";
import { useEppoFeatureFlagProvider } from "shared/hooks/useEppoFeatureFlag";
import { useErrorToastWithAudio } from "shared/hooks/useErrorToastWithAudio";
import { useScan } from "shared/hooks/useScan/useScan";
import { WarningRoundFilledIcon } from "ui/Icons/Icons";
import { SeparatorHeader } from "ui/SeparatorHeader/SeparatorHeader";
import { Toast } from "ui/Toast";
import { BodyM, TitleM } from "ui/Typography/Typography";
import { isNotNullNorUndefined } from "utils/tsHelpers";

import { EndPickingButton } from "../../components/EndPickingButton";
import { Gift } from "../../components/Gift";
import { GoToScanContainersButton } from "../../components/GoToScanContainersButton";
import { ItemNotFoundModal } from "../../components/ItemNotFoundModal";
import { PickingListHeader } from "../../components/PickingListHeader";
import { PickingListItem } from "../../components/PickingListItem/PickingListItem";
import { usePickingListScrollEffects } from "../../hooks/usePickingListScrollEffects";
import {
  useSelectItemsSortedByShelf,
  useSelectPickedOrder,
  useSelectCurrentItem as useSelectCurrentItemToPick,
} from "../../hooks/usePickingMachineSelectors";
import { usePickingService } from "../../hooks/usePickingService";
import { Item } from "../../models/item/types";

const BARCODE_ISSUE_REPORTED_TOAST_ID = "barcode_issue_reported_toast_id";
const ASK_FOR_RESTOCKING_ERROR_TOAST_ID = "ask_for_restocking_error_toast_for_sku";

export function PickingListPage() {
  const intl = useIntl();
  const { showToastUI } = useCustomToast();

  const { addItemToPublicRestockingList, restockingItems } = usePublicRestockingList();
  const [selectedItemsForRestocking, setSelectedItemsForRestocking] = useState<string[]>(
    restockingItems ? restockingItems.map((item) => item.sku) : [],
  );

  const { isFeatureEnabled: isWrongBarcodeDetailsFlagEnabled } = useEppoFeatureFlagProvider(
    EppoFeatureFlags.WRONG_BARCODE_DETAILS,
  );
  const { isFeatureEnabled: isCampaignProductFlagEnabled } = useEppoFeatureFlagProvider(
    EppoFeatureFlags.CAMPAIGN_PRODUCT,
  );
  const { isFeatureEnabled: isScanJetReweBagFlagEnabled } = useEppoFeatureFlagProvider(
    EppoFeatureFlags.SCAN_JET_REWE_BAG,
  );
  const { showToastAndPlayError, closeIfToastActive } = useErrorToastWithAudio({
    errorToastId: "scan_error_toast",
  });

  const { sendSegmentTrackEvent } = useAnalytics();

  const pickingService = usePickingService();

  // We have discovered a bug in the app that if sometimes OAs press native back button instead of
  // clicking the picking done button(labeled Next Order) and some orders stuck in picking state.
  // So we are adding this check to finish picking.
  const isEndPickingIdle = useSelector(pickingService, (state) => state.matches("endPicking.idle"));
  const isPickingFinished = useSelector(pickingService, (state) =>
    state.matches("picking.finished"),
  );

  if (isEndPickingIdle && isPickingFinished) {
    pickingService.send({ type: "FINISH_PICKING" });
  }

  const currentOrder = useSelectPickedOrder();
  const reseller = currentOrder?.reseller ?? "";
  const isExternalProvider = [
    "UBER-EATS",
    "UBER-EATS-CARREFOUR",
    "WOLT",
    "JUST-EAT",
    "JUST-EAT-REWE",
  ].includes(reseller);
  const isNewCustomer = reseller === "FLINK" && currentOrder?.isNewCustomer === true;

  const {
    itemsSortedByShelf,
    skippedItemsSortedByShelf,
    isPickingDone,
    isGiftPicked,
    isCampaignPicked,
    hasFlyersRemoved,
  } = useSelectItemsSortedByShelf();
  const isCampaignItemPicked = isCampaignProductFlagEnabled ? isCampaignPicked : true;

  const currentItemToPick = useSelectCurrentItemToPick();

  const currentItem = currentItemToPick?.item;

  const countryCode = useCountryCode();

  const {
    itemsRef,
    skippedItemsHeaderRef,
    skippedItemsRef,
    endPickingButtonRef,
    giftsHeaderRef,
    removeFlyersHeaderRef,
  } = usePickingListScrollEffects();

  const {
    isOpen: isItemNotFoundModalOpen,
    onOpen: onOpenItemNotFoundModal,
    onClose: onCloseItemNotFoundModal,
  } = useDisclosure();

  const {
    isOpen: isScanJETReweBagModalOpen,
    onOpen: onOpenScanJETReweBagModal,
    onClose: onCloseScanJETReweBagModal,
  } = useDisclosure();

  const handleOnCloseScanJETReweBagModal = useCallback(
    (method: string) => {
      onCloseScanJETReweBagModal();
      if (currentOrder?.id && currentOrder?.number) {
        sendSegmentTrackEvent("orderProgressed", {
          action: "bag_picked",
          product_sku: null,
          ean: null,
          order_id: currentOrder.id,
          order_number: currentOrder.number,
          quantity: 1,
          method,
          reason: null,
        });
      }
    },
    [currentOrder, onCloseScanJETReweBagModal, sendSegmentTrackEvent],
  );

  useEffect(() => {
    if (isScanJetReweBagFlagEnabled && reseller === "JUST-EAT-REWE") {
      onOpenScanJETReweBagModal();
    }
  }, [isScanJetReweBagFlagEnabled, onOpenScanJETReweBagModal, reseller]);

  const incrementCurrentItemQuantity = useCallback(
    ({ reportedBarcodeIssue = false, pickedByScan = false }) => {
      if (!currentItemToPick) {
        return;
      }
      pickingService.send({
        type: "INCREMENT_ITEM_QUANTITY",
        itemId: currentItemToPick.item.id,
        isSkippedItem: currentItemToPick.isSkipped,
        reportedBarcodeIssue,
        pickedByScan,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentItemToPick],
  );

  const decrementCurrentItemQuantity = useCallback(
    (itemId: string) => {
      pickingService.send({
        type: "DECREMENT_ITEM_QUANTITY",
        itemId,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentItemToPick],
  );

  const handleUpdateItemQuantity = useCallback(
    (itemId: string, decrement: boolean = false) => {
      if (decrement) {
        decrementCurrentItemQuantity(itemId);
      } else if (itemId === currentItemToPick?.item.id) {
        incrementCurrentItemQuantity({});
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentItemToPick],
  );

  const skipCurrentItem = useCallback(
    (callback?: () => void) => () => {
      if (!currentItemToPick) {
        return;
      }
      pickingService.send({
        type: "SKIP_ITEM",
        itemId: currentItemToPick.item.id,
        isSkippedItem: currentItemToPick?.isSkipped,
      });
      if (callback) {
        callback();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentItemToPick],
  );

  const onConfirmBarcodeIssue = useCallback(
    () => {
      incrementCurrentItemQuantity({ reportedBarcodeIssue: true });
      showToastUI({
        id: BARCODE_ISSUE_REPORTED_TOAST_ID,
        duration: 2000,
        render: ({ onClose }) => (
          <Toast
            icon={<WarningRoundFilledIcon color="white" />}
            title={intl.formatMessage({
              id: "pages.picking.picking-list.barcode_issue_reported_toast.title",
            })}
            bgColor="nightBlueFlink.500"
            onClose={onClose}
          />
        ),
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentItemToPick],
  );

  const onScan = useCallback(
    (barcode: string) => {
      if (isScanJetReweBagFlagEnabled && isScanJETReweBagModalOpen) return;
      if (!currentItemToPick || currentItemToPick.item.eans?.length === 0) {
        return;
      }
      if (!currentItemToPick.item.eans?.find((ean) => ean === barcode)) {
        showToastAndPlayError({
          duration: 10000,
          render: () => (
            <Flex
              alignItems="center"
              borderRadius="sm"
              h="5.5rem"
              px="s200"
              py="s100"
              gap="s200"
              bgColor="red.500"
            >
              <WarningRoundFilledIcon color="white" boxSize="1.5rem" />
              <Flex flexDirection="column">
                <TitleM color="white">
                  {intl.formatMessage({ id: "pages.picking.picking-list.wrong-barcode-toast" })}
                </TitleM>
                <BodyM color="white">
                  {intl.formatMessage({
                    id: "pages.picking.picking-list.wrong-barcode-toast.body",
                  })}
                </BodyM>
              </Flex>
              <Box width="1px" height="1.7rem" bg="white" />
              <Button
                colorScheme="red.500"
                onClick={() => {
                  closeIfToastActive();
                  onConfirmBarcodeIssue();
                }}
              >
                <TitleM color="white">
                  {intl.formatMessage({
                    id: "pages.picking.picking-list.wrong-barcode-toast.cta",
                  })}
                </TitleM>
              </Button>
            </Flex>
          ),
        });
        sendSegmentTrackEvent("errorShown", {
          screen_name: PageName.PICKING_PAGE,
          component_value: ErrorTypeForAnalytics.SCAN_PRODUCT_FAILED,
          component_name: barcode,
          component_content: currentItem?.sku ?? undefined,
          component_variant: currentOrder?.number ?? undefined,
        });
        return;
      }
      closeIfToastActive();
      // HERE
      incrementCurrentItemQuantity({ pickedByScan: true });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      currentItemToPick,
      showToastAndPlayError,
      isWrongBarcodeDetailsFlagEnabled,
      currentOrder?.number,
    ],
  );

  const onResetItemClick = useCallback((itemId: string) => {
    pickingService.send({
      type: "RESET_ITEM",
      itemId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleBarCodeDebugClick = useCallback(() => {
    if (config.environment.ENVIRONMENT !== "production" && currentItemToPick?.item.eans?.[0]) {
      incrementCurrentItemQuantity({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentItem?.id, onScan]);

  const endPickingButton = useMemo(() => {
    if (!isPickingDone || !isGiftPicked || !isCampaignItemPicked || !hasFlyersRemoved) {
      return null;
    }
    const shouldSkipHandoverProcess =
      ["UBER-EATS", "UBER-EATS-CARREFOUR", "WOLT"].includes(currentOrder?.reseller ?? "") ||
      isNotNullNorUndefined(currentOrder?.externalDeliveryProvider) ||
      currentOrder?.isInStore === true ||
      currentOrder?.isClickAndCollect === true;

    if (shouldSkipHandoverProcess) {
      return <EndPickingButton />;
    }
    return <GoToScanContainersButton />;
  }, [
    isPickingDone,
    isGiftPicked,
    isCampaignItemPicked,
    hasFlyersRemoved,
    currentOrder?.reseller,
    currentOrder?.externalDeliveryProvider,
    currentOrder?.isInStore,
    currentOrder?.isClickAndCollect,
  ]);

  const handleAskForRestocking = async (item: Item) => {
    if (item.sku) {
      try {
        await addItemToPublicRestockingList(item.sku);
        if (!selectedItemsForRestocking.includes(item.sku)) {
          setSelectedItemsForRestocking([...selectedItemsForRestocking, item.sku]);
        }
      } catch (e: any) {
        showToastUI({
          id: `${ASK_FOR_RESTOCKING_ERROR_TOAST_ID}_${item.sku}`,
          title: "components.picking.restocking.ask-for-restocking-error-title",
        });
      }
      sendSegmentTrackEvent("click", {
        screen_name: PageName.PICKING_PAGE,
        component_name: "ask_for_restock",
        component_value: item.sku,
        component_content: item.id,
      });
    }
  };

  const renderItemsList = (isSkippedItemsList = false) => {
    return (isSkippedItemsList ? skippedItemsSortedByShelf : itemsSortedByShelf).map(
      (itemToPick, itemIndex) => (
        <PickingListItem
          role="listitem"
          key={itemToPick.item.id}
          listItem={itemToPick}
          itemIndex={itemIndex}
          isSkipped={isSkippedItemsList}
          countryCode={countryCode}
          isAskRestockingButtonDisabled={selectedItemsForRestocking.includes(
            itemToPick.item.sku ?? "",
          )}
          elementRef={(element) => {
            (isSkippedItemsList ? skippedItemsRef : itemsRef).current[itemIndex] = element;
          }}
          onBarcodeIssueClick={onConfirmBarcodeIssue}
          onBarcodeClick={handleBarCodeDebugClick}
          onSkipItemClick={skipCurrentItem()}
          onResetItemClick={onResetItemClick}
          onItemNotFoundClick={onOpenItemNotFoundModal}
          handleAskForRestocking={() => handleAskForRestocking(itemToPick.item)}
          handleUpdateItemQuantity={handleUpdateItemQuantity}
        />
      ),
    );
  };

  useScan({
    onScan,
  });

  return (
    <Page isFull pos="relative" isBgGrey>
      {isNotNullNorUndefined(currentItemToPick) && (
        <ItemNotFoundModal
          isOpen={isItemNotFoundModalOpen}
          onClose={onCloseItemNotFoundModal}
          availableQuantity={currentItemToPick.item.availableQuantity}
          onConfirmRefund={skipCurrentItem(onCloseItemNotFoundModal)}
          pickedQuantity={currentItemToPick.pickedQuantity}
          totalQuantity={currentItemToPick.item.quantity}
        />
      )}

      <ScanJETReweBagModal
        isOpen={isScanJETReweBagModalOpen}
        onClose={handleOnCloseScanJETReweBagModal}
      />
      <PickingListHeader />
      <Box w="100%" data-testid="list-items" role="list">
        {renderItemsList()}
      </Box>
      {skippedItemsSortedByShelf.length > 0 && (
        <>
          <SeparatorHeader
            title={intl.formatMessage({
              id: "pages.picking.picking-list.skipped-items-header-title",
            })}
            hintText={intl.formatMessage({
              id: "pages.picking.picking-list.skipped-items-header-hint-text",
            })}
            ref={skippedItemsHeaderRef}
          />
          <Box w="100%" data-testid="list-items-skipped" role="list">
            {renderItemsList(true)}
          </Box>
        </>
      )}
      {isNewCustomer && (
        <>
          <SeparatorHeader
            ref={giftsHeaderRef}
            title={intl.formatMessage({ id: "pages.picking.picking-list.gift-header-title" })}
          />
          <Box w="100%" data-testid="list-items-gift" role="list">
            <Gift />
          </Box>
          {isCampaignProductFlagEnabled && (
            <Box mt="s200" w="100%" data-testid="list-items-campaign" role="list">
              <Campaign />
            </Box>
          )}
        </>
      )}
      {isExternalProvider && (
        <>
          <SeparatorHeader
            ref={removeFlyersHeaderRef}
            title={intl.formatMessage({
              id: "pages.picking.picking-list.remove-flyers.header-title",
            })}
          />
          <Box w="100%" data-testid="list-items-remove-flyers" role="list">
            <RemoveFlyers />
          </Box>
        </>
      )}
      <Box ref={endPickingButtonRef} w="100%" py="s300" px="s200">
        {endPickingButton}
      </Box>
    </Page>
  );
}
