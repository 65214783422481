import dayjs from "dayjs";
import { IntlShape } from "react-intl/src/types";

const MAX_NAME_SIZE = 17;

export const formatEmployeeName = (firstName: string, lastName: string) => {
  const formattedFirstName =
    firstName.trim().length > MAX_NAME_SIZE
      ? `${firstName.trim().slice(0, MAX_NAME_SIZE - 3)}...`
      : firstName.trim();

  const formattedLastName = `${lastName.trim().toUpperCase()[0]}.`;

  return `${formattedFirstName} ${formattedLastName}`;
};

export const getShortOrderNumber = (orderNumber: string) =>
  `#${orderNumber.split("-").pop()?.toUpperCase()}`;

export const getDisplayedDate = (date: Date, separator = ".") => {
  const day = `0${date.getDate()}`.slice(-2);
  const month = `0${date.getMonth() + 1}`.slice(-2);
  const year = date.getFullYear();

  return `${day}${separator}${month}${separator}${year}`;
};

export const formatDuration = (durationInMs: number): string => {
  const seconds = Math.floor(durationInMs / 1000) % 60;
  const minutes = Math.floor(durationInMs / 60000);
  return [minutes, seconds].map((t) => t.toString().padStart(2, "0")).join(":");
};

export const formatWeight = (weight: number): string => `${+(weight / 1000).toFixed(2)} kg`;

export function formatProductString(skus: string[], quantity = 3) {
  return skus.map((sku: any) => `${sku}:${quantity}`).join(",");
}

export const getFormattedDate = (date: string | null, intl: IntlShape) => {
  if (!date) return intl.formatMessage({ id: "unknown-date" });

  const now = dayjs();
  const targetDate = dayjs(date);

  const diffDays = now.diff(targetDate, "day");

  if (diffDays === 0) return intl.formatMessage({ id: "today" });
  if (diffDays === 1) return intl.formatMessage({ id: "yesterday" });
  return intl.formatMessage({ id: "days-ago" }, { days: diffDays });
};
