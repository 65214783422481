import { useCallback, useEffect, useMemo, useState } from "react";

import { Box, Flex } from "@chakra-ui/react";
import { useSelector } from "@xstate/react";
import { FormattedMessage, useIntl } from "react-intl";

import { useAppLanguageStore } from "core/stores/useAppLanguageStore";
import { EndDroppingProcessButton } from "flows/Inbound/components/EndDroppingProcessButton";
import { useInboundUIStore } from "flows/Inbound/stores/useInboundUIStore";
import { countryNameFromCode } from "libs/countryNames";
import { useProductSearchService } from "shared/hooks/useProductSearchService";
import {
  ProductSearchUnit,
  TranslatedProductSearchUnit,
} from "shared/models/productSearchUnit/types";
import { ProductSearchResultCard } from "ui/ProductSearchResultCard/ProductSearchResultCard";
import { BodyS, TitleM } from "ui/Typography/Typography";
import { isNullOrUndefined } from "utils/tsHelpers";

export function InboundUnitsSearchResultsList() {
  const intl = useIntl();

  const productSearchService = useProductSearchService();

  const searchedUnitType = useInboundUIStore((state) => state.searchedUnitType);

  const appLanguage = useAppLanguageStore((state) => state.appLanguage);

  const searchResults: TranslatedProductSearchUnit[] | null = useSelector(
    productSearchService,
    (state) => {
      const { textSearchResults } = state.context;
      if (!textSearchResults) {
        return null;
      }
      const translatedSearchResults: TranslatedProductSearchUnit[] = textSearchResults.results.map(
        (inboundUnit) => ({
          ...inboundUnit,
          countryOfOriginName: countryNameFromCode(appLanguage, inboundUnit.countryOfOriginCode),
        }),
      );
      if (textSearchResults.matchType === "ean") {
        return translatedSearchResults;
      }
      const unitsUniqueBySKUAndHandlingUnitSize: TranslatedProductSearchUnit[] = [];
      translatedSearchResults.forEach((result) => {
        if (
          !unitsUniqueBySKUAndHandlingUnitSize.find(
            (unit) => unit.productSku === result.productSku && unit.quantity === result.quantity,
          )
        ) {
          unitsUniqueBySKUAndHandlingUnitSize.push(result);
        }
      });
      return unitsUniqueBySKUAndHandlingUnitSize.filter(
        (unit) => unit.unitType === searchedUnitType,
      );
    },
  );

  const textSearchQuery = useSelector(productSearchService, (state) => {
    return state.context.textSearch;
  });

  const shouldShowTextSearchEmptyResults = useSelector(productSearchService, (state) => {
    const { textSearchResults, textSearch } = state.context;
    if (!textSearchResults) {
      return false;
    }
    return (
      state.matches("textSearch.idle") &&
      textSearchResults.origin === "textSearch" &&
      textSearch.length > 2 &&
      searchResults?.length === 0
    );
  });

  const [searchResultsSelectedStates, setSearchResultsSelectedStates] = useState<
    Record<string, boolean>
  >({});

  useEffect(() => {
    setSearchResultsSelectedStates({});
  }, [textSearchQuery]);

  const selectedResults = useMemo(() => {
    return searchResults?.filter(({ id }) => searchResultsSelectedStates[id]) || [];
  }, [searchResults, searchResultsSelectedStates]);

  const onClickSearchResultCard = useCallback(
    (searchResult: ProductSearchUnit) => () => {
      const isSelected = searchResultsSelectedStates[searchResult.id];
      setSearchResultsSelectedStates({
        ...searchResultsSelectedStates,
        [searchResult.id]: !isSelected,
      });
    },
    [searchResultsSelectedStates],
  );

  const onClickAddToList = useCallback(() => {
    productSearchService.send({
      type: "CHOOSE_UNITS",
      inboundUnits: selectedResults,
      origin: "textSearch",
    });
  }, [selectedResults, productSearchService]);

  const productSearchResultCardTranslatedLabels = useMemo(
    () => ({
      singleUnitLabel: intl.formatMessage({
        id: "components.inbound.inbound-units-search-results-list.single-unit-label",
      }),
      handlingUnitLabel: intl.formatMessage({
        id: "components.inbound.inbound-units-search-results-list.handling-unit-label",
      }),
    }),
    [intl],
  );

  if (isNullOrUndefined(searchResults)) {
    return null;
  }

  return (
    <Flex
      direction="column"
      w="100%"
      gap="s50"
      h={shouldShowTextSearchEmptyResults ? "100%" : undefined}
      data-testid="inbound-unit-search-results-list"
    >
      {shouldShowTextSearchEmptyResults ? (
        <Flex direction="column" flex={1} justifyContent="center" textAlign="center">
          <TitleM>
            <FormattedMessage id="components.inbound.inbound-units-search-results-list.no-results-found.title" />
          </TitleM>
          <BodyS>
            <FormattedMessage id="components.inbound.inbound-units-search-results-list.no-results-found.description" />
          </BodyS>
        </Flex>
      ) : (
        <>
          {searchResults.map((searchResult) => (
            <ProductSearchResultCard
              {...searchResult}
              {...productSearchResultCardTranslatedLabels}
              onClickProductCard={onClickSearchResultCard(searchResult)}
              isHighlighted={searchResultsSelectedStates[searchResult.id]}
              key={searchResult.id}
            />
          ))}
          <Box mb="60px" />
          <EndDroppingProcessButton
            shouldShowButton={selectedResults.length > 0}
            onClickButton={onClickAddToList}
            labelMessageId="components.inbound.inbound-units-search-results-list.add-to-list-button-label"
            buttonTestId="add-to-list-button"
            bottom="0"
            p="s200"
          />
        </>
      )}
    </Flex>
  );
}
