import * as Types from "../../../__graphql__/types.js";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type NextOrderForPickingIdQueryVariables = Types.Exact<{ [key: string]: never }>;

export type NextOrderForPickingIdQuery = {
  __typename?: "Query";
  nextOrderForPicking: {
    __typename?: "NextOrderToBePreparedResponse";
    order?: { __typename?: "Order"; id: string; number?: string | null } | null;
  };
};

export const NextOrderForPickingIdDocument = gql`
  query nextOrderForPickingId {
    nextOrderForPicking {
      order {
        id
        number
      }
    }
  }
`;

/**
 * __useNextOrderForPickingIdQuery__
 *
 * To run a query within a React component, call `useNextOrderForPickingIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useNextOrderForPickingIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNextOrderForPickingIdQuery({
 *   variables: {
 *   },
 * });
 */
export function useNextOrderForPickingIdQuery(
  baseOptions?: Apollo.QueryHookOptions<
    NextOrderForPickingIdQuery,
    NextOrderForPickingIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<NextOrderForPickingIdQuery, NextOrderForPickingIdQueryVariables>(
    NextOrderForPickingIdDocument,
    options,
  );
}
export function useNextOrderForPickingIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    NextOrderForPickingIdQuery,
    NextOrderForPickingIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<NextOrderForPickingIdQuery, NextOrderForPickingIdQueryVariables>(
    NextOrderForPickingIdDocument,
    options,
  );
}
export type NextOrderForPickingIdQueryHookResult = ReturnType<typeof useNextOrderForPickingIdQuery>;
export type NextOrderForPickingIdLazyQueryHookResult = ReturnType<
  typeof useNextOrderForPickingIdLazyQuery
>;
export type NextOrderForPickingIdQueryResult = Apollo.QueryResult<
  NextOrderForPickingIdQuery,
  NextOrderForPickingIdQueryVariables
>;
