import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { ApolloQueryResult, isApolloError } from "@apollo/client";
import { orderBy } from "lodash";

import {
  GetProductsByShelfQuery,
  SearchProductsByTextQuery,
} from "flows/Inventory/shared/queries/product/product.generated";

import { useRestockingProductSearchQueries } from "../../hooks/useRestockingProductSearchQueries";
import {
  serializeRestockingProductShelfSearchResults,
  serializeRestockingProductTextSearchResults,
} from "../../models/productSearchResult/serializer";
import { useRestockingProductSearchStore } from "../../stores/restockingProductSearchStore";

export type RestockingProductSearchMode = "text" | "shelf";

export function useRestockingProductSearch<Mode extends RestockingProductSearchMode>({
  mode,
}: {
  mode: RestockingProductSearchMode;
}) {
  type Query = {
    text: GetProductsByShelfQuery;
    shelf: SearchProductsByTextQuery;
  }[Mode];
  type QueryResult = Promise<ApolloQueryResult<Query>>;

  const { getProductsByShelfQuery, searchProductsByTextQuery } =
    useRestockingProductSearchQueries();
  const initialSearchQuery = useRestockingProductSearchStore((state) => state.initialSearchQuery);

  // undefined == nothing has been searched yet
  // null = search produced error
  // [] = search produced no results
  const [searchResultsData, setSearchResultsData] = useState<Query | null | undefined>(undefined);

  const currentQueryString = useRef(initialSearchQuery);
  const [isLoading, setIsLoading] = useState(true);

  const searchResults = useMemo(() => {
    if (searchResultsData === undefined) return null;
    if (searchResultsData === null) return [];
    if (mode === "shelf") {
      const serializedResults = serializeRestockingProductShelfSearchResults(
        searchResultsData as GetProductsByShelfQuery,
      );
      return orderBy(serializedResults, (result) => result.stockOnShelf, "desc");
    }
    return serializeRestockingProductTextSearchResults(
      searchResultsData as SearchProductsByTextQuery,
    );
  }, [searchResultsData, mode]);

  const queryFunction = {
    shelf: getProductsByShelfQuery,
    text: searchProductsByTextQuery,
  }[mode] as (queryString: string) => QueryResult;

  const searchProducts = useCallback(
    async (queryString: string | null) => {
      try {
        setIsLoading(true);
        currentQueryString.current = queryString;
        if (queryString === null) {
          setSearchResultsData(undefined);
          return;
        }

        const { data } = await queryFunction(queryString);

        // if the current query string has changed, that means another query has
        // started while we were still waiting for results for this one.
        // In that case, we discard the results.
        if (currentQueryString.current === queryString) {
          setSearchResultsData(data);
        }
      } catch (error) {
        if (!isApolloError(error as Error)) {
          throw error;
        }
        setSearchResultsData(null);
      } finally {
        setIsLoading(false);
      }
    },
    [queryFunction],
  );

  useEffect(() => {
    if (initialSearchQuery !== null) {
      searchProducts(initialSearchQuery);
    } else {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const currentQuery = currentQueryString.current;

  return {
    searchResults,
    isLoading,
    currentQuery,
    searchProducts,
  };
}
