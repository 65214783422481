import { useEffect } from "react";

import { useAnalytics } from "shared/hooks/useAnalytics";

export function useAppAnalyticsEffects() {
  const { sendSegmentTrackEvent } = useAnalytics();

  useEffect(() => {
    sendSegmentTrackEvent("applicationOpened");

    const sendApplicationBackgroundedEvent = () => sendSegmentTrackEvent("applicationBackgrounded");

    window.addEventListener("blur", sendApplicationBackgroundedEvent);
    return () => {
      window.removeEventListener("blur", sendApplicationBackgroundedEvent);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
