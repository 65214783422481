import { IntlFormatters } from "react-intl";

import { IntlMessageId } from "shared/types/lang";

type IntlFormatMessageArgs = Parameters<IntlFormatters["formatMessage"]>;

export interface ListVerificationCheck {
  key: string;
  sku: string;
  title: IntlMessageId;
  description: IntlFormatMessageArgs;
  isCompleted: boolean;
}

export enum ListVerificationCheckTypeKeys {
  InboundQuantityOfOne = "InboundQuantityOfOne",
  LargeInboundQuantity = "LargeInboundQuantity",
  HandlingUnitQuantityMismatch = "HandlingUnitQuantityMismatch",
}
type ListVerificationCheckTypeKey =
  | ListVerificationCheckTypeKeys.InboundQuantityOfOne
  | ListVerificationCheckTypeKeys.LargeInboundQuantity
  | ListVerificationCheckTypeKeys.HandlingUnitQuantityMismatch;

type SpecificSerializedListVerificationCheck<
  TypeKey extends ListVerificationCheckTypeKey,
  DataType extends Record<string, any>,
> = {
  key: string;
  sku: string;
  type: TypeKey;
  isCompleted: boolean;
  isActive: boolean;
  data: DataType;
};

export type InboundQuantityOfOne = SpecificSerializedListVerificationCheck<
  ListVerificationCheckTypeKeys.InboundQuantityOfOne,
  {}
>;
export type LargeInboundQuantity = SpecificSerializedListVerificationCheck<
  ListVerificationCheckTypeKeys.LargeInboundQuantity,
  { quantity: number }
>;
export type HandlingUnitQuantityMismatch = SpecificSerializedListVerificationCheck<
  ListVerificationCheckTypeKeys.HandlingUnitQuantityMismatch,
  { quantity: number; expected?: number }
>;

export type SerializedListVerificationCheck =
  | InboundQuantityOfOne
  | LargeInboundQuantity
  | HandlingUnitQuantityMismatch;

export type ListVerificationDeserializerOptions<Check extends SerializedListVerificationCheck> = {
  title: IntlMessageId;
  description: (data: Check["data"]) => IntlFormatMessageArgs;
};
