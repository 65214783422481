import { useCallback, useEffect, useRef } from "react";

import { FlexProps } from "@chakra-ui/react";
import { useIntl } from "react-intl";
import shallow from "zustand/shallow";

import {
  ProductSearchActionTypes,
  useProductSearchStore,
} from "shared/stores/useProductSearchStore";
import {
  ProductSearchBar as UIProductSearchBar,
  ProductSearchBarProps as UIProductSearchBarProps,
} from "ui/ProductSearchBar/ProductSearchBar";

type ProductsSearchsComponentProps = {
  isSearchFlowActive: boolean;
  cancelSearchFlow?: UIProductSearchBarProps["onClickCancel"];
  activateTextSearch: UIProductSearchBarProps["onFocusSearchBar"];
} & FlexProps;

export function ProductsSearchComponent({
  isSearchFlowActive,
  cancelSearchFlow = () => {},
  activateTextSearch,
  ...rest
}: ProductsSearchsComponentProps) {
  const productSearchInputRef = useRef<HTMLInputElement | null>(null);

  const intl = useIntl();

  const { searchUnitType, textSearch, activeState, setSearchUnitType, setTextSearch } =
    useProductSearchStore(
      (state) => ({
        searchUnitType: state.searchUnitType,
        textSearch: state.textSearch,
        activeState: state.activeState,
        setSearchUnitType: state.setSearchUnitType,
        setTextSearch: state.setTextSearch,
      }),
      shallow,
    );

  useEffect(() => {
    if (activeState === ProductSearchActionTypes.ACTIVATE_SEARCH) {
      productSearchInputRef.current?.focus();
    }
  }, [activeState]);

  const onInputValueChange = useCallback(
    (newValue: string) => {
      setTextSearch(newValue);
    },
    [setTextSearch],
  );

  useEffect(() => {
    function handleScrollOutside() {
      if (productSearchInputRef.current) {
        productSearchInputRef.current.blur();
      }
    }
    document.addEventListener("touchmove", handleScrollOutside);
    return () => {
      document.removeEventListener("touchmove", handleScrollOutside);
    };
  }, [productSearchInputRef]);

  const searchBarSingleUnitsPlaceholder = isSearchFlowActive
    ? "pages.inbound.inbound-index.product-search-bar.single-units"
    : "pages.inbound.inbound-index.product-search-bar.placeholder";
  const searchBarHandlingUnitsPlaceholder = isSearchFlowActive
    ? "pages.inbound.inbound-index.product-search-bar.handling-units"
    : "pages.inbound.inbound-index.product-search-bar.placeholder";

  return (
    <UIProductSearchBar
      unitType={searchUnitType}
      showUnitsToggle
      onChangeUnitType={(newUnitType) => setSearchUnitType(newUnitType)}
      inputValue={textSearch}
      onInputValueChange={onInputValueChange}
      singleUnitsPlaceholder={intl.formatMessage({
        id: searchBarSingleUnitsPlaceholder,
      })}
      handlingUnitsPlaceholder={intl.formatMessage({
        id: searchBarHandlingUnitsPlaceholder,
      })}
      cancelButtonPlaceholder={intl.formatMessage({
        id: "pages.inbound.inbound-index.product-search-bar.cancel",
      })}
      onClickCancel={cancelSearchFlow}
      onFocusSearchBar={activateTextSearch}
      showCancelButton={isSearchFlowActive}
      w="100%"
      p="s200"
      ref={productSearchInputRef}
      {...rest}
    />
  );
}
