import { createContext, useEffect, useMemo, useState } from "react";

import { useInterpret } from "@xstate/react";
import { Outlet } from "react-router-dom";
import { InterpreterFrom } from "xstate";

import { PageName } from "analytics/events";
import { useCheckRoutesEffects } from "flows/Inventory/shared/hooks/useCheckRoutesEffects";
import { serializeCheck } from "flows/Inventory/shared/models/check/serializer";
import { Check } from "flows/Inventory/shared/models/check/types";
import { useInterpreterRouteSynchronizer } from "shared/hooks/useInterpreterRouteSynchronizer";
import { keepTrackOfStateForDebugging } from "utils/xstate";

import { useBbdCheckActionsImplems } from "../machines/bbdCheckMachine/actions";
import {
  contextInitialState,
  bbdCheckMachine,
  BBD_CHECK_MACHINE_NAME,
} from "../machines/bbdCheckMachine/machine";
import { useBbdCheckServicesImplems } from "../machines/bbdCheckMachine/services";
import { BbdCheckMachineContext, BbdCheckMachineEvents } from "../machines/bbdCheckMachine/types";

export const XStateBbdCheckContext = createContext({
  bbdCheckService: {} as InterpreterFrom<typeof bbdCheckMachine>,
});

type BbdCheckRoutesXStateProviderProps = {
  checkId: string;
  origin: PageName;
  check: Check;
};

function BbdCheckRoutesXStateProvider({
  checkId,
  origin,
  check,
}: BbdCheckRoutesXStateProviderProps) {
  const actions = useBbdCheckActionsImplems();
  const services = useBbdCheckServicesImplems();

  // We use useState to keep the same machine reference for the whole lifetime of this component
  const [machine] = useState(() => {
    return bbdCheckMachine.withContext({
      ...contextInitialState,
      checkId,
      origin,
      sku: check.sku!,
      shelfNumber: check.shelfNumber ?? null,
      shelfLetter: check.shelfLetter ?? null,
      priority: check.priority!,
    });
  });

  const bbdCheckService = useInterpret(
    machine,
    {
      actions,
      services,
    },
    ({ value }) => keepTrackOfStateForDebugging("bbdCheckMachine", value),
  );

  useInterpreterRouteSynchronizer<BbdCheckMachineContext, BbdCheckMachineEvents>(
    BBD_CHECK_MACHINE_NAME,
    bbdCheckService,
  );

  useEffect(() => {
    // Making sure we can call start so it is only triggered once
    if (bbdCheckService.getSnapshot().nextEvents.includes("START")) {
      bbdCheckService.send({ type: "START" });
    }
  }, [bbdCheckService]);

  const values = useMemo(() => ({ bbdCheckService }), [bbdCheckService]);

  return (
    <XStateBbdCheckContext.Provider value={values}>
      <Outlet />
    </XStateBbdCheckContext.Provider>
  );
}

export function XStateBbdCheckProvider() {
  const { isInvalidParams, loading, checkId, origin, data } = useCheckRoutesEffects();

  if (isInvalidParams || loading) {
    return null;
  }

  return (
    <BbdCheckRoutesXStateProvider
      checkId={checkId!}
      origin={origin!}
      check={serializeCheck(data!.getTaskById.task!)}
    />
  );
}
