import { useCallback, useState } from "react";

import { Checkbox, Flex } from "@chakra-ui/react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router";

import { routes } from "config/routes";
import { EndDroppingProcessButton } from "flows/Inbound/components/EndDroppingProcessButton";
import { ProductInformation } from "ui/ProductInformation/ProductInformation";
import { TitleXS } from "ui/Typography/Typography";

import { PublicRestockingItem } from "../../models/restockingItem/types";
import { usePrivateRestockingList } from "../../services/restockingService/service";
import { useRestockingServiceStore } from "../../stores/restockingServiceStore";

function PrivateItem({
  item,
  isChecked,
  onToggle,
}: {
  item: PublicRestockingItem;
  isChecked: boolean;
  onToggle: () => void;
}) {
  return (
    <Checkbox
      data-testid="private-item-card"
      bg="white"
      p="s100"
      w="100%"
      isChecked={isChecked}
      onChange={onToggle}
      colorScheme="pinkFlink"
      sx={{
        "& .chakra-checkbox__label": { width: "100%" },
        "& .chakra-checkbox__control": {
          borderRadius: "2px",
          borderColor: "pinkFlink.500",
          margin: "12px",
        },
      }}
    >
      <ProductInformation
        name={item.product?.name || ""}
        imageUrl={item.product?.imageUrl}
        sku={item.sku}
        variantSize="sm"
        bg="grey.100"
      />
    </Checkbox>
  );
}

export function RestockingMoveItems() {
  const intl = useIntl();
  const navigate = useNavigate();

  const { filteredTranslatedRestockingItems, removeMultipleRestockingItems } =
    usePrivateRestockingList();
  const { selectedItemsBeingRemoved, updateSelectedItemsForRemoval } = useRestockingServiceStore(
    (state) => ({
      selectedItemsBeingRemoved: state.selectedItemsBeingRemoved,
      updateSelectedItemsForRemoval: state.updateSelectedItemsForRemoval,
    }),
  );
  const shouldShowButton = selectedItemsBeingRemoved.length > 0;

  const toggleSelection = useCallback(
    (sku: string) => {
      updateSelectedItemsForRemoval(
        selectedItemsBeingRemoved.includes(sku)
          ? selectedItemsBeingRemoved.filter((item) => item !== sku)
          : [...selectedItemsBeingRemoved, sku],
      );
    },
    [selectedItemsBeingRemoved, updateSelectedItemsForRemoval],
  );
  const [itemsBeingRemoved, setItemsBeingRemoved] = useState(false);

  const handleRemoveMultipleRestockingItems = useCallback(
    async (skus: string[]) => {
      try {
        setItemsBeingRemoved(true);
        await removeMultipleRestockingItems(skus);
        navigate(routes.inventory.restockingList.root);
      } finally {
        setItemsBeingRemoved(false);
      }
    },
    [navigate, removeMultipleRestockingItems],
  );
  return (
    <Flex
      direction="column"
      w="100%"
      flex={1}
      gap={2}
      bg="grey.150"
      paddingBottom={shouldShowButton ? 16 : 2}
    >
      {filteredTranslatedRestockingItems === null ? (
        <TitleXS color="grey.800" fontWeight={700} noOfLines={2}>
          {intl.formatMessage({
            id: "flows.inventory.restocking-list.pages.list-of-items.paragraph.something-went-wrong",
          })}
        </TitleXS>
      ) : (
        <>
          {filteredTranslatedRestockingItems.map((item) => (
            <PrivateItem
              key={item.sku}
              item={item}
              isChecked={selectedItemsBeingRemoved.includes(item.sku)}
              onToggle={() => toggleSelection(item.sku)}
            />
          ))}
          <EndDroppingProcessButton
            shouldShowButton={shouldShowButton}
            isLoading={itemsBeingRemoved}
            onClickButton={() => handleRemoveMultipleRestockingItems(selectedItemsBeingRemoved)}
            labelMessageId="flows.inventory.restocking-list.pages.restocking-move-items.move-items"
            values={{ amount: selectedItemsBeingRemoved.length }}
            buttonTestId="remove-items-from-private-list-button"
            bottom="0"
          />
        </>
      )}
    </Flex>
  );
}
