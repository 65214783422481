import { generatePath } from "react-router-dom";
import { createMachine, assign } from "xstate";
import { send } from "xstate/lib/actions";

import { PageName } from "analytics/events";
import { routes } from "config/routes";
import { CHECK_SESSION_TIMEOUT_MS } from "flows/Inventory/shared/utils/checkTimeout";
import { XstateRouteSyncMeta } from "shared/types/xstate";
import { isNotNullNorUndefined } from "utils/tsHelpers";
import { wrongEventErrorHandlerFactory } from "utils/xstate";

import {
  StockCheckMachineContext,
  StockCheckMachineEvents,
  StockCheckMachineServices,
} from "./types";

export const contextInitialState: StockCheckMachineContext = {
  sku: null,
  checkId: null,
  priority: 0,
  shelfNumber: null,
  shelfLetter: null,
  expectedQuantity: 0,
  declaredQuantity: 0,
  expiredQuantity: 0,
  damagedQuantity: 0,
  remainingStock: 0,
  tooGoodToGoQuantity: 0,
  origin: PageName.ACTIVITIES_PAGE,
};

export const STOCK_CHECK_MACHINE_NAME = "stockCheck";

export const stockCheckMachine = createMachine(
  {
    id: STOCK_CHECK_MACHINE_NAME,
    initial: "idle",
    context: contextInitialState,
    predictableActionArguments: true,
    tsTypes: {} as import("./machine.typegen").Typegen0,
    schema: {
      context: {} as StockCheckMachineContext,
      events: {} as StockCheckMachineEvents,
      services: {} as StockCheckMachineServices,
    },
    on: {
      "*": { actions: "wrongEventErrorHandler" },
      SESSION_EXPIRED: {
        target: "sessionExpired",
      },
    },
    entry: send({ type: "SESSION_EXPIRED" }, { delay: CHECK_SESSION_TIMEOUT_MS }),
    states: {
      idle: {
        on: {
          START: {
            actions: "sendTrackStockCheckStarted",
            target: "declareStock",
          },
        },
      },
      declareStock: {
        on: {
          DECLARE_QUANTITY: {
            actions: "setQuantity",
            target: "declareStockDetails",
          },
        },
        meta: {
          routeSync: {
            generatePath: (ctx: StockCheckMachineContext) => {
              return generatePath(routes.inventory.stockChecks.quantity, {
                checkId: ctx.checkId!,
              });
            },
          } as XstateRouteSyncMeta,
        },
      },
      declareStockDetails: {
        on: {
          DECLARE_STOCK_DETAILS: {
            actions: "setStockDetails",
            target: "performCheckUpdate",
          },
          GO_BACK: { target: "declareStock" },
        },
        meta: {
          routeSync: {
            generatePath: (ctx: StockCheckMachineContext) => {
              return generatePath(routes.inventory.stockChecks.update, {
                checkId: ctx.checkId!,
              });
            },
          } as XstateRouteSyncMeta,
        },
      },
      performCheckUpdate: {
        initial: "loading",
        states: {
          loading: {
            invoke: {
              id: "performCheckUpdate",
              src: "performCheckUpdate",
              onDone: [
                {
                  cond: (ctx) => ctx.origin === PageName.ACTIVITIES_PAGE,
                  actions: [
                    "sendTrackStockCheckFinished",
                    "taskCompletedToast",
                    "redirectToOrigin",
                  ],
                },
                {
                  actions: "sendTrackStockCheckFinished",
                  target: "finished",
                },
              ],
              onError: [
                {
                  cond: (_, event) =>
                    (event.data?.toString() ?? "").includes("Error: taskAlreadyCompleted"),
                  actions: ["taskAlreadyCompletedToast", "sendTrackOnError", "redirectToOrigin"],
                },
                {
                  actions: ["errorToast", "sendTrackOnError"],
                  target: "#stockCheck.declareStockDetails",
                },
              ],
            },
          },
          finished: {
            invoke: {
              id: "fetchNextCheckAndStartCheck",
              src: "fetchNextCheckAndStartCheck",
              onDone: [
                {
                  cond: (_, { data }) => data?.status === "noMoreCheck",
                  actions: ["taskCompletedToast", "redirectToOrigin"],
                },
                {
                  cond: (_, { data }) => data?.status === "unknownError",
                  actions: "redirectToOrigin",
                },
                {
                  cond: (_, { data }) => data?.status === "checkAlreadyTaken",
                  target: "finished",
                },
                {
                  cond: (_, { data }) => isNotNullNorUndefined(data?.check),
                  actions: "redirectToCheck",
                },
              ],
              onError: {
                actions: "redirectToOrigin",
              },
            },
          },
        },
      },
      sessionExpired: {
        entry: ["redirectToOrigin", "checkSessionExpiredToast"],
      },
    },
  },
  {
    actions: {
      setQuantity: assign((_, { declaredQuantity }) => ({
        declaredQuantity,
      })),
      setStockDetails: assign(
        (
          _,
          {
            damagedQuantity,
            expiredQuantity,
            remainingStock,
            expectedQuantity,
            tooGoodToGoQuantity,
          },
        ) => ({
          damagedQuantity,
          expiredQuantity,
          remainingStock,
          expectedQuantity,
          tooGoodToGoQuantity,
        }),
      ),
      wrongEventErrorHandler: wrongEventErrorHandlerFactory(STOCK_CHECK_MACHINE_NAME),
    },
  },
);
