import { useCallback, useMemo, useState } from "react";

import { Flex } from "@chakra-ui/react";
import { useIntl } from "react-intl";

import { useScan } from "shared/hooks/useScan/useScan";
import { RolliCard } from "ui/RolliCard/RolliCard";
import { isValidSSCCFormat, removeLeadingZerosFromSSCC } from "utils/scan";

import { useDeliveryCheckinService } from "../services/deliveryCheckInService/service";
import { RolliStatus, useCheckinUIStore } from "../stores/useCheckInUIStore";
import { RolliScanPlaceholder } from "./RolliScanPlaceholder";
import { ToggleRolliButton } from "./ToggleRolliButton";

export function RolliListContainer() {
  const intl = useIntl();
  const { checkScannedRolli } = useDeliveryCheckinService();
  const [selectedFilter, setSelectedFilter] = useState<string[]>([]);
  const { scannedRollies, highlightedRolliId, setCheckinUIState, toggleRolliIdBarcodeError } =
    useCheckinUIStore((state) => ({
      scannedRollies: state.scannedRollies,
      highlightedRolliId: state.highlightedRolliId,
      setCheckinUIState: state.setCheckinUIState,
      toggleRolliIdBarcodeError: state.toggleRolliIdBarcodeError,
    }));

  const rolliesFilteredAndSorted = useMemo(() => {
    if (!selectedFilter.length) {
      return scannedRollies;
    }
    return scannedRollies?.filter(
      (rollie) => rollie.status && selectedFilter.includes(rollie.status),
    );
  }, [scannedRollies, selectedFilter]);

  const handlingToggleFilter = (selectedValue: string) => () => {
    setSelectedFilter((prevSelectedFilter) => {
      if (prevSelectedFilter.includes(selectedValue)) {
        return prevSelectedFilter.filter((item) => item !== selectedValue);
      }
      return [...prevSelectedFilter, selectedValue];
    });
  };

  const onChangeStateOfRolli = useCallback(
    (sscc: string, status: RolliStatus) => {
      const updatedRollies = scannedRollies?.map((rolli) =>
        rolli.sscc === sscc
          ? {
              ...rolli,
              status,
            }
          : rolli,
      );
      setCheckinUIState({ scannedRollies: updatedRollies });
    },
    [scannedRollies, setCheckinUIState],
  );

  const isScannedRolliesListEmpty = scannedRollies?.length;

  useScan({
    onScan: (scannedValue) => {
      if (!isValidSSCCFormat(scannedValue)) {
        toggleRolliIdBarcodeError();
      } else {
        checkScannedRolli(removeLeadingZerosFromSSCC(scannedValue));
      }
    },
  });

  if (!isScannedRolliesListEmpty) {
    return <RolliScanPlaceholder />;
  }
  return (
    <Flex direction="column" bgColor="white" h="100%">
      <Flex justify="space-between" px="s150">
        <ToggleRolliButton
          isSelected={selectedFilter.includes(RolliStatus.ACCEPTED)}
          label={intl.formatMessage({
            id: "components.rolli-list-container.toggle-rolli-button.accepted",
          })}
          onClick={handlingToggleFilter(RolliStatus.ACCEPTED)}
          color="veggie"
          number={
            scannedRollies?.filter((rollie) => rollie.status === RolliStatus.ACCEPTED).length || 0
          }
        />
        <ToggleRolliButton
          isSelected={selectedFilter.includes(RolliStatus.REJECTED)}
          label={intl.formatMessage({
            id: "components.rolli-list-container.toggle-rolli-button.rejected",
          })}
          onClick={handlingToggleFilter(RolliStatus.REJECTED)}
          color="orange"
          number={
            scannedRollies?.filter((rollie) => rollie.status === RolliStatus.REJECTED).length || 0
          }
        />
      </Flex>
      <Flex
        direction="column"
        gap={2}
        mt="s150"
        pb="160px"
        px="s100"
        overflowY="auto"
        w="100%"
        minH="321px"
        maxH="calc(100% - 160px)"
      >
        {rolliesFilteredAndSorted?.map((item, index) => (
          <RolliCard
            key={item.sscc}
            title={`Rolli (${rolliesFilteredAndSorted.length - index})`}
            id={item.sscc}
            leftLabel={intl.formatMessage({
              id: "components.rolli-list-container.rolli-card.wrong-rolli",
            })}
            rightLabel={intl.formatMessage({
              id: "components.rolli-list-container.rolli-card.right-rolli",
            })}
            status={item.status}
            onChange={(status: RolliStatus) => onChangeStateOfRolli(item.sscc, status)}
            isRolliNotMatch={item.isRolliNotMatch}
            isHighlightedRolli={highlightedRolliId === item.sscc}
          />
        ))}
      </Flex>
    </Flex>
  );
}
