import { Modal, ModalOverlay, ModalContent, ModalBody, ModalFooter, Image } from "@chakra-ui/react";
import { FormattedMessage } from "react-intl";

import Bags from "images/random/bags.svg";
import { useScan } from "shared/hooks/useScan/useScan";
import { BodyM, HeaderS, DetailM } from "ui/Typography/Typography";
import { transformChunks } from "utils/ui";

function ScanJETReweBagModal({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: (method: string) => void;
}) {
  useScan({
    onScan: (scannedValue) => {
      if (scannedValue) onClose("scanned");
    },
  });
  const handleReport = () => {
    onClose("reported");
  };
  return (
    <Modal
      trapFocus={false}
      closeOnOverlayClick={false}
      isCentered
      isOpen={isOpen}
      onClose={() => {}}
      size="xs"
    >
      <ModalOverlay bg="rgba(0,0,0,0.2)" />
      <ModalContent marginX="s300">
        <ModalBody display="flex" flexDir="column" alignItems="center" textAlign="center">
          <Image mt="s200" src={Bags} />
          <HeaderS mt="s200">
            <FormattedMessage id="components.scan-jet-rewe-bag-modal.header" />
          </HeaderS>
          <BodyM mt="s100">
            <FormattedMessage id="components.scan-jet-rewe-bag-modal.body" />
          </BodyM>
        </ModalBody>
        <ModalFooter m="auto">
          <DetailM data-testid="scan-jet-rewe-bag-modal-report">
            <FormattedMessage
              id="components.scan-jet-rewe-bag-modal.footer"
              values={transformChunks({
                a: {
                  cursor: "pointer",
                  textDecoration: "underline",
                  onClick: handleReport,
                },
              })}
            />
          </DetailM>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default ScanJETReweBagModal;
