/* eslint-disable @typescript-eslint/no-unused-vars */
import produce from "immer";
import { assign, createMachine, DoneInvokeEvent } from "xstate";

import { routes } from "config/routes";
import { useRestockingServiceStore } from "flows/Inventory/flows/RestockingList/stores/restockingServiceStore";
import { XstateRouteSyncMeta } from "shared/types/xstate";
import { wrongEventErrorHandlerFactory } from "utils/xstate";

import { InventoryProduct } from "../../../../shared/models/inventoryProduct/types";
import {
  StockCorrectionEvents,
  StockCorrectionMachineContext,
  StockCorrectionServices,
  UpdateProductStockSuccessPayload,
} from "./types";

export const contextInitialState: StockCorrectionMachineContext = {
  selectedInventoryProductSku: null,
  selectedInventoryProductShelf: null,
  inventoryProductsList: [],
  scanType: null,
  scannedValue: null,
  isFromSelectProduct: false,
};

export const STOCK_CORRECTION_MACHINE_NAME = "stockCorrectionMachine";

export const stockCorrectionMachine = createMachine(
  {
    preserveActionOrder: true,
    predictableActionArguments: true,
    id: STOCK_CORRECTION_MACHINE_NAME,
    tsTypes: {} as import("./machine.typegen").Typegen0,
    schema: {
      context: {} as StockCorrectionMachineContext,
      events: {} as StockCorrectionEvents,
      services: {} as StockCorrectionServices,
    },
    context: contextInitialState,
    on: {
      "*": { actions: "wrongEventErrorHandler" },
    },
    initial: "idle",
    states: {
      idle: {
        entry: [
          "resetScanType",
          "resetScannedValue",
          assign(() => ({ isFromSelectProduct: false })),
        ],
        meta: {
          routeSync: {
            path: routes.inventory.stockCorrections.root,
            replace: true,
          } as XstateRouteSyncMeta,
        },
        on: {
          SET_SCAN_TYPE: {
            actions: "setScanType",
          },
          SCAN: {
            actions: "setScannedValue",
            target: "resolvingProducts",
          },
          ACTIVATE_SEARCH: "search",
          SCAN_PRODUCT_FROM_TEXT: {
            target: "resolvingProductForRestocking",
          },
        },
      },
      resolvingProducts: {
        invoke: {
          src: "fetchProducts",
          onDone: [
            {
              cond: (context, event: DoneInvokeEvent<InventoryProduct[]>) => {
                return (
                  context.scanType === "shelf" &&
                  (event.data.every((product) => product.shelf !== context.scannedValue) ||
                    event.data.length === 0)
                );
              },
              actions: "showNoProductAssignedError",
              target: "#stockCorrectionMachine.idle",
            },
            {
              cond: (_, event: DoneInvokeEvent<InventoryProduct[]>) => {
                return event.data.length === 0;
              },
              actions: "showScanProductError",
              target: "#stockCorrectionMachine.idle",
            },
            {
              cond: (_, event: DoneInvokeEvent<InventoryProduct[]>) => {
                return event.data.length > 1;
              },
              actions: ["setInventoryProductsList"],
              target: "#stockCorrectionMachine.selectProduct",
            },
            {
              target: "#stockCorrectionMachine.declareStockCorrections",
              actions: ["setInventoryProductsList", "setFirstInventoryProductAsSelected"],
            },
          ],
          onError: {
            actions: ["showScanProductError"],
            target: "#stockCorrectionMachine.idle",
          },
        },
      },
      resolvingProductForRestocking: {
        invoke: {
          src: "fetchProductsByText",
          onDone: [
            {
              target: "#stockCorrectionMachine.declareStockCorrections",
              actions: ["setInventoryProductsList", "setFirstInventoryProductAsSelected"],
            },
          ],
          onError: {
            actions: ["showScanProductError"],
            target: "#stockCorrectionMachine.idle",
          },
        },
      },
      search: {
        initial: "idle",
        entry: ["resetInventoryProductsList", assign(() => ({ scanType: "search" }))],
        on: {
          CANCEL_SEARCH: {
            target: "#stockCorrectionMachine.idle",
            actions: "resetInventoryProductsList",
          },
          RESET_SEARCH_RESULTS: {
            actions: "resetInventoryProductsList",
          },
          SELECT_INVENTORY_PRODUCT: {
            actions: "setInventoryProduct",
            target: "#stockCorrectionMachine.declareStockCorrections",
          },
        },
        states: {
          idle: {
            on: {
              SEARCH_PRODUCTS: {
                actions: "resetInventoryProductsList",
                target: "loading",
              },
            },
          },
          loading: {
            invoke: {
              src: "fetchProductsByText",
              onDone: {
                target: "idle",
                actions: "setInventoryProductsList",
              },
            },
          },
        },
      },
      navigateToRestocking: {
        meta: {
          routeSync: {
            path: routes.inventory.restockingList.root,
          } as XstateRouteSyncMeta,
        },
      },
      selectProduct: {
        meta: {
          routeSync: {
            path: routes.inventory.stockCorrections.selectProduct,
          } as XstateRouteSyncMeta,
        },
        entry: assign(() => ({ isFromSelectProduct: true })),
        on: {
          SELECT_INVENTORY_PRODUCT: {
            actions: "setInventoryProduct",
            target: "#stockCorrectionMachine.declareStockCorrections",
          },
          VALIDATE_PRODUCT_STOCK: {
            actions: [
              "setInventoryProduct",
              "validateInventoryProductStock",
              "sendSegmentStartCorrectionsByShortcutEvent",
              "sendSegmentFinishCorrectionsByShortcutEvent",
            ],
          },
          CLEAR_LIST: {
            actions: ["resetMachineState"],
            target: "#stockCorrectionMachine.idle",
          },
          GO_BACK: {
            target: "#stockCorrectionMachine.idle",
          },
        },
      },
      declareStockCorrections: {
        initial: "idle",
        meta: {
          routeSync: {
            path: routes.inventory.stockCorrections.declare,
          } as XstateRouteSyncMeta,
        },
        entry: ["sendSegmentStartCorrectionsEvent"],
        on: {
          GO_BACK: [
            {
              cond: (context) => context.isFromSelectProduct,
              target: "#stockCorrectionMachine.selectProduct",
            },
            {
              target: "#stockCorrectionMachine.idle",
            },
          ],
        },
        states: {
          idle: {
            on: {
              UPDATE_PRODUCT_STOCK: {
                target: "loading",
              },
            },
          },
          loading: {
            invoke: {
              src: "updateProductStock",
              onDone: [
                {
                  cond: () => useRestockingServiceStore.getState().isNavigatingToStockCorrection,
                  actions: [
                    "sendSegmentFinishCorrectionsEvent",
                    "updateInventoryProductStock",
                    "showUpdateProductStockSuccess",
                    "resetRestockingServiceStore",
                  ],
                  target: "#stockCorrectionMachine.navigateToRestocking",
                },
                {
                  cond: (context) => {
                    return context.scanType === "shelf";
                  },
                  actions: [
                    "sendSegmentFinishCorrectionsEvent",
                    "updateInventoryProductStock",
                    "showUpdateProductStockSuccess",
                  ],
                  target: "#stockCorrectionMachine.selectProduct",
                },
                {
                  actions: [
                    "sendSegmentFinishCorrectionsEvent",
                    "resetMachineState",
                    "updateInventoryProductStock",
                    "showUpdateProductStockSuccess",
                  ],
                  target: "#stockCorrectionMachine.#stockCorrectionMachine.idle",
                },
              ],
              onError: {
                target: "idle",
                actions: "showUpdateProductStockError",
              },
            },
          },
        },
      },
    },
  },
  {
    actions: {
      wrongEventErrorHandler: wrongEventErrorHandlerFactory(STOCK_CORRECTION_MACHINE_NAME),
      setInventoryProduct: assign((_, event) => ({
        selectedInventoryProductSku: event.inventoryProductSku,
        selectedInventoryProductShelf: event.inventoryProductShelf,
      })),
      setFirstInventoryProductAsSelected: assign((ctx) => ({
        selectedInventoryProductSku: ctx.inventoryProductsList[0].productSku,
        selectedInventoryProductShelf: ctx.inventoryProductsList[0].shelf,
      })),
      setInventoryProductsList: assign((_, event) => ({
        inventoryProductsList: event.data as InventoryProduct[],
      })),
      validateInventoryProductStock: assign((ctx, event) => {
        const nextState = produce(ctx, (ctxDraft) => {
          const inventoryProduct = ctxDraft.inventoryProductsList.find(
            (product: InventoryProduct) =>
              product.productSku === event.inventoryProductSku &&
              product.stockStatus === "unchanged",
          );
          if (inventoryProduct) {
            inventoryProduct.stockStatus = "validated";
          }
          return ctxDraft;
        });
        return nextState;
      }),
      updateInventoryProductStock: assign((ctx, event) => {
        const nextState = produce(ctx, (ctxDraft) => {
          const { productSku, after: stockUpdated } =
            event.data as UpdateProductStockSuccessPayload;

          const inventoryProduct = ctxDraft.inventoryProductsList.find(
            (product: InventoryProduct) => product.productSku === productSku,
          );

          if (inventoryProduct) {
            inventoryProduct.stockStatus =
              inventoryProduct.initialStock !== stockUpdated ? "updated" : "validated";
            inventoryProduct.stock = stockUpdated;
          }
        });
        return nextState;
      }),
      resetInventoryProductsList: assign((_, event) => ({
        inventoryProductsList: [],
      })),
      resetMachineState: assign(() => contextInitialState),
      setScanType: assign((_, event) => ({ scanType: event.scanType })),
      resetScanType: assign((_) => ({ scanType: null })),
      setScannedValue: assign((_, event) => ({ scannedValue: event.barcode })),

      resetScannedValue: assign((_) => ({ scannedValue: null })),
    },
  },
);
