import * as Types from "../../../../__graphql__/types.js";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type GetMultipleDesadvsRolliIdQueryVariables = Types.Exact<{
  input: Types.GetMultipleDesadvsRolliIdInput;
}>;

export type GetMultipleDesadvsRolliIdQuery = {
  __typename?: "Query";
  getMultipleDesadvsRolliID: {
    __typename?: "GetMultipleDesadvsRolliIDResponse";
    despatchAdvices: Array<{
      __typename?: "MultipleDesadv";
      id: string;
      items: Array<{
        __typename?: "DespatchAdviceItem";
        handlingUnitSize: number;
        isTotalQuantityEstimate: boolean;
        skus: Array<string>;
        totalQuantity: number;
      }>;
    }>;
  };
};

export const GetMultipleDesadvsRolliIdDocument = gql`
  query getMultipleDesadvsRolliID($input: GetMultipleDesadvsRolliIDInput!) {
    getMultipleDesadvsRolliID(GetMultipleDesadvsRolliIDInput: $input) {
      despatchAdvices {
        id
        items {
          handlingUnitSize
          isTotalQuantityEstimate
          skus
          totalQuantity
        }
      }
    }
  }
`;

/**
 * __useGetMultipleDesadvsRolliIdQuery__
 *
 * To run a query within a React component, call `useGetMultipleDesadvsRolliIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMultipleDesadvsRolliIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMultipleDesadvsRolliIdQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetMultipleDesadvsRolliIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMultipleDesadvsRolliIdQuery,
    GetMultipleDesadvsRolliIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMultipleDesadvsRolliIdQuery, GetMultipleDesadvsRolliIdQueryVariables>(
    GetMultipleDesadvsRolliIdDocument,
    options,
  );
}
export function useGetMultipleDesadvsRolliIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMultipleDesadvsRolliIdQuery,
    GetMultipleDesadvsRolliIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMultipleDesadvsRolliIdQuery,
    GetMultipleDesadvsRolliIdQueryVariables
  >(GetMultipleDesadvsRolliIdDocument, options);
}
export type GetMultipleDesadvsRolliIdQueryHookResult = ReturnType<
  typeof useGetMultipleDesadvsRolliIdQuery
>;
export type GetMultipleDesadvsRolliIdLazyQueryHookResult = ReturnType<
  typeof useGetMultipleDesadvsRolliIdLazyQuery
>;
export type GetMultipleDesadvsRolliIdQueryResult = Apollo.QueryResult<
  GetMultipleDesadvsRolliIdQuery,
  GetMultipleDesadvsRolliIdQueryVariables
>;
