import { useMemo, useState } from "react";

import { Box, Flex, Image, useDisclosure } from "@chakra-ui/react";
import { shallowEqual, useSelector } from "@xstate/react";
import { FormattedMessage, useIntl } from "react-intl";

import { PageName } from "analytics/events";
import { TaskCheckHeader } from "flows/Inventory/shared/components/TaskCheckHeader";
import { useGetProductStockQuery } from "flows/Inventory/shared/queries/inventoryEntry/inventoryEntry.generated";
import { IncreaseDecrease } from "shared/components/IncreaseDecrease";
import { Page } from "shared/components/Page";
import { SpinnerModal } from "shared/components/SpinnerModal";
import { useAnalytics } from "shared/hooks/useAnalytics";
import { Button } from "ui/Button/Button";
import { BodyS, HeaderM, HeaderS, TitleS, TitleXS } from "ui/Typography/Typography";
import { getDisplayedDate } from "utils/formats";
import { formatImage } from "utils/image";
import { isNotNullNorUndefined } from "utils/tsHelpers";

import { CheckBackstockModal } from "../../components/CheckBackstockModal";
import { OutboundMoreThanExpectedStockModal } from "../../components/OutboundMoreThanExpectedStockModal";
import { ShelfIsEmptyModal } from "../../components/ShelfIsEmptyModal";
import { SkipStepButton } from "../../components/SkipStepButton";
import { useBbdCheckService } from "../../hooks/useBbdCheckService";
import { useBbdCheckTaskData } from "../../hooks/useBbdCheckTaskData";

const IMAGE_SIZE = 187;

export function BbdCheckDeclareExpired() {
  const intl = useIntl();
  const { sendSegmentTrackEvent } = useAnalytics();
  const bbdCheckService = useBbdCheckService();

  const { origin, shelfLetter, shelfNumber } = useSelector(
    bbdCheckService,
    ({ context }) => context,
    shallowEqual,
  );

  const isUpdating = useSelector(bbdCheckService, (state) => state.matches("performCheckUpdate"));

  const {
    productImageUrl,
    productName,
    productCountryOfOriginName,
    minDaysToBestBeforeDate,
    sku,
    checkId,
  } = useBbdCheckTaskData();

  const { data, loading: expectedQuantityLoading } = useGetProductStockQuery({
    fetchPolicy: "network-only",
    variables: { sku: sku!, isAlwaysInbound: true },
    skip: !sku,
    onError: () => bbdCheckService.send({ type: "UNEXPECTED_ERROR" }),
  });

  const [tooGoodToGoQuantity, setTooGoodToGoQuantity] = useState<number>(0);
  const [expiredQuantity, setExpiredQuantity] = useState<number>(0);

  const {
    isOpen: isShelfIsEmptyModalOpen,
    onOpen: onOpenShelfIsEmptyModal,
    onClose: onCloseShelfIsEmptyModal,
  } = useDisclosure();

  const {
    isOpen: isCheckBackstockModalOpen,
    onOpen: onOpenCheckBackstockModal,
    onClose: onCloseCheckBackstockModal,
  } = useDisclosure();

  const {
    isOpen: isOutboundMoreThanExpectedStockModalOpen,
    onOpen: onOpenOutboundMoreThanExpectedStockModal,
    onClose: onCloseOutboundMoreThanExpectedStockModal,
  } = useDisclosure();

  const productStock = data?.getProduct.inventoryEntry.stock.shelf ?? 0;

  const declareQuantities = () => {
    bbdCheckService.send({
      type: "DECLARE_QUANTITIES",
      expiredQuantity,
      tooGoodToGoQuantity,
      expectedQuantity: productStock,
    });
  };

  const handleConfirm = () => {
    const totalOutboundedQuantity = tooGoodToGoQuantity + expiredQuantity;

    const newProductStock = productStock - totalOutboundedQuantity;

    if (newProductStock < 0) {
      sendSegmentTrackEvent("inAppMessageShown", {
        screen_name: PageName.INVENTORY_BBD_DECLARE_EXPIRED,
        component_name: "more_items_to_outbound_than_expected",
        component_content: checkId!,
      });
      onOpenOutboundMoreThanExpectedStockModal();
      return;
    }

    if (newProductStock === 0) {
      sendSegmentTrackEvent("inAppMessageShown", {
        screen_name: PageName.INVENTORY_BBD_DECLARE_EXPIRED,
        component_name: "the_shelf_is_empty",
        component_content: checkId!,
      });
      onOpenShelfIsEmptyModal();
      return;
    }

    declareQuantities();
  };

  const handleConfirmOutboundMoreThanExpectedStock = () => {
    sendSegmentTrackEvent("click", {
      screen_name: PageName.INVENTORY_BBD_DECLARE_EXPIRED,
      component_name: "more_items_to_outbound_than_expected",
      component_value: "Yes",
      component_content: checkId!,
    });
    onCloseOutboundMoreThanExpectedStockModal();
    onOpenShelfIsEmptyModal();
  };

  const handleConfirmShelfIsEmpty = () => {
    bbdCheckService.send({
      type: "SKIP_DECLARE_CLOSEST_BBD",
      expiredQuantity,
      tooGoodToGoQuantity,
      expectedQuantity: productStock,
    });
    onCloseShelfIsEmptyModal();
  };

  const handleEnterDateClick = () => {
    sendSegmentTrackEvent("click", {
      screen_name: PageName.INVENTORY_BBD_DECLARE_EXPIRED,
      component_name: "the_shelf_is_empty",
      component_value: "enter_a_date",
      component_content: checkId!,
    });
    bbdCheckService.send({ type: "SET_CREATE_CHECK_TYPE", checkType: "QUANTITY_AVAILABLE" });
    declareQuantities();
  };

  const onClickOutOfStock = () => {
    sendSegmentTrackEvent("click", {
      screen_name: PageName.INVENTORY_BBD_DECLARE_EXPIRED,
      component_name: "please_check_the_backstock",
      component_value: "out_of_stock",
      component_content: checkId!,
    });
    bbdCheckService.send({ type: "SET_CREATE_CHECK_TYPE", checkType: "NO_QUANTITY" });
    bbdCheckService.send({
      type: "SKIP_DECLARE_CLOSEST_BBD",
      expiredQuantity: 0,
      tooGoodToGoQuantity: 0,
      expectedQuantity: productStock,
    });
    onCloseCheckBackstockModal();
  };

  const onClickSkipStepButton = () => {
    sendSegmentTrackEvent("inAppMessageShown", {
      screen_name: PageName.INVENTORY_BBD_DECLARE_EXPIRED,
      component_name: "please_check_the_backstock",
      component_content: checkId!,
    });
    onOpenCheckBackstockModal();
  };

  const imageUrlFormatted = isNotNullNorUndefined(productImageUrl)
    ? formatImage(productImageUrl, IMAGE_SIZE, IMAGE_SIZE)
    : null;

  const computedExpiryDate = useMemo(() => {
    const date = new Date();
    const minDaysToBBD = minDaysToBestBeforeDate ?? 0;
    const numberOfDaysToAdd = Math.max(-1, minDaysToBBD - 1);
    date.setDate(date.getDate() + numberOfDaysToAdd);
    return date;
  }, [minDaysToBestBeforeDate]);

  return (
    <>
      <Page isLoading={expectedQuantityLoading} isCentered isBgGrey isFull>
        <TaskCheckHeader
          headerMessageId="flows.inventory.checks.bbd-checks.pages.bbd-check-declare-expired.bbd-check"
          origin={origin}
          shelfLetter={shelfLetter}
          shelfNumber={shelfNumber}
        />
        <Flex direction="column" flex={1} w="100%" alignItems="center" overflowY="scroll">
          <Flex direction="column" alignItems="center" gap="s100">
            <Flex
              direction="column"
              justifyContent="center"
              alignItems="center"
              bg="purple.100"
              paddingX="s200"
              paddingY="s150"
              w="100%"
            >
              <BodyS>
                {intl.formatMessage({
                  id: "flows.inventory.checks.bbd-checks.pages.bbd-check-declare-expired.banner.label",
                })}
              </BodyS>
              <HeaderS data-testid="expiry-date">{getDisplayedDate(computedExpiryDate)}</HeaderS>
            </Flex>
            {isNotNullNorUndefined(imageUrlFormatted) && (
              <Image
                src={imageUrlFormatted}
                objectFit="contain"
                h="60vh"
                maxW={`${IMAGE_SIZE}px`}
                maxH={`${IMAGE_SIZE}px`}
              />
            )}
            <Flex px="s200" gap="s100">
              <Flex
                justifyContent="center"
                alignItems="center"
                px="s200"
                py="s100"
                bg="white"
                borderRadius="sm"
                alignSelf="stretch"
                w="100%"
              >
                <BodyS textAlign="center" color="grey.800" data-testid="stock-check-product-name">
                  {productName}
                  {productCountryOfOriginName ? ` (${productCountryOfOriginName})` : null}
                </BodyS>
              </Flex>
              <Flex
                direction="column"
                justifyContent="center"
                alignItems="center"
                p="s100"
                bg="white"
                borderRadius="sm"
              >
                <TitleS color="grey.800">
                  {intl.formatMessage({
                    id: "flows.inventory.checks.bbd-checks.pages.bbd-check-declare-expired.stock-label",
                  })}
                </TitleS>
                <HeaderM data-testid="product-stock">{productStock}</HeaderM>
              </Flex>
            </Flex>
            <Box
              bg="purple.100"
              px="s100"
              py="s50"
              mt="s100"
              ml="s200"
              borderRadius="4px"
              alignSelf="start"
            >
              <TitleXS data-testid="todays-date">
                <FormattedMessage id="flows.inventory.checks.bbd-checks.pages.bbd-check-declare-expired.todays-date" />
                {` ${getDisplayedDate(new Date())}`}
              </TitleXS>
            </Box>
            <Box w="100%" bg="white" px="s300" py="s200">
              <IncreaseDecrease
                labelMessageId="components.increasedecrease.too_good_to_go"
                changeHandler={setTooGoodToGoQuantity}
                count={tooGoodToGoQuantity}
                dataTestId="tgtg-quantity"
                withInput
              />
              <IncreaseDecrease
                labelMessageId="components.increasedecrease.how_many_are_expired"
                count={expiredQuantity}
                changeHandler={setExpiredQuantity}
                dataTestId="expired-quantity"
                withInput
              />
            </Box>
          </Flex>
          <SkipStepButton
            onClick={onClickSkipStepButton}
            label={intl.formatMessage({
              id: "flows.inventory.bbd-checks.pages.declare-expired.shelf-is-empty-button-label",
            })}
          />
        </Flex>
        <Box
          bg="white"
          borderTopWidth="1px"
          borderTopStyle="solid"
          borderTopColor="grey.200"
          p="s200"
          width="100%"
        >
          <Button
            disabled={isUpdating}
            onClick={handleConfirm}
            width="full"
            flinkVariant="primary"
            size="lg"
            data-testid="submit-quantities-button"
          >
            <FormattedMessage id="pages.task.taskIndex.confirm_amount" />
          </Button>
        </Box>
      </Page>
      <ShelfIsEmptyModal
        isOpen={isShelfIsEmptyModalOpen}
        onClickConfirm={handleConfirmShelfIsEmpty}
        onClickEnterDate={handleEnterDateClick}
      />
      <CheckBackstockModal
        isOpen={isCheckBackstockModalOpen}
        onClickConfirm={onCloseCheckBackstockModal}
        onClickOutOfStock={onClickOutOfStock}
        totalUnits={productStock}
      />
      <OutboundMoreThanExpectedStockModal
        isOpen={isOutboundMoreThanExpectedStockModalOpen}
        onClickConfirm={handleConfirmOutboundMoreThanExpectedStock}
        onClickNo={onCloseOutboundMoreThanExpectedStockModal}
        totalUnits={tooGoodToGoQuantity + expiredQuantity}
      />
      <SpinnerModal isOpen={isUpdating} />
    </>
  );
}
