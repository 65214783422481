import { useCallback } from "react";

import { openOngoingActivityModal } from "core/stores/useLayoutStore";
import { useTimedActivityStore } from "shared/stores/useTimedActivityStore";
import { BOTTOM_TAB_BAR_HEIGHT_NUMBER } from "ui/BottomTabBarContainer";
import { isNotNullNorUndefined } from "utils/tsHelpers";

import { useGenerateVerificationChecks } from "../hooks/useGenerateVerificationChecks";
import { EndDroppingProcessButton } from "./EndDroppingProcessButton";

export function SharedListVerificationButton() {
  const timedActivityInstance = useTimedActivityStore((state) => state.timedActivityInstance);
  const { generateChecks, isGeneratingChecks } = useGenerateVerificationChecks();
  const onClickStartListVerification = useCallback(() => {
    if (isNotNullNorUndefined(timedActivityInstance)) {
      openOngoingActivityModal();
    }
    generateChecks();
  }, [generateChecks, timedActivityInstance]);

  return (
    <EndDroppingProcessButton
      shouldShowButton
      onClickButton={onClickStartListVerification}
      labelMessageId="pages.inbound.inbound-index.verify-list-button-label"
      bottom={BOTTOM_TAB_BAR_HEIGHT_NUMBER}
      isLoading={isGeneratingChecks}
    />
  );
}
