import { Config } from "./types";

export const developmentConfig: Config = {
  environment: {
    ENVIRONMENT: "development",
    HUB_ONE_CORE_API_URL: import.meta.env.EXP_API_URL || "http://localhost:8080",
    FORCE_PWA: false,
    AUTH0_AUDIENCE: "https://api.staging.goflink.com",
    AUTH0_DOMAIN: "flink-staging.eu.auth0.com",
    AUTH0_CLIENT_ID: "zY6Xe9S0j13u7RqntZUWaafms0oeGFU1",
    IDLE_LOGOUT_MINUTES: 40,
    CLOUDINARY_BASE_URL: "https://res.cloudinary.com/goflink/image/upload",
    SEGMENT_WRITE_KEY: "s3uXAITjXFDb9QpEij9iYcZS7Rf9wjB2",
    OPTIMIZELY_SDK_KEY: "Bv3NpK8V6KiovoJvnpw1D",
    DATADOG_APPLICATION_ID: "86aceac2-449a-4e20-9d05-22666af1f498",
    DATADOG_CLIENT_TOKEN: "pubbf0c4d05c2d3bd6caedbe851f6a3bf54",
    EPPO_URL: "https://api.staging.goflink.com/user-tracking-gateway/user-tracking-gateway",
    INTERCOM_APP_ID: "csvc20v5",
  },
};
