import { uniqBy } from "lodash";

import {
  GetProductsByShelfQuery,
  SearchProductsByTextQuery,
} from "flows/Inventory/shared/queries/product/product.generated";
import { getShelfDetail } from "utils/item";

import { RestockingProductSearchResult } from "./types";

export const serializeRestockingProductShelfSearchResults = (
  data: GetProductsByShelfQuery,
): RestockingProductSearchResult[] => {
  const filteredIventoryEntries = data.getInventoryEntriesByShelf.inventoryEntries.filter(
    (entry) => !!entry.product,
  );

  return filteredIventoryEntries.map(({ product }) => {
    const shelfDetail = product?.inventoryEntry.shelfNumber
      ? getShelfDetail(product.inventoryEntry.shelfNumber)
      : null;
    return {
      sku: product!.sku,
      name: product!.name,
      imageUrl: product!.imageUrl ?? null,
      isBio: product!.bio ?? null,
      countryOfOriginCode: product!.countryOfOrigin.code?.toUpperCase() ?? null,
      shelfLetter: shelfDetail?.shelfLetter ?? null,
      shelfNumber: shelfDetail?.shelfNumber ?? null,
      shelf: product!.inventoryEntry.shelfNumber ?? null,
      stockOnShelf: product!.inventoryEntry.stock.shelf,
    };
  });
};

export const serializeRestockingProductTextSearchResults = (
  data: SearchProductsByTextQuery,
): RestockingProductSearchResult[] => {
  const filteredUnits = data.searchUnitsByText.units.filter((unit) => !!unit.product);
  const uniqueProducts = uniqBy(filteredUnits, (unit) => unit.product.sku);

  return uniqueProducts.map(({ product }) => {
    const shelfDetail = product?.inventoryEntry.shelfNumber
      ? getShelfDetail(product.inventoryEntry.shelfNumber)
      : null;
    return {
      sku: product!.sku,
      name: product!.name,
      imageUrl: product!.imageUrl ?? null,
      isBio: product!.bio ?? null,
      countryOfOriginCode: product!.countryOfOrigin.code?.toUpperCase() ?? null,
      shelfLetter: shelfDetail?.shelfLetter ?? null,
      shelfNumber: shelfDetail?.shelfNumber ?? null,
      shelf: product!.inventoryEntry.shelfNumber ?? null,
      stockOnShelf: product!.inventoryEntry.stock.shelf,
    };
  });
};
