import { useCallback, useEffect, useState } from "react";

import { useApolloClient } from "@apollo/client";
import { Flex, IconButton } from "@chakra-ui/react";
import { useSelector } from "@xstate/react";
import { useIntl } from "react-intl";

import { useAppBackgroundEffectsStore } from "core/stores/useAppBackgroundEffectsStore";
import { openOngoingActivityModal } from "core/stores/useLayoutStore";
import { EppoFeatureFlags } from "core/types/flags";
import { NewOrderSummary } from "flows/Picking/components/NewOrderSummary/NewOrderSummary";
import { serializeOrderToStartPicking } from "flows/Picking/models/order/serializer";
import { OrderWithItems } from "flows/Picking/models/order/types";
import {
  GetOrderDocument,
  GetOrderQuery,
  GetOrderQueryVariables,
  GetNextOrderForPickingDocument,
  GetNextOrderForPickingQuery,
  GetNextOrderForPickingQueryVariables,
} from "flows/Picking/queries/order/order.generated";
import { Page } from "shared/components/Page";
import { useEppoFeatureFlagProvider } from "shared/hooks/useEppoFeatureFlag";
import { useTimedActivityStore } from "shared/stores/useTimedActivityStore";
import { ScanIcon, SignIcon } from "ui/Icons/Icons";
import { PagePlaceholder } from "ui/PagePlaceholder/PagePlaceholder";
import { isNotNullNorUndefined } from "utils/tsHelpers";

import { usePickingService } from "../../hooks/usePickingService";

export function PickingIndexPage() {
  const intl = useIntl();
  const pickingService = usePickingService();
  const client = useApolloClient();
  const [nextOrderForPicking, setNextOrderForPicking] = useState<OrderWithItems | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const { isFeatureEnabled: isOpsAssignmentV0Enabled } = useEppoFeatureFlagProvider(
    EppoFeatureFlags.OPS_ASSIGNMENT_V0,
  );

  const timedActivityInstance = useTimedActivityStore((state) => state.timedActivityInstance);

  const nextOrderForPickingId = useAppBackgroundEffectsStore(
    (state) => state.summaryOfNextOrderForPicking?.id,
  );

  // OAs sometimes press the back button, and orders are stuck in the picking state.
  // So, we are adding this check to redirect users to the stuck page if they are not in the correct state.
  const isEndPickingIdle = useSelector(pickingService, (state) => state.matches("endPicking.idle"));
  const isPickingIdle = useSelector(pickingService, (state) => state.matches("picking.idle"));
  const isManualPicking = useSelector(pickingService, (state) =>
    state.matches("picking.startManualPicking"),
  );

  if (!isManualPicking) {
    if (!isEndPickingIdle || !isPickingIdle) {
      pickingService.send({ type: "GO_TO_STUCK" });
    }
  }

  const fetchOrderById = useCallback(() => {
    if (nextOrderForPickingId) {
      client
        .query<GetOrderQuery, GetOrderQueryVariables>({
          query: GetOrderDocument,
          variables: { input: { orderId: nextOrderForPickingId } },
          fetchPolicy: "network-only",
        })
        .then(({ data }) => {
          const order = data?.getOrder?.order;
          if (order) {
            setNextOrderForPicking(serializeOrderToStartPicking({ order }));
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  }, [client, nextOrderForPickingId]);

  const fetchGetNextOrderForPicking = useCallback(() => {
    client
      .query<GetNextOrderForPickingQuery, GetNextOrderForPickingQueryVariables>({
        query: GetNextOrderForPickingDocument,
        variables: { isAlwaysInbound: true },
        fetchPolicy: "network-only",
      })
      .then(({ data }) => {
        const order = data?.nextOrderForPicking?.order;
        if (order) {
          setNextOrderForPicking(serializeOrderToStartPicking({ order }));
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [client]);

  useEffect(() => {
    setIsLoading(true);
    setNextOrderForPicking(null);
    if (isOpsAssignmentV0Enabled) {
      fetchOrderById();
    } else {
      fetchGetNextOrderForPicking();
    }
  }, [
    nextOrderForPickingId,
    client,
    isOpsAssignmentV0Enabled,
    fetchOrderById,
    fetchGetNextOrderForPicking,
  ]);

  const onClickManualPickingIcon = () => {
    if (isNotNullNorUndefined(timedActivityInstance)) {
      openOngoingActivityModal();
      return;
    }
    pickingService.send({ type: "GO_TO_START_MANUAL_PICKING" });
  };

  return (
    <Page isCentered={!nextOrderForPickingId} isLoading={isLoading} isBgGrey>
      <Flex w="100%" justifyContent="flex-end">
        <IconButton
          bg="pinkFlink.200"
          height="40px"
          width="40px"
          aria-label="scan-manually"
          icon={<ScanIcon color="pinkFlink.500" height="22px" width="22px" />}
          onClick={onClickManualPickingIcon}
        />
      </Flex>
      {isNotNullNorUndefined(nextOrderForPicking) ? (
        <NewOrderSummary nextOrder={nextOrderForPicking} />
      ) : (
        <PagePlaceholder
          icon={<SignIcon />}
          title={intl.formatMessage({ id: "pages.picking.picking-index.placeholder-title" })}
          subtitle={intl.formatMessage({ id: "pages.picking.picking-index.placeholder-subtitle" })}
        />
      )}
    </Page>
  );
}
