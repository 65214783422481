import * as Types from "../../../__graphql__/types.js";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type GetPublicRestockingListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GetPublicRestockingListQuery = {
  __typename?: "Query";
  getPublicRestockingList: {
    __typename?: "GetPublicRestockingListResponse";
    publicRestockingList: {
      __typename?: "PublicRestockingList";
      hubSlug: string;
      restockingItems: Array<{
        __typename?: "RestockingItem";
        sku: string;
        product?: {
          __typename?: "IProduct";
          sku: string;
          imageUrl?: string | null;
          name: string;
          bio?: boolean | null;
          countryOfOrigin: { __typename?: "CountryOfOrigin"; code?: string | null };
          inventoryEntry: {
            __typename?: "InventoryEntry";
            shelfNumber?: string | null;
            stock: { __typename?: "Stock"; shelf: number };
          };
        } | null;
      }>;
    };
  };
};

export const GetPublicRestockingListDocument = gql`
  query getPublicRestockingList {
    getPublicRestockingList {
      publicRestockingList {
        hubSlug
        restockingItems {
          sku
          product {
            sku
            imageUrl
            name
            bio
            countryOfOrigin {
              code
            }
            inventoryEntry {
              shelfNumber
              stock {
                shelf
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetPublicRestockingListQuery__
 *
 * To run a query within a React component, call `useGetPublicRestockingListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPublicRestockingListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPublicRestockingListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPublicRestockingListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetPublicRestockingListQuery,
    GetPublicRestockingListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPublicRestockingListQuery, GetPublicRestockingListQueryVariables>(
    GetPublicRestockingListDocument,
    options,
  );
}
export function useGetPublicRestockingListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPublicRestockingListQuery,
    GetPublicRestockingListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPublicRestockingListQuery, GetPublicRestockingListQueryVariables>(
    GetPublicRestockingListDocument,
    options,
  );
}
export type GetPublicRestockingListQueryHookResult = ReturnType<
  typeof useGetPublicRestockingListQuery
>;
export type GetPublicRestockingListLazyQueryHookResult = ReturnType<
  typeof useGetPublicRestockingListLazyQuery
>;
export type GetPublicRestockingListQueryResult = Apollo.QueryResult<
  GetPublicRestockingListQuery,
  GetPublicRestockingListQueryVariables
>;
