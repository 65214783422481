import { generatePath } from "react-router";
import { assign, createMachine, send } from "xstate";

import { PageName } from "analytics/events";
import { routes } from "config/routes";
import { CHECK_SESSION_TIMEOUT_MS } from "flows/Inventory/shared/utils/checkTimeout";
import { XstateRouteSyncMeta } from "shared/types/xstate";
import { isNotNullNorUndefined } from "utils/tsHelpers";
import { wrongEventErrorHandlerFactory } from "utils/xstate";

import { BbdCheckMachineContext, BbdCheckMachineEvents, BbdCheckMachineServices } from "./types";

export const contextInitialState: BbdCheckMachineContext = {
  sku: null,
  checkId: null,
  priority: 0,
  shelfNumber: null,
  shelfLetter: null,
  expiredQuantity: 0,
  tooGoodToGoQuantity: 0,
  origin: PageName.ACTIVITIES_PAGE,
  createCheckType: null,
  expectedQuantity: 0,
};

export const BBD_CHECK_MACHINE_NAME = "bbdCheck";

export const bbdCheckMachine = createMachine(
  {
    id: BBD_CHECK_MACHINE_NAME,
    initial: "idle",
    context: contextInitialState,
    predictableActionArguments: true,
    tsTypes: {} as import("./machine.typegen").Typegen0,
    schema: {
      context: {} as BbdCheckMachineContext,
      events: {} as BbdCheckMachineEvents,
      services: {} as BbdCheckMachineServices,
    },
    on: {
      "*": { actions: "wrongEventErrorHandler" },
      UNEXPECTED_ERROR: { actions: ["errorToast", "redirectToOrigin"] },
      SESSION_EXPIRED: {
        target: "sessionExpired",
      },
    },
    entry: send({ type: "SESSION_EXPIRED" }, { delay: CHECK_SESSION_TIMEOUT_MS }),
    states: {
      idle: {
        on: {
          START: {
            actions: "sendTrackBbdCheckStarted",
            target: "declareExpired",
          },
        },
      },
      declareExpired: {
        entry: "resetCreateCheckType",
        on: {
          DECLARE_QUANTITIES: {
            actions: "setQuantities",
            target: "declareClosestBbd",
          },
          SKIP_DECLARE_CLOSEST_BBD: {
            actions: "setQuantities",
            target: "performCheckUpdate",
          },
          SET_CREATE_CHECK_TYPE: {
            actions: "setCreateCheckType",
          },
        },
        meta: {
          routeSync: {
            generatePath: (ctx: BbdCheckMachineContext) => {
              return generatePath(routes.inventory.bbdChecks.declareExpired, {
                checkId: ctx.checkId!,
              });
            },
          } as XstateRouteSyncMeta,
        },
      },
      declareClosestBbd: {
        on: {
          SET_CLOSEST_BBD: {
            actions: "setClosestBbd",
          },
          UPDATE_CHECK: {
            target: "performCheckUpdate",
          },
          SET_CREATE_CHECK_TYPE: {
            actions: "setCreateCheckType",
          },
          GO_BACK: { target: "declareExpired" },
        },
        meta: {
          routeSync: {
            generatePath: (ctx: BbdCheckMachineContext) => {
              return generatePath(routes.inventory.bbdChecks.declareClosestBdd, {
                checkId: ctx.checkId!,
              });
            },
          } as XstateRouteSyncMeta,
        },
      },
      performCheckUpdate: {
        initial: "loading",
        states: {
          loading: {
            invoke: {
              id: "performCheckUpdate",
              src: "performCheckUpdate",
              onDone: [
                {
                  cond: (ctx) => ctx.createCheckType !== null,
                  actions: "sendTrackBbdCheckFinished",
                  target: "loadingCreateCheck",
                },
                {
                  cond: "isFromActivitiesPage",
                  actions: ["sendTrackBbdCheckFinished", "taskCompletedToast", "redirectToOrigin"],
                },
                {
                  actions: "sendTrackBbdCheckFinished",
                  target: "finished",
                },
              ],
              onError: [
                {
                  cond: (_, event) =>
                    (event.data?.toString() ?? "").includes("Error: taskAlreadyCompleted"),
                  actions: ["taskAlreadyCompletedToast", "sendTrackOnError", "redirectToOrigin"],
                },
                {
                  cond: (_, __, condMeta) =>
                    condMeta.state.event.type === "SKIP_DECLARE_CLOSEST_BBD",
                  actions: ["errorToast", "sendTrackOnError"],
                  target: "#bbdCheck.declareExpired",
                },
                {
                  actions: ["errorToast", "sendTrackOnError"],
                  target: "#bbdCheck.declareClosestBbd",
                },
              ],
            },
          },
          loadingCreateCheck: {
            invoke: {
              id: "createCheck",
              src: "createCheck",
              onDone: [
                {
                  cond: "isFromActivitiesPage",
                  actions: ["stockCheckCreatedToast", "taskCompletedToast", "redirectToOrigin"],
                },
                { target: "finished", actions: "stockCheckCreatedToast" },
              ],
              onError: [
                {
                  cond: "isFromActivitiesPage",
                  actions: ["taskCompletedToast", "redirectToOrigin"],
                },
                { target: "finished" },
              ],
            },
          },
          finished: {
            invoke: {
              id: "fetchNextCheckAndStartCheck",
              src: "fetchNextCheckAndStartCheck",
              onDone: [
                {
                  cond: (_, { data }) => data?.status === "noMoreCheck",
                  actions: ["taskCompletedToast", "redirectToOrigin"],
                },
                {
                  cond: (_, { data }) => data?.status === "unknownError",
                  actions: "redirectToOrigin",
                },
                {
                  cond: (_, { data }) => data?.status === "checkAlreadyTaken",
                  target: "finished",
                },
                {
                  cond: (_, { data }) => isNotNullNorUndefined(data?.check),
                  actions: "redirectToCheck",
                },
              ],
              onError: {
                actions: "redirectToOrigin",
              },
            },
          },
        },
      },
      sessionExpired: {
        entry: ["redirectToOrigin", "checkSessionExpiredToast"],
      },
    },
  },
  {
    actions: {
      setQuantities: assign((_, { expiredQuantity, tooGoodToGoQuantity, expectedQuantity }) => ({
        expiredQuantity,
        tooGoodToGoQuantity,
        expectedQuantity,
      })),
      setClosestBbd: assign((_, { closestBbd }) => ({ closestBbd })),
      wrongEventErrorHandler: wrongEventErrorHandlerFactory(BBD_CHECK_MACHINE_NAME),
      setCreateCheckType: assign((_, { checkType }) => ({ createCheckType: checkType })),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      resetCreateCheckType: assign((_) => ({ createCheckType: null })),
    },
    guards: {
      isFromActivitiesPage: (ctx) => ctx.origin === PageName.ACTIVITIES_PAGE,
    },
  },
);
