import { useCallback, useRef } from "react";

import { isApolloError } from "@apollo/client";
import { Flex, Tag } from "@chakra-ui/react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router";

import { RESTOCKING_PROGRESSED_METHOD } from "analytics/events";
import { routes } from "config/routes";
import { useAppBackgroundEffectsStore } from "core/stores/useAppBackgroundEffectsStore";
import { EppoFeatureFlags } from "core/types/flags";
import { Page } from "shared/components/Page";
import { useBarcodeScanner } from "shared/hooks/useBarcodeScanner";
import { useCustomToast } from "shared/hooks/useCustomToast";
import { useEppoFeatureFlagProvider } from "shared/hooks/useEppoFeatureFlag";
import { useScan } from "shared/hooks/useScan/useScan";
import { IntlMessageId } from "shared/types/lang";
import { FileBlankListIcon } from "ui/Icons/Icons";
import { NavigationHeader } from "ui/NavigationHeader/NavigationHeader";
import { ProductSearchBar } from "ui/ProductSearchBar/ProductSearchBar";
import { TitleS } from "ui/Typography/Typography";
import { isShelfNumberFormat } from "utils/scan";

import { RestockingListPreparationList } from "../../components/RestockingListPreparationList";
import { useResolveEANToSkus } from "../../hooks/useResolveEanToSkus";
import { RestockingItem } from "../../models/restockingItem/types";
import { usePrivateRestockingList } from "../../services/restockingService/service";
import { useRestockingProductSearchStore } from "../../stores/restockingProductSearchStore";

const ADD_UNIT_TO_THE_LIST_ERROR_TOAST_ID = "add-unit-to-the-list-error-toast-id";

export function RestockingListPreparation() {
  const intl = useIntl();
  const { showToastUI } = useCustomToast();

  const navigate = useNavigate();
  const scrollContainer = useRef<HTMLDivElement | null>(null);
  const resolveEANToSkus = useResolveEANToSkus();
  const { processBarcode } = useBarcodeScanner();
  const { isFeatureEnabled: isNewRestocking } = useEppoFeatureFlagProvider(
    EppoFeatureFlags.NEW_RESTOCKING,
  );
  const publicRestockingList =
    useAppBackgroundEffectsStore((state) => state.publicRestockingList)?.getPublicRestockingList
      .publicRestockingList.restockingItems.length ?? 0;

  const { isLoadingPrivateRestockingList, addItemsToPrivateRestockingList, privateRestockingList } =
    usePrivateRestockingList();

  const setInitialSearchQuery = useRestockingProductSearchStore(
    (state) => state.setInitialSearchQuery,
  );

  const showErrorToast = useCallback(
    (errorMessage: IntlMessageId) => {
      showToastUI({
        id: ADD_UNIT_TO_THE_LIST_ERROR_TOAST_ID,
        containerStyle: {
          width: "100%",
          bottom: "150px",
          position: "absolute",
          paddingLeft: "s100",
          paddingRight: "s100",
        },
        title: errorMessage,
      });
    },
    [showToastUI],
  );

  const addItemsToPrivateRestockingListByScanning = useCallback(
    async (ean: string) => {
      const skus = await resolveEANToSkus(ean);
      if (skus.length === 0) {
        return;
      }
      if (skus.length > 1) {
        setInitialSearchQuery(ean);
        navigate(routes.inventory.restockingList.textSearch);
        return;
      }
      const [sku] = skus;
      const isSkuAlreadyInPrivateList = privateRestockingList?.restockingItems.some(
        (item: RestockingItem) => sku === item.sku,
      );
      if (isSkuAlreadyInPrivateList) {
        showErrorToast("flows.inventory.restocking-list.item-is-already-in-the-list");
        return;
      }
      try {
        await addItemsToPrivateRestockingList([sku], RESTOCKING_PROGRESSED_METHOD.SCANNED_PRODUCT);
      } catch (error) {
        showErrorToast("flows.inventory.restocking-list.add-items-to-private-list-error");
        if (!isApolloError(error as Error)) {
          throw error;
        }
      }
    },
    [
      addItemsToPrivateRestockingList,
      resolveEANToSkus,
      privateRestockingList?.restockingItems,
      showErrorToast,
      setInitialSearchQuery,
      navigate,
    ],
  );

  const onScan = useCallback(
    (value: string) => {
      if (isShelfNumberFormat(value)) {
        setInitialSearchQuery(value);
        navigate(routes.inventory.restockingList.shelfSearch);
        return;
      }
      const searchedEan = processBarcode(value);
      if (searchedEan) {
        addItemsToPrivateRestockingListByScanning(searchedEan);
      }
    },
    [setInitialSearchQuery, navigate, addItemsToPrivateRestockingListByScanning, processBarcode],
  );

  useScan({ onScan });

  const goToTextSearch = () => {
    setInitialSearchQuery(null);
    navigate(routes.inventory.restockingList.textSearch);
  };

  if (isNewRestocking)
    return (
      <RestockingListPreparationList
        isLoading={isLoadingPrivateRestockingList}
        scrollContainer={scrollContainer}
      />
    );

  return (
    <Page isFull isBgGrey height="100%">
      <NavigationHeader
        title={intl.formatMessage({
          id: "flows.inventory.restocking-list.pages.list-preparation.title",
        })}
        onClickGoBack={() => navigate(routes.inventory.root)}
      />

      <Flex
        direction="column"
        alignItems={isLoadingPrivateRestockingList ? "center" : undefined}
        overflowY="scroll"
        w="100%"
        h="100%"
        ref={scrollContainer}
      >
        <Flex alignItems="center" justifyContent="space-evenly" w="100%">
          <ProductSearchBar
            showUnitsToggle={false}
            inputValue=""
            onInputValueChange={() => {}}
            showCancelButton={false}
            placeholder={intl.formatMessage({
              id: "flows.inventory.restocking-list.product-search.placeholder",
            })}
            onFocusSearchBar={goToTextSearch}
            w="100%"
            p="s200"
          />
          <Flex
            justify="center"
            align="center"
            w="60px"
            h="40px"
            position="relative"
            borderRadius="8"
            bg="white"
            color="gray.800"
            mr="4"
            data-testid="publicListIcon"
          >
            <TitleS onClick={() => navigate(routes.inventory.restockingList.publicList)}>
              <FileBlankListIcon boxSize="6" />
              <Tag
                size="sm"
                pos="absolute"
                top="-3px"
                right="28px"
                transform="translateX(100%)"
                bg="pinkFlink.500"
                color="white"
                borderRadius="100px"
              >
                {publicRestockingList}
              </Tag>
            </TitleS>
          </Flex>
        </Flex>
        <RestockingListPreparationList
          isLoading={isLoadingPrivateRestockingList}
          scrollContainer={scrollContainer}
        />
      </Flex>
    </Page>
  );
}
