import { useCallback } from "react";

import dayjs from "dayjs";

import { PageName } from "analytics/events";
import { useCheckAlreadyCompletedToast } from "flows/Inventory/shared/hooks/useCheckAlreadyCompletedToast";
import { useCheckSessionExpiredToast } from "flows/Inventory/shared/hooks/useCheckSessionExpiredToast";
import { useChecksRedirect } from "flows/Inventory/shared/hooks/useChecksRedirect";
import { useTaskCompletedToast } from "flows/Inventory/shared/hooks/useTaskCompletedToast";
import { ErrorTypeForAnalytics, useAnalytics } from "shared/hooks/useAnalytics";
import { useCustomToast } from "shared/hooks/useCustomToast";
import { useErrorToastWithAudio } from "shared/hooks/useErrorToastWithAudio";
import { isNotNullNorUndefined, isNullOrUndefined } from "utils/tsHelpers";

import { BbdCheckMachineContext, BbdCheckMachineServices } from "./types";

export function useBbdCheckActionsImplems() {
  const { showToastUI } = useCustomToast();

  const { sendSegmentTrackEvent } = useAnalytics();
  const showTaskCompletedToast = useTaskCompletedToast();
  const { showToastAndPlayError } = useErrorToastWithAudio({ errorToastId: "stock_machine_error" });
  const { showCheckAlreadyCompletedToast } = useCheckAlreadyCompletedToast();
  const { showCheckSessionExpiredToast } = useCheckSessionExpiredToast();
  const { navigateToCheck, navigateToOrigin } = useChecksRedirect();

  const sendTrackBbdCheckStarted = useCallback(
    (ctx: BbdCheckMachineContext) => {
      if (isNullOrUndefined(ctx.sku)) {
        return;
      }
      sendSegmentTrackEvent("stockCheckStarted", {
        type: "automated",
        check_id: ctx.checkId,
        product_sku: ctx.sku,
        origin: ctx.origin,
      });
    },
    [sendSegmentTrackEvent],
  );

  const sendTrackBbdCheckFinished = useCallback(
    (ctx: BbdCheckMachineContext) => {
      const nextExpirationDate = ctx.closestBbd
        ? dayjs(ctx.closestBbd).format("YYYY-MM-DD")
        : undefined;
      sendSegmentTrackEvent("stockCheckFinished", {
        type: "automated",
        check_id: ctx.checkId,
        product_sku: ctx.sku,
        quantity_expected: ctx.expectedQuantity,
        quantity_counted: 0,
        quantity_damaged: 0,
        quantity_expired: ctx.expiredQuantity,
        quantity_tgtg: ctx.tooGoodToGoQuantity,
        next_expiration_date: nextExpirationDate,
      });
    },
    [sendSegmentTrackEvent],
  );
  const sendTrackOnError = useCallback(() => {
    sendSegmentTrackEvent("errorShown", {
      screen_name: PageName.INVENTORY_BBD_CHECK_LIST_PAGE,
      component_value: ErrorTypeForAnalytics.UNKNOWN,
    });
  }, [sendSegmentTrackEvent]);

  const checkSessionExpiredToast = useCallback(() => {
    showCheckSessionExpiredToast();
  }, [showCheckSessionExpiredToast]);

  const taskCompletedToast = useCallback(() => {
    showTaskCompletedToast();
  }, [showTaskCompletedToast]);

  const taskAlreadyCompletedToast = useCallback(() => {
    showCheckAlreadyCompletedToast();
  }, [showCheckAlreadyCompletedToast]);

  const stockCheckCreatedToast = useCallback(() => {
    showToastUI({
      variant: "completed",
      title: "flows.inventory.bbd-checks.machines.actions.check-created-toast.body",
      duration: 2000,
    });
  }, [showToastUI]);

  const errorToast = useCallback(() => {
    showToastAndPlayError({ title: "hooks.use-custom-toast.error-title" });
  }, [showToastAndPlayError]);

  const redirectToOrigin = useCallback(
    (context: BbdCheckMachineContext) => navigateToOrigin(context.origin),
    [navigateToOrigin],
  );

  const redirectToCheck = useCallback(
    (
      context: BbdCheckMachineContext,
      event: BbdCheckMachineServices["fetchNextCheckAndStartCheck"],
    ) => {
      if ("check" in event.data && isNotNullNorUndefined(event.data.check)) {
        navigateToCheck(event.data.check, context.origin);
      }
    },
    [navigateToCheck],
  );

  return {
    redirectToOrigin,
    taskCompletedToast,
    sendTrackBbdCheckFinished,
    sendTrackBbdCheckStarted,
    errorToast,
    redirectToCheck,
    sendTrackOnError,
    stockCheckCreatedToast,
    taskAlreadyCompletedToast,
    checkSessionExpiredToast,
  };
}
