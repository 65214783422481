import { useCallback, useMemo } from "react";

import { Box, Flex } from "@chakra-ui/react";
import { useNavigate } from "react-router";
import shallow from "zustand/shallow";

import { useEppoFeatureFlagsProvider } from "core/providers/CustomEppoProvider";
import { useFeatureFlagOverridesStore } from "core/stores/useFeatureFlagOverridesStore";
import { EppoFeatureFlags, EppoFeatureVariants } from "core/types/flags";
import { Page } from "shared/components/Page";
import { Button } from "ui/Button/Button";
import { DropDown } from "ui/DropDown/DropDown";
import { DropDownOption } from "ui/DropDownList/DropDownList";
import { NavigationHeader } from "ui/NavigationHeader/NavigationHeader";
import { Status } from "ui/Status/Status";
import { BodyS, TitleS, TitleM } from "ui/Typography/Typography";
import { isNullOrUndefined } from "utils/tsHelpers";

const localFeatureFlagNames = Object.keys(
  EppoFeatureFlags,
).sort() as (keyof typeof EppoFeatureFlags)[];

type FeatureFlag = {
  key: EppoFeatureFlags;
  name: keyof typeof EppoFeatureFlags;
  evaluatedRemoteDecision: boolean | null;
  localOverride: boolean | null;
};

const bgColors: Record<string, string> = {
  true: "green.400",
  false: "red.400",
};

const overrideValueToString = (v: boolean | null) => {
  return v === null ? "null" : v.toString();
};

const localOverrideOptions: DropDownOption[] = [
  {
    key: "null",
    label: "none",
  },
  {
    key: "true",
    label: "true",
  },
  {
    key: "false",
    label: "false",
  },
];

export function FeatureFlagConfig() {
  const navigate = useNavigate();
  const {
    overridesMap: allOverrides,
    setFeatureFlagOverrides,
    resetOverrides,
  } = useFeatureFlagOverridesStore(
    (state) => ({
      overridesMap: state.overridesMap,
      setFeatureFlagOverrides: state.setFeatureFlagOverrides,
      resetOverrides: state.resetOverrides,
    }),
    shallow,
  );

  const { decisions: allFFDecisions, isLoading } = useEppoFeatureFlagsProvider();

  const setOverrideValue = useCallback(
    (key: string, v: "null" | "true" | "false") => {
      setFeatureFlagOverrides(key, v === "null" ? null : v === "true");
    },
    [setFeatureFlagOverrides],
  );

  const featureFlags: FeatureFlag[] = useMemo(() => {
    if (isNullOrUndefined(allFFDecisions)) {
      return [];
    }
    return localFeatureFlagNames.map((name) => {
      const key = EppoFeatureFlags[name];
      const localOverride = typeof allOverrides[key] === "boolean" ? allOverrides[key] : null;
      const evaluatedRemoteDecision = allFFDecisions[key]
        ? allFFDecisions[key] === EppoFeatureVariants.ON
        : null;

      return {
        key,
        name,
        evaluatedRemoteDecision,
        localOverride,
      };
    });
  }, [allFFDecisions, allOverrides]);

  return (
    <Page
      isLoading={isLoading}
      isFull
      isBgGrey
      minH="100%"
      alignItems="flex-start"
      justifyContent="flex-start"
      data-testid="feature-flag-config-page"
    >
      <NavigationHeader
        title="Feature Flag Local Config"
        onClickGoBack={() => {
          navigate("/");
        }}
      />
      {!isLoading && (
        <Flex py="s200" gap="s200" direction="column" w="100%">
          <Button mx="s200" onClick={resetOverrides} data-testid="remove-all-overrides">
            Remove all local overrides!
          </Button>

          {featureFlags!.map((featureFlag) => (
            <Box key={featureFlag.key} bgColor="white" p="s200">
              <TitleM>{featureFlag.name}</TitleM>
              <BodyS mb="s100">{featureFlag.key}</BodyS>
              <Flex justifyContent="space-between">
                <Flex direction="column" flex={1}>
                  <TitleS>Eppo Value:</TitleS>
                  <Status
                    maxW="fit-content"
                    label={
                      featureFlag.evaluatedRemoteDecision === null
                        ? "null"
                        : featureFlag.evaluatedRemoteDecision.toString()
                    }
                    size="small"
                    bgColor={
                      featureFlag.localOverride !== null
                        ? undefined
                        : bgColors[
                            featureFlag.evaluatedRemoteDecision === null
                              ? "null"
                              : featureFlag.evaluatedRemoteDecision.toString()
                          ]
                    }
                  />
                </Flex>

                <Flex direction="column" flex={1}>
                  <TitleS>Local Override:</TitleS>
                  <DropDown
                    filterMode={false}
                    options={localOverrideOptions}
                    value={overrideValueToString(featureFlag.localOverride)}
                    onInput={(v) =>
                      setOverrideValue(featureFlag.key, v as "null" | "true" | "false")
                    }
                    bgColor={
                      featureFlag.localOverride === null
                        ? "gray.100"
                        : bgColors[featureFlag.localOverride.toString()]
                    }
                    maxW="fit-content"
                    testId={`drop-down-${featureFlag.key}`}
                  />
                </Flex>
              </Flex>
            </Box>
          ))}
        </Flex>
      )}
    </Page>
  );
}
