import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { Box, BoxProps, chakra, Flex, InputProps, Grid, SlideFade } from "@chakra-ui/react";

import { TranslatedProductSearchUnit, UnitType } from "shared/models/productSearchUnit/types";
import { CheckButton } from "ui/CheckButton/CheckButton";
import { WarningRoundIcon, WarningThreeIcon } from "ui/Icons/Icons";
import { InboundFruitOrVeggyChips } from "ui/InboundFruitOrVeggyChips/InboundFruitOrVeggyChips";
import { ItemsExpectedBar } from "ui/ItemsExpectedBar/ItemsExpectedBar";
import { Number } from "ui/Number/Number";
import { ProductInformation } from "ui/ProductInformation/ProductInformation";
import { ProductQuantityInput } from "ui/ProductQuantityInput/ProductQuantityInput";
import { Shelf } from "ui/Shelf/Shelf";
import { Status } from "ui/Status/Status";
import { TitleS } from "ui/Typography/Typography";
import { isNotNullNorUndefined } from "utils/tsHelpers";

const getPartiallyInboundedBarProps = (animated: boolean) =>
  animated ? { bg: "purple.500", transition: "all 1s", opacity: "0" } : { bg: "purple.500" };

export type InboundPreDroppingListItemCardTranslatedLabelsProps = {
  singleUnitsLabel: string;
  handlingUnitsLabel: string;
  totalUnitsLabel?: string;
  inboundedLabel: string;
  bioLabel: string;
  nonBioLabel: string;
  partialOutboundLabel: string;
  outboundedLabel: string;
  outboundLabel: string;
};

export type QuantityFieldProps = {
  inputProps: InputProps;
  onSubmitForm: React.FormEventHandler;
};

export type TranslatedInboundUnit = Pick<
  TranslatedProductSearchUnit,
  | "shelfLetter"
  | "shelfNumber"
  | "productName"
  | "productSku"
  | "productImageUrl"
  | "countryOfOriginName"
  | "isProductBio"
  | "ean"
>;

export type InboundPreDroppingListItemCardProps = {
  elementRef?: (element: HTMLDivElement) => void;
  translatedInboundUnit: TranslatedInboundUnit;
  stockUpdatePlanTotal?: number;
  quantityFieldProps?: QuantityFieldProps;
  isPartiallyOutbounded?: boolean;
  showPartiallyOutboundedAnimation?: boolean;
  isOutbounded?: boolean;
  displayQuantity: number;
  isStockUpdateComplete: boolean;
  displayAsHandlingUnit: boolean;
  isEditingQuantity?: boolean;
  isEditingTotal?: boolean;
  showExpectedItems?: boolean;
  expectedUnitType?: UnitType;
  expectedNumberOfUnits?: number;
  showQuantityConfirmation?: boolean;
  isQuantityMatchingExpectation?: boolean | null; // null means user hasn't finalized the quantity
  isTotalQuantityMatchingExpectation?: boolean;
  isEnabledFilter?: boolean;
  onClickTotalQuantityNumber?: () => void;
  onConfirmQuantity?: () => void;
  totalQuantityFieldProps?: QuantityFieldProps;
} & InboundPreDroppingListItemCardTranslatedLabelsProps &
  BoxProps;

export function InboundPreDroppingListItemCard({
  elementRef,
  translatedInboundUnit: {
    shelfLetter,
    shelfNumber,
    productName,
    productSku,
    productImageUrl,
    countryOfOriginName,
    isProductBio,
    ean,
  },
  displayQuantity,
  displayAsHandlingUnit,
  inboundedLabel,
  singleUnitsLabel,
  handlingUnitsLabel,
  bioLabel,
  nonBioLabel,
  outboundedLabel,
  outboundLabel,
  partialOutboundLabel,
  stockUpdatePlanTotal,
  totalUnitsLabel,
  quantityFieldProps,
  isPartiallyOutbounded = false,
  showPartiallyOutboundedAnimation = false,
  isOutbounded = false,
  isStockUpdateComplete = false,
  isEditingQuantity = false,
  isEditingTotal = false,
  showExpectedItems = false,
  expectedUnitType,
  expectedNumberOfUnits,
  showQuantityConfirmation = false,
  isQuantityMatchingExpectation,
  isTotalQuantityMatchingExpectation = true,
  isEnabledFilter = true,
  onClickTotalQuantityNumber = () => {},
  onConfirmQuantity = () => {},
  totalQuantityFieldProps,
  ...rest
}: InboundPreDroppingListItemCardProps) {
  const shouldShowTotalHandlingUnits =
    displayAsHandlingUnit && isNotNullNorUndefined(stockUpdatePlanTotal);

  const isFruitOrVeggy = isNotNullNorUndefined(countryOfOriginName);

  const partiallyOutboundedAnimTimeout = useRef<NodeJS.Timeout | null>(null);

  const [partiallyOutboundedAnimIn, setPartiallyOutboundedAnimIn] = useState(
    showPartiallyOutboundedAnimation,
  );
  const [productInfoLeftSideBarProps, setProductInfoLeftSideBarProps] = useState<BoxProps>(() =>
    getPartiallyInboundedBarProps(showPartiallyOutboundedAnimation),
  );

  const onPartiallyOutboundedAnimationComplete = useCallback(() => {
    partiallyOutboundedAnimTimeout.current = setTimeout(() => {
      setPartiallyOutboundedAnimIn(false);
      setProductInfoLeftSideBarProps({ ...productInfoLeftSideBarProps, opacity: "1" });
      partiallyOutboundedAnimTimeout.current = null;
    }, 1000);
  }, [setPartiallyOutboundedAnimIn, setProductInfoLeftSideBarProps, productInfoLeftSideBarProps]);

  useEffect(() => {
    return () => {
      // Cleanup if animation is not finished when component unmounts
      if (partiallyOutboundedAnimTimeout.current) {
        clearTimeout(partiallyOutboundedAnimTimeout.current);
      }
    };
  }, []);

  const displayedStatus = useMemo(() => {
    if (isOutbounded) {
      return (
        <Status
          bg="purple.500"
          textColor="white"
          label={isStockUpdateComplete ? outboundedLabel : outboundLabel}
        />
      );
    }
    if (isStockUpdateComplete) {
      return <Status bg="veggie.500" textColor="white" label={inboundedLabel} />;
    }
    if (isFruitOrVeggy) {
      return (
        <InboundFruitOrVeggyChips
          bioLabel={bioLabel}
          nonBioLabel={nonBioLabel}
          countryOfOriginName={countryOfOriginName}
          isBio={!!isProductBio}
        />
      );
    }
    return null;
  }, [
    isStockUpdateComplete,
    isOutbounded,
    isFruitOrVeggy,
    bioLabel,
    nonBioLabel,
    countryOfOriginName,
    isProductBio,
    inboundedLabel,
    outboundedLabel,
    outboundLabel,
  ]);

  const quantityDisplay = useMemo(() => {
    if (isEditingTotal) {
      return (
        <Number
          content={displayQuantity.toString()}
          color="black"
          bgColor="white"
          data-testid="product-number-of-units"
        />
      );
    }

    if (isStockUpdateComplete) {
      return (
        <Number
          content={displayQuantity.toString()}
          color="grey.800"
          bgColor="grey.100"
          data-testid="unit-quantity"
        />
      );
    }

    if (!quantityFieldProps) {
      return (
        <Number
          content={displayQuantity.toString()}
          color="grey.800"
          bgColor={displayAsHandlingUnit ? "orange.100" : "marine.100"}
          data-testid="unit-quantity"
        />
      );
    }
    return (
      <chakra.form onSubmit={quantityFieldProps.onSubmitForm}>
        <ProductQuantityInput
          displayAsHandlingUnit={displayAsHandlingUnit}
          {...quantityFieldProps.inputProps}
          hasWarning={isQuantityMatchingExpectation === false}
          data-testid="product-quantity-input"
        />
      </chakra.form>
    );
  }, [
    isEditingTotal,
    isStockUpdateComplete,
    quantityFieldProps,
    displayAsHandlingUnit,
    isQuantityMatchingExpectation,
    displayQuantity,
  ]);

  const displayedShelf =
    isNotNullNorUndefined(shelfLetter) && isNotNullNorUndefined(shelfNumber) ? (
      <Shelf gridColumnStart="3" letter={shelfLetter} number={shelfNumber} />
    ) : (
      <Status bg="grey.200" textColor="black" gridColumnStart="3" label="N/A" />
    );

  function quantityConfirmation() {
    if (isEditingTotal || isEditingQuantity) return null;
    if (isQuantityMatchingExpectation !== false) {
      return <CheckButton checked={!!isQuantityMatchingExpectation} onClick={onConfirmQuantity} />;
    }
    return (
      <WarningRoundIcon
        color="red.500"
        w="32px"
        h="40px"
        data-testid="quantity-expectation-warning"
      />
    );
  }

  return (
    <Box
      ref={elementRef}
      p="s200"
      bg="white"
      filter={isEnabledFilter && isStockUpdateComplete ? "grayscale(100%)" : undefined}
      data-testid={`pre-droppping-product-card-${ean}`}
      {...rest}
    >
      <Grid position="relative" mb="s200" templateColumns="min-content 1fr min-content">
        {displayedStatus}
        {showPartiallyOutboundedAnimation && (
          <Box position="absolute" left="0" right="0">
            <SlideFade
              onAnimationComplete={onPartiallyOutboundedAnimationComplete}
              unmountOnExit
              in={partiallyOutboundedAnimIn}
              offsetX="-300"
              offsetY={0}
            >
              <Status
                bg="purple.500"
                textColor="white"
                label={partialOutboundLabel}
                whiteSpace="nowrap"
              />
            </SlideFade>
          </Box>
        )}
        {displayedShelf}
      </Grid>
      <ProductInformation
        bg="grey.100"
        name={productName ?? ""}
        sku={productSku}
        imageUrl={productImageUrl ?? ""}
        showLeftSideBar={isPartiallyOutbounded || isStockUpdateComplete}
        leftSideBarProps={
          isPartiallyOutbounded ? productInfoLeftSideBarProps : { bg: "veggie.500" }
        }
      />
      {showExpectedItems && (
        <ItemsExpectedBar
          isUnitTypeHandlingUnit={expectedUnitType === "handling"}
          numberOfUnits={expectedNumberOfUnits ?? 0}
        />
      )}
      <Grid templateColumns="1fr 1fr min-content" mt="s200">
        <Flex direction="column">
          <TitleS mb="s50" color="grey.800">
            {displayAsHandlingUnit ? handlingUnitsLabel : singleUnitsLabel}
          </TitleS>
          <Flex gap="s150">
            {quantityDisplay}
            {showQuantityConfirmation && quantityConfirmation()}
          </Flex>
        </Flex>
        {shouldShowTotalHandlingUnits && (
          <Flex gridColumnStart={3} flex={1}>
            <Flex direction="column" alignItems="center">
              <TitleS mb="s50" color="grey.800">
                {totalUnitsLabel}
              </TitleS>
              {isEditingTotal ? (
                <chakra.form onSubmit={totalQuantityFieldProps?.onSubmitForm}>
                  <ProductQuantityInput
                    displayAsHandlingUnit
                    {...totalQuantityFieldProps?.inputProps}
                    data-testid="total-quantity-input"
                  />
                </chakra.form>
              ) : (
                <Number
                  color={isTotalQuantityMatchingExpectation ? "black" : "red.500"}
                  bgColor="white"
                  content={stockUpdatePlanTotal.toString()}
                  onClick={onClickTotalQuantityNumber}
                  data-testid="product-handling-units-total-value"
                />
              )}
            </Flex>
            {!isTotalQuantityMatchingExpectation && (
              <WarningThreeIcon
                color="red.500"
                h="100%"
                w="20px"
                pt="23px"
                data-testid="total-quantity-warning"
              />
            )}
          </Flex>
        )}
      </Grid>
    </Box>
  );
}
