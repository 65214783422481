import { groupBy } from "lodash";

import { AppLanguage } from "core/stores/useAppLanguageStore";
import { countryNameFromCode } from "libs/countryNames";
import { getShelfDetail } from "utils/item";

import {
  AllPrioritiesChecksList,
  Check,
  PriorityTag,
  RawCheck,
  RawCheckWithProduct,
  TranslatedCheck,
} from "./types";

export const tagToPriority = {
  low: [0, 1],
  medium: [2],
  high: [3, 4, 5, 6],
} as Record<PriorityTag, number[]>;

export const getPriorityGroup = (priority: number) => {
  if (priority < 2) {
    return tagToPriority.low;
  }
  if (priority < 3) {
    return tagToPriority.medium;
  }
  return tagToPriority.high;
};

export const priorityToTag = {
  0: "low",
  1: "low",
  2: "medium",
  3: "high",
  4: "high",
  5: "high",
  6: "high",
} as Record<number, PriorityTag>;

export const serializeCheck = (check: RawCheckWithProduct): Check => {
  const shelfDetail = check.shelfNumber ? getShelfDetail(check.shelfNumber) : null;
  const status = "status" in check ? check.status ?? null : null;

  return {
    checkId: check.id ?? null,
    productImageUrl: check.product?.imageUrl,
    productCountryOfOriginCode: check.product?.countryOfOrigin?.code?.toLocaleUpperCase() ?? null,
    productName: check.product?.name ?? null,
    shelfNumber: shelfDetail?.shelfNumber ?? null,
    shelfLetter: shelfDetail?.shelfLetter ?? null,
    sku: check.product?.sku ?? null,
    status,
    type: check.type ?? null,
    priority: check.priority ?? null,
    minDaysToBestBeforeDate: check.product?.minDaysToBestBeforeDate ?? null,
  };
};

export const serializeAndTranslateCheck = (
  rawCheck: RawCheckWithProduct,
  lang: AppLanguage,
): TranslatedCheck => {
  const check = serializeCheck(rawCheck);
  return {
    ...check,
    productCountryOfOriginName: countryNameFromCode(lang, check.productCountryOfOriginCode),
  };
};

const getShelfObjectFromPrioritizedChecks = (
  prioritizedChecks: (RawCheck & { priorityTag: PriorityTag })[],
) => {
  // group checks by shelfNumber
  const shelfedPrioritizedChecks = groupBy(prioritizedChecks, "shelfNumber");

  // structure object according to output
  const shelves = Object.entries(shelfedPrioritizedChecks).map(([shelfNumber, checks]) => {
    const checksMaxPriority = Math.max(...checks.map((c) => c.priority));
    return {
      shelfNumber,
      sortIndex: checksMaxPriority * 10 + (checks.length > 1 ? 0 : 5),
      checks: checks.map((c) => ({ type: c.type })),
    };
  });

  return {
    checkCount: prioritizedChecks?.length ?? 0,
    shelves,
  };
};
export const serializeChecksList = (checksList: RawCheck[]): AllPrioritiesChecksList => {
  // add priority tag to checks from definitions, and group
  const checksWithTag = checksList.map((c) => ({ ...c, priorityTag: priorityToTag[c.priority] }));
  const checksByTag = groupBy(checksWithTag, "priorityTag");

  // structure each priority tag
  return {
    high: getShelfObjectFromPrioritizedChecks(checksByTag.high),
    medium: getShelfObjectFromPrioritizedChecks(checksByTag.medium),
    low: getShelfObjectFromPrioritizedChecks(checksByTag.low),
  };
};
