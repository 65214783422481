import { useEffect, useRef, useState } from "react";

type UseIsCardClickedProps = {
  onClickCallback?: () => void;
};

export function useIsCardClicked({ onClickCallback }: UseIsCardClickedProps) {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const [isClicked, setIsClicked] = useState(false);

  const onClickCard = () => {
    setIsClicked(true);
    timeoutRef.current = setTimeout(() => {
      setIsClicked(false);
      if (onClickCallback) {
        onClickCallback();
      }
    }, 100);
  };

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return {
    isClicked,
    onClickCard,
  };
}
