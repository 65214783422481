import { IDeliveryCheckin, IDeliveryCheckinV2 } from "__graphql__/types";

import { DeliveryCheckIn, DeliveryCheckInV2 } from "./types";

export const serializeDeliveryCheckIn = ({
  id,
  categories,
  supplier,
  delivered_at,
}: Pick<IDeliveryCheckin, "id" | "categories" | "supplier" | "delivered_at">): DeliveryCheckIn => ({
  id,
  categories: categories ?? [],
  deliveredAt: new Date(delivered_at),
  supplier: {
    id: supplier.id,
    name: supplier.name,
  },
});

export const serializeDeliveryCheckInV2 = ({
  id,
  supplier,
  deliveredAt,
  numberOfAcceptedRollies,
}: Pick<
  IDeliveryCheckinV2,
  "id" | "supplier" | "deliveredAt" | "numberOfAcceptedRollies"
>): DeliveryCheckInV2 => ({
  id,
  deliveredAt: new Date(deliveredAt),
  supplier,
  numberOfAcceptedRollies,
});
