import React, { ReactElement, cloneElement } from "react";

import { Flex, FlexProps } from "@chakra-ui/react";

import { BodyS } from "ui/Typography/Typography";

type TableLineProps = {
  label: string;
  rightElem: string | ReactElement;
  rightElemTestId?: string;
} & FlexProps;

export function TableLine({ label, rightElem, rightElemTestId, ...rest }: TableLineProps) {
  return (
    <Flex
      w="100%"
      justifyContent="space-between"
      alignItems="center"
      height="50px"
      borderBottomWidth="3px"
      borderBottomStyle="solid"
      borderBottomColor="grey.100"
      {...rest}
    >
      <BodyS color="grey.500">{label}</BodyS>
      {typeof rightElem === "string" ? (
        <BodyS data-testid={rightElemTestId}>{rightElem}</BodyS>
      ) : (
        cloneElement(rightElem, { "data-testid": rightElemTestId })
      )}
    </Flex>
  );
}
