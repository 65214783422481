import { useCallback, useEffect, useMemo } from "react";

import { Box } from "@chakra-ui/react";
import { FormattedMessage, useIntl } from "react-intl";
import { generatePath, useNavigate } from "react-router-dom";

import { routes } from "config/routes";
import { useEmployeeStore } from "core/stores/useEmployeeStore";
import { useLayoutStore } from "core/stores/useLayoutStore";
import { timedActivities } from "flows/Activities/models/timedActivity/config";
import { useCountUpTimer } from "shared/hooks/useCountUpTimer/useCountUpTimer";
import { useTimedActivityStore } from "shared/stores/useTimedActivityStore";
import { DetailL } from "ui/Typography/Typography";
import { formatDuration } from "utils/formats";
import { isNullOrUndefined, isNotNullNorUndefined } from "utils/tsHelpers";
import { transformChunks } from "utils/ui";

export function OngoingTimedActivityBanner() {
  const navigate = useNavigate();
  const intl = useIntl();

  const withOngoingActivityBanner = useLayoutStore((state) => state.withOngoingActivityBanner);
  const isIdentified = useEmployeeStore((state) => state.isIdentified);
  const timedActivityInstance = useTimedActivityStore((state) => state.timedActivityInstance);

  const { elapsedTimeInMs, startTimer, stopTimer } = useCountUpTimer();

  useEffect(() => {
    if (isNotNullNorUndefined(timedActivityInstance)) {
      startTimer(timedActivityInstance.startTime);
    } else {
      stopTimer();
    }
  }, [timedActivityInstance, startTimer, stopTimer]);

  const onClickBanner = useCallback(() => {
    navigate(
      generatePath(routes.activities.timedActivities.details, {
        activityId: timedActivityInstance?.activityID,
      }),
    );
  }, [timedActivityInstance, navigate]);

  const timedActivityName = useMemo(() => {
    const nameKey = timedActivities.find(
      (timedActivity) => timedActivity.id === timedActivityInstance?.activityID,
    )?.nameKey;
    return isNullOrUndefined(nameKey) ? "" : intl.formatMessage({ id: nameKey });
  }, [timedActivityInstance?.activityID, intl]);

  if (!timedActivityInstance || !isIdentified || !withOngoingActivityBanner) {
    return null;
  }

  return (
    <Box
      onClick={onClickBanner}
      w="100%"
      textAlign="center"
      paddingY="s100"
      bg="pinkFlink.100"
      data-testid="ongoing-activity-banner"
    >
      <DetailL>
        <FormattedMessage
          id="core.components.ongoing-timed-activity-banner.title"
          values={{
            ...transformChunks({ b: { as: "strong" } }),
            elapsedTime: formatDuration(elapsedTimeInMs),
            timedActivityCategoryName: timedActivityName,
          }}
        />
      </DetailL>
    </Box>
  );
}
