import { Flex, FlexProps } from "@chakra-ui/react";

import { Spinner } from "ui/Spinner/Spinner";

export type PageProps = FlexProps & {
  isCentered?: boolean; // controls wether the content should be centered
  isLoading?: boolean;
  isFull?: boolean; // if true, won't apply padding on the page, otherwise will apply default padding
  isBgGrey?: boolean;
};

export function Page({
  children,
  isLoading,
  isCentered,
  isFull = false,
  isBgGrey = false,
  ...rest
}: PageProps) {
  const justifyContent = isLoading || !rest.justifyContent ? "center" : rest.justifyContent;

  return (
    <Flex
      direction="column"
      alignItems="center"
      width="100%"
      height={isCentered || isLoading ? "100%" : undefined}
      flex={1}
      paddingY={!isFull ? "s100" : undefined}
      paddingX={!isFull ? "s200" : undefined}
      bg={isBgGrey ? "grey.150" : undefined}
      {...rest}
      justifyContent={justifyContent}
    >
      {isLoading ? <Spinner data-testid="spinner" /> : children}
    </Flex>
  );
}
