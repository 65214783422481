import { useEffect } from "react";

import { routes } from "config/routes";
import { Page } from "shared/components/Page";

import { TimedActivitiesList } from "../components/TimedActivitiesList";

// This page will be deleted when implementing the Timed activities V2, thus the simplicity of it

export function TimedActivitiesListPage() {
  useEffect(() => {
    window.history.pushState(null, "", routes.activities.root);
    window.history.pushState(null, "", routes.activities.timedActivities.list);
  }, []);

  return (
    <Page isBgGrey isFull flexDirection="column">
      <TimedActivitiesList />
    </Page>
  );
}
