import { useIntl } from "react-intl";

import { BoxIcon } from "ui/Icons/Icons";
import { IconTextPill } from "ui/IconTextPill/IconTextPill";
import { NumberTextPill } from "ui/NumberTextPill/NumberTextPill";
import { ProductInfoBar } from "ui/ProductInfoBar/ProductInfoBar";

type ItemsExpectedBarProps = {
  isUnitTypeHandlingUnit: boolean;
  numberOfUnits: number;
};

export function ItemsExpectedBar({ isUnitTypeHandlingUnit, numberOfUnits }: ItemsExpectedBarProps) {
  const intl = useIntl();
  const itemsExpectedLabel = intl.formatMessage({
    id: "flows.inbound.pre-dropping.product-information.items-expected",
  });

  const { unitsLabel, color } = isUnitTypeHandlingUnit
    ? {
        unitsLabel: intl.formatMessage(
          { id: "flows.inbound.pre-dropping.product-information.handling-units-label" },
          { count: numberOfUnits },
        ),
        color: "orange.500",
      }
    : {
        unitsLabel: intl.formatMessage(
          { id: "flows.inbound.pre-dropping.product-information.single-units-label" },
          { count: numberOfUnits },
        ),
        color: "marine.500",
      };

  const leftSide = (
    <IconTextPill icon={<BoxIcon color={color} boxSize="18px" />} text={itemsExpectedLabel} />
  );
  const rightSide = (
    <NumberTextPill
      number={numberOfUnits}
      text={unitsLabel}
      color={color}
      testIdNumber="number-of-expected-units"
      testIdText="expected-unit-type"
    />
  );

  return <ProductInfoBar leftSide={leftSide} rightSide={rightSide} />;
}
