import { useCallback } from "react";

import { useSelector } from "@xstate/react";

import { useInboundService } from "flows/Inbound/hooks/useInboundService";

import { EndDroppingProcessButton } from "./EndDroppingProcessButton";

export function StartDroppingButton() {
  const inboundService = useInboundService();

  const startDropping = useCallback(() => {
    inboundService.send({ type: "START_DROPPING" });
  }, [inboundService]);

  const isLoadingDroppingList = useSelector(inboundService, (state) => {
    return state.matches("inbounding.loadDroppingList");
  });

  return (
    <EndDroppingProcessButton
      shouldShowButton
      onClickButton={startDropping}
      labelMessageId="pages.inbound.inbound-index.start-dropping-button-label"
      isLoading={isLoadingDroppingList}
      bottom="0"
    />
  );
}
