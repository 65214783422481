import { v4 as uuidv4 } from "uuid";
import create from "zustand";

import { isNotNullNorUndefined } from "utils/tsHelpers";

export type TimedActivityInstance = {
  activityID: string;
  startTime: Date;
  activityInstanceID: string;
};

export type TimedActivityStore = {
  timedActivityInstance: TimedActivityInstance | null;
};

type TimedActivityStoreWithMethods = TimedActivityStore & {
  startTimedActivity(activityID: string): string | null;
  stopTimedActivity(): void;
};

const initialState: TimedActivityStore = {
  timedActivityInstance: null,
};

export const useTimedActivityStore = create<TimedActivityStoreWithMethods>()((set, get) => ({
  ...initialState,
  startTimedActivity(activityID) {
    const id = uuidv4();
    const runningActivityInstance = get().timedActivityInstance;
    if (isNotNullNorUndefined(runningActivityInstance)) {
      return null;
    }
    set({
      timedActivityInstance: {
        activityInstanceID: uuidv4(),
        startTime: new Date(),
        activityID,
      },
    });
    return id;
  },
  stopTimedActivity() {
    set(initialState);
  },
}));
