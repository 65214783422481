import * as Types from "../../../../../../__graphql__/types.js";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type ValidateBbdCheckMutationVariables = Types.Exact<{
  input: Types.ValidateBbdCheckInput;
}>;

export type ValidateBbdCheckMutation = {
  __typename?: "Mutation";
  validateBBDCheck: { __typename?: "CheckMutationResponse"; success: boolean };
};

export const ValidateBbdCheckDocument = gql`
  mutation ValidateBBDCheck($input: ValidateBBDCheckInput!) {
    validateBBDCheck(input: $input) {
      success
    }
  }
`;
export type ValidateBbdCheckMutationFn = Apollo.MutationFunction<
  ValidateBbdCheckMutation,
  ValidateBbdCheckMutationVariables
>;

/**
 * __useValidateBbdCheckMutation__
 *
 * To run a mutation, you first call `useValidateBbdCheckMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidateBbdCheckMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validateBbdCheckMutation, { data, loading, error }] = useValidateBbdCheckMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useValidateBbdCheckMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ValidateBbdCheckMutation,
    ValidateBbdCheckMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ValidateBbdCheckMutation, ValidateBbdCheckMutationVariables>(
    ValidateBbdCheckDocument,
    options,
  );
}
export type ValidateBbdCheckMutationHookResult = ReturnType<typeof useValidateBbdCheckMutation>;
export type ValidateBbdCheckMutationResult = Apollo.MutationResult<ValidateBbdCheckMutation>;
export type ValidateBbdCheckMutationOptions = Apollo.BaseMutationOptions<
  ValidateBbdCheckMutation,
  ValidateBbdCheckMutationVariables
>;
