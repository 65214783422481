import * as Types from "../../../../../../__graphql__/types.js";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type SaveSkuToPublicRestockingListMutationVariables = Types.Exact<{
  input: Types.RestockingItemInput;
}>;

export type SaveSkuToPublicRestockingListMutation = {
  __typename?: "Mutation";
  saveSkuToPublicRestockingList: {
    __typename?: "SaveSkuToPublicRestockingListResponse";
    success: boolean;
  };
};

export const SaveSkuToPublicRestockingListDocument = gql`
  mutation saveSkuToPublicRestockingList($input: RestockingItemInput!) {
    saveSkuToPublicRestockingList(input: $input) {
      success
    }
  }
`;
export type SaveSkuToPublicRestockingListMutationFn = Apollo.MutationFunction<
  SaveSkuToPublicRestockingListMutation,
  SaveSkuToPublicRestockingListMutationVariables
>;

/**
 * __useSaveSkuToPublicRestockingListMutation__
 *
 * To run a mutation, you first call `useSaveSkuToPublicRestockingListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveSkuToPublicRestockingListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveSkuToPublicRestockingListMutation, { data, loading, error }] = useSaveSkuToPublicRestockingListMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveSkuToPublicRestockingListMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveSkuToPublicRestockingListMutation,
    SaveSkuToPublicRestockingListMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SaveSkuToPublicRestockingListMutation,
    SaveSkuToPublicRestockingListMutationVariables
  >(SaveSkuToPublicRestockingListDocument, options);
}
export type SaveSkuToPublicRestockingListMutationHookResult = ReturnType<
  typeof useSaveSkuToPublicRestockingListMutation
>;
export type SaveSkuToPublicRestockingListMutationResult =
  Apollo.MutationResult<SaveSkuToPublicRestockingListMutation>;
export type SaveSkuToPublicRestockingListMutationOptions = Apollo.BaseMutationOptions<
  SaveSkuToPublicRestockingListMutation,
  SaveSkuToPublicRestockingListMutationVariables
>;
