import { useCallback } from "react";

import { Flex } from "@chakra-ui/react";
import { useSelector } from "@xstate/react";

import { InboundUnitsSearchResultsList } from "flows/Inbound/components/InboundUnitsSearchResultsList";
import { NoEANSubcategories } from "flows/Inbound/components/NoEANSubcategories/NoEANSubcategories";
import { ProductSearchBar } from "flows/Inbound/components/ProductSearchBar";
import { Page } from "shared/components/Page";
import { SpinnerModal } from "shared/components/SpinnerModal";
import { useProductSearchService } from "shared/hooks/useProductSearchService";

export function InboundManualSearch() {
  const productSearchService = useProductSearchService();

  const isLoading = useSelector(productSearchService, (state) =>
    state.matches("textSearch.loading"),
  );

  const isBrowsingSubcategories = useSelector(productSearchService, (state) => {
    return state.matches("noEAN.subcategories");
  });

  const shouldShowTextSearchResults = useSelector(productSearchService, (state) => {
    return state.matches("textSearch");
  });

  const activateTextSearch = useCallback(() => {
    if (isBrowsingSubcategories) {
      productSearchService.send({ type: "SWITCH_TO_TEXT_SEARCH" });
    }
  }, [isBrowsingSubcategories, productSearchService]);

  const cancelSearchFlow = useCallback(() => {
    productSearchService.send({ type: "GO_BACK" });
  }, [productSearchService]);

  return (
    <Page h="100%" pos="relative" isFull isBgGrey data-testid="inbound-manual-search-page">
      <Flex direction="column" justify="flex-start" h="100%" width="100%">
        <ProductSearchBar
          isSearchFlowActive
          activateTextSearch={activateTextSearch}
          cancelSearchFlow={cancelSearchFlow}
        />
        {shouldShowTextSearchResults ? <InboundUnitsSearchResultsList /> : <NoEANSubcategories />}
      </Flex>

      <SpinnerModal isOpen={isLoading} />
    </Page>
  );
}
