import { Flex, Image } from "@chakra-ui/react";
import { FormattedMessage } from "react-intl";

import ScanImage from "images/inbound/inbound-index-page-scan.png";
import { BOTTOM_TAB_BAR_HEIGHT_NUMBER } from "ui/BottomTabBarContainer";
import { BodyM, HeaderS } from "ui/Typography/Typography";

export function RolliScanPlaceholder() {
  return (
    <Flex
      flex={1}
      direction="column"
      h="100%"
      w="100%"
      pb={`${BOTTOM_TAB_BAR_HEIGHT_NUMBER}px`}
      alignItems="center"
      justifyContent="center"
      bg="white"
      data-testid="rolli-scan-placeholder"
    >
      <Flex maxW="296px" minH="272px" gap="24px" flexDirection="column" alignItems="center">
        <HeaderS>
          <FormattedMessage id="components.rolli-scan-placeholder.title" />
        </HeaderS>
        <BodyM textAlign="center" color="grey.800">
          <FormattedMessage id="components.rolli-scan-placeholder.subtitle" />
        </BodyM>
        <Image src={ScanImage} h="124px" w="124px" />
      </Flex>
    </Flex>
  );
}
