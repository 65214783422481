import { Flex, FlexProps, Input } from "@chakra-ui/react";

import { ArrowLeftIcon } from "ui/Icons/Icons";
import { TitleM } from "ui/Typography/Typography";

export type NavigationHeaderWithInputProps = {
  title: string;
  isDisabled?: boolean;
  showNavigationArrow?: boolean;
  onClickGoBack: () => void;
  onTitleChange?: (value: string) => void;
  rightElement?: React.ReactNode;
} & FlexProps;

export function NavigationHeaderWithInput({
  title,
  onClickGoBack,
  onTitleChange = () => {},
  rightElement,
  isDisabled = false,
  showNavigationArrow = true,
  ...rest
}: NavigationHeaderWithInputProps) {
  return (
    <Flex
      bg="white"
      p="s200"
      w="100%"
      alignItems="center"
      borderBottomWidth="1px"
      borderBottomColor="grey.200"
      {...rest}
    >
      {showNavigationArrow && (
        <ArrowLeftIcon
          data-testid="navigation-header-go-back-icon"
          cursor="pointer"
          boxSize="1.5rem"
          onClick={onClickGoBack}
        />
      )}
      {isDisabled ? (
        <TitleM flex={1} noOfLines={1} textOverflow="ellipsis" marginLeft="s100">
          {title}
        </TitleM>
      ) : (
        <Input
          data-testid="navigation-input-title"
          variant="unstyled"
          borderRadius="none"
          marginLeft="s100"
          value={title}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            onTitleChange(event.target.value)
          }
          textOverflow="ellipsis"
          fontSize="1rem"
          lineHeight="1.5rem"
          fontWeight={500}
          fontFamily="Flink-Medium"
          autoFocus
          _focus={{ boxShadow: "none" }}
          color="black"
        />
      )}
      {rightElement}
    </Flex>
  );
}
