import React, { useState, forwardRef } from "react";

import {
  Flex,
  Input,
  FormControl,
  InputGroup,
  InputRightElement,
  InputLeftElement,
  Button,
  FlexProps,
} from "@chakra-ui/react";

import { UnitType } from "shared/models/productSearchUnit/types";
import { BoxIcon, MagnifyIcon } from "ui/Icons/Icons";
import { isNotNullNorUndefined } from "utils/tsHelpers";

export type ProductSearchBarProps = {
  inputValue: string;
  onInputValueChange: (newValue: string, unitType: UnitType) => void;
  showUnitsToggle: boolean;
  unitType?: UnitType;
  placeholder?: string;
  singleUnitsPlaceholder?: string;
  handlingUnitsPlaceholder?: string;
  cancelButtonPlaceholder?: string;
  showCancelButton: boolean;
  isDisabled?: boolean;
  onClickCancel?: () => void;
  onFocusSearchBar: () => void;
  onChangeUnitType?: (unitType: UnitType) => void;
} & FlexProps;

const MAX_SEARCH_TERM_LENGTH = 50;

export const ProductSearchBar = forwardRef<HTMLInputElement, ProductSearchBarProps>(
  (
    {
      inputValue,
      onInputValueChange,
      showUnitsToggle,
      unitType = "single",
      placeholder = "",
      singleUnitsPlaceholder = "",
      handlingUnitsPlaceholder = "",
      cancelButtonPlaceholder = "",
      showCancelButton,
      isDisabled = false,
      onClickCancel = () => {},
      onFocusSearchBar,
      onChangeUnitType = () => {},
      ...rest
    },
    inputRef,
  ) => {
    const [isInputFocused, setIsInputFocused] = useState(false);

    const handleUnitToggleMouseDown = (event: React.MouseEvent<SVGElement>) => {
      // note: mousedown handler is triggered before click handler, we do the following
      // in order to not lose focus on the input if it was previously focused
      event.preventDefault();
    };

    const handleUnitToggleClick = () => {
      if (isNotNullNorUndefined(inputRef) && "current" in inputRef) {
        inputRef.current?.focus();
      }
      onChangeUnitType(unitType === "single" ? "handling" : "single");
    };

    const displayedPlaceholder = (() => {
      if (!showUnitsToggle) return placeholder;
      return unitType === "single" ? singleUnitsPlaceholder : handlingUnitsPlaceholder;
    })();

    const handleFocus = () => {
      setIsInputFocused(true);
      onFocusSearchBar();
    };

    return (
      <Flex justify="center" {...rest}>
        <FormControl>
          <InputGroup>
            <InputLeftElement>
              <MagnifyIcon color={isInputFocused ? "grey.800" : "grey.600"} h="24px" w="24px" />
            </InputLeftElement>
            <Input
              value={inputValue}
              onChange={(e) => onInputValueChange(e.currentTarget.value, unitType)}
              ref={inputRef}
              placeholder={displayedPlaceholder}
              onFocus={handleFocus}
              onBlur={() => setIsInputFocused(false)}
              bg="white"
              variant="filled"
              _focusVisible={{ bg: "white" }}
              _hover={{ bg: "white" }}
              autoComplete="off"
              maxLength={MAX_SEARCH_TERM_LENGTH}
              data-testid="product-search-input"
              disabled={isDisabled}
            />
            {showUnitsToggle && (
              <InputRightElement>
                <BoxIcon
                  onMouseDown={handleUnitToggleMouseDown}
                  onClick={handleUnitToggleClick}
                  h="24px"
                  w="24px"
                  color={unitType === "single" ? "marine.500" : "orange.400"}
                  data-testid="searchbar-unit-type-toggle"
                />
              </InputRightElement>
            )}
          </InputGroup>
        </FormControl>
        {showCancelButton && (
          <Button
            onClick={onClickCancel}
            variant="link"
            color="grey.600"
            ml="s200"
            _hover={{
              textDecoration: "none",
            }}
            _active={{
              textDecoration: "none",
            }}
          >
            {cancelButtonPlaceholder}
          </Button>
        )}
      </Flex>
    );
  },
);
