import { Flex, FlexProps, Image } from "@chakra-ui/react";
import { FormattedMessage } from "react-intl";

import ScanImage from "images/inbound/inbound-index-page-scan.png";
import { IntlMessageId } from "shared/types/lang";
import { BodyM, HeaderS } from "ui/Typography/Typography";
import { transformChunks } from "utils/ui";

export type ScanPlaceholderProps = {
  title: IntlMessageId;
  subtitle: IntlMessageId;
} & FlexProps;

export function ScanPlaceholder({ title, subtitle, ...rest }: ScanPlaceholderProps) {
  return (
    <Flex flexDir="column" alignItems="center" {...rest}>
      <HeaderS textAlign="center">
        <FormattedMessage id={title} />
      </HeaderS>
      <BodyM mt="s100" textAlign="center" data-testid="stock-correction-instructions">
        <FormattedMessage
          id={subtitle}
          values={transformChunks({
            highlight: { bgColor: "pinkFlink.200", borderRadius: "xs", px: "s50" },
          })}
        />
      </BodyM>
      <Image mt="s300" src={ScanImage} h="124px" w="124px" />
    </Flex>
  );
}
