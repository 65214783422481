import { Box, Flex, Image, useDisclosure } from "@chakra-ui/react";
import { useIntl } from "react-intl";

import { TaskType } from "__graphql__/types";
import { PageName } from "analytics/events";
import { ConfirmLargeQuantityChangeModal } from "flows/Inbound/components/ConfirmLargeQuantityChangeModal";
import { TaskCheckHeader } from "flows/Inventory/shared/components/TaskCheckHeader";
import { IncreaseDecrease } from "shared/components/IncreaseDecrease";
import { Page } from "shared/components/Page";
import { Button } from "ui/Button/Button";
import { BodyS } from "ui/Typography/Typography";
import { formatImage } from "utils/image";
import { productNameWithCountry } from "utils/product";
import { isNotNullNorUndefined } from "utils/tsHelpers";

import { useEoyChecksService } from "../hooks/useEoyChecksService";

const IMAGE_SIZE = 218;

type EoyCheckProps = {
  type: TaskType;
  origin: PageName;
};

export function EoyCheck({ type, origin }: EoyCheckProps) {
  const intl = useIntl();

  const { eoyCheck, validateEoyCheck, countedItems, updateItemQuantity } = useEoyChecksService({
    type,
    origin,
  });

  const isLoadingEoyCheck = eoyCheck === null;

  const {
    isOpen: isConfirmQuantityModalOpen,
    onOpen: openConfirmQuantityModal,
    onClose: closeConfirmQuantityModal,
  } = useDisclosure();

  const onCompleteCount = () => {
    validateEoyCheck();
    closeConfirmQuantityModal();
  };
  const imageUrlFormatted =
    eoyCheck && eoyCheck.productImageUrl
      ? formatImage(eoyCheck.productImageUrl, IMAGE_SIZE, IMAGE_SIZE)
      : null;
  const headerMessageId =
    origin === PageName.INVENTORY_EOY_CHECK_LIST_PAGE
      ? "flows.inventory.eoy-checks.pages.eoy-checks-list.title"
      : "flows.inventory.recount-checks.pages.recount-checks-list.title";
  return (
    <Page isCentered isBgGrey isFull isLoading={isLoadingEoyCheck}>
      <TaskCheckHeader
        headerMessageId={headerMessageId}
        origin={origin}
        shelfLetter={eoyCheck?.shelfLetter ?? ""}
        shelfNumber={eoyCheck?.shelfNumber ?? ""}
      />
      <Flex flex={1} flexDir="column" overflowY="scroll" w="100%">
        <ConfirmLargeQuantityChangeModal
          onClickConfirm={onCompleteCount}
          onClickDecline={closeConfirmQuantityModal}
          isOpen={isConfirmQuantityModalOpen}
          customeTitle={intl.formatMessage(
            { id: "components.inbound.confirm-quantity-eoy-modal.title" },
            { quantity: countedItems },
          )}
        />
        <Flex flex={1} align="center" flexDir="column" justify="center" px="s100">
          <Flex flex={1} align="center">
            {isNotNullNorUndefined(imageUrlFormatted) && (
              <Image
                src={imageUrlFormatted}
                objectFit="contain"
                h="60vh"
                maxW={`${IMAGE_SIZE}px`}
                maxH={`${IMAGE_SIZE}px`}
              />
            )}
          </Flex>
          <Box
            py="s100"
            px="s200"
            mb="s100"
            bg="white"
            borderRadius="sm"
            justifyContent="center"
            w="100%"
          >
            <BodyS
              w="100%"
              textAlign="center"
              color="grey.800"
              data-testid="stock-check-product-name"
            >
              {productNameWithCountry(
                eoyCheck?.productName ?? "",
                eoyCheck?.productCountryOfOriginName ?? "",
              )}
            </BodyS>
          </Box>
        </Flex>
        <Box bg="white" p="s200" gap="s50">
          <Box mx="s100">
            <IncreaseDecrease
              labelMessageId="components.increasedecrease.how_many_units"
              changeHandler={updateItemQuantity}
              count={countedItems}
              withInput
            />
          </Box>
          <Button
            size="lg"
            mt="s300"
            width="100%"
            onClick={() => openConfirmQuantityModal()}
            data-testid="submit-quantity-button"
          >
            {intl.formatMessage({ id: "pages.task.taskIndex.confirm_amount" })}
          </Button>
        </Box>
      </Flex>
    </Page>
  );
}
