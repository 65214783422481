import { Spinner as CharkaSpinner, SpinnerProps } from "@chakra-ui/react";

export function Spinner({ ...rest }: SpinnerProps) {
  return (
    <CharkaSpinner
      emptyColor="pinkFlink.500"
      color="pinkFlink.200"
      size="xl"
      thickness="4px"
      data-testid="spinner"
      {...rest}
    />
  );
}
