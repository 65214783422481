import * as Types from "../../../../__graphql__/types.js";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type GetSuppliersForHubQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GetSuppliersForHubQuery = {
  __typename?: "Query";
  getSuppliersForHub: {
    __typename?: "GetSuppliersForHubResponse";
    suppliers: Array<{ __typename?: "ISupplier"; id: string; name: string; sortSequence: number }>;
  };
};

export const GetSuppliersForHubDocument = gql`
  query getSuppliersForHub {
    getSuppliersForHub {
      suppliers {
        id
        name
        sortSequence
      }
    }
  }
`;

/**
 * __useGetSuppliersForHubQuery__
 *
 * To run a query within a React component, call `useGetSuppliersForHubQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSuppliersForHubQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSuppliersForHubQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSuppliersForHubQuery(
  baseOptions?: Apollo.QueryHookOptions<GetSuppliersForHubQuery, GetSuppliersForHubQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSuppliersForHubQuery, GetSuppliersForHubQueryVariables>(
    GetSuppliersForHubDocument,
    options,
  );
}
export function useGetSuppliersForHubLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSuppliersForHubQuery,
    GetSuppliersForHubQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSuppliersForHubQuery, GetSuppliersForHubQueryVariables>(
    GetSuppliersForHubDocument,
    options,
  );
}
export type GetSuppliersForHubQueryHookResult = ReturnType<typeof useGetSuppliersForHubQuery>;
export type GetSuppliersForHubLazyQueryHookResult = ReturnType<
  typeof useGetSuppliersForHubLazyQuery
>;
export type GetSuppliersForHubQueryResult = Apollo.QueryResult<
  GetSuppliersForHubQuery,
  GetSuppliersForHubQueryVariables
>;
