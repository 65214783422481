import { Flex, FlexProps } from "@chakra-ui/react";

import { HeaderM, TitleS } from "ui/Typography/Typography";

type ShelfLetterProps = {
  variant?: "big" | "small";
  letter: string;
} & FlexProps;

const VARIANT_BIG_SIZE = "36px";
const VARIANT_SMALL_SIZE = "24px";

const getColorConfig = (letter: string): { color?: string; backgroundColor: string } => {
  switch (letter) {
    case "B":
      return {
        color: "black",
        backgroundColor: "sunny.500",
      };
    case "C":
      return {
        backgroundColor: "veggie.500",
      };
    case "D":
      return {
        backgroundColor: "marine.500",
      };
    case "E":
      return {
        backgroundColor: "orange.500",
      };
    case "F":
      return {
        backgroundColor: "nightBlueFlink.500",
      };
    case "G":
      return {
        color: "black",
        backgroundColor: "grey.200",
      };
    case "H":
      return {
        backgroundColor: "red.500",
      };
    case "I":
      return {
        color: "black",
        backgroundColor: "pinkFlink.400",
      };
    case "J":
      return {
        backgroundColor: "purple.500",
      };
    case "K":
      return {
        color: "black",
        backgroundColor: "veggie.400",
      };
    case "L":
      return {
        color: "black",
        backgroundColor: "marine.200",
      };
    case "M":
      return {
        color: "black",
        backgroundColor: "orange.200",
      };
    case "N":
      return {
        backgroundColor: "grey.600",
      };
    case "O":
      return {
        color: "black",
        backgroundColor: "pinkFlink.200",
      };
    case "P":
      return {
        color: "black",
        backgroundColor: "purple.200",
      };
    default:
      return {
        backgroundColor: "pinkFlink.500",
      };
  }
};

export function ShelfLetter({ variant = "big", letter, ...rest }: ShelfLetterProps) {
  const { color, backgroundColor } = getColorConfig(letter);
  const letterColor = color || "white";
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      width={variant === "big" ? VARIANT_BIG_SIZE : VARIANT_SMALL_SIZE}
      height={variant === "big" ? VARIANT_BIG_SIZE : VARIANT_SMALL_SIZE}
      backgroundColor={backgroundColor}
      borderRadius={variant === "big" ? "sm" : "xs"}
      {...rest}
    >
      {variant === "big" ? (
        <HeaderM color={letterColor}>{letter}</HeaderM>
      ) : (
        <TitleS color={letterColor}>{letter}</TitleS>
      )}
    </Flex>
  );
}
