import { useCallback, useMemo } from "react";

import { Flex, Image } from "@chakra-ui/react";
import { sortBy } from "lodash";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router";

import { DeliveryCheckinProductCategory } from "__graphql__/types";
import { routes } from "config/routes";
import { openOngoingActivityModal } from "core/stores/useLayoutStore";
import { EppoFeatureFlags } from "core/types/flags";
import {
  serializeDeliveryCheckIn,
  serializeDeliveryCheckInV2,
} from "flows/Activities/models/deliveryCheckIn/serializer";
import { DeliveryCheckInType } from "flows/Activities/models/deliveryCheckIn/types";
import { DeliveryCategory } from "flows/Activities/pages/DeliveryCheckIn/DeliveryCheckIn";
import {
  useGetDeliveryCheckInsForHubDeliveredTodayQuery,
  useGetDeliveryCheckInsForHubDeliveredTodayV2Query,
} from "flows/Activities/queries/deliveryCheckin/deliveryCheckin.generated";
import DeliveryTruckColored from "images/random/delivery-truck-colored.svg";
import DeliveryTruck from "images/random/delivery-truck.svg";
import { useEppoFeatureFlagProvider } from "shared/hooks/useEppoFeatureFlag";
import { useTimedActivityStore } from "shared/stores/useTimedActivityStore";
import { Button } from "ui/Button/Button";
import { PlusSmallIcon } from "ui/Icons/Icons";
import { Spinner } from "ui/Spinner/Spinner";
import { BodyS, TitleM } from "ui/Typography/Typography";
import { isNotNullNorUndefined, isNullOrUndefined } from "utils/tsHelpers";

function DeliveryHistoryItems({
  deliveryHistory,
  isLoading = false,
}: {
  deliveryHistory: DeliveryCheckInType[];
  isLoading?: boolean;
}) {
  const intl = useIntl();

  const deliveryNumber = useCallback(
    (deliveryCheckIn: DeliveryCheckInType): number => {
      if (isNullOrUndefined(deliveryHistory)) {
        return 0;
      }
      return (
        deliveryHistory
          .filter(({ supplier }) => supplier.id === deliveryCheckIn.supplier.id)
          .findIndex(({ id }) => id === deliveryCheckIn.id) + 1
      );
    },
    [deliveryHistory],
  );

  const labelForCategory = useCallback(
    (category: DeliveryCheckinProductCategory): string => {
      const normalizedCategory = category.toLowerCase() as DeliveryCategory;
      return intl.formatMessage({
        id: `pages.activities.delivery-check-in-history.category-labels.${normalizedCategory}`,
      });
    },
    [intl],
  );

  if (isLoading) return <Spinner alignSelf="center" mb="s200" />;

  if (!deliveryHistory) return null;

  return (
    <>
      {deliveryHistory.map((deliveryCheckIn) => (
        <Flex
          key={deliveryCheckIn.id}
          mx="-s200"
          mb="s200"
          py="s200"
          bgColor="white"
          align="center"
        >
          <Image src={DeliveryTruck} borderRadius="sm" mx="s200" />
          <Flex direction="column" gap="s50">
            <TitleM noOfLines={2}>{deliveryCheckIn.supplier.name}</TitleM>
            <BodyS>
              {intl.formatMessage(
                { id: "pages.activities.delivery-check-in-history.delivery-number" },
                { n: deliveryNumber(deliveryCheckIn).toString() },
              )}
            </BodyS>
            <BodyS>
              {intl.formatMessage(
                {
                  id: "pages.activities.delivery-check-in-history.arrival-time",
                },
                {
                  arrivalTimePlaceholder: deliveryCheckIn.deliveredAt.toLocaleString("de-DE", {
                    timeStyle: "short",
                  }),
                },
              )}
            </BodyS>
            {"categories" in deliveryCheckIn && deliveryCheckIn.categories && (
              <BodyS>
                {`${intl.formatMessage({
                  id: "pages.activities.delivery-check-in-history.category",
                })}:
            ${deliveryCheckIn.categories.map(labelForCategory).join(", ")}`}
              </BodyS>
            )}
            {"numberOfAcceptedRollies" in deliveryCheckIn &&
              !isNullOrUndefined(deliveryCheckIn.numberOfAcceptedRollies) && (
                <BodyS>
                  {`${intl.formatMessage({
                    id: "pages.activities.delivery-check-in-history.rollies_accepted",
                  })}:
            ${deliveryCheckIn.numberOfAcceptedRollies}`}
                </BodyS>
              )}
          </Flex>
        </Flex>
      ))}
    </>
  );
}

function DeliveryCheckInsForHubDeliveredToday() {
  const { data: deliveryHistoryData, loading: isLoading } =
    useGetDeliveryCheckInsForHubDeliveredTodayQuery({
      fetchPolicy: "network-only",
    });

  const deliveryHistory = useMemo(() => {
    return sortBy(
      deliveryHistoryData?.getDeliveryCheckInsForHubDeliveredToday.deliveryCheckins?.map(
        serializeDeliveryCheckIn,
      ),
      (dci) => dci.deliveredAt,
    );
  }, [deliveryHistoryData]);

  return <DeliveryHistoryItems deliveryHistory={deliveryHistory} isLoading={isLoading} />;
}

function DeliveryCheckInsForHubDeliveredTodayV2() {
  const { data: deliveryHistoryData, loading: isLoading } =
    useGetDeliveryCheckInsForHubDeliveredTodayV2Query({
      fetchPolicy: "network-only",
    });

  const deliveryCheckins =
    deliveryHistoryData?.getDeliveryCheckInsForHubDeliveredTodayV2.deliveryCheckins;

  const deliveryHistory = useMemo(() => {
    return sortBy(deliveryCheckins?.map(serializeDeliveryCheckInV2), (dci) => dci.deliveredAt);
  }, [deliveryCheckins]);

  return <DeliveryHistoryItems deliveryHistory={deliveryHistory} isLoading={isLoading} />;
}

export function DeliveryCheckInHistory() {
  const navigate = useNavigate();
  const { isFeatureEnabled: isRolliValidationEnabled } = useEppoFeatureFlagProvider(
    EppoFeatureFlags.ROLLI_VALIDATION,
  );

  const timedActivityInstance = useTimedActivityStore((state) => state.timedActivityInstance);

  const goToDeliveryCheckIn = useCallback(() => {
    if (isNotNullNorUndefined(timedActivityInstance)) {
      openOngoingActivityModal();
      return;
    }
    navigate(routes.activities.deliveryCheckIn);
  }, [navigate, timedActivityInstance]);

  return (
    <Flex direction="column" p="s200" w="100%" data-testid="delivery-check-in-block">
      <TitleM my="s200">
        <FormattedMessage id="pages.activities.delivery-check-in-history.title" />
      </TitleM>

      <Flex bgColor="white" my="s200" p="s200" align="center" borderRadius="sm">
        <Image src={DeliveryTruckColored} />
        <BodyS ml="s300" my="s100">
          <FormattedMessage id="pages.activities.delivery-check-in-history.instructions" />
        </BodyS>
      </Flex>
      {isRolliValidationEnabled ? (
        <DeliveryCheckInsForHubDeliveredTodayV2 />
      ) : (
        <DeliveryCheckInsForHubDeliveredToday />
      )}
      <Button
        alignSelf="center"
        w="189px"
        flinkVariant="secondary"
        onClick={goToDeliveryCheckIn}
        data-testid="delivery-checkin-cta-button"
      >
        <PlusSmallIcon w="1.5rem" h="1.5rem" />
        <FormattedMessage id="pages.activities.delivery-check-in-history.cta-button" />
      </Button>
    </Flex>
  );
}
