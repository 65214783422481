import { Flex, IconButton } from "@chakra-ui/react";

import { ArrowLeftIcon, LocationIcon, ProfileIcon } from "ui/Icons/Icons";
import { NotificationPill } from "ui/NotificationPill/NotificationPill";
import { BodyS } from "ui/Typography/Typography";
import { isNotNullNorUndefined, isNullOrUndefined } from "utils/tsHelpers";

export type TopBarProps = {
  hubName?: string;
  onClickHubName?: () => void;
  userName?: string;
  showUserNameNotification?: boolean;
  onClickUserName?: () => void;
  goBack?: () => void;
};

export function TopBar({
  hubName,
  userName,
  goBack,
  onClickHubName,
  onClickUserName,
  showUserNameNotification = false,
}: TopBarProps) {
  return (
    <>
      {isNotNullNorUndefined(goBack) && (
        <IconButton
          variant="ghost"
          aria-label="Go back"
          icon={<ArrowLeftIcon boxSize="1.2rem" />}
          onClick={goBack}
        />
      )}
      {isNotNullNorUndefined(hubName) && (
        <Flex
          alignItems="center"
          flex="auto"
          overflow="hidden"
          justifyContent={isNullOrUndefined(goBack) ? "flex-start" : "center"}
          cursor={onClickHubName ? "pointer" : "auto"}
          onClick={onClickHubName ?? undefined}
        >
          <LocationIcon boxSize={5} fill="none" />
          <BodyS flex={1} w="0" noOfLines={1} textOverflow="ellipsis" marginLeft="s50">
            {hubName.toUpperCase()}
          </BodyS>
        </Flex>
      )}
      {isNotNullNorUndefined(userName) && (
        <Flex
          alignItems="center"
          marginLeft="s200"
          onClick={onClickUserName ?? undefined}
          cursor={onClickUserName ? "pointer" : "auto"}
          position="relative"
          data-testid="username-dropdown"
        >
          <ProfileIcon boxSize={5} fill="none" />
          <BodyS marginLeft="s50">{userName}</BodyS>
          {showUserNameNotification && (
            <NotificationPill position="absolute" top="-4px" right="-4px" />
          )}
        </Flex>
      )}
    </>
  );
}
