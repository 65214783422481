import {
  Circle,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
} from "@chakra-ui/react";
import { FormattedMessage } from "react-intl";

import { Button } from "ui/Button/Button";
import { WarningThreeIcon } from "ui/Icons/Icons";
import { BodyM, HeaderS } from "ui/Typography/Typography";

export type UnverifiedQuantityModalProps = {
  onVerifyQuantity: () => void;
  isOpen: boolean;
};

export function UnverifiedQuantityModal({
  onVerifyQuantity,
  isOpen,
}: UnverifiedQuantityModalProps) {
  return (
    <Modal
      trapFocus={false}
      closeOnOverlayClick={false}
      isCentered
      isOpen={isOpen}
      onClose={() => {}}
    >
      <ModalOverlay bg="rgba(0,0,0,0.32)" />
      <ModalContent marginX="s300">
        <ModalBody textAlign="center" paddingY="0">
          <Flex align="center" marginY="s300" justify="center">
            <Circle bg="grey.100" size="120px">
              <WarningThreeIcon boxSize="80px" />
            </Circle>
          </Flex>
          <HeaderS>
            <FormattedMessage id="components.unverified-quantity-modal.header" />
          </HeaderS>
          <BodyM mt="s100">
            <FormattedMessage id="components.unverified-quantity-modal.body" />
          </BodyM>
        </ModalBody>
        <ModalFooter padding="s200">
          <Button
            h="54px"
            mt="s100"
            onClick={onVerifyQuantity}
            width="full"
            flinkVariant="primary"
            size="lg"
          >
            <FormattedMessage id="components.unverified-quantity-modal.button.label" />
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
