import { useRef } from "react";

import { Box, Flex } from "@chakra-ui/react";
import { useIntl } from "react-intl";

import { useLayoutStore } from "core/stores/useLayoutStore";
import { RolliStatus, useCheckinUIStore } from "flows/Activities/stores/useCheckInUIStore";
import { useCustomToast } from "shared/hooks/useCustomToast";
import useSwipeScroll from "shared/hooks/useSwipeScroll";
import { BOTTOM_TAB_BAR_HEIGHT_NUMBER } from "ui/BottomTabBarContainer";
import { CheckFilledCircleIcon, CrossIcon, EmptyCircleIcon, TrashIcon } from "ui/Icons/Icons";
import { SideButton } from "ui/SideButton/SideButton";
import { SwipeScrollWrapper } from "ui/SwipeScrollWrapper";
import { BodyM, BodyS, TitleM } from "ui/Typography/Typography";
import { isNullOrUndefined } from "utils/tsHelpers";

type RolliCardProps = {
  title: string;
  id: string;
  leftLabel: string;
  rightLabel: string;
  status: RolliStatus | null;
  onChange: (status: RolliStatus) => void;
  isRolliNotMatch: boolean;
  isHighlightedRolli: boolean;
};

const statusStyles = {
  [RolliStatus.REJECTED]: {
    bgColor: "orange.100",
    borderColor: "orange.500",
    icon: (
      <Flex
        h="20px"
        w="20px"
        borderRadius="20px"
        bgColor="orange.500"
        align="center"
        justify="center"
        m="2px"
      >
        <CrossIcon color="white" boxSize="18px" />
      </Flex>
    ),
  },
  [RolliStatus.ACCEPTED]: {
    bgColor: "veggie.100",
    borderColor: "veggie.500",
    icon: <CheckFilledCircleIcon boxSize="24px" color="veggie.500" />,
  },
  default: {
    bgColor: "grey.100",
    borderColor: "grey.500",
    icon: <EmptyCircleIcon boxSize="20px" m="2px" color="grey.800" />,
  },
};

export function RolliCard({
  title,
  id,
  leftLabel,
  rightLabel,
  status,
  onChange,
  isRolliNotMatch,
  isHighlightedRolli,
}: RolliCardProps) {
  const intl = useIntl();
  const { showToastUI } = useCustomToast();

  const { scannedRollies, setCheckinUIState } = useCheckinUIStore((state) => ({
    scannedRollies: state.scannedRollies,
    setCheckinUIState: state.setCheckinUIState,
  }));

  const getIconForOption = (option: RolliStatus) => {
    if (isNullOrUndefined(status) || status !== option) return statusStyles.default.icon;
    return statusStyles[status].icon;
  };

  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const scrollContainerRefFromStore = useLayoutStore((state) => state.scrollContainerRef);
  const { handleSwipeScroll, isScrolledLeft } = useSwipeScroll(
    scrollContainerRefFromStore,
    wrapperRef,
  );

  const { bgColor, borderColor } = statusStyles[status || "default"];

  const borderStyle = isHighlightedRolli ? { borderColor, borderWidth: 2 } : "";
  const isRolliMatched = !isRolliNotMatch && status === RolliStatus.ACCEPTED;
  const padding = isRolliNotMatch ? "10px 22px 10px 22px" : "12px 10px 12px 10px";

  const removeSSCCFromList = (sscc: string) => () => {
    setCheckinUIState({ scannedRollies: scannedRollies?.filter((item) => item.sscc !== sscc) });
    showToastUI({
      title: "components.delivery-checkin.rolli-card.successfully-deleted",
      variant: "warning",
      containerStyle: {
        background: "#33448D",
        borderRadius: "8px",
        color: "white",
        bottom: BOTTOM_TAB_BAR_HEIGHT_NUMBER,
        position: "absolute",
      },
    });
  };

  const mainComponent = (
    <Flex
      direction="column"
      p={padding}
      bgColor={bgColor}
      {...borderStyle}
      borderRadius="sm"
      gap="s150"
      data-testid={`rolli-card-${id}`}
    >
      <Flex gap="s75" align="center">
        {isRolliMatched && <CheckFilledCircleIcon boxSize="20px" color="veggie.500" />}
        <TitleM>{title}</TitleM>
        <BodyM letterSpacing="0.05rem">{id}</BodyM>
      </Flex>
      {isRolliNotMatch && (
        <Flex gap="26px" alignSelf="center">
          <Flex
            align="center"
            gap="s75"
            cursor="pointer"
            onClick={() => onChange(RolliStatus.REJECTED)}
            data-testid="wrong-rolli-radio"
          >
            {getIconForOption(RolliStatus.REJECTED)}
            <BodyS color="grey.800">{leftLabel}</BodyS>
          </Flex>
          <Flex
            align="center"
            gap="s75"
            cursor="pointer"
            onClick={() => onChange(RolliStatus.ACCEPTED)}
            data-testid="right-rolli-radio"
          >
            {getIconForOption(RolliStatus.ACCEPTED)}
            <BodyS color="grey.800">{rightLabel}</BodyS>
          </Flex>
        </Flex>
      )}
    </Flex>
  );

  const scrolledComponent = (
    <Box paddingY="1px">
      <SideButton
        borderRightRadius="sm"
        color="red.500"
        bgColor="red.100"
        icon={<TrashIcon boxSize={6} />}
        height="100%"
        marginLeft={isScrolledLeft ? "-3px" : "0"}
        label={
          isRolliMatched
            ? null
            : intl.formatMessage({
                id: "components.delivery-checkin.rolli-card.delete-label",
                defaultMessage: "Delete",
              })
        }
        onClick={removeSSCCFromList(id)}
        data-testid={`remove-sscc-${id}`}
      />
    </Box>
  );

  return (
    <SwipeScrollWrapper
      mainComponent={mainComponent}
      handleSwipeScroll={handleSwipeScroll}
      scrolledComponent={scrolledComponent}
      showSwipeButtons
      ref={wrapperRef}
      flex="none"
    />
  );
}
