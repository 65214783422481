import React, { useCallback, useState } from "react";

import { AddIcon } from "@chakra-ui/icons";
import { Box, BoxProps, Button, ButtonProps, Grid } from "@chakra-ui/react";

import { useIsCardClicked } from "shared/hooks/useIsCardClicked";
import { TranslatedProductSearchUnit, UnitType } from "shared/models/productSearchUnit/types";
import { InboundFruitOrVeggyChips } from "ui/InboundFruitOrVeggyChips/InboundFruitOrVeggyChips";
import { ProductInformation } from "ui/ProductInformation/ProductInformation";
import { TitleXS } from "ui/Typography/Typography";
import { preventPropagation } from "utils/domEvents";

type BottomActionButtonColor = "orange" | "blue";

const getSelectedButtonBorderProps = (color: BottomActionButtonColor) => ({
  borderColor: `${color}.500`,
  borderWidth: "2px",
});

type BottomActionButtonProps = {
  color: BottomActionButtonColor;
  isSelected: boolean;
  label: string;
} & ButtonProps;

function BottomActionButton({
  color,
  isSelected,
  onClick,
  label,
  ...rest
}: BottomActionButtonProps) {
  const borderProps = isSelected ? getSelectedButtonBorderProps(color) : undefined;
  return (
    <Button
      {...borderProps}
      bg={`${color}.100`}
      flex={1}
      size="md"
      onClick={onClick}
      leftIcon={<AddIcon color={`${color}.500`} />}
      _active={{
        bg: `${color}.100`,
      }}
      _focus={{ bg: `${color}.100` }}
      {...rest}
    >
      <TitleXS color={`${color}.500`}>{label}</TitleXS>
    </Button>
  );
}

export type InboundNoEANProductCardProps = {
  bioLabel: string;
  nonBioLabel: string;
  showAddHandlingUnitButton?: boolean;
  onClickProductCard: (unitType: UnitType) => void;
  addHandlingUnitLabel: string;
  addSingleUnitLabel: string;
} & Pick<
  TranslatedProductSearchUnit,
  "productName" | "productImageUrl" | "productSku" | "isProductBio" | "countryOfOriginName"
> &
  BoxProps;

export function InboundNoEANProductCard({
  productName,
  productImageUrl,
  productSku,
  isProductBio = false,
  bioLabel,
  nonBioLabel,
  countryOfOriginName,
  showAddHandlingUnitButton = false,
  addHandlingUnitLabel,
  addSingleUnitLabel,
  onClickProductCard,
  ...rest
}: InboundNoEANProductCardProps) {
  const [selectedButton, setSelectedButton] = useState<UnitType>(() => {
    return showAddHandlingUnitButton ? "handling" : "single";
  });

  const onClickCallback = useCallback(() => {
    onClickProductCard(selectedButton);
  }, [onClickProductCard, selectedButton]);

  const { isClicked: isProductSelected, onClickCard: onSelectProduct } = useIsCardClicked({
    onClickCallback,
  });

  const handleButtonClick = (unitType: UnitType) => () => {
    setSelectedButton(unitType);
  };

  return (
    <Box
      p="s200"
      bg={isProductSelected ? "grey.350" : "white"}
      data-testid="inbound-dropping-card"
      onClick={onSelectProduct}
      {...rest}
    >
      <InboundFruitOrVeggyChips
        isBio={!!isProductBio}
        countryOfOriginName={countryOfOriginName}
        bioLabel={bioLabel}
        nonBioLabel={nonBioLabel}
        mb="s200"
      />
      <ProductInformation
        name={productName ?? ""}
        sku={productSku}
        imageUrl={productImageUrl ?? ""}
        bg={isProductSelected ? "grey.650" : "grey.100"}
      />
      <Grid mt="s200" templateColumns="1fr 1fr" gridColumnGap="s150">
        {showAddHandlingUnitButton && (
          <BottomActionButton
            color="orange"
            isSelected={selectedButton === "handling"}
            label={addHandlingUnitLabel}
            onClick={preventPropagation(handleButtonClick("handling"))}
          />
        )}
        <BottomActionButton
          color="blue"
          isSelected={selectedButton === "single"}
          label={addSingleUnitLabel}
          onClick={preventPropagation(handleButtonClick("single"))}
          gridColumnStart="2"
        />
      </Grid>
    </Box>
  );
}
