export const radii = {
  none: "0", // 0px
  xs: "0.25rem", // 4px
  sm: "0.5rem", // 8px
  md: "0.75rem", // 12px
  base: "0.75rem", // 12px
  lg: "1.125rem", // 18px
  xl: "1.5rem", // 24px
  "2xl": "9999px", // 100%
};
