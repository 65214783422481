import { cloneElement, ReactElement } from "react";

import { Flex, FlexProps } from "@chakra-ui/react";

export type CircleIconProps = {
  icon: ReactElement;
} & FlexProps;

export function CircleIcon({ icon, ...rest }: CircleIconProps) {
  return (
    <Flex
      borderRadius="50%"
      w="200px"
      h="200px"
      bg="white"
      alignItems="center"
      justifyContent="center"
      {...rest}
    >
      {cloneElement(icon, {
        boxSize: "100px",
        color: "pinkFlink.500",
      })}
    </Flex>
  );
}
