import { useCallback } from "react";

import { useApolloClient } from "@apollo/client";
import _ from "lodash";

import {
  ResolveEanToSkusDocument,
  ResolveEanToSkusQuery,
  ResolveEanToSkusQueryVariables,
} from "../queries/sku/sku.generated";

export const useResolveEANToSkus = () => {
  const apollo = useApolloClient();

  return useCallback(
    async (ean: string) => {
      const {
        data: { searchUnitsByEan: result },
      } = await apollo.query<ResolveEanToSkusQuery, ResolveEanToSkusQueryVariables>({
        query: ResolveEanToSkusDocument,
        variables: { input: { ean } },
        fetchPolicy: "no-cache",
      });

      return _.uniq(result.units.map((unit) => unit.productSku));
    },
    [apollo],
  );
};
