import { AppLanguage } from "core/stores/useAppLanguageStore";

export const countryNames: Record<AppLanguage, Record<string, string>> = {
  en: {
    AD: "Andorra",
    AE: "United Arab Emirates",
    AF: "Afghanistan",
    AG: "Antigua & Barbuda",
    AI: "Anguilla",
    AL: "Albania",
    AM: "Armenia",
    AO: "Angola",
    AQ: "Antarctica",
    AR: "Argentina",
    AS: "American Samoa",
    AT: "Austria",
    AU: "Australia",
    AW: "Aruba",
    AX: "Åland Islands",
    AZ: "Azerbaijan",
    BA: "Bosnia & Herzegovina",
    BB: "Barbados",
    BD: "Bangladesh",
    BE: "Belgium",
    BF: "Burkina Faso",
    BG: "Bulgaria",
    BH: "Bahrain",
    BI: "Burundi",
    BJ: "Benin",
    BL: "Saint Barthélemy",
    BM: "Bermuda",
    BN: "Brunei",
    BO: "Bolivia",
    BQ: "Caribbean Netherlands",
    BR: "Brazil",
    BS: "Bahamas",
    BT: "Bhutan",
    BV: "Bouvet Island",
    BW: "Botswana",
    BY: "Belarus",
    BZ: "Belize",
    CA: "Canada",
    CC: "Cocos Islands",
    CD: "Dem. Rep. of the Congo",
    CF: "Central African Republic",
    CG: "Rep. of the Congo",
    CH: "Switzerland",
    CI: "Ivory Coast",
    CK: "Cook Islands",
    CL: "Chile",
    CM: "Cameroon",
    CN: "China",
    CO: "Colombia",
    CR: "Costa Rica",
    CU: "Cuba",
    CV: "Cape Verde",
    CW: "Curaçao",
    CX: "Christmas Island",
    CY: "Cyprus",
    CZ: "Czech Republic",
    DE: "Germany",
    DJ: "Djibouti",
    DK: "Denmark",
    DM: "Dominica",
    DO: "Dominican Republic",
    DZ: "Algeria",
    EC: "Ecuador",
    EE: "Estonia",
    EG: "Egypt",
    EH: "Western Sahara",
    ER: "Eritrea",
    ES: "Spain",
    ET: "Ethiopia",
    FI: "Finland",
    FJ: "Fiji",
    FK: "Falkland Islands",
    FM: "Micronesia",
    FO: "Faroe Islands",
    FR: "France",
    GA: "Gabon",
    GB: "United Kingdom",
    GD: "Grenada",
    GE: "Georgia",
    GF: "French Guiana",
    GG: "Guernsey",
    GH: "Ghana",
    GI: "Gibraltar",
    GL: "Greenland",
    GM: "Gambia",
    GN: "Guinea",
    GP: "Guadeloupe",
    GQ: "Equatorial Guinea",
    GR: "Greece",
    GS: "South Georgia & Southern Sandwich Isl.",
    GT: "Guatemala",
    GU: "Guam",
    GW: "Guinea-Bissau",
    GY: "Guyana",
    HK: "Hong Kong",
    HM: "Heard Island & McDonald Islands",
    HN: "Honduras",
    HR: "Croatia",
    HT: "Haiti",
    HU: "Hungary",
    ID: "Indonesia",
    IE: "Ireland",
    IL: "Israel",
    IM: "Isle of Man",
    IN: "India",
    IO: "British Indian Ocean Territory",
    IQ: "Iraq",
    IR: "Iran",
    IS: "Iceland",
    IT: "Italy",
    JE: "Jersey",
    JM: "Jamaica",
    JO: "Jordan",
    JP: "Japan",
    KE: "Kenya",
    KG: "Kyrgyzstan",
    KH: "Cambodia",
    KI: "Kiribati",
    KM: "Comoros",
    KN: "Saint Kitts & Nevis",
    KP: "North Korea",
    KR: "South Korea",
    KW: "Kuwait",
    KY: "Cayman Islands",
    KZ: "Kazakhstan",
    LA: "Laos",
    LB: "Lebanon",
    LC: "Saint Lucia",
    LI: "Liechtenstein",
    LK: "Sri Lanka",
    LR: "Liberia",
    LS: "Lesotho",
    LT: "Lithuania",
    LU: "Luxembourg",
    LV: "Latvia",
    LY: "Libya",
    MA: "Morocco",
    MC: "Monaco",
    MD: "Moldova",
    ME: "Montenegro",
    MF: "Saint Martin (French)",
    MG: "Madagascar",
    MH: "Marshall Islands",
    MK: "North Macedonia",
    ML: "Mali",
    MM: "Myanmar",
    MN: "Mongolia",
    MO: "Macao",
    MP: "Northern Mariana Islands",
    MQ: "Martinique",
    MR: "Mauritania",
    MS: "Montserrat",
    MT: "Malta",
    MU: "Mauritius",
    MV: "Maldives",
    MW: "Malawi",
    MX: "Mexico",
    MY: "Malaysia",
    MZ: "Mozambique",
    NA: "Namibia",
    NC: "New Caledonia",
    NE: "Niger",
    NF: "Norfolk Island",
    NG: "Nigeria",
    NI: "Nicaragua",
    NL: "Netherlands",
    NO: "Norway",
    NP: "Nepal",
    NR: "Nauru",
    NU: "Niue",
    NZ: "New Zealand",
    OM: "Oman",
    PA: "Panama",
    PE: "Peru",
    PF: "French Polynesia",
    PG: "Papua New Guinea",
    PH: "Philippines",
    PK: "Pakistan",
    PL: "Poland",
    PM: "Saint Pierre & Miquelon",
    PN: "Pitcairn",
    PR: "Puerto Rico",
    PS: "Palestine",
    PT: "Portugal",
    PY: "Paraguay",
    PW: "Palau",
    QA: "Qatar",
    RE: "Réunion",
    RO: "Romania",
    RS: "Serbia",
    RU: "Russia",
    RW: "Rwanda",
    SA: "Saudi Arabia",
    SB: "Solomon Islands",
    SC: "Seychelles",
    SD: "Sudan",
    SE: "Sweden",
    SG: "Singapore",
    SH: "Saint Helena",
    SI: "Slovenia",
    SJ: "Svalbard & Jan Mayen",
    SK: "Slovakia",
    SL: "Sierra Leone",
    SM: "San Marino",
    SN: "Senegal",
    SO: "Somalia",
    SR: "Suriname",
    SS: "South Sudan",
    ST: "São Tomé & Príncipe",
    SV: "El Salvador",
    SX: "Sint Maarten (Dutch)",
    SY: "Syria",
    SZ: "Eswatini",
    TC: "Turks & Caicos Islands",
    TD: "Chad",
    TF: "French Southern & Antarctic Lands",
    TG: "Togo",
    TH: "Thailand",
    TJ: "Tajikistan",
    TK: "Tokelau",
    TL: "Timor-Leste",
    TM: "Turkmenistan",
    TN: "Tunisia",
    TO: "Tonga",
    TR: "Turkey",
    TT: "Trinidad & Tobago",
    TV: "Tuvalu",
    TW: "Taiwan",
    TZ: "Tanzania",
    UA: "Ukraine",
    UG: "Uganda",
    UM: "U.S. Minor Outlying Islands",
    US: "United States",
    UY: "Uruguay",
    UZ: "Uzbekistan",
    VA: "Vatican",
    VC: "Saint Vincent & the Grenadines",
    VE: "Venezuela",
    VG: "British Virgin Islands",
    VI: "U.S. Virgin Islands",
    VN: "Vietnam",
    VU: "Vanuatu",
    WF: "Wallis & Futuna",
    WS: "Samoa",
    YE: "Yemen",
    YT: "Mayotte",
    ZA: "South Africa",
    ZM: "Zambia",
    ZW: "Zimbabwe",
  },
  de: {
    AD: "Andorra",
    AE: "Vereinigte Arabische Emirate",
    AF: "Afghanistan",
    AG: "Antigua & Barbuda",
    AI: "Anguilla",
    AL: "Albanien",
    AM: "Armenien",
    AO: "Angola",
    AQ: "Antarktis",
    AR: "Argentinien",
    AS: "Amerikanisch-Samoa",
    AT: "Österreich",
    AU: "Australien",
    AW: "Aruba",
    AX: "Ålandinseln",
    AZ: "Aserbaidschan",
    BA: "Bosnien & Herzegowina",
    BB: "Barbados",
    BD: "Bangladesch",
    BE: "Belgien",
    BF: "Burkina Faso",
    BG: "Bulgarien",
    BH: "Bahrain",
    BI: "Burundi",
    BJ: "Benin",
    BL: "St. Barthélemy",
    BM: "Bermuda",
    BN: "Brunei",
    BO: "Bolivien",
    BQ: "Karibische Niederlande",
    BR: "Brasilien",
    BS: "Bahamas",
    BT: "Bhutan",
    BV: "Bouvetinsel",
    BW: "Botswana",
    BY: "Belarus",
    BZ: "Belize",
    CA: "Kanada",
    CC: "Kokosinseln",
    CD: "Dem. Rep. Kongo",
    CF: "Zentralafrikanische Rep.",
    CG: "Rep. Kongo",
    CH: "Schweiz",
    CI: "Elfenbeinküste",
    CK: "Cookinseln",
    CL: "Chile",
    CM: "Kamerun",
    CN: "China",
    CO: "Kolumbien",
    CR: "Costa Rica",
    CU: "Kuba",
    CV: "Kap-Verde",
    CW: "Curaçao",
    CX: "Weihnachtsinsel",
    CY: "Zypern",
    CZ: "Tschechein",
    DE: "Deutschland",
    DJ: "Dschibuti",
    DK: "Dänemark",
    DM: "Dominica",
    DO: "Dominikanische Rep.",
    DZ: "Algerien",
    EC: "Ecuador",
    EE: "Estland",
    EG: "Ägypten",
    EH: "Westsahara",
    ER: "Eritrea",
    ES: "Spanien",
    ET: "Äthiopien",
    FI: "Finnland",
    FJ: "Fidschi",
    FK: "Falklandinseln",
    FM: "Mikronesien",
    FO: "Färöer",
    FR: "Frankreich",
    GA: "Gabun",
    GB: "Großbritannien",
    GD: "Grenada",
    GE: "Georgien",
    GF: "Franz.-Guayana",
    GG: "Guernsey",
    GH: "Ghana",
    GI: "Gibraltar",
    GL: "Grönland",
    GM: "Gambia",
    GN: "Guinea",
    GP: "Guadeloupe",
    GQ: "Äquatorialguinea",
    GR: "Griechenland",
    GS: "Südgeorgien & Südl. Sandwichinseln",
    GT: "Guatemala",
    GU: "Guam",
    GW: "Guinea-Bissau",
    GY: "Guyana",
    HK: "Hongkong",
    HM: "Heard & McDonaldinseln",
    HN: "Honduras",
    HR: "Kroatien",
    HT: "Haiti",
    HU: "Ungarn",
    ID: "Indonesien",
    IE: "Irland",
    IL: "Israel",
    IM: "Insel Man",
    IN: "Indien",
    IO: "Britisches Territorium im Indischen Ozean",
    IQ: "Irak",
    IR: "Iran",
    IS: "Island",
    IT: "Italien",
    JE: "Jersey",
    JM: "Jamaika",
    JO: "Jordanien",
    JP: "Japan",
    KE: "Kenia",
    KG: "Kirgistan",
    KH: "Kambodscha",
    KI: "Kiribati",
    KM: "Komoren",
    KN: "St. Kitts & Nevis",
    KP: "Nordkorea",
    KR: "Südkorea",
    KW: "Kuwait",
    KY: "Kaimaninseln",
    KZ: "Kasachstan",
    LA: "Laos",
    LB: "Libanon",
    LC: "St. Lucia",
    LI: "Liechtenstein",
    LK: "Sri Lanka",
    LR: "Liberia",
    LS: "Lesotho",
    LT: "Litauen",
    LU: "Luxemburg",
    LV: "Lettland",
    LY: "Libyen",
    MA: "Marokko",
    MC: "Monaco",
    MD: "Moldau",
    ME: "Montenegro",
    MF: "Saint Martin (franz.)",
    MG: "Madagaskar",
    MH: "Marshallinseln",
    MK: "Nordmazedonien",
    ML: "Mali",
    MM: "Myanmar",
    MN: "Mongolei",
    MO: "Macao",
    MP: "Nördliche Marianen",
    MQ: "Martinique",
    MR: "Mauritanien",
    MS: "Montserrat",
    MT: "Malta",
    MU: "Mauritius",
    MV: "Malediven",
    MW: "Malawi",
    MX: "Mexiko",
    MY: "Malaysia",
    MZ: "Mosambik",
    NA: "Namibia",
    NC: "Neukaledonien",
    NE: "Niger",
    NF: "Norfolkinsel",
    NG: "Nigeria",
    NI: "Nicaragua",
    NL: "Niederlande",
    NO: "Norwegen",
    NP: "Nepal",
    NR: "Nauru",
    NU: "Niue",
    NZ: "Neuseeland",
    OM: "Oman",
    PA: "Panama",
    PE: "Peru",
    PF: "Franz.-Polynesien",
    PG: "Papua-Neuguinea",
    PH: "Philippinen",
    PK: "Pakistan",
    PL: "Polen",
    PM: "St. Pierre & Miquelon",
    PN: "Pitcairninseln",
    PR: "Puerto Rico",
    PS: "Palästina",
    PT: "Portugal",
    PY: "Paraguay",
    PW: "Palau",
    QA: "Katar",
    RE: "Réunion",
    RO: "Rumänien",
    RS: "Serbien",
    RU: "Russland",
    RW: "Ruanda",
    SA: "Saudi-Arabien",
    SB: "Salomonen",
    SC: "Seychellen",
    SD: "Sudan",
    SE: "Schweden",
    SG: "Singapur",
    SH: "St. Helena",
    SI: "Slowenien",
    SJ: "Spitzbergen & Jan Mayen",
    SK: "Slowakei",
    SL: "Sierra Leone",
    SM: "San Marino",
    SN: "Senegal",
    SO: "Somalia",
    SR: "Surinam",
    SS: "Südsudan",
    ST: "São Tomé & Príncipe",
    SV: "El Salvador",
    SX: "Sint Maarten (niederl.)",
    SY: "Syrien",
    SZ: "Eswatini",
    TC: "Turks- & Caicosinseln",
    TD: "Tschad",
    TF: "Franz. Süd- und Antarktisgebiete",
    TG: "Togo",
    TH: "Thailand",
    TJ: "Tadschikistan",
    TK: "Tokelau",
    TL: "Osttimor",
    TM: "Turkmenistan",
    TN: "Tunesien",
    TO: "Tonga",
    TR: "Türkei",
    TT: "Trinidad & Tobago",
    TV: "Tuvalu",
    TW: "Taiwan",
    TZ: "Tansania",
    UA: "Ukraine",
    UG: "Uganda",
    UM: "US Minor Outlying Islands",
    US: "Vereinigte Staaten",
    UY: "Uruguay",
    UZ: "Usbekistan",
    VA: "Vatikan",
    VC: "St. Vincent & die Grenadinen",
    VE: "Venezuela",
    VG: "Brit. Jungferninseln",
    VI: "Amerik. Jungferninseln",
    VN: "Vietnam",
    VU: "Vanuatu",
    WF: "Wallis & Futuna",
    WS: "Samoa",
    YE: "Jemen",
    YT: "Mayotte",
    ZA: "Südafrika",
    ZM: "Sambia",
    ZW: "Simbabwe",
  },
  nl: {
    AD: "Andorra",
    AE: "Verenigde Arabische Emiraten",
    AF: "Afghanistan",
    AG: "Antigua & Barbuda",
    AI: "Anguilla",
    AL: "Albanië",
    AM: "Armenië",
    AO: "Angola",
    AQ: "Antarctica",
    AR: "Argentinië",
    AS: "Amerikaans-Samoa",
    AT: "Oostenrijk",
    AU: "Australië",
    AW: "Aruba",
    AX: "Åland",
    AZ: "Azerbeidzjan",
    BA: "Bosnië & Herzegovina",
    BB: "Barbados",
    BD: "Bangladesh",
    BE: "België",
    BF: "Burkina Faso",
    BG: "Bulgarije",
    BH: "Bahrein",
    BI: "Burundi",
    BJ: "Benin",
    BL: "Saint-Barthélemy",
    BM: "Bermuda",
    BN: "Brunei",
    BO: "Bolivia",
    BQ: "Caribisch Nederland",
    BR: "Brazilië",
    BS: "Bahama's",
    BT: "Bhutan",
    BV: "Bouvet",
    BW: "Botswana",
    BY: "Wit-Rusland",
    BZ: "Belize",
    CA: "Canada",
    CC: "Cocoseilanden",
    CD: "Congo-Kinshasa",
    CF: "Centraal-Afrikaanse Republiek",
    CG: "Congo-Brazzaville",
    CH: "Zwitserland",
    CI: "Ivoorkust",
    CK: "Cookeilanden",
    CL: "Chili",
    CM: "Kameroen",
    CN: "China",
    CO: "Colombia",
    CR: "Costa Rica",
    CU: "Cuba",
    CV: "Kaapverdië",
    CW: "Curaçao",
    CX: "Christmaseiland",
    CY: "Cyprus",
    CZ: "Tsjechië",
    DE: "Duitsland",
    DJ: "Djibouti",
    DK: "Denemarken",
    DM: "Dominica",
    DO: "Dominicaanse Republiek",
    DZ: "Algerije",
    EC: "Ecuador",
    EE: "Estland",
    EG: "Egypte",
    EH: "Westelijke Sahara",
    ER: "Eritrea",
    ES: "Spanje",
    ET: "Ethiopië",
    FI: "Finland",
    FJ: "Fiji",
    FK: "Falklandeilanden",
    FM: "Micronesië",
    FO: "Faeröer",
    FR: "Frankrijk",
    GA: "Gabon",
    GB: "Verenigd Koninkrijk",
    GD: "Grenada",
    GE: "Georgië",
    GF: "Frans-Guyana",
    GG: "Guernsey",
    GH: "Ghana",
    GI: "Gibraltar",
    GL: "Groenland",
    GM: "Gambia",
    GN: "Guinee",
    GP: "Guadeloupe",
    GQ: "Equatoriaal-Guinee",
    GR: "Griekenland",
    GS: "Zuid-Georgia & Zuidelijke Sandwicheilanden",
    GT: "Guatemala",
    GU: "Guam",
    GW: "Guinee-Bissau",
    GY: "Guyana",
    HK: "Hongkong",
    HM: "Heard & McDonaldeilanden",
    HN: "Honduras",
    HR: "Kroatië",
    HT: "Haïti",
    HU: "Hongarije",
    ID: "Indonesië",
    IE: "Ierland",
    IL: "Israël",
    IM: "Man",
    IN: "India",
    IO: "Brits Indische Oceaanterritorium",
    IQ: "Irak",
    IR: "Iran",
    IS: "IJsland",
    IT: "Italië",
    JE: "Jersey",
    JM: "Jamaica",
    JO: "Jordanië",
    JP: "Japan",
    KE: "Kenia",
    KG: "Kirgisië",
    KH: "Cambodja",
    KI: "Kiribati",
    KM: "Comoren",
    KN: "Saint Kitts & Nevis",
    KP: "Noord-Korea",
    KR: "Zuid-Korea",
    KW: "Koeweit",
    KY: "Kaaimaneilanden",
    KZ: "Kazachstan",
    LA: "Laos",
    LB: "Libanon",
    LC: "Saint Lucia",
    LI: "Liechtenstein",
    LK: "Sri Lanka",
    LR: "Liberia",
    LS: "Lesotho",
    LT: "Litouwen",
    LU: "Luxemburg",
    LV: "Letland",
    LY: "Libië",
    MA: "Marokko",
    MC: "Monaco",
    MD: "Moldavië",
    ME: "Montenegro",
    MF: "Saint-Martin (frans)",
    MG: "Madagaskar",
    MH: "Marshalleilanden",
    MK: "Noord-Macedonië",
    ML: "Mali",
    MM: "Myanmar",
    MN: "Mongolië",
    MO: "Macau",
    MP: "Noordelijke Marianen",
    MQ: "Martinique",
    MR: "Mauritanië",
    MS: "Montserrat",
    MT: "Malta",
    MU: "Mauritius",
    MV: "Malediven",
    MW: "Malawi",
    MX: "Mexico",
    MY: "Maleisië",
    MZ: "Mozambique",
    NA: "Namibië",
    NC: "Nieuw-Caledonië",
    NE: "Niger",
    NF: "Norfolk",
    NG: "Nigeria",
    NI: "Nicaragua",
    NL: "Nederland",
    NO: "Noorwegen",
    NP: "Nepal",
    NR: "Nauru",
    NU: "Niue",
    NZ: "Nieuw-Zeeland",
    OM: "Oman",
    PA: "Panama",
    PE: "Peru",
    PF: "Frans-Polynesië",
    PG: "Papoea-Nieuw-Guinee",
    PH: "Filipijnen",
    PK: "Pakistan",
    PL: "Polen",
    PM: "Saint-Pierre & Miquelon",
    PN: "Pitcairneilanden",
    PR: "Puerto Rico",
    PS: "Palestina",
    PT: "Portugal",
    PY: "Paraguay",
    PW: "Palau",
    QA: "Qatar",
    RE: "Réunion",
    RO: "Roumenië",
    RS: "Servië",
    RU: "Rusland",
    RW: "Rwanda",
    SA: "Saoedi-Arabië",
    SB: "Salomonseilanden",
    SC: "Seychellen",
    SD: "Soedan",
    SE: "Zweden",
    SG: "Singapore",
    SH: "Sint-Helena",
    SI: "Slovenië",
    SJ: "Spitsbergen & Jan Mayen",
    SK: "Slovakije",
    SL: "Sierra Leone",
    SM: "San Marino",
    SN: "Senegal",
    SO: "Somalië",
    SR: "Suriname",
    SS: "Zuid-Soedan",
    ST: "Sao Tomé & Principe",
    SV: "El Salvador",
    SX: "Sint Maarten (nederlands)",
    SY: "Syrië",
    SZ: "Eswatini",
    TC: "Turks- & Caicoseilanden",
    TD: "Tsjaad",
    TF: "Franse Zuidelijke Gebieden",
    TG: "Togo",
    TH: "Thailand",
    TJ: "Tadzjikistan",
    TK: "Tokelau",
    TL: "Oost-Timor",
    TM: "Turkmenistan",
    TN: "Tunesië",
    TO: "Tonga",
    TR: "Turkije",
    TT: "Trinidad & Tobago",
    TV: "Tuvalu",
    TW: "Taiwan",
    TZ: "Tanzania",
    UA: "Oukraïne",
    UG: "Oeganda",
    UM: "Kleine afgelegen eilanden van de Verenigde Staten",
    US: "Verenigde Staten",
    UY: "Uruguay",
    UZ: "Oezbekistan",
    VA: "Vaticaan",
    VC: "Saint Vincent & de Grenadines",
    VE: "Venezuela",
    VG: "Britse Maagdeneilanden",
    VI: "Amerikaanse Maagdeneilanden",
    VN: "Vietnam",
    VU: "Vanuatu",
    WF: "Wallis & Futuna",
    WS: "Samoa",
    YE: "Jemen",
    YT: "Mayotte",
    ZA: "Zuid-Afrika",
    ZM: "Zambia",
    ZW: "Zimbabwe",
  },
};

export const countryNameFromCode = (
  locale: AppLanguage = AppLanguage.En,
  countryCode?: string | null,
) => {
  if (!countryCode || !locale) {
    return null;
  }
  const fallbackCountryName = countryNames.en[countryCode];

  if (countryNames[locale]?.[countryCode]) {
    return countryNames[locale][countryCode];
  }
  return fallbackCountryName || countryCode;
};
