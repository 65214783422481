import React from "react";

import { Button, ButtonProps, Flex } from "@chakra-ui/react";

import { TitleS } from "ui/Typography/Typography";

type SideButtonProps = {
  label: string | null;
  icon: JSX.Element;
  bgColor: string;
  color: string;
} & ButtonProps;

export function SideButton({ bgColor, color, icon, label, ...rest }: SideButtonProps) {
  return (
    <Button
      height={rest.height || "50%"}
      width="137px"
      variant="unstyled"
      bg={bgColor}
      borderRadius="0px"
      {...rest}
    >
      <Flex alignItems="center" direction="column">
        {React.cloneElement(icon, { color })}
        {label && (
          <TitleS mt="s100" color={color}>
            {label}
          </TitleS>
        )}
      </Flex>
    </Button>
  );
}
