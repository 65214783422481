import { AddIcon, MinusIcon } from "@chakra-ui/icons";
import { Center, Flex, FlexProps, Box } from "@chakra-ui/react";

import { ProductState } from "flows/Picking/types/productState";
import { HeaderM, HeaderS } from "ui/Typography/Typography";
import { preventPropagation } from "utils/domEvents";

type PickingProductCardCounterProps = {
  count: number;
  total: number;
  state: ProductState;
  hasError: boolean;
  isSkippedItem: boolean;
  onIncrementItemQuantityClick?: () => void;
  onDecrementItemQuantityClick?: () => void;
} & FlexProps;

const getBgColor = ({
  state,
  hasError,
  isSkippedItem,
  count,
}: Pick<PickingProductCardCounterProps, "state" | "hasError" | "isSkippedItem" | "count">) => {
  switch (state) {
    case "PICKING": {
      if (isSkippedItem) {
        return "orange.100";
      }
      return count > 0 ? "orange.100" : "grey.300";
    }
    case "DONE": {
      if (hasError) {
        return isSkippedItem ? "orange.500" : "red.100";
      }
      return "veggie.100";
    }
    default: {
      return isSkippedItem ? "orange.100" : "grey.300";
    }
  }
};

const getTextColor = ({
  state,
  isSkippedItem,
  hasError,
  count,
}: Pick<PickingProductCardCounterProps, "state" | "isSkippedItem" | "hasError" | "count">) => {
  switch (state) {
    case "PICKING": {
      if (isSkippedItem) {
        return "orange.500";
      }
      return count > 0 ? "grey.700" : "grey.800";
    }
    case "DONE": {
      if (hasError) {
        return isSkippedItem ? "white" : "red.500";
      }
      return "veggie.500";
    }
    default: {
      return isSkippedItem ? "orange.500" : "grey.800";
    }
  }
};

export function PickingProductCardCounter({
  count,
  total,
  state,
  hasError,
  isSkippedItem,
  onIncrementItemQuantityClick,
  onDecrementItemQuantityClick,
  ...rest
}: PickingProductCardCounterProps) {
  const bgColor = getBgColor({ state, hasError, isSkippedItem, count });
  const color = getTextColor({ state, isSkippedItem, hasError, count });

  if (count > 0) {
    return (
      <Flex
        w="fit-content"
        alignItems="center"
        border="1px solid"
        borderRadius="sm"
        borderColor={bgColor}
      >
        <Center
          backgroundColor="grey.100"
          borderTopLeftRadius="sm"
          borderBottomLeftRadius="sm"
          w="34px"
          h="40px"
          cursor="pointer"
          onClick={preventPropagation(onDecrementItemQuantityClick)}
          data-testid="decrement-button"
        >
          <MinusIcon color="pink.400" />
        </Center>
        <Center w="60px" h="40px" bgColor={bgColor}>
          <HeaderS color={color}>
            <Box as="span" data-testid="counter-current">
              {count}
            </Box>
            /
            <Box as="span" data-testid="counter-total">
              {total}
            </Box>
          </HeaderS>
        </Center>
        <Center
          backgroundColor="grey.100"
          borderTopRightRadius="sm"
          borderBottomRightRadius="sm"
          cursor="pointer"
          w="34px"
          h="40px"
          onClick={preventPropagation(onIncrementItemQuantityClick)}
          data-testid="increment-button"
        >
          <AddIcon color="grey.700" />
        </Center>
      </Flex>
    );
  }
  return (
    <Flex
      width="fit-content"
      px="s150"
      py="s50"
      bgColor={bgColor}
      alignItems="center"
      justifyContent="center"
      borderRadius="0.5rem"
      {...rest}
    >
      <HeaderM color={color}>
        <Box as="span" data-testid="counter-current">
          {count}
        </Box>
        /
        <Box as="span" data-testid="counter-total">
          {total}
        </Box>
      </HeaderM>
    </Flex>
  );
}
