import { useCallback } from "react";

import { useApolloClient } from "@apollo/client";

import { TaskType } from "__graphql__/types";
import { useStartNextCheck } from "flows/Inventory/shared/hooks/useStartNextCheck";
import { getPriorityGroup } from "flows/Inventory/shared/models/check/serializer";
import { reportErrorsToDDAndThrow } from "utils/datadog";
import { isNullOrUndefined } from "utils/tsHelpers";

import {
  ValidateFreshnessCheckDocument,
  ValidateFreshnessCheckMutation,
  ValidateFreshnessCheckMutationVariables,
} from "../../queries/freshnessCheck/freshnessCheck.generated";
import { FreshCheckMachineContext } from "./types";

export function useFreshCheckServiceImplems() {
  const client = useApolloClient();
  const { startNextCheck: fetchNextCheckAndStartCheckService } = useStartNextCheck();

  const performCheckUpdate = useCallback(
    async (context: FreshCheckMachineContext) => {
      const {
        checkId,
        damagedQuantity: quantityDamaged,
        expiredQuantity: quantityExpired,
        tooGoodToGoQuantity: quantityTooGoodToGo,
      } = context;
      return reportErrorsToDDAndThrow(
        "updating fresh check",
        async () => {
          if (isNullOrUndefined(checkId)) {
            throw new Error("No checkId present in context");
          }
          try {
            await client.mutate<
              ValidateFreshnessCheckMutation,
              ValidateFreshnessCheckMutationVariables
            >({
              mutation: ValidateFreshnessCheckDocument,
              variables: {
                input: {
                  checkId,
                  quantityDamaged,
                  quantityExpired,
                  quantityTooGoodToGo,
                },
              },
            });
          } catch (e: any) {
            if (e?.graphQLErrors?.[0]?.extensions?.code === "TASK_UPDATE_CONFLICT") {
              throw new Error("taskAlreadyCompleted");
            }
            throw e;
          }
          return null;
        },
        {
          xstateContext: context,
        },
      );
    },
    [client],
  );

  const fetchNextCheckAndStartCheck = useCallback(
    (context: FreshCheckMachineContext) => {
      return fetchNextCheckAndStartCheckService({
        groupedPriority: getPriorityGroup(context.priority),
        shelfNumber: `${context.shelfNumber}${context.shelfLetter}`,
        type: [TaskType.freshness_check],
      });
    },
    [fetchNextCheckAndStartCheckService],
  );

  return {
    performCheckUpdate,
    fetchNextCheckAndStartCheck,
  };
}
