import { useState } from "react";

import { Flex } from "@chakra-ui/react";

import { CheckIcon } from "ui/Icons/Icons";
import { BodyM } from "ui/Typography/Typography";

type DropDownListItemProps = {
  isSelected: boolean;
  label: string;
  dataTestId?: string;
  onClickItem: () => void;
};

export function DropDownListItem({
  isSelected,
  label,
  dataTestId,
  onClickItem,
}: DropDownListItemProps) {
  const [isClicked, setIsClicked] = useState(false);
  const onClickCard = () => {
    setIsClicked(true);
    onClickItem();
    setTimeout(() => {
      setIsClicked(false);
    }, 200);
  };

  return (
    <Flex
      width="237px"
      minHeight="56px"
      bgColor={isClicked ? "grey.200" : "white"}
      color="black"
      alignItems="center"
      paddingX="s200"
      justify="space-between"
      onClick={onClickCard}
      as="li"
      data-testid={dataTestId}
    >
      <BodyM>{label}</BodyM>
      {isSelected && <CheckIcon color="pink.500" />}
    </Flex>
  );
}
