import { useEffect, useMemo } from "react";

import { useAuth0 } from "@auth0/auth0-react";
import { datadogRum } from "@datadog/browser-rum";
import { FormattedMessage } from "react-intl";
import { useSearchParams } from "react-router-dom";

import { PageName } from "analytics/events";
import { getDeviceId } from "analytics/utils";
import { config } from "config";
import { Page } from "shared/components/Page";
import { useAnalytics } from "shared/hooks/useAnalytics";
import { useFFConfigPageSecretScan } from "shared/hooks/useFFConfigPageSecretScan";
import { useHubSlug } from "shared/hooks/useHubSlug";
import { Button } from "ui/Button/Button";
import { Spinner } from "ui/Spinner/Spinner";
import { BodyS } from "ui/Typography/Typography";

import { AuthError } from "./AuthError";
import { EmployeeIdentification } from "./EmployeeIdentification/EmployeeIdentification";

const URL_PARAM_LOGIN_ACTION_VALUE = "login_action";

export function AuthPage() {
  const { isAuthenticated, isLoading, loginWithRedirect, error } = useAuth0();
  const [searchParams] = useSearchParams();

  const { analytics, sendSegmentTrackEvent } = useAnalytics();
  const hubSlug = useHubSlug();

  useFFConfigPageSecretScan();

  useEffect(() => {
    if (isLoading) {
      return;
    }
    if (error) {
      sendSegmentTrackEvent("errorShown", {
        screen_name: PageName.HUB_AUTHENTICATION_PAGE,
        component_value: error.message,
      });
      return;
    }
    if (!isAuthenticated) {
      loginWithRedirect({
        redirectUri: `${window.location.origin}?origin=${URL_PARAM_LOGIN_ACTION_VALUE}`,
        prompt: config.environment.ENVIRONMENT !== "development" ? "login" : undefined,
      });
      return;
    }
    if (isAuthenticated && searchParams.get("origin") === URL_PARAM_LOGIN_ACTION_VALUE) {
      analytics.identify(null, { hub_slug: hubSlug, device_id: getDeviceId() });
      datadogRum.setUser({ hub_slug: hubSlug });
      sendSegmentTrackEvent("loginCompleted", { type: "hub", hub_slug: hubSlug! });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, isLoading, error]);

  const element = useMemo(() => {
    if (error) {
      return <AuthError />;
    }
    if (isAuthenticated) {
      return <EmployeeIdentification />;
    }
    if (isLoading) {
      return <Spinner />;
    }
    return (
      <Button
        h="54px"
        mt="s100"
        onClick={() => {
          window.location.reload();
        }}
        width="full"
        flinkVariant="secondary"
        size="lg"
        data-testid="yes-no-modal-no-button"
      >
        <FormattedMessage id="pages.auth.login-again" />
      </Button>
    );
  }, [error, isAuthenticated, isLoading]);

  return (
    <Page isCentered textAlign="center">
      {element}
      <BodyS position="absolute" bottom="10px">
        <FormattedMessage
          id="pages.auth.app-version"
          values={{ appVersion: import.meta.env.EXP_CI_GIT_TAG || "" }}
        />
      </BodyS>
    </Page>
  );
}
