import { FetchPolicy } from "@apollo/client";

import { isNullOrUndefined } from "utils/tsHelpers";

const lastFetchDateByKey: Record<string, number> = {};

export const getFetchPolicyWithCacheTimeout = ({
  key,
  minutes,
}: {
  key: string;
  minutes: number;
}): FetchPolicy => {
  const lastFetchDate = lastFetchDateByKey[key];
  const diffFromNow = lastFetchDate && Date.now() - lastFetchDate;

  if (isNullOrUndefined(diffFromNow) || diffFromNow > minutes * 60000) {
    lastFetchDateByKey[key] = Date.now();
    return "network-only";
  }
  return "cache-first";
};
