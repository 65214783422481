import * as Types from "../../../__graphql__/types.js";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type UpdateProductStockByDeltaAndMultipleReasonsMutationVariables = Types.Exact<{
  updateProductStockByDeltaAndMultipleReasonsInput: Types.UpdateProductStockByDeltaAndMultipleReasonsInput;
}>;

export type UpdateProductStockByDeltaAndMultipleReasonsMutation = {
  __typename?: "Mutation";
  updateProductStockByDeltaAndMultipleReasons: {
    __typename?: "UpdateProductStockByDeltaAndMultipleReasonsResponse";
    updateResults: Array<{ __typename?: "UpdateProductStockByDeltaResponse"; success: boolean }>;
  };
};

export const UpdateProductStockByDeltaAndMultipleReasonsDocument = gql`
  mutation updateProductStockByDeltaAndMultipleReasons(
    $updateProductStockByDeltaAndMultipleReasonsInput: UpdateProductStockByDeltaAndMultipleReasonsInput!
  ) {
    updateProductStockByDeltaAndMultipleReasons(
      UpdateProductStockByDeltaAndMultipleReasonsInput: $updateProductStockByDeltaAndMultipleReasonsInput
    ) {
      updateResults {
        success
      }
    }
  }
`;
export type UpdateProductStockByDeltaAndMultipleReasonsMutationFn = Apollo.MutationFunction<
  UpdateProductStockByDeltaAndMultipleReasonsMutation,
  UpdateProductStockByDeltaAndMultipleReasonsMutationVariables
>;

/**
 * __useUpdateProductStockByDeltaAndMultipleReasonsMutation__
 *
 * To run a mutation, you first call `useUpdateProductStockByDeltaAndMultipleReasonsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductStockByDeltaAndMultipleReasonsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductStockByDeltaAndMultipleReasonsMutation, { data, loading, error }] = useUpdateProductStockByDeltaAndMultipleReasonsMutation({
 *   variables: {
 *      updateProductStockByDeltaAndMultipleReasonsInput: // value for 'updateProductStockByDeltaAndMultipleReasonsInput'
 *   },
 * });
 */
export function useUpdateProductStockByDeltaAndMultipleReasonsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateProductStockByDeltaAndMultipleReasonsMutation,
    UpdateProductStockByDeltaAndMultipleReasonsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateProductStockByDeltaAndMultipleReasonsMutation,
    UpdateProductStockByDeltaAndMultipleReasonsMutationVariables
  >(UpdateProductStockByDeltaAndMultipleReasonsDocument, options);
}
export type UpdateProductStockByDeltaAndMultipleReasonsMutationHookResult = ReturnType<
  typeof useUpdateProductStockByDeltaAndMultipleReasonsMutation
>;
export type UpdateProductStockByDeltaAndMultipleReasonsMutationResult =
  Apollo.MutationResult<UpdateProductStockByDeltaAndMultipleReasonsMutation>;
export type UpdateProductStockByDeltaAndMultipleReasonsMutationOptions = Apollo.BaseMutationOptions<
  UpdateProductStockByDeltaAndMultipleReasonsMutation,
  UpdateProductStockByDeltaAndMultipleReasonsMutationVariables
>;
