import { useCallback } from "react";

import { useApolloClient } from "@apollo/client";

import {
  CreatePrivateRestockingListDocument,
  CreatePrivateRestockingListMutation,
  CreatePrivateRestockingListMutationVariables,
  DeletePrivateRestockingListDocument,
  DeletePrivateRestockingListMutation,
  DeletePrivateRestockingListMutationVariables,
  DeleteSkuFromPrivateListDocument,
  DeleteSkuFromPrivateListMutation,
  DeleteSkuFromPrivateListMutationVariables,
  GetPrivateRestockingListDocument,
  GetPrivateRestockingListQuery,
  GetPrivateRestockingListQueryVariables,
  RemoveItemFromRestockingPrivateListDocument,
  RemoveItemFromRestockingPrivateListMutation,
  RemoveItemFromRestockingPrivateListMutationVariables,
  RemoveMultipleSkusFromRestockingPrivateListDocument,
  RemoveMultipleSkusFromRestockingPrivateListMutation,
  RemoveMultipleSkusFromRestockingPrivateListMutationVariables,
  RestockItemDocument,
  RestockItemMutation,
  RestockItemMutationVariables,
} from "../queries/privateRestockingList/privateRestockingList.generated";

export const usePrivateRestockingListQueries = () => {
  const apollo = useApolloClient();

  const getPrivateRestockingListQuery = useCallback(() => {
    return apollo.query<GetPrivateRestockingListQuery, GetPrivateRestockingListQueryVariables>({
      query: GetPrivateRestockingListDocument,
      fetchPolicy: "network-only",
    });
  }, [apollo]);

  const deletePrivateRestockingListMutation = useCallback(
    (id: string) => {
      return apollo.mutate<
        DeletePrivateRestockingListMutation,
        DeletePrivateRestockingListMutationVariables
      >({
        mutation: DeletePrivateRestockingListDocument,
        variables: { input: { privateListId: id } },
      });
    },
    [apollo],
  );

  const restockItemMutation = useCallback(
    (id: string, sku: string) => {
      return apollo.mutate<RestockItemMutation, RestockItemMutationVariables>({
        mutation: RestockItemDocument,
        variables: { input: { listId: id, sku } },
      });
    },
    [apollo],
  );

  const createPrivateRestockingListMutation = useCallback(
    (skus: string[]) => {
      return apollo.mutate<
        CreatePrivateRestockingListMutation,
        CreatePrivateRestockingListMutationVariables
      >({
        mutation: CreatePrivateRestockingListDocument,
        variables: { input: { skus } },
      });
    },
    [apollo],
  );

  const removeRestockingItemFromPrivateList = useCallback(
    (id: string, sku: string) => {
      return apollo.mutate<
        RemoveItemFromRestockingPrivateListMutation,
        RemoveItemFromRestockingPrivateListMutationVariables
      >({
        mutation: RemoveItemFromRestockingPrivateListDocument,
        variables: { input: { listId: id, sku } },
      });
    },
    [apollo],
  );

  const removeMultipleSkusFromRestockingPrivateList = useCallback(
    (id: string, skus: string[]) => {
      return apollo.mutate<
        RemoveMultipleSkusFromRestockingPrivateListMutation,
        RemoveMultipleSkusFromRestockingPrivateListMutationVariables
      >({
        mutation: RemoveMultipleSkusFromRestockingPrivateListDocument,
        variables: { input: { listId: id, skus } },
      });
    },
    [apollo],
  );

  const deleteSkuFromPrivateList = useCallback(
    (id: string, sku: string) => {
      return apollo.mutate<
        DeleteSkuFromPrivateListMutation,
        DeleteSkuFromPrivateListMutationVariables
      >({
        mutation: DeleteSkuFromPrivateListDocument,
        variables: { input: { listId: id, sku } },
      });
    },
    [apollo],
  );

  return {
    getPrivateRestockingListQuery,
    deletePrivateRestockingListMutation,
    restockItemMutation,
    createPrivateRestockingListMutation,
    removeRestockingItemFromPrivateList,
    removeMultipleSkusFromRestockingPrivateList,
    deleteSkuFromPrivateList,
  };
};
