import { useIntl } from "react-intl";

import { YesNoModal } from "shared/components/YesNoModal";
import { WarningThreeIcon } from "ui/Icons/Icons";

export type ProductNotAssignedForTheHubErrorModalProps = {
  onClickYes: () => void;
  onClickNo: () => void;
  isOpen: boolean;
};

export function ProductNotAssignedForTheHubErrorModal({
  onClickYes,
  onClickNo,
  isOpen,
}: ProductNotAssignedForTheHubErrorModalProps) {
  const intl = useIntl();
  return (
    <YesNoModal
      icon={<WarningThreeIcon />}
      isOpen={isOpen}
      onClickYes={onClickYes}
      onClickNo={onClickNo}
      title={intl.formatMessage({
        id: "components.product-not-assigned-for-the-hub-error-modal.title",
      })}
      body={intl.formatMessage({
        id: "components.product-not-assigned-for-the-hub-error-modal.body",
      })}
      yesLabel={intl.formatMessage({
        id: "components.product-not-assigned-for-the-hub-error-modal.yes",
      })}
      noLabel={intl.formatMessage({
        id: "components.product-not-assigned-for-the-hub-error-modal.no",
      })}
    />
  );
}
