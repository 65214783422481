import { Flex, Image } from "@chakra-ui/react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router";

import { routes } from "config/routes";
import Stopwatch from "images/random/stopwatch.svg";
import { Button } from "ui/Button/Button";
import { BodyS, TitleM } from "ui/Typography/Typography";
import { transformChunks } from "utils/ui";

export function TimedActivities() {
  const navigate = useNavigate();
  return (
    <Flex direction="column" p="s200" w="100%" data-testid="timed-activities-block">
      <TitleM my="s200">
        <FormattedMessage id="pages.activities.timed-activities.title" />
      </TitleM>

      <Flex bgColor="white" my="s200" p="s200" align="center" borderRadius="sm">
        <Image src={Stopwatch} boxSize="48px" borderRadius="full" />

        <BodyS ml="s300" my="s100">
          <FormattedMessage
            id="pages.activities.timed-activities.instructions"
            values={transformChunks({ b: { as: "strong" } })}
          />
        </BodyS>
      </Flex>

      <Button
        alignSelf="center"
        w="154px"
        flinkVariant="secondary"
        onClick={() => navigate(routes.activities.timedActivities.list)}
        data-testid="timed-activities-link"
      >
        <FormattedMessage id="pages.activities.timed-activities.cta-button" />
      </Button>
    </Flex>
  );
}
