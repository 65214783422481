import { useCallback } from "react";

import { Box, Flex } from "@chakra-ui/react";
import { useSelector } from "@xstate/react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router";

import { Page } from "shared/components/Page";
import { NavigationHeader } from "ui/NavigationHeader/NavigationHeader";
import { Shelf } from "ui/Shelf/Shelf";
import { TitleM } from "ui/Typography/Typography";
import { getShelfDetail } from "utils/item";
import { isShelfNumberFormat } from "utils/scan";
import { isNotNullNorUndefined } from "utils/tsHelpers";

import { InventoryProduct } from "../../../shared/models/inventoryProduct/types";
import { ProductsList } from "../components/ProductsList";
import { useStockCorrectionService } from "../hooks/useStockCorrectionService";

export function SelectProductPage() {
  const intl = useIntl();
  const navigate = useNavigate();
  const stockCorrectionService = useStockCorrectionService();
  const inventoryProductsList = useSelector(
    stockCorrectionService,
    (state) => state.context.inventoryProductsList,
  );
  const { scanType, scannedValue } = useSelector(stockCorrectionService, (state) => ({
    scannedValue: state.context.scannedValue,
    scanType: state.context.scanType,
  }));

  const isASearchByShelf =
    scanType === "shelf" && scannedValue !== null && isShelfNumberFormat(scannedValue);
  const shelfDetail = isASearchByShelf ? getShelfDetail(scannedValue) : null;

  const onConfirmStock = useCallback(
    (product: InventoryProduct) => {
      stockCorrectionService.send({
        type: "VALIDATE_PRODUCT_STOCK",
        inventoryProductSku: product.productSku,
        inventoryProductShelf: product.shelf,
        stock: product.stock,
      });
    },
    [stockCorrectionService],
  );

  const onClickProduct = useCallback(
    (product: InventoryProduct) => {
      stockCorrectionService.send({
        type: "SELECT_INVENTORY_PRODUCT",
        inventoryProductSku: product.productSku,
        inventoryProductShelf: product.shelf,
      });
    },
    [stockCorrectionService],
  );

  return (
    <Page isFull isBgGrey h="100%">
      <NavigationHeader
        title={intl.formatMessage({
          id: "components.inventory.select-product.header-title",
          defaultMessage: "Stock list",
        })}
        onClickGoBack={() => {
          navigate(-1);
        }}
      />
      <Box flex={1} flexDirection="column" w="100%" overflowY="scroll">
        <Flex alignItems="center" justifyContent="space-between" px="s200" pt="s300" pb="s200">
          {!isASearchByShelf && (
            <TitleM>
              {intl.formatMessage(
                {
                  id: "pages.inventory.stock-correction.results-found",
                },
                { quantity: inventoryProductsList.length },
              )}
            </TitleM>
          )}
          {isASearchByShelf && isNotNullNorUndefined(shelfDetail) && (
            <Shelf
              letter={shelfDetail.shelfLetter}
              number={shelfDetail.shelfNumber}
              variant="small"
            />
          )}
          <TitleM
            color="grey.600"
            onClick={() => stockCorrectionService.send({ type: "CLEAR_LIST" })}
          >
            {intl.formatMessage({
              id: "pages.inventory.stock-correction.clear",
            })}
          </TitleM>
        </Flex>
        <ProductsList
          products={inventoryProductsList}
          variantSize="s"
          onConfirmStock={onConfirmStock}
          onClickProduct={onClickProduct}
          showUpdateStockShortCut
          showStock
          showStockStatus
          showProductFacing
        />
      </Box>
    </Page>
  );
}
