import { useEffect } from "react";

import { FlexProps } from "@chakra-ui/react";
import { Navigate } from "react-router";

import { PageName } from "analytics/events";
import { routes as routesConfig } from "config/routes";
import { TabBarItemID, useLayoutStore } from "core/stores/useLayoutStore";
import { OAOccupation, useOAOccupationStore } from "core/stores/useOAOccupationStore";
import { EppoFeatureFlags } from "core/types/flags";
import { WaitingOrderBannerType } from "core/types/waitingOrderBanner";
import { useEppoFeatureFlagProvider } from "shared/hooks/useEppoFeatureFlag";

export type Flag = {
  featureFlag?: EppoFeatureFlags;
  inaccessible?: boolean;
  redirectPath?: string;
};

export type RouteLayoutProps = {
  canGoBack?: boolean;
  withTabBar?: boolean;
  highlightedTab?: TabBarItemID | null;
  withWaitingOrdersBannerOfType?: WaitingOrderBannerType;
  withMenu?: boolean;
  withOfflineBanner?: boolean;
  withAppHeader?: boolean;
  withScrollableAppHeader?: boolean;
  withOngoingActivityBanner?: boolean;
  withTabList?: boolean;
  canShowUpdateModal?: boolean;
  pageName?: PageName | null;
  oaOccupation?: OAOccupation;
  flag?: Flag;
};

export type AppLayoutProviderProps = RouteLayoutProps & FlexProps & { id: string };

function ProtectedRoute({
  featureFlag,
  redirectPath = routesConfig.activities.root,
  inaccessible = false,
  children,
}: {
  featureFlag: EppoFeatureFlags;
  redirectPath?: string;
  inaccessible?: boolean;
  children: React.ReactNode;
}) {
  const { isFeatureEnabled, isLoading } = useEppoFeatureFlagProvider(featureFlag);

  if (isLoading) return null;

  const shouldRedirect = inaccessible ? isFeatureEnabled : !isFeatureEnabled;

  if (shouldRedirect) {
    return <Navigate to={redirectPath} replace />;
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
}

export function AppLayoutProvider({
  children,
  id,
  canGoBack = true,
  withTabBar = false,
  highlightedTab = null,
  withWaitingOrdersBannerOfType = "default",
  withMenu = true,
  withOfflineBanner = true,
  withAppHeader = true,
  withScrollableAppHeader = false,
  withOngoingActivityBanner = false,
  withTabList = false,
  canShowUpdateModal = false,
  pageName = null,
  oaOccupation,
  flag,
}: AppLayoutProviderProps) {
  const setAppLayout = useLayoutStore((state) => state.setAppLayout);
  const setOAOccupation = useOAOccupationStore((state) => state.setOAOccupation);

  useEffect(() => {
    setAppLayout({
      canGoBack,
      withTabBar,
      highlightedTab,
      withWaitingOrdersBannerOfType,
      withMenu,
      withOfflineBanner,
      withAppHeader,
      withScrollableAppHeader,
      withOngoingActivityBanner,
      withTabList,
      canShowUpdateModal,
      pageName,
    });
    setOAOccupation(oaOccupation ?? OAOccupation.Other);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  if (flag?.featureFlag) {
    return (
      <ProtectedRoute
        featureFlag={flag.featureFlag}
        inaccessible={flag.inaccessible}
        redirectPath={flag.redirectPath}
      >
        {children}
      </ProtectedRoute>
    );
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
}
