import { useCallback, useMemo, useState } from "react";

import { Box, Flex, IconButton } from "@chakra-ui/react";
import { useSelector } from "@xstate/react";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router";

import { EppoFeatureFlags } from "core/types/flags";
import { DroppingListInboundUnitListItem } from "flows/Inbound/components/DroppingListInboundUnitListItem";
import { FinishDroppingButton } from "flows/Inbound/components/FinishDroppingButton";
import {
  useSelectDroppingListActiveInboundUnit,
  useSelectDroppingListInboundUnitsSkusSortedByShelf,
} from "flows/Inbound/hooks/useInboundMachineSelectors";
import { useInboundService } from "flows/Inbound/hooks/useInboundService";
import { Page } from "shared/components/Page";
import { useEppoFeatureFlagProvider } from "shared/hooks/useEppoFeatureFlag";
import { useListScrollEffects } from "shared/hooks/useListScrollEffects";
import { AscDscIcon } from "ui/AscDscIcon/AscDscIcon";
import { Banner } from "ui/Banner/Banner";
import { TapIcon } from "ui/Icons/Icons";
import { NavigationHeader } from "ui/NavigationHeader/NavigationHeader";
import { isNullOrUndefined } from "utils/tsHelpers";
import { transformChunks } from "utils/ui";

export function InboundDroppingList() {
  const intl = useIntl();
  const navigate = useNavigate();
  const [isBannerVisible, setBannerVisible] = useState(true);

  const inboundService = useInboundService();
  const droppingListInboundUnitsSkusSortedByShelf =
    useSelectDroppingListInboundUnitsSkusSortedByShelf();
  const activeInboundUnit = useSelectDroppingListActiveInboundUnit();
  const droppingListShelfSortOrder = useSelector(
    inboundService,
    (state) => state.context.droppingListShelfSortOrder,
  );

  const { itemsRef, scrollContainerRef } = useListScrollEffects({
    activeItemId: activeInboundUnit?.productSku ?? null,
    listItemIds: droppingListInboundUnitsSkusSortedByShelf,
  });

  const onClickGoBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const toggleDroppingListOrder = useCallback(() => {
    inboundService.send({ type: "TOGGLE_DROPPING_LIST_SHELF_SORT_ORDER" });
  }, [inboundService]);

  const handleTapOnCardBanner = () => setBannerVisible(false);

  const isDroppingComplete = isNullOrUndefined(activeInboundUnit);

  const headerMessageId = isDroppingComplete
    ? "pages.inbound.inbound-dropping-list.header-title-completed"
    : "pages.inbound.inbound-dropping-list.header-title-dropping";

  const translatedLabels = useMemo(
    () => ({
      singleUnitsLabel: intl.formatMessage({
        id: "components.inbound.inbound-units-list.single-units-label",
      }),
      handlingUnitsLabel: intl.formatMessage({
        id: "components.inbound.inbound-units-list.handling-units-label",
      }),
      inboundedLabel: intl.formatMessage({
        id: "components.inbound.inbound-units-list.inbounded-label",
      }),
      totalUnitsLabel: intl.formatMessage({
        id: "components.inbound.inbound-units-list.total-handling-units-label",
      }),
      bioLabel: intl.formatMessage({
        id: "components.inbound.inbound-units-list.bio-label",
      }),
      nonBioLabel: intl.formatMessage({
        id: "components.inbound.inbound-units-list.non-bio-label",
      }),
      outboundedLabel: intl.formatMessage({
        id: "components.inbound.inbound-units-list.outbounded-label",
      }),
      outboundLabel: intl.formatMessage({
        id: "components.inbound.inbound-units-list.outbound-label",
      }),
      droppingLabel: intl.formatMessage({
        id: "components.inbound.inbound-units-list.dropping-label",
      }),
      inStockLabel: intl.formatMessage({
        id: "components.inbound.inbound-units-list.in-stock-label",
      }),
    }),
    [intl],
  );

  const { isFeatureEnabled: isProductFacingEnabled } = useEppoFeatureFlagProvider(
    EppoFeatureFlags.PRODUCT_FACING,
  );
  const showTapOnCardAlert = isProductFacingEnabled && isBannerVisible && !isDroppingComplete;

  return (
    <Page isFull isBgGrey height="100%">
      <NavigationHeader
        onClickGoBack={onClickGoBack}
        title={intl.formatMessage({ id: headerMessageId })}
        rightElement={
          <IconButton
            size="xs"
            variant="ghost"
            aria-label="Change sort order"
            icon={<AscDscIcon height="24px" width="24px" sortState={droppingListShelfSortOrder} />}
            onClick={toggleDroppingListOrder}
          />
        }
      />
      {showTapOnCardAlert && (
        <Banner
          colorVariant="purple"
          icon={<TapIcon />}
          subTitle={
            <FormattedMessage
              id="components.inbound.inbound-units-list.banner"
              values={transformChunks({ b: { as: "strong", color: "black" } })}
            />
          }
          onClose={handleTapOnCardBanner}
        />
      )}
      <Flex
        direction="column"
        pos="relative"
        ref={scrollContainerRef}
        gap="s150"
        flex={1}
        overflowY="scroll"
        w="100%"
        pt="s200"
      >
        {droppingListInboundUnitsSkusSortedByShelf.map((sku, index) => (
          <DroppingListInboundUnitListItem
            key={sku}
            sku={sku}
            elementRef={itemsRef.current[index]}
            translatedLabels={translatedLabels}
            handleTapOnCardBanner={handleTapOnCardBanner}
          />
        ))}
        {/* Below box helps us having the EndDroppingProcessButton not overflowing bottom content */}
        <Box mb="60px" />
        <FinishDroppingButton />
      </Flex>
    </Page>
  );
}
