import { createContext, useContext, useMemo } from "react";

import { useInterpret } from "@xstate/react";
import { Outlet } from "react-router-dom";
import { InterpreterFrom } from "xstate";

import { useInterpreterRouteSynchronizer } from "shared/hooks/useInterpreterRouteSynchronizer";

import { useBbdCorrectionActionsImplems } from "../machines/bbdCorrectionMachine/actions";
import {
  BBD_CORRECTION_MACHINE_NAME,
  bbdCorrectionMachine,
} from "../machines/bbdCorrectionMachine/machine";
import { useBbdCorrectionServiceImplems } from "../machines/bbdCorrectionMachine/services";
import {
  BbdCorrectionEvents,
  BbdCorrectionMachineContext,
} from "../machines/bbdCorrectionMachine/types";

export const XStateBbdCorrectionContext = createContext({
  bbdCorrectionService: {} as InterpreterFrom<typeof bbdCorrectionMachine>,
});

export function XStateBbdCorrectionProvider() {
  const bbdCorrectionActionsImplems = useBbdCorrectionActionsImplems();
  const bbdCorrectionServicesImplems = useBbdCorrectionServiceImplems();

  const bbdCorrectionService = useInterpret(bbdCorrectionMachine, {
    actions: bbdCorrectionActionsImplems,
    services: bbdCorrectionServicesImplems,
  });

  useInterpreterRouteSynchronizer<BbdCorrectionMachineContext, BbdCorrectionEvents>(
    BBD_CORRECTION_MACHINE_NAME,
    bbdCorrectionService,
  );

  const values = useMemo(
    () => ({
      bbdCorrectionService,
    }),
    [bbdCorrectionService],
  );

  return (
    <XStateBbdCorrectionContext.Provider value={values}>
      <Outlet />
    </XStateBbdCorrectionContext.Provider>
  );
}

export function useBbdCorrectionServiceContext() {
  return useContext(XStateBbdCorrectionContext);
}
