import { Tag as ChakraTag } from "@chakra-ui/react";

import { TitleXS } from "ui/Typography/Typography";

export enum TagStateEnum {
  Success = "success",
  Error = "error",
  Default = "default",
}
export type TagProps = {
  label: string;
  state?: TagStateEnum;
};

const getColorVariant = (state: TagStateEnum, shade: string | number) => {
  const color = {
    [TagStateEnum.Success]: "veggie",
    [TagStateEnum.Error]: "red",
    [TagStateEnum.Default]: "grey",
  }[state];
  return `${color}.${shade}`;
};

export function Tag({ state = TagStateEnum.Default, label }: TagProps) {
  return (
    <ChakraTag bg={getColorVariant(state, "100")} borderRadius="4px">
      <TitleXS color={getColorVariant(state, "500")}>{label}</TitleXS>
    </ChakraTag>
  );
}
