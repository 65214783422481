import * as Types from "../../../../__graphql__/types.js";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type GetDespatchAdviceSummaryByRolliIdQueryVariables = Types.Exact<{
  input: Types.GetDespatchAdviceSummaryByRolliIdInput;
}>;

export type GetDespatchAdviceSummaryByRolliIdQuery = {
  __typename?: "Query";
  getDespatchAdviceSummaryByRolliID: {
    __typename?: "GetDespatchAdviceSummaryByRolliIDResponse";
    despatchAdviceSummary: {
      __typename?: "DespatchAdvice";
      id: string;
      hubSlug: string;
      expectedDeliveryDate?: any | null;
    };
  };
};

export type GetDespatchAdviceDeliveriesForHubQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GetDespatchAdviceDeliveriesForHubQuery = {
  __typename?: "Query";
  getDespatchAdviceDeliveriesForHub: {
    __typename?: "GetDespatchAdviceDeliveriesForHubResponse";
    despatchAdviceDeliveriesForHub: Array<{
      __typename?: "DespatchAdviceDeliveriesForHub";
      id: string;
      estimatedDeliveryDate?: any | null;
      hubSlug: string;
      ssccs: Array<string>;
    }>;
  };
};

export const GetDespatchAdviceSummaryByRolliIdDocument = gql`
  query getDespatchAdviceSummaryByRolliID($input: GetDespatchAdviceSummaryByRolliIDInput!) {
    getDespatchAdviceSummaryByRolliID(GetDespatchAdviceSummaryByRolliIDInput: $input) {
      despatchAdviceSummary {
        id
        hubSlug
        expectedDeliveryDate
      }
    }
  }
`;

/**
 * __useGetDespatchAdviceSummaryByRolliIdQuery__
 *
 * To run a query within a React component, call `useGetDespatchAdviceSummaryByRolliIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDespatchAdviceSummaryByRolliIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDespatchAdviceSummaryByRolliIdQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetDespatchAdviceSummaryByRolliIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetDespatchAdviceSummaryByRolliIdQuery,
    GetDespatchAdviceSummaryByRolliIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetDespatchAdviceSummaryByRolliIdQuery,
    GetDespatchAdviceSummaryByRolliIdQueryVariables
  >(GetDespatchAdviceSummaryByRolliIdDocument, options);
}
export function useGetDespatchAdviceSummaryByRolliIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDespatchAdviceSummaryByRolliIdQuery,
    GetDespatchAdviceSummaryByRolliIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDespatchAdviceSummaryByRolliIdQuery,
    GetDespatchAdviceSummaryByRolliIdQueryVariables
  >(GetDespatchAdviceSummaryByRolliIdDocument, options);
}
export type GetDespatchAdviceSummaryByRolliIdQueryHookResult = ReturnType<
  typeof useGetDespatchAdviceSummaryByRolliIdQuery
>;
export type GetDespatchAdviceSummaryByRolliIdLazyQueryHookResult = ReturnType<
  typeof useGetDespatchAdviceSummaryByRolliIdLazyQuery
>;
export type GetDespatchAdviceSummaryByRolliIdQueryResult = Apollo.QueryResult<
  GetDespatchAdviceSummaryByRolliIdQuery,
  GetDespatchAdviceSummaryByRolliIdQueryVariables
>;
export const GetDespatchAdviceDeliveriesForHubDocument = gql`
  query getDespatchAdviceDeliveriesForHub {
    getDespatchAdviceDeliveriesForHub {
      despatchAdviceDeliveriesForHub {
        id
        estimatedDeliveryDate
        hubSlug
        ssccs
      }
    }
  }
`;

/**
 * __useGetDespatchAdviceDeliveriesForHubQuery__
 *
 * To run a query within a React component, call `useGetDespatchAdviceDeliveriesForHubQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDespatchAdviceDeliveriesForHubQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDespatchAdviceDeliveriesForHubQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDespatchAdviceDeliveriesForHubQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetDespatchAdviceDeliveriesForHubQuery,
    GetDespatchAdviceDeliveriesForHubQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetDespatchAdviceDeliveriesForHubQuery,
    GetDespatchAdviceDeliveriesForHubQueryVariables
  >(GetDespatchAdviceDeliveriesForHubDocument, options);
}
export function useGetDespatchAdviceDeliveriesForHubLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDespatchAdviceDeliveriesForHubQuery,
    GetDespatchAdviceDeliveriesForHubQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDespatchAdviceDeliveriesForHubQuery,
    GetDespatchAdviceDeliveriesForHubQueryVariables
  >(GetDespatchAdviceDeliveriesForHubDocument, options);
}
export type GetDespatchAdviceDeliveriesForHubQueryHookResult = ReturnType<
  typeof useGetDespatchAdviceDeliveriesForHubQuery
>;
export type GetDespatchAdviceDeliveriesForHubLazyQueryHookResult = ReturnType<
  typeof useGetDespatchAdviceDeliveriesForHubLazyQuery
>;
export type GetDespatchAdviceDeliveriesForHubQueryResult = Apollo.QueryResult<
  GetDespatchAdviceDeliveriesForHubQuery,
  GetDespatchAdviceDeliveriesForHubQueryVariables
>;
