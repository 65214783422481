import React, { useState } from "react";

import { Page, PageProps } from "shared/components/Page";
import { Spinner } from "ui/Spinner/Spinner";

type SwipeablePageWrapperProps = PageProps & {
  refetch: () => void;
};

export function SwipeablePageWrapper({ children, refetch, ...rest }: SwipeablePageWrapperProps) {
  const [clientX, setClientX] = useState(0);
  const [clientY, setClientY] = useState(0);
  const [showSpinner, setShowSpinner] = useState(false);

  const onTouchStart = (e: React.TouchEvent) => {
    setClientX(e.targetTouches[0].clientX);
    setClientY(e.targetTouches[0].clientY);
  };

  const onTouchMove = (e: React.TouchEvent) => {
    if (!clientX || !clientY) return;
    const currentClientX = e.targetTouches[0].clientX;
    const currentClientY = e.targetTouches[0].clientY;
    const xDiff = clientX - currentClientX;
    const yDiff = clientY - currentClientY;
    const SwipeUpToDown = Math.abs(xDiff) < Math.abs(yDiff) && yDiff < 0;
    if (SwipeUpToDown) {
      setShowSpinner(true);
    }
    setClientX(0);
    setClientY(0);
  };

  const onTouchEnd = () => {
    if (showSpinner) {
      setTimeout(() => {
        refetch();
      }, 800);
    }
    setShowSpinner(false);
  };
  const onTouchCancel = () => {
    setShowSpinner(false);
  };

  return (
    <Page
      isBgGrey
      onTouchStart={onTouchStart}
      onTouchMove={onTouchMove}
      onTouchEnd={onTouchEnd}
      onTouchCancel={onTouchCancel}
      {...rest}
    >
      {showSpinner && <Spinner boxSize={34} position="absolute" />}
      {children}
    </Page>
  );
}
