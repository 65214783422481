import { PageName } from "analytics/events";
import { useLayoutStore } from "core/stores/useLayoutStore";
import { BOTTOM_TAB_BAR_HEIGHT_NUMBER } from "ui/BottomTabBarContainer";

import { useSharedListCreator } from "../stores/useSharedListCreator";
import { EndDroppingProcessButton } from "./EndDroppingProcessButton";

export function SharingPreDropListButton() {
  const { pageName } = useLayoutStore((state) => ({
    pageName: state.pageName,
  }));

  const bottomHeight =
    pageName === PageName.INBOUND_PAGE_LIST_VERIFICATION ? 0 : BOTTOM_TAB_BAR_HEIGHT_NUMBER;

  const { handleSharingList } = useSharedListCreator();

  return (
    <EndDroppingProcessButton
      shouldShowButton
      onClickButton={handleSharingList}
      labelMessageId="pages.inbound.sharing-pre-drop-list.button"
      bottom={bottomHeight}
    />
  );
}
