import { useEffect, useState } from "react";

import { Button, ButtonProps, keyframes } from "@chakra-ui/react";
import { FormattedMessage } from "react-intl";

import { PlusSmallIcon, RotateIcon } from "ui/Icons/Icons";

const fadeOutAnimation = `${keyframes`
from { opacity: 1; }
to { opacity: 0; }
`} 0.4s ease-in 0.1s forwards`;

const spinAnimation = `${keyframes`
from { transform: rotate(90deg); }
to { transform: rotate(0deg); }
`} 1 0.2s linear`;

export const ANIMATION_STATE = {
  INITIAL: "initial",
  SHRINK: "shrink",
  FADE: "fade",
  SPIN: "spin",
  DONE: "done",
} as const;

export const ANIMATION_DURATION = {
  INITIAL: 0,
  SHRINK: 100,
  FADE: 400,
  SPIN: 300,
} as const;

export type AnimationState = typeof ANIMATION_STATE[keyof typeof ANIMATION_STATE];
export type AnimationDuration = typeof ANIMATION_DURATION[keyof typeof ANIMATION_DURATION];

export const useButtonAnimation = () => {
  const [animationState, setAnimationState] = useState<AnimationState>(ANIMATION_STATE.INITIAL);
  const handleAnimation = (state: AnimationState, delay: AnimationDuration) =>
    setTimeout(() => setAnimationState(state), delay);
  const triggerButtonShrink = () =>
    handleAnimation(ANIMATION_STATE.SHRINK, ANIMATION_DURATION.INITIAL);

  useEffect(() => {
    const timeouts: NodeJS.Timeout[] = [];

    if (animationState === ANIMATION_STATE.SHRINK) {
      timeouts.push(handleAnimation(ANIMATION_STATE.FADE, ANIMATION_DURATION.SHRINK));
    } else if (animationState === ANIMATION_STATE.FADE) {
      timeouts.push(handleAnimation(ANIMATION_STATE.SPIN, ANIMATION_DURATION.FADE));
    } else if (animationState === ANIMATION_STATE.SPIN) {
      timeouts.push(handleAnimation(ANIMATION_STATE.DONE, ANIMATION_DURATION.SPIN));
    }
    return () => timeouts.forEach((timeout) => clearTimeout(timeout));
  }, [animationState]);

  return { animationState, triggerButtonShrink };
};

type RestockingButtonProps = {
  disabled?: boolean;
  onAskForRestocking?: () => void;
} & ButtonProps;

export function AskForRestockingButton({
  disabled,
  onAskForRestocking = () => {},
  ...rest
}: RestockingButtonProps) {
  const { animationState, triggerButtonShrink } = useButtonAnimation();
  const handleButtonClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    triggerButtonShrink();
    onAskForRestocking();
  };

  const commonButtonProps = {
    height: "36px",
    width: "154px",
    overflow: "hidden",
    p: "2",
    color: "pinkFlink.500",
    bg: "pinkFlink.100",
    borderRadius: "sm",
    fontWeight: "bold",
    fontSize: "xs",
    variant: "unstyled",
    sx: { pointerEvents: "none" },
    ...rest,
  };

  if (animationState === ANIMATION_STATE.SHRINK) {
    return (
      <Button {...commonButtonProps} w="s300" transition="all 0.1s ease-in-out" disabled>
        <PlusSmallIcon boxSize={6} marginRight="s100" />
        <FormattedMessage id="components.picking.restocking.ask-for-restocking" />
      </Button>
    );
  }

  if (animationState === ANIMATION_STATE.FADE) {
    return (
      <Button {...commonButtonProps} w="s300">
        <PlusSmallIcon boxSize={6} animation={fadeOutAnimation} />
      </Button>
    );
  }

  if (animationState === ANIMATION_STATE.SPIN) {
    return (
      <Button {...commonButtonProps} w="s300">
        <RotateIcon boxSize={6} animation={spinAnimation} />
      </Button>
    );
  }

  if (animationState === ANIMATION_STATE.DONE || disabled) {
    return (
      <Button {...commonButtonProps} bg="pinkFlink.200" data-testid="restockRequestedButton">
        <RotateIcon boxSize={6} />
        <FormattedMessage id="components.picking.restocking.restock-requested" />
      </Button>
    );
  }

  return (
    <Button
      {...commonButtonProps}
      sx={{ pointerEvents: "auto" }}
      onClick={handleButtonClick}
      data-testid="askForRestockingButton"
    >
      <PlusSmallIcon boxSize={6} />
      <FormattedMessage id="components.picking.restocking.ask-for-restocking" />
    </Button>
  );
}
