import { useIntl } from "react-intl";

import { FacingsHuIcon, FacingsSuIcon } from "ui/Icons/Icons";
import { IconTextPill } from "ui/IconTextPill/IconTextPill";
import { NumberTextPill } from "ui/NumberTextPill/NumberTextPill";
import { ProductInfoBar } from "ui/ProductInfoBar/ProductInfoBar";
import { TitleXS } from "ui/Typography/Typography";
import { isNullOrUndefined } from "utils/tsHelpers";

export type ProductFacingProps = {
  isShelvedInHandlingUnits: boolean | null;
  numberOfFacings: number | null;
  bg?: string;
  hideTitle?: boolean;
};

export function ProductFacing({
  isShelvedInHandlingUnits,
  numberOfFacings,
  hideTitle = false,
  bg,
}: ProductFacingProps) {
  const intl = useIntl();
  const title = !hideTitle
    ? intl.formatMessage({ id: "components.product-facing.lable.facings" })
    : "";
  const isMissingInfo = !numberOfFacings || isNullOrUndefined(isShelvedInHandlingUnits);
  const { Icon, label, color, width } = isShelvedInHandlingUnits
    ? {
        label: intl.formatMessage(
          { id: "components.product-facing.lable.handling-units" },
          { count: numberOfFacings },
        ),
        color: "orange.500",
        width: "21px",
        Icon: FacingsHuIcon,
      }
    : {
        label: intl.formatMessage(
          { id: "components.product-facing.lable.single-units" },
          { count: numberOfFacings },
        ),
        color: "marine.500",
        width: "9px",
        Icon: FacingsSuIcon,
      };

  const leftSide = isMissingInfo ? (
    <TitleXS data-testid="facings-title">{title}</TitleXS>
  ) : (
    <IconTextPill icon={<Icon color={color} width={width} />} text={title} testId="facings-title" />
  );

  const rightSide = isMissingInfo ? (
    <TitleXS data-testid="facings-quantity">{numberOfFacings || "N/A"}</TitleXS>
  ) : (
    <NumberTextPill
      number={numberOfFacings}
      text={label}
      color={color}
      testIdNumber="facings-quantity"
      testIdText="facings-unit-type"
    />
  );

  return (
    <ProductInfoBar
      leftSide={leftSide}
      rightSide={rightSide}
      bg={bg}
      justifyContent={hideTitle ? "flex-start" : "space-between"}
      testId="product-facing"
    />
  );
}
