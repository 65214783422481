import React, { useMemo } from "react";

import { ApolloClient, ApolloProvider, from, HttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { useAuth0 } from "@auth0/auth0-react";

import { getDeviceId } from "analytics/utils";
import { config } from "config";
import { useEmployeeStore } from "core/stores/useEmployeeStore";
import { EppoFeatureFlags } from "core/types/flags";
import { useEppoFeatureFlagProvider } from "shared/hooks/useEppoFeatureFlag";

type CustomApolloProviderProps = {
  children: React.ReactNode;
};

const GRAPHQL_URL_HUB_ONE_CORE = `${config.environment.HUB_ONE_CORE_API_URL}/graphql`;

function generateApolloClient(
  getAccessToken: () => Promise<string>,
  apiUrl: string,
  isDeviceClaimingEnabled: boolean | null,
) {
  const authLink = setContext(async (_, { headers }) => {
    const newHeaders = { ...headers };

    const employeeId = useEmployeeStore.getState().badgeNo;
    if (employeeId) {
      newHeaders["x-employee-id"] = employeeId;
    }

    if (isDeviceClaimingEnabled) {
      newHeaders["x-device-id"] = getDeviceId();
    }

    try {
      const accessToken = await getAccessToken();
      newHeaders.authorization = `Bearer ${accessToken}`;
    } catch (e) {
      // eslint-disable-next-line
      console.error("Failed to retrieve access token");
    }

    return {
      headers: newHeaders,
    };
  });

  const httpLink = new HttpLink({
    uri: apiUrl,
  });

  const errorLink = onError(({ graphQLErrors }) => {
    if (graphQLErrors?.[0].extensions.code === "UNAUTHORIZED") {
      useEmployeeStore.setState({ isSetForLogout: true });
    }
  });

  return new ApolloClient({
    link: from([authLink, errorLink, httpLink]),
    cache: new InMemoryCache(),
  });
}

export function CustomApolloProvider({ children }: CustomApolloProviderProps) {
  const { getAccessTokenSilently } = useAuth0();
  const { isFeatureEnabled: isDeviceClaimingEnabled } = useEppoFeatureFlagProvider(
    EppoFeatureFlags.DEVICE_CLAIMING_PROCESS,
  );

  const client = useMemo(() => {
    return generateApolloClient(
      getAccessTokenSilently,
      GRAPHQL_URL_HUB_ONE_CORE,
      isDeviceClaimingEnabled,
    );
  }, [getAccessTokenSilently, isDeviceClaimingEnabled]);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}
