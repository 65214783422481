import { useSelector } from "@xstate/react";

import { useInboundService } from "flows/Inbound/hooks/useInboundService";

export function useProductSearchGuardsImplems() {
  const inboundService = useInboundService();
  const hasUnconfirmedQuantities = useSelector(
    inboundService,
    (state) => !!state.context.skusWithUnconfirmedQuantity.length,
  );

  const guardSearchIsEnabled = () => {
    return !hasUnconfirmedQuantities;
  };

  return {
    guardSearchIsEnabled,
  };
}
