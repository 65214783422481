import { useIntl } from "react-intl";

import { YesNoModal, YesNoModalProps } from "shared/components/YesNoModal";

export type PreDroppingItemRemovalModalProps = Pick<
  YesNoModalProps,
  "isOpen" | "onClickYes" | "onClickNo"
>;

export function PreDroppingItemRemovalModal({
  onClickYes,
  onClickNo,
  isOpen,
}: PreDroppingItemRemovalModalProps) {
  const intl = useIntl();
  return (
    <YesNoModal
      isOpen={isOpen}
      onClickYes={onClickYes}
      onClickNo={onClickNo}
      title={intl.formatMessage({
        id: "components.pre-dropping-item-removal-confirmation-modal.title",
      })}
      body={intl.formatMessage({
        id: "components.pre-dropping-item-removal-confirmation-modal.body",
      })}
      yesLabel={intl.formatMessage({
        id: "generic.actions.confirm",
      })}
      noLabel={intl.formatMessage({
        id: "generic.actions.cancel",
      })}
      isGrey
    />
  );
}
