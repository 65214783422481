import { ISupplier } from "__graphql__/types";

import { Supplier } from "./types";

export const serializeSupplier = ({
  id,
  name,
  sortSequence,
}: Pick<ISupplier, "id" | "name" | "sortSequence">): Supplier => ({
  id,
  name,
  sortSequence: sortSequence ?? null,
});
