import { cloneElement, ReactElement } from "react";

import { Flex, Tag } from "@chakra-ui/react";

import { BodyS } from "ui/Typography/Typography";

export type BottomTabBarItemProps = {
  id: string;
  icon: ReactElement;
  title: string;
  showNotificationTag?: boolean;
  onClick: (itemId: string) => void;
  isActive: boolean;
  testId?: string;
};

export function BottomTabBarItem({
  id,
  icon,
  title,
  showNotificationTag = false,
  isActive,
  onClick,
  testId,
}: BottomTabBarItemProps) {
  return (
    <Flex
      justifyContent="center"
      direction="column"
      alignItems="center"
      cursor="pointer"
      px="s200"
      data-testid={testId}
      onClick={() => !isActive && onClick(id)}
    >
      <Flex
        mb="s50"
        justify="center"
        align="center"
        w="50px"
        h="32px"
        position="relative"
        borderRadius="0.375rem"
        bg={isActive ? "pinkFlink.200" : undefined}
      >
        {cloneElement(icon, {
          boxSize: 6,
          color: isActive ? "pinkFlink.500" : "black",
        })}
        {showNotificationTag && (
          <Tag
            size="sm"
            pos="absolute"
            top="-3px"
            right="30px"
            transform="translateX(100%)"
            bg="pinkFlink.500"
            color="white"
            borderRadius="100px"
          />
        )}
      </Flex>
      <Flex align="center" height="18px">
        <BodyS>{title}</BodyS>
      </Flex>
    </Flex>
  );
}
