import { useCallback, useEffect, useRef } from "react";

import { FlexProps } from "@chakra-ui/react";
import { useSelector } from "@xstate/react";
import { useIntl } from "react-intl";
import shallow from "zustand/shallow";

import { useInboundUIStore } from "flows/Inbound/stores/useInboundUIStore";
import { useProductSearchService } from "shared/hooks/useProductSearchService";
import {
  ProductSearchBar as UIProductSearchBar,
  ProductSearchBarProps as UIProductSearchBarProps,
} from "ui/ProductSearchBar/ProductSearchBar";

type ProductSearchBarProps = {
  isSearchFlowActive: boolean;
  cancelSearchFlow?: UIProductSearchBarProps["onClickCancel"];
  activateTextSearch: UIProductSearchBarProps["onFocusSearchBar"];
} & FlexProps;

export function ProductSearchBar({
  isSearchFlowActive,
  cancelSearchFlow = () => {},
  activateTextSearch,
  ...rest
}: ProductSearchBarProps) {
  const productSearchInputRef = useRef<HTMLInputElement | null>(null);

  const intl = useIntl();
  const productSearchService = useProductSearchService();

  const { setInboundUIState, searchedUnitType } = useInboundUIStore(
    (state) => ({
      setInboundUIState: state.setInboundUIState,
      searchedUnitType: state.searchedUnitType,
    }),
    shallow,
  );

  useEffect(() => {
    if (productSearchService.getSnapshot().matches("active")) {
      productSearchInputRef.current?.focus();
    }
  }, [productSearchInputRef, productSearchService]);

  const textSearch = useSelector(productSearchService, (state) => state.context.textSearch);

  const onInputValueChange = useCallback(
    (newValue: string) => {
      productSearchService.send({ type: "RESOLVE_TEXT_SEARCH", query: newValue });
    },
    [productSearchService],
  );

  useEffect(() => {
    function handleScrollOutside() {
      if (productSearchInputRef.current) {
        productSearchInputRef.current.blur();
      }
    }
    document.addEventListener("touchmove", handleScrollOutside);
    return () => {
      document.removeEventListener("touchmove", handleScrollOutside);
    };
  }, [productSearchInputRef]);

  const searchBarSingleUnitsPlaceholder = isSearchFlowActive
    ? "pages.inbound.inbound-index.product-search-bar.single-units"
    : "pages.inbound.inbound-index.product-search-bar.placeholder";
  const searchBarHandlingUnitsPlaceholder = isSearchFlowActive
    ? "pages.inbound.inbound-index.product-search-bar.handling-units"
    : "pages.inbound.inbound-index.product-search-bar.placeholder";

  return (
    <UIProductSearchBar
      unitType={searchedUnitType}
      showUnitsToggle
      onChangeUnitType={(newUnitType) => setInboundUIState({ searchedUnitType: newUnitType })}
      inputValue={textSearch}
      onInputValueChange={onInputValueChange}
      singleUnitsPlaceholder={intl.formatMessage({
        id: searchBarSingleUnitsPlaceholder,
      })}
      handlingUnitsPlaceholder={intl.formatMessage({
        id: searchBarHandlingUnitsPlaceholder,
      })}
      cancelButtonPlaceholder={intl.formatMessage({
        id: "pages.inbound.inbound-index.product-search-bar.cancel",
      })}
      onClickCancel={cancelSearchFlow}
      onFocusSearchBar={activateTextSearch}
      showCancelButton={isSearchFlowActive}
      w="100%"
      p="s200"
      ref={productSearchInputRef}
      {...rest}
    />
  );
}
