import create from "zustand";

export type NewOrderBannerStore = {
  canShowBanner: boolean;
  userClosedBanner: boolean;
  pendingOrderId: string | null;
};

type NewOrderBannerStoreWithMethods = NewOrderBannerStore & {
  setNewOrderPending(orderId: string): void;
  setUserClosedBanner(): void;
  reset(): void;
};

const initialState: NewOrderBannerStore = {
  canShowBanner: false,
  userClosedBanner: false,
  pendingOrderId: null,
};

export const useNewOrderBannerStore = create<NewOrderBannerStoreWithMethods>()((set, get) => ({
  ...initialState,
  setNewOrderPending(orderId) {
    const { pendingOrderId, userClosedBanner } = get();
    if (pendingOrderId !== orderId) {
      set({
        canShowBanner: true,
        pendingOrderId: orderId,
        userClosedBanner: false,
      });
    } else {
      set({
        canShowBanner: !userClosedBanner,
      });
    }
  },
  setUserClosedBanner() {
    set({ canShowBanner: false, userClosedBanner: true });
  },
  reset() {
    set(initialState);
  },
}));
