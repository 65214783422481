import * as Types from "../../../__graphql__/types.js";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type GetNonScannableCategoriesQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GetNonScannableCategoriesQuery = {
  __typename?: "Query";
  getNonScannableCategories: {
    __typename?: "NonScannableCategoriesV2Response";
    categories: Array<{
      __typename?: "CategoryV2";
      id: string;
      name: string;
      subcategories: Array<{
        __typename?: "SubcategoryV2";
        id: string;
        imageUrl?: string | null;
        name: string;
        productsCount: number;
      }>;
    }>;
  };
};

export const GetNonScannableCategoriesDocument = gql`
  query getNonScannableCategories {
    getNonScannableCategories {
      categories {
        id
        name
        subcategories {
          id
          imageUrl
          name
          productsCount
        }
      }
    }
  }
`;

/**
 * __useGetNonScannableCategoriesQuery__
 *
 * To run a query within a React component, call `useGetNonScannableCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNonScannableCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNonScannableCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetNonScannableCategoriesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetNonScannableCategoriesQuery,
    GetNonScannableCategoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetNonScannableCategoriesQuery, GetNonScannableCategoriesQueryVariables>(
    GetNonScannableCategoriesDocument,
    options,
  );
}
export function useGetNonScannableCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetNonScannableCategoriesQuery,
    GetNonScannableCategoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetNonScannableCategoriesQuery,
    GetNonScannableCategoriesQueryVariables
  >(GetNonScannableCategoriesDocument, options);
}
export type GetNonScannableCategoriesQueryHookResult = ReturnType<
  typeof useGetNonScannableCategoriesQuery
>;
export type GetNonScannableCategoriesLazyQueryHookResult = ReturnType<
  typeof useGetNonScannableCategoriesLazyQuery
>;
export type GetNonScannableCategoriesQueryResult = Apollo.QueryResult<
  GetNonScannableCategoriesQuery,
  GetNonScannableCategoriesQueryVariables
>;
