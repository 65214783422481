import { useMemo } from "react";

import { AppLanguage, useAppLanguageStore } from "core/stores/useAppLanguageStore";

import German from "../../../i18n/de.json";
import English from "../../../i18n/en.json";
// Called "nl2.json" because of a bug in phrase app not allowing us to use "nl"
import Dutch from "../../../i18n/nl2.json";

export function useLocalizedMessages() {
  const appLanguage = useAppLanguageStore((state) => state.appLanguage);

  const messages = useMemo(() => {
    return {
      [AppLanguage.Nl]: Dutch,
      [AppLanguage.De]: German,
      [AppLanguage.En]: English,
    }[appLanguage];
  }, [appLanguage]);

  return messages;
}
