import { ReactElement } from "react";

import { Flex, FlexProps } from "@chakra-ui/react";
import { FormattedMessage } from "react-intl";

import { IntlMessageId } from "shared/types/lang";
import { Button } from "ui/Button/Button";
import { HeaderS, TitleS } from "ui/Typography/Typography";
import { getDisplayedDate } from "utils/formats";

const dateParts = ["day", "month", "year"];
const datePartsIntlMessageIds = dateParts.map(
  (datePart) =>
    `flows.inventory.bbd-checks.pages.bbd-check-declare-closest-bdd.${datePart}` as IntlMessageId,
);
type BbdClosestExpirationDateProps = {
  date?: Date;
  titleElement: ReactElement;
  onClickAdjustBbdButton: () => void;
} & FlexProps;

export function BbdClosestExpirationDate({
  date,
  onClickAdjustBbdButton,
  titleElement,
  ...rest
}: BbdClosestExpirationDateProps) {
  const datePartsElements = (date ? getDisplayedDate(date) : "--.--.--")
    .split(".")
    .map((datePart, i) => ({
      datePart: dateParts[i],
      messageId: datePartsIntlMessageIds[i],
      value: datePart,
    }));

  return (
    <Flex
      direction="column"
      w="100%"
      bg="white"
      alignItems="center"
      p="s300"
      textAlign="center"
      {...rest}
    >
      {titleElement}
      <Flex width="200px" mt="s300" justifyContent="space-between">
        {datePartsElements.map(({ messageId, value, datePart }) => (
          <Flex key={messageId} direction="column">
            <TitleS color="grey.700">
              <FormattedMessage id={messageId} />
            </TitleS>
            <HeaderS mt="s150" data-testid={`bbd-closest-expiration-date-${datePart}`}>
              {value}
            </HeaderS>
          </Flex>
        ))}
      </Flex>
      <Button
        onClick={onClickAdjustBbdButton}
        data-testid="open-adjust-bbd-modal-button"
        minWidth="255px"
        mt="s300"
        flinkVariant="secondary"
      >
        <FormattedMessage id="flows.inventory.bbd-checks.pages.bbd-check-declare-closest-bdd.adjust-date" />
      </Button>
    </Flex>
  );
}
