import { Flex, FlexProps } from "@chakra-ui/react";

type ProductInfoBarProps = {
  leftSide: JSX.Element;
  rightSide: JSX.Element;
  testId?: string;
} & FlexProps;

export function ProductInfoBar({
  leftSide,
  rightSide,
  testId,
  bg = "grey.100",
  justifyContent = "space-between",
  ...rest
}: ProductInfoBarProps) {
  return (
    <Flex
      alignItems="center"
      bg={bg}
      justifyContent={justifyContent}
      px="s100"
      py="s50"
      borderRadius="sm"
      mt="s100"
      data-testid={testId}
      {...rest}
    >
      {leftSide}
      {rightSide}
    </Flex>
  );
}
