import { Circle, Flex } from "@chakra-ui/react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router";

import { TaskType } from "__graphql__/types";
import { routes } from "config/routes";
import { useAppBackgroundEffectsStore } from "core/stores/useAppBackgroundEffectsStore";
import { EppoFeatureFlags } from "core/types/flags";
import { Page } from "shared/components/Page";
import { useEppoFeatureFlagProvider } from "shared/hooks/useEppoFeatureFlag";
import { useGetPendingCheckCountQuery } from "shared/queries/checkCount/checkCount.generated";
import { ArrowRightIcon } from "ui/Icons/Icons";
import { BodyS, TitleM, TitleXS } from "ui/Typography/Typography";
import { isNotNullNorUndefined } from "utils/tsHelpers";

type InventoryElemProps = {
  label: string;
  count?: number;
  dataTestId?: string;
  onClick: () => void;
};

function InventoryElem({ label, count, dataTestId, onClick }: InventoryElemProps) {
  return (
    <Flex
      w="100%"
      alignItems="center"
      px="s200"
      py="s150"
      bg="white"
      borderBottom="1px solid"
      borderBottomColor="grey.200"
      data-testid={dataTestId}
      onClick={onClick}
    >
      <BodyS>{label}</BodyS>
      {isNotNullNorUndefined(count) && (
        <Circle minW="20px" minH="20px" px="s50" bg="grey.600" ml="s100">
          <TitleXS color="white">{count}</TitleXS>
        </Circle>
      )}
      <ArrowRightIcon boxSize="1.5rem" ml="auto" />
    </Flex>
  );
}

export function InventoryPage() {
  const intl = useIntl();
  const navigate = useNavigate();

  const { isFeatureEnabled: showEoyChecks } = useEppoFeatureFlagProvider(
    EppoFeatureFlags.EOY_SHOW_CHECKS,
  );
  const { isFeatureEnabled: isEoyChecksEnabled } = useEppoFeatureFlagProvider(
    EppoFeatureFlags.EOY_CHECKS,
  );
  const { isFeatureEnabled: isEOYHideCorrectionsEnabled } = useEppoFeatureFlagProvider(
    EppoFeatureFlags.EOY_HIDE_CORRECTIONS,
  );

  const publicRestockingList =
    useAppBackgroundEffectsStore((state) => state.publicRestockingList)?.getPublicRestockingList
      .publicRestockingList.restockingItems.length ?? 0;

  const { data: freshCheckCountData } = useGetPendingCheckCountQuery({
    fetchPolicy: "network-only",
    variables: { input: { type: [TaskType.freshness_check] } },
  });

  const pendingFreshChecks = freshCheckCountData?.getOpenedChecksCountForHub.checkCount ?? 0;

  const { data: bbdCheckCountData } = useGetPendingCheckCountQuery({
    fetchPolicy: "network-only",
    variables: { input: { type: [TaskType.bbd_check] } },
  });

  const pendingBbdChecks = bbdCheckCountData?.getOpenedChecksCountForHub.checkCount ?? 0;

  const { data: stockCheckCountData } = useGetPendingCheckCountQuery({
    fetchPolicy: "network-only",
    variables: { input: { type: [TaskType.stock_check] } },
  });

  const pendingStockChecks = stockCheckCountData?.getOpenedChecksCountForHub.checkCount ?? 0;

  return (
    <Page isCentered isFull isBgGrey justifyContent="flex-start">
      <TitleM m="s200" alignSelf="flex-start">
        {intl.formatMessage({ id: "pages.inventory.title" })}
      </TitleM>
      {showEoyChecks && (
        <>
          <InventoryElem
            label={intl.formatMessage({ id: "pages.inventory.eoy-checks.title" })}
            onClick={() => {
              navigate(routes.inventory.eoyChecks.root);
            }}
            dataTestId="eoy-checks-link"
          />
          <InventoryElem
            label={intl.formatMessage({ id: "pages.inventory.recount-checks.title" })}
            onClick={() => {
              navigate(routes.inventory.eoyRecountChecks.root);
            }}
            dataTestId="recount-checks-link"
          />
        </>
      )}
      {!isEoyChecksEnabled && (
        <>
          <InventoryElem
            label={intl.formatMessage({ id: "pages.inventory.restocking-list.title" })}
            onClick={() => {
              navigate(routes.inventory.restockingList.root);
            }}
            dataTestId="restocking-list-link"
            count={publicRestockingList}
          />
          {pendingFreshChecks > 0 && (
            <InventoryElem
              label={intl.formatMessage({ id: "pages.inventory.fresh-checks.title" })}
              onClick={() => {
                navigate(routes.inventory.freshChecks.root);
              }}
              dataTestId="fresh-checks-link"
              count={pendingFreshChecks}
            />
          )}
          {pendingBbdChecks > 0 && (
            <InventoryElem
              label={intl.formatMessage({ id: "pages.inventory.bbd-checks.title" })}
              onClick={() => {
                navigate(routes.inventory.bbdChecks.root);
              }}
              dataTestId="bbd-checks-link"
              count={pendingBbdChecks}
            />
          )}
          <InventoryElem
            label={intl.formatMessage({ id: "pages.inventory.bbd-correction.title" })}
            onClick={() => {
              navigate(routes.inventory.bbdCorrections.root);
            }}
            dataTestId="bbd-correction-link"
          />
        </>
      )}
      {!isEOYHideCorrectionsEnabled && (
        <>
          {pendingStockChecks > 0 && (
            <InventoryElem
              label={intl.formatMessage({ id: "pages.inventory.stock-checks.title" })}
              onClick={() => {
                navigate(routes.inventory.stockChecks.root);
              }}
              dataTestId="stock-checks-link"
              count={pendingStockChecks}
            />
          )}
          <InventoryElem
            label={intl.formatMessage({ id: "pages.inventory.stock-correction.title" })}
            onClick={() => {
              navigate(routes.inventory.stockCorrections.root);
            }}
            dataTestId="stock-correction-link"
          />
        </>
      )}
    </Page>
  );
}
