import { Box, Flex, Image } from "@chakra-ui/react";

import { TranslatedProductSearchUnit } from "shared/models/productSearchUnit/types";
import { DetailM, TitleXS } from "ui/Typography/Typography";
import { isNotNullNorUndefined } from "utils/tsHelpers";

type ProductSearchResultDataProps = Pick<
  TranslatedProductSearchUnit,
  | "productName"
  | "countryOfOriginName"
  | "shelfNumber"
  | "shelfLetter"
  | "unitType"
  | "productImageUrl"
  | "productSku"
>;

export type ProductSearchResultCardProps = ProductSearchResultDataProps & {
  singleUnitLabel: string;
  handlingUnitLabel: string;
  onClickProductCard: () => void;
  isHighlighted: boolean;
};

export function ProductSearchResultCard({
  productName,
  unitType,
  singleUnitLabel,
  handlingUnitLabel,
  shelfLetter,
  shelfNumber,
  productSku,
  productImageUrl,
  onClickProductCard,
  countryOfOriginName,
  isHighlighted,
}: ProductSearchResultCardProps) {
  const displayedShelf =
    isNotNullNorUndefined(shelfNumber) && isNotNullNorUndefined(shelfLetter)
      ? `${shelfNumber}${shelfLetter}`
      : "n/a";

  const displayedProductName = `${productName}${
    isNotNullNorUndefined(countryOfOriginName) ? ` (${countryOfOriginName})` : ""
  }`;

  return (
    <Flex
      onClick={onClickProductCard}
      alignItems="center"
      p="s200"
      data-testid="product-search-result"
      pos="relative"
      bg="white"
    >
      <Box
        pos="absolute"
        bg="grey.800"
        top="0"
        left="0"
        height="100%"
        width="100%"
        transition="opacity 50ms linear"
        opacity={isHighlighted ? 0.1 : 0}
      />
      <Flex
        bg="grey.100"
        borderRadius="8px"
        h="80px"
        w="80px"
        alignItems="center"
        justifyContent="center"
        flexShrink={0}
      >
        {isNotNullNorUndefined(productImageUrl) && (
          <Image maxHeight="100%" objectFit="contain" src={productImageUrl} />
        )}
      </Flex>
      <Flex direction="column" ml="s200">
        <TitleXS color="grey.800" fontWeight={700} noOfLines={2}>
          {displayedProductName}
        </TitleXS>
        <TitleXS color="grey.800">
          {unitType === "single" ? singleUnitLabel : handlingUnitLabel}
        </TitleXS>
        <DetailM color="grey.600">{`${displayedShelf} - SKU ${productSku}`}</DetailM>
      </Flex>
    </Flex>
  );
}
