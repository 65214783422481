import { Flex, FlexProps } from "@chakra-ui/react";

import { OfflineIcon } from "ui/Icons/Icons";
import { TitleM } from "ui/Typography/Typography";

type BannerProps = {
  title: string;
} & FlexProps;

export function ConnectivityBanner({ title, ...rest }: BannerProps) {
  return (
    <Flex w="100%" bg="error" paddingY="s50" paddingX="s200" {...rest}>
      <OfflineIcon alignSelf="center" color="white" h="20px" w="20px" mr="s100" />
      <TitleM color="white">{title}</TitleM>
    </Flex>
  );
}
