import { Button as ChakraButton, ButtonProps } from "@chakra-ui/react";

export type PrimaryButtonProps = ButtonProps & { flinkVariant?: "primary" | "secondary" };

const primaryStyle: ButtonProps = {
  color: "white",
  backgroundColor: "pinkFlink.500",
  _loading: {
    backgroundColor: "pinkFlink.500",
  },
  _active: {
    backgroundColor: "pinkFlink.400",
  },
  _hover: {
    backgroundColor: "pinkFlink.400",
  },
};
const secondaryStyle: ButtonProps = {
  color: "pinkFlink.500",
  backgroundColor: "pinkFlink.100",
  _active: {
    backgroundColor: "pinkFlink.200",
  },
  _hover: {
    backgroundColor: "pinkFlink.200",
  },
};

export function Button({ children, flinkVariant = "primary", ...rest }: PrimaryButtonProps) {
  const buttonStyle = flinkVariant === "primary" ? primaryStyle : secondaryStyle;
  return (
    <ChakraButton
      {...buttonStyle}
      colorScheme="pinkFlink"
      _disabled={{
        backgroundColor: "grey.200",
        color: "white",
        _hover: {
          backgroundColor: "grey.200",
          color: "white",
        },
      }}
      whiteSpace="normal"
      {...rest}
    >
      {children}
    </ChakraButton>
  );
}
