// shelf.match(/\D+|\d+/g)
// finds ALL matches of
// -- multiple non-digits
// -- multiple digits

// splits {shelf} (usually following pattern 001A, 123C, 999X, 001.1A)
// into { shelfNumber: "001", "shelfLetter: "A" }
// into { shelfNumber: "001.1", "shelfLetter: "A" }
//
// Technically, it finds all matches of
// - multiple non-digits
// - or multiple digits (with optional dot and digit)
// and puts them in found order into shelfNumber and shelfLetter
// 001A -> [001, A]
// 001.1A -> [001.1, A]
// A001 -> [A, 001] (this looks like unwanted behavior)
//
// Supports "001A" and "001.1A" shelf number formats
export function getShelfDetail(shelf: string) {
  const explode = shelf.match(/\D+|\d+(?:\.\d)?/g);

  return {
    shelfNumber: explode === null ? "???" : explode[0],
    shelfLetter: explode === null ? "?" : explode[1],
  };
}

type ItemWithShelf = {
  shelfNumber?: string | null;
  shelfLetter?: string | null;
  shelf?: string | null;
  name?: string | null;
};

export type SortOrder = "asc" | "desc";

// compares two {ItemWithShelf} returning number indicating order
// When {order} is "asc" returns:
// * -1 when a < b
// * 1 when a > b
// * 0 when a == b
// When {order} is "desc" values are multiplied
// * 1 when a < b
// * -1 when a > b
// * -0 when a == b
// Supports "001A" and "001.1A" shelf number formats
export function sortByShelf(a: ItemWithShelf, b: ItemWithShelf, order: SortOrder = "asc") {
  const orderMultiplier = order === "asc" ? 1 : -1;
  const sortRes = (() => {
    if (a.shelfNumber && a.shelfLetter && b.shelfNumber && b.shelfLetter) {
      if (a.shelfNumber === b.shelfNumber) {
        return a.shelfLetter < b.shelfLetter ? -1 : 1;
      }
      // rackKeys are: "001", "001.1", etc.
      // We want to order them as float numbers
      // "001" becomes 1.0
      // "001.1" becomes 1.1
      // now 1.0 is less than 1.1
      const rackKeyA = parseFloat(a.shelfNumber);
      const rackKeyB = parseFloat(b.shelfNumber);
      return rackKeyA < rackKeyB ? -1 : 1;
    }
    // put item without {shelf} as last
    if (!a.shelf && b.shelf) {
      return 1;
    }
    if (a.shelf && !b.shelf) {
      return -1;
    }

    // compare by name
    return a.name?.localeCompare(b.name ?? "", "en-US") ?? 0;
  })();
  return sortRes * orderMultiplier;
}
