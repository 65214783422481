import * as Types from "../../../__graphql__/types.js";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type StartInboundingMutationVariables = Types.Exact<{
  input: Types.StartInboundingInput;
}>;

export type StartInboundingMutation = {
  __typename?: "Mutation";
  startInbounding: { __typename?: "StartInboundingResponse"; timestamp: any };
};

export type EndInboundingMutationVariables = Types.Exact<{
  input: Types.EndInboundingInput;
}>;

export type EndInboundingMutation = {
  __typename?: "Mutation";
  endInbounding: { __typename?: "EndInboundingResponse"; timestamp: any };
};

export const StartInboundingDocument = gql`
  mutation startInbounding($input: StartInboundingInput!) {
    startInbounding(input: $input) {
      timestamp
    }
  }
`;
export type StartInboundingMutationFn = Apollo.MutationFunction<
  StartInboundingMutation,
  StartInboundingMutationVariables
>;

/**
 * __useStartInboundingMutation__
 *
 * To run a mutation, you first call `useStartInboundingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartInboundingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startInboundingMutation, { data, loading, error }] = useStartInboundingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStartInboundingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    StartInboundingMutation,
    StartInboundingMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<StartInboundingMutation, StartInboundingMutationVariables>(
    StartInboundingDocument,
    options,
  );
}
export type StartInboundingMutationHookResult = ReturnType<typeof useStartInboundingMutation>;
export type StartInboundingMutationResult = Apollo.MutationResult<StartInboundingMutation>;
export type StartInboundingMutationOptions = Apollo.BaseMutationOptions<
  StartInboundingMutation,
  StartInboundingMutationVariables
>;
export const EndInboundingDocument = gql`
  mutation endInbounding($input: EndInboundingInput!) {
    endInbounding(input: $input) {
      timestamp
    }
  }
`;
export type EndInboundingMutationFn = Apollo.MutationFunction<
  EndInboundingMutation,
  EndInboundingMutationVariables
>;

/**
 * __useEndInboundingMutation__
 *
 * To run a mutation, you first call `useEndInboundingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEndInboundingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [endInboundingMutation, { data, loading, error }] = useEndInboundingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEndInboundingMutation(
  baseOptions?: Apollo.MutationHookOptions<EndInboundingMutation, EndInboundingMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EndInboundingMutation, EndInboundingMutationVariables>(
    EndInboundingDocument,
    options,
  );
}
export type EndInboundingMutationHookResult = ReturnType<typeof useEndInboundingMutation>;
export type EndInboundingMutationResult = Apollo.MutationResult<EndInboundingMutation>;
export type EndInboundingMutationOptions = Apollo.BaseMutationOptions<
  EndInboundingMutation,
  EndInboundingMutationVariables
>;
