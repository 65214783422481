import { Flex, FlexProps } from "@chakra-ui/react";

import { ShelfLetter } from "ui/ShelfLetter/ShelfLetter";
import { HeaderM, TitleM } from "ui/Typography/Typography";

type ShelfProps = {
  letter: string;
  number: string;
  variant?: "big" | "small";
} & FlexProps;

export function Shelf({ letter, number, variant = "big", ...rest }: ShelfProps) {
  return (
    <Flex alignItems="center" {...rest}>
      {variant === "big" ? (
        <HeaderM data-testid="shelf-number" mr="s100">
          {number}
        </HeaderM>
      ) : (
        <TitleM data-testid="shelf-number" mr="s50">
          {number}
        </TitleM>
      )}
      <ShelfLetter data-testid="shelf-letter" letter={letter} variant={variant} />
    </Flex>
  );
}
