import { useCallback } from "react";

import { isApolloError } from "@apollo/client";

import { isNullOrUndefined } from "utils/tsHelpers";

import { ActionAddInboundUnitToDroppingList, InboundMachineContext } from "./types";

export function useInboundGuardsImplems() {
  const shouldShowDesAdvModalGuard = useCallback((_: InboundMachineContext, event: any) => {
    if (isApolloError(event.data)) {
      const errorCode = event.data.graphQLErrors?.[0]?.extensions?.code;
      return errorCode === "DESPATCH_ADVICE_NOT_FOUND";
    }
    return false;
  }, []);

  const canAddInboundUnitToList = useCallback(
    (context: InboundMachineContext, event: ActionAddInboundUnitToDroppingList) => {
      return isNullOrUndefined(context.inboundUnitsMap[event.inboundUnit.productSku]);
    },
    [],
  );

  return {
    shouldShowDesAdvModalGuard,
    canAddInboundUnitToList,
  };
}
