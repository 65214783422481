import { useCallback, useMemo, useState } from "react";

import { Box, Flex, IconButton, Tag } from "@chakra-ui/react";
import { groupBy, orderBy } from "lodash";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router";

import { RESTOCKING_PROGRESSED_METHOD } from "analytics/events";
import { routes } from "config/routes";
import { EppoFeatureFlags } from "core/types/flags";
import { EndDroppingProcessButton } from "flows/Inbound/components/EndDroppingProcessButton";
import { Page } from "shared/components/Page";
import { useCustomToast } from "shared/hooks/useCustomToast";
import { useEppoFeatureFlagProvider } from "shared/hooks/useEppoFeatureFlag";
import { AscDscIcon, AscDscIconProps } from "ui/AscDscIcon/AscDscIcon";
import { NavigationHeader } from "ui/NavigationHeader/NavigationHeader";
import { ProductInformation } from "ui/ProductInformation/ProductInformation";
import { Shelf } from "ui/Shelf/Shelf";
import { Status } from "ui/Status/Status";
import { TitleS, TitleXS } from "ui/Typography/Typography";
import { getShelfDetail } from "utils/item";
import { isNullOrUndefined } from "utils/tsHelpers";

import { PublicRestockingItem } from "../../models/restockingItem/types";
import {
  usePrivateRestockingList,
  usePublicRestockingList,
} from "../../services/restockingService/service";
import { useRestockingServiceStore } from "../../stores/restockingServiceStore";

const CREATE_PRIVATE_RESTOCKING_LIST_ERROR_TOAST_ID = "create_private_restocking_list_error_toast";
const CREATE_PRIVATE_RESTOCKING_LIST_SUCCESS_TOAST_ID =
  "create_private_restocking_list_success_toast";

function ProductList({
  shelf,
  items,
  selectedRestockingItems,
  setSelectedRestockingItems,
}: {
  shelf: string;
  items: PublicRestockingItem[];
  selectedRestockingItems: string[];
  setSelectedRestockingItems: (selectedRestockingItems: string[]) => void;
}) {
  const { shelfNumber: number, shelfLetter: letter } = getShelfDetail(shelf);
  const displayedShelf =
    shelf === "N/A" ? (
      <Status bg="grey.200" textColor="black" gridColumnStart="3" label="N/A" size="small" />
    ) : (
      <Shelf letter={letter} number={number} variant="small" />
    );
  const onClickProductCard = useCallback(
    (selectedItem: string) => () => {
      setSelectedRestockingItems(
        selectedRestockingItems.includes(selectedItem)
          ? selectedRestockingItems.filter((restockingItem) => restockingItem !== selectedItem)
          : [...selectedRestockingItems, selectedItem],
      );
    },
    [selectedRestockingItems, setSelectedRestockingItems],
  );
  return (
    <>
      <Flex px={4} py={2} alignItems="center" bg="grey.150">
        {displayedShelf}
      </Flex>
      {items.map((item) => (
        <Flex
          onClick={onClickProductCard(item.sku)}
          alignItems="center"
          py="s200"
          px="s100"
          pos="relative"
          bg="white"
          key={item.sku}
          data-testid="productCard"
        >
          <Box
            pos="absolute"
            bg="grey.800"
            top="0"
            left="0"
            height="100%"
            width="100%"
            transition="opacity 50ms linear"
            opacity={
              selectedRestockingItems.some((restockingItem) => restockingItem === item.sku)
                ? 0.1
                : 0
            }
          />
          <ProductInformation
            name={item.product?.name || ""}
            imageUrl={item.product?.imageUrl}
            sku={item.sku}
            variantSize="s"
            bg="none"
            stock={item.product?.stockOnShelf ?? "N/A"}
            stockStatus="validated"
          />
        </Flex>
      ))}
    </>
  );
}

export function PublicList({ sortState }: { sortState: AscDscIconProps["sortState"] }) {
  const intl = useIntl();
  const { showToastUI } = useCustomToast();

  const navigate = useNavigate();

  const { restockingItems } = usePublicRestockingList();

  const [selectedRestockingItems, setSelectedRestockingItems] = useState<string[]>([]);
  const [isLoadingItemsToPrivateList, setIsLoadingItemsToPrivateList] = useState(false);

  const sortedAndGroupedRestockingItems = useMemo(() => {
    const grouped = groupBy(restockingItems, (item) => {
      const shelf = item.product?.shelf;
      return isNullOrUndefined(shelf) ? "N/A" : shelf;
    });
    const orderedGroups = orderBy(Object.entries(grouped), ([shelf]) => shelf, [sortState]);
    return orderedGroups;
  }, [restockingItems, sortState]);

  const { addItemsToPrivateRestockingList } = usePrivateRestockingList({ loadOnInit: false });

  const loadItemsToPrivateList = useCallback(
    async (skus: string[]) => {
      try {
        setIsLoadingItemsToPrivateList(true);
        await addItemsToPrivateRestockingList(skus, RESTOCKING_PROGRESSED_METHOD.PRE_LOADED_LIST);
        navigate(routes.inventory.restockingList.root);
        showToastUI({
          id: CREATE_PRIVATE_RESTOCKING_LIST_SUCCESS_TOAST_ID,
          status: "success",
          containerStyle: {
            width: "100%",
            bottom: "150px",
            position: "absolute",
            paddingLeft: "s100",
            paddingRight: "s100",
          },
          title: "components.picking.restocking.public-restocking-items-success-title",
        });
      } catch (error) {
        showToastUI({
          id: CREATE_PRIVATE_RESTOCKING_LIST_ERROR_TOAST_ID,
          title: "flows.inventory.restocking-list.add-items-to-private-list-error",
        });
        setIsLoadingItemsToPrivateList(false);
      }
    },
    [addItemsToPrivateRestockingList, navigate, showToastUI],
  );

  const onClickAddItemToList = useCallback(() => {
    loadItemsToPrivateList(selectedRestockingItems);
  }, [loadItemsToPrivateList, selectedRestockingItems]);

  const onClickAddAllItemsToList = useCallback(() => {
    if (restockingItems) {
      const allSkus = restockingItems.map((item) => item.sku);
      loadItemsToPrivateList(allSkus);
    }
  }, [loadItemsToPrivateList, restockingItems]);

  return (
    <>
      <Flex w="100%" justifyContent="flex-end" alignItems="center" h="36px" pr={3} py={2} my={2}>
        <TitleS>
          <FormattedMessage id="flows.inventory.restocking-list.pages.list-of-items.subtitle" />
          <Tag size="sm" bg="pinkFlink.500" color="white" borderRadius="100px" ml="s100">
            {restockingItems?.length ?? 0}
          </Tag>
        </TitleS>
      </Flex>
      <Flex direction="column" w="100%" flex={1} gap={2} bg="grey.150" paddingBottom={16}>
        {restockingItems === null ? (
          <TitleXS color="grey.800" fontWeight={700} noOfLines={2}>
            {intl.formatMessage({
              id: "flows.inventory.restocking-list.pages.list-of-items.paragraph.something-went-wrong",
            })}
          </TitleXS>
        ) : (
          <>
            {sortedAndGroupedRestockingItems.map(([shelf, items]) => (
              <ProductList
                key={shelf}
                shelf={shelf}
                items={items}
                selectedRestockingItems={selectedRestockingItems}
                setSelectedRestockingItems={setSelectedRestockingItems}
              />
            ))}
            <EndDroppingProcessButton
              shouldShowButton={restockingItems.length > 0}
              isLoading={isLoadingItemsToPrivateList}
              onClickButton={
                selectedRestockingItems.length > 0 ? onClickAddItemToList : onClickAddAllItemsToList
              }
              labelMessageId={
                selectedRestockingItems.length > 0
                  ? "flows.inventory.restocking-list.pages.list-of-items.button.add-items-to-the-list"
                  : "flows.inventory.restocking-list.pages.list-of-items.button.add-all-items"
              }
              buttonTestId="add-items-to-restocking-list-button"
              bottom="0"
            />
          </>
        )}
      </Flex>
    </>
  );
}

export function RestockingPublicList() {
  const intl = useIntl();
  const navigate = useNavigate();

  const [sortState, setSortState] = useState<AscDscIconProps["sortState"]>("asc");

  const { isFeatureEnabled: isNewRestocking } = useEppoFeatureFlagProvider(
    EppoFeatureFlags.NEW_RESTOCKING,
  );

  const { publicListSortState } = useRestockingServiceStore((state) => ({
    publicListSortState: state.publicListSortState,
  }));

  if (isNewRestocking) return <PublicList sortState={publicListSortState} />;

  return (
    <Page isFull isBgGrey h="100%" justifyContent="start">
      <NavigationHeader
        title={intl.formatMessage({
          id: "flows.inventory.restocking-list.pages.list-of-items.title",
        })}
        onClickGoBack={() => navigate(routes.inventory.restockingList.root)}
        rightElement={
          <IconButton
            size="xs"
            variant="ghost"
            aria-label="Change sort order"
            icon={<AscDscIcon height="24px" width="24px" sortState={sortState} bg="white" />}
            onClick={() => setSortState((pre) => (pre === "asc" ? "desc" : "asc"))}
            data-testid="asc-dsc-icon"
          />
        }
      />
      <PublicList sortState={sortState} />
    </Page>
  );
}
