import create from "zustand";

import { GetPublicRestockingListQuery } from "shared/queries/publicRestockingList/publicRestockingList.generated";

export type SummaryOfNextOrderForPicking = {
  id: string;
  number: string | null;
} | null;

export type AppBackgroundEffectsStore = {
  summaryOfNextOrderForPicking: SummaryOfNextOrderForPicking;
  hasOpenUrgentChecks: boolean;
  publicRestockingList: GetPublicRestockingListQuery | null;
};

type AppBackgroundEffectsStoreWithMethods = AppBackgroundEffectsStore & {
  setAppBackgroundEffectsStore(state: Partial<AppBackgroundEffectsStore>): void;
};

const initialState: AppBackgroundEffectsStore = {
  summaryOfNextOrderForPicking: null,
  hasOpenUrgentChecks: false,
  publicRestockingList: null,
};

export const useAppBackgroundEffectsStore = create<AppBackgroundEffectsStoreWithMethods>()(
  (set) => ({
    ...initialState,
    setAppBackgroundEffectsStore(state) {
      set(state);
    },
  }),
);
