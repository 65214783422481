import { useCallback } from "react";

import { Modal, ModalBody, ModalContent, ModalFooter, ModalOverlay } from "@chakra-ui/react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";

import { routes } from "config/routes";
import { Button } from "ui/Button/Button";
import { BodyM, HeaderS } from "ui/Typography/Typography";

import { useInboundStore } from "../stores/inboundStore/useInboundStore";

export function UnclaimedListConfirmationModal() {
  const navigate = useNavigate();
  const setInboundUIState = useInboundStore((state) => state.setInboundUIState);
  const handleOnClick = useCallback(() => {
    setInboundUIState({ isUnclaimedListConfirmationModalVisible: false });
    navigate(routes.inbound.root);
  }, [navigate, setInboundUIState]);
  return (
    <Modal trapFocus={false} closeOnOverlayClick={false} isCentered isOpen onClose={() => {}}>
      <ModalOverlay bg="rgba(0,0,0,0.2)" />
      <ModalContent marginX="s300">
        <ModalBody textAlign="center" paddingY="0" paddingX="s200">
          <HeaderS mt="s300">
            <FormattedMessage id="components.unclaimed-list-confirmation-modal.title" />
          </HeaderS>
          <BodyM mt="s300" color="grey.700">
            <FormattedMessage id="components.unclaimed-list-confirmation-modal.body" />
          </BodyM>
        </ModalBody>
        <ModalFooter>
          <Button
            h="54px"
            mt="s100"
            onClick={handleOnClick}
            width="full"
            flinkVariant="primary"
            size="lg"
          >
            <FormattedMessage id="generic.actions.okay" />
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
