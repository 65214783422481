import * as Types from "../../../../__graphql__/types.js";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type GetDeliveryCheckInsForHubDeliveredTodayQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type GetDeliveryCheckInsForHubDeliveredTodayQuery = {
  __typename?: "Query";
  getDeliveryCheckInsForHubDeliveredToday: {
    __typename?: "GetDeliveryCheckinsResponse";
    deliveryCheckins: Array<{
      __typename?: "IDeliveryCheckin";
      categories?: Array<Types.DeliveryCheckinProductCategory> | null;
      delivered_at: string;
      id: string;
      supplier: { __typename?: "ISupplierSummary"; id: string; name: string };
    }>;
  };
};

export type GetDeliveryCheckInsForHubDeliveredTodayV2QueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type GetDeliveryCheckInsForHubDeliveredTodayV2Query = {
  __typename?: "Query";
  getDeliveryCheckInsForHubDeliveredTodayV2: {
    __typename?: "GetDeliveryCheckinsResponseV2";
    deliveryCheckins: Array<{
      __typename?: "IDeliveryCheckinV2";
      deliveredAt: string;
      id: string;
      numberOfAcceptedRollies: number;
      supplier: { __typename?: "ISupplierSummary"; id: string; name: string };
    }>;
  };
};

export type CreateDeliveryCheckinForHubMutationVariables = Types.Exact<{
  input: Types.NewDeliveryCheckinInput;
}>;

export type CreateDeliveryCheckinForHubMutation = {
  __typename?: "Mutation";
  createDeliveryCheckinForHub: {
    __typename?: "DeliveryCheckinResponse";
    deliveryCheckin: { __typename?: "IDeliveryCheckin"; id: string };
  };
};

export type CreateDeliveryCheckinForHubV2MutationVariables = Types.Exact<{
  input: Types.NewDeliveryCheckinInputV2;
}>;

export type CreateDeliveryCheckinForHubV2Mutation = {
  __typename?: "Mutation";
  createDeliveryCheckinForHubV2: {
    __typename?: "DeliveryCheckinResponseV2";
    deliveryCheckin: { __typename?: "IDeliveryCheckinV2"; id: string };
  };
};

export const GetDeliveryCheckInsForHubDeliveredTodayDocument = gql`
  query getDeliveryCheckInsForHubDeliveredToday {
    getDeliveryCheckInsForHubDeliveredToday {
      deliveryCheckins {
        categories
        delivered_at
        id
        supplier {
          id
          name
        }
      }
    }
  }
`;

/**
 * __useGetDeliveryCheckInsForHubDeliveredTodayQuery__
 *
 * To run a query within a React component, call `useGetDeliveryCheckInsForHubDeliveredTodayQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeliveryCheckInsForHubDeliveredTodayQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeliveryCheckInsForHubDeliveredTodayQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDeliveryCheckInsForHubDeliveredTodayQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetDeliveryCheckInsForHubDeliveredTodayQuery,
    GetDeliveryCheckInsForHubDeliveredTodayQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetDeliveryCheckInsForHubDeliveredTodayQuery,
    GetDeliveryCheckInsForHubDeliveredTodayQueryVariables
  >(GetDeliveryCheckInsForHubDeliveredTodayDocument, options);
}
export function useGetDeliveryCheckInsForHubDeliveredTodayLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDeliveryCheckInsForHubDeliveredTodayQuery,
    GetDeliveryCheckInsForHubDeliveredTodayQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDeliveryCheckInsForHubDeliveredTodayQuery,
    GetDeliveryCheckInsForHubDeliveredTodayQueryVariables
  >(GetDeliveryCheckInsForHubDeliveredTodayDocument, options);
}
export type GetDeliveryCheckInsForHubDeliveredTodayQueryHookResult = ReturnType<
  typeof useGetDeliveryCheckInsForHubDeliveredTodayQuery
>;
export type GetDeliveryCheckInsForHubDeliveredTodayLazyQueryHookResult = ReturnType<
  typeof useGetDeliveryCheckInsForHubDeliveredTodayLazyQuery
>;
export type GetDeliveryCheckInsForHubDeliveredTodayQueryResult = Apollo.QueryResult<
  GetDeliveryCheckInsForHubDeliveredTodayQuery,
  GetDeliveryCheckInsForHubDeliveredTodayQueryVariables
>;
export const GetDeliveryCheckInsForHubDeliveredTodayV2Document = gql`
  query getDeliveryCheckInsForHubDeliveredTodayV2 {
    getDeliveryCheckInsForHubDeliveredTodayV2 {
      deliveryCheckins {
        deliveredAt
        id
        supplier {
          id
          name
        }
        numberOfAcceptedRollies
      }
    }
  }
`;

/**
 * __useGetDeliveryCheckInsForHubDeliveredTodayV2Query__
 *
 * To run a query within a React component, call `useGetDeliveryCheckInsForHubDeliveredTodayV2Query` and pass it any options that fit your needs.
 * When your component renders, `useGetDeliveryCheckInsForHubDeliveredTodayV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeliveryCheckInsForHubDeliveredTodayV2Query({
 *   variables: {
 *   },
 * });
 */
export function useGetDeliveryCheckInsForHubDeliveredTodayV2Query(
  baseOptions?: Apollo.QueryHookOptions<
    GetDeliveryCheckInsForHubDeliveredTodayV2Query,
    GetDeliveryCheckInsForHubDeliveredTodayV2QueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetDeliveryCheckInsForHubDeliveredTodayV2Query,
    GetDeliveryCheckInsForHubDeliveredTodayV2QueryVariables
  >(GetDeliveryCheckInsForHubDeliveredTodayV2Document, options);
}
export function useGetDeliveryCheckInsForHubDeliveredTodayV2LazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDeliveryCheckInsForHubDeliveredTodayV2Query,
    GetDeliveryCheckInsForHubDeliveredTodayV2QueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDeliveryCheckInsForHubDeliveredTodayV2Query,
    GetDeliveryCheckInsForHubDeliveredTodayV2QueryVariables
  >(GetDeliveryCheckInsForHubDeliveredTodayV2Document, options);
}
export type GetDeliveryCheckInsForHubDeliveredTodayV2QueryHookResult = ReturnType<
  typeof useGetDeliveryCheckInsForHubDeliveredTodayV2Query
>;
export type GetDeliveryCheckInsForHubDeliveredTodayV2LazyQueryHookResult = ReturnType<
  typeof useGetDeliveryCheckInsForHubDeliveredTodayV2LazyQuery
>;
export type GetDeliveryCheckInsForHubDeliveredTodayV2QueryResult = Apollo.QueryResult<
  GetDeliveryCheckInsForHubDeliveredTodayV2Query,
  GetDeliveryCheckInsForHubDeliveredTodayV2QueryVariables
>;
export const CreateDeliveryCheckinForHubDocument = gql`
  mutation createDeliveryCheckinForHub($input: NewDeliveryCheckinInput!) {
    createDeliveryCheckinForHub(input: $input) {
      deliveryCheckin {
        id
      }
    }
  }
`;
export type CreateDeliveryCheckinForHubMutationFn = Apollo.MutationFunction<
  CreateDeliveryCheckinForHubMutation,
  CreateDeliveryCheckinForHubMutationVariables
>;

/**
 * __useCreateDeliveryCheckinForHubMutation__
 *
 * To run a mutation, you first call `useCreateDeliveryCheckinForHubMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDeliveryCheckinForHubMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDeliveryCheckinForHubMutation, { data, loading, error }] = useCreateDeliveryCheckinForHubMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDeliveryCheckinForHubMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateDeliveryCheckinForHubMutation,
    CreateDeliveryCheckinForHubMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateDeliveryCheckinForHubMutation,
    CreateDeliveryCheckinForHubMutationVariables
  >(CreateDeliveryCheckinForHubDocument, options);
}
export type CreateDeliveryCheckinForHubMutationHookResult = ReturnType<
  typeof useCreateDeliveryCheckinForHubMutation
>;
export type CreateDeliveryCheckinForHubMutationResult =
  Apollo.MutationResult<CreateDeliveryCheckinForHubMutation>;
export type CreateDeliveryCheckinForHubMutationOptions = Apollo.BaseMutationOptions<
  CreateDeliveryCheckinForHubMutation,
  CreateDeliveryCheckinForHubMutationVariables
>;
export const CreateDeliveryCheckinForHubV2Document = gql`
  mutation createDeliveryCheckinForHubV2($input: NewDeliveryCheckinInputV2!) {
    createDeliveryCheckinForHubV2(input: $input) {
      deliveryCheckin {
        id
      }
    }
  }
`;
export type CreateDeliveryCheckinForHubV2MutationFn = Apollo.MutationFunction<
  CreateDeliveryCheckinForHubV2Mutation,
  CreateDeliveryCheckinForHubV2MutationVariables
>;

/**
 * __useCreateDeliveryCheckinForHubV2Mutation__
 *
 * To run a mutation, you first call `useCreateDeliveryCheckinForHubV2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDeliveryCheckinForHubV2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDeliveryCheckinForHubV2Mutation, { data, loading, error }] = useCreateDeliveryCheckinForHubV2Mutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDeliveryCheckinForHubV2Mutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateDeliveryCheckinForHubV2Mutation,
    CreateDeliveryCheckinForHubV2MutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateDeliveryCheckinForHubV2Mutation,
    CreateDeliveryCheckinForHubV2MutationVariables
  >(CreateDeliveryCheckinForHubV2Document, options);
}
export type CreateDeliveryCheckinForHubV2MutationHookResult = ReturnType<
  typeof useCreateDeliveryCheckinForHubV2Mutation
>;
export type CreateDeliveryCheckinForHubV2MutationResult =
  Apollo.MutationResult<CreateDeliveryCheckinForHubV2Mutation>;
export type CreateDeliveryCheckinForHubV2MutationOptions = Apollo.BaseMutationOptions<
  CreateDeliveryCheckinForHubV2Mutation,
  CreateDeliveryCheckinForHubV2MutationVariables
>;
