import { Button, ButtonProps, Circle, Flex } from "@chakra-ui/react";

import { TitleXS } from "ui/Typography/Typography";

const getSelectedButtonProps = (color: "orange" | "veggie") => ({
  borderColor: `${color}.500`,
  borderWidth: "2px",
  background: `${color}.100`,
});

const getBadgeColor = (color: "orange" | "veggie") => `${color}.500`;

type ToggleRolliButtonProps = {
  color: "orange" | "veggie";
  isSelected: boolean;
  label: string;
  number: number;
} & ButtonProps;

export function ToggleRolliButton({
  color,
  isSelected,
  onClick,
  label,
  number,
}: ToggleRolliButtonProps) {
  const buttonStyle = isSelected ? getSelectedButtonProps(color) : { background: "grey.100" };
  const badgeBG = getBadgeColor(color);

  return (
    <Flex justify="space-between">
      <Button
        size="md"
        width="150px"
        onClick={onClick}
        _active={{
          bg: `${color}.100`,
        }}
        {...buttonStyle}
      >
        {label}
        <Circle bg={badgeBG} ml="s100" size="16px">
          <TitleXS color="white">{number}</TitleXS>
        </Circle>
      </Button>
    </Flex>
  );
}
