import { useIntl } from "react-intl";

import { ClosedIcon } from "ui/Icons/Icons";
import { PagePlaceholder } from "ui/PagePlaceholder/PagePlaceholder";

export function ConnectionErrorPlaceholder({ onClickCta }: { onClickCta: () => void }) {
  const intl = useIntl();
  return (
    <PagePlaceholder
      icon={<ClosedIcon />}
      title={intl.formatMessage({ id: "components.connection-error-placeholder.title" })}
      subtitle={intl.formatMessage({ id: "components.connection-error-placeholder.subtitle" })}
      ctaLabel={intl.formatMessage({ id: "components.connection-error-placeholder.ctaLabel" })}
      onClickCta={onClickCta}
    />
  );
}
