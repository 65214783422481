import { useCallback, useEffect, useRef, useState } from "react";

import { Flex } from "@chakra-ui/react";
import { FormattedMessage, useIntl } from "react-intl";

import { ProductSearchBar } from "ui/ProductSearchBar/ProductSearchBar";
import { Spinner } from "ui/Spinner/Spinner";
import { BodyS, TitleM } from "ui/Typography/Typography";
import { debounce } from "utils/debounce";

type CorrectionsTextSearchProps<ResultType> = {
  isSearchActive: boolean;
  isLoading: boolean;
  searchResults: ResultType[] | null;
  onCancel: () => void;
  onSearch: (query: string) => void;
  onFocusSearchBar: () => void;
  isDisabled?: boolean;
  searchResultsList?: JSX.Element;
};

export function CorrectionsTextSearch<ResultType>({
  isSearchActive,
  onCancel,
  onFocusSearchBar,
  isLoading,
  onSearch,
  searchResults,
  isDisabled,
  searchResultsList,
}: CorrectionsTextSearchProps<ResultType>) {
  const productSearchInputRef = useRef<HTMLInputElement | null>(null);
  const intl = useIntl();
  const [value, setValue] = useState("");
  const [isDirty, setIsDirty] = useState(false);

  const hasSearchResults = searchResults !== null;

  const hasNoResults =
    !isLoading && value.length >= 3 && searchResults?.length === 0 && isSearchActive && isDirty;

  const shouldShowResults = hasSearchResults && isSearchActive;

  const onInputValueChange = useCallback((newValue: string) => {
    setValue(newValue);
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const search = useCallback(
    debounce((query: string) => {
      setIsDirty(true);
      onSearch(query);
    }, 300),
    [isSearchActive],
  );

  const handleCancel = useCallback(() => {
    setValue("");
    setIsDirty(false);
    onCancel();
  }, [onCancel]);

  useEffect(() => {
    if (isSearchActive) {
      search(value);
    }
  }, [isSearchActive, search, value]);

  useEffect(() => {
    function handleScrollOutside() {
      if (productSearchInputRef.current) {
        productSearchInputRef.current.blur();
      }
    }
    document.addEventListener("touchmove", handleScrollOutside);
    return () => {
      document.removeEventListener("touchmove", handleScrollOutside);
    };
  }, [productSearchInputRef]);

  return (
    <>
      <ProductSearchBar
        showUnitsToggle={false}
        inputValue={value}
        onInputValueChange={onInputValueChange}
        placeholder={intl.formatMessage({
          id: "pages.inventory.stock-correction.product-search-bar.placeholder",
          defaultMessage: "Search for a product, SKU...",
        })}
        cancelButtonPlaceholder={intl.formatMessage({
          id: "pages.inventory.stock-correction.product-search-bar.cancel",
          defaultMessage: "Cancel",
        })}
        onClickCancel={handleCancel}
        onFocusSearchBar={onFocusSearchBar}
        showCancelButton={isSearchActive}
        w="100%"
        p="s200"
        ref={productSearchInputRef}
        isDisabled={isDisabled}
      />

      {isLoading && (
        <Flex direction="column" flex={1} justifyContent="center" textAlign="center">
          <Spinner alignSelf="center" justifySelf="center" />
        </Flex>
      )}

      {hasNoResults && (
        <Flex direction="column" flex={1} justifyContent="center" textAlign="center">
          <TitleM>
            <FormattedMessage id="components.inbound.inbound-units-search-results-list.no-results-found.title" />
          </TitleM>
          <BodyS>
            <FormattedMessage id="components.inbound.inbound-units-search-results-list.no-results-found.description" />
          </BodyS>
        </Flex>
      )}

      {shouldShowResults && searchResultsList}
    </>
  );
}
