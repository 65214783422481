import { Flex, Tag } from "@chakra-ui/react";
import { useIntl } from "react-intl";

import { TaskType } from "__graphql__/types";
import { useEmployeeStore } from "core/stores/useEmployeeStore";
import { IntlMessageId } from "shared/types/lang";
import { Shelf } from "ui/Shelf/Shelf";
import { TitleM } from "ui/Typography/Typography";
import { getShelfDetail } from "utils/item";

const getTabProps = ({
  checkCount,
  checkType,
}: {
  checkType: TaskType;
  checkCount: number;
}): { bg: string; messageId: IntlMessageId } => {
  if (checkCount > 1) {
    return {
      bg: "grey.200",
      messageId: "components.inventory-check-card.multiple-checks",
    };
  }
  switch (checkType) {
    case TaskType.freshness_check: {
      return {
        bg: "veggie.100",
        messageId: "components.inventory-check-card.fresh-check",
      };
    }
    case TaskType.bbd_check: {
      return {
        bg: "purple.100",
        messageId: "flows.inventory.checks.components.inventory-check-card.bbd-check",
      };
    }
    case TaskType.eoy_inventory_check: {
      return {
        bg: "red.100",
        messageId: "flows.inventory.checks.components.inventory-check-card.eoy-check",
      };
    }
    case TaskType.eoy_inventory_recount_check: {
      return {
        bg: "blue.100",
        messageId: "flows.inventory.checks.components.inventory-check-card.recount-check",
      };
    }
    default: {
      return {
        bg: "orange.100",
        messageId: "components.inventory-check-card.stock-check",
      };
    }
  }
};

export type CheckListItem = {
  id: string;
  priority: number;
  shelfNumber: string;
  type: TaskType;
  count?: number;
};

type CheckListProps = {
  items: CheckListItem[];
  onSelectCheck: (shelfId: string, priority: number) => void;
};

export function ChecksList({ items, onSelectCheck }: CheckListProps) {
  const intl = useIntl();

  const isAutoScanActive = useEmployeeStore((state) => state.autoScan);

  return (
    <Flex gap="s100" direction="column" w="100%">
      {items.map(({ id, priority, shelfNumber: shelfId, type, count = 0 }) => {
        const { bg, messageId } = getTabProps({ checkCount: count, checkType: type });
        const { shelfNumber: number, shelfLetter: letter } = getShelfDetail(shelfId);

        return (
          <Flex
            onClick={isAutoScanActive ? undefined : () => onSelectCheck(shelfId, priority)}
            key={id}
            bg="white"
            p="s200"
            data-testid={`inventory-check-shelf-${shelfId}`}
          >
            <Tag px="s150" size="sm" bg={bg}>
              <TitleM>
                {`${intl.formatMessage({
                  id: messageId,
                })} ${count > 1 ? `(${count})` : ""}`}
              </TitleM>
            </Tag>
            <Shelf letter={letter} number={number} ml="auto" />
          </Flex>
        );
      })}
    </Flex>
  );
}
