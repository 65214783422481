import { useCallback, useRef } from "react";

import { Box, Flex } from "@chakra-ui/react";

import { TitleM } from "ui/Typography/Typography";
import { isNotNullNorUndefined } from "utils/tsHelpers";

import { DateTimePickerColumn } from "../DateTimePickerColumn";

export const TIME_PICKER_WIDTH_NUMBER = 262;
const NUMBER_HEIGHT = 30;
const COLUMN_WIDTH = 44;

const hours = Array.from({ length: 24 }, (_, i) => i);
const minutes = Array.from({ length: 60 }, (_, i) => i);

type TimePickerProps = {
  /**
   * initialValue: expected format : "hh:mm"
   */
  initialValue?: string;
  onChange?: (newDate: string) => void;
};

export function TimePicker({
  onChange,
  initialValue = new Date().toLocaleTimeString("de-DE").slice(0, 5),
}: TimePickerProps) {
  const currentHour = useRef(parseInt(initialValue.split(":")[0], 10));
  const currentMinute = useRef(parseInt(initialValue.split(":")[1], 10));

  const triggerOnChange = useCallback(() => {
    if (isNotNullNorUndefined(onChange)) {
      onChange(
        `${currentHour.current.toString().padStart(2, "0")}:${currentMinute.current
          .toString()
          .padStart(2, "0")}`,
      );
    }
  }, [onChange]);

  const onHourChange = useCallback(
    (newHour: number) => {
      currentHour.current = newHour;
      triggerOnChange();
    },
    [triggerOnChange],
  );

  const onMinuteChange = useCallback(
    (newMinute: number) => {
      currentMinute.current = newMinute;
      triggerOnChange();
    },
    [triggerOnChange],
  );

  return (
    <Flex justifyContent="space-between" position="relative" width="262px" pl="76px" pr="76px">
      <Box
        position="absolute"
        top="0"
        bottom="0"
        left="0"
        right="0"
        opacity="0.03"
        zIndex={4}
        pointerEvents="none"
        bg="black"
        borderBottomLeftRadius="10px"
        borderBottomRightRadius="10px"
      />
      <Flex
        position="absolute"
        alignItems="center"
        justifyContent="center"
        top={`${NUMBER_HEIGHT * 3}px`}
        left="46px"
        right="46px"
        minHeight={`${NUMBER_HEIGHT}px`}
        bgColor="grey.200"
        minWidth={`${COLUMN_WIDTH}px`}
        pointerEvents="none"
        borderRadius="8px"
        zIndex={1}
      >
        <TitleM>:</TitleM>
      </Flex>
      <DateTimePickerColumn
        columnWidth={COLUMN_WIDTH}
        elementHeight={NUMBER_HEIGHT}
        elements={hours}
        onChange={onHourChange}
        initialValue={currentHour.current}
        showLeadingZero
        size="m"
      />
      <DateTimePickerColumn
        columnWidth={COLUMN_WIDTH}
        elementHeight={NUMBER_HEIGHT}
        elements={minutes}
        onChange={onMinuteChange}
        initialValue={currentMinute.current}
        showLeadingZero
        size="m"
      />
    </Flex>
  );
}
