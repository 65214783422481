import { Flex, ModalProps, Text } from "@chakra-ui/react";
import { FormattedMessage, useIntl } from "react-intl";

import { CtaModal } from "ui/CtaModal";
import { InfoIcon } from "ui/Icons/Icons";
import { TitleM, TitleS } from "ui/Typography/Typography";
import { isNotNullNorUndefined } from "utils/tsHelpers";
import { transformChunks } from "utils/ui";

type ItemNotFoundModalBodyProps = {
  pickedQuantity: number;
  totalQuantity: number;
  availableQuantity: number;
};

function ItemNotFoundModalBody({
  pickedQuantity,
  totalQuantity,
  availableQuantity,
}: ItemNotFoundModalBodyProps) {
  const showAvailableQuantity =
    isNotNullNorUndefined(availableQuantity) && availableQuantity >= totalQuantity;

  return (
    <>
      <TitleM>
        <FormattedMessage
          id="component.picking.refund-process-modal.subtitle"
          values={{
            quantity: (
              <Text display="inline" color="pinkFlink.500">
                {pickedQuantity}/{totalQuantity}
              </Text>
            ),
            ...transformChunks({ b: { as: "strong" } }),
          }}
        />
      </TitleM>
      {showAvailableQuantity && (
        <Flex mt="s200" height="32px" bg="orange.100" borderRadius="8px" alignItems="center">
          <InfoIcon marginX="s100" color="orange.500" boxSize="20px" />
          <TitleS color="orange.500">
            <FormattedMessage
              id="component.picking.refund-process-modal.stock-alert"
              values={{
                availableQuantity,
                ...transformChunks({ b: { as: "strong" } }),
              }}
            />
          </TitleS>
        </Flex>
      )}
    </>
  );
}

type ItemNotFoundModalProps = {
  pickedQuantity: number;
  totalQuantity: number;
  availableQuantity: number;
  onConfirmRefund: () => void;
} & Pick<ModalProps, "onClose" | "isOpen">;

export function ItemNotFoundModal({
  isOpen = false,
  onClose,
  pickedQuantity,
  totalQuantity,
  availableQuantity,
  onConfirmRefund,
}: ItemNotFoundModalProps) {
  const intl = useIntl();

  return (
    <CtaModal
      onClose={onClose}
      isOpen={isOpen}
      title={intl.formatMessage(
        { id: "component.picking.refund-process-modal.header" },
        { amount: totalQuantity - pickedQuantity },
      )}
      body={
        <ItemNotFoundModalBody
          pickedQuantity={pickedQuantity}
          totalQuantity={totalQuantity}
          availableQuantity={availableQuantity}
        />
      }
      ctaLabel={intl.formatMessage(
        { id: "component.picking.refund-process-modal.button" },
        { amount: totalQuantity - pickedQuantity },
      )}
      onClickCta={onConfirmRefund}
    />
  );
}
