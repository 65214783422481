import { useCallback, useMemo, useState } from "react";

import { Button, Flex } from "@chakra-ui/react";
import { useSelector } from "@xstate/react";
import { sortBy } from "lodash";
import { FormattedMessage, useIntl } from "react-intl";
import shallow from "zustand/shallow";

import { useAppLanguageStore } from "core/stores/useAppLanguageStore";
import { useTranslatedNonScannableCategories } from "flows/Inbound/hooks/useFilteredSortedGroupedAndTranslatedSubcategories";
import { useInboundUIStore } from "flows/Inbound/stores/useInboundUIStore";
import { countryNameFromCode } from "libs/countryNames";
import { Page } from "shared/components/Page";
import { SpinnerModal } from "shared/components/SpinnerModal";
import { useProductSearchService } from "shared/hooks/useProductSearchService";
import { ProductBySubcategory, TranslatedProductBySubcategory } from "shared/models/product/types";
import { findAndExtractInboundUnitFromProduct } from "shared/models/productSearchUnit/serializer";
import { UnitType } from "shared/models/productSearchUnit/types";
import { DropDown } from "ui/DropDown/DropDown";
import { InboundNoEANProductCard } from "ui/InboundNoEANProductCard/InboundNoEANProductCard";
import { NavigationHeader } from "ui/NavigationHeader/NavigationHeader";
import { isNullOrUndefined } from "utils/tsHelpers";

const CATEGORIES_WITH_COOS = ["Fruits", "Vegetables"];

const showHandlingUnits = (product: ProductBySubcategory) => {
  return product.units.filter((unit) => unit.unitType === "handling").length === 1;
};

export function InboundNoEANProducts() {
  const intl = useIntl();
  const appLanguage = useAppLanguageStore((state) => state.appLanguage);
  const productSearchService = useProductSearchService();
  const { send } = productSearchService;

  const goBackToSubcategories = useCallback(() => {
    send({ type: "GO_BACK" });
  }, [send]);

  const areProductsLoaded = useSelector(productSearchService, (state) =>
    state.matches("noEAN.products.loaded"),
  );

  const selectedCategoryName = useInboundUIStore((state) => state.selectedCategoryName, shallow);
  const showCountryFilter = useMemo(
    () => CATEGORIES_WITH_COOS.includes(selectedCategoryName ?? ""),
    [selectedCategoryName],
  );

  const products: TranslatedProductBySubcategory[] | undefined = useSelector(
    productSearchService,
    (state) => {
      return state.context.productsForSubcategory?.map((product) => ({
        ...product,
        countryOfOriginName: countryNameFromCode(appLanguage, product.countryOfOriginCode) ?? null,
      }));
    },
  );

  const selectedSubcategoryID = useSelector(
    productSearchService,
    ({ context }) => context.selectedSubcategoryID,
  );

  const translatedNonScannableCategories = useTranslatedNonScannableCategories();

  const nameOfSelectedSubcategory = useMemo(
    () =>
      translatedNonScannableCategories
        ?.flatMap((cat) => cat.subcategories)
        .find((s) => s.id === selectedSubcategoryID)?.translatedName || "",
    [translatedNonScannableCategories, selectedSubcategoryID],
  );

  const shouldShowSpinnerModal = !areProductsLoaded || isNullOrUndefined(products);

  const translatedLabels = {
    bioLabel: intl.formatMessage({
      id: "components.inbound.search-flow.no-ean-products.bio-label",
    }),
    nonBioLabel: intl.formatMessage({
      id: "components.inbound.search-flow.no-ean-products.non-bio-label",
    }),
    addHandlingUnitLabel: intl.formatMessage({
      id: "components.inbound.search-flow.no-ean-products.add-handling-unit-label",
    }),
    addSingleUnitLabel: intl.formatMessage({
      id: "components.inbound.search-flow.no-ean-products.add-single-unit-label",
    }),
  };

  const onChooseUnit = useCallback(
    (product: ProductBySubcategory, type: UnitType) => {
      const inboundUnit = findAndExtractInboundUnitFromProduct(product, (p) =>
        p.units.find(({ unitType }) => unitType === type),
      );
      if (isNullOrUndefined(inboundUnit)) {
        return;
      }
      send({ type: "CHOOSE_UNITS", inboundUnits: [inboundUnit], origin: "noEANSearch" });
    },
    [send],
  );

  const bioFilterLabelBio = intl.formatMessage({
    id: "components.inbound.search-flow.no-ean-products.bio-filter.bio",
  });
  const bioFilterLabelNonBio = intl.formatMessage({
    id: "components.inbound.search-flow.no-ean-products.bio-filter.non-bio",
  });

  const [bioFilter, setBioFilter] = useState<string | null>(null);
  const [countryFilter, setCountryFilter] = useState<string | null>(null);

  const productMatchesBioFilter = useCallback(
    (p: ProductBySubcategory) => isNullOrUndefined(bioFilter) || (bioFilter === "bio") === p.isBio,
    [bioFilter],
  );

  const productMatchesCountryFilter = useCallback(
    (p: TranslatedProductBySubcategory) =>
      isNullOrUndefined(countryFilter) || countryFilter === p.countryOfOriginName,
    [countryFilter],
  );

  const productsFilteredbyBio = useMemo(
    () => products?.filter(productMatchesBioFilter),
    [products, productMatchesBioFilter],
  );

  const productsFilteredbyCountry = useMemo(
    () => products?.filter(productMatchesCountryFilter),
    [products, productMatchesCountryFilter],
  );

  const productsFiltered = useMemo(
    () =>
      products?.filter((p) => {
        return productMatchesBioFilter(p) && productMatchesCountryFilter(p);
      }),
    [products, productMatchesBioFilter, productMatchesCountryFilter],
  );

  const productsFilteredAndSorted = useMemo(
    () => sortBy(productsFiltered, (product) => product.name),
    [productsFiltered],
  );

  const bioFilterOptions = useMemo(
    () =>
      [
        { key: "bio", label: bioFilterLabelBio },
        { key: "nonbio", label: bioFilterLabelNonBio },
      ].filter(({ key }) => productsFilteredbyCountry?.find((p) => p.isBio === (key === "bio"))),
    [bioFilterLabelBio, bioFilterLabelNonBio, productsFilteredbyCountry],
  );

  const countryFilterOptions = useMemo(() => {
    const setOfCountryNames =
      productsFilteredbyBio?.reduce((countries, product) => {
        const countryOfOrigin = product.countryOfOriginName;
        if (countryOfOrigin) {
          countries.add(countryOfOrigin);
        }
        return countries;
      }, new Set<string>()) || new Set<string>();
    return Array.from(setOfCountryNames)
      .sort(new Intl.Collator(appLanguage).compare)
      .map((countryName) => ({
        key: countryName,
        label: countryName,
      }));
  }, [productsFilteredbyBio, appLanguage]);

  const resetFilters = useCallback(() => {
    setBioFilter(null);
    setCountryFilter(null);
  }, []);

  return (
    <Page pos="relative" isFull isBgGrey data-testid="inbound-no-ean-products-page">
      <Flex position="sticky" top="0" zIndex="2" direction="column" width="100%">
        <NavigationHeader title={nameOfSelectedSubcategory} onClickGoBack={goBackToSubcategories} />
        <Flex pt="1.25rem" pb="s200" pl="1.25rem" justify="space-between" bgColor="grey.100">
          <Flex gap="s150">
            <DropDown options={bioFilterOptions} value={bioFilter} onInput={setBioFilter}>
              <FormattedMessage id="components.inbound.search-flow.no-ean-products.bio-filter.label" />
            </DropDown>
            {showCountryFilter && (
              <DropDown
                options={countryFilterOptions}
                value={countryFilter}
                onInput={setCountryFilter}
              >
                <FormattedMessage id="components.inbound.search-flow.no-ean-products.country-filter.label" />
              </DropDown>
            )}
          </Flex>
          <Button
            onClick={resetFilters}
            variant="link"
            color="grey.600"
            _hover={{
              textDecoration: "none",
            }}
            _active={{
              textDecoration: "none",
            }}
            pr="s200"
            pl="s200"
          >
            <FormattedMessage id="components.inbound.search-flow.no-ean-products.reset-filters" />
          </Button>
        </Flex>
      </Flex>
      <SpinnerModal isOpen={shouldShowSpinnerModal} />
      <Flex direction="column" gap="s200">
        {!shouldShowSpinnerModal &&
          productsFilteredAndSorted.map((p) => {
            const productCardProps = {
              productName: p.name,
              productImageUrl: p.imageUrl,
              productSku: p.sku,
              isProductBio: p.isBio,
              countryOfOriginName: p.countryOfOriginName,
              showAddHandlingUnitButton: showHandlingUnits(p),
              onClickProductCard: (unitType: UnitType) => onChooseUnit(p, unitType),
              ...translatedLabels,
            };
            return <InboundNoEANProductCard key={p.sku} {...productCardProps} />;
          })}
      </Flex>
    </Page>
  );
}
