import { ReactNode, RefObject } from "react";

import { Flex } from "@chakra-ui/react";

interface TabPanelProps {
  children: ReactNode;
  scrollContainerRef: RefObject<HTMLDivElement>;
  isLoading?: boolean;
}

export function TabPanel({ children, scrollContainerRef, isLoading = false }: TabPanelProps) {
  return (
    <Flex flex="1" overflowY="auto" w="100%" h="100%" direction="column" ref={scrollContainerRef}>
      <Flex
        px="0px"
        py="s100"
        flexDirection="column"
        alignItems={isLoading ? "center" : "stretch"}
        w="100%"
        h="100%"
      >
        {children}
      </Flex>
    </Flex>
  );
}
