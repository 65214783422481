import { useMemo } from "react";

import { Flex, Image, Tag } from "@chakra-ui/react";
import { FormattedMessage, useIntl } from "react-intl";

import { TableLine } from "flows/Picking/components/TableLine";
import InStoreOrdersIcon from "images/random/in-store-orders.png";
import { DeliveryLogo } from "ui/DeliveryLogo/DeliveryLogo";
import { SupplierLogo } from "ui/SupplierLogo/SupplierLogo";
import { BodyS, DetailM, TitleM, TitleXS } from "ui/Typography/Typography";
import { formatWeight, getShortOrderNumber } from "utils/formats";
import { isNotNullNorUndefined, isNullOrUndefined } from "utils/tsHelpers";
import { transformChunks } from "utils/ui";

import { OrderWithItems } from "../models/order/types";

interface OrderSummaryProps {
  order: OrderWithItems | null;
  state: "END" | "START";
  itemsPickedCount?: number;
  containerIds?: string[];
  shelfIds?: string[];
}
export function OrderSummary({
  order,
  state,
  itemsPickedCount,
  containerIds,
  shelfIds,
}: OrderSummaryProps) {
  const intl = useIntl();

  const itemCounter = useMemo(() => {
    if (isNullOrUndefined(order)) {
      return "0";
    }
    return isNotNullNorUndefined(itemsPickedCount)
      ? `${itemsPickedCount}/${order.numberOfItems}`
      : `${order.numberOfItems}`;
  }, [order, itemsPickedCount]);

  const handoverId = useMemo<string | undefined>(() => {
    if (state !== "END" || isNullOrUndefined(order)) {
      return undefined;
    }
    if (
      ["UBER-EATS", "UBER-EATS-CARREFOUR", "JUST-EAT"].includes(order.reseller ?? "") &&
      isNotNullNorUndefined(order.externalProviderId)
    ) {
      return order.externalProviderId;
    }

    if (
      isNotNullNorUndefined(order.externalDeliveryProvider) &&
      isNotNullNorUndefined(order.handoverIdentifier)
    ) {
      return order.handoverIdentifier;
    }
    return undefined;
  }, [order, state]);

  if (isNullOrUndefined(order)) {
    return null;
  }

  const hasContainersIdsRow =
    state === "END" && isNotNullNorUndefined(containerIds) && containerIds.length > 0;

  const hasShelvesIdsRow =
    state === "END" && isNotNullNorUndefined(shelfIds) && shelfIds.length > 0;

  const displayedWeight = formatWeight(order.weight);

  const shouldShowInStoreOrder = state === "END" && order.isInStore === true;
  const shouldVerifyId =
    shouldShowInStoreOrder && ["SIXTEEN", "EIGHTEEN"].includes(order.ageVerification);
  const minRequiredCustomerAge = (() => {
    switch (order.ageVerification) {
      case "EIGHTEEN":
        return "18";
      case "SIXTEEN":
        return "16";
      default:
        return undefined;
    }
  })();

  const shouldShowCustomerName =
    state === "END" && order.reseller === "FLINK" && !shouldShowInStoreOrder;

  return (
    <>
      {isNotNullNorUndefined(order.reseller) && (
        <TableLine
          label={intl.formatMessage({ id: "components.picking.order-summary.supplier" })}
          rightElem={<SupplierLogo reseller={order.reseller} />}
          rightElemTestId="supplier-logo-value"
        />
      )}
      {isNotNullNorUndefined(order.externalDeliveryProvider) && (
        <TableLine
          label={intl.formatMessage({ id: "components.picking.order-summary.delivery" })}
          rightElem={<DeliveryLogo externalDeliveryProvider={order.externalDeliveryProvider} />}
        />
      )}
      <TableLine
        data-testid="label-order-number"
        label={intl.formatMessage({ id: "components.picking.order-summary.order-number" })}
        rightElem={getShortOrderNumber(order.number ?? "")}
        rightElemTestId="order-number-value"
      />
      {state === "START" && (
        <TableLine
          label={intl.formatMessage({ id: "components.picking.order-summary.weight" })}
          rightElem={displayedWeight}
        />
      )}
      {shouldShowCustomerName && (
        <TableLine
          label={intl.formatMessage({ id: "components.picking.order-summary.name" })}
          rightElem={`${order.customerFirstName || ""} ${
            order.customerLastName?.substring(0, 1) || ""
          }.`}
        />
      )}
      {isNotNullNorUndefined(handoverId) && (
        <TableLine
          data-testid="label-order-uber-id"
          label={intl.formatMessage({ id: "components.picking.order-summary.handover-id" })}
          rightElem={handoverId}
          rightElemTestId="order-uber-id-number"
        />
      )}
      {state === "END" && order.reseller === "WOLT" && (
        <TableLine
          label={intl.formatMessage({ id: "components.picking.order-summary.wolt-id" })}
          rightElem={order?.externalProviderId || ""}
        />
      )}
      {order.reseller === "FLINK" && order.isNewCustomer && (
        <TableLine
          label={intl.formatMessage({ id: "components.picking.order-summary.status" })}
          rightElem={
            <Tag bg="lightBlue" borderRadius="4px">
              <TitleXS color="white">
                {intl.formatMessage({ id: "components.picking.order-summary.new-customer" })}
              </TitleXS>
            </Tag>
          }
        />
      )}
      <TableLine
        label={intl.formatMessage({ id: "components.picking.order-summary.number-items" })}
        rightElem={itemCounter}
      />
      {hasContainersIdsRow && (
        <TableLine
          label={intl.formatMessage({ id: "components.picking.order-summary.containers" })}
          height="fit-content"
          rightElem={
            <BodyS my="s200" width="50%" align="end" wordBreak="break-word">
              {containerIds?.map((value) => value.slice(-5)).join(", ")}
            </BodyS>
          }
        />
      )}
      {hasShelvesIdsRow && (
        <TableLine
          label={intl.formatMessage({ id: "components.picking.order-summary.shelves" })}
          wordBreak="break-word"
          rightElem={
            <BodyS my="s200" width="50%" align="end" wordBreak="break-word">
              {shelfIds?.join(", ")}
            </BodyS>
          }
        />
      )}
      {shouldVerifyId && (
        <Flex width="100%" mt="s200" justifyContent="flex-end">
          <Tag bg="pinkFlink.400" borderRadius="4px">
            <TitleXS color="white">
              {intl.formatMessage({ id: "components.picking.order-summary.id-verification" })}
            </TitleXS>
          </Tag>
        </Flex>
      )}
      {shouldShowInStoreOrder && (
        <Flex
          alignItems="center"
          bg="pinkFlink.100"
          px="s200"
          py="s150"
          mt="s200"
          borderRadius="6px"
          data-testid="in-store-order-block"
        >
          <Image
            role="img"
            alt="logo-in-store-orders"
            src={InStoreOrdersIcon}
            height="40px"
            width="auto"
          />
          <Flex
            ml="s150"
            alignItems="flex-start"
            direction="column"
            data-testid="in-store-order-description"
          >
            <TitleM>
              <FormattedMessage id="components.picking.order-summary.in-store-order.title" />
            </TitleM>
            <DetailM data-testid="in-store-order-age-restriction">
              <FormattedMessage
                id={
                  shouldVerifyId
                    ? "components.picking.order-summary.in-store-order.description-age-restriction"
                    : "components.picking.order-summary.in-store-order.description-no-restriction"
                }
                values={{
                  minRequiredCustomerAge,
                  ...transformChunks({ pink: { color: "pinkFlink.500" }, br: { as: "br" } }),
                }}
              />
            </DetailM>
          </Flex>
        </Flex>
      )}
    </>
  );
}
