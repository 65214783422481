import { datadogRum } from "@datadog/browser-rum";
import { EventObject, StateValue } from "xstate";

import { config } from "config";

const STATE_VALUES_FOR_DEBUGGING_PURPOSES: Record<string, StateValue | undefined> = {};

export const keepTrackOfStateForDebugging = (machineName: string, value?: StateValue) => {
  STATE_VALUES_FOR_DEBUGGING_PURPOSES[machineName] = value;
};

export const wrongEventErrorHandlerFactory =
  (machineName: string) => (_context: any, event: EventObject) => {
    const errorString = `[XSTATE ERROR]: Invalid call of event ${event.type} in the machine ${machineName}`;

    if (config.environment.ENVIRONMENT === "development" && process.env?.VITEST !== "true") {
      // eslint-disable-next-line no-console
      console.error(errorString);
    }

    datadogRum.addError(new Error(errorString), STATE_VALUES_FOR_DEBUGGING_PURPOSES);
  };
