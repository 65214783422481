import { Box, BoxProps } from "@chakra-ui/react";

import { useAppLanguageStore } from "core/stores/useAppLanguageStore";
import { countryNameFromCode } from "libs/countryNames";
import {
  ProductInformation,
  ProductInformationProps,
} from "ui/ProductInformation/ProductInformation";

import { InventoryProduct } from "../../../shared/models/inventoryProduct/types";

export type ProductsListProps = {
  products: InventoryProduct[];
  onClickProduct: (product: InventoryProduct) => void;
  variantSize: ProductInformationProps["variantSize"];
} & BoxProps;
export function ProductsList({
  products,
  onClickProduct,
  variantSize,
  ...rest
}: ProductsListProps) {
  const appLanguage = useAppLanguageStore((state) => state.appLanguage);

  return (
    <Box display="flex" w="100%" flexDirection="column" gap="s200" {...rest}>
      {products.map((product: InventoryProduct) => {
        if (product.productName === null) return null;

        return (
          <Box padding="s200" bg="white" key={product.productSku}>
            <ProductInformation
              imageUrl={product.productImageUrl}
              name={product.productName}
              sku={product.productSku}
              countryOfOrigin={countryNameFromCode(appLanguage, product.countryOfOriginCode)}
              data-testid={`select-inventory-unit-${product.productSku}`}
              variantSize={variantSize}
              onClick={() => onClickProduct(product)}
            />
          </Box>
        );
      })}
    </Box>
  );
}
