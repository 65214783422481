import { State } from "xstate";

import { Item } from "flows/Picking/models/item/types";

import { ItemPickingState, PickingMachineContext } from "./types";
import {
  getActiveItemFromContext,
  getItemsIdsSortedByShelfList,
  getItemWithPickingState,
} from "./utils";

export const selectOrderDetailsFromContext = (context: PickingMachineContext) => {
  const getPickedQuantity = (itemId: string) => {
    const skippedItemPickingState = getItemWithPickingState(itemId, context, true);
    if (skippedItemPickingState) {
      // If item is in the skipped map, its pickedQuantity prevails over the nominal items map
      return skippedItemPickingState.pickedQuantity;
    }
    return getItemWithPickingState(itemId, context, false)?.pickedQuantity || 0;
  };
  return {
    orderNumber: context.order?.number,
    itemsPickedCount: Object.keys(context.itemsMap).reduce((acc, curr) => {
      const inc = acc + getPickedQuantity(curr);
      return inc;
    }, 0),
    numberOfItems: context.order?.numberOfItems || 0,
    orderWeight: context.order?.weight,
    externalDeliveryProvider: context.order?.externalDeliveryProvider,
    reseller: context.order?.reseller,
  };
};

export const selectOrderDetail = (state: State<PickingMachineContext>) =>
  selectOrderDetailsFromContext(state.context);

export const selectItemsSortedByShelf = (state: State<PickingMachineContext>) => {
  let isPickingDone = true;
  let isGiftPicked = true;
  let isCampaignPicked = true;
  let hasFlyersRemoved = true;
  const getItemsWithPickingStateSortedByShelf = (isSkippedItemsList: boolean) => {
    const itemsIdsSortedByShelfList = getItemsIdsSortedByShelfList(
      state.context,
      isSkippedItemsList,
    );
    return itemsIdsSortedByShelfList.flatMap((itemId) => {
      const itemWithPickingState = getItemWithPickingState(
        itemId,
        state.context,
        isSkippedItemsList,
      );
      if (itemWithPickingState?.state !== "DONE") {
        isPickingDone = false;
      }
      return itemWithPickingState ? [itemWithPickingState] : [];
    });
  };
  if (state.context.totalGiftsQuantity > state.context.pickedGiftsQuantity) {
    isGiftPicked = false;
  }
  if (state.context.totalCampaignsQuantity > state.context.pickedCampaignsQuantity) {
    isCampaignPicked = false;
  }
  if (state.context.totalRemoveFlyersQuantity > state.context.pickedRemoveFlyersQuantity) {
    hasFlyersRemoved = false;
  }
  return {
    itemsSortedByShelf: getItemsWithPickingStateSortedByShelf(false),
    skippedItemsSortedByShelf: getItemsWithPickingStateSortedByShelf(true),
    isPickingDone,
    isGiftPicked,
    isCampaignPicked,
    hasFlyersRemoved,
  };
};

export type CurrentItemToPick = {
  item: Item;
  isSkipped: boolean;
} & ItemPickingState;

export const selectCurrentItem = (
  state: State<PickingMachineContext>,
): CurrentItemToPick | undefined => {
  return (
    getActiveItemFromContext(state.context, false) ?? getActiveItemFromContext(state.context, true)
  );
};
