import { useCallback } from "react";

import { IconProps } from "@chakra-ui/react";
import { useSelector } from "@xstate/react";
import { FormattedMessage } from "react-intl";
import shallow from "zustand/shallow";

import { useInboundUIStore } from "flows/Inbound/stores/useInboundUIStore";
import { SpinnerModal } from "shared/components/SpinnerModal";
import { useProductSearchService } from "shared/hooks/useProductSearchService";
import { IntlMessageId } from "shared/types/lang";
import { ChipSelector } from "ui/ChipSelector/ChipSelector";
import {
  AppleSliceIcon,
  BeerIcon,
  BurgerAndDrinkIcon,
  CarrotIcon,
  CroissantIcon,
  DrinkIcon,
} from "ui/Icons/Icons";
import { TitleS } from "ui/Typography/Typography";

import { NoEANSubcategoriesList } from "../NoEANSubcategoriesList/NoEANSubcategoriesList";

type CategoryOption = {
  apiName: string;
  icon: (props: IconProps) => JSX.Element;
  intlMessageId: IntlMessageId;
};

const categoryOptions: CategoryOption[] = [
  {
    apiName: "Fruits",
    icon: AppleSliceIcon,
    intlMessageId: "components.inbound.search-flow-index.fruits",
  },
  {
    apiName: "Vegetables",
    icon: CarrotIcon,
    intlMessageId: "components.inbound.search-flow-index.veggies",
  },
  {
    apiName: "Alcohol",
    icon: BeerIcon,
    intlMessageId: "components.inbound.search-flow-index.alcoholic-drinks",
  },
  {
    apiName: "Non-Alcoholic Drinks",
    icon: DrinkIcon,
    intlMessageId: "components.inbound.search-flow-index.non-alcoholic-drinks",
  },
  {
    apiName: "Bakery Products Ultra Fresh",
    icon: CroissantIcon,
    intlMessageId: "components.inbound.search-flow-index.fresh-bakery",
  },
  {
    apiName: "Ready Meals Fresh",
    icon: BurgerAndDrinkIcon,
    intlMessageId: "components.inbound.search-flow-index.ready-to-eat",
  },
];

export function NoEANSubcategories() {
  const productSearchService = useProductSearchService();
  const { send } = productSearchService;

  const isNoEANSearchActive = useSelector(productSearchService, (state) => state.matches("noEAN"));
  const areSubcategoriesLoading = useSelector(productSearchService, (state) =>
    state.matches("noEAN.subcategories.loading"),
  );

  const enterNoEANFlow = useCallback(() => {
    send({ type: "ENTER_NO_EAN_FLOW" });
  }, [send]);

  const [setInboundUIState, selectedCategoryName] = useInboundUIStore(
    (state) => [state.setInboundUIState, state.selectedCategoryName],
    shallow,
  );

  const selectCategory = (categoryName: string) => {
    setInboundUIState({ selectedCategoryName: categoryName });
    if (!isNoEANSearchActive) {
      enterNoEANFlow();
    }
  };

  const chooseSubcategory = useCallback(
    (subcategoryID: string) => {
      send({ type: "CHOOSE_SUBCATEGORY", subcategoryID });
    },
    [send],
  );

  return (
    <>
      <ChipSelector
        gap="s200"
        px="s200"
        pb="s200"
        overflowX="scroll"
        min-height="56px"
        css={{
          "&::-webkit-scrollbar": {
            display: "none",
          },
        }}
        options={categoryOptions.map((option) => ({
          value: option.apiName,
          children: (
            <>
              {option.icon({ h: "1.5rem", w: "1.5rem", me: "s50" })}
              <TitleS color="inherit">
                <FormattedMessage id={option.intlMessageId} />
              </TitleS>
            </>
          ),
          px: "s100",
        }))}
        value={isNoEANSearchActive ? selectedCategoryName : null}
        onInput={(v) => selectCategory(v as string)}
      />
      {isNoEANSearchActive &&
        (areSubcategoriesLoading ? (
          <SpinnerModal isOpen />
        ) : (
          <NoEANSubcategoriesList chooseSubcategory={chooseSubcategory} />
        ))}
    </>
  );
}
