import { Box } from "@chakra-ui/react";

import { IncreaseDecrease } from "shared/components/IncreaseDecrease";

export type StockCorrectorProps = {
  expired: number;
  setExpired: (value: number) => void;
  damaged: number;
  setDamaged: (value: number) => void;
  toGoodToGo: number;
  setToGoodToGo: (value: number) => void;
  correction: number;
  setCorrection: (value: number) => void;
  isIncreaseBtnDisabled: boolean;
};
export function StockCorrector({
  expired,
  damaged,
  setDamaged,
  toGoodToGo,
  setToGoodToGo,
  correction,
  setCorrection,
  setExpired,
  isIncreaseBtnDisabled,
}: StockCorrectorProps) {
  return (
    <Box p="s200" bg="white" borderRadius="sm">
      <IncreaseDecrease
        dataTestId="expired"
        labelMessageId="components.increasedecrease.how_many_are_expired"
        changeHandler={setExpired}
        isIncreaseBtnDisabled={isIncreaseBtnDisabled}
        count={expired}
        withInput
        mb="s200"
      />
      <IncreaseDecrease
        dataTestId="damaged"
        labelMessageId="components.increasedecrease.how_many_are_damaged"
        changeHandler={setDamaged}
        isIncreaseBtnDisabled={isIncreaseBtnDisabled}
        count={damaged}
        withInput
        mb="s200"
      />
      <IncreaseDecrease
        dataTestId="correction"
        labelMessageId="components.increasedecrease.stock_correction"
        changeHandler={setCorrection}
        isDecreaseBtnDisabled={isIncreaseBtnDisabled}
        count={correction}
        withInput
        allowNevativeInput
      />
      <IncreaseDecrease
        dataTestId="too_good_to_go"
        labelMessageId="components.increasedecrease.too_good_to_go"
        changeHandler={setToGoodToGo}
        isIncreaseBtnDisabled={isIncreaseBtnDisabled}
        withInput
        count={toGoodToGo}
      />
    </Box>
  );
}
