import create from "zustand";

export type AppDisplayMode = "browser" | "standalone" | "fullscreen";

export type AppPWAStateStore = {
  appUpdateAvailable: boolean;
  isLoading: boolean;
  displayMode: AppDisplayMode;
  updateServiceWorker?: () => void;
};

type AppPWAStateStoreWithMethods = AppPWAStateStore & {
  setAppPWAState(appPWAStateState: Partial<AppPWAStateStore>): void;
};

const initialState: AppPWAStateStore = {
  appUpdateAvailable: false,
  displayMode: "browser",
  isLoading: true,
};

export const useAppPWAStateStore = create<AppPWAStateStoreWithMethods>()((set) => ({
  ...initialState,
  setAppPWAState(appPWAStateState) {
    set(appPWAStateState);
  },
}));
