import { PickingMachineContext } from "./types";

const DEFAULT_MISSING_REASON = "goods_not_on_shelf";

export function getItemsIdsSortedByShelfList(
  ctx: PickingMachineContext,
  isSkippedItemsList: boolean,
) {
  return isSkippedItemsList ? ctx.skippedItemsIdsSortedByShelf : ctx.itemsIdsSortedByShelf;
}

export function getPickingStateMap(ctx: PickingMachineContext, isSkippedItem: boolean) {
  return isSkippedItem ? ctx.skippedItemsPickingState : ctx.itemsPickingState;
}

export function getItemPickingStateRef(
  itemId: string,
  ctx: PickingMachineContext,
  isSkippedItem: boolean,
) {
  const pickingStateMap = getPickingStateMap(ctx, isSkippedItem);
  return pickingStateMap[itemId];
}

export function getItemWithPickingState(
  itemId: string,
  ctx: PickingMachineContext,
  isSkippedItem: boolean,
) {
  const itemPickingStateRef = getItemPickingStateRef(itemId, ctx, isSkippedItem);
  const item = ctx.itemsMap[itemId];
  if (!itemPickingStateRef || !item) {
    return undefined;
  }
  return {
    ...itemPickingStateRef,
    item,
  };
}

export function getMissingItemsFromContext(ctx: PickingMachineContext) {
  return Object.keys(ctx.itemsMap).flatMap((itemId) => {
    const item = ctx.itemsMap[itemId];
    const itemState = ctx.skippedItemsPickingState[itemId]
      ? ctx.skippedItemsPickingState[itemId]
      : ctx.itemsPickingState[itemId];
    if (itemState.pickedQuantity === item.quantity) {
      return [];
    }
    return [
      {
        quantity: item.quantity - itemState.pickedQuantity,
        sku: item.sku,
        comment: DEFAULT_MISSING_REASON,
      },
    ];
  });
}

export function getActiveItemFromContext(
  ctx: PickingMachineContext,
  isSkippedItemsPickingState: boolean,
) {
  const [foundItemId] =
    Object.entries(
      isSkippedItemsPickingState ? ctx.skippedItemsPickingState : ctx.itemsPickingState,
    ).find(([, itemState]) => itemState.state === "PICKING") ?? [];
  if (!foundItemId) {
    return undefined;
  }
  const itemWithPickingState = getItemWithPickingState(
    foundItemId,
    ctx,
    isSkippedItemsPickingState,
  );
  if (!itemWithPickingState) {
    return undefined;
  }
  return { ...itemWithPickingState, isSkipped: isSkippedItemsPickingState };
}

export function selectNextItemInContext(ctx: PickingMachineContext) {
  const getFirstPickableItemStateRef = (isSkippedItems: boolean) => {
    const pickingStateMap = getPickingStateMap(ctx, isSkippedItems);
    const itemsIdsSortedByShelf = getItemsIdsSortedByShelfList(ctx, isSkippedItems);
    const idleItemId = itemsIdsSortedByShelf.find((id) => pickingStateMap[id].state === "IDLE");
    return idleItemId ? pickingStateMap[idleItemId] : undefined;
  };

  const firstPickableItemStateRef =
    getFirstPickableItemStateRef(false) || getFirstPickableItemStateRef(true);

  if (firstPickableItemStateRef) {
    firstPickableItemStateRef.state = "PICKING";
  }
}
