import { useCallback } from "react";

import { datadogLogs } from "@datadog/browser-logs";

import { TrackEventTypesMap } from "analytics/events";
import { getDeviceId } from "analytics/utils";
import { useEmployeeStore } from "core/stores/useEmployeeStore";

import { analytics } from "../../core/components/App";
import { useHubSlug } from "./useHubSlug";

export enum ErrorTypeForAnalytics {
  // common
  CONNECTION_LOST = "connection_lost",
  UNKNOWN = "unknown",

  // inventory
  TASK_ALREADY_TAKEN = "inventory_task_already_taken",

  // picking
  ORDER_ALREADY_TAKEN = "order_already_taken",
  SCAN_PRODUCT_FAILED = "scan_product_failed",
}

export function useAnalytics() {
  const hubSlug = useHubSlug();

  const badgeNoFromStore = useEmployeeStore((state) => state.badgeNo);

  const getBadgeNo = useCallback(
    (payload?: Record<string, unknown>) => {
      if (badgeNoFromStore) {
        return badgeNoFromStore;
      }

      if (payload && "quinyx_badge_number" in payload) {
        return payload?.quinyx_badge_number;
      }

      return undefined;
    },
    [badgeNoFromStore],
  );

  const sendSegmentTrackEvent = useCallback(
    <T extends keyof TrackEventTypesMap>(name: T, payload?: TrackEventTypesMap[T]["payload"]) => {
      const trackEventWithRetry = (retryCount = 0) => {
        try {
          analytics.track(name, payload, {
            traits: {
              quinyx_badge_number: getBadgeNo(payload),
              hub_slug: hubSlug,
              device_id: getDeviceId(),
            },
          });
        } catch (error) {
          if (retryCount < 3) {
            setTimeout(() => trackEventWithRetry(retryCount + 1), 1000);
          } else {
            datadogLogs.logger.error("Failed to send Segment Event after retries:", {
              name,
              payload,
              error,
            });
          }
        }
      };

      trackEventWithRetry();
    },
    [getBadgeNo, hubSlug],
  );

  return { sendSegmentTrackEvent, analytics };
}
