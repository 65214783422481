import { ApolloError } from "@apollo/client";

type ErrorWithMessage = {
  message: string;
};

function isErrorWithMessage(error: unknown): error is ErrorWithMessage {
  return (
    typeof error === "object" &&
    error !== null &&
    "message" in error &&
    typeof (error as Record<string, unknown>).message === "string"
  );
}

function toErrorWithMessage(maybeError: unknown): ErrorWithMessage {
  if (isErrorWithMessage(maybeError)) {
    return maybeError;
  }

  try {
    return new Error(JSON.stringify(maybeError));
  } catch {
    // fallback in case there's an error stringifying the maybeError
    // like with circular references for example.
    return new Error(String(maybeError));
  }
}

export function getErrorMessage(error: unknown) {
  return toErrorWithMessage(error).message;
}

export async function asyncTryCatchWithErrorChecking<T>(
  func: () => Promise<T>,
  handleError: (e: ApolloError) => any = () => {},
): Promise<T | undefined> {
  try {
    return await func();
  } catch (e) {
    if (e instanceof ApolloError) {
      handleError(e);
      return undefined;
    }
    throw e;
  }
}
