import { Flex, FlexProps } from "@chakra-ui/react";

import { HeaderS } from "ui/Typography/Typography";

type NumberProps = {
  content: string;
  color: string;
  bgColor: string;
} & FlexProps;

export function Number({ content, color, bgColor, ...rest }: NumberProps) {
  return (
    <Flex
      width="fit-content"
      bgColor={bgColor}
      alignItems="center"
      justifyContent="center"
      borderRadius="8px"
      paddingX="s150"
      paddingY="s50"
      minWidth="56px"
      h="40px"
      {...rest}
    >
      <HeaderS color={color}>{content}</HeaderS>
    </Flex>
  );
}
