import { useCallback, useEffect, useMemo, useRef } from "react";

import { isApolloError } from "@apollo/client";
import shallow from "zustand/shallow";

import { RestockingProgressedMethod } from "analytics/events";
import { useAppBackgroundEffectsStore } from "core/stores/useAppBackgroundEffectsStore";
import { useAppLanguageStore } from "core/stores/useAppLanguageStore";
import { EppoFeatureFlags } from "core/types/flags";
import { serializePrivateRestockingList } from "flows/Inventory/flows/RestockingList/models/privateList/serializer";
import {
  RestockingItemStatus,
  TranslatedRestockingItem,
} from "flows/Inventory/flows/RestockingList/models/restockingItem/types";
import { countryNameFromCode } from "libs/countryNames";
import { useAnalytics } from "shared/hooks/useAnalytics";
import { useCustomToast } from "shared/hooks/useCustomToast";
import { useEppoFeatureFlagProvider } from "shared/hooks/useEppoFeatureFlag";
import { sortByShelf } from "utils/item";

import { usePrivateRestockingListQueries } from "../../hooks/usePrivateRestockingListQueries";
import { usePublicRestockingListQueries } from "../../hooks/usePublicRestockingListQueries";
import { serializePublicRestockingList } from "../../models/publicList/serializer";
import { useRestockingServiceStore } from "../../stores/restockingServiceStore";

const REMOVE_MULTIPLE_RESTOCKING_ITEMS_ERROR_TOAST_ID =
  "remove_multiple_restocking_items_error_toast";
const REMOVE_MULTIPLE_RESTOCKING_ITEMS_SUCCESS_TOAST_ID =
  "remove_multiple_restocking_items_success_toast";

const usePublicRestockingListRefresh = () => {
  const { getPublicRestockingListQuery } = usePublicRestockingListQueries();

  const setAppBackgroundEffectsStore = useAppBackgroundEffectsStore(
    (state) => state.setAppBackgroundEffectsStore,
  );

  return useCallback(async () => {
    const { data } = await getPublicRestockingListQuery();
    setAppBackgroundEffectsStore({
      publicRestockingList: data,
    });
  }, [getPublicRestockingListQuery, setAppBackgroundEffectsStore]);
};

export const usePrivateRestockingList = (
  { loadOnInit }: { loadOnInit: boolean } = { loadOnInit: true },
) => {
  const appLanguage = useAppLanguageStore((state) => state.appLanguage);

  const refreshPublicRestockingList = usePublicRestockingListRefresh();
  const { sendSegmentTrackEvent } = useAnalytics();
  const { showToastUI } = useCustomToast();

  const { isFeatureEnabled: isNewRestocking } = useEppoFeatureFlagProvider(
    EppoFeatureFlags.NEW_RESTOCKING,
  );

  const {
    getPrivateRestockingListQuery,
    deletePrivateRestockingListMutation,
    restockItemMutation,
    createPrivateRestockingListMutation,
    removeRestockingItemFromPrivateList,
    removeMultipleSkusFromRestockingPrivateList,
    deleteSkuFromPrivateList,
  } = usePrivateRestockingListQueries();

  const {
    privateRestockingListData,
    restockingItemsSortOrder,
    isLoadingPrivateRestockingList,
    itemInRestockingProcess,
    totalRestockingItemCount,
    setPrivateRestockingListData,
    toggleRestockingItemsSortOrder,
    updateSelectedItemsForRemoval,
    setIsLoadingPrivateRestockingList,
    setItemInRestockingProcess,
    getCurrentPrivateRestockingList,
    getShelfNumber,
  } = useRestockingServiceStore((state) => state, shallow);

  const isLoadingPrivateRestockingListRef = useRef(false);

  const setIsLoading = useCallback(
    (v: boolean) => {
      // Using both a ref AND state here. The ref is so that we don't
      // run two requests simultaneously, the state is so that components
      // can subscribe to changes.
      isLoadingPrivateRestockingListRef.current = v;
      setIsLoadingPrivateRestockingList(v);
    },
    [setIsLoadingPrivateRestockingList],
  );

  const privateRestockingList = useMemo(() => {
    if (!privateRestockingListData) return null;
    return serializePrivateRestockingList(privateRestockingListData);
  }, [privateRestockingListData]);

  const translatedRestockingItems: TranslatedRestockingItem[] | null = useMemo(() => {
    if (privateRestockingList === null) return [];

    return privateRestockingList.restockingItems.map((item) => {
      if (item.product === null) {
        return { ...item, product: null };
      }
      const countryOfOriginName = countryNameFromCode(
        appLanguage,
        item.product.countryOfOriginCode,
      );
      return {
        ...item,
        product: {
          ...item.product,
          countryOfOriginName,
        },
      };
    });
  }, [privateRestockingList, appLanguage]);

  const filteredTranslatedRestockingItems = useMemo(() => {
    return translatedRestockingItems.filter((item) => item.status !== RestockingItemStatus.done);
  }, [translatedRestockingItems]);

  const sortedTranslatedRestockingItems = useMemo(() => {
    return translatedRestockingItems.sort((itemA, itemB) =>
      sortByShelf(itemA.product ?? {}, itemB.product ?? {}, restockingItemsSortOrder),
    );
  }, [translatedRestockingItems, restockingItemsSortOrder]);

  const loadPrivateRestockingList = useCallback(async () => {
    if (isLoadingPrivateRestockingListRef.current) return;
    setIsLoading(true);
    try {
      const response = await getPrivateRestockingListQuery();
      setPrivateRestockingListData(response.data ?? null);
    } catch (error) {
      if (!isApolloError(error as Error)) {
        throw error;
      }
      setPrivateRestockingListData(null);
    } finally {
      setIsLoading(false);
    }
  }, [getPrivateRestockingListQuery, setPrivateRestockingListData, setIsLoading]);

  const refreshPrivateRestockingList = useCallback(async () => {
    if (privateRestockingListData === undefined) return;
    await loadPrivateRestockingList();
  }, [privateRestockingListData, loadPrivateRestockingList]);

  const restockItem = useCallback(
    async (sku: string) => {
      if (!privateRestockingList?.id || itemInRestockingProcess !== null) return;
      setItemInRestockingProcess(sku);
      try {
        await restockItemMutation(privateRestockingList.id, sku);
        sendSegmentTrackEvent("restockingProgressed", {
          restocking_list_id: privateRestockingList.id,
          action: "product_restocked",
          shelf_number: getShelfNumber(sku),
          product_sku: sku,
        });
      } catch (error) {
        if (!isApolloError(error as Error)) {
          setItemInRestockingProcess(null);
          throw error;
        }
      }
      await refreshPrivateRestockingList();
      setItemInRestockingProcess(null);
    },
    [
      privateRestockingList?.id,
      itemInRestockingProcess,
      setItemInRestockingProcess,
      refreshPrivateRestockingList,
      restockItemMutation,
      sendSegmentTrackEvent,
      getShelfNumber,
    ],
  );

  const deletePrivateList = useCallback(async () => {
    if (!privateRestockingList?.id) return;
    try {
      await deletePrivateRestockingListMutation(privateRestockingList.id);
      sendSegmentTrackEvent("restockingStateUpdated", {
        restocking_list_id: privateRestockingList.id,
        state: "restocking_list_finished",
      });
    } catch (error) {
      if (!isApolloError(error as Error)) {
        throw error;
      }
    }
    await refreshPrivateRestockingList();
  }, [
    privateRestockingList,
    deletePrivateRestockingListMutation,
    refreshPrivateRestockingList,
    sendSegmentTrackEvent,
  ]);

  const addItemsToPrivateRestockingList = useCallback(
    async (skus: string[], method: RestockingProgressedMethod) => {
      try {
        await createPrivateRestockingListMutation(skus);
      } catch (error) {
        if (isApolloError(error as Error)) {
          await refreshPublicRestockingList();
        }
        throw error;
      }
      refreshPublicRestockingList();
      await refreshPrivateRestockingList();

      const currentPrivateRestockingList = getCurrentPrivateRestockingList();
      if (currentPrivateRestockingList && currentPrivateRestockingList.id) {
        if (!totalRestockingItemCount) {
          sendSegmentTrackEvent("restockingStateUpdated", {
            restocking_list_id: currentPrivateRestockingList.id,
            state: "restocking_list_preparation_started",
          });
        }
        skus.forEach((sku: string) => {
          sendSegmentTrackEvent("restockingProgressed", {
            restocking_list_id: currentPrivateRestockingList.id,
            action: "product_added_to_list",
            method,
            shelf_number: getShelfNumber(sku),
            product_sku: sku,
          });
        });
      }
    },
    [
      createPrivateRestockingListMutation,
      getCurrentPrivateRestockingList,
      getShelfNumber,
      refreshPrivateRestockingList,
      refreshPublicRestockingList,
      sendSegmentTrackEvent,
      totalRestockingItemCount,
    ],
  );

  const removeRestockingItem = useCallback(
    async (sku: string) => {
      if (!privateRestockingList?.id) return;
      try {
        const shelfNumber = getShelfNumber(sku);
        const removeItem = isNewRestocking
          ? deleteSkuFromPrivateList
          : removeRestockingItemFromPrivateList;

        await removeItem(privateRestockingList.id, sku);
        refreshPublicRestockingList();
        await refreshPrivateRestockingList();
        sendSegmentTrackEvent("restockingProgressed", {
          restocking_list_id: privateRestockingList.id,
          action: "product_removed_from_list",
          shelf_number: shelfNumber,
          product_sku: sku,
        });
      } catch (error) {
        if (!isApolloError(error as Error)) {
          throw error;
        }
      }
    },
    [
      deleteSkuFromPrivateList,
      getShelfNumber,
      isNewRestocking,
      privateRestockingList?.id,
      refreshPrivateRestockingList,
      refreshPublicRestockingList,
      removeRestockingItemFromPrivateList,
      sendSegmentTrackEvent,
    ],
  );

  const removeMultipleRestockingItems = useCallback(
    async (skus: string[]) => {
      const listId = privateRestockingList?.id;
      if (!listId) return;

      try {
        await removeMultipleSkusFromRestockingPrivateList(listId, skus);
        updateSelectedItemsForRemoval([]);
        refreshPublicRestockingList();
        await refreshPrivateRestockingList();
        skus.forEach((sku: string) => {
          sendSegmentTrackEvent("restockingProgressed", {
            restocking_list_id: privateRestockingList.id,
            action: "product_removed_from_list",
            shelf_number: getShelfNumber(sku),
            product_sku: sku,
          });
        });
        showToastUI({
          id: REMOVE_MULTIPLE_RESTOCKING_ITEMS_SUCCESS_TOAST_ID,
          status: "success",
          title: "flows.restocking-list.services.remove-multiple-restocking-items-success_toast",
        });
      } catch (error: any) {
        const isRestockingListNotFound =
          isApolloError(error) &&
          error.graphQLErrors?.[0]?.extensions?.code === "RESTOCKING_PRIVATE_LIST_NOT_FOUND";
        const toastTitle = isRestockingListNotFound
          ? "flows.restocking-list.services.remove-multiple-restocking-items-error-toast.not-found"
          : "flows.restocking-list.services.remove-multiple-restocking-items-error-toast";
        showToastUI({
          id: REMOVE_MULTIPLE_RESTOCKING_ITEMS_ERROR_TOAST_ID,
          title: toastTitle,
        });
        throw error;
      }
    },
    [
      getShelfNumber,
      privateRestockingList?.id,
      refreshPrivateRestockingList,
      refreshPublicRestockingList,
      removeMultipleSkusFromRestockingPrivateList,
      sendSegmentTrackEvent,
      showToastUI,
      updateSelectedItemsForRemoval,
    ],
  );

  useEffect(() => {
    if (loadOnInit && privateRestockingListData === undefined) {
      loadPrivateRestockingList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    privateRestockingList,
    translatedRestockingItems,
    filteredTranslatedRestockingItems,
    sortedTranslatedRestockingItems,
    restockingItemsSortOrder,
    isLoadingPrivateRestockingList,
    itemInRestockingProcess,
    restockItem,
    toggleRestockingItemsSortOrder,
    deletePrivateList,
    addItemsToPrivateRestockingList,
    removeRestockingItem,
    removeMultipleRestockingItems,
    deleteSkuFromPrivateList,
  };
};

export const usePublicRestockingList = () => {
  const { addItemToPublicRestockingListMutation } = usePublicRestockingListQueries();
  const refreshPublicRestockingList = usePublicRestockingListRefresh();

  const publicRestockingListData = useAppBackgroundEffectsStore(
    (state) => state.publicRestockingList,
  );

  const restockingItems = useMemo(
    () =>
      publicRestockingListData
        ? serializePublicRestockingList(publicRestockingListData).restockingItems
        : null,
    [publicRestockingListData],
  );

  const addItemToPublicRestockingList = useCallback(
    async (sku: string) => {
      await addItemToPublicRestockingListMutation(sku);
      refreshPublicRestockingList();
    },
    [addItemToPublicRestockingListMutation, refreshPublicRestockingList],
  );

  return {
    restockingItems,
    addItemToPublicRestockingList,
  };
};
