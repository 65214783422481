import create from "zustand";

import { PageName } from "analytics/events";
import { WaitingOrderBannerType } from "core/types/waitingOrderBanner";
import { IntlMessageId } from "shared/types/lang";

export type TabBarItemID = "activities" | "inbound" | "inventory" | "picking";

export type LayoutStore = {
  canGoBack: boolean;
  isMenuOpen: boolean;
  isLanguageMenuOpen: boolean;
  withTabBar: boolean;
  highlightedTab: TabBarItemID | null;
  withWaitingOrdersBannerOfType: WaitingOrderBannerType | null;
  withOfflineBanner: boolean;
  withMenu: boolean;
  scrollContainerRef: React.RefObject<HTMLDivElement | null>;
  withAppHeader: boolean;
  withScrollableAppHeader: boolean;
  withOngoingActivityBanner: boolean;
  withTabList: boolean;
  showOngoingTimedActivityModalWithContent: IntlMessageId | null;
  showUpdateAppModal: boolean;
  canShowUpdateModal: boolean;
  isUpdating: boolean;
  pageName: PageName | null;
};

type LayoutStoreWithMethods = LayoutStore & {
  setAppLayout(appLayoutState: Partial<LayoutStore>): void;
  toggleMenu(): void;
  reset(): void;
};

const initialState: LayoutStore = {
  canGoBack: false,
  isMenuOpen: false,
  isLanguageMenuOpen: false,
  withTabBar: false,
  highlightedTab: null,
  withWaitingOrdersBannerOfType: null,
  scrollContainerRef: { current: null },
  withMenu: true,
  withOfflineBanner: false,
  withAppHeader: true,
  withScrollableAppHeader: false,
  withOngoingActivityBanner: false,
  withTabList: false,
  showOngoingTimedActivityModalWithContent: null,
  showUpdateAppModal: false,
  canShowUpdateModal: false,
  isUpdating: false,
  pageName: null,
};

export const useLayoutStore = create<LayoutStoreWithMethods>()((set, get) => ({
  ...initialState,
  setAppLayout(appLayoutState) {
    set(appLayoutState);
  },
  toggleMenu() {
    const currentState = get();
    if (!currentState.withMenu) {
      return;
    }
    if (currentState.isMenuOpen) {
      set({ ...currentState, isMenuOpen: false, isLanguageMenuOpen: false });
    } else {
      set({ ...currentState, isMenuOpen: true });
    }
  },
  reset() {
    set(initialState);
  },
}));

export const openOngoingActivityModal = (
  messageId: IntlMessageId = "core.components.ongoing-timed-activity-modal.description",
) =>
  useLayoutStore.setState({
    showOngoingTimedActivityModalWithContent: messageId,
  });
