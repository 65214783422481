import { useCallback, useEffect, useRef } from "react";

import { useApolloClient } from "@apollo/client";
import shallow from "zustand/shallow";

import {
  EndInboundingDocument,
  EndInboundingMutation,
  EndInboundingMutationVariables,
  StartInboundingDocument,
  StartInboundingMutation,
  StartInboundingMutationVariables,
} from "core/queries/oaOccupation/oaOccupation.generated";
import { useEmployeeStore } from "core/stores/useEmployeeStore";
import { OAOccupation, useOAOccupationStore } from "core/stores/useOAOccupationStore";
import { EppoFeatureFlags } from "core/types/flags";
import { useEppoFeatureFlagProvider } from "shared/hooks/useEppoFeatureFlag";

const REGULAR_REPORT_INTERVAL = 20000; // 20 seconds
const RETRY_TIMEOUT = 4000; // 4 seconds
const DEBOUNCE_TIMEOUT = 2000; // 2 seconds

export function useAppOAOccupationEffects() {
  const apolloClient = useApolloClient();
  const { isFeatureEnabled: areOAOccupationReportsEnabled } = useEppoFeatureFlagProvider(
    EppoFeatureFlags.OA_OCCUPATION_REPORTS,
  );

  const { oaOccupation, registerAckedByBackend, oaOccupationInBackend } = useOAOccupationStore(
    (state) => ({
      oaOccupation: state.oaOccupation,
      registerAckedByBackend: state.registerAckedByBackend,
      oaOccupationInBackend: state.oaOccupationInBackend,
    }),
    shallow,
  );
  const [employeeId, isIdentified] = useEmployeeStore(
    (state) => [state.badgeNo, state.isIdentified],
    shallow,
  );

  const debounceTimer = useRef<NodeJS.Timeout | null>(null);
  const regularReportTimer = useRef<NodeJS.Timeout | null>(null);

  const reportOAOccupation = useCallback(
    async (occupation: OAOccupation) => {
      if (employeeId === undefined) return;
      if (areOAOccupationReportsEnabled) {
        if (occupation === OAOccupation.Inbounding) {
          await apolloClient.mutate<StartInboundingMutation, StartInboundingMutationVariables>({
            mutation: StartInboundingDocument,
            variables: { input: { employeeId } },
          });
        } else {
          await apolloClient.mutate<EndInboundingMutation, EndInboundingMutationVariables>({
            mutation: EndInboundingDocument,
            variables: { input: { employeeId } },
          });
        }
      }
      registerAckedByBackend(occupation);
    },
    [employeeId, apolloClient, registerAckedByBackend, areOAOccupationReportsEnabled],
  );

  const reportOAOccupationWithRetry = useCallback(
    async (occupation: OAOccupation) => {
      try {
        await reportOAOccupation(occupation);
      } catch (error) {
        // if something goes wrong with sending the occupation change to or BE,
        // keep trying every couple seconds.
        if (debounceTimer.current) clearTimeout(debounceTimer.current);
        debounceTimer.current = setTimeout(
          () => reportOAOccupationWithRetry(oaOccupation),
          RETRY_TIMEOUT,
        );
      }
    },
    [oaOccupation, reportOAOccupation],
  );

  const scheduleRegularReport = useCallback(() => {
    if (regularReportTimer.current) clearInterval(regularReportTimer.current);
    regularReportTimer.current = setInterval(
      () => reportOAOccupation(oaOccupation),
      REGULAR_REPORT_INTERVAL,
    );
  }, [reportOAOccupation, oaOccupation]);

  const debounceOAOccupation = useCallback(
    async (occupation: OAOccupation) => {
      if (oaOccupationInBackend === oaOccupation) return;

      // cancel scheduled regular report
      if (regularReportTimer.current) clearInterval(regularReportTimer.current);

      await reportOAOccupationWithRetry(occupation);
      scheduleRegularReport();
    },
    [oaOccupationInBackend, oaOccupation, reportOAOccupationWithRetry, scheduleRegularReport],
  );

  // Report OA occupation every REGULAR_REPORT_INTERVAL
  useEffect(() => {
    scheduleRegularReport();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isIdentified || !employeeId) return;

    if (debounceTimer.current) clearTimeout(debounceTimer.current);
    debounceTimer.current = setTimeout(() => debounceOAOccupation(oaOccupation), DEBOUNCE_TIMEOUT);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oaOccupation, isIdentified, employeeId]);
}
