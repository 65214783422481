import create from "zustand";

import { UnitType } from "shared/models/productSearchUnit/types";

export enum ActionTypes {
  ADD_ITEM = "ADD_ITEM",
  CHECK_QUANTITIES = "CHECK_QUANTITIES",
  RESET_STATE = "CHECKED",
}

type InboundUIStore = {
  isEditingQuantity: boolean;
  selectedCategoryName: string | null;
  selectedLetterRangeId: string;
  searchedUnitType: UnitType;
  showScanningFailedModal: boolean;
  showPartialOutboundAnimationForSKU: string | null;
  skuToFocusQuantityInputFor: string | null | undefined;
  isEditingHUSize: boolean;
  showQuantityMismatchBanner: boolean;
  shouldShowDespatchAdviceModal: boolean;
  showDespatchAdviceInfoBanner: ActionTypes;
  showProductAlreadyAddedBanner: boolean;
  isUnverifiedQuantityModalOpen: boolean;
};

type InboundUIStoreWithMethods = InboundUIStore & {
  setInboundUIState(inboundUIState: Partial<InboundUIStore>): void;
  resetNoEANStore(): void;
  resetToInitialInboundUIState(): void;
};

const initialState: InboundUIStore = {
  isEditingQuantity: false,
  selectedCategoryName: null,
  selectedLetterRangeId: "AD",
  searchedUnitType: "single",
  showScanningFailedModal: false,
  showPartialOutboundAnimationForSKU: null,
  skuToFocusQuantityInputFor: undefined,
  isEditingHUSize: false,
  showQuantityMismatchBanner: false,
  shouldShowDespatchAdviceModal: false,
  showDespatchAdviceInfoBanner: ActionTypes.ADD_ITEM,
  showProductAlreadyAddedBanner: false,
  isUnverifiedQuantityModalOpen: false,
};

export const useInboundUIStore = create<InboundUIStoreWithMethods>()((set) => ({
  ...initialState,
  setInboundUIState(inboundUIState) {
    set(inboundUIState);
  },
  resetNoEANStore() {
    set({
      selectedLetterRangeId: initialState.selectedLetterRangeId,
      selectedCategoryName: initialState.selectedCategoryName,
    });
  },
  resetToInitialInboundUIState() {
    set(initialState);
  },
}));
