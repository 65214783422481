import {
  Circle,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
} from "@chakra-ui/react";
import { FormattedMessage } from "react-intl";

import { Button } from "ui/Button/Button";
import { PackageBoxWarningIcon } from "ui/Icons/Icons";
import { BodyS, HeaderS } from "ui/Typography/Typography";

export type CheckBackstockModalProps = {
  onClickConfirm: () => void;
  onClickOutOfStock: () => void;
  isOpen: boolean;
  totalUnits: number;
};

export function CheckBackstockModal({
  onClickOutOfStock,
  onClickConfirm,
  isOpen,
  totalUnits,
}: CheckBackstockModalProps) {
  return (
    <Modal
      trapFocus={false}
      closeOnOverlayClick={false}
      isCentered
      isOpen={isOpen}
      onClose={() => {}}
    >
      <ModalOverlay bg="rgba(0,0,0,0.2)" />
      <ModalContent marginX="s300">
        <ModalBody display="flex" flexDir="column" alignItems="center" textAlign="center" p="s200">
          <Circle bg="grey.100" size="120px">
            <PackageBoxWarningIcon boxSize="66.67px" />
          </Circle>
          <HeaderS mt="s200">
            <FormattedMessage id="flows.inventory.checks.bbd-checks.components.check-backstock-modal.title" />
          </HeaderS>
          <BodyS mt="s100">
            <FormattedMessage
              id="flows.inventory.checks.bbd-checks.components.check-backstock-modal.body1"
              values={{ totalUnits }}
            />
          </BodyS>
        </ModalBody>
        <ModalFooter paddingX="s200" pt="s100" flexDir="column">
          <Button
            onClick={onClickConfirm}
            flinkVariant="primary"
            size="lg"
            width="full"
            data-testid="check-backstock-modal-confirm-button"
          >
            <FormattedMessage id="flows.inventory.checks.bbd-checks.components.check-backstock-modal.confirm" />
          </Button>
          <Button
            mt="s100"
            onClick={onClickOutOfStock}
            flinkVariant="secondary"
            size="lg"
            width="full"
            data-testid="check-backstock-modal-out-of-stock-button"
          >
            <FormattedMessage id="flows.inventory.checks.bbd-checks.components.check-backstock-modal.out-of-stock" />
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
