import { ButtonProps } from "@chakra-ui/react";
import { FormattedMessage } from "react-intl";

import { usePickingService } from "flows/Picking/hooks/usePickingService";
import { Button } from "ui/Button/Button";

type GoToScanContainersButtonProps = { isButtonDisabled?: boolean } & ButtonProps;

export function GoToScanContainersButton({
  isButtonDisabled = false,
  ...rest
}: GoToScanContainersButtonProps) {
  const { send } = usePickingService();

  return (
    <Button
      isDisabled={isButtonDisabled}
      onClick={() => send({ type: "GO_TO_SCAN_CONTAINERS" })}
      size="lg"
      width="full"
      data-testid="redirect-to-scan-containers-button"
      {...rest}
    >
      <FormattedMessage id="components.picking.go-to-scan-containers-button.button-label-scan-containers" />
    </Button>
  );
}
