import { useCallback } from "react";

import { useApolloClient } from "@apollo/client";

import { CreateCheckInput, TaskMetadataReason, TaskType } from "__graphql__/types";
import { useStartNextCheck } from "flows/Inventory/shared/hooks/useStartNextCheck";
import { getPriorityGroup } from "flows/Inventory/shared/models/check/serializer";
import {
  CreateCheckDocument,
  CreateCheckMutation,
  CreateCheckMutationVariables,
} from "flows/Inventory/shared/queries/check/check.generated";
import { reportErrorsToDDAndThrow } from "utils/datadog";
import { isNullOrUndefined } from "utils/tsHelpers";

import {
  ValidateBbdCheckDocument,
  ValidateBbdCheckMutation,
  ValidateBbdCheckMutationVariables,
} from "../../queries/bbdCheck/bbdCheck.generated";
import { BbdCheckMachineContext } from "./types";

export function useBbdCheckServicesImplems() {
  const client = useApolloClient();
  const { startNextCheck: fetchNextCheckAndStartCheckService } = useStartNextCheck();

  const performCheckUpdate = useCallback(
    async (context: BbdCheckMachineContext) => {
      const { checkId, expiredQuantity, tooGoodToGoQuantity, closestBbd } = context;
      return reportErrorsToDDAndThrow(
        "updating BBD check",
        async () => {
          if (isNullOrUndefined(checkId)) {
            throw new Error("No checkId present in context");
          }
          const closestBBDAfterOutbound = closestBbd?.toISOString(); // back end expected format is ISO
          try {
            await client.mutate<ValidateBbdCheckMutation, ValidateBbdCheckMutationVariables>({
              mutation: ValidateBbdCheckDocument,
              variables: {
                input: {
                  checkId,
                  closestBBDAfterOutbound,
                  quantityExpired: expiredQuantity,
                  quantityTooGoodToGo: tooGoodToGoQuantity,
                },
              },
            });
          } catch (e: any) {
            if (e?.graphQLErrors?.[0]?.extensions?.code === "TASK_UPDATE_CONFLICT") {
              throw new Error("taskAlreadyCompleted");
            }
            throw e;
          }
          return null;
        },
        {
          xstateContext: context,
        },
      );
    },
    [client],
  );

  const createCheck = useCallback(
    async (context: BbdCheckMachineContext) => {
      const input: Partial<CreateCheckInput> = {
        type: TaskType.stock_check,
        shelfNumber: `${context.shelfNumber}${context.shelfLetter}`,
        sku: context.sku!,
      };

      if (context.createCheckType === "NO_QUANTITY") {
        input.metadataReason = TaskMetadataReason.bbd_missing;
        input.priority = 6;
      } else {
        input.metadataReason = TaskMetadataReason.bbd;
        input.priority = 5;
      }

      return reportErrorsToDDAndThrow(
        "creating BBD check",
        async () => {
          await client.mutate<CreateCheckMutation, CreateCheckMutationVariables>({
            mutation: CreateCheckDocument,
            variables: {
              input: input as CreateCheckInput,
            },
          });
          return { success: true };
        },
        {
          xstateContext: context,
        },
      );
    },
    [client],
  );

  const fetchNextCheckAndStartCheck = useCallback(
    (context: BbdCheckMachineContext) => {
      return fetchNextCheckAndStartCheckService({
        groupedPriority: getPriorityGroup(context.priority),
        shelfNumber: `${context.shelfNumber}${context.shelfLetter}`,
        type: [TaskType.bbd_check],
      });
    },
    [fetchNextCheckAndStartCheckService],
  );

  return {
    performCheckUpdate,
    fetchNextCheckAndStartCheck,
    createCheck,
  };
}
