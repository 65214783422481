import { useCallback, useEffect } from "react";

import { useNavigate } from "react-router";
import { EventObject, Interpreter } from "xstate";

import { XstateRouteSyncMeta } from "shared/types/xstate";

export function useInterpreterRouteSynchronizer<TContext, TEvent extends EventObject>(
  serviceName: string,
  service: Interpreter<TContext, any, TEvent, any, any>,
) {
  const navigate = useNavigate();

  useEffect(() => {
    const sub = service.subscribe((state) => {
      // In case of parallel states, and also to support attaching routes to child states
      // as well as parent states, this code will look through all currently matching state strings
      // to find an attached route, starting at the back of the array, i.e. with the child states.
      // That means you can override a parent's route in a specific child state
      // by adding a different route there.
      const metaLookUpChain = state.toStrings().reverse();

      const metaRouteSync = metaLookUpChain.reduce(
        (meta: XstateRouteSyncMeta | undefined, metaKey) => {
          return (
            meta ||
            state.meta[`${serviceName}.${metaKey}`]?.routeSync ||
            state.meta[metaKey]?.routeSync
          );
        },
        undefined,
      );

      if (!metaRouteSync) {
        return;
      }
      const replace: boolean = metaRouteSync?.replace ?? false;

      let landingRoute: string | null = null;
      // Check if there is a dynamic params in route that need to be generated with the context
      if (metaRouteSync.generatePath) {
        // Example of a generatePath function set in the Xstate meta
        /*
          meta: {
            routeSync: {
              generatePath: (context: MachineContext) => {
                return reactRouterGeneratePath("/stockCheck/:checkId", { // reactRouterGeneratePath is generatePath function imported from react-router
                    checkId: context.checkId,
                  });
                },
              },
            },
          },
        */
        landingRoute = metaRouteSync?.generatePath(state.context);
      } // otherwise check if there is a dynamic path in the meta
      else if (metaRouteSync.path !== undefined) {
        landingRoute = metaRouteSync.path;
      }

      if (landingRoute !== null && landingRoute !== window.location.pathname) {
        navigate(landingRoute, {
          replace,
        });
      }
    });

    return () => {
      sub.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [service, serviceName]);

  const goBack = useCallback(() => {
    if (service.getSnapshot().nextEvents.includes("GO_BACK")) {
      service.send("GO_BACK");
    }
  }, [service]);

  useEffect(() => {
    window.addEventListener("popstate", goBack);
    return () => window.removeEventListener("popstate", goBack);
  }, [goBack]);
}
