import { useCallback } from "react";

import { Modal, ModalBody, ModalContent, ModalFooter, ModalOverlay } from "@chakra-ui/react";
import { FormattedMessage } from "react-intl";

import { useAppUpdate } from "core/hooks/useAppUpdate";
import { useLayoutStore } from "core/stores/useLayoutStore";
import { Button } from "ui/Button/Button";
import { BodyM, HeaderS } from "ui/Typography/Typography";

const closeUpdateAppModal = () => useLayoutStore.setState({ showUpdateAppModal: false });

export function UpdateAppModal() {
  const showUpdateAppModal = useLayoutStore((state) => state.showUpdateAppModal);

  const updateApp = useAppUpdate();

  const onClickUpdateNow = useCallback(() => {
    updateApp();
  }, [updateApp]);

  return (
    <Modal
      trapFocus={false}
      closeOnOverlayClick={false}
      isCentered
      isOpen={showUpdateAppModal}
      onClose={() => {}}
    >
      <ModalOverlay bg="rgba(0,0,0,0.2)" />
      <ModalContent marginX="s300">
        <ModalBody display="flex" flexDir="column" alignItems="center" textAlign="center" p="s200">
          <HeaderS mt="s200">
            <FormattedMessage id="core.components.update-app-modal.title" />
          </HeaderS>
          <BodyM mt="s100" color="grey.700">
            <FormattedMessage id="core.components.update-app-modal.description" />
          </BodyM>
        </ModalBody>
        <ModalFooter paddingX="s200" pt="s100" flexDir="column">
          <Button onClick={onClickUpdateNow} flinkVariant="primary" size="lg" width="full">
            <FormattedMessage id="core.components.update-app-modal.update-now" />
          </Button>
          <Button
            mt="s100"
            onClick={closeUpdateAppModal}
            flinkVariant="secondary"
            size="lg"
            width="full"
          >
            <FormattedMessage id="core.components.update-app-modal.update-later" />
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
