import { memo, useCallback, useEffect, useRef, useState } from "react";

import { Box, Flex } from "@chakra-ui/react";

import { TitleS } from "ui/Typography/Typography";
import { isNotNullNorUndefined } from "utils/tsHelpers";

import { DateTimePickerColumn } from "../DateTimePickerColumn";

export const DATE_PICKER_WIDTH_NUMBER = 262;
export const NUMBER_HEIGHT = 30;
const COLUMN_WIDTH = 60;

// month, here, is NOT zero-indexed
const daysInMonth = (month: number | string, year: number | string) => {
  const parsedMonth = typeof month === "string" ? parseInt(month, 10) : month;
  const parsedYear = typeof year === "string" ? parseInt(year, 10) : year;
  return new Date(parsedYear, parsedMonth, 0).getDate();
};

const daysInMonthAsArray = (numberOfdaysInMonth: number) => {
  return Array.from({ length: numberOfdaysInMonth }, (_, i) => i + 1);
};

// month, here, is NOT zero-indexed
const getDaysInMonth = (month: number, year: number, minDate: Date, maxDate: Date) => {
  let days = daysInMonthAsArray(daysInMonth(month, year));
  const zeroIndexedMonth = month - 1;
  if (year === minDate.getFullYear() && minDate.getMonth() === zeroIndexedMonth) {
    const removeBeforeIndex = days.indexOf(minDate.getDate());
    days = days.slice(removeBeforeIndex);
  }
  if (year === maxDate.getFullYear() && maxDate.getMonth() === zeroIndexedMonth) {
    const removeFromIndex = days.indexOf(maxDate.getDate());
    days = days.slice(0, removeFromIndex + 1);
  }
  return days;
};

const getMonths = (year: number, minDate: Date, maxDate: Date) => {
  let months = Array.from({ length: 12 }, (_, i) => i + 1);
  if (year === minDate.getFullYear()) {
    const removeBeforeIndex = months.indexOf(minDate.getMonth() + 1);
    months = months.slice(removeBeforeIndex);
  }
  if (year === maxDate.getFullYear()) {
    const removeFromIndex = months.indexOf(maxDate.getMonth() + 1);
    months = months.slice(0, removeFromIndex + 1);
  }
  return months;
};

// month is zero-indexed
const isValidDate = (day: number, month: number, year: number) => {
  return day <= daysInMonth(month + 1, year);
};

const getInitialValueForDatePart = (
  initialValue: Date,
  minDate: Date,
  maxDate: Date,
  part: "day" | "month" | "year",
) => {
  const adjustedInitialValue = (() => {
    const initialValueTime = initialValue.getTime();
    const minDateTime = minDate.getTime();
    const maxDateTime = maxDate.getTime();
    if (initialValueTime < minDateTime) {
      return minDate;
    }
    if (initialValueTime > maxDateTime) {
      return maxDate;
    }
    return initialValue;
  })();
  const day = adjustedInitialValue.getDate();
  const month = adjustedInitialValue.getMonth();
  const year = adjustedInitialValue.getFullYear();
  const parsedMinYear = minDate.getFullYear();
  const parsedMaxYear = maxDate.getFullYear();
  const isInRange = year >= parsedMinYear && year <= parsedMaxYear;
  const finalYear = isInRange ? year : parsedMinYear;
  const isDateValid = isValidDate(day, month, finalYear);
  const finalDay = isDateValid ? day : 1;
  const finalMonth = isDateValid ? month : 1;
  switch (part) {
    case "day":
      return finalDay;
    case "month":
      return finalMonth;
    case "year":
      return finalYear;
    default:
      return finalDay;
  }
};

type DatePickerProps = {
  initialValue?: Date;
  minDate?: Date;
  maxDate?: Date;
  onChange?: (newDate: Date) => void;
  dayLabel: string;
  monthLabel: string;
  yearLabel: string;
};

function DatePickerComponent({
  minDate = new Date(new Date().getFullYear(), 0, 1),
  maxDate = new Date(new Date().getFullYear() + 2, 11, 31),
  initialValue = new Date(),
  onChange,
  dayLabel,
  monthLabel,
  yearLabel,
}: DatePickerProps) {
  const [years] = useState(() => {
    const parsedMinYear = minDate.getFullYear();
    let parsedMaxYear = maxDate.getFullYear();
    if (parsedMaxYear < parsedMinYear) {
      parsedMaxYear = parsedMinYear + 2;
    }
    return Array.from({ length: parsedMaxYear - parsedMinYear + 1 }, (_, i) => parsedMinYear + i);
  });
  const [months, setMonths] = useState(() => {
    return getMonths(initialValue.getFullYear(), minDate, maxDate);
  });
  const [numberOfDaysInMonth, setNumberOfDaysInMonth] = useState(() => {
    return getDaysInMonth(
      initialValue.getMonth() + 1,
      initialValue.getFullYear(),
      minDate,
      maxDate,
    );
  });

  const currentDay = useRef(getInitialValueForDatePart(initialValue, minDate, maxDate, "day"));
  // currentMonth is zero-index, meaning that february will be equal to 1
  const currentMonth = useRef(getInitialValueForDatePart(initialValue, minDate, maxDate, "month"));
  const currentYear = useRef(getInitialValueForDatePart(initialValue, minDate, maxDate, "year"));

  useEffect(() => {
    setNumberOfDaysInMonth(
      getDaysInMonth(currentMonth.current + 1, currentYear.current, minDate, maxDate),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const triggerOnChange = useCallback(() => {
    if (
      isNotNullNorUndefined(onChange) &&
      isValidDate(currentDay.current, currentMonth.current, currentYear.current)
    ) {
      onChange(new Date(currentYear.current, currentMonth.current, currentDay.current, 12));
    }
  }, [onChange]);

  const onDayChange = useCallback(
    (newDay: number) => {
      currentDay.current = newDay;
      triggerOnChange();
    },
    [triggerOnChange],
  );

  const onMonthChange = useCallback(
    (newMonth: number) => {
      // The value returned by the component is not zero-indexed
      setNumberOfDaysInMonth(getDaysInMonth(newMonth, currentYear.current, minDate, maxDate));
      currentMonth.current = newMonth - 1;
      triggerOnChange();
    },
    [triggerOnChange, minDate, maxDate],
  );

  const onYearChange = useCallback(
    (newYear: number) => {
      setNumberOfDaysInMonth(getDaysInMonth(currentMonth.current + 1, newYear, minDate, maxDate));
      setMonths(getMonths(newYear, minDate, maxDate));
      currentYear.current = newYear;
      triggerOnChange();
    },
    [triggerOnChange, minDate, maxDate],
  );

  return (
    <Flex direction="column" width={`${DATE_PICKER_WIDTH_NUMBER}px`}>
      <Flex
        borderTopLeftRadius="10px"
        borderTopRightRadius="10px"
        bg="grey.100"
        alignItems="center"
        justifyContent="space-evenly"
        height="40px"
      >
        <TitleS color="grey.700">{dayLabel}</TitleS>
        <TitleS color="grey.700">{monthLabel}</TitleS>
        <TitleS color="grey.700">{yearLabel}</TitleS>
      </Flex>
      <Flex justifyContent="space-evenly" position="relative">
        <Box
          position="absolute"
          top="0"
          bottom="0"
          left="0"
          right="0"
          opacity="0.03"
          zIndex={4}
          pointerEvents="none"
          bg="black"
          borderBottomLeftRadius="10px"
          borderBottomRightRadius="10px"
        />
        <Box
          position="absolute"
          top={`${NUMBER_HEIGHT * 2}px`}
          left="14px"
          right="14px"
          minHeight={`${NUMBER_HEIGHT}px`}
          bgColor="grey.200"
          minWidth={`${COLUMN_WIDTH}px`}
          pointerEvents="none"
          borderRadius="8px"
          zIndex={1}
        />
        <DateTimePickerColumn
          columnWidth={COLUMN_WIDTH}
          elementHeight={NUMBER_HEIGHT}
          elements={numberOfDaysInMonth}
          onChange={onDayChange}
          initialValue={currentDay.current}
          showLeadingZero
          dataTestId="date-picker-day-column"
          id="date-picker-day-column"
        />
        <DateTimePickerColumn
          columnWidth={COLUMN_WIDTH}
          elementHeight={NUMBER_HEIGHT}
          elements={months}
          onChange={onMonthChange}
          initialValue={currentMonth.current + 1}
          showLeadingZero
          dataTestId="date-picker-month-column"
          id="date-picker-month-column"
        />
        <DateTimePickerColumn
          columnWidth={COLUMN_WIDTH}
          elementHeight={NUMBER_HEIGHT}
          elements={years}
          onChange={onYearChange}
          initialValue={currentYear.current}
          dataTestId="date-picker-year-column"
          id="date-picker-year-column"
        />
      </Flex>
    </Flex>
  );
}

export const DatePicker = memo(DatePickerComponent);

DatePicker.displayName = "DatePicker";
