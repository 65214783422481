import { useCallback, useEffect, useMemo } from "react";

import { useNavigate } from "react-router";
import shallow from "zustand/shallow";

import { routes } from "config/routes";
import { useAppLanguageStore } from "core/stores/useAppLanguageStore";
import {
  useFetchNonScannableCategories,
  useLetterRanges,
  useSafeSelectedLetterRangeId,
} from "shared/stores/useProductSearchService";
import { useProductSearchStore } from "shared/stores/useProductSearchStore";
import { LetterRangeSelector } from "ui/LetterRangeSelector/LetterRangeSelector";
import { SubcategoryList } from "ui/SubcategoryList/SubcategoryList";

import { useInboundStore } from "../stores/inboundStore/useInboundStore";

export function ProductsSearchNoEANSubcategoriesList() {
  const navigate = useNavigate();

  const setSelectedSubcategory = useProductSearchStore((state) => state.setSelectedSubcategory);

  const { currentLetterRangeId, setInboundUIState } = useInboundStore(
    (state) => ({
      currentLetterRangeId: state.inboundUIState.currentLetterRangeId,
      setInboundUIState: state.setInboundUIState,
    }),
    shallow,
  );
  const appLanguage = useAppLanguageStore((state) => state.appLanguage);
  const { categories: groupedSubcategories } = useFetchNonScannableCategories();

  const letterRanges = useLetterRanges(groupedSubcategories || {});
  const safeSelectedLetterRangeId = useSafeSelectedLetterRangeId(
    letterRanges,
    currentLetterRangeId,
  );

  const selectLetterRangeId = useCallback(
    (letterRangeId: string) => setInboundUIState({ currentLetterRangeId: letterRangeId }),
    [setInboundUIState],
  );

  useEffect(() => {
    if (safeSelectedLetterRangeId !== currentLetterRangeId) {
      selectLetterRangeId(safeSelectedLetterRangeId);
    }
  }, [currentLetterRangeId, safeSelectedLetterRangeId, selectLetterRangeId]);

  const displayedSubcategories = useMemo(
    () => groupedSubcategories?.[safeSelectedLetterRangeId] || [],
    [groupedSubcategories, safeSelectedLetterRangeId],
  );

  const handleSubcategoryClick = useCallback(
    (subcategoryID: string, subcategoryName?: string) => {
      setTimeout(() => {
        setSelectedSubcategory({ subcategoryID, subcategoryName });
        navigate(routes.inbound.noEANProducts);
      }, 90);
    },
    [navigate, setSelectedSubcategory],
  );

  return (
    <>
      <LetterRangeSelector
        p="s200"
        pt="0"
        value={safeSelectedLetterRangeId}
        letterRanges={letterRanges}
        onInput={(v) => selectLetterRangeId(v as string)}
      />
      <SubcategoryList
        subcategories={displayedSubcategories}
        onClickSubcategory={handleSubcategoryClick}
        lang={appLanguage}
        bgColor="white"
        pt="s300"
        overflowY="scroll"
      />
    </>
  );
}
