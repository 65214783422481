import { useMemo, Fragment, useCallback } from "react";

import { Circle, Flex } from "@chakra-ui/react";
import { groupBy, orderBy } from "lodash";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router";

import { TaskType } from "__graphql__/types";
import { PageName } from "analytics/events";
import { routes } from "config/routes";
import { openOngoingActivityModal } from "core/stores/useLayoutStore";
import { CheckScannerV2 } from "flows/Inventory/shared/components/CheckScannerV2";
import { CheckListItem, ChecksList } from "flows/Inventory/shared/components/ChecksList";
import { ConnectionErrorModal } from "flows/Inventory/shared/components/ConnectionErrorModal";
import { TaskIsTakenModal } from "flows/Inventory/shared/components/TaskIsTakenModal";
import { useChecksList } from "flows/Inventory/shared/hooks/useChecksList";
import { SpinnerModal } from "shared/components/SpinnerModal";
import { SwipeablePageWrapper } from "shared/components/SwipeablePageWrapper";
import { useTimedActivityStore } from "shared/stores/useTimedActivityStore";
import { IntlMessageId } from "shared/types/lang";
import { NavigationHeader } from "ui/NavigationHeader/NavigationHeader";
import { TitleM, TitleXS } from "ui/Typography/Typography";
import { isNotNullNorUndefined, isNullOrUndefined } from "utils/tsHelpers";

type StockCheckPriorityTag = "urgent" | "top" | "medium" | "low";

type ChecksAndCountGroupedByPriorityTag = Record<
  StockCheckPriorityTag,
  { count: number; checks: CheckListItem[] }
>;

const priorityToStockCheckPriorityTag: Record<number, StockCheckPriorityTag> = {
  0: "low",
  1: "low",
  2: "medium",
  3: "top",
  4: "urgent",
  5: "urgent",
  6: "urgent",
};

export function StockChecksList() {
  const intl = useIntl();
  const navigate = useNavigate();

  const timedActivityInstance = useTimedActivityStore((state) => state.timedActivityInstance);

  const {
    rawChecks,
    refetchChecks,
    isLoadingChecks,
    isLoadingFetchNextCheckAndStartCheck,
    startCheck,
    isErrorModalOpen,
    isTaskIsTakenModalOpen,
    onCloseErrorModal,
    onCloseTaskIsTakenModal,
  } = useChecksList({
    type: [TaskType.stock_check],
    origin: PageName.INVENTORY_STOCK_CHECK_LIST_PAGE,
  });

  const onSelectCheck = useCallback(
    (shelfId: string, priority: number) => {
      if (isNotNullNorUndefined(timedActivityInstance)) {
        openOngoingActivityModal();
        return;
      }
      startCheck(shelfId, priority);
    },
    [startCheck, timedActivityInstance],
  );

  const checksAndCountGroupedByPriorityTag = useMemo<ChecksAndCountGroupedByPriorityTag>(() => {
    // First, group checks by priority
    const checksGroupedByPriority = groupBy(rawChecks, "priority");
    // Second, group checks by priority tag, we map over an int's array so that they are
    // already sorted by priority when pushed to the grouped array
    const checksGroupedByPriorityTag = [6, 5, 4, 3, 2, 1, 0].reduce(
      (acc: Record<StockCheckPriorityTag, CheckListItem[]>, priority) => {
        const checks = checksGroupedByPriority[priority];
        if (isNullOrUndefined(checks) || checks.length === 0) {
          return acc;
        }
        const stockCheckPriorityTag = priorityToStockCheckPriorityTag[priority];
        acc[stockCheckPriorityTag].push(...checks);
        return acc;
      },
      { urgent: [], top: [], medium: [], low: [] },
    );

    // Third, within the created groups, group by shelfNumber
    return Object.entries(checksGroupedByPriorityTag).reduce((acc, [priorityTag, checks]) => {
      let count = 0;
      const groupedByShelf = Object.entries(groupBy(checks, "shelfNumber")).map(
        ([shelfNumber, groupedByShelfChecks]) => {
          count += groupedByShelfChecks.length;
          return {
            shelfNumber,
            type: TaskType.stock_check,
            count: groupedByShelfChecks.length,
            priority: groupedByShelfChecks[0].priority,
            id: groupedByShelfChecks[0].id,
          };
        },
      );
      // Finally, order checks by shelf and count the total number of checks for this priority group
      acc[priorityTag as StockCheckPriorityTag] = {
        count,
        checks: orderBy(groupedByShelf, ["shelfNumber"], ["asc"]),
      };
      return acc;
    }, {} as ChecksAndCountGroupedByPriorityTag);
  }, [rawChecks]);

  return (
    <>
      <SwipeablePageWrapper
        refetch={refetchChecks}
        isFull
        justifyContent="flex-start"
        flexDirection="column"
        h="100%"
        isLoading={isLoadingChecks}
      >
        <NavigationHeader
          title={intl.formatMessage({
            id: "flows.inventory.stock-checks.pages.stock-checks-list.title",
          })}
          onClickGoBack={() => navigate(routes.inventory.root)}
        />
        <Flex h="100%" direction="column" w="100%" overflowY="scroll">
          <CheckScannerV2
            checksList={rawChecks}
            startCheck={startCheck}
            type={[TaskType.stock_check]}
          />
          {Object.entries(checksAndCountGroupedByPriorityTag).map(
            ([priorityTag, { checks, count }]) => {
              if (count === 0) {
                return null;
              }
              return (
                <Fragment key={priorityTag}>
                  <Flex w="100%" alignItems="center" px="s200" pt="s100" mb="s100">
                    <TitleM>
                      {intl.formatMessage({
                        id: `flows.inventory.stock-checks.pages.stock-checks-list.prioriry-group-${priorityTag}` as IntlMessageId,
                      })}
                    </TitleM>
                    <Circle minW="20px" minH="20px" px="s50" bg="grey.600" m="s100">
                      <TitleXS color="white">{count}</TitleXS>
                    </Circle>
                  </Flex>
                  <ChecksList items={checks} onSelectCheck={onSelectCheck} />
                </Fragment>
              );
            },
          )}
        </Flex>
      </SwipeablePageWrapper>
      <TaskIsTakenModal
        isOpen={isTaskIsTakenModalOpen}
        onClose={onCloseTaskIsTakenModal}
        onRefresh={refetchChecks}
      />
      <ConnectionErrorModal
        isOpen={isErrorModalOpen}
        onClose={onCloseErrorModal}
        onRefresh={refetchChecks}
      />
      <SpinnerModal isOpen={isLoadingFetchNextCheckAndStartCheck} />
    </>
  );
}
