import { Box, BoxProps } from "@chakra-ui/react";

import { ArrowDownIcon, ArrowUpIcon } from "ui/Icons/Icons";

export type AscDscIconProps = {
  sortState: "asc" | "desc";
} & BoxProps;

export function AscDscIcon({ sortState, ...rest }: AscDscIconProps) {
  return (
    <Box {...rest} position="relative">
      <ArrowUpIcon
        position="absolute"
        left="0"
        top="0"
        height={rest.height}
        width={rest.width}
        color={sortState === "asc" ? "grey.800" : "grey.400"}
      />
      <ArrowDownIcon
        position="absolute"
        left="0"
        top="0"
        height={rest.height}
        width={rest.width}
        color={sortState === "asc" ? "grey.400" : "grey.800"}
      />
    </Box>
  );
}
