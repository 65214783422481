import * as Types from "../../../../../__graphql__/types.js";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type SearchProductsByEanQueryVariables = Types.Exact<{
  searchProductsByEanInput: Types.SearchUnitsByEanInput;
  isAlwaysInbound?: Types.InputMaybe<Types.Scalars["Boolean"]>;
}>;

export type SearchProductsByEanQuery = {
  __typename?: "Query";
  searchUnitsByEan: {
    __typename?: "SearchUnitsByEanResponse";
    units: Array<{
      __typename?: "Unit";
      product: {
        __typename?: "IProduct";
        bio?: boolean | null;
        imageUrl?: string | null;
        name: string;
        sku: string;
        countryOfOrigin: { __typename?: "CountryOfOrigin"; code?: string | null };
        inventoryEntry: {
          __typename?: "InventoryEntry";
          shelfNumber?: string | null;
          stock: { __typename?: "Stock"; shelf: number };
        };
      };
    }>;
  };
};

export type SearchProductsByTextQueryVariables = Types.Exact<{
  searchProductsByTextInput: Types.SearchUnitsByTextInput;
}>;

export type SearchProductsByTextQuery = {
  __typename?: "Query";
  searchUnitsByText: {
    __typename?: "SearchUnitsByTextResponse";
    units: Array<{
      __typename?: "Unit";
      product: {
        __typename?: "IProduct";
        bio?: boolean | null;
        imageUrl?: string | null;
        name: string;
        sku: string;
        countryOfOrigin: { __typename?: "CountryOfOrigin"; code?: string | null };
        inventoryEntry: {
          __typename?: "InventoryEntry";
          shelfNumber?: string | null;
          stock: { __typename?: "Stock"; shelf: number };
        };
      };
    }>;
  };
};

export type GetProductsByShelfQueryVariables = Types.Exact<{
  input: Types.GetInventoryEntriesByShelfInput;
}>;

export type GetProductsByShelfQuery = {
  __typename?: "Query";
  getInventoryEntriesByShelf: {
    __typename?: "GetInventoryEntriesByShelfResponse";
    inventoryEntries: Array<{
      __typename?: "InventoryEntry";
      product?: {
        __typename?: "IProduct";
        bio?: boolean | null;
        imageUrl?: string | null;
        name: string;
        sku: string;
        numberOfShelfFacings?: number | null;
        isShelvedInHandlingUnits?: boolean | null;
        countryOfOrigin: { __typename?: "CountryOfOrigin"; code?: string | null };
        inventoryEntry: {
          __typename?: "InventoryEntry";
          shelfNumber?: string | null;
          stock: { __typename?: "Stock"; shelf: number };
        };
      } | null;
    }>;
  };
};

export const SearchProductsByEanDocument = gql`
  query searchProductsByEan(
    $searchProductsByEanInput: SearchUnitsByEanInput!
    $isAlwaysInbound: Boolean
  ) {
    searchUnitsByEan(
      searchUnitsByEanInput: $searchProductsByEanInput
      isAlwaysInbound: $isAlwaysInbound
    ) {
      units {
        product {
          bio
          imageUrl
          name
          sku
          countryOfOrigin {
            code
          }
          inventoryEntry {
            shelfNumber
            stock {
              shelf
            }
          }
        }
      }
    }
  }
`;

/**
 * __useSearchProductsByEanQuery__
 *
 * To run a query within a React component, call `useSearchProductsByEanQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchProductsByEanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchProductsByEanQuery({
 *   variables: {
 *      searchProductsByEanInput: // value for 'searchProductsByEanInput'
 *      isAlwaysInbound: // value for 'isAlwaysInbound'
 *   },
 * });
 */
export function useSearchProductsByEanQuery(
  baseOptions: Apollo.QueryHookOptions<SearchProductsByEanQuery, SearchProductsByEanQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SearchProductsByEanQuery, SearchProductsByEanQueryVariables>(
    SearchProductsByEanDocument,
    options,
  );
}
export function useSearchProductsByEanLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchProductsByEanQuery,
    SearchProductsByEanQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SearchProductsByEanQuery, SearchProductsByEanQueryVariables>(
    SearchProductsByEanDocument,
    options,
  );
}
export type SearchProductsByEanQueryHookResult = ReturnType<typeof useSearchProductsByEanQuery>;
export type SearchProductsByEanLazyQueryHookResult = ReturnType<
  typeof useSearchProductsByEanLazyQuery
>;
export type SearchProductsByEanQueryResult = Apollo.QueryResult<
  SearchProductsByEanQuery,
  SearchProductsByEanQueryVariables
>;
export const SearchProductsByTextDocument = gql`
  query searchProductsByText($searchProductsByTextInput: SearchUnitsByTextInput!) {
    searchUnitsByText(searchUnitsByTextInput: $searchProductsByTextInput) {
      units {
        product {
          bio
          imageUrl
          name
          sku
          countryOfOrigin {
            code
          }
          inventoryEntry {
            shelfNumber
            stock {
              shelf
            }
          }
        }
      }
    }
  }
`;

/**
 * __useSearchProductsByTextQuery__
 *
 * To run a query within a React component, call `useSearchProductsByTextQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchProductsByTextQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchProductsByTextQuery({
 *   variables: {
 *      searchProductsByTextInput: // value for 'searchProductsByTextInput'
 *   },
 * });
 */
export function useSearchProductsByTextQuery(
  baseOptions: Apollo.QueryHookOptions<
    SearchProductsByTextQuery,
    SearchProductsByTextQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SearchProductsByTextQuery, SearchProductsByTextQueryVariables>(
    SearchProductsByTextDocument,
    options,
  );
}
export function useSearchProductsByTextLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchProductsByTextQuery,
    SearchProductsByTextQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SearchProductsByTextQuery, SearchProductsByTextQueryVariables>(
    SearchProductsByTextDocument,
    options,
  );
}
export type SearchProductsByTextQueryHookResult = ReturnType<typeof useSearchProductsByTextQuery>;
export type SearchProductsByTextLazyQueryHookResult = ReturnType<
  typeof useSearchProductsByTextLazyQuery
>;
export type SearchProductsByTextQueryResult = Apollo.QueryResult<
  SearchProductsByTextQuery,
  SearchProductsByTextQueryVariables
>;
export const GetProductsByShelfDocument = gql`
  query getProductsByShelf($input: GetInventoryEntriesByShelfInput!) {
    getInventoryEntriesByShelf(input: $input) {
      inventoryEntries {
        product {
          bio
          imageUrl
          name
          sku
          countryOfOrigin {
            code
          }
          numberOfShelfFacings
          isShelvedInHandlingUnits
          inventoryEntry {
            shelfNumber
            stock {
              shelf
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetProductsByShelfQuery__
 *
 * To run a query within a React component, call `useGetProductsByShelfQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductsByShelfQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductsByShelfQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetProductsByShelfQuery(
  baseOptions: Apollo.QueryHookOptions<GetProductsByShelfQuery, GetProductsByShelfQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProductsByShelfQuery, GetProductsByShelfQueryVariables>(
    GetProductsByShelfDocument,
    options,
  );
}
export function useGetProductsByShelfLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProductsByShelfQuery,
    GetProductsByShelfQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetProductsByShelfQuery, GetProductsByShelfQueryVariables>(
    GetProductsByShelfDocument,
    options,
  );
}
export type GetProductsByShelfQueryHookResult = ReturnType<typeof useGetProductsByShelfQuery>;
export type GetProductsByShelfLazyQueryHookResult = ReturnType<
  typeof useGetProductsByShelfLazyQuery
>;
export type GetProductsByShelfQueryResult = Apollo.QueryResult<
  GetProductsByShelfQuery,
  GetProductsByShelfQueryVariables
>;
