import * as Types from "../../../../../../__graphql__/types.js";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type GetPrivateRestockingListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GetPrivateRestockingListQuery = {
  __typename?: "Query";
  getPrivateRestockingList: {
    __typename?: "PrivateRestockingListResponse";
    id: string;
    restockingItems: Array<{
      __typename?: "RestockingItem";
      status: Types.RestockingItemStatus;
      sku: string;
      product?: {
        __typename?: "IProduct";
        sku: string;
        name: string;
        imageUrl?: string | null;
        bio?: boolean | null;
        numberOfShelfFacings?: number | null;
        isShelvedInHandlingUnits?: boolean | null;
        countryOfOrigin: { __typename?: "CountryOfOrigin"; code?: string | null };
        inventoryEntry: {
          __typename?: "InventoryEntry";
          shelfNumber?: string | null;
          stock: { __typename?: "Stock"; shelf: number };
        };
      } | null;
    }>;
  };
};

export type RestockItemMutationVariables = Types.Exact<{
  input: Types.RestockItemWithSkuAndListIdInput;
}>;

export type RestockItemMutation = {
  __typename?: "Mutation";
  restockItem: { __typename?: "RestockingMutationResponse"; message: string };
};

export type DeletePrivateRestockingListMutationVariables = Types.Exact<{
  input: Types.PrivateListIdInput;
}>;

export type DeletePrivateRestockingListMutation = {
  __typename?: "Mutation";
  deletePrivateRestockingList: {
    __typename?: "DeletePrivateListResponse";
    message: string;
    success: boolean;
  };
};

export type RemoveItemFromRestockingPrivateListMutationVariables = Types.Exact<{
  input: Types.RestockItemWithSkuAndListIdInput;
}>;

export type RemoveItemFromRestockingPrivateListMutation = {
  __typename?: "Mutation";
  removeItemFromRestockingPrivateList: {
    __typename?: "RestockingMutationResponse";
    message: string;
  };
};

export type RemoveMultipleSkusFromRestockingPrivateListMutationVariables = Types.Exact<{
  input: Types.RestockItemWithSkUsAndListIdInput;
}>;

export type RemoveMultipleSkusFromRestockingPrivateListMutation = {
  __typename?: "Mutation";
  removeMultipleSkusFromRestockingPrivateList: {
    __typename?: "RestockingMutationResponse";
    message: string;
  };
};

export type CreatePrivateRestockingListMutationVariables = Types.Exact<{
  input: Types.CreatePrivateRestockingListInput;
}>;

export type CreatePrivateRestockingListMutation = {
  __typename?: "Mutation";
  createPrivateRestockingList: { __typename?: "PrivateRestockingListResponse"; id: string };
};

export type DeleteSkuFromPrivateListMutationVariables = Types.Exact<{
  input: Types.RestockItemWithSkuAndListIdInput;
}>;

export type DeleteSkuFromPrivateListMutation = {
  __typename?: "Mutation";
  deleteSkuFromPrivateList: { __typename?: "RestockingMutationResponse"; message: string };
};

export const GetPrivateRestockingListDocument = gql`
  query getPrivateRestockingList {
    getPrivateRestockingList {
      id
      restockingItems {
        status
        sku
        product {
          sku
          name
          imageUrl
          bio
          numberOfShelfFacings
          isShelvedInHandlingUnits
          countryOfOrigin {
            code
          }
          inventoryEntry {
            shelfNumber
            stock {
              shelf
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetPrivateRestockingListQuery__
 *
 * To run a query within a React component, call `useGetPrivateRestockingListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPrivateRestockingListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPrivateRestockingListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPrivateRestockingListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetPrivateRestockingListQuery,
    GetPrivateRestockingListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPrivateRestockingListQuery, GetPrivateRestockingListQueryVariables>(
    GetPrivateRestockingListDocument,
    options,
  );
}
export function useGetPrivateRestockingListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPrivateRestockingListQuery,
    GetPrivateRestockingListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPrivateRestockingListQuery, GetPrivateRestockingListQueryVariables>(
    GetPrivateRestockingListDocument,
    options,
  );
}
export type GetPrivateRestockingListQueryHookResult = ReturnType<
  typeof useGetPrivateRestockingListQuery
>;
export type GetPrivateRestockingListLazyQueryHookResult = ReturnType<
  typeof useGetPrivateRestockingListLazyQuery
>;
export type GetPrivateRestockingListQueryResult = Apollo.QueryResult<
  GetPrivateRestockingListQuery,
  GetPrivateRestockingListQueryVariables
>;
export const RestockItemDocument = gql`
  mutation restockItem($input: RestockItemWithSKUAndListIdInput!) {
    restockItem(input: $input) {
      message
    }
  }
`;
export type RestockItemMutationFn = Apollo.MutationFunction<
  RestockItemMutation,
  RestockItemMutationVariables
>;

/**
 * __useRestockItemMutation__
 *
 * To run a mutation, you first call `useRestockItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestockItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restockItemMutation, { data, loading, error }] = useRestockItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRestockItemMutation(
  baseOptions?: Apollo.MutationHookOptions<RestockItemMutation, RestockItemMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RestockItemMutation, RestockItemMutationVariables>(
    RestockItemDocument,
    options,
  );
}
export type RestockItemMutationHookResult = ReturnType<typeof useRestockItemMutation>;
export type RestockItemMutationResult = Apollo.MutationResult<RestockItemMutation>;
export type RestockItemMutationOptions = Apollo.BaseMutationOptions<
  RestockItemMutation,
  RestockItemMutationVariables
>;
export const DeletePrivateRestockingListDocument = gql`
  mutation deletePrivateRestockingList($input: PrivateListIdInput!) {
    deletePrivateRestockingList(input: $input) {
      message
      success
    }
  }
`;
export type DeletePrivateRestockingListMutationFn = Apollo.MutationFunction<
  DeletePrivateRestockingListMutation,
  DeletePrivateRestockingListMutationVariables
>;

/**
 * __useDeletePrivateRestockingListMutation__
 *
 * To run a mutation, you first call `useDeletePrivateRestockingListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePrivateRestockingListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePrivateRestockingListMutation, { data, loading, error }] = useDeletePrivateRestockingListMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePrivateRestockingListMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeletePrivateRestockingListMutation,
    DeletePrivateRestockingListMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeletePrivateRestockingListMutation,
    DeletePrivateRestockingListMutationVariables
  >(DeletePrivateRestockingListDocument, options);
}
export type DeletePrivateRestockingListMutationHookResult = ReturnType<
  typeof useDeletePrivateRestockingListMutation
>;
export type DeletePrivateRestockingListMutationResult =
  Apollo.MutationResult<DeletePrivateRestockingListMutation>;
export type DeletePrivateRestockingListMutationOptions = Apollo.BaseMutationOptions<
  DeletePrivateRestockingListMutation,
  DeletePrivateRestockingListMutationVariables
>;
export const RemoveItemFromRestockingPrivateListDocument = gql`
  mutation removeItemFromRestockingPrivateList($input: RestockItemWithSKUAndListIdInput!) {
    removeItemFromRestockingPrivateList(input: $input) {
      message
    }
  }
`;
export type RemoveItemFromRestockingPrivateListMutationFn = Apollo.MutationFunction<
  RemoveItemFromRestockingPrivateListMutation,
  RemoveItemFromRestockingPrivateListMutationVariables
>;

/**
 * __useRemoveItemFromRestockingPrivateListMutation__
 *
 * To run a mutation, you first call `useRemoveItemFromRestockingPrivateListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveItemFromRestockingPrivateListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeItemFromRestockingPrivateListMutation, { data, loading, error }] = useRemoveItemFromRestockingPrivateListMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveItemFromRestockingPrivateListMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveItemFromRestockingPrivateListMutation,
    RemoveItemFromRestockingPrivateListMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveItemFromRestockingPrivateListMutation,
    RemoveItemFromRestockingPrivateListMutationVariables
  >(RemoveItemFromRestockingPrivateListDocument, options);
}
export type RemoveItemFromRestockingPrivateListMutationHookResult = ReturnType<
  typeof useRemoveItemFromRestockingPrivateListMutation
>;
export type RemoveItemFromRestockingPrivateListMutationResult =
  Apollo.MutationResult<RemoveItemFromRestockingPrivateListMutation>;
export type RemoveItemFromRestockingPrivateListMutationOptions = Apollo.BaseMutationOptions<
  RemoveItemFromRestockingPrivateListMutation,
  RemoveItemFromRestockingPrivateListMutationVariables
>;
export const RemoveMultipleSkusFromRestockingPrivateListDocument = gql`
  mutation removeMultipleSkusFromRestockingPrivateList($input: RestockItemWithSKUsAndListIdInput!) {
    removeMultipleSkusFromRestockingPrivateList(input: $input) {
      message
    }
  }
`;
export type RemoveMultipleSkusFromRestockingPrivateListMutationFn = Apollo.MutationFunction<
  RemoveMultipleSkusFromRestockingPrivateListMutation,
  RemoveMultipleSkusFromRestockingPrivateListMutationVariables
>;

/**
 * __useRemoveMultipleSkusFromRestockingPrivateListMutation__
 *
 * To run a mutation, you first call `useRemoveMultipleSkusFromRestockingPrivateListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveMultipleSkusFromRestockingPrivateListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeMultipleSkusFromRestockingPrivateListMutation, { data, loading, error }] = useRemoveMultipleSkusFromRestockingPrivateListMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveMultipleSkusFromRestockingPrivateListMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveMultipleSkusFromRestockingPrivateListMutation,
    RemoveMultipleSkusFromRestockingPrivateListMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveMultipleSkusFromRestockingPrivateListMutation,
    RemoveMultipleSkusFromRestockingPrivateListMutationVariables
  >(RemoveMultipleSkusFromRestockingPrivateListDocument, options);
}
export type RemoveMultipleSkusFromRestockingPrivateListMutationHookResult = ReturnType<
  typeof useRemoveMultipleSkusFromRestockingPrivateListMutation
>;
export type RemoveMultipleSkusFromRestockingPrivateListMutationResult =
  Apollo.MutationResult<RemoveMultipleSkusFromRestockingPrivateListMutation>;
export type RemoveMultipleSkusFromRestockingPrivateListMutationOptions = Apollo.BaseMutationOptions<
  RemoveMultipleSkusFromRestockingPrivateListMutation,
  RemoveMultipleSkusFromRestockingPrivateListMutationVariables
>;
export const CreatePrivateRestockingListDocument = gql`
  mutation createPrivateRestockingList($input: CreatePrivateRestockingListInput!) {
    createPrivateRestockingList(input: $input) {
      id
    }
  }
`;
export type CreatePrivateRestockingListMutationFn = Apollo.MutationFunction<
  CreatePrivateRestockingListMutation,
  CreatePrivateRestockingListMutationVariables
>;

/**
 * __useCreatePrivateRestockingListMutation__
 *
 * To run a mutation, you first call `useCreatePrivateRestockingListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePrivateRestockingListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPrivateRestockingListMutation, { data, loading, error }] = useCreatePrivateRestockingListMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePrivateRestockingListMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePrivateRestockingListMutation,
    CreatePrivateRestockingListMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreatePrivateRestockingListMutation,
    CreatePrivateRestockingListMutationVariables
  >(CreatePrivateRestockingListDocument, options);
}
export type CreatePrivateRestockingListMutationHookResult = ReturnType<
  typeof useCreatePrivateRestockingListMutation
>;
export type CreatePrivateRestockingListMutationResult =
  Apollo.MutationResult<CreatePrivateRestockingListMutation>;
export type CreatePrivateRestockingListMutationOptions = Apollo.BaseMutationOptions<
  CreatePrivateRestockingListMutation,
  CreatePrivateRestockingListMutationVariables
>;
export const DeleteSkuFromPrivateListDocument = gql`
  mutation deleteSkuFromPrivateList($input: RestockItemWithSKUAndListIdInput!) {
    deleteSkuFromPrivateList(input: $input) {
      message
    }
  }
`;
export type DeleteSkuFromPrivateListMutationFn = Apollo.MutationFunction<
  DeleteSkuFromPrivateListMutation,
  DeleteSkuFromPrivateListMutationVariables
>;

/**
 * __useDeleteSkuFromPrivateListMutation__
 *
 * To run a mutation, you first call `useDeleteSkuFromPrivateListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSkuFromPrivateListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSkuFromPrivateListMutation, { data, loading, error }] = useDeleteSkuFromPrivateListMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteSkuFromPrivateListMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteSkuFromPrivateListMutation,
    DeleteSkuFromPrivateListMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteSkuFromPrivateListMutation,
    DeleteSkuFromPrivateListMutationVariables
  >(DeleteSkuFromPrivateListDocument, options);
}
export type DeleteSkuFromPrivateListMutationHookResult = ReturnType<
  typeof useDeleteSkuFromPrivateListMutation
>;
export type DeleteSkuFromPrivateListMutationResult =
  Apollo.MutationResult<DeleteSkuFromPrivateListMutation>;
export type DeleteSkuFromPrivateListMutationOptions = Apollo.BaseMutationOptions<
  DeleteSkuFromPrivateListMutation,
  DeleteSkuFromPrivateListMutationVariables
>;
