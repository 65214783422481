import { ReactElement, ReactNode } from "react";

import { Box, BoxProps, Flex, FlexProps } from "@chakra-ui/react";

import { Avatar } from "ui/Avatar/Avatar";
import { NotificationPill } from "ui/NotificationPill/NotificationPill";
import { BodyM } from "ui/Typography/Typography";
import { isNotNullNorUndefined } from "utils/tsHelpers";

export type MenuItem = {
  icon: ReactElement;
  showIconNotification?: boolean;
  title: string | ReactElement;
  onClick?: () => void;
  rightIcon?: ReactElement;
  subtitle?: ReactElement;
};

export type MenuProps = {
  items: MenuItem[];
  userName?: string;
  badgeNo?: string;
} & BoxProps;

type ItemWrapperProps = { children: ReactNode } & FlexProps;

function ItemWrapper({ children, ...rest }: ItemWrapperProps) {
  return (
    <Flex
      alignItems="center"
      cursor="pointer"
      p="s200"
      borderBottom="solid 1px"
      borderColor="grey.200"
      data-testid="menuItem"
      {...rest}
    >
      {children}
    </Flex>
  );
}

export function Menu({ items, badgeNo, userName, ...rest }: MenuProps) {
  return (
    <Box {...rest} bg="white" boxShadow="0px 4px 10px rgba(0, 0, 0, 0.1)" zIndex="docked">
      {isNotNullNorUndefined(badgeNo) && (
        <ItemWrapper>
          {isNotNullNorUndefined(userName) && <Avatar userName={userName} mr="s200" />}
          <Box>
            <BodyM>{userName}</BodyM>
            <BodyM color="grey.500">{badgeNo}</BodyM>
          </Box>
        </ItemWrapper>
      )}
      {items.map(
        ({ title, icon, onClick, rightIcon, subtitle = null, showIconNotification = false }) => (
          <ItemWrapper key={title.toString()} onClick={onClick}>
            <Box position="relative" mr="s100">
              {icon}
              {showIconNotification && (
                <NotificationPill position="absolute" top="0px" right="-2px" />
              )}
            </Box>
            <Flex direction="column">
              {typeof title === "string" ? <BodyM>{title}</BodyM> : title}
              {subtitle}
            </Flex>
            {isNotNullNorUndefined(rightIcon) && (
              <Flex flex={1} justifyContent="flex-end">
                {rightIcon}
              </Flex>
            )}
          </ItemWrapper>
        ),
      )}
    </Box>
  );
}
