import { ButtonProps } from "@chakra-ui/react";
import { FormattedMessage } from "react-intl";

import { usePickingService } from "flows/Picking/hooks/usePickingService";
import { Button } from "ui/Button/Button";

type GoToAssignShelvesButtonProps = {
  isButtonDisabled?: boolean;
  onValidateContinue?: () => boolean;
} & ButtonProps;

export function GoToAssignShelvesButton({
  isButtonDisabled = false,
  onValidateContinue = () => true,
  ...rest
}: GoToAssignShelvesButtonProps) {
  const { send } = usePickingService();

  return (
    <Button
      isDisabled={isButtonDisabled}
      onClick={() => {
        if (onValidateContinue()) {
          send({ type: "GO_TO_ASSIGN_SHELVES" });
        }
      }}
      size="lg"
      width="full"
      data-testid="redirect-to-assign-shelves-button"
      {...rest}
    >
      <FormattedMessage id="components.picking.go-to-assign-shelves.button-label-assign-shelf" />
    </Button>
  );
}
