import { useCallback } from "react";

import { useApolloClient } from "@apollo/client";

import {
  GetProductsByShelfDocument,
  GetProductsByShelfQuery,
  GetProductsByShelfQueryVariables,
  SearchProductsByTextDocument,
  SearchProductsByTextQuery,
  SearchProductsByTextQueryVariables,
} from "flows/Inventory/shared/queries/product/product.generated";

export const useRestockingProductSearchQueries = () => {
  const apollo = useApolloClient();

  const getProductsByShelfQuery = useCallback(
    (shelfNumber: string) => {
      return apollo.query<GetProductsByShelfQuery, GetProductsByShelfQueryVariables>({
        query: GetProductsByShelfDocument,
        variables: {
          input: { shelfNumber },
        },
        fetchPolicy: "network-only",
      });
    },
    [apollo],
  );

  const searchProductsByTextQuery = useCallback(
    (query: string) => {
      return apollo.query<SearchProductsByTextQuery, SearchProductsByTextQueryVariables>({
        query: SearchProductsByTextDocument,
        variables: {
          searchProductsByTextInput: { query },
        },
        fetchPolicy: "network-only",
      });
    },
    [apollo],
  );

  return { getProductsByShelfQuery, searchProductsByTextQuery };
};
