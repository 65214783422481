import React, { ReactElement, cloneElement } from "react";

import { Button, Flex, FlexProps, IconButton, IconProps } from "@chakra-ui/react";

import { CloseIcon } from "ui/Icons/Icons";
import { BodyM, BodyS, TitleM } from "ui/Typography/Typography";
import { isNotNullNorUndefined } from "utils/tsHelpers";

export const BANNER_HEIGHT = 72;

type ColorPalette = {
  bg: string;
  color: string;
};

const colorPalette: Record<string, ColorPalette> = {
  pink: {
    bg: "pinkFlink.100",
    color: "pinkFlink.500",
  },
  purple: {
    bg: "purple.100",
    color: "purple.500",
  },
};

type BannerProps = {
  title?: string;
  subTitle?: string | React.ReactNode;
  subTitleSize?: "s" | "m";
  buttonText?: string;
  onClickButton?: () => void;
  description?: string | React.ReactNode;
  descriptionSize?: "s" | "m";
  colorVariant?: "pink" | "purple";
  icon?: ReactElement;
  iconProps?: Pick<IconProps, "color" | "boxSize">;
  onClose?: (event: React.MouseEvent) => void;
  onClickBanner?: () => void;
  bannerHeight?: number;
  dataTestId?: string;
} & Omit<FlexProps, "title">;

export function Banner({
  title,
  subTitle,
  subTitleSize = "m",
  buttonText,
  onClickButton,
  description,
  descriptionSize = "m",
  colorVariant = "pink",
  icon,
  iconProps = {},
  onClose,
  onClickBanner,
  dataTestId,
  ...rest
}: BannerProps) {
  const { bg, color } = colorPalette[colorVariant];

  return (
    <Flex
      alignItems="center"
      w="100%"
      bg={bg}
      paddingY="s150"
      paddingX="s200"
      boxShadow="0px 4px 10px rgba(0, 0, 0, 0.1);"
      data-testid={dataTestId}
      onClick={onClickBanner}
      {...rest}
    >
      {icon && cloneElement(icon, { color, boxSize: "32px", ...iconProps })}
      <Flex direction="column" flex={1} alignItems="flex-start" marginLeft="s200">
        {isNotNullNorUndefined(title) && <TitleM>{title}</TitleM>}
        {isNotNullNorUndefined(subTitle) &&
          React.createElement(
            subTitleSize === "s" ? BodyS : BodyM,
            { color: "grey.800", lineHeight: "1.5rem" },
            subTitle,
          )}
        {isNotNullNorUndefined(buttonText) && (
          <Button
            textDecoration="underline"
            variant="link"
            size="sm"
            color={color}
            onClick={onClickButton}
            data-testid="banner-button"
          >
            {buttonText}
          </Button>
        )}
        {isNotNullNorUndefined(description) &&
          React.createElement(
            descriptionSize === "s" ? BodyS : BodyM,
            { color: "grey.600" },
            description,
          )}
      </Flex>
      {isNotNullNorUndefined(onClose) && (
        <IconButton
          variant="ghost"
          aria-label="Close"
          icon={<CloseIcon boxSize="1.2rem" />}
          onClick={onClose}
          size="sm"
          alignSelf="center"
          data-testid="close-banner"
        />
      )}
    </Flex>
  );
}
