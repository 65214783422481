import { useCallback, useEffect, useRef, useState } from "react";

import { useIntl } from "react-intl";

import { ProductSearchBar } from "ui/ProductSearchBar/ProductSearchBar";
import { debounce } from "utils/debounce";

const SEARCH_DEBOUNCE_IN_MS = 300;
const MIN_QUERY_STRING_LENGTH = 3;

export function RestockingTextSearch({
  initialInputValue,
  onClickCancel,
  onQuery,
}: {
  initialInputValue: string;
  onClickCancel: () => void;
  onQuery: (value: string | null) => void;
}) {
  const intl = useIntl();

  const [inputValue, setInputValue] = useState(initialInputValue);
  const searchInput = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (searchInput.current) {
      searchInput.current.focus();
    }
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const querySearchWithDebounce = useCallback(
    debounce((query: string | null) => {
      onQuery(query);
    }, SEARCH_DEBOUNCE_IN_MS),
    [onQuery],
  );

  const onInputValueChange = useCallback(
    (value: string) => {
      setInputValue(value);
      querySearchWithDebounce(value.length >= MIN_QUERY_STRING_LENGTH ? value : null);
    },
    [setInputValue, querySearchWithDebounce],
  );

  return (
    <ProductSearchBar
      showUnitsToggle={false}
      inputValue={inputValue}
      onInputValueChange={onInputValueChange}
      showCancelButton
      onClickCancel={onClickCancel}
      placeholder={intl.formatMessage({
        id: "flows.inventory.restocking-list.product-search.placeholder",
      })}
      cancelButtonPlaceholder={intl.formatMessage({
        id: "flows.inventory.restocking-list.product-search.cancel",
      })}
      onFocusSearchBar={() => {}}
      ref={searchInput}
      w="100%"
      p="s200"
    />
  );
}
