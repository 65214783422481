import { Flex, FlexProps } from "@chakra-ui/react";

import { Status } from "ui/Status/Status";

export type InboundFruitOrVeggyChipsProps = {
  bioLabel: string;
  nonBioLabel: string;
  isBio: boolean;
  countryOfOriginName: string | null;
} & FlexProps;

export function InboundFruitOrVeggyChips({
  bioLabel,
  nonBioLabel,
  countryOfOriginName,
  isBio,
  ...rest
}: InboundFruitOrVeggyChipsProps) {
  return (
    <Flex {...rest}>
      <Status
        size="small"
        label={isBio ? bioLabel : nonBioLabel}
        bg="veggie.100"
        mr="s50"
        whiteSpace="nowrap"
      />
      {countryOfOriginName && (
        <Status size="small" label={countryOfOriginName} bg="grey.100" whiteSpace="nowrap" />
      )}
    </Flex>
  );
}
