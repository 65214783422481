import { useCallback, useEffect, useState } from "react";

import { BackgroundProps, Box, ColorProps, Flex } from "@chakra-ui/react";
import { useSelector } from "@xstate/react";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router";

import { InventoryChangeReason } from "__graphql__/types";
import {
  useSelectInboundUnitListItemDataProps,
  useSelectInboundUnitListItemStockUpdateState,
} from "flows/Inbound/hooks/useInboundMachineSelectors";
import { useInboundService } from "flows/Inbound/hooks/useInboundService";
import { useInboundUIStore } from "flows/Inbound/stores/useInboundUIStore";
import { IncreaseDecrease } from "shared/components/IncreaseDecrease";
import { Page } from "shared/components/Page";
import { BottomTabBarContainer } from "ui/BottomTabBarContainer";
import { Button } from "ui/Button/Button";
import { NavigationHeaderShelfNumber } from "ui/NavigationHeaderShelfNumber/NavigationHeaderShelfNumber";
import { ProductInformation } from "ui/ProductInformation/ProductInformation";
import { HeaderM, TitleS } from "ui/Typography/Typography";
import { isNullOrUndefined } from "utils/tsHelpers";

type OutboundCounterProps = {
  numberColor?: ColorProps["color"];
  bgColor?: BackgroundProps["bg"];
  value: number;
  title: string;
  countDataTestId?: string;
};

function OutboundCounter({
  numberColor,
  bgColor,
  value,
  title,
  countDataTestId,
}: OutboundCounterProps) {
  return (
    <Flex direction="column" alignItems="center">
      <TitleS color="grey.600">{title}</TitleS>
      <HeaderM
        data-testid={countDataTestId}
        paddingX="s100"
        borderRadius="8px"
        bg={bgColor}
        color={numberColor}
        mt="s50"
      >
        {value}
      </HeaderM>
    </Flex>
  );
}

export function InboundOutboundPage() {
  const intl = useIntl();
  const navigate = useNavigate();

  const inboundService = useInboundService();
  const sku = useSelector(inboundService, (state) => {
    return state.context.inboundUnitSkuToOutbound;
  });

  const inboundUnitData = useSelectInboundUnitListItemDataProps(sku || "");
  const inboundUnitStockUpdateState = useSelectInboundUnitListItemStockUpdateState(sku || "");

  const { setInboundUIState } = useInboundUIStore();

  const isInvalidParams = isNullOrUndefined(sku) || isNullOrUndefined(inboundUnitData);

  const initialCountForReason = useCallback(
    (reason: InventoryChangeReason) => {
      return (
        inboundUnitStockUpdateState?.stockUpdatePlan.find((plan) => plan.reason === reason)
          ?.quantityDelta || 0
      );
    },
    [inboundUnitStockUpdateState],
  );
  const [damagedCount, setDamagedCount] = useState(
    () => -initialCountForReason(InventoryChangeReason.outbound_delivery_product_damaged),
  );
  const [expiredCount, setExpiredCount] = useState(
    () => -initialCountForReason(InventoryChangeReason.outbound_delivery_product_expired),
  );
  const inboundCount = initialCountForReason(InventoryChangeReason.inbound_goods_received);

  const returnToInboundHomePage = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  useEffect(() => {
    if (isInvalidParams) {
      returnToInboundHomePage();
    }
  }, [isInvalidParams, returnToInboundHomePage]);

  const confirmOutboundChoices = useCallback(() => {
    const quantitiesByReason = [
      {
        reason: InventoryChangeReason.outbound_delivery_product_damaged,
        quantity: -damagedCount,
      },
      {
        reason: InventoryChangeReason.outbound_delivery_product_expired,
        quantity: -expiredCount,
      },
    ];

    const outboundTotal = quantitiesByReason.reduce((sum, { quantity }) => {
      return sum + Math.abs(quantity);
    }, 0);

    const shouldShowPartiallyOutboundedAnimation =
      quantitiesByReason.every(({ reason }) => initialCountForReason(reason) === 0) &&
      quantitiesByReason.some(({ quantity }) => quantity < 0) &&
      outboundTotal < initialCountForReason(InventoryChangeReason.inbound_goods_received);

    setInboundUIState({
      showPartialOutboundAnimationForSKU: shouldShowPartiallyOutboundedAnimation ? sku : null,
    });

    inboundService.send({
      type: "SET_INBOUND_UNIT_QUANTITIES_BY_REASON",
      sku: sku || "",
      quantitiesByReason,
    });
  }, [inboundService, sku, damagedCount, expiredCount, initialCountForReason, setInboundUIState]);

  if (isInvalidParams) {
    return null;
  }

  const outboundedCount = expiredCount + damagedCount;
  const isIncreaseBtnDisabled = outboundedCount >= inboundCount;

  const { shelfLetter, shelfNumber, productImageUrl, productName, productSku } = inboundUnitData;

  return (
    <Page h="100%" justifyContent="flex-start" isFull isBgGrey>
      <Flex direction="column" w="100%" flex={1}>
        <NavigationHeaderShelfNumber
          title={intl.formatMessage({ id: "pages.inbound.inbound-outbound.title" })}
          shelfLetter={shelfLetter}
          shelfNumber={shelfNumber}
          onClickGoBack={returnToInboundHomePage}
        />
        <Box bg="white" w="full">
          <ProductInformation
            name={productName ?? ""}
            imageUrl={productImageUrl}
            sku={productSku}
            variantSize="s"
            p="s200"
            borderRadius="0"
          />
        </Box>
        <Flex direction="column" w="100%" p="s200">
          <Flex
            alignItems="center"
            justifyContent="space-evenly"
            borderRadius="8px"
            bg="white"
            w="100%"
            paddingY="s200"
          >
            <OutboundCounter
              countDataTestId="counted-value"
              title={intl.formatMessage({ id: "pages.inbound.inbound-outbound.counted-title" })}
              value={inboundCount}
            />
            <OutboundCounter
              countDataTestId="outbounded-value"
              title={intl.formatMessage({ id: "pages.inbound.inbound-outbound.outbounded-title" })}
              value={outboundedCount * -1}
              numberColor="purple.500"
            />
            <OutboundCounter
              countDataTestId="new-stock-value"
              title={intl.formatMessage({ id: "pages.inbound.inbound-outbound.new-stock-title" })}
              value={inboundCount - outboundedCount}
              numberColor="pinkFlink.500"
              bgColor="pinkFlink.100"
            />
          </Flex>
        </Flex>
        <Flex direction="column" w="100%" paddingX="s200">
          <Box bg="white" borderRadius="8px" p="s200">
            <IncreaseDecrease
              dataTestId="damaged"
              changeHandler={setDamagedCount}
              labelMessageId="pages.inbound.inbound-outbound.damaged-adjuster-label"
              count={damagedCount}
              isIncreaseBtnDisabled={isIncreaseBtnDisabled}
            />
          </Box>
          <Box bg="white" borderRadius="8px" p="s200">
            <IncreaseDecrease
              dataTestId="expired"
              changeHandler={setExpiredCount}
              labelMessageId="pages.inbound.inbound-outbound.expired-adjuster-label"
              count={expiredCount}
              isIncreaseBtnDisabled={isIncreaseBtnDisabled}
            />
          </Box>
        </Flex>
      </Flex>
      <BottomTabBarContainer
        w="100%"
        px="s200"
        bgColor="white"
        borderTopWidth="0.5px"
        borderTopColor="grey.200"
        align="center"
      >
        <Button w="100%" h="48px" onClick={confirmOutboundChoices} data-testid="outbound-confirm">
          <FormattedMessage id="pages.inbound.inbound-outbound.confirm-button" />
        </Button>
      </BottomTabBarContainer>
    </Page>
  );
}
