import { getShelfDetail } from "utils/item";

import { InventoryProduct } from "./types";

export type SerializeInventoryProductFromSearchResultInput = {
  sku: string;
  imageUrl?: string | null;
  name: string;
  inventoryEntry: {
    shelfNumber?: string | null;
    stock: {
      shelf: number;
    };
  };
  countryOfOrigin: {
    code?: string | null;
  };
  bio?: boolean | null;
  minDaysToBestBeforeDate?: number | null;
  bbd?: string | null;
  numberOfShelfFacings?: number | null;
  isShelvedInHandlingUnits?: boolean | null;
};

export function serializeInventoryProductFromSearchResult(
  product: SerializeInventoryProductFromSearchResultInput,
): InventoryProduct {
  const { shelfNumber } = product.inventoryEntry;
  const shelfDetail = shelfNumber ? getShelfDetail(shelfNumber) : null;
  const closestBbd = product.bbd ? new Date(product.bbd) : null;

  return {
    productImageUrl: product.imageUrl ?? null,
    productName: product.name,
    stockStatus: "unchanged",
    productSku: product.sku,
    stock: product.inventoryEntry.stock.shelf,
    initialStock: product.inventoryEntry.stock.shelf,
    shelf: product.inventoryEntry.shelfNumber ?? null,
    shelfLetter: shelfDetail?.shelfLetter ?? null,
    shelfNumber: shelfDetail?.shelfNumber ?? null,
    countryOfOriginCode: product.countryOfOrigin.code?.toUpperCase() ?? null,
    isProductBio: product.bio ?? null,
    minDaysToBestBeforeDate: product.minDaysToBestBeforeDate ?? null,
    closestBbd,
    numberOfShelfFacings: product.numberOfShelfFacings ?? 0,
    isShelvedInHandlingUnits: !!product.isShelvedInHandlingUnits,
  };
}
