import { State } from "xstate";

import { StockCorrectionMachineContext } from "./types";

export const selectSelectedInventoryProductContext = (
  state: State<StockCorrectionMachineContext>,
) =>
  state.context.inventoryProductsList.find(
    (inventoryProduct) => inventoryProduct.productSku === state.context.selectedInventoryProductSku,
  );
