import { useIntl } from "react-intl";

import { YesNoModal, YesNoModalProps } from "shared/components/YesNoModal";

export type DeleteFinalItemConfirmationModalProps = Pick<
  YesNoModalProps,
  "isOpen" | "onClickYes" | "onClickNo"
>;

export function DeleteFinalItemConfirmationModal({
  isOpen,
  onClickYes,
  onClickNo,
}: DeleteFinalItemConfirmationModalProps) {
  const intl = useIntl();
  return (
    <YesNoModal
      isOpen={isOpen}
      onClickYes={onClickYes}
      onClickNo={onClickNo}
      title={intl.formatMessage({
        id: "components.delete-final-item-confirmation-modal.title",
      })}
      body={intl.formatMessage({
        id: "components.delete-final-item-confirmation-modal.body",
      })}
      yesLabel={intl.formatMessage({
        id: "generic.actions.delete",
      })}
      noLabel={intl.formatMessage({
        id: "generic.actions.cancel",
      })}
      isGrey
    />
  );
}
