import { useCallback, useEffect, useState } from "react";

import { useSelector } from "@xstate/react";

import { openOngoingActivityModal, useLayoutStore } from "core/stores/useLayoutStore";
import { useSelectPreDroppingListInboundUnitsSkusSortedByAddedDate } from "flows/Inbound/hooks/useInboundMachineSelectors";
import { useInboundService } from "flows/Inbound/hooks/useInboundService";
import { useInboundUIStore } from "flows/Inbound/stores/useInboundUIStore";
import { useTimedActivityStore } from "shared/stores/useTimedActivityStore";
import { BOTTOM_TAB_BAR_HEIGHT_NUMBER } from "ui/BottomTabBarContainer";
import { isNotNullNorUndefined } from "utils/tsHelpers";

import { EndDroppingProcessButton } from "./EndDroppingProcessButton";

export function ListVerificationButton() {
  const scrollContainerRef = useLayoutStore((state) => state.scrollContainerRef);
  const isEditingQuantity = useInboundUIStore((state) => state.isEditingQuantity);
  const timedActivityInstance = useTimedActivityStore((state) => state.timedActivityInstance);

  const inboundService = useInboundService();
  const inboundUnitsSortedByAddedDate = useSelectPreDroppingListInboundUnitsSkusSortedByAddedDate();

  const [isScrollingPage, setIsScrollingPage] = useState(false);

  useEffect(() => {
    let isScrolling = false;
    let isScrollingTimeout: NodeJS.Timeout;

    function handleScroll() {
      clearTimeout(isScrollingTimeout);
      if (!isScrolling) {
        isScrolling = true;
        setIsScrollingPage(true);
      }
      isScrollingTimeout = setTimeout(() => {
        // when scroll is finished
        setIsScrollingPage(false);
        isScrolling = false;
      }, 100);
    }

    const scrollContainer = scrollContainerRef.current;
    scrollContainer?.addEventListener("scroll", handleScroll);

    return () => {
      scrollContainer?.removeEventListener("scroll", handleScroll);
    };
  }, [setIsScrollingPage, scrollContainerRef]);

  const hasInboundUnitsInPreDroppingList = inboundUnitsSortedByAddedDate.length > 0;

  const shouldShowListVerificationButton =
    hasInboundUnitsInPreDroppingList && !isScrollingPage && !isEditingQuantity;

  const onClickStartListVerification = useCallback(() => {
    if (isNotNullNorUndefined(timedActivityInstance)) {
      openOngoingActivityModal();
      return;
    }
    inboundService.send({ type: "START_LIST_VERIFICATION" });
  }, [inboundService, timedActivityInstance]);

  const isLoadingDroppingList = useSelector(inboundService, (state) => {
    return state.matches("inbounding.loadDroppingList");
  });

  return (
    <EndDroppingProcessButton
      shouldShowButton={shouldShowListVerificationButton}
      onClickButton={onClickStartListVerification}
      labelMessageId="pages.inbound.inbound-index.verify-list-button-label"
      bottom={BOTTOM_TAB_BAR_HEIGHT_NUMBER}
      isLoading={isLoadingDroppingList}
    />
  );
}
