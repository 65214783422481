import { useIntl } from "react-intl";

import { YesNoModal } from "shared/components/YesNoModal";
import { WarningThreeIcon } from "ui/Icons/Icons";

export type DespatchAdviceNotFoundModalProps = {
  onClickContinue: () => void;
  onClickTryNew: () => void;
  isOpen: boolean;
};

export function DespatchAdviceNotFoundModal({
  onClickTryNew,
  onClickContinue,
  isOpen,
}: DespatchAdviceNotFoundModalProps) {
  const intl = useIntl();

  return (
    <YesNoModal
      isOpen={isOpen}
      onClickYes={onClickTryNew}
      onClickNo={onClickContinue}
      title={intl.formatMessage({
        id: "components.inbound.desadv-ask-modal.title",
      })}
      icon={<WarningThreeIcon />}
      body={intl.formatMessage({
        id: "components.inbound.desadv-ask-modal.body",
      })}
      yesLabel={intl.formatMessage({
        id: "components.inbound.desadv-ask-modal.button.try-new",
      })}
      noLabel={intl.formatMessage({
        id: "components.inbound.desadv-ask-modal.button.continue",
      })}
    />
  );
}
