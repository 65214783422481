import { extendTheme } from "@chakra-ui/react";

import { Alert } from "./components/alert";
import { Button } from "./components/button";
import { Input } from "./components/input";
import { NumberInput } from "./components/number-input";
import { breakpoints } from "./foundations/breakpoints";
import { colors } from "./foundations/colors";
import { fonts } from "./foundations/fonts";
import { fontSizes } from "./foundations/fontSizes";
import { radii } from "./foundations/radius";
import { shadows } from "./foundations/shadows";
import { sizes } from "./foundations/sizes";
import { space } from "./foundations/space";
import { typography } from "./foundations/typography";

export const theme = extendTheme({
  fonts,
  colors,
  shadows,
  fontSizes,
  space,
  sizes,
  radii,
  breakpoints,
  typography,
  components: {
    Button,
    Input,
    NumberInput,
    Alert,
  },
});
