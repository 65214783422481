import { useCallback } from "react";

import { Box, Flex } from "@chakra-ui/react";
import { useSelector } from "@xstate/react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router";

import { Page } from "shared/components/Page";
import { NavigationHeader } from "ui/NavigationHeader/NavigationHeader";
import { TitleM } from "ui/Typography/Typography";

import { InventoryProduct } from "../../../shared/models/inventoryProduct/types";
import { ProductsList } from "../components/ProductsList";
import { useBbdCorrectionService } from "../hooks/useBbdCorrectionService";

export function SelectBbdProductPage() {
  const intl = useIntl();
  const navigate = useNavigate();
  const bbdCorrectionService = useBbdCorrectionService();
  const inventoryProductsList = useSelector(
    bbdCorrectionService,
    (state) => state.context.inventoryProductsList,
  );

  const onClickProduct = useCallback(
    (product: InventoryProduct) => {
      bbdCorrectionService.send({
        type: "SELECT_INVENTORY_PRODUCT",
        inventoryProductSku: product.productSku,
        inventoryProductShelf: product.shelf,
      });
    },
    [bbdCorrectionService],
  );

  return (
    <Page isFull isBgGrey h="100%">
      <NavigationHeader
        title={intl.formatMessage({
          id: "components.inventory.bbd-correction.select-product.header-title",
          defaultMessage: "BBD Date correction",
        })}
        onClickGoBack={() => {
          navigate(-1);
        }}
      />
      <Box flex={1} flexDirection="column" w="100%" overflowY="scroll">
        <Flex alignItems="center" justifyContent="space-between" px="s200" pt="s300" pb="s200">
          <TitleM>
            {intl.formatMessage(
              {
                id: "pages.inventory.bbd-correction.results-found",
              },
              { quantity: inventoryProductsList.length },
            )}
          </TitleM>
          <TitleM
            color="grey.600"
            onClick={() => bbdCorrectionService.send({ type: "CLEAR_LIST" })}
          >
            {intl.formatMessage({
              id: "pages.inventory.bbd-correction.clear",
            })}
          </TitleM>
        </Flex>
        <ProductsList
          products={inventoryProductsList}
          variantSize="m"
          onClickProduct={onClickProduct}
        />
      </Box>
    </Page>
  );
}
