import { Flex } from "@chakra-ui/react";

import { TitleXS } from "ui/Typography/Typography";

type NumberTextPillProps = {
  number: number;
  text: string;
  color: string;
  testIdNumber?: string;
  testIdText?: string;
};

export function NumberTextPill({
  number,
  text,
  color,
  testIdNumber,
  testIdText,
}: NumberTextPillProps) {
  return (
    <Flex
      bg="white"
      borderRadius="full"
      borderColor={color}
      borderWidth="1px"
      alignItems="center"
      gap="s50"
      w="fit-content"
      px="s100"
      py="s25"
    >
      <TitleXS color={color} data-testid={testIdNumber}>
        {number}
      </TitleXS>
      <TitleXS data-testid={testIdText}>{text}</TitleXS>
    </Flex>
  );
}
