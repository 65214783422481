import { useCallback, useState } from "react";

import { Box } from "@chakra-ui/react";
import { shallowEqual, useSelector } from "@xstate/react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router";

import { PageName } from "analytics/events";
import { TaskCheckHeader } from "flows/Inventory/shared/components/TaskCheckHeader";
import { useGetProductStockQuery } from "flows/Inventory/shared/queries/inventoryEntry/inventoryEntry.generated";
import { AskForRestockingButton } from "flows/Picking/components/AskForRestockingButton/AskForRestockingButton";
import { IncreaseDecrease } from "shared/components/IncreaseDecrease";
import { Page } from "shared/components/Page";
import { SpinnerModal } from "shared/components/SpinnerModal";
import { useAnalytics } from "shared/hooks/useAnalytics";
import { useCustomToast } from "shared/hooks/useCustomToast";
import { Button } from "ui/Button/Button";
import { VerticalLayout } from "ui/VerticalLayout/VerticalLayout";

import { usePublicRestockingList } from "../../RestockingList/services/restockingService/service";
import { StockChangeOverview } from "../components/StockChangeOverview";
import { useStockCheckService } from "../hooks/useStockCheckService";

const ASK_FOR_RESTOCKING_ERROR_TOAST_ID = "ask_for_restocking_error_toast_for_sku";

export function StockCheckUpdatePage() {
  const intl = useIntl();
  const navigate = useNavigate();
  const { showToastUI } = useCustomToast();
  const { sendSegmentTrackEvent } = useAnalytics();

  const stockCheckService = useStockCheckService();

  const { origin, sku, declaredQuantity, shelfLetter, shelfNumber, checkId } = useSelector(
    stockCheckService,
    (state) => state.context,
    shallowEqual,
  );
  const isUpdating = useSelector(stockCheckService, (state) => state.matches("performCheckUpdate"));
  const { addItemToPublicRestockingList } = usePublicRestockingList();

  const [isCTADisabled, setIsCTADisabled] = useState<boolean>(false);
  const [isIncreaseBtnDisabled, setIsIncreaseBtnDisabled] = useState<boolean>(false);
  const [totalNewStock, setTotalNewStock] = useState<number>(declaredQuantity);
  const [quantityExpired, setQuantityExpired] = useState<number>(0);
  const [quantityDamaged, setQuantityDamaged] = useState<number>(0);
  const [quantitytooGoodToGo, setQuantityTooGoodToGo] = useState<number>(0);
  const [isRestockingButtonDisabled, setIsRestockingButtonDisabled] = useState<boolean>(false);
  const { data, loading } = useGetProductStockQuery({
    fetchPolicy: "network-only",
    variables: { sku: sku!, isAlwaysInbound: true },
    skip: !sku,
  });

  const totalStock = data?.getProduct.inventoryEntry.stock.shelf ?? null;

  const newStockHandler = useCallback(
    (newStockCount: number) => {
      setTotalNewStock(newStockCount);
      setIsCTADisabled(newStockCount < 0);
      setIsIncreaseBtnDisabled(newStockCount === 0);
    },
    [setTotalNewStock],
  );

  const declareStock = useCallback(() => {
    stockCheckService.send({
      type: "DECLARE_STOCK_DETAILS",
      expectedQuantity: totalStock ?? 0,
      remainingStock: totalNewStock,
      damagedQuantity: quantityDamaged,
      expiredQuantity: quantityExpired,
      tooGoodToGoQuantity: quantitytooGoodToGo,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quantityDamaged, quantitytooGoodToGo, totalNewStock, quantityExpired, totalStock]);

  const handleAskForRestocking = async () => {
    if (sku) {
      try {
        await addItemToPublicRestockingList(sku);
        setIsRestockingButtonDisabled(true);
      } catch (e: any) {
        showToastUI({
          id: `${ASK_FOR_RESTOCKING_ERROR_TOAST_ID}_${sku}`,
          title: "components.picking.restocking.ask-for-restocking-error-title",
        });
      }
      sendSegmentTrackEvent("click", {
        screen_name: PageName.STOCK_CHECK,
        component_name: "ask_for_restock",
        component_value: sku,
        component_content: checkId ?? "",
      });
    }
  };

  return (
    <Page isFull isCentered isBgGrey>
      <SpinnerModal isOpen={loading} />
      <TaskCheckHeader
        headerMessageId="pages.stock-check-header.stock-check"
        onBack={() => navigate(-1)}
        origin={origin}
        shelfLetter={shelfLetter}
        shelfNumber={shelfNumber}
      />
      <VerticalLayout flexGrow={1} alignItems="space-between" overflowY="scroll">
        <VerticalLayout p="s200" flexGrow={1} gap="s100">
          <StockChangeOverview
            counted={declaredQuantity || 0}
            expectedStock={totalStock}
            expiredCount={quantityExpired || 0}
            damagedCount={quantityDamaged || 0}
            tooGoodToGoCount={quantitytooGoodToGo || 0}
            newStockCallback={newStockHandler}
          />
          <VerticalLayout bg="white" pt="s50" px="s200" pb="s150" borderRadius="sm" gap="s50">
            <IncreaseDecrease
              labelMessageId="components.increasedecrease.how_many_are_expired"
              changeHandler={setQuantityExpired}
              count={quantityExpired || 0}
              isIncreaseBtnDisabled={isIncreaseBtnDisabled}
            />
            <IncreaseDecrease
              labelMessageId="components.increasedecrease.how_many_are_damaged"
              changeHandler={setQuantityDamaged}
              count={quantityDamaged || 0}
              isIncreaseBtnDisabled={isIncreaseBtnDisabled}
            />
            <IncreaseDecrease
              labelMessageId="components.increasedecrease.too_good_to_go"
              changeHandler={setQuantityTooGoodToGo}
              count={quantitytooGoodToGo || 0}
              isIncreaseBtnDisabled={isIncreaseBtnDisabled}
            />
          </VerticalLayout>
          <Box paddingTop="s150">
            <AskForRestockingButton
              height="48px"
              width="full"
              disabled={isRestockingButtonDisabled}
              onAskForRestocking={handleAskForRestocking}
            />
          </Box>
        </VerticalLayout>

        <Box bg="white" p="s200" borderTop="solid 1px" borderTopColor="grey.200">
          <Button
            size="lg"
            width="100%"
            onClick={declareStock}
            disabled={isCTADisabled || isUpdating}
            isLoading={isUpdating}
            data-testid="submit-update-button"
          >
            {intl.formatMessage({ id: "components.stock-change.complete-task-button" })}
          </Button>
        </Box>
      </VerticalLayout>
    </Page>
  );
}
