import { Box, Circle, Flex, Image } from "@chakra-ui/react";
import { useSelector } from "@xstate/react";
import { FormattedMessage } from "react-intl";

import { OrderSummary } from "flows/Picking/components/OrderSummary";
import ClickAndCollectLogo from "images/logos/click-and-collect.png";
import ParkDeliveryLogo from "images/random/park-delivery.svg";
import { Page } from "shared/components/Page";
import { Button } from "ui/Button/Button";
import { DetailM, HeaderS, TitleM } from "ui/Typography/Typography";
import { transformChunks } from "utils/ui";

import { useSelectOrderDetails } from "../../hooks/usePickingMachineSelectors";
import { usePickingService } from "../../hooks/usePickingService";
import { DeliveryTag } from "../../models/order/types";

export function PickingDonePage() {
  const pickingService = usePickingService();
  const { itemsPickedCount } = useSelectOrderDetails();
  const { order, containersIds, shelvesIds } = useSelector(pickingService, (state) => ({
    order: state.context.order,
    containersIds: state.context.containersIds,
    shelvesIds: state.context.shelvesIds,
  }));

  return (
    <Page isFull>
      <Flex
        width="100%"
        height="60px"
        backgroundColor="veggie.500"
        justifyContent="center"
        alignItems="center"
      >
        <HeaderS color="white" data-testid="label-picking-result">
          <FormattedMessage id="components.picking.end-picking.title" />
        </HeaderS>
      </Flex>
      <Box width="100%" p="s200">
        <OrderSummary
          itemsPickedCount={itemsPickedCount}
          containerIds={containersIds}
          shelfIds={shelvesIds}
          order={order}
          state="END"
        />
        {order?.isClickAndCollect && (
          <Flex
            width="100%"
            backgroundColor="sunny.200"
            justifyContent="flex-start"
            alignItems="center"
            borderRadius="6px"
            paddingX="s150"
            paddingY="s200"
            mt="s200"
          >
            <Image src={ClickAndCollectLogo} h="50px" w="47px" />
            <Box ml="s150">
              <TitleM>
                <FormattedMessage id="components.picking.end-picking.click-and-collect" />
              </TitleM>
              <DetailM>
                <FormattedMessage id="components.picking.end-picking.click-and-collect-text" />
              </DetailM>
            </Box>
          </Flex>
        )}
        {order?.deliveryTag === DeliveryTag.OUTDOOR && (
          <Flex
            width="100%"
            backgroundColor="veggie.100"
            justifyContent="flex-start"
            alignItems="center"
            borderRadius="6px"
            paddingX="s150"
            paddingY="s200"
            mt="s200"
            test-dataid="outdoor-delivery-order-label"
          >
            <Circle bgColor="white" size="40px">
              <Image src={ParkDeliveryLogo} h="50px" w="47px" />
            </Circle>
            <Box ml="s150">
              <TitleM test-dataid="outdoor-delivery-order-header">
                <FormattedMessage id="components.picking.end-picking.park-delivery" />
              </TitleM>
              <DetailM test-dataid="outdoor-delivery-order-description">
                <FormattedMessage
                  id="components.picking.end-picking.park-delivery-text"
                  values={transformChunks({ strong: { color: "pinkFlink.500" } })}
                  test-dataid="outdoor-delivery-order-description"
                />
              </DetailM>
            </Box>
          </Flex>
        )}
        <Button
          mt="s200"
          backgroundColor="veggie.500"
          onClick={() => pickingService.send({ type: "FINISH_PICKING" })}
          width="full"
          size="lg"
        >
          <FormattedMessage id="components.picking.end-picking.next-order" />
        </Button>
      </Box>
    </Page>
  );
}
