import { useCallback } from "react";

import { useAppPWAStateStore } from "core/stores/useAppPWAStateStore";
import { useEmployeeStore } from "core/stores/useEmployeeStore";
import { useLayoutStore } from "core/stores/useLayoutStore";

const closeUpdateAppModal = () => useLayoutStore.setState({ showUpdateAppModal: false });

export function useAppUpdate() {
  const updateServiceWorker = useAppPWAStateStore((state) => state.updateServiceWorker);
  const setAppLayout = useLayoutStore((state) => state.setAppLayout);
  const { setEmployee, isIdentified } = useEmployeeStore((state) => ({
    setEmployee: state.setEmployee,
    isIdentified: state.isIdentified,
  }));

  return useCallback(() => {
    if (!updateServiceWorker) {
      closeUpdateAppModal();
      return;
    }
    closeUpdateAppModal();
    setAppLayout({ isUpdating: true });
    if (isIdentified) {
      setEmployee({ persistDuringUpdatePageRefresh: true });
    }
    updateServiceWorker();
  }, [updateServiceWorker, setAppLayout, isIdentified, setEmployee]);
}
