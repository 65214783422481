import * as Types from "../../../../../../__graphql__/types.js";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type ResolveEanToSkusQueryVariables = Types.Exact<{
  input: Types.SearchUnitsByEanInput;
}>;

export type ResolveEanToSkusQuery = {
  __typename?: "Query";
  searchUnitsByEan: {
    __typename?: "SearchUnitsByEanResponse";
    units: Array<{ __typename?: "Unit"; productSku: string }>;
  };
};

export const ResolveEanToSkusDocument = gql`
  query resolveEanToSkus($input: SearchUnitsByEanInput!) {
    searchUnitsByEan(searchUnitsByEanInput: $input) {
      units {
        productSku
      }
    }
  }
`;

/**
 * __useResolveEanToSkusQuery__
 *
 * To run a query within a React component, call `useResolveEanToSkusQuery` and pass it any options that fit your needs.
 * When your component renders, `useResolveEanToSkusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResolveEanToSkusQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResolveEanToSkusQuery(
  baseOptions: Apollo.QueryHookOptions<ResolveEanToSkusQuery, ResolveEanToSkusQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ResolveEanToSkusQuery, ResolveEanToSkusQueryVariables>(
    ResolveEanToSkusDocument,
    options,
  );
}
export function useResolveEanToSkusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ResolveEanToSkusQuery, ResolveEanToSkusQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ResolveEanToSkusQuery, ResolveEanToSkusQueryVariables>(
    ResolveEanToSkusDocument,
    options,
  );
}
export type ResolveEanToSkusQueryHookResult = ReturnType<typeof useResolveEanToSkusQuery>;
export type ResolveEanToSkusLazyQueryHookResult = ReturnType<typeof useResolveEanToSkusLazyQuery>;
export type ResolveEanToSkusQueryResult = Apollo.QueryResult<
  ResolveEanToSkusQuery,
  ResolveEanToSkusQueryVariables
>;
