import { useCallback, useEffect, useState } from "react";

import { ApolloError } from "@apollo/client";
import { datadogRum } from "@datadog/browser-rum";
import { useNavigate } from "react-router";

import { InventoryChangeReason, ListProductStatus } from "__graphql__/types";
import {
  CollaborativeInboundProgressedAction,
  CollaborativeInboundStage,
  CollaborativeInboundUpdateState,
  PageName,
} from "analytics/events";
import { routes } from "config/routes";
import { useAppLanguageStore } from "core/stores/useAppLanguageStore";
import { countryNameFromCode } from "libs/countryNames";
import { useAnalytics } from "shared/hooks/useAnalytics";
import { useCustomToast } from "shared/hooks/useCustomToast";
import { getShelfDetail } from "utils/item";

import { useInboundEventTracker } from "../hooks/useInboundEventTracker/useInboundEventTracker";
import {
  GetProductsFromClaimedListQuery,
  useClaimInboundingListMutation,
  useDeleteInboundingListProductsMutation,
  useEditProductsInInboundingListMutation,
  useGetProductsFromClaimedListLazyQuery,
  useInboundProductMutation,
  useReleaseInboundingListMutation,
} from "../queries/collaborativeInbound/collaborativeInbound.generated";
import { ClaimedProductsState, TranslatedClaimedProduct } from "./inboundStore/types";
import { useInboundStore } from "./inboundStore/useInboundStore";
import { createInitialStockUpdate } from "./inboundStore/utils";

const CLAIM_SHARED_LIST_ERROR_TOAST_ID = "claim_shared_list_error_toast";
const CLAIM_SHARED_LIST_SUCCESS_TOAST_ID = "claim_shared_list_success_toast";
const CLAIM_INBOUND_PRODUCT_ERROR_TOAST_ID = "claim_inbound_product_error_toast";
const GET_CLAIMED_PRODUCTS_ERROR_TOAST_ID = "get_claimed_products_error_toast_id";
const UNCLAIM_LIST_ERROR_TOAST_ID = "unclaim-list-error-toast";
const DELETE_PRODUCT_ERROR_TOAST_ID = "delete-product-error-toast";
const EDIT_PRODUCT_ERROR_TOAST_ID = "edit-product-error-toast";

export function useSerializedClaimedProduct() {
  const appLanguage = useAppLanguageStore((state) => state.appLanguage);

  const transformProductForDisplay = useCallback(
    (
      product: GetProductsFromClaimedListQuery["getProductsFromClaimedList"]["products"][0],
    ): TranslatedClaimedProduct => {
      const shelf = product.inventoryEntry.shelfNumber ?? null;
      const shelfDetail = shelf ? getShelfDetail(shelf) : null;
      const countryOfOriginCode = product.countryOfOrigin.code?.toUpperCase() ?? null;
      const countryOfOriginName = countryNameFromCode(appLanguage, countryOfOriginCode);
      const handlingUnitSize = product.handlingUnitSize || 1;
      const unitSizeForDisplay =
        handlingUnitSize > 1 ? product.quantity / handlingUnitSize : product.quantity;
      const stockUpdated = product.status === ListProductStatus.list_product_status_inbounded;
      const totalInboundQuantity = stockUpdated ? 0 : product.quantity ?? 0;
      return {
        productId: product.id,
        productName: product.name,
        productSku: product.sku,
        productImageUrl: product.imageUrl ?? null,
        shelf,
        shelfLetter: shelfDetail?.shelfLetter ?? null,
        shelfNumber: shelfDetail?.shelfNumber ?? null,
        numberOfShelfFacings: product.numberOfShelfFacings ?? 0,
        isShelvedInHandlingUnits: !!product.isShelvedInHandlingUnits,
        countryOfOriginName,
        countryOfOriginCode,
        isProductBio: product.bio ?? null,
        unitType: handlingUnitSize > 1 ? "handling" : "single",
        handlingUnitSize,
        unitSizeForDisplay,
        totalInboundQuantity,
        stockOnShelf: product.inventoryEntry.stock.shelf,
        status: product.status,
      };
    },
    [appLanguage],
  );

  return transformProductForDisplay;
}

export function useFetchClaimedProducts(shouldNavigate = true) {
  const navigate = useNavigate();
  const { showToastUI } = useCustomToast();
  const serializeClaimedProduct = useSerializedClaimedProduct();
  const setInboundClaimedProducts = useInboundStore((state) => state.setInboundClaimedProducts);
  const [isFetchingClaimedProducts, setIsFetchingClaimedProducts] = useState(false);

  const handleError = useCallback(
    (error: unknown) => {
      setIsFetchingClaimedProducts(false);
      if (!shouldNavigate) return;
      const errorMessage = error instanceof Error ? error.message : "Unknown error occurred.";
      datadogRum.addError("Error while fetching claimed products", { errorMessage });
      showToastUI({
        id: GET_CLAIMED_PRODUCTS_ERROR_TOAST_ID,
        title: "get_claimed_products_error_toast_id.title",
        description: "get_claimed_products_error_toast.description",
      });
      navigate(routes.inbound.claimedList);
    },
    [navigate, showToastUI, shouldNavigate],
  );

  const [getClaimedProducts] = useGetProductsFromClaimedListLazyQuery({
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      setIsFetchingClaimedProducts(false);
      if (!data || !data.getProductsFromClaimedList.products.length) {
        if (shouldNavigate) {
          navigate(routes.inbound.claimedList);
        }
        return;
      }
      const claimedProductsData =
        data.getProductsFromClaimedList.products.reduce<ClaimedProductsState>(
          (acc, product) => {
            const serializedProduct = serializeClaimedProduct(product);
            const stockUpdated =
              serializedProduct.status === ListProductStatus.list_product_status_inbounded;
            acc.claimedProductsMap[serializedProduct.productSku] = serializedProduct;
            acc.claimedProductsStockUpdates[serializedProduct.productSku] =
              createInitialStockUpdate(serializedProduct.totalInboundQuantity, stockUpdated);
            acc.claimedProductsSortedByShelf.push({
              name: serializedProduct.productName,
              sku: serializedProduct.productSku,
              shelf: serializedProduct.shelf,
              shelfLetter: serializedProduct.shelfLetter,
              shelfNumber: serializedProduct.shelfNumber,
            });
            return acc;
          },
          {
            claimedProductsMap: {},
            claimedProductsStockUpdates: {},
            claimedProductsSortedByShelf: [],
            droppingListId: data.getProductsFromClaimedList?.id ?? null,
            droppingListName: data.getProductsFromClaimedList?.name ?? null,
          },
        );

      setInboundClaimedProducts(claimedProductsData);
      if (shouldNavigate) {
        navigate(routes.inbound.dropping);
      }
    },
    onError: handleError,
  });

  const fetchClaimedProducts = useCallback(async () => {
    setIsFetchingClaimedProducts(true);
    await getClaimedProducts();
  }, [getClaimedProducts]);

  return { fetchClaimedProducts, isFetchingClaimedProducts };
}

export function useCheckClaimedProductsOnLanding() {
  const navigate = useNavigate();
  const { fetchClaimedProducts, isFetchingClaimedProducts } = useFetchClaimedProducts(false);
  useEffect(() => {
    const checkClaimedProducts = async () => {
      await fetchClaimedProducts();
      const hasClaimedProducts = useInboundStore.getState().claimedProductsSortedByShelf.length > 0;
      if (hasClaimedProducts) {
        navigate(routes.inbound.dropping);
      }
    };
    checkClaimedProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { isFetchingClaimedProducts };
}

export const useClaimInboundList = () => {
  const { showToastUI } = useCustomToast();
  const [isClaimingList, setIsClaimingList] = useState(false);
  const { fetchClaimedProducts } = useFetchClaimedProducts();
  const { sendInboundStateUpdate } = useInboundEventTracker();
  const setSharedListId = useInboundStore((state) => state.setSharedListId);
  const handleError = useCallback(
    (error: unknown) => {
      setIsClaimingList(false);
      const errorMessage = error instanceof Error ? error.message : "Unknown error";
      datadogRum.addError("Claim Shared List Error", { errorMessage });
      showToastUI({
        id: CLAIM_SHARED_LIST_ERROR_TOAST_ID,
        title: "claim_shared_list_cannot_claim_error_toast",
      });
    },
    [showToastUI],
  );

  const [claimListMutation] = useClaimInboundingListMutation({
    onCompleted: async () => {
      setIsClaimingList(false);
      showToastUI({
        status: "success",
        id: CLAIM_SHARED_LIST_SUCCESS_TOAST_ID,
        title: "claim_shared_list_success_toast",
      });
      const { sharedListId, deliverySSCC } = useInboundStore.getState();
      sendInboundStateUpdate({
        state: CollaborativeInboundUpdateState.DroppingListStarted,
        droppingListId: sharedListId,
        sscc: deliverySSCC,
        inboundingType: null,
      });
      await fetchClaimedProducts();
    },
    onError: handleError,
  });

  const claimInboundList = useCallback(
    async (listId: string) => {
      setIsClaimingList(true);
      setSharedListId(listId);
      await claimListMutation({
        variables: { input: { listId } },
      });
    },
    [claimListMutation, setSharedListId],
  );

  return { claimInboundList, isClaimingList };
};
export const useUnClaimInboundList = () => {
  const { sendInboundStateUpdate } = useInboundEventTracker();
  const [isUnClaimingList, setIsUnClaimingList] = useState(false);
  const navigate = useNavigate();
  const { showToastUI } = useCustomToast();
  const { resetToInitialState, setInboundUIState } = useInboundStore((state) => ({
    resetToInitialState: state.resetToInitialState,
    setInboundUIState: state.setInboundUIState,
  }));

  const handleError = useCallback(
    (error: unknown) => {
      setIsUnClaimingList(false);
      if (
        error instanceof ApolloError &&
        error?.graphQLErrors?.[0]?.message === "the inbounding list is not claimed: invalid request"
      ) {
        setInboundUIState({ isUnclaimListConfirmationModalVisible: false });
        navigate(routes.inbound.root);
        return;
      }
      const errorMessage = error instanceof Error ? error.message : "Unknown error";
      datadogRum.addError("UnClaim List Error", { errorMessage });
      showToastUI({
        id: UNCLAIM_LIST_ERROR_TOAST_ID,
        title: "unclaim-list-error-toast",
      });
    },
    [navigate, setInboundUIState, showToastUI],
  );

  const [releaseInboundingListMutation] = useReleaseInboundingListMutation({
    onCompleted: () => {
      setIsUnClaimingList(false);
      const { droppingListId, deliverySSCC } = useInboundStore.getState();
      sendInboundStateUpdate({
        state: CollaborativeInboundUpdateState.DroppingListUnclaimed,
        droppingListId,
        sscc: deliverySSCC,
        inboundingType: null,
        isManualUnclaim: true,
      });
      resetToInitialState();
      setInboundUIState({ isUnclaimListConfirmationModalVisible: false });
      navigate(routes.inbound.root);
    },
    onError: handleError,
  });

  const unClaimInboundList = useCallback(
    async (listId: string) => {
      setIsUnClaimingList(true);
      await releaseInboundingListMutation({
        variables: { input: { listId } },
      });
    },
    [releaseInboundingListMutation],
  );

  return { unClaimInboundList, isUnClaimingList };
};

export const useInboundClaimedProduct = () => {
  const { sendSegmentTrackEvent } = useAnalytics();
  const { showToastUI } = useCustomToast();
  const updateStockForInboundReasons = useInboundStore(
    (state) => state.updateStockForInboundReasons,
  );
  const {
    droppingListId,
    droppingListName,
    claimedProductsStockUpdates,
    claimedProductsMap,
    setInboundUIState,
  } = useInboundStore.getState();

  const [isInboundingProduct, setIsInboundingProduct] = useState(false);

  const handleError = useCallback(
    (error: unknown) => {
      setIsInboundingProduct(false);
      if (
        error instanceof ApolloError &&
        error?.graphQLErrors?.[0]?.message ===
          "Cannot inbound a product that is not claimed by you.: creator ID mismatch"
      ) {
        setInboundUIState({ isUnclaimedListConfirmationModalVisible: true });
        return;
      }
      const errorMessage = error instanceof Error ? error.message : "Unknown error";
      datadogRum.addError("Claim Inbound Product Error", { errorMessage });
      showToastUI({
        id: CLAIM_INBOUND_PRODUCT_ERROR_TOAST_ID,
        title: "inbound_product_claim_error_toast.title",
        description: "inbound_product_claim_error_toast.description",
      });
    },
    [setInboundUIState, showToastUI],
  );

  const [inboundClaimedProductMutation] = useInboundProductMutation();

  const inboundClaimedProduct = useCallback(
    async (sku: string, productId?: string) => {
      setIsInboundingProduct(true);
      if (!productId) {
        handleError(new Error("Missing product ID"));
        return;
      }
      if (!claimedProductsStockUpdates[sku]) {
        handleError(new Error("Missing stock update plan"));
        return;
      }
      const { unitSizeForDisplay, handlingUnitSize, totalInboundQuantity, shelf } =
        claimedProductsMap[sku];
      if (!unitSizeForDisplay) {
        handleError(new Error("Missing display state"));
        return;
      }
      if (totalInboundQuantity === 0) {
        handleError(new Error("Stock updates must contain an inbounding"));
        return;
      }

      try {
        await inboundClaimedProductMutation({
          variables: { input: { productId } },
        });
        updateStockForInboundReasons(sku);
        const quantityInbounded =
          claimedProductsStockUpdates[sku]?.stockUpdatePlan.find(
            ({ reason }) => reason === InventoryChangeReason.inbound_goods_received,
          )?.quantityDelta ?? 0;
        sendSegmentTrackEvent("inboundProgressed", {
          action: CollaborativeInboundProgressedAction.ProductDropped,
          quantity: quantityInbounded,
          dropping_list_id: droppingListId!,
          product_sku: sku,
          is_handling_unit: handlingUnitSize > 1,
          sscc: null,
          shelf_number: shelf,
          dropping_list_name: droppingListName,
          inbounding_stage: CollaborativeInboundStage.ListDropping,
        });
      } catch (error) {
        handleError(error);
        sendSegmentTrackEvent("errorShown", {
          screen_name: PageName.INBOUND_DROPPING_PAGE,
          component_value: "inbounding_request_failed",
        });
      } finally {
        setIsInboundingProduct(false);
      }
    },
    [
      claimedProductsMap,
      claimedProductsStockUpdates,
      droppingListId,
      droppingListName,
      handleError,
      inboundClaimedProductMutation,
      sendSegmentTrackEvent,
      updateStockForInboundReasons,
    ],
  );

  return { inboundClaimedProduct, isInboundingProduct };
};

export const useDeleteClaimedProduct = () => {
  const { sendSegmentTrackEvent } = useAnalytics();
  const [isDeletingProduct, setIsDeletingProduct] = useState(false);
  const { showToastUI } = useCustomToast();
  const removeInboundUnitFromDroppingList = useInboundStore(
    (state) => state.removeInboundUnitFromDroppingList,
  );
  const { droppingListName, claimedProductsStockUpdates, claimedProductsMap, setInboundUIState } =
    useInboundStore((state) => ({
      droppingListName: state.droppingListName,
      claimedProductsStockUpdates: state.claimedProductsStockUpdates,
      claimedProductsMap: state.claimedProductsMap,
      setInboundUIState: state.setInboundUIState,
    }));
  const handleError = useCallback(
    (error: unknown) => {
      setIsDeletingProduct(false);
      if (
        error instanceof ApolloError &&
        error?.graphQLErrors?.[0]?.message ===
          "the request can not be performed for this hub_code or creator_id: invalid request"
      ) {
        setInboundUIState({ isUnclaimedListConfirmationModalVisible: true });
        return;
      }
      const errorMessage = error instanceof Error ? error.message : "Unknown error";
      datadogRum.addError("Delete Product Error", { errorMessage });
      showToastUI({
        id: DELETE_PRODUCT_ERROR_TOAST_ID,
        title: "delete-product-error-toast",
      });
    },
    [setInboundUIState, showToastUI],
  );

  const [deleteInboundingListProducts] = useDeleteInboundingListProductsMutation();

  const deleteClaimedProduct = useCallback(
    async (listId: string, sku: string) => {
      try {
        setIsDeletingProduct(true);
        await deleteInboundingListProducts({
          variables: { input: { listId, skus: [sku] } },
        });
        const quantityInbounded =
          claimedProductsStockUpdates[sku]?.stockUpdatePlan.find(
            ({ reason }) => reason === InventoryChangeReason.inbound_goods_received,
          )?.quantityDelta ?? 0;
        sendSegmentTrackEvent("inboundProgressed", {
          action: CollaborativeInboundProgressedAction.ProductRemovedFromList,
          quantity: quantityInbounded,
          dropping_list_id: listId,
          product_sku: sku,
          is_handling_unit: claimedProductsMap[sku].handlingUnitSize > 1,
          sscc: null,
          shelf_number: claimedProductsMap[sku].shelf,
          dropping_list_name: droppingListName,
          inbounding_stage: CollaborativeInboundStage.ListDropping,
        });
        removeInboundUnitFromDroppingList(sku);
      } catch (e: any) {
        handleError(e);
      } finally {
        setIsDeletingProduct(false);
      }
    },
    [
      claimedProductsMap,
      claimedProductsStockUpdates,
      deleteInboundingListProducts,
      droppingListName,
      handleError,
      removeInboundUnitFromDroppingList,
      sendSegmentTrackEvent,
    ],
  );

  return { deleteClaimedProduct, isDeletingProduct };
};

export const useEditClaimedProduct = () => {
  const { sendSegmentTrackEvent } = useAnalytics();
  const [isEditingProduct, setIsEditingProduct] = useState(false);
  const { showToastUI } = useCustomToast();
  const updateInboundQuantityForDisplay = useInboundStore(
    (state) => state.updateInboundQuantityForDisplay,
  );
  const handleError = useCallback(
    (error: unknown) => {
      setIsEditingProduct(false);
      const errorMessage = error instanceof Error ? error.message : "Unknown error";
      datadogRum.addError("Edit Product Error", { errorMessage });
      showToastUI({
        id: EDIT_PRODUCT_ERROR_TOAST_ID,
        title: "edit-product-error-toast",
      });
    },
    [showToastUI],
  );

  const [editInboundProducts] = useEditProductsInInboundingListMutation();
  const editInboundProduct = useCallback(
    async ({
      droppingListId,
      droppingListName,
      sku,
      shelf,
      newValue,
      oldValue,
    }: {
      droppingListId: string;
      droppingListName: string;
      sku: string;
      shelf: string | null;
      newValue: number;
      oldValue: number;
    }) => {
      try {
        setIsEditingProduct(true);
        await editInboundProducts({
          variables: {
            input: {
              listId: droppingListId,
              products: [
                {
                  sku,
                  quantity: newValue,
                  handlingUnitSize: 1,
                },
              ],
            },
          },
        });
        updateInboundQuantityForDisplay(sku, newValue);
        sendSegmentTrackEvent("inboundProgressed", {
          action: CollaborativeInboundProgressedAction.ProductUpdatedQuantity,
          quantity: Math.abs(newValue - oldValue),
          dropping_list_id: droppingListId,
          product_sku: sku,
          is_handling_unit: false,
          sscc: null,
          shelf_number: shelf,
          dropping_list_name: droppingListName,
          inbounding_stage: CollaborativeInboundStage.ListDropping,
        });
      } catch (e: any) {
        handleError(e);
      } finally {
        setIsEditingProduct(false);
      }
    },
    [editInboundProducts, handleError, sendSegmentTrackEvent, updateInboundQuantityForDisplay],
  );

  return { editInboundProduct, isEditingProduct };
};
