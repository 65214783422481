import { GetPublicRestockingListQuery } from "shared/queries/publicRestockingList/publicRestockingList.generated";

import { serializePublicRestockingItem } from "../restockingItem/serializer";
import { PublicRestockingList } from "./types";

export const serializePublicRestockingList = ({
  getPublicRestockingList: {
    publicRestockingList: { restockingItems },
  },
}: GetPublicRestockingListQuery): PublicRestockingList => ({
  restockingItems: restockingItems.map(serializePublicRestockingItem),
});
