import { Flex, Image } from "@chakra-ui/react";
import { FormattedMessage } from "react-intl";

import Packages from "images/random/packages.svg";
import { BodyM, HeaderS } from "ui/Typography/Typography";

export function InboundClaimedList() {
  return (
    <Flex
      flex={1}
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      px="s100"
      gap="s100"
    >
      <HeaderS textAlign="center">
        <FormattedMessage id="flows.inbound.page.inbound-claimed-list.header" />
      </HeaderS>
      <BodyM textAlign="center">
        <FormattedMessage id="flows.inbound.page.inbound-shared-list.paragraph" />
      </BodyM>
      <Image src={Packages} mt="s200" />
    </Flex>
  );
}
