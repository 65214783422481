import { assign, createMachine, DoneInvokeEvent } from "xstate";

import { routes } from "config/routes";
import { XstateRouteSyncMeta } from "shared/types/xstate";
import { isNotNullNorUndefined } from "utils/tsHelpers";

import { wrongEventErrorHandlerFactory } from "../../../utils/xstate";
import {
  ProductSearchEvents,
  ProductSearchMachineContext,
  ProductSearchServices,
  SearchResults,
} from "./types";

export const contextInitialState: ProductSearchMachineContext = {
  textSearchResults: null,
  textSearch: "",
  nonScannableCategories: null,
  productsForSubcategory: null,
  selectedSubcategoryID: null,
};

export const PRODUCT_SEARCH_MACHINE_NAME = "productSearchMachine";

export const productSearchMachine = createMachine(
  {
    preserveActionOrder: true,
    predictableActionArguments: true,
    id: PRODUCT_SEARCH_MACHINE_NAME,
    tsTypes: {} as import("./machine.typegen").Typegen0,
    schema: {
      context: {} as ProductSearchMachineContext,
      events: {} as ProductSearchEvents,
      services: {} as ProductSearchServices,
    },
    context: contextInitialState,
    on: {
      "*": { actions: "wrongEventErrorHandler" },
    },
    initial: "idle",
    states: {
      idle: {
        id: "idle",
        on: {
          ACTIVATE_SEARCH: {
            target: "active",
            actions: "putInboundMachineIntoManualSearchMode",
          },
          RESOLVE_EAN: [
            {
              target: "resolvingEAN",
              cond: "guardSearchIsEnabled",
            },
            {
              actions: "showUnverifiedQuantityModal",
            },
          ],
        },
      },
      resolvingEAN: {
        invoke: {
          src: "fetchUnitsByEAN",
          onDone: [
            {
              cond: (_, event: DoneInvokeEvent<SearchResults>) => event.data?.results?.length === 0,
              target: "idle",
              actions: "onEanSearchNoResult",
            },
            {
              cond: (_, event: DoneInvokeEvent<SearchResults>) =>
                isNotNullNorUndefined(event.data) && event.data.results?.length > 1,
              target: "#textSearchIdle",
              actions: ["putInboundMachineIntoManualSearchMode", "setTextSearchResults"],
            },
            {
              cond: (_, event: DoneInvokeEvent<SearchResults>) => event.data?.results?.length === 1,
              target: "idle",
              actions: "sendInboundUnitsToInboundMachine",
            },
          ],
          onError: {
            actions: "onSearchUnitsByEanError",
            target: "idle",
          },
        },
      },
      active: {
        id: "active",
        on: {
          GO_BACK: {
            target: "idle",
            actions: ["resetTextSearch", "ejectInboundMachineFromManualSearch"],
          },
          RESOLVE_TEXT_SEARCH: {
            target: "textSearch",
            actions: "setTextSearch",
            cond: (_, event) => event.query !== "",
          },
          ENTER_NO_EAN_FLOW: "noEAN",
        },
        meta: {
          routeSync: {
            path: routes.inbound.manualSearch,
          } as XstateRouteSyncMeta,
        },
      },
      textSearch: {
        initial: "loading",
        on: {
          GO_BACK: {
            target: "idle",
            actions: ["resetTextSearch", "ejectInboundMachineFromManualSearch"],
          },
          RESOLVE_TEXT_SEARCH: [
            {
              target: ".",
              internal: false,
              actions: "setTextSearch",
              cond: (_, event) => event.query !== "",
            },
            {
              target: "active",
              actions: "setTextSearch",
              cond: (_, event) => event.query === "",
            },
          ],
          CHOOSE_UNITS: {
            actions: [
              "ejectInboundMachineFromManualSearch",
              "sendInboundUnitsToInboundMachine",
              "resetTextSearch",
            ],
            target: "idle",
          },
        },
        states: {
          idle: {
            id: "textSearchIdle",
          },
          loading: {
            invoke: {
              src: "fetchUnitsByText",
              onDone: {
                target: "idle",
                actions: "setTextSearchResults",
              },
            },
          },
        },
        meta: {
          routeSync: {
            path: routes.inbound.manualSearch,
          } as XstateRouteSyncMeta,
        },
      },
      noEAN: {
        id: "noEAN",
        initial: "subcategories",
        states: {
          subcategories: {
            initial: "loading",
            on: {
              GO_BACK: {
                target: "#idle",
                actions: ["resetTextSearch", "ejectInboundMachineFromManualSearch"],
              },
              SWITCH_TO_TEXT_SEARCH: "#active",
            },
            states: {
              loading: {
                invoke: {
                  src: "fetchNonScannableCategories",
                  onDone: {
                    actions: "setNonScannableCategories",
                    target: "loaded",
                  },
                  onError: {
                    // actions: TODO: communicate error to the user
                    target: "#active",
                  },
                },
              },
              loaded: {
                on: {
                  CHOOSE_SUBCATEGORY: {
                    actions: "setSubcategoryID",
                    target: "#noEAN.products",
                  },
                },
              },
            },
            meta: {
              routeSync: {
                path: routes.inbound.manualSearch,
              } as XstateRouteSyncMeta,
            },
          },
          products: {
            id: "noEAN.products",
            initial: "loading",
            on: {
              GO_BACK: "#noEAN",
            },
            states: {
              loading: {
                invoke: {
                  src: "fetchProductsForSubcategory",
                  onDone: {
                    actions: "setProductsForSubcategory",
                    target: "loaded",
                  },
                },
              },
              loaded: {
                on: {
                  CHOOSE_UNITS: {
                    actions: [
                      "ejectInboundMachineFromManualSearch",
                      "sendInboundUnitsToInboundMachine",
                    ],
                    target: "#idle",
                  },
                },
              },
            },
            meta: {
              routeSync: {
                path: routes.inbound.noEANProducts,
              } as XstateRouteSyncMeta,
            },
          },
        },
      },
    },
  },
  {
    actions: {
      wrongEventErrorHandler: wrongEventErrorHandlerFactory(PRODUCT_SEARCH_MACHINE_NAME),
      setTextSearchResults: assign((_, event) => ({
        textSearchResults: event.data,
      })),
      setNonScannableCategories: assign((_, event) => ({
        nonScannableCategories: event.data,
      })),
      setTextSearch: assign((_, event) => ({ textSearch: event.query })),
      setProductsForSubcategory: assign((_, event) => ({
        productsForSubcategory: event.data,
      })),
      setSubcategoryID: assign((_, event) => ({
        selectedSubcategoryID: event.subcategoryID,
      })),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      resetTextSearch: assign((_) => ({ textSearch: "" })),
    },
  },
);
