import { useMemo } from "react";

import { Image } from "@chakra-ui/react";

import FlinkLogoSmall from "images/logos/Flink-logo-small.png";
import JustEatLogo from "images/logos/Just-Eat-logo.png";
import JustEatReweLogo from "images/logos/Just-Eat-Rewe-logo.png";
import UberEatsCarrefourLogo from "images/logos/Uber-eats-carrefour-logo.png";
import UberEatsLogoSmall from "images/logos/Uber-eats-logo-small.png";
import WoltLogoSmall from "images/logos/Wolt-logo-small.png";

export function SupplierLogo({ reseller }: { reseller: string }) {
  const supplierLogo = useMemo(() => {
    switch (reseller) {
      case "UBER-EATS-CARREFOUR":
        return UberEatsCarrefourLogo;
      case "WOLT":
        return WoltLogoSmall;
      case "UBER-EATS":
        return UberEatsLogoSmall;
      case "JUST-EAT":
        return JustEatLogo;
      case "JUST-EAT-REWE":
        return JustEatReweLogo;
      default:
        return FlinkLogoSmall;
    }
  }, [reseller]);

  return (
    <Image
      role="img"
      alt="logo-supplier"
      src={supplierLogo}
      height="28px"
      width="auto"
      loading="lazy"
    />
  );
}
